import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import ViewWeekOutlinedIcon from "@mui/icons-material/ViewWeekOutlined";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { transferOrderModule } from "app/i18n/types";
import { TransferLine } from "core/transfer/entities/TransferOrder";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { DataFilterTransferOrder } from "utils/general";
import StatisticCard from "../StatisticCard";

interface TransferOrderSummaryProps {
  transferLines: TransferLine[];
  dataFilter: DataFilterTransferOrder;
  setDataFilter: (dataFilter: DataFilterTransferOrder) => void;
}

const TransferOrderSummary: FunctionComponent<TransferOrderSummaryProps> = ({
  transferLines,
  dataFilter,
  setDataFilter,
}) => {
  const { t } = useTranslation();
  const enlistment = transferLines.filter((line) => {
    return (
      line.status === DataFilterTransferOrder.IN_PROCESS ||
      line.status === DataFilterTransferOrder.RELEASED
    );
  }).length;
  const packed = transferLines.filter((line) => {
    return line.status === DataFilterTransferOrder.PACKED;
  }).length;
  const inTransit = transferLines.filter((line) => {
    return line.status === DataFilterTransferOrder.IN_TRANSIT;
  }).length;
  const received = transferLines.filter((line) => {
    return line.status === DataFilterTransferOrder.RECEIVED;
  }).length;

  return (
    <>
      {!!transferLines.length && (
        <Grid item xs={12}>
          <Box display={"flex"} mb={2} gap={2} flexWrap={"wrap"}>
            <StatisticCard
              icon={ViewWeekOutlinedIcon}
              label={"Todos"}
              value={transferLines.length.toString()}
              count={""}
              color="primary"
              selected={dataFilter === DataFilterTransferOrder.ALL}
              onClick={() => setDataFilter(DataFilterTransferOrder.ALL)}
            />
            <StatisticCard
              icon={TrendingUpOutlinedIcon}
              label={t(transferOrderModule.RELEASED)}
              value={enlistment.toString()}
              count={""}
              color="warning"
              selected={dataFilter === DataFilterTransferOrder.IN_PROCESS}
              onClick={() => setDataFilter(DataFilterTransferOrder.IN_PROCESS)}
            />
            <StatisticCard
              icon={TrendingUpOutlinedIcon}
              label={t(transferOrderModule.PACKED)}
              value={packed.toString()}
              count={""}
              color="info"
              selected={dataFilter === DataFilterTransferOrder.PACKED}
              onClick={() => setDataFilter(DataFilterTransferOrder.PACKED)}
            />
            <StatisticCard
              icon={TrendingUpOutlinedIcon}
              label={t(transferOrderModule.IN_TRANSIT)}
              value={inTransit.toString()}
              count={""}
              color="primary"
              selected={dataFilter === DataFilterTransferOrder.IN_TRANSIT}
              onClick={() => setDataFilter(DataFilterTransferOrder.IN_TRANSIT)}
            />
            <StatisticCard
              icon={TrendingUpOutlinedIcon}
              label={t(transferOrderModule.RECEIVED)}
              value={received.toString()}
              count={""}
              color="success"
              selected={dataFilter === DataFilterTransferOrder.RECEIVED}
              onClick={() => setDataFilter(DataFilterTransferOrder.RECEIVED)}
            />
          </Box>
        </Grid>
      )}
    </>
  );
};

export default TransferOrderSummary;
