import { UseCase } from "core/common/interfaces/useCase";
import { CanAddItemCount } from "core/physicalCount/interfaces/product/CanAddItemCount";

export class AddItemCount implements UseCase {
  constructor(private readonly repository: CanAddItemCount) {}

  public execute(
    countingId: string,
    sku: string,
    unit: string,
    quantity: number,
    comments: string,
    status: string
  ) {
    return this.repository.addItemCount(
      countingId,
      sku,
      unit,
      quantity,
      comments,
      status
    );
  }
}
