import { UseCase } from "core/common/interfaces/useCase";
import { CanGetAdjustmentsByKitchenForReview } from "core/dailyAdjustments/interfaces/adjustment/canGetAdjustmentsByKitchenForReview";

export class GetAdjustmentsByKitchenForReviewUseCase implements UseCase {
  constructor(
    private readonly repository: CanGetAdjustmentsByKitchenForReview
  ) {}

  public execute(kitchenId: string) {
    return this.repository.getAdjustmentsByKitchenForReview(kitchenId);
  }
}
