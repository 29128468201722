import { StoreRequestError, StoreRequestStatus } from "app/store/types";
import { TransferOrderError } from "core/BusinessCentralIntegration/entities/BusinessCentralIntegration";
import {
  ExtraItem,
  PickingLocationsResponse,
  PickingResponse,
  TransferOrderResponse,
  TransferResponse,
} from "core/transfer/entities/TransferOrder";

export interface TransferState {
  createTransferOrder: {
    status: StoreRequestStatus;
    error: StoreRequestError;
  };
  createSingleTransferOrder: {
    status: StoreRequestStatus;
    error: StoreRequestError;
  };
  reportTransferOrder: {
    status: StoreRequestStatus;
    error: StoreRequestError;
  };
  archiveLines: {
    status: StoreRequestStatus;
    error: StoreRequestError;
  };
  transfeOrders: {
    status: StoreRequestStatus;
    error: StoreRequestError;
    transferOrders: Array<TransferResponse> | null;
  };
  pendingTransferOrders: {
    status: StoreRequestStatus;
    error: StoreRequestError;
    transferOrders: Array<TransferOrderResponse> | null;
  };
  packedTransferOrders: {
    status: StoreRequestStatus;
    error: StoreRequestError;
    transferOrders: Array<TransferOrderResponse> | null;
  };
  pickingItems: {
    status: StoreRequestStatus;
    error: StoreRequestError;
    pickingItems: PickingResponse | null;
  };
  pickingItemLocations: {
    status: StoreRequestStatus;
    error: StoreRequestError;
    pickingItemLocations: Array<PickingLocationsResponse> | null;
  };
  linesStatus: {
    [sku: string]: {
      status: StoreRequestStatus;
      error: StoreRequestError;
    };
  } | null;
  setTransferOrderAsPacked: {
    status: StoreRequestStatus;
    error: StoreRequestError;
  };
  extraItems: Array<ExtraItem> | null;
  saveExtraItems: {
    status: StoreRequestStatus;
    error: StoreRequestError;
  };
  archiveTransferOrder: {
    status: StoreRequestStatus;
    error: StoreRequestError;
  };
  transferErrors: Array<TransferOrderError>;
}

export const initialState: TransferState = {
  createTransferOrder: {
    status: "idle",
    error: null,
  },
  createSingleTransferOrder: {
    status: "idle",
    error: null,
  },
  reportTransferOrder: {
    status: "idle",
    error: null,
  },
  archiveLines: {
    status: "idle",
    error: null,
  },
  pendingTransferOrders: {
    status: "idle",
    error: null,
    transferOrders: null,
  },
  transfeOrders: {
    status: "idle",
    error: null,
    transferOrders: null,
  },
  packedTransferOrders: {
    status: "idle",
    error: null,
    transferOrders: null,
  },
  pickingItems: {
    status: "idle",
    error: null,
    pickingItems: null,
  },
  linesStatus: null,
  pickingItemLocations: {
    status: "idle",
    error: null,
    pickingItemLocations: null,
  },
  setTransferOrderAsPacked: {
    status: "idle",
    error: null,
  },
  extraItems: [],
  saveExtraItems: {
    status: "idle",
    error: null,
  },
  archiveTransferOrder: {
    status: "idle",
    error: null,
  },
  transferErrors: [],
};
