import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { productionManagement } from "app/i18n/types";
import { ProductionRecord } from "core/productions/entities/Productions";
import React from "react";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import ProductionIngredientsPrintTemplate from "./IngredientsPrintTemplate";

interface Props {
  production: ProductionRecord;
  compact?: boolean;
}

const ProductionCardIngredientsToPrint = (props: Props): React.ReactElement => {
  const { production, compact } = props;

  const { t } = useTranslation();

  const componentRef = React.useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      {compact ? (
        <Button
          onClick={handlePrint}
          variant="outlined"
          color="info"
          size="small"
          sx={{ minWidth: 56 }}
        >
          <ReceiptLongOutlinedIcon color="info" />
        </Button>
      ) : (
        <Button
          color="primary"
          variant="outlined"
          onClick={handlePrint}
          startIcon={<ReceiptLongOutlinedIcon />}
        >
          {t(productionManagement.PRINT_INGREDIENTS)}
        </Button>
      )}
      <Box sx={{ display: "none" }}>
        <ProductionIngredientsPrintTemplate
          production={production}
          ref={componentRef}
        />
      </Box>
    </>
  );
};

export default ProductionCardIngredientsToPrint;
