import { UseCase } from "core/common/interfaces/useCase";
import { ExtraItemRequest } from "core/transfer/entities/TransferOrder";
import { CanSaveExtraItems } from "core/transfer/interfaces/transfer/canSaveExtraItems";

export class SaveExtraItemsUseCase implements UseCase {
  constructor(private readonly repository: CanSaveExtraItems) {}

  public execute(payload: ExtraItemRequest) {
    return this.repository.saveExtraItems(payload);
  }
}
