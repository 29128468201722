import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { CatalogProductionIngredient } from "core/productions/entities/Catalog";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { commons, productionManagement } from "../../../i18n/types";

interface ProductionCalculatorProps {
  openCalculator: boolean;
  ingredients: CatalogProductionIngredient[];
  handleClose: () => void;
  handleConfirm: (estimatedQuantity: number) => void;
}

const ProductionCalculator: FunctionComponent<ProductionCalculatorProps> = (
  props
) => {
  const { t } = useTranslation();
  const { openCalculator, ingredients, handleClose, handleConfirm } = props;
  const [estimatedQuantity, setEstimatedQuantity] = useState<number | null>(
    null
  );
  const [quantityToCalculate, setQuantityToCalculate] = useState<number>(0);
  const [supplyToCalculate, setSupplyToCalculate] =
    useState<CatalogProductionIngredient | null>(null);

  useEffect(() => {
    if (quantityToCalculate > 0 && supplyToCalculate) {
      setEstimatedQuantity(
        Math.floor(quantityToCalculate / supplyToCalculate.quantity)
      );
    } else {
      setEstimatedQuantity(null);
    }
  }, [quantityToCalculate, supplyToCalculate]);

  const onSelectSupply = (supply: CatalogProductionIngredient) => {
    setSupplyToCalculate(supply);
    setQuantityToCalculate(0);
  };

  const clearState = () => {
    setEstimatedQuantity(null);
    setSupplyToCalculate(null);
    setQuantityToCalculate(0);
  };

  return (
    <Dialog
      open={openCalculator}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={{ pr: "40px", pl: "40px", pt: "30px" }}
        id="authorization-dialog-title"
        color="primary.dark"
        variant="h5"
        fontWeight={600}
      >
        {t(productionManagement.PRODUCTION_CALCULATOR_TITLE)}
      </DialogTitle>

      <DialogContent sx={{ maxWidth: "450px", pr: "40px", pl: "40px" }}>
        <Box display="flex" justifyContent="center" pt={2}>
          {t(productionManagement.PRODUCTION_CALCULATOR_DESCRIPTION)}
        </Box>
        <Box display="flex" justifyContent="center" pt={2}>
          <FormControl sx={{ width: "100%" }}>
            <Autocomplete
              options={ingredients ?? []}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label={t(commons.SUPPLY)} />
              )}
              onChange={(
                event: any,
                newValue: CatalogProductionIngredient | null
              ) => {
                if (newValue) {
                  onSelectSupply(newValue);
                }
              }}
            />
          </FormControl>
        </Box>
        <Box display="flex" justifyContent="center" pt={2}>
          <TextField
            label={t(commons.QUANTITY)}
            value={quantityToCalculate ?? ""}
            onChange={(e) => setQuantityToCalculate(parseFloat(e.target.value))}
            InputProps={{
              type: "number",
              endAdornment: (
                <InputAdornment position="start">
                  {supplyToCalculate?.unit ?? ""}
                </InputAdornment>
              ),
            }}
            fullWidth={true}
            disabled={supplyToCalculate === null}
            focused
            autoComplete="off"
          />
        </Box>
        {estimatedQuantity && (
          <Box display="flex" justifyContent="start" pt={2}>
            <Typography>
              {t(productionManagement.PRODUCTION_CALCULATOR_ESTIMATED)}
              {": "}
              {estimatedQuantity}
            </Typography>
          </Box>
        )}
      </DialogContent>

      <DialogActions sx={{ pl: "40px", pr: "40px", pb: "30px" }}>
        <Box width="100%" display="flex" gap={1}>
          <Button
            onClick={() => {
              clearState();
              handleClose();
            }}
            variant="outlined"
            color="error"
            startIcon={<CloseOutlinedIcon />}
            fullWidth
            sx={{ p: "12px", fontWeight: "600" }}
          >
            {t(commons.CANCEL)}
          </Button>

          <Button
            onClick={() => {
              handleConfirm(estimatedQuantity ?? 0);
              clearState();
            }}
            variant="contained"
            color="primary"
            startIcon={<ArrowForwardIosIcon />}
            fullWidth
            autoFocus
            disabled={estimatedQuantity === null}
            sx={{ p: "12px", fontWeight: "600" }}
          >
            {t(commons.CONTINUE)}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ProductionCalculator;
