import { HermesNotification as HermesNotificationIncoming } from "@foodology-co/legology/dist/hermes/providers/hermes/types";
import { firebaseApp } from "core/common/utils/firebase-config";
import { Messaging, onMessage } from "firebase/messaging";
import { useEffect, useState } from "react";
import { slugsForKitchenSelector } from "utils/general";
import { useNavigator } from "./useNavigator";

const { legologyHermesInstance } = firebaseApp();

const useNotifications = () => {
  const [notifications, setNotifications] = useState<
    HermesNotificationIncoming[]
  >([]);
  const navigator = useNavigator();
  const { kitchenId } = navigator.params();
  const kitchenIdValidator = !slugsForKitchenSelector.includes(kitchenId ?? "");

  const fetchNotifications = async (kitchenId: string) => {
    try {
      const initialNotifications =
        await legologyHermesInstance.getAllNotifications(kitchenId);
      setNotifications(initialNotifications);
    } catch (error) {
      console.error(error);
    }
  };

  const refreshNotifications = () => {
    if (kitchenId && kitchenIdValidator) {
      fetchNotifications(kitchenId).then(() => {});
    }
  };

  useEffect(() => {
    if (kitchenId && kitchenIdValidator) {
      const registerTopic = async () => {
        await legologyHermesInstance.unsubscribe();
        await legologyHermesInstance.subscribeToTopic(kitchenId);
      };
      registerTopic().catch((error) => console.error(error));
    }
  }, [kitchenId]);

  useEffect(() => {
    refreshNotifications();
  }, [kitchenId]);

  useEffect(() => {
    onMessage(
      legologyHermesInstance.messagingInstance as Messaging,
      (payload) => {
        legologyHermesInstance.incomingMessageCallback(
          payload,
          false,
          (upToDateNotifications) => {
            setNotifications(upToDateNotifications);
          }
        );
      }
    );
  }, []);

  const verifyNotificationPerms = () => {
    if (!legologyHermesInstance.accessGranted) {
      legologyHermesInstance.requestBrowserAccess();
    }
  };

  return {
    notifications,
    legologyHermesInstance,
    refreshNotifications,
    verifyNotificationPerms,
  };
};

export default useNotifications;
