import {
  CalendarMonth,
  FactCheckOutlined,
  Settings,
} from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import CustomTabs from "app/components/common/Tabs";
import { transferOrdersPackedReviewTable } from "app/i18n/types";
import { TransferOrderLineRefined } from "core/transfer/entities/TransferOrder";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import {
  transferLineCompleteValidator,
  transferLineNoSentValidator,
  transferLinePartialValidator,
} from "utils/general";
import AvgPaceIcon from "utils/icons/AvgPaceIcon";

interface TransferLinesSummaryProps {
  lines: TransferOrderLineRefined[];
  setDataFilter: (tab: number) => void;
}

const TransferLinesSummary: FunctionComponent<TransferLinesSummaryProps> = (
  props
) => {
  const { t } = useTranslation();
  const { lines, setDataFilter } = props;

  const total = lines.length;
  const completeCount = lines.filter((el) =>
    transferLineCompleteValidator(el)
  ).length;
  const partialCount = lines.filter((el) =>
    transferLinePartialValidator(el)
  ).length;
  const noSentCount = lines.filter((el) =>
    transferLineNoSentValidator(el)
  ).length;

  return (
    <>
      {!!lines.length && (
        <Grid item xs={12}>
          <Box display={"flex"} mb={2} gap={2} flexWrap={"wrap"}>
            <CustomTabs
              tabs={[
                {
                  childrenWithChip: {
                    startIcon: <AvgPaceIcon fontSize="24" />,
                    text: t(transferOrdersPackedReviewTable.ALL_TITLE),
                    chipLabel: total,
                  },
                },
                {
                  childrenWithChip: {
                    startIcon: <CalendarMonth />,
                    text: t(transferOrdersPackedReviewTable.COMPLETE_TITLE),
                    chipLabel: completeCount,
                  },
                },
                {
                  childrenWithChip: {
                    startIcon: <FactCheckOutlined />,
                    text: t(transferOrdersPackedReviewTable.PARTIAL_TITLE),
                    chipLabel: partialCount,
                  },
                },
                {
                  childrenWithChip: {
                    startIcon: <Settings />,
                    text: t(transferOrdersPackedReviewTable.NO_SENT_TITLE),
                    chipLabel: noSentCount,
                  },
                },
              ]}
              onChange={setDataFilter}
            />
          </Box>
        </Grid>
      )}
    </>
  );
};

export default TransferLinesSummary;
