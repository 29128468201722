import { Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { commons, purchaseOrderCreation } from "app/i18n/types";
import {
  CreatePurchaseOrderError,
  CreatePurchaseOrderResponse,
} from "core/purchaseOrders/entities/PurchaseOrder";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface CreationResultDialogProps {
  response: CreatePurchaseOrderResponse;
  onClose: () => void;
}

const CreationResultDialog: FunctionComponent<CreationResultDialogProps> = ({
  response,
  onClose,
}) => {
  const { t } = useTranslation();

  const getErrorMessage = (error: CreatePurchaseOrderError) => {
    return t(`purchaseOrderCreation.RESULT_DIALOG_ERROR_${error.type}`, {
      code: error.code,
      invoiceNo: error.invoiceNo.replace(/F-/g, ""),
    });
  };
  return (
    <Dialog open>
      <DialogTitle>{t(purchaseOrderCreation.RESULT_DIALOG_TITLE)}</DialogTitle>
      <DialogContent>
        {response.errors?.length ? (
          response.errors.map((error) => (
            <Alert key={`${error.invoiceNo}-${error.code}`} severity="error">
              {getErrorMessage(error)}
            </Alert>
          ))
        ) : (
          <Alert severity="success">
            <Typography variant="body1">
              {t(purchaseOrderCreation.RESULT_NO_ERRORS)}
            </Typography>
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          {t(commons.CONTINUE)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreationResultDialog;
