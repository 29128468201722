import { LinesPurchaseOrder } from "core/linesPurchaseOrders/entities/LinesPurchaseOrder";
import {
  Purchase,
  PurchaseLine,
  PurchaseOrder,
  PurchaseOrderLine,
  PurchaseOrderStatus,
} from "core/purchaseOrders/entities/PurchaseOrder";

export const converterLineToItem = (line: PurchaseLine) => {
  return {
    lineId: line.id,
    receiptOrderId: "",
    lineNo: 0,
    source: "",
    sourceId: "",
    sourceLineNo: 0,
    locationCode: "",
    sku: line.sku,
    description: line.description,
    quantity: line.quantity,
    quantityReceived: line.quantityReceive,
    measureUnit: line.unit,
    status: "IN_PROCESS",
    overReceiptQuantity: 50,
    labels: [],
    quantityToReceive: line.quantityReceive,
  } as LinesPurchaseOrder;
};

export const converterLineToPurchaseOrderLine = (line: PurchaseLine) => {
  return {
    id: line.id,
    sku: line.sku,
    productName: line.description,
    quantity: line.quantity,
    unitPrice: line.unitCost,
    measureUnit: line.unit,
  } as PurchaseOrderLine;
};

export const converterPurchaseOrderLineToPurchaseLine = (
  line: PurchaseOrderLine
) => {
  return {
    id: line.id,
    sku: line.sku,
    type: "Item",
    description: line.productName,
    unit: line.measureUnit,
    quantity: line.quantity,
    quantityReceive: 0,
    unitCost: line.unitPrice,
    amout: line.unitPrice * line.quantity,
    status: "PENDING",
  } as PurchaseLine;
};

export const converterPurchaseToPurchaseOrder = (
  order: Purchase,
  kitchenId?: string
) => {
  return {
    id: Number(order.orderId),
    vendorCode: order.vendorId,
    vendorName: order.vendorName,
    postingDate: order.expectedReceiptAt?.toString(),
    locationCode: kitchenId ?? "",
    country: order.country,
    orderDate: order.dateDocument?.toString(),
    invoiceNo: order.invoiceNo,
    expectedReceiptAt: order.expectedReceiptAt?.toString(),
    lines:
      order.lines?.map((line) => converterLineToPurchaseOrderLine(line)) ?? [],
  } as PurchaseOrder;
};

export const unreceivedStatuses = [
  PurchaseOrderStatus.PENDING,
  PurchaseOrderStatus.RELEASED,
] as string[];

export const finishedStatuses = [
  PurchaseOrderStatus.ARCHIVED,
  PurchaseOrderStatus.ACCOUNTED,
] as string[];

export const getIsEditionMode = (status?: string) => {
  const statuses = [
    PurchaseOrderStatus.PENDING,
    PurchaseOrderStatus.RELEASED,
    PurchaseOrderStatus.PARTIAL_RECEIVED,
    PurchaseOrderStatus.RECEIVED,
    PurchaseOrderStatus.ERROR_IN_PRICE,
    PurchaseOrderStatus.ERROR_IN_QUANTITY,
    PurchaseOrderStatus.ERROR_IN_PRICE_AND_QUANTITY,
  ] as string[];
  return statuses.includes(status ?? "");
};
