import { AppConfig } from "config/app";
import { User } from "core/account/entities/User";
import { ProductionCatalogResponse } from "core/productions/entities/Catalog";
import { ProductionInventoryResponse } from "core/productions/entities/Inventory";
import {
  Organoleptics,
  OrganolepticsResultRequest,
  ProductionApprovementResult,
  ProductionLabelResponse,
  ProductionLogSearchResponse,
  ProductionLogSearchType,
  ProductionPayload,
  ProductionRecord,
  ProductionsResponse,
} from "core/productions/entities/Productions";
import { canArchiveProduction } from "core/productions/interfaces/canArchiveProduction";
import { CanCreateOrganolepticsResults } from "core/productions/interfaces/canCreateOrganolepticsResults";
import { canCreateProduction } from "core/productions/interfaces/canCreateProduction";
import { canCreateProductionlabel } from "core/productions/interfaces/canCreateProductionLabel";
import { canFinishProduction } from "core/productions/interfaces/canFinishProduction";
import { CanGetOrganolepticsResults } from "core/productions/interfaces/canGetOrganolepticsResults";
import { canGetProductionById } from "core/productions/interfaces/canGetProductionbyId";
import { canGetProductionInventory } from "core/productions/interfaces/canGetProductionInventory";
import { canGetProductionLabel } from "core/productions/interfaces/canGetproductionLabel";
import { canGetProductionRecipe } from "core/productions/interfaces/canGetProductionRecipe";
import { canGetProductionsByKitchen } from "core/productions/interfaces/canGetProductionsByKitchen";
import { canGetProductionsCatalogByKitchen } from "core/productions/interfaces/canGetProductionsCatalogByKitchen";
import { CanLiberateProduction } from "core/productions/interfaces/canLiberateProduction";
import { CanSendProductionsPrintEvent } from "core/productions/interfaces/canSendProductionsPrintEvent";
import { canSetProductionDetailUsedQuantity } from "core/productions/interfaces/canSetProductionDetailUsedQUantity";
import { CanStartProduction } from "core/productions/interfaces/canStartProduction";
import { ApiVersion, getHttp, postHttp } from "utils/http";

export class ProductionsHTTPRepository
  implements
    canGetProductionsCatalogByKitchen,
    canGetProductionInventory,
    canGetProductionsByKitchen,
    CanStartProduction,
    canFinishProduction,
    canGetProductionById,
    canSetProductionDetailUsedQuantity,
    canCreateProduction,
    canGetProductionRecipe,
    canArchiveProduction,
    canCreateProductionlabel,
    canGetProductionLabel,
    CanSendProductionsPrintEvent,
    CanLiberateProduction,
    CanCreateOrganolepticsResults,
    CanGetOrganolepticsResults
{
  constructor(private readonly config: AppConfig) {}

  public async sendProductionsPrintEvent(
    productionId: string
  ): Promise<boolean> {
    try {
      const productionInventoryResponse = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `productions/${productionId}/print`
      );
      return productionInventoryResponse.ok;
    } catch (err) {
      console.error("sendProductionsPrintEvent error", err);
      throw err;
    }
  }

  public async saveProductionSchedule(
    kitchenId: string,
    body: Array<{
      sku: string;
      quantity: number;
      scheduledDate: string;
      userAssigned: string;
      timeSlot: string;
    }>
  ): Promise<boolean> {
    try {
      const productionInventoryResponse = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `kitchen/${kitchenId}/productions/plan/save`,
        body
      );
      return productionInventoryResponse.ok;
    } catch (err) {
      console.error("sendProductionsPrintEvent error", err);
      throw err;
    }
  }

  public async setProductionDetailUsedQuantity(
    productionDetailId: string,
    usedQuantity: number
  ): Promise<void> {
    try {
      const productionInventoryResponse = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `productions/detail/${productionDetailId}`,
        {
          usedQuantity,
        }
      );
      await productionInventoryResponse.json();
      return;
    } catch (err) {
      console.error("setProductionDetailUsedQuantity error", err);
      throw err;
    }
  }

  public async getProductionsCatalogByKitchen(
    kitchenId: string
  ): Promise<ProductionCatalogResponse> {
    try {
      const productionsResponse = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `productions/kitchen/${kitchenId}/catalog`
      );
      const productionsResponseJson: ProductionCatalogResponse =
        await productionsResponse.json();
      return productionsResponseJson;
    } catch (err) {
      console.error("getProductionsByKitchen error", err);
      throw err;
    }
  }

  public async getProductionInventory(
    kitchenId: string,
    sku: string
  ): Promise<ProductionInventoryResponse> {
    try {
      const productionInventoryResponse = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `productions/kitchen/${kitchenId}/sku/${sku}/inventory`
      );
      const productionInventoryResponseJson: ProductionInventoryResponse =
        await productionInventoryResponse.json();
      return productionInventoryResponseJson;
    } catch (err) {
      console.error("getProductionInventory error", err);
      throw err;
    }
  }

  public async createProduction(
    production: ProductionPayload
  ): Promise<string> {
    try {
      const productionInventoryResponse = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `productions`,
        production
      );
      const id = await productionInventoryResponse.text();
      return id;
    } catch (err) {
      console.error("addProduction error", err);
      throw err;
    }
  }

  public async getProductionsByKitchen(
    kitchenId: string,
    status: "ACTIVES" | "TO_APPROVE" | "FINISHED"
  ): Promise<Array<ProductionRecord>> {
    try {
      const productionsResponse = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `productions/kitchen/${kitchenId}/${status}/list`
      );
      const productionsResponseJson: ProductionsResponse =
        await productionsResponse.json();
      return productionsResponseJson.products;
    } catch (err) {
      console.error("getProductionsByKitchen error", err);
      throw err;
    }
  }

  public async getProductionById(
    productionId: string,
    kitchenId: string
  ): Promise<ProductionRecord> {
    try {
      const productionsResponse = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `productions/kitchen/${kitchenId}/${productionId}`
      );
      const productionsResponseJson: ProductionRecord =
        await productionsResponse.json();
      return productionsResponseJson;
    } catch (err) {
      console.error("getProductionsByKitchen error", err);
      throw err;
    }
  }

  public async startProduction(productionId: string): Promise<void> {
    try {
      const productionInventoryResponse = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `productions/${productionId}/start`
      );
      await productionInventoryResponse.json();
      return;
    } catch (err) {
      console.error("startProduction error", err);
      throw err;
    }
  }

  public async finishProduction(
    productionId: string,
    producedQuantity: number,
    comment: string
  ): Promise<void> {
    try {
      const productionInventoryResponse = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `productions/${productionId}/finish`,
        {
          producedQuantity,
          userId: "",
          comment,
        }
      );
      await productionInventoryResponse.json();
      return;
    } catch (err) {
      console.error("finishProduction error", err);
      throw err;
    }
  }

  public async liberateProduction(
    productionId: string,
    result: ProductionApprovementResult,
    novelty: string
  ): Promise<void> {
    try {
      const productionInventoryResponse = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `productions/${productionId}/liberate`,
        {
          result,
          novelty,
        }
      );
      await productionInventoryResponse.json();
      return;
    } catch (err) {
      console.error("liberateProduction error", err);
      throw err;
    }
  }

  public async getProductionRecipe(
    productionId: string,
    kitchenId: string
  ): Promise<ProductionCatalogResponse> {
    try {
      const productionsResponse = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `productions/kitchen/${kitchenId}/production/${productionId}`
      );
      const productionsResponseJson: ProductionCatalogResponse =
        await productionsResponse.json();
      return productionsResponseJson;
    } catch (err) {
      console.error("getProductionRecipe error", err);
      throw err;
    }
  }

  public async updateProduction(
    production: ProductionPayload,
    productionId: string
  ): Promise<string> {
    try {
      const productionInventoryResponse = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `productions/${productionId}`,
        production
      );
      return await productionInventoryResponse.text();
    } catch (err) {
      console.error("update production error", err);
      throw err;
    }
  }

  public async updateProductionManager(
    productionId: string,
    userId: string,
    userName: string
  ): Promise<boolean> {
    try {
      const productionInventoryResponse = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `productions/update/manager`,
        { productionId, userId, userName }
      );
      return (await productionInventoryResponse.text()) === "true";
    } catch (err) {
      console.error("updateProductionManager error", err);
      throw err;
    }
  }

  public async archiveProduction(productionId: string): Promise<string> {
    try {
      const productionInventoryResponse = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `productions/archive/${productionId}`
      );
      return await productionInventoryResponse.text();
    } catch (err) {
      console.error("archive production error", err);
      throw err;
    }
  }

  public async getProductionLabels(
    productionId: string
  ): Promise<Array<ProductionLabelResponse>> {
    try {
      const labelsResponse = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `productions/${productionId}/label`
      );
      const labelsResponseJson: Array<ProductionLabelResponse> =
        await labelsResponse.json();
      return labelsResponseJson;
    } catch (err) {
      console.error("get labels production error", err);
      throw err;
    }
  }

  public async createProductionLabels(
    productionId: string,
    quantity: number,
    quantityOfPacking: number
  ): Promise<Array<ProductionLabelResponse>> {
    try {
      const labelsResponse = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `productions/${productionId}/generate-labels`,
        {
          quantity,
          quantityOfPacking,
        }
      );
      const labelsResponseJson: Array<ProductionLabelResponse> =
        await labelsResponse.json();
      return labelsResponseJson;
    } catch (err) {
      console.error("create labels production error", err);
      throw err;
    }
  }

  public async getUsersByKitchenId(kitchenId: string): Promise<User[]> {
    try {
      const response = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `productions/users/by/kitchen/${kitchenId}`
      );
      const responseJson = await response.json();
      return responseJson as User[];
    } catch (error) {
      console.error(
        "AdjustmentHTTPRepository.getAdjustmentsByKitchen error",
        error
      );
      throw error;
    }
  }

  public async getProductionLogByType(
    type: ProductionLogSearchType,
    searchTerm: string
  ): Promise<ProductionLogSearchResponse> {
    try {
      const labelsResponse = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `production/log/by-type`,
        {
          type,
          search: searchTerm,
        }
      );
      const labelsResponseJson: ProductionLogSearchResponse =
        await labelsResponse.json();
      return labelsResponseJson;
    } catch (err) {
      console.error("create labels production error", err);
      throw err;
    }
  }

  public async createOrganolepticsResults(
    request: OrganolepticsResultRequest
  ): Promise<void> {
    try {
      const response = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `productions/organoleptics/save`,
        request
      );
      await response.json();
    } catch (error) {
      console.error("createOrganolepticsResults error", error);
      throw error;
    }
  }

  public async getOrganolepticsResults(productionId: string): Promise<any> {
    try {
      const response = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `productions/organoleptics/${productionId}`
      );
      const responseJson: Organoleptics = await response.json();
      return responseJson;
    } catch (error) {
      console.error("getOrganolepticsResults error", error);
      throw error;
    }
  }
}
