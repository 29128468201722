import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Box, Button, Typography } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { convertLocalDate } from "app/utils/dateUtils";
import { allReasons } from "config/dailyAdjustment";
import { AdjustmentRecord } from "core/dailyAdjustments/entities/Adjustment";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { measureUnits } from "../../i18n/types";

interface DailyAdjustmentRowProps {
  item: AdjustmentRecord;
  handleOpenDocumentList: () => void;
}

const DailyAdjustmentRow: FunctionComponent<DailyAdjustmentRowProps> = (
  props
) => {
  const { item, handleOpenDocumentList } = props;
  const { t } = useTranslation();

  return (
    <TableRow key={item.sku}>
      <TableCell sx={{ textAlign: "center" }}>{item.sku}</TableCell>
      <TableCell sx={{ textAlign: "center" }}>{item.name}</TableCell>
      <TableCell sx={{ textAlign: "center" }}>{item.quantity}</TableCell>
      <TableCell sx={{ textAlign: "center" }}>
        {t(measureUnits[item.unit as keyof typeof measureUnits]).toUpperCase()}
      </TableCell>
      <TableCell align="center">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography textTransform="uppercase" color="text.secondary">
            {item.motiveId && t(allReasons[item.motiveId])}

            {!item.motiveId && t(`adjustmentType.${item.typeLoss}`)}
          </Typography>
          {(item.typeLoss === "LOSS" || item.typeLoss === "EVENT") && (
            <Button
              variant="text"
              color="info"
              size="small"
              onClick={handleOpenDocumentList}
            >
              <VisibilityOutlinedIcon />
            </Button>
          )}
        </Box>
      </TableCell>
      <TableCell align="center">
        <Typography fontWeight={700} textTransform="uppercase">
          {convertLocalDate(item.createdAt.toString(), "date")}
        </Typography>
        <Typography fontWeight={700} textTransform="uppercase">
          {convertLocalDate(item.createdAt.toString(), "time")}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default DailyAdjustmentRow;
