import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import TagOutlinedIcon from "@mui/icons-material/TagOutlined";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { commons, vendor } from "app/i18n/types";
import { VendorWithSupplies } from "core/vendors/entities/Vendor";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  data: VendorWithSupplies;
}

const VendorSupplyByVendorResume: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const { data } = props;

  const defaultText = "---";

  return (
    <Grid item sm={9} xs={12}>
      <Paper sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6">{data.name}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            mb: 1,
            gap: 1,
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <TagOutlinedIcon />
            <Box>
              <Typography>{t(commons.CODE)}</Typography>
              <Typography sx={{ fontWeight: "bold" }}>
                {data.externalCode}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <LocationOnOutlinedIcon />
            <Box>
              <Typography>{t(commons.ADDRESS)}</Typography>
              <Typography sx={{ fontWeight: "bold" }}>
                {data.address || defaultText}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <PhoneIphoneOutlinedIcon />
            <Box>
              <Typography>{t(commons.PHONE)}</Typography>
              <Typography sx={{ fontWeight: "bold" }}>
                {data.phone || defaultText}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <PaymentsOutlinedIcon />
            <Box>
              <Typography>{t(vendor.PAYMENT_DAYS)}</Typography>
              <Typography sx={{ fontWeight: "bold" }}>
                {data.paymentDays || defaultText}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

export default VendorSupplyByVendorResume;
