import {
  PaginationParams,
  paginationParamsToObjectParams,
} from "app/utils/pagination";
import { AppConfig } from "config/app";
import { ProductionScannedtResponse } from "core/productions/entities/Productions";
import {
  ArchiveLinesPayload,
  ExtraItemRequest,
  PackingKitchen,
  PaginatedMadeTransferResponse,
  PaginatedTransferResponse,
  PickingLocationsResponse,
  PickingResponse,
  ReportTransferOrderPayload,
  ResponsePayload,
  TransferOrder,
  TransferOrderResponse,
  UpdateLineQuantityAndReason,
} from "core/transfer/entities/TransferOrder";
import { CanGetPickingLinesByIds } from "core/transfer/interfaces/transfer/CanGetPickingLinesByIds";
import { CanReceiveTransferOrders } from "core/transfer/interfaces/transfer/CanReceiveTransferOrders";
import { CanArchiveLines } from "core/transfer/interfaces/transfer/canArchiveLines";
import { CanArchiveTransferOrder } from "core/transfer/interfaces/transfer/canArchiveTransferOrder";
import { CanCreateTransferOrder } from "core/transfer/interfaces/transfer/canCreateTransferOrder";
import { CanGetMadeTransferOrdersByLocationId } from "core/transfer/interfaces/transfer/canGetMadeTransferOrdersByLocationId";
import { CanGetPackedTransferOrders } from "core/transfer/interfaces/transfer/canGetPackedTransferOrders";
import { CanGetPendingTransferOrdersByLocationId } from "core/transfer/interfaces/transfer/canGetPendingTransferOrdersByLocationId";
import { CanGetTransferOrderPickingItems } from "core/transfer/interfaces/transfer/canGetTransferOrderPickingItems";
import { CanGetTransferOrdersByKitchenId } from "core/transfer/interfaces/transfer/canGetTransferOrdersByKitchenId";
import { CanReportTransferOrder } from "core/transfer/interfaces/transfer/canReportTransferOrder";
import { CanSaveExtraItems } from "core/transfer/interfaces/transfer/canSaveExtraItems";
import { CanSetTransferOrderAsPacked } from "core/transfer/interfaces/transfer/canSetTransferOrderAsPacked";
import { CanSetTransferOrderAsSend } from "core/transfer/interfaces/transfer/canSetTransferOrderAsSended";
import { CanUpdateTransferOrderLineQuantity } from "core/transfer/interfaces/transfer/canUpdateTransferOrderLineQuantity";
import { ApiVersion, getHttp, postHttp } from "utils/http";
import { CanGetTransferOrders } from "../../interfaces/transfer/CanGetTransferOrders";

export class TransferHTTPRepository
  implements
    CanCreateTransferOrder,
    CanGetPendingTransferOrdersByLocationId,
    CanGetTransferOrdersByKitchenId,
    CanReportTransferOrder,
    CanArchiveLines,
    CanUpdateTransferOrderLineQuantity,
    CanGetTransferOrderPickingItems,
    CanGetPickingLinesByIds,
    CanSetTransferOrderAsPacked,
    CanSetTransferOrderAsSend,
    CanGetPackedTransferOrders,
    CanGetTransferOrders,
    CanSaveExtraItems,
    CanArchiveTransferOrder,
    CanGetMadeTransferOrdersByLocationId,
    CanReceiveTransferOrders
{
  constructor(private readonly config: AppConfig) {}

  public async getPendingTransferOrdersByLocationId(
    locationId: string
  ): Promise<Array<TransferOrderResponse>> {
    try {
      const pendingTransferOrders = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `transfer-orders/pending/${locationId}`
      );
      const pendingTransferOrdersJson: Array<TransferOrderResponse> =
        await pendingTransferOrders.json();
      return pendingTransferOrdersJson;
    } catch (err) {
      console.error("getPendingTransferOrdersByLocationId error", err);
      throw err;
    }
  }

  public async getTransferOrdersByKitchenId(
    locationId: string,
    params: PaginationParams
  ): Promise<PaginatedTransferResponse> {
    try {
      const transferOrders = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `transfer-orders/kitchen/${locationId}`,
        paginationParamsToObjectParams(params)
      );
      const transferOrdersJson: PaginatedTransferResponse =
        await transferOrders.json();
      return transferOrdersJson;
    } catch (err) {
      console.error("getTransferOrdersByKitchenId error", err);
      throw err;
    }
  }

  public async getMadeTransferOrdersByLocationId(
    locationId: string,
    params: PaginationParams
  ): Promise<PaginatedMadeTransferResponse> {
    try {
      const madeTransferOrders = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `transfer-orders/made/${locationId}`,
        paginationParamsToObjectParams(params)
      );
      const madeTransferOrdersJson: PaginatedMadeTransferResponse =
        await madeTransferOrders.json();
      return madeTransferOrdersJson;
    } catch (err) {
      console.error("getMadeTransferOrdersByLocationId error", err);
      throw err;
    }
  }

  public async createTransferOrder(
    locationId: string,
    transferOrders: Array<TransferOrder>
  ): Promise<unknown> {
    try {
      const createTransferOrderResponse = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `transfer-orders/${locationId}`,
        transferOrders
      );
      const createTransferOrderJson: any =
        await createTransferOrderResponse.json();
      return createTransferOrderJson;
    } catch (err) {
      console.error("createTransferOrder error", err);
      throw err;
    }
  }

  public async reportTransferOrder(
    payload: ReportTransferOrderPayload
  ): Promise<unknown> {
    try {
      const reportTransferOrderResponse = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `transfer-orders/report`,
        payload
      );
      const reportTransferOrderJson: any =
        await reportTransferOrderResponse.json();
      return reportTransferOrderJson;
    } catch (err) {
      console.error("reportTransferOrder error", err);
      throw err;
    }
  }

  public async archiveLines(payload: ArchiveLinesPayload): Promise<unknown> {
    try {
      const archiveLinesResponse = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `transfer-orders/lines/archive`,
        payload
      );
      const archiveLinesJson: any = await archiveLinesResponse.json();
      return archiveLinesJson;
    } catch (err) {
      console.error("archiveLines error", err);
      throw err;
    }
  }

  public async updateTransferOrderLineQuantity(
    transferLineId: number,
    payload: UpdateLineQuantityAndReason
  ): Promise<unknown> {
    try {
      const updateLineSendQuantityResponse = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `transfer-orders/line/${transferLineId}/update-quantity`,
        {
          quantity: payload.quantity,
          transferOrderStep: payload.transferOrderStep,
          returnReason: payload.returnReason,
        }
      );
      const updateLineSendQuantityJson: any =
        await updateLineSendQuantityResponse.json();
      return updateLineSendQuantityJson;
    } catch (err) {
      console.error("updateTransferOrderLineQuantity error", err);
      throw err;
    }
  }

  public async getTransferOrderPickingItems(
    locationId: string
  ): Promise<PickingResponse> {
    try {
      const transferOrderPickingItemsResponse = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `transfer-orders/picking-items/${locationId}`
      );
      const transferOrderPickingItemsJson: any =
        await transferOrderPickingItemsResponse.json();
      return transferOrderPickingItemsJson;
    } catch (err) {
      console.error("getTransferOrderPickingItems error", err);
      throw err;
    }
  }
  public async getTransferOrderPickingOrders(
    locationId: string
  ): Promise<PackingKitchen[]> {
    try {
      const response = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `transfer-orders/picking-by-kitchen/${locationId}`
      );
      const responseJSON: PackingKitchen[] = await response.json();
      return responseJSON;
    } catch (err) {
      console.error("getTransferOrderPickingOrders error", err);
      throw err;
    }
  }

  public async pickingLinesByIds(
    lines: number[]
  ): Promise<Array<PickingLocationsResponse>> {
    try {
      const pickingLinesResponse = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `transfer-orders/picking-lines/ids/${lines.join(",")}`
      );
      const pickingLinesResponseJson: any = await pickingLinesResponse.json();
      return pickingLinesResponseJson;
    } catch (err) {
      console.error("pickingLinesByIds error", err);
      throw err;
    }
  }

  public async getTransferOrderPackingLogs(
    sku: string,
    transferId: number
  ): Promise<PickingLocationsResponse> {
    try {
      const response = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `transfer-orders/id/${transferId}/item/${sku}`
      );
      const responseJSON: PickingLocationsResponse = await response.json();
      return responseJSON;
    } catch (err) {
      console.error("getTransferOrderPackingLogs error", err);
      throw err;
    }
  }

  public async setTransferOrderAsPacked(locationId: string): Promise<unknown> {
    try {
      const setTransferOrderAsPackedResponse = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `transfer-orders/packed/kitchen/${locationId}`
      );
      const setTransferOrderAsPackedJson: any =
        await setTransferOrderAsPackedResponse.json();
      return setTransferOrderAsPackedJson;
    } catch (err) {
      console.error("setTransferOrderAsPacked error", err);
      throw err;
    }
  }

  public async completeTransfersByKitchen(
    transferId: number
  ): Promise<boolean> {
    try {
      const response = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `transfer-orders/id/${transferId}/packed`
      );
      const responseJson = (await response.json()) as { ok: boolean };
      return responseJson.ok;
    } catch (err) {
      console.error("completeTransfersByKitchen error", err);
      throw err;
    }
  }

  public async getPackedTransferOrders(
    locationId: string
  ): Promise<Array<TransferOrderResponse>> {
    try {
      const getPackedTransferOrdersResponse = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `transfer-orders/packed/${locationId}`
      );
      const getPackedTransferOrdersJson: any =
        await getPackedTransferOrdersResponse.json();
      return getPackedTransferOrdersJson;
    } catch (err) {
      console.error("getPackedTransferOrders error", err);
      throw err;
    }
  }

  public async getTransferOrder(
    transferId: string
  ): Promise<TransferOrderResponse> {
    try {
      const getTransferOrdersResponse = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `transfer-orders/id/${transferId}`
      );
      const getTransferOrdersResponseJson: any =
        await getTransferOrdersResponse.json();
      return getTransferOrdersResponseJson;
    } catch (err) {
      console.error("getTransferOrders error", err);
      throw err;
    }
  }

  public async setTransferOrderAsSend(
    transferOrderId: number,
    numberOfBaskets: number
  ): Promise<ResponsePayload> {
    try {
      const setTransferOrderAsSendResponse = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `transfer-orders/send/id/${transferOrderId}`,
        { numberOfBaskets }
      );
      const setTransferOrderAsSendJson: ResponsePayload =
        await setTransferOrderAsSendResponse.json();
      return setTransferOrderAsSendJson;
    } catch (err) {
      console.error("setTransferOrderAsSend error", err);
      throw err;
    }
  }

  public async saveExtraItems(payload: ExtraItemRequest): Promise<unknown> {
    try {
      const saveExtraItemsResponse = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `transfer-orders/extra-items`,
        payload
      );
      const saveExtraItemsJson: any = await saveExtraItemsResponse.json();
      return saveExtraItemsJson;
    } catch (err) {
      console.error("saveExtraItems error", err);
      throw err;
    }
  }

  public async archiveTransferOrder(transferOrderId: number): Promise<unknown> {
    try {
      const archiveOrder = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `transfer-orders/archive/${transferOrderId}`
      );
      const archiveOrderJson: any = await archiveOrder.json();
      return archiveOrderJson;
    } catch (err) {
      console.error("archiveTransferOrder error", err);
      throw err;
    }
  }

  public async receiveTransferOrder(
    transferOrderId: number,
    haveExtraOrLeftover: boolean,
    comment?: string
  ): Promise<ResponsePayload> {
    try {
      const response = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `transfer-orders/id/${transferOrderId}/receive`,
        {
          haveExtraOrLeftover,
          comment,
        }
      );
      const jsonResponse: ResponsePayload = await response.json();
      return jsonResponse;
    } catch (err) {
      console.error("receiveTransferOrder error", err);
      throw err;
    }
  }

  public async saveScannedTransfer(
    transferOrderId: number,
    productionLabelId: string
  ): Promise<ProductionScannedtResponse> {
    try {
      const response = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `transfer-orders/by-barcode-scanner/save`,
        {
          transferOrderId,
          productionLabelId,
        }
      );
      const responseJson: ProductionScannedtResponse = await response.json();
      return responseJson;
    } catch (err) {
      console.error("saveScannedTransfer error", err);
      throw err;
    }
  }

  public async existsLabelLog(
    originId: string,
    productionLabelId: string
  ): Promise<ProductionScannedtResponse> {
    try {
      const response = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `transfer-orders/by-barcode-scanner/validate`,
        {
          originId,
          productionLabelId,
        }
      );
      const responseJson: ProductionScannedtResponse = await response.json();
      return responseJson;
    } catch (err) {
      console.error("existsLabelLog error", err);
      throw err;
    }
  }

  public async removeLabelLog(
    deleteLabelsIds: number[],
    originId: string
  ): Promise<boolean> {
    try {
      const response = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `transfer-orders/by-barcode-scanner/remove`,
        {
          deleteLabelsIds,
          originId,
        }
      );
      const responseJson: boolean = (await response.text()) === "true";
      return responseJson;
    } catch (err) {
      console.error("removeLabelLog error", err);
      throw err;
    }
  }
}
