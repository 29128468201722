import * as Sentry from "@sentry/react";

export default () => {
  Sentry.init({
    environment: process.env.REACT_APP_ENV,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: Number(process.env.REACT_APP_SENTRY_SAMPLING),
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};
