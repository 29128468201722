import { UseCase } from "core/common/interfaces/useCase";
import { CanGetComparisonByCity } from "../interfaces/canGetComparisonByCity";

export class GetComparisonByCityUseCase implements UseCase {
  constructor(private readonly repository: CanGetComparisonByCity) {}

  public execute(city: string) {
    return this.repository.getComparisonByCity(city);
  }
}
