/* eslint-disable react/no-array-index-key */
import { Box, DialogTitle, Divider, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { purchaseOrderModule } from "app/i18n/types";
import { AttachmentFile } from "core/attachment/entity";
import { AttachmentsDetailResponse } from "core/purchaseOrders/entities/ReceiptOrder";
import React from "react";
import { useTranslation } from "react-i18next";
import AddDocumentsDialogContent from "./AddDocumentsDialogContent";

interface Props {
  code: string;
  origin: string;
  type: string;
  initialAttachments?: AttachmentFile[];
  documentNo?: string;
  showDocumentNo?: boolean;
  kitchenId: string;
  noClose?: boolean;
  setDetail?: (detail: AttachmentsDetailResponse) => void;
  onCloseDialog: () => void;
}

const AddDocumentsDialog = (props: Props): React.ReactElement => {
  const {
    onCloseDialog,
    initialAttachments,
    setDetail,
    noClose,
    kitchenId,
    code,
    origin,
    type,
    documentNo,
    showDocumentNo,
  } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open
      onClose={onCloseDialog}
      disableEscapeKeyDown={!!noClose}
    >
      <Box>
        <DialogTitle>
          <Typography variant="h5">
            {t(purchaseOrderModule.ADD_DOCUMENTS)}
          </Typography>
        </DialogTitle>
        <Divider variant="fullWidth" />
        <AddDocumentsDialogContent
          onCloseDialog={onCloseDialog}
          initialAttachments={initialAttachments}
          setDetail={setDetail}
          kitchenId={kitchenId}
          origin={origin}
          type={type}
          code={code}
          documentNo={documentNo ?? ""}
          showDocumentNo={showDocumentNo}
        />
      </Box>
    </Dialog>
  );
};

export default AddDocumentsDialog;
