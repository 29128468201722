import { useAppSelector } from "app/hooks/useAppSelector";
import appConfig from "config/app";
import React, { useEffect } from "react";

const WidgetTorre = (): React.ReactElement => {
  const user = useAppSelector((state) => state.session.user.data);
  const selectedKitchen = useAppSelector(
    (state) => state.global.selectedKitchen
  );
  const isVisibleWidgetTT = process.env.REACT_APP_VIEW_WIDGET_TT;

  const [userTickets, setUserTickets] = React.useState<any>(null);
  const [kitchenTickets, setKitchenTickets] = React.useState<any>(null);
  const [environment, setEnvironment] = React.useState<string>("stg");

  useEffect(() => {
    if (selectedKitchen && user) {
      setKitchenTickets({
        id: selectedKitchen.kitchenId,
        name: selectedKitchen.name,
      });
      setUserTickets({
        id: user?._id,
        name: user?.profile.name,
        roles: [user?.role],
        country: selectedKitchen.country,
      });
      if (appConfig.env === "production") {
        setEnvironment("prod");
      } else {
        setEnvironment("stg");
      }
    }
  }, [user, selectedKitchen]);

  return (
    <>
      {selectedKitchen &&
        userTickets &&
        kitchenTickets &&
        isVisibleWidgetTT === "true" && (
          <widget-tt
            env={environment}
            kitchen={JSON.stringify(kitchenTickets)}
            user={JSON.stringify(userTickets)}
          ></widget-tt>
        )}
    </>
  );
};

export default WidgetTorre;
