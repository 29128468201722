import { vendor } from "app/i18n/types";
import {
  NewIndividualPurchaseOrderLine,
  PurchaseOrderLine,
} from "core/purchaseOrders/entities/PurchaseOrder";
import { TFunction } from "i18next";
import { formatPrice, localeData } from "utils/currency";

export interface PriceRange {
  min: number;
  max: number;
}

export type PriceRangeConfig = {
  [key in string]: PriceRange;
};

export interface SupplyToValidate {
  id: number;
  sku: string;
  price: number;
  measureUnit: string;
  vendorSupplyId?: number;
  supplyPrice?: number;
  vendorPrice?: number;
  vendorUnit?: string;
}

export interface VendorSupplyToValidate {
  id: number;
  sku: string;
  unit: string;
  price: number;
}

export const newPurchaseOrderLineToSupplyToValidate = (
  purchase: NewIndividualPurchaseOrderLine
): SupplyToValidate[] => {
  return [
    {
      id: -1,
      sku: purchase.sku,
      price: purchase.unitPrice,
      measureUnit: purchase.measureUnit,
    },
  ];
};

export const purchaseOrderLineToSupplyToValidate = (
  lines: PurchaseOrderLine[]
): SupplyToValidate[] => {
  return lines.map((line) => ({
    id: line.id,
    sku: line.sku,
    price: line.unitPrice,
    measureUnit: line.measureUnit,
    vendorSupplyId: line.vendorSupplyId,
    supplyPrice: line.supplyUnitPrice,
    vendorPrice: line.supplyVendorPrice,
    vendorUnit: line.supplyVendorUnit,
  }));
};

export interface ValidationItemResponse {
  id: number;
  longMessage: string;
  shortMessage: string;
  shortMessage2: string;
}

export const getValidation = (
  supplyToValidate: SupplyToValidate[],
  vendorSupplies: VendorSupplyToValidate[],
  config: PriceRange,
  country: string,
  t: TFunction
): ValidationItemResponse[] => {
  if (!supplyToValidate.length) return [];
  const { min, max } = config;
  const result: ValidationItemResponse[] = [];
  supplyToValidate.forEach((current) => {
    const { id, price: quantity } = current;

    const supply = vendorSupplies.find((el) =>
      current.vendorSupplyId
        ? current.vendorSupplyId === el.id
        : current.sku === el.sku
    );
    if (!supply) return;

    const { price, sku } = supply;

    const maxPrice = (price * (100 + max)) / 100;
    const minPrice = (price * (100 + min)) / 100;
    if (minPrice <= quantity && quantity <= maxPrice) return;
    const locale = localeData[country];
    result.push({
      id,
      longMessage: t(vendor.SUPPLY_VALIDATOR_LABEL_LONG, {
        price: formatPrice(quantity, locale),
        sku,
        maxPrice: formatPrice(maxPrice, locale),
        minPrice: formatPrice(minPrice, locale),
        vendorPrice: formatPrice(price, locale),
      }),
      shortMessage: t(vendor.SUPPLY_VALIDATOR_LABEL_SHORT, {
        maxPrice: formatPrice(maxPrice, locale),
        minPrice: formatPrice(minPrice, locale),
        vendorPrice: formatPrice(price, locale),
      }),
      shortMessage2: t(vendor.SUPPLY_VALIDATOR_LABEL_SHORT_2, {
        vendorPrice: formatPrice(price, locale),
      }),
    });
  });
  return result;
};
