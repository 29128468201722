import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import { Box, Button, TextField } from "@mui/material";
import { commentArea, commons } from "app/i18n/types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  onSuccess: (commentId: number, content: string) => void;
  commentId: number;
  inDrawer?: boolean;
}

const AnswerForm = (props: Props) => {
  const { onSuccess, commentId, inDrawer } = props;
  const { t } = useTranslation();
  const [answer, setAnswer] = useState("");

  const onSubmit = (event: any) => {
    event.preventDefault();
    if (answer) {
      onSuccess(commentId, answer);
      setAnswer("");
    }
  };

  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      sx={{ display: inDrawer ? undefined : "flex", gap: 1, mb: 2 }}
    >
      <TextField
        label={t(commentArea.ANSWER_LABEL)}
        fullWidth
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
        multiline
        maxRows={inDrawer ? 6 : 3}
      />
      <Button
        type="submit"
        variant="outlined"
        color="primary"
        fullWidth={inDrawer}
        sx={{ height: "auto", mt: inDrawer ? 1 : undefined }}
        endIcon={inDrawer ? <KeyboardArrowRightOutlinedIcon /> : <></>}
      >
        {inDrawer ? t(commons.SEND) : <ArrowForwardIosOutlinedIcon />}
      </Button>
    </Box>
  );
};

export default AnswerForm;
