import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { physicalCountSchedule } from "app/i18n/types";
import { FunctionComponent, memo } from "react";
import { useTranslation } from "react-i18next";
import { sortStringOfDate, tableHeaderSX } from "utils/general";

interface DateStepTableProps {
  dates: string[];
  removeDate: (index: number) => void;
  size?: "small" | "medium";
}

const DateStepTable: FunctionComponent<DateStepTableProps> = (props) => {
  const { dates, removeDate, size } = props;

  const { t } = useTranslation();
  const datesTmp = [...dates];
  datesTmp.sort(sortStringOfDate);

  return (
    <TableContainer component={Paper} sx={{ mt: 1 }}>
      <Table>
        <TableHead>
          <TableRow sx={tableHeaderSX}>
            <TableCell align="center" sx={{ textTransform: "uppercase" }}>
              NO.
            </TableCell>
            <TableCell sx={{ textTransform: "uppercase" }}>
              {t(physicalCountSchedule.DATE)}
            </TableCell>
            <TableCell align="center" sx={{ textTransform: "uppercase" }}>
              {t(physicalCountSchedule.ACTIONS)}
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {!datesTmp.length && (
            <TableRow>
              <TableCell
                align="center"
                colSpan={3}
                sx={{ textTransform: "uppercase" }}
              >
                {t(physicalCountSchedule.STEP_3_TABLE_EMPTY)}
              </TableCell>
            </TableRow>
          )}
          {datesTmp.map((date, index) => (
            <TableRow key={`DateRow${index}`}>
              <TableCell align="center" sx={{ textTransform: "uppercase" }}>
                {index + 1}
              </TableCell>
              <TableCell>{date}</TableCell>
              <TableCell align="center">
                <Button
                  onClick={() => removeDate(index)}
                  variant="outlined"
                  color="error"
                  size={size}
                  sx={{ height: "100%" }}
                >
                  <DeleteOutlineOutlinedIcon />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(DateStepTable);
