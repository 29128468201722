import { convertDateUtc } from "@foodology-co/alejandria";
import appConfig, { AppConfig } from "config/app";
import { AttachmentFile } from "core/attachment/entity";
import { LinesPurchaseOrder } from "core/linesPurchaseOrders/entities/LinesPurchaseOrder";
import { InventoryResponse } from "core/physicalCount/entities/Counting";
import { ModifyPayload } from "core/purchaseOrders/entities/PurchaseOrder";
import {
  AttachmentSavePayload,
  ReceiptOrder,
  ReceivedOrder,
} from "core/purchaseOrders/entities/ReceiptOrder";
import { canDeleteReceiptOrder } from "core/purchaseOrders/traits/receiptOrders/canDeleteReceiptOrder";
import { canGetReceiptOrdersForKitchen } from "core/purchaseOrders/traits/receiptOrders/canGetReceiptOrdersForKitchen";
import { CanMarkOrderAsRecived } from "core/purchaseOrders/traits/receiptOrders/canMarkOrderAsRecived";
import { ApiVersion, getHttp, postHttp } from "utils/http";

export class PurchaseOrdersHTTPRepository
  implements
    canGetReceiptOrdersForKitchen,
    canDeleteReceiptOrder,
    CanMarkOrderAsRecived
{
  constructor(private readonly config: AppConfig) {}

  public async updateLinesQuantityAndUnitPrice(
    payload: ModifyPayload
  ): Promise<boolean> {
    const response = await postHttp(
      this.config.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `purchase-order/modify`,
      payload
    );
    const result = (await response.text()) === "true";
    return result;
  }

  public async deletePurchaseOrderReceipt(
    kitchenId: string,
    receiptNo: string
  ): Promise<InventoryResponse> {
    const response = await postHttp(
      this.config.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `receipts/delete`,
      {
        receiptNo: receiptNo,
        kitchenId: kitchenId,
      }
    );
    return await response.json();
  }

  public async markPurchaseOrderAsReceived(
    purchaseOrderId: number
  ): Promise<InventoryResponse> {
    const response = await postHttp(
      this.config.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `purchase-order/mark-received/${purchaseOrderId}`
    );
    return await response.json();
  }

  public async getReceiptOrdersForKitchen(
    kitchenId: string
  ): Promise<ReceiptOrder[]> {
    try {
      const receiptsOrders = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `receipts/orders/${kitchenId}`
      );
      return receiptsOrders.json();
    } catch (err) {
      console.warn("err", err);
      throw err;
    }
  }

  public async getReceiptOrderByReceiptNo(
    receiptNo: string,
    origin: string,
    kitchenId: string
  ): Promise<ReceiptOrder | null> {
    try {
      const receiptsOrders = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `receipt/order/${receiptNo}/${origin}/${kitchenId}`
      );
      return receiptsOrders.json();
    } catch (err) {
      console.warn("err", err);
      throw err;
    }
  }

  public async saveReceiptAttachments(
    data: AttachmentSavePayload
  ): Promise<AttachmentFile[]> {
    try {
      const receiptsOrders = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `receipt/attachments/save`,
        data
      );
      return receiptsOrders.json();
    } catch (err) {
      console.warn("err", err);
      throw err;
    }
  }
}

export const receivedOrders = async (
  kitchenId: string,
  startDate: Date,
  endDate: Date
): Promise<ReceivedOrder[]> => {
  try {
    const orders = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `receipts/received/orders/${kitchenId}`,
      {
        startDate: convertDateUtc(startDate, "dd/MM/YYY"),
        endDate: convertDateUtc(endDate, "dd/MM/YYY"),
      }
    );
    return orders.json();
  } catch (err) {
    console.warn("err", err);
    throw err;
  }
};

export const transferReceiptLines = async (
  transferId: number
): Promise<LinesPurchaseOrder[]> => {
  try {
    const linesPurchaseOrdersResponse = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `receipts/transfer/${transferId}/lines`
    );
    const linesPurchaseOrdersJson: Array<LinesPurchaseOrder> =
      await linesPurchaseOrdersResponse.json();
    return linesPurchaseOrdersJson;
  } catch (err) {
    console.error("Error in transferReceiptLines", err);
    throw err;
  }
};
