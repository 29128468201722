import { KOSTopbar, Notification } from "@foodology-co/alejandria";
import { HermesNotification } from "@foodology-co/alejandria/dist/components/molecules/Notification";
import { Box } from "@mui/material";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import useNotifications from "app/hooks/useNotifications";
import { setSearchOnTopBar } from "app/store/slices/global";
import { toggleMenu } from "app/store/slices/menu";
import appConfig from "config/app";
import React from "react";
import SearchField from "../TopBarSearch/SearchField";

const TopBar = () => {
  const dispatch = useAppDispatch();
  const menuOpen = useAppSelector((state) => state.menu.open);
  const user = useAppSelector((state) => state.session.user.data);
  const title = useAppSelector((state) => state.global.title);
  const canSearchOnTopBar = useAppSelector(
    (state) => state.global.canSearchOnTopBar
  );

  const [flexRightArea, setFlexRightArea] = React.useState(1);

  const {
    notifications,
    legologyHermesInstance,
    refreshNotifications,
    verifyNotificationPerms,
  } = useNotifications();

  const toggle = () => {
    dispatch(toggleMenu());
  };

  const isProduction = appConfig.env === "production";

  const handleClickNotificationButton = (isNotificationsOff: boolean) => {
    refreshNotifications();
  };

  const handleClickNotification = (notification?: HermesNotification) => {
    verifyNotificationPerms();
  };

  const onSearch = (textParam: string) =>
    dispatch(setSearchOnTopBar(textParam));
  const searchText = useAppSelector((state) => state.global.textSearchOnTopBar);

  return (
    <KOSTopbar
      title={title ?? ""}
      flexRightArea={flexRightArea}
      onOpenMenuClick={toggle}
      isDevelopment={!isProduction}
      helpCenterLink="https://foodology.notion.site/Centro-de-ayuda-para-cocinas-f49c4322877e428f907652f9b3d2783c"
      openMenu={menuOpen}
      searchComponent={
        <Box sx={{ display: "flex" }}>
          {canSearchOnTopBar && (
            <SearchField
              collapsible
              setFlexRightArea={setFlexRightArea}
              onSearch={onSearch}
              searchText={searchText}
            />
          )}
          <Notification
            noPerms={!legologyHermesInstance.accessGranted}
            notifications={notifications as HermesNotification[]}
            onClick={handleClickNotificationButton}
            onNotificationClick={handleClickNotification}
          />
        </Box>
      }
      username={user?.profile?.name}
      avatarUrl={user?.profileImage}
    />
  );
};

export default TopBar;
