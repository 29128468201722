import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { commons, purchaseOrderLine } from "app/i18n/types";
import { updatePurchaseOrderLine } from "app/store/slices/purchase/lines.slice";
import { LinesPurchaseOrder } from "core/linesPurchaseOrders/entities/LinesPurchaseOrder";
import { updateLineReceivedQuantity } from "core/purchaseOrders/repositories/http/purchase";
import { FunctionComponent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import NumberField from "../common/Field/Number";

interface Props {
  item: LinesPurchaseOrder;
  kitchenId: string;
  orderId: string;
  origin: string;
  autoSave?: boolean;
  setQuantityReceive?: (id: number, quantityReceive: number) => void;
  inReview?: boolean;
}

const UpdateRecivedQuantityTextField: FunctionComponent<Props> = (props) => {
  const {
    item,
    kitchenId,
    orderId,
    origin,
    setQuantityReceive,
    autoSave,
    inReview,
  } = props;
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const linesStatus = useAppSelector(
    (state) => state.linesPurchase.linesStatus
  );

  const [error, setError] = useState<string>("");

  const getHelperText = useCallback((): string | null => {
    if (error) {
      return error;
    }

    if (linesStatus?.[item.lineId]) {
      return linesStatus[item.lineId].error;
    }

    return null;
  }, [item.lineId, linesStatus, error]);

  const save = (lineId: number, quantityReceived: number) => {
    if (setQuantityReceive) {
      setQuantityReceive(lineId, quantityReceived);
    }
  };

  const updateItemQuantity = useCallback(
    (quantityReceived: number, item: LinesPurchaseOrder) => {
      if (kitchenId && orderId) {
        setError("");
        const maxQuantity = (item.quantity * 200) / 100;
        if (quantityReceived > maxQuantity) {
          setError(
            t(purchaseOrderLine.UPDATE_RECEIVED_QUANTITY_ERROR, {
              num: (quantityReceived - item.quantity).toFixed(0),
            })
          );
        }

        if (
          quantityReceived === item.quantityReceived &&
          quantityReceived > 0
        ) {
          return;
        }

        if (autoSave) {
          inReview
            ? updateLineReceivedQuantity(item.lineId, quantityReceived)
            : dispatch(
                updatePurchaseOrderLine({
                  id: item.lineId,
                  lineNo: item.lineNo,
                  kitchenId,
                  sku: item.sku,
                  quantityReceived: quantityReceived,
                  status: "IN_PROCESS",
                  receiptNo: orderId,
                  userId: "",
                  externalCode: item.sourceId,
                  origin: origin ?? "",
                })
              );
        }

        save(item.lineId, quantityReceived);
      }
    },
    [dispatch, kitchenId, orderId]
  );

  return (
    <NumberField
      value={item.quantityReceived}
      onChangeValue={(newValue) => {
        updateItemQuantity(newValue, item);
      }}
      size="small"
      label={t(
        item.status === "IDLE"
          ? commons.ENTER_QUANTITY
          : commons.RECIVED_QUANTITY
      )}
      color={error ? "warning" : "primary"}
      helperText={getHelperText()}
      FormHelperTextProps={{
        sx: {
          color: "orangered",
        },
      }}
      focused={!!error}
      disabled={
        (linesStatus && linesStatus[item.lineId]?.status === "loading") ||
        !!item.labels.length
      }
    />
  );
};

export default UpdateRecivedQuantityTextField;
