import { UseCase } from "core/common/interfaces/useCase";
import { ProductionApprovementResult } from "../entities/Productions";
import { CanLiberateProduction } from "../interfaces/canLiberateProduction";

export class LiberateProduction implements UseCase {
  constructor(private readonly repository: CanLiberateProduction) {}

  public execute(
    productionId: string,
    result: ProductionApprovementResult,
    novelty: string
  ) {
    return this.repository.liberateProduction(productionId, result, novelty);
  }
}
