import {
  ProductionLabel,
  ProductionScannedtResponse,
} from "core/productions/entities/Productions";

export const getLabelFromResponse = (
  response: ProductionScannedtResponse
): ProductionLabel => {
  const { labelId, sku, quantity, productName, unit } = response;

  return {
    id: labelId,
    quantity,
    name: productName,
    unit,
    batch: "",
    sku,
    productionDate: "",
    expirationDate: "",
    createdAt: new Date().toString(),
  } as ProductionLabel;
};

export const addNewLabelFromResponse = (
  labels: ProductionLabel[],
  response: ProductionScannedtResponse
) => {
  const newLabel = getLabelFromResponse(response);
  labels.push(newLabel);
};

export interface TransferPayload {
  lineId: number;
  productionLabelId: number;
  quantity: number;
  unit: string;
  productName: string;
  sum: number;
}

export const getResponseFromTransferPayload = (
  payload: TransferPayload
): ProductionScannedtResponse => {
  const {
    productionLabelId: labelId,
    quantity,
    unit,
    productName,
    sum,
  } = payload;

  return {
    sum,
    quantity,
    ok: true,
    sku: "",
    reasonId: 0,
    unit,
    productName,
    labelId,
  } as ProductionScannedtResponse;
};

export const addNewLabelFromTransferAction = (
  labels: ProductionLabel[],
  payload: TransferPayload
) => {
  const response = getResponseFromTransferPayload(payload);
  addNewLabelFromResponse(labels, response);
};
