import { Supply } from "core/supplies/entities/Supply";
import { Vendor } from "core/vendors/entities/Vendor";
import { VendorSupply } from "core/vendors/entities/VendorSupply";

export enum Actions {
  add = "ADD",
  edit = "EDIT",
  view = "VENDOR_INFO",
}

export interface BaseModal {
  open: boolean;
  action?: Actions;
}

export interface VendorSupplyModal extends BaseModal {
  item?: Partial<VendorSupply>;
  supplies?: Supply[];
  country?: string;
}

export interface VendorModal extends BaseModal {
  itemId?: number;
  item?: Vendor;
}
