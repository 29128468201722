import {
  convertDateUtc,
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSEmptyState,
  KOSRowData,
} from "@foodology-co/alejandria";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PersonIcon from "@mui/icons-material/Person";
import TagOutlinedIcon from "@mui/icons-material/TagOutlined";

import { Box, Chip, Paper, Typography } from "@mui/material";
import FullDialog from "app/components/common/FullDialog";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { commons, receiptType, transferModule } from "app/i18n/types";
import { getLinesPurchase } from "app/store/slices/purchase/lines.slice";
import { LinesPurchaseOrder } from "core/linesPurchaseOrders/entities/LinesPurchaseOrder";
import { ReceivedOrder } from "core/purchaseOrders/entities/ReceiptOrder";
import { transferReceiptLines } from "core/purchaseOrders/repositories/http/purchaseOrders";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { flexStyleBox } from "utils/generalUI";

interface Props {
  kitchenId: string;
  orderId: number;
  onClose: () => void;
  open: boolean;
  receiptOrder: ReceivedOrder;
}

export const ReceiptReceivedLinesModal: FunctionComponent<Props> = (props) => {
  const { kitchenId, orderId, onClose, open, receiptOrder } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<LinesPurchaseOrder[]>([]);

  const getData = () => {
    if (!kitchenId || !receiptOrder) return;
    setLoading(true);
    if (receiptOrder.type === "PURCHASE_ORDER") {
      findPurchaseLines();
    } else {
      findTransferLines();
    }
  };

  const findPurchaseLines = () => {
    dispatch(
      getLinesPurchase({
        kitchenId,
        orderId: orderId.toString(),
        origin: "KIS",
      })
    )
      .then((response) => {
        setData(response.payload as LinesPurchaseOrder[]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const findTransferLines = () => {
    transferReceiptLines(orderId)
      .then((response) => {
        setData(response);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, [kitchenId, orderId]);

  const getHeaders = (): KOSBaseTableHeader[] => {
    const statusChip = (item: KOSRowData) => {
      const leftQuantity = item.quantity - item.quantityReceived;
      if (leftQuantity > 0) {
        return (
          <Chip
            icon={<InfoOutlinedIcon />}
            label={t(commons.PARTIAL)}
            color="warning"
          />
        );
      } else {
        return (
          <Chip
            icon={<InfoOutlinedIcon />}
            label={t(commons.COMPLETE)}
            color="success"
          />
        );
      }
    };
    return [
      {
        label: t(commons.SKU),
        field: "sku",
      },
      {
        label: t(commons.NAME),
        field: "description",
      },
      {
        label: t(commons.REQUESTED),
        field: "quantity",
      },
      {
        label: t(commons.RECEIVED),
        field: "quantityReceived",
      },
      {
        label: t(commons.UNIT),
        field: "measureUnit",
      },
      {
        label: t(commons.STATUS),
        component: statusChip,
      },
    ];
  };

  return (
    <FullDialog
      open={open}
      onClose={onClose}
      title={t(receiptType.DETAIL_TITLE)}
      content={
        <>
          <Paper
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              p: 2,
              mx: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={flexStyleBox}>
                <TagOutlinedIcon />
                <Box>
                  <Typography>{t(commons.CODE)}</Typography>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {receiptOrder.code}
                  </Typography>
                </Box>
              </Box>

              <Box sx={flexStyleBox}>
                <ContentPasteOutlinedIcon />
                <Box>
                  <Typography>{t(commons.TYPE)}</Typography>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {t("commons.receiptType." + receiptOrder.type)}
                  </Typography>
                </Box>
              </Box>

              <Box sx={flexStyleBox}>
                <CalendarMonthOutlinedIcon />
                <Box>
                  <Typography>{t(transferModule.RECEIVED_AT)}</Typography>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {receiptOrder.receivedAt &&
                      convertDateUtc(
                        receiptOrder.receivedAt,
                        "MM/dd/yyyy hh:mm a"
                      )}
                  </Typography>
                </Box>
              </Box>

              <Box sx={flexStyleBox}>
                <PersonIcon />
                <Box>
                  <Typography>{t(transferModule.RECEIVED_BY)}</Typography>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {receiptOrder.receivedById
                      ? receiptOrder.receivedByName
                      : "--"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Paper>
          <Paper
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              mx: 2,
              mt: 1,
            }}
          >
            <KOSBaseTable
              columns={getHeaders()}
              rows={{
                data: data ?? [],
                total: data.length,
                loading,
              }}
              pagination={{
                enable: false,
              }}
              emptyState={
                <KOSEmptyState
                  icon={InfoOutlinedIcon}
                  message={t(commons.TABLE_EMPTY)}
                />
              }
            />
          </Paper>
        </>
      }
    />
  );
};
