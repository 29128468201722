import { UseCase } from "core/common/interfaces/useCase";
import { CanStartProduction } from "../interfaces/canStartProduction";

export class StartProduction implements UseCase {
  constructor(private readonly repository: CanStartProduction) {}

  public execute(productionId: string) {
    return this.repository.startProduction(productionId);
  }
}
