import { StoreRequestError, StoreRequestStatus } from "app/store/types";
import { AdjustmentFromStore } from "core/dailyAdjustments/entities/Adjustment";
import { ProductRequest } from "core/dailyAdjustments/entities/Catalog";
import { ReportResponse } from "core/dailyAdjustments/entities/Report";

export interface LossesState {
  kitchenId: string | null;
  dailyAdjustments: Array<ProductRequest> | [];
  forReview: {
    data: AdjustmentFromStore[];
    status: StoreRequestStatus;
    error: StoreRequestError;
  };
  forValidation: {
    data: AdjustmentFromStore[];
    status: StoreRequestStatus;
    error: StoreRequestError;
  };
  authorization: {
    status: StoreRequestStatus;
    error: StoreRequestError;
  };
  archiving: {
    status: StoreRequestStatus;
    error: StoreRequestError;
  };
  createDailyReport: {
    status: StoreRequestStatus;
    error: StoreRequestError;
    data: ReportResponse | null;
  };
  filters: { group: Array<string>; search: string | null };
  status: StoreRequestStatus;
  error: StoreRequestError;
}

export const initialState: LossesState = {
  kitchenId: null,
  dailyAdjustments: [],
  forReview: {
    data: [],
    status: "idle",
    error: null,
  },
  forValidation: {
    data: [],
    status: "idle",
    error: null,
  },
  authorization: {
    status: "idle",
    error: null,
  },
  archiving: {
    status: "idle",
    error: null,
  },
  filters: {
    group: [],
    search: null,
  },
  status: "idle",
  error: null,
  createDailyReport: {
    status: "idle",
    error: null,
    data: null,
  },
};
