import { Grid } from "@mui/material";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import { commons, purchaseOrderCreation } from "app/i18n/types";
import { PurchaseOrder } from "core/purchaseOrders/entities/PurchaseOrder";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { ManagerDialog } from "../common/Manager/Dialog";
import { ManagerDialogFooter } from "../common/Manager/Dialog/Footer";
import PurchaseOrderInfoHeader from "./PurchaseOrderInfoHeader";

interface Props {
  purchaseOrder: PurchaseOrder;
  onClose: () => void;
  updatePurchaseEstimatedDate: (id: number, date: string) => void;
}

const UpdateEstimateDateModal: FunctionComponent<Props> = (props) => {
  const { purchaseOrder, onClose, updatePurchaseEstimatedDate } = props;

  const { t } = useTranslation();

  return (
    <ManagerDialog
      title={purchaseOrderCreation.UPDATE_ORDER_MODAL_TITLE}
      onClose={onClose}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PurchaseOrderInfoHeader purchaseOrder={purchaseOrder} />
          </Grid>
          <Grid item xs={12}>
            <Alert severity="info">
              {t(purchaseOrderCreation.UPDATE_ORDER_MODAL_INFO)}
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="date"
              label={t(purchaseOrderCreation.ESTIMATED_DATE_LABEL)}
              value={purchaseOrder.expectedReceiptAt}
              onChange={(e) =>
                updatePurchaseEstimatedDate(purchaseOrder.id, e.target.value)
              }
              autoComplete="off"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <ManagerDialogFooter
              mainButton={{
                children: t(commons.UPDATE),
                onClick: onClose,
              }}
              onCancel={onClose}
              loading={false}
            />
          </Grid>
        </Grid>
      }
    />
  );
};
export default UpdateEstimateDateModal;
