import { createSlice } from "@reduxjs/toolkit";
import { createCounting } from "../counting/thunks";

import { initialState } from "./state";
import { getCountingHistory, getDetailsByCounting } from "./thunks";

export const countingHistoryReducer = createSlice({
  name: "countingHistory",
  initialState,
  reducers: {
    clearCountingHistoryState(state) {
      state.newCountingId = null;
      state.status = "idle";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCountingHistory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCountingHistory.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.countings = action.payload.countingHistory;
      })
      .addCase(getCountingHistory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ?? null;
      })
      .addCase(createCounting.pending, (state, action) => {
        state.countingStatus = "loading";
        state.error = null;
      })
      .addCase(createCounting.fulfilled, (state, action) => {
        state.countingStatus = "succeeded";
        state.newCountingId = action.payload.id;
      })
      .addCase(createCounting.rejected, (state, action) => {
        state.countingStatus = "failed";
      })
      .addCase(getDetailsByCounting.pending, (state, action) => {
        state.detailsByCountingRequest.status = "loading";
        state.error = null;
      })
      .addCase(getDetailsByCounting.fulfilled, (state, action) => {
        state.detailsByCountingRequest.status = "succeeded";
        state.detailsByCountingRequest.accuracy = action.payload.accuracy;
        state.detailsByCountingRequest.data = action.payload.details.map(
          (item) => {
            return {
              sku: item.sku,
              difference: item.quantity - item.theoreticalQuantity,
              group: item.productGroup,
              productName: item.productName,
              realQuantity: item.quantity,
              theoreticalQuantity: item.theoreticalQuantity,
              unit: item.unit,
            };
          }
        );
      })
      .addCase(getDetailsByCounting.rejected, (state, action) => {
        state.detailsByCountingRequest.status = "failed";
        state.detailsByCountingRequest.error = action.error.message ?? null;
      });
  },
});

export const { clearCountingHistoryState } = countingHistoryReducer.actions;

export const { reducer } = countingHistoryReducer;

export default countingHistoryReducer;
