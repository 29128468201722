import { AppConfig } from "config/app";
import { LinesPurchaseOrder } from "core/linesPurchaseOrders/entities/LinesPurchaseOrder";
import { canCompletePurchaseOrderTrait } from "core/linesPurchaseOrders/traits/linesPurchaseOrder/canCompletePurchaseOrder";
import { canGetLinesPurchaseOrdersTrait } from "core/linesPurchaseOrders/traits/linesPurchaseOrder/canGetLinesPurchaseOrders";
import { canUpdatePurchaseOrderLineTrait } from "core/linesPurchaseOrders/traits/linesPurchaseOrder/canUpdatePurchaseOrderLineTrait";
import { InventoryResponse } from "core/physicalCount/entities/Counting";
import { ProductionScannedtResponse } from "core/productions/entities/Productions";
import { ApiVersion, getHttp, postHttp } from "utils/http";

export class LinesPurchaseOrdersHTTPRepository
  implements
    canGetLinesPurchaseOrdersTrait,
    canUpdatePurchaseOrderLineTrait,
    canCompletePurchaseOrderTrait
{
  constructor(private readonly config: AppConfig) {}
  public async completePurchaseOrder(
    kitchenId: string,
    orderId: string,
    invoiceNumber: string,
    originId: string,
    originBD: string
  ): Promise<InventoryResponse> {
    const completePurchaseOrderResponse = await postHttp(
      this.config.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `receipts/kitchenId/${kitchenId}/complete`,
      {
        receiptNo: orderId,
        invoiceNo: invoiceNumber,
        userId: "",
        originId,
        originBD,
      }
    );
    const response = await completePurchaseOrderResponse.json();
    return response;
  }

  public async getLinesPurchaseOrders(
    kitchenId: string,
    orderId: string,
    origin: string
  ): Promise<LinesPurchaseOrder[]> {
    try {
      const linesPurchaseOrdersResponse = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `receipts/lines/kitchen/${kitchenId}/order/${orderId}/${origin}`
      );
      const linesPurchaseOrdersJson: Array<LinesPurchaseOrder> =
        await linesPurchaseOrdersResponse.json();
      return linesPurchaseOrdersJson;
    } catch (err) {
      console.error("Error in getLinesPurchaseOrders", err);
      throw err;
    }
  }

  public async updatePurchaseOrderLine(
    id: number,
    lineNo: number,
    kitchenId: string,
    sku: string,
    quantityReceived: number,
    status: "REJECTED" | null,
    receiptNo: string,
    userId: string,
    externalCode: string,
    origin: string
  ) {
    const updatePurchaseOrderLineResponse = await postHttp(
      this.config.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `lines/purchases/orders/update/${kitchenId}`,
      {
        id,
        lineNo,
        sku,
        quantityReceived,
        status,
        receiptNo,
        userId,
        externalCode,
        origin,
      }
    );

    const updatePurchaseOrderLineResponseText =
      await updatePurchaseOrderLineResponse.text();

    if (updatePurchaseOrderLineResponseText !== "true") {
      return false;
    }

    return false;
  }

  public async scannedItemReceipt(
    orderId: string,
    productionLabelId: string
  ): Promise<ProductionScannedtResponse> {
    try {
      const response = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `receipt/by-barcode-scanner`,
        {
          orderId,
          productionLabelId,
        }
      );
      const responseJson: ProductionScannedtResponse = await response.json();

      return responseJson;
    } catch (err) {
      console.error("scannedItemReceipt error", err);
      throw err;
    }
  }

  public async existsLabelLog(
    orderId: string,
    originId: string,
    productionLabelId: string
  ): Promise<ProductionScannedtResponse> {
    try {
      const data = {
        orderId,
        originId,
        productionLabelId,
      };
      const response = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `receipt/by-barcode-scanner/validate`,
        data
      );
      const result: ProductionScannedtResponse = await response.json();
      return result;
    } catch (err) {
      console.error("existsLabelLog error", err);
      throw err;
    }
  }

  public async removeLabelLog(
    deleteLabelsIds: number[],
    originId: string
  ): Promise<boolean> {
    try {
      const response = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `receipt/by-barcode-scanner/remove`,
        {
          deleteLabelsIds,
          originId,
        }
      );
      const responseJson: boolean = (await response.text()) === "true";
      return responseJson;
    } catch (err) {
      console.error("removeLabelLog error", err);
      throw err;
    }
  }
}
