import { KOSEmptyState } from "@foodology-co/alejandria";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Autocomplete,
  AutocompleteChangeReason,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { commons } from "app/i18n/types";
import { getListProducts } from "app/store/slices/dailyAdjustments/thunks";
import {
  addExtraItem,
  removeExtraItem,
  setExtraItemQuantity,
} from "app/store/slices/transfer";
import { saveExtraItems } from "app/store/slices/transfer/thunks";
import { Product } from "core/dailyAdjustments/entities/Catalog";
import { TransferOrderLine } from "core/transfer/entities/TransferOrder";
import { FunctionComponent, SyntheticEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";

interface AddItemDialogProps {
  open: boolean;
  handleClose: (updateItems: boolean) => void;
  kitchenId: string;
  transferOrderId: number;
  lines: Array<
    TransferOrderLine & { id: number; name: string; status: string }
  > | null;
}

const AddItemDialog: FunctionComponent<AddItemDialogProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { open, handleClose, kitchenId, transferOrderId, lines } = props;
  const products = useAppSelector((state) => state.global.products.data);
  const status = useAppSelector((state) => state.global.products.status);
  const items = useAppSelector((state) => state.transfer.extraItems);
  const statusSave = useAppSelector(
    (state) => state.transfer.saveExtraItems.status
  );

  useEffect(() => {
    if (kitchenId !== null && kitchenId !== undefined) {
      dispatch(getListProducts({ kitchenId: kitchenId }));
    }
  }, [dispatch, kitchenId]);

  useEffect(() => {
    if (statusSave === "succeeded") {
      handleClose(true);
    }
  }, [statusSave]);

  const addProduct = (
    e: SyntheticEvent<Element, Event>,
    value: {
      label: string;
      value: Product;
    } | null,
    reason: AutocompleteChangeReason
  ) => {
    const product = value?.value;
    if (product) {
      if (
        lines?.filter((item) => item.sku === product.sku).length === 0 &&
        items?.filter((item) => item.sku === product.sku).length === 0
      ) {
        dispatch(addExtraItem(product));
      }
    }
  };

  const setQuantity = (quantity: number, sku: string) => {
    dispatch(setExtraItemQuantity({ sku, quantity }));
  };

  const removeItem = (sku: string) => {
    dispatch(removeExtraItem(sku));
  };

  const sendExtraItems = () => {
    if (items != null) {
      dispatch(
        saveExtraItems({
          transferOrderId: transferOrderId,
          userId: "",
          items: items,
        })
      );
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="md"
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={{ pr: "40px", pl: "40px", pt: "30px" }}
        id="authorization-dialog-title"
        color="primary.dark"
        variant="h5"
        fontWeight={600}
      >
        Agregar Items
      </DialogTitle>

      <DialogContent sx={{ pr: "40px", pl: "40px" }}>
        <DialogContentText id="alert-dialog-description" color="text.primary">
          Agregue items adicionales a la orden de traslado
        </DialogContentText>
        <Box sx={{ "padding-bottom": "40px", "padding-top": "20px" }}>
          <Autocomplete
            disablePortal
            options={
              products
                .reduce<Array<Product>>(
                  (acc, product) => acc.concat(product),
                  []
                )
                .map((product: Product) => ({
                  label: product.sku + "-" + product.name.toLocaleUpperCase(),
                  value: product,
                })) ?? []
            }
            sx={{
              height: "24px",
              padding: "0px",
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  status === "loading"
                    ? `${t(commons.LOADING)}...`
                    : "Buscar insumo"
                }
              />
            )}
            onChange={addProduct}
            disabled={status === "loading"}
          />
        </Box>
        <TableContainer component={Paper} sx={{ mt: 1 }}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  "& > th": {
                    bgcolor: "#F9FAFC",
                    borderBottom: "2px solid #E3E5E8",
                    color: "text.secondary",
                    fontWeight: 700,
                    textTransform: "uppercase",
                  },
                }}
              >
                <TableCell>Sku</TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell>Cantidad</TableCell>
                <TableCell>{t(commons.UNIT)}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {items?.map((item) => (
                <TableRow key={item.sku}>
                  <TableCell>
                    <Typography>{item.sku}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{item.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      label="Cantidad"
                      size="small"
                      onChange={(e) => setQuantity(+e.target.value, item.sku)}
                      value={item.quantity ?? ""}
                      InputProps={{
                        type: "number",
                      }}
                      autoComplete="off"
                    />
                  </TableCell>
                  <TableCell>
                    <Typography
                      textTransform="uppercase"
                      fontWeight={700}
                      color="text.secondary"
                    >
                      {t(`measureUnits.${item.measureUnit.toLowerCase()}`)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      onClick={() => removeItem(item.sku)}
                    >
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {(!items || items.length === 0) && (
                <TableRow>
                  <TableCell colSpan={5}>
                    <KOSEmptyState
                      icon={InfoOutlinedIcon}
                      instruction="Seleccione almenos un insumo"
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions sx={{ pl: "40px", pr: "40px", pb: "30px" }}>
        <Box width="100%" display="flex" gap={1}>
          <Button
            onClick={() => handleClose(false)}
            variant="outlined"
            color="error"
            startIcon={<CloseOutlinedIcon />}
            fullWidth
            sx={{ p: "12px", fontWeight: "600" }}
          >
            {t(commons.CANCEL)}
          </Button>

          <Button
            onClick={sendExtraItems}
            variant="contained"
            color="primary"
            startIcon={<ArrowForwardIosIcon />}
            fullWidth
            autoFocus
            sx={{ p: "12px", fontWeight: "600" }}
            disabled={
              !items ||
              items.length === 0 ||
              items.some((item) => !item.quantity) ||
              statusSave === "loading"
            }
          >
            Agregar Items
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AddItemDialog;
