import { UseCase } from "core/common/interfaces/useCase";
import { canArchiveProduction } from "../interfaces/canArchiveProduction";

export class ArchiveProductionUseCase implements UseCase {
  constructor(private readonly repository: canArchiveProduction) {}

  public execute(productionId: string) {
    return this.repository.archiveProduction(productionId);
  }
}
