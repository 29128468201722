import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Box, Card, CardContent, Chip } from "@mui/material";
import Typography from "@mui/material/Typography";
import ProgressBar from "app/components/ProgressBar";
import { commons } from "app/i18n/types";
import { PackingKitchen } from "core/transfer/entities/TransferOrder";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

interface PackingKitchenCardProps {
  kitchen: PackingKitchen;
  selectedTransferOrderId: number;
  setSelectedTransferOrderId: (transferid: number) => void;
}

const PackingKitchenCard: FunctionComponent<PackingKitchenCardProps> = (
  props
) => {
  const { kitchen, selectedTransferOrderId, setSelectedTransferOrderId } =
    props;

  const { t } = useTranslation();
  const [color, setColor] = useState<string>("");

  const handleClicK = () => {
    setSelectedTransferOrderId(kitchen.transferOrderId);
  };

  return (
    <Card
      sx={{
        minWidth: "fit-content",
        cursor: "pointer",
        backgroundColor:
          selectedTransferOrderId === kitchen.transferOrderId
            ? "#3c81db1f"
            : undefined,
      }}
      onClick={handleClicK}
    >
      <CardContent sx={{ padding: "8px 16px !important" }}>
        <Box sx={{ display: "flex", alignItems: "flex-end", gap: 1 }}>
          <Typography variant="body2" sx={{ lineHeight: "24px" }}>
            #{kitchen.transferOrderId.toString().slice(-2)}
          </Typography>
          <Typography fontSize={16} fontWeight={700}>
            {kitchen.name}
          </Typography>
        </Box>
        <Box sx={{ width: "100%", pt: 0.5 }}>
          <Chip
            icon={
              kitchen.progress === kitchen.total ? (
                <CheckCircleOutlineOutlinedIcon
                  sx={{ color: "white !important" }}
                />
              ) : (
                <ErrorOutlineOutlinedIcon sx={{ color: "white !important" }} />
              )
            }
            label={t(commons.PACKING_PROGRESS_SUPPLIES, {
              packing: kitchen.progress,
              total: kitchen.total,
              percentage: `${kitchen.percentage}%`,
            })}
            sx={{
              backgroundColor: color,
              color: "white",
              fontWeight: "bold",
            }}
          />
        </Box>
        <Box sx={{ width: "100%", pt: 0.5 }}>
          <ProgressBar
            percent={Number(kitchen.percentage)}
            setColor={setColor}
            compact
            hidePercentLabel
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default PackingKitchenCard;
