import { LockPersonOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import NumberField from "app/components/common/Field/Number";
import { commons, productionManagement, supplyReception } from "app/i18n/types";
import { SlotEnum } from "core/productions/entities/Plan";
import { DetailToSave } from "core/productions/entities/Supply";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface SupplyRequestTableProps {
  newDetails: DetailToSave[];
  setNewDetails: (details: DetailToSave[]) => void;
}

const SupplyRequestTable: FunctionComponent<SupplyRequestTableProps> = ({
  newDetails,
  setNewDetails,
}) => {
  const { t } = useTranslation();

  return newDetails.length > 0 ? (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" width="17%">
              <Typography
                sx={{ textTransform: "uppercase" }}
                color="text.secondary"
                fontWeight={700}
              >
                {t(commons.SKU)}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                sx={{ textTransform: "uppercase" }}
                color="text.secondary"
                fontWeight={700}
              >
                {t(commons.SUPPLY)}
              </Typography>
            </TableCell>
            <TableCell align="center" width="17%">
              <Typography
                sx={{ textTransform: "uppercase" }}
                color="text.secondary"
                fontWeight={700}
              >
                {t(supplyReception.REQUESTED_QUANTITY)}
              </Typography>
            </TableCell>
            <TableCell align="center" width="17%">
              <Typography
                sx={{ textTransform: "uppercase" }}
                color="text.secondary"
                fontWeight={700}
              >
                {t(productionManagement.TIME_SLOT)}
              </Typography>
            </TableCell>
            <TableCell align="center" width="17%">
              <Typography
                sx={{ textTransform: "uppercase" }}
                color="text.secondary"
                fontWeight={700}
              >
                {t(commons.UNIT)}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {newDetails.map((detail, index) => (
            <TableRow key={detail.sku}>
              <TableCell align="center">
                <Typography>{detail.sku}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{detail.name}</Typography>
              </TableCell>
              <TableCell align="center">
                <NumberField
                  value={detail.quantity}
                  onChangeValue={(newValue) => {
                    const newDetailsCopy = [...newDetails];
                    newDetailsCopy[index].quantity = newValue;
                    setNewDetails(newDetailsCopy);
                  }}
                />
              </TableCell>
              <TableCell align="center">
                <Autocomplete
                  value={detail.timeSlot}
                  options={Object.values(SlotEnum)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t(productionManagement.TIME_SLOT)}
                    />
                  )}
                  onChange={(_, newValue) => {
                    const newDetailsCopy = [...newDetails];
                    if (newValue) {
                      newDetailsCopy[index].timeSlot = newValue;
                    }
                    setNewDetails(newDetailsCopy);
                  }}
                />
              </TableCell>
              <TableCell align="center">
                <Typography fontWeight={700} textTransform="uppercase">
                  {t(`measureUnits.${detail.measureUnit.toLowerCase()}`)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      py={8}
    >
      <Box
        borderRadius={56}
        width={112}
        height={112}
        bgcolor="#F1EFEF"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <LockPersonOutlined sx={{ fontSize: 80 }} />
      </Box>
      <Typography fontWeight={700}>{t(supplyReception.NO_SUPPLIES)}</Typography>
      <Typography>{t(supplyReception.NO_SUPPLIES_INFO)}</Typography>
    </Box>
  );
};

export default SupplyRequestTable;
