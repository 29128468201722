import { UseCase } from "core/common/interfaces/useCase";
import { TransferOrder } from "core/transfer/entities/TransferOrder";
import { CanCreateTransferOrder } from "core/transfer/interfaces/transfer/canCreateTransferOrder";

export class CreateTransferOrderUseCase implements UseCase {
  constructor(private readonly repository: CanCreateTransferOrder) {}

  public execute(locationId: string, transferOrders: Array<TransferOrder>) {
    return this.repository.createTransferOrder(locationId, transferOrders);
  }
}
