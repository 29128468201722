import { UseCase } from "core/common/interfaces/useCase";
import { ProductionPayload } from "../entities/Productions";
import {
  canUpdateProduction,
  canupdateProductionManager,
} from "../interfaces/canUpdateProduction";

export class UpdateProductionUseCase implements UseCase {
  constructor(private readonly repository: canUpdateProduction) {}

  public execute(production: ProductionPayload, productionId: string) {
    return this.repository.updateProduction(production, productionId);
  }
}

export class updateProductionManagerUseCase implements UseCase {
  constructor(private readonly repository: canupdateProductionManager) {}

  public execute(productionId: string, userId: string, userName: string) {
    return this.repository.updateProductionManager(
      productionId,
      userId,
      userName
    );
  }
}
