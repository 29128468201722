import { Edit, FactCheckOutlined } from "@mui/icons-material";
import { Alert, Box, Tab, Tabs } from "@mui/material";
import { commons, productionManagement } from "app/i18n/types";
import appConfig from "config/app";
import { ProductionPlanSelectedSlot } from "core/productions/entities/Plan";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { openUrl, s3Folder } from "utils/general";
import { Actions } from "utils/modal";
import { BulkUploader } from "./bulkUploader";
import { HandleForm } from "./form";

interface Props {
  action: Actions;
  onClose: () => void;
  selectedSlot?: ProductionPlanSelectedSlot;
  isSlotAdded: boolean;
  refreshCalendar?: () => void;
}

export const CreateDialogContent = (props: Props) => {
  const { action, onClose, isSlotAdded, selectedSlot, refreshCalendar } = props;
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();

  const handleChangeValue = useCallback(
    (event: React.SyntheticEvent, newValue: number) => setValue(newValue),
    []
  );

  const handleDownloadExampleSheet = () => {
    if (appConfig.s3Url) {
      openUrl(
        `${appConfig.s3Url}${s3Folder.templates.root}/${s3Folder.templates.files.productionPlan}`
      );
    }
  };

  return (
    <>
      {!isSlotAdded && (
        <Tabs
          value={value}
          onChange={handleChangeValue}
          sx={{ mb: 2 }}
          variant="fullWidth"
        >
          <Tab
            sx={{ minHeight: "auto" }}
            label={t(commons.MASSIVE)}
            icon={<FactCheckOutlined />}
            iconPosition="start"
          />
          <Tab
            sx={{ minHeight: "auto" }}
            label={t(commons.INDIVIDUAL)}
            icon={<Edit />}
            iconPosition="start"
          />
        </Tabs>
      )}

      {!isSlotAdded && (
        <Box role="tabpanel" hidden={value !== 0}>
          <Alert
            severity="info"
            sx={{
              mb: 3,
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={handleDownloadExampleSheet}
          >
            {t(productionManagement.DOWNLOAD_EXAMPLE_TEMPLATE)}
          </Alert>

          <BulkUploader onClose={onClose} refreshCalendar={refreshCalendar} />
        </Box>
      )}

      <Box role="tabpanel" hidden={isSlotAdded ? false : value !== 1}>
        <Alert
          severity="info"
          sx={{ mb: 3, display: "flex", alignItems: "center" }}
        >
          {t(productionManagement.FILL_IN_THE_FORM)}
        </Alert>

        <HandleForm
          action={action}
          onClose={onClose}
          selectedSlot={selectedSlot}
          refreshCalendar={refreshCalendar}
        />
      </Box>
    </>
  );
};
