import { UseCase } from "core/common/interfaces/useCase";
import { OrganolepticsResultRequest } from "../entities/Productions";
import { CanCreateOrganolepticsResults } from "../interfaces/canCreateOrganolepticsResults";

export class CreateOrganolepticsResultsUseCase implements UseCase {
  constructor(private readonly repository: CanCreateOrganolepticsResults) {}

  public execute(organoleptics: OrganolepticsResultRequest) {
    return this.repository.createOrganolepticsResults(organoleptics);
  }
}
