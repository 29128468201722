import Checkbox from "@mui/material/Checkbox";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { commons } from "app/i18n/types";
import { AdjustmentQuantities } from "config/dailyAdjustment";
import { AdjustmentRecord } from "core/dailyAdjustments/entities/Adjustment";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import EmptyAuthorizationTable from "./Empty";
import AuthorizationTableRow from "./Row";

interface Props {
  loadding: boolean;
  adjustments: AdjustmentRecord[];
  toggle: (adjustmentId?: number) => void;
  selected: number[];
  setOpenDocumentList: (open: boolean) => void;
  quantities: AdjustmentQuantities | null;
  setQuantity: (adjustmentId: number, quantity: number) => void;
  setAdjustment: (adjustment: AdjustmentRecord) => void;
  handleOpenProductDrawer: (adjustment: AdjustmentRecord) => void;
}

const AuthorizationTable: FunctionComponent<Props> = (props) => {
  const {
    adjustments,
    loadding,
    toggle,
    selected,
    setOpenDocumentList,
    quantities,
    setQuantity,
    setAdjustment,
    handleOpenProductDrawer,
  } = props;
  const { t } = useTranslation();

  return (
    <TableContainer component={Paper}>
      {loadding && <LinearProgress />}

      <Table
        sx={{ minWidth: 650 }}
        aria-label="counting-history"
        size="small"
        stickyHeader
      >
        <TableHead>
          <TableRow
            sx={{
              "& > th": {
                bgcolor: "#F9FAFC",
                borderBottom: "2px solid #E3E5E8",
              },
            }}
          >
            <TableCell align="center">
              <Checkbox
                onChange={() => toggle()}
                checked={
                  selected.length > 0 &&
                  selected.length ===
                    adjustments.filter(
                      (adjusment) => !adjusment.isFinishedProduct
                    )?.length
                }
              />
            </TableCell>
            <TableCell align="left">{t(commons.NAME)}</TableCell>
            <TableCell align="center">{t(commons.QUANTITY)}</TableCell>
            <TableCell align="center">{t(commons.UNIT)}</TableCell>
            <TableCell align="center">{t(commons.REASON)}</TableCell>
            <TableCell align="center">{t(commons.DATETIME)}</TableCell>
            <TableCell align="center">{t(commons.PERSON_IN_CHARGE)}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {adjustments.map((adjustment) => (
            <AuthorizationTableRow
              key={`AuthorizationTableRow-${adjustment.id}`}
              adjustment={adjustment}
              quantities={quantities}
              setQuantity={setQuantity}
              selected={selected}
              setAdjustment={setAdjustment}
              toggle={toggle}
              setOpenDocumentList={setOpenDocumentList}
              handleOpenProductDrawer={handleOpenProductDrawer}
            />
          ))}

          <EmptyAuthorizationTable
            isTableEmpty={adjustments.length === 0}
            tableCellNumber={7}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AuthorizationTable;
