import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FunctionComponent } from "react";

interface FinishDrawerInfoItemProps {
  label: string;
  value: string;
  foregroundColor: string;
  backgroundColor: string;
}

const FinishDrawerInfoItem: FunctionComponent<FinishDrawerInfoItemProps> = (
  props
) => {
  const { label, value, foregroundColor, backgroundColor } = props;

  return (
    <Box display="flex" gap={2} width="100%" alignItems="center">
      <Box
        py={0.5}
        px={2}
        bgcolor={backgroundColor}
        border={`1px solid ${foregroundColor}`}
        borderRadius={1}
        display="flex"
        gap={2}
        width="100%"
        justifyContent="space-between"
      >
        <Box display="flex" flexDirection="column">
          <Typography color={foregroundColor} fontWeight={300} gutterBottom>
            {label}
          </Typography>
          <Typography fontWeight={600} color={foregroundColor}>
            {value}
          </Typography>
        </Box>
      </Box>

      <HelpOutlineOutlinedIcon sx={{ color: foregroundColor }} />
    </Box>
  );
};

export default FinishDrawerInfoItem;
