import { UseCase } from "core/common/interfaces/useCase";
import {
  CanGetTransferOrderPickingItems,
  CanGetTransferOrderPickingOrders,
} from "core/transfer/interfaces/transfer/canGetTransferOrderPickingItems";

export class GetTransferOrderPickingItemsUseCase implements UseCase {
  constructor(private readonly repository: CanGetTransferOrderPickingItems) {}

  public execute(locationId: string) {
    return this.repository.getTransferOrderPickingItems(locationId);
  }
}

export class GetTransferOrderPickingOrdersUseCase implements UseCase {
  constructor(private readonly repository: CanGetTransferOrderPickingOrders) {}

  public execute(locationId: string) {
    return this.repository.getTransferOrderPickingOrders(locationId);
  }
}
