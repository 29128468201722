import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Alert } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { transferPackingDialog } from "app/i18n/types";
import { getTransferOrderPackingLogs } from "app/store/slices/transfer/thunks";
import {
  PickingDetailResponse,
  PickingLocationsResponse,
} from "core/transfer/entities/TransferOrder";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DialogTitle from "../Dialog/CustomDialogTitle";
import ProductionLabelList from "../Productions/Label/Dialog/Purchase/List";
import TransferPackingDialogRow from "./TransferPackingDialogRow";
import TransferPackingSupplyBox from "./TransferPackingSupplyBox";

interface TransferPackingForKitchenDialogProps {
  selectedLine: PickingDetailResponse | null;
  transferId: number;
  onClose: () => void;
  setStatus: (
    id: number,
    status: "PENDING" | "COMPLETE",
    packedQuantity: number
  ) => void;
}

const TransferPackingForKitchenDialog: FunctionComponent<
  TransferPackingForKitchenDialogProps
> = (props) => {
  const { selectedLine, transferId, onClose, setStatus } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [pickingItemLocation, setPickingItemLocation] =
    useState<PickingLocationsResponse>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (selectedLine) {
      setLoading(true);
      dispatch(
        getTransferOrderPackingLogs({
          sku: selectedLine.sku.toString(),
          transferId,
        })
      )
        .then((response) => {
          setPickingItemLocation(response.payload as PickingLocationsResponse);
        })
        .catch(() => {
          setPickingItemLocation(undefined);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selectedLine]);

  const setStatusLocation = (
    id: number,
    status: "RELEASED" | "PACKED",
    packedQuantity: number
  ) => {
    const newItem = { ...pickingItemLocation, packedQuantity, status };
    setPickingItemLocation(newItem as PickingLocationsResponse);
  };

  const onCloseModal = () => {
    if (pickingItemLocation) {
      setStatus(
        pickingItemLocation.id,
        pickingItemLocation.status === "PACKED" ? "COMPLETE" : "PENDING",
        pickingItemLocation.packedQuantity
      );
    }
    onClose();
  };

  return (
    <Dialog open onClose={onCloseModal} maxWidth="sm" fullWidth>
      <DialogTitle
        title={transferPackingDialog.RESUME}
        status={loading ? "loading" : "succeeded"}
        onClose={onCloseModal}
      />

      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <TransferPackingSupplyBox selectedLine={selectedLine} />
          </Grid>
          {pickingItemLocation && (
            <Grid xs={12} md={12} item>
              <TransferPackingDialogRow
                line={pickingItemLocation}
                selectedLine={selectedLine}
                setStatusLocation={setStatusLocation}
                hideHeader
              />
            </Grid>
          )}
          {!!pickingItemLocation?.labels.length && (
            <Grid xs={12} sm={12} item>
              <Alert severity="info">
                {t(transferPackingDialog.SUBTITLE_FOR_KITCHEN)}
              </Alert>
            </Grid>
          )}
          <Grid xs={12} md={12} item>
            <ProductionLabelList labels={pickingItemLocation?.labels ?? []} />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          onClick={onCloseModal}
          color="error"
          startIcon={<CloseOutlinedIcon />}
          fullWidth
        >
          {t(transferPackingDialog.CANCEL)}
        </Button>
        <Button
          variant="contained"
          onClick={onCloseModal}
          startIcon={<ChevronRightOutlinedIcon />}
          disabled={loading}
          fullWidth
        >
          {t(transferPackingDialog.CONTINUE)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransferPackingForKitchenDialog;
