/* eslint-disable react/no-array-index-key */
import { Box, DialogTitle, Divider, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { commons } from "app/i18n/types";
import { viewFile } from "core/attachment/repository/http";
import { ProductRequest } from "core/dailyAdjustments/entities/Catalog";
import React from "react";
import { useTranslation } from "react-i18next";
import AddImageDialogContent from "./AddImageDialogContent";

interface AddImageDialogProps {
  onCloseDialog: () => void;
  productToSave: ProductRequest;
  setProductToSave: (productToSave: ProductRequest) => void;
}

const AddImageDialog = (props: AddImageDialogProps): React.ReactElement => {
  const { onCloseDialog, productToSave, setProductToSave } = props;
  const { t } = useTranslation();

  const openDocument = (name: string) => {
    viewFile(
      productToSave.id,
      origin,
      productToSave.typeLoss as unknown as string,
      name
    );
  };

  return (
    <Dialog fullWidth maxWidth="md" open onClose={onCloseDialog}>
      <Box>
        <DialogTitle>
          <Typography variant="h5">{t(commons.UPLOAD_EVIDENCE)}</Typography>
        </DialogTitle>
        <Divider variant="fullWidth" />
        <AddImageDialogContent
          onCloseDialog={onCloseDialog}
          productToSave={productToSave}
          setProductToSave={setProductToSave}
          openDocument={openDocument}
        />
      </Box>
    </Dialog>
  );
};

export default AddImageDialog;
