import { createSlice } from "@reduxjs/toolkit";
import { Product } from "core/dailyAdjustments/entities/Catalog";
import { TransferPayload, addNewLabelFromTransferAction } from "utils/scanner";
import { byKitchen } from "../bcIntegration/thunks";
import { initialState } from "./state";
import {
  archiveLines,
  archiveTransferOrder,
  createSingleTransferOrder,
  createTransferOrder,
  getPackedTransferOrders,
  getPendingTransferOrdersByLocationId,
  reportTransferOrder,
  saveExtraItems,
  setTransferOrderAsPacked,
  updateTransferOrderLineQuantity,
  updateTransferOrderPackedLineQuantity,
} from "./thunks";

export const transferSlice = createSlice({
  name: "transfers",
  initialState,
  reducers: {
    clearTransfersState: (state) => {
      state.createTransferOrder = initialState.createTransferOrder;
      state.reportTransferOrder = initialState.reportTransferOrder;
      state.pendingTransferOrders = initialState.pendingTransferOrders;
      state.createSingleTransferOrder = initialState.createSingleTransferOrder;
    },
    addExtraItem: (state, action) => {
      let selectedItems = state.extraItems || [];
      const product: Product = action.payload;
      if (product) {
        selectedItems.push({
          sku: product.sku,
          name: product.name,
          measureUnit: product.unit,
          group: product.group,
        });
      }
      state.extraItems = selectedItems;
    },
    setExtraItemQuantity: (state, action) => {
      let extraItems = state.extraItems || [];
      const index = extraItems.findIndex(
        (item) => item.sku === action.payload.sku
      );
      extraItems[index].quantity = action.payload.quantity;
      state.extraItems = extraItems;
    },
    removeExtraItem: (state, action) => {
      let extraItems = state.extraItems || [];
      const index = extraItems.findIndex(
        (item) => item.sku === action.payload.sku
      );
      extraItems.splice(index, 1);
      state.extraItems = extraItems;
    },
    clearExtraItem: (state) => {
      state.extraItems = initialState.extraItems;
    },
    deletePackedOrder: (state, action) => {
      let transfers = state.packedTransferOrders.transferOrders;
      if (transfers != null) {
        const index = transfers.findIndex(
          (item) => item.id === action.payload.id
        );
        transfers.splice(index, 1);
        state.packedTransferOrders.transferOrders = transfers;
      }
    },
    scannedOnPackingForSupply: (state, action) => {
      let lines = state.pickingItemLocations.pickingItemLocations;
      if (lines?.length) {
        const { lineId, sum } = action.payload as TransferPayload;
        const index = lines.findIndex((item) => item.id === lineId);
        if (index !== -1) {
          lines[index].sendQuantity = sum;
          addNewLabelFromTransferAction(
            lines[index].labels,
            action.payload as TransferPayload
          );
        }
      }
      state.pickingItemLocations.pickingItemLocations = lines;
    },
  },
  extraReducers(builder) {
    builder
      // Create Transfer Order
      .addCase(createTransferOrder.pending, (state, action) => {
        state.createTransferOrder = { status: "loading", error: null };
      })
      .addCase(createTransferOrder.fulfilled, (state, action) => {
        state.createTransferOrder = { status: "succeeded", error: null };
      })
      .addCase(createTransferOrder.rejected, (state, action) => {
        state.createTransferOrder = {
          status: "failed",
          error: action.error.message ?? null,
        };
      })

      // Create Single Transfer Order
      .addCase(createSingleTransferOrder.pending, (state, action) => {
        state.createSingleTransferOrder = { status: "loading", error: null };
      })
      .addCase(createSingleTransferOrder.fulfilled, (state, action) => {
        state.createSingleTransferOrder = { status: "succeeded", error: null };
      })
      .addCase(createSingleTransferOrder.rejected, (state, action) => {
        state.createSingleTransferOrder = {
          status: "failed",
          error: action.error.message ?? null,
        };
      })

      // Get pending Transfer Orders
      .addCase(
        getPendingTransferOrdersByLocationId.pending,
        (state, action) => {
          state.pendingTransferOrders = {
            status: "loading",
            error: null,
            transferOrders: null,
          };
        }
      )
      .addCase(
        getPendingTransferOrdersByLocationId.fulfilled,
        (state, action) => {
          state.pendingTransferOrders = {
            status: "succeeded",
            error: null,
            transferOrders: action.payload,
          };
        }
      )
      .addCase(
        getPendingTransferOrdersByLocationId.rejected,
        (state, action) => {
          state.pendingTransferOrders = {
            status: "failed",
            error: action.error.message ?? null,
            transferOrders: null,
          };
        }
      )

      // Get packed Transfer Orders
      .addCase(getPackedTransferOrders.pending, (state, action) => {
        state.packedTransferOrders = {
          status: "loading",
          error: null,
          transferOrders: null,
        };
      })
      .addCase(getPackedTransferOrders.fulfilled, (state, action) => {
        state.packedTransferOrders = {
          status: "succeeded",
          error: null,
          transferOrders: action.payload,
        };
      })
      .addCase(getPackedTransferOrders.rejected, (state, action) => {
        state.packedTransferOrders = {
          status: "failed",
          error: action.error.message ?? null,
          transferOrders: null,
        };
      })

      // Report Transfer Order
      .addCase(reportTransferOrder.pending, (state, action) => {
        state.reportTransferOrder = { status: "loading", error: null };
      })
      .addCase(reportTransferOrder.fulfilled, (state, action) => {
        state.reportTransferOrder = { status: "succeeded", error: null };
      })
      .addCase(reportTransferOrder.rejected, (state, action) => {
        state.reportTransferOrder = {
          status: "failed",
          error: action.error.message ?? null,
        };
      })

      // Archive lines
      .addCase(archiveLines.pending, (state, action) => {
        state.archiveLines = { status: "loading", error: null };
      })
      .addCase(archiveLines.fulfilled, (state, action) => {
        const sku = action.meta.arg.sku;
        state.archiveLines = { status: "succeeded", error: null };

        if (state.pendingTransferOrders.transferOrders !== null) {
          state.pendingTransferOrders.transferOrders =
            state.pendingTransferOrders.transferOrders.map((order) => {
              return {
                ...order,
                lines: order.lines.filter(
                  (line) => !action.meta.arg.lineIds.includes(line.id)
                ),
              };
            });
        }

        if (state.pickingItemLocations.pickingItemLocations !== null) {
          state.pickingItemLocations.pickingItemLocations =
            state.pickingItemLocations.pickingItemLocations.filter(
              (line) => !action.meta.arg.lineIds.includes(line.id)
            );
        }

        if (
          state.pickingItemLocations.pickingItemLocations === null ||
          state.pickingItems.pickingItems === null
        ) {
          return;
        }

        if (!sku) {
          return;
        }

        if (state.pickingItems.pickingItems === null) {
          return;
        }

        const pickingItemIndex =
          state.pickingItems.pickingItems.detail.findIndex(
            (item) => item.sku === sku
          );

        if (pickingItemIndex < 0) {
          return;
        }

        if (
          state.pickingItemLocations.pickingItemLocations.every(
            (location) => location.status === "PACKED"
          )
        ) {
          state.pickingItems.pickingItems.detail[pickingItemIndex].status =
            "COMPLETE";
        }
      })
      .addCase(archiveLines.rejected, (state, action) => {
        state.archiveLines = {
          status: "failed",
          error: action.error.message ?? null,
        };
      })

      // updatePurchaseOrderLine
      .addCase(updateTransferOrderLineQuantity.pending, (state, action) => {
        if (state.linesStatus === null) {
          state.linesStatus = {};
        }

        state.linesStatus[action.meta.arg.transferLineId] = {
          status: "loading",
          error: null,
        };
      })
      .addCase(updateTransferOrderLineQuantity.fulfilled, (state, action) => {
        if (state.linesStatus === null) {
          state.linesStatus = {};
        }

        state.linesStatus[action.meta.arg.transferLineId] = {
          status: "succeeded",
          error: null,
        };
      })
      .addCase(updateTransferOrderLineQuantity.rejected, (state, action) => {
        if (state.linesStatus === null) {
          state.linesStatus = {};
        }

        state.linesStatus[action.meta.arg.transferLineId] = {
          status: "failed",
          error: action.error.message ?? "",
        };
      })
      // updateLineSendQuantity
      .addCase(
        updateTransferOrderPackedLineQuantity.pending,
        (state, action) => {
          if (state.linesStatus === null) {
            state.linesStatus = {};
          }

          state.linesStatus[action.meta.arg.transferLineId] = {
            status: "loading",
            error: null,
          };
        }
      )
      .addCase(
        updateTransferOrderPackedLineQuantity.fulfilled,
        (state, action) => {
          if (state.linesStatus === null) {
            state.linesStatus = {};
          }
          const { transferLineId } = action.meta.arg;
          const { quantity, sku } = action.meta.arg.updateQuantityAndReason;

          state.linesStatus[action.meta.arg.transferLineId] = {
            status: "succeeded",
            error: null,
          };

          if (state.pickingItemLocations.pickingItemLocations === null) {
            return;
          }

          const pickingItemLocationIndex =
            state.pickingItemLocations.pickingItemLocations.findIndex(
              (location) => location.id === transferLineId
            );

          if (pickingItemLocationIndex < 0) {
            return;
          }

          state.pickingItemLocations.pickingItemLocations[
            pickingItemLocationIndex
          ] = {
            ...state.pickingItemLocations.pickingItemLocations[
              pickingItemLocationIndex
            ],
            status: "PACKED",
            sendQuantity: quantity,
          };

          if (state.pickingItems.pickingItems === null) {
            return;
          }

          const pickingItemIndex =
            state.pickingItems.pickingItems.detail.findIndex(
              (item) => item.sku === sku
            );

          if (pickingItemIndex < 0) {
            return;
          }
        }
      )
      .addCase(
        updateTransferOrderPackedLineQuantity.rejected,
        (state, action) => {
          if (state.linesStatus === null) {
            state.linesStatus = {};
          }

          state.linesStatus[action.meta.arg.transferLineId] = {
            status: "failed",
            error: action.error.message ?? "",
          };
        }
      )

      // setTransferOrderAsPacked
      .addCase(setTransferOrderAsPacked.pending, (state, action) => {
        state.setTransferOrderAsPacked = { status: "loading", error: null };
      })
      .addCase(setTransferOrderAsPacked.fulfilled, (state, action) => {
        state.setTransferOrderAsPacked = { status: "succeeded", error: null };
      })
      .addCase(setTransferOrderAsPacked.rejected, (state, action) => {
        state.setTransferOrderAsPacked = {
          status: "failed",
          error: action.error.message ?? null,
        };
      })

      // Save Extra Item
      .addCase(saveExtraItems.pending, (state, action) => {
        state.saveExtraItems = { status: "loading", error: null };
      })
      .addCase(saveExtraItems.fulfilled, (state, action) => {
        state.saveExtraItems = { status: "succeeded", error: null };
      })
      .addCase(saveExtraItems.rejected, (state, action) => {
        state.saveExtraItems = {
          status: "failed",
          error: action.error.message ?? null,
        };
      })
      // Create Transfer Order
      .addCase(archiveTransferOrder.pending, (state, action) => {
        state.archiveTransferOrder = { status: "loading", error: null };
      })
      .addCase(archiveTransferOrder.fulfilled, (state, action) => {
        state.archiveTransferOrder = { status: "succeeded", error: null };
      })
      .addCase(archiveTransferOrder.rejected, (state, action) => {
        state.archiveTransferOrder = {
          status: "failed",
          error: action.error.message ?? null,
        };
      })
      // Get error summary
      .addCase(byKitchen.pending, (state, action) => {
        state.transferErrors = [];
      })
      .addCase(byKitchen.fulfilled, (state, action) => {
        state.transferErrors = action.payload.transfers;
      })
      .addCase(byKitchen.rejected, (state, action) => {
        state.transferErrors = [];
      });
  },
});

export const {
  clearTransfersState: clearState,
  clearTransfersState,
  addExtraItem,
  clearExtraItem,
  setExtraItemQuantity,
  removeExtraItem,
  deletePackedOrder,
  scannedOnPackingForSupply,
} = transferSlice.actions;

export const { reducer } = transferSlice;

export default transferSlice;
