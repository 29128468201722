import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { IconButton, Popover, Typography } from "@mui/material";
import { useState } from "react";

interface Props {
  message: string;
}

const BulkLoadError = (props: Props) => {
  const { message } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const onOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
      >
        <ReportProblemOutlinedIcon color="warning" />
      </IconButton>
      <Popover
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={onClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{message}</Typography>
      </Popover>
    </>
  );
};

export default BulkLoadError;
