import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { commons } from "app/i18n/types";
import { updatePurchaseOrderLine } from "app/store/slices/purchase/lines.slice";
import { LinesPurchaseOrder } from "core/linesPurchaseOrders/entities/LinesPurchaseOrder";
import {
  FocusEvent,
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

interface Props {
  item: LinesPurchaseOrder;
  kitchenId: string;
  orderId: string;
}

const UpdateRecivedQuantityPurchaseTextField: FunctionComponent<Props> = (
  props
) => {
  const { item, kitchenId, orderId } = props;
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const linesStatus = useAppSelector(
    (state) => state.linesPurchase.linesStatus
  );

  const [quantity, setQuantity] = useState<string | undefined>(undefined);
  const [validation, setValidation] = useState<string | null>(null);

  const getInputColor = useCallback(():
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning" => {
    if (validation !== null) {
      return "error";
    }

    if (item.status === "IDLE") {
      return "warning";
    }

    if (item.status === "IN_PROCESS") {
      return "success";
    }

    return "primary";
  }, [validation, item.status]);

  const getInputAddornement = useCallback((): ReactNode => {
    if (linesStatus === null || linesStatus[item.lineId] === undefined) {
      return null;
    }

    if (linesStatus[item.lineId].status === "loading") {
      return <CircularProgress size={24} />;
    }

    if (linesStatus[item.lineId].status === "failed" || validation != null) {
      return <ErrorOutlineIcon color="error" />;
    }

    if (linesStatus[item.lineId].status === "succeeded") {
      return <CheckCircleOutlineIcon color="success" />;
    }

    return undefined;
  }, [item.lineId, linesStatus, validation]);

  const getHelperText = useCallback((): string | null => {
    if (validation) {
      return validation;
    }

    if (linesStatus?.[item.lineId]) {
      return linesStatus[item.lineId].error;
    }

    return null;
  }, [item.lineId, linesStatus, validation]);

  const updateItemQuantity = useCallback(
    (event: FocusEvent<HTMLInputElement>, item: LinesPurchaseOrder) => {
      if (kitchenId && orderId) {
        const toReceive = Number(event.target.value);

        const maxQuantity =
          item.quantity * (1 + item.overReceiptQuantity / 100) -
          item.quantityReceived;

        if (toReceive > maxQuantity) {
          setValidation(`Cantidad máxima permitida ${maxQuantity.toFixed(1)}`);
          return;
        }

        dispatch(
          updatePurchaseOrderLine({
            id: item.lineId,
            lineNo: item.lineNo,
            kitchenId,
            sku: item.sku,
            quantityReceived: toReceive,
            status: "IN_PROCESS",
            receiptNo: orderId,
            userId: "",
            externalCode: item.sourceId,
            origin: "KIS",
          })
        );

        setValidation(null);
      }
    },
    [dispatch, kitchenId, orderId]
  );

  const setQuantityNumber = useCallback((quantity: string) => {
    if (quantity === "" || isNaN(Number(quantity))) {
      setQuantity(undefined);
      return;
    }
    setQuantity(Number(quantity).toString());
  }, []);

  useEffect(() => {
    setQuantity(item.quantityToReceive.toString());
  }, [item.quantityToReceive]);

  return (
    <TextField
      id={`quantity-${item.sku}`}
      name="value"
      onChange={(e) => {
        setQuantityNumber(e.target.value);
      }}
      value={quantity ?? ""}
      size="small"
      label={t(commons.ENTER_QUANTITY)}
      InputProps={{
        endAdornment: getInputAddornement(),
        required: true,
        type: "number",
      }}
      color={getInputColor()}
      error={getInputColor() === "error"}
      helperText={getHelperText()}
      onBlur={(event: FocusEvent<HTMLInputElement>) => {
        updateItemQuantity(event, item);
      }}
      sx={{ width: 150 }}
      disabled={
        (linesStatus && linesStatus[item.lineId]?.status === "loading") ||
        !!item.labels.length
      }
      focused
      autoComplete="off"
    />
  );
};

export default UpdateRecivedQuantityPurchaseTextField;
