import { AppConfig } from "config/app";
import {
  AdjustmentRecord,
  AuthorizeAdjustmentsPayload,
} from "core/dailyAdjustments/entities/Adjustment";
import {
  ReportRequest,
  ReportResponse,
} from "core/dailyAdjustments/entities/Report";
import { CanArchiveAdjustments } from "core/dailyAdjustments/interfaces/adjustment/canArchiveAdjustments";
import { CanAuthorizeAdjustments } from "core/dailyAdjustments/interfaces/adjustment/canAuthorizeAdjustments";
import { CanCreateDailyAdjustmentReport } from "core/dailyAdjustments/interfaces/adjustment/canCreateDailyAdjustmentReport";
import { CanGetAdjustmentsByKitchenForReview } from "core/dailyAdjustments/interfaces/adjustment/canGetAdjustmentsByKitchenForReview";
import { ApiVersion, getHttp, postHttp } from "utils/http";

export class AdjustmentHTTPRepository
  implements
    CanGetAdjustmentsByKitchenForReview,
    CanAuthorizeAdjustments,
    CanArchiveAdjustments,
    CanCreateDailyAdjustmentReport
{
  constructor(private readonly config: AppConfig) {}

  public async getAdjustmentsByKitchenForReview(kitchenId: string) {
    try {
      const response = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `daily-adjustments/${kitchenId}`
      );
      const responseJson = await response.json();
      const adjustments: Array<AdjustmentRecord> = responseJson.map(
        (adjustment: AdjustmentRecord) => ({
          ...adjustment,
          createdAt: new Date(adjustment.createdAt),
        })
      );
      return adjustments;
    } catch (error) {
      console.error(
        "AdjustmentHTTPRepository.getAdjustmentsByKitchen error",
        error
      );
      throw error;
    }
  }

  public async authorizeAdjustments(
    adjustments: Array<AuthorizeAdjustmentsPayload>
  ) {
    try {
      const response = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `daily-adjustments/authorize-many`,
        adjustments
      );
      const responseJson: unknown = await response.json();
      return responseJson;
    } catch (error) {
      console.error(
        "AdjustmentHTTPRepository.authorizeAdjustments error",
        error
      );
      throw error;
    }
  }

  public async archiveAdjustments(adjustmentsIds: Array<number>) {
    try {
      const response = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `daily-adjustments/archive-many`,
        adjustmentsIds
      );
      const responseJson: unknown = await response.json();
      return responseJson;
    } catch (error) {
      console.error("AdjustmentHTTPRepository.archiveMovements error", error);
      throw error;
    }
  }

  public async sendToValidationAdjustments(adjustmentsIds: Array<number>) {
    try {
      const response = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `daily-adjustments/send-to-validation-many`,
        adjustmentsIds
      );
      const responseJson: unknown = await response.json();
      return responseJson;
    } catch (error) {
      console.error(
        "AdjustmentHTTPRepository.sendToValidationAdjustments error",
        error
      );
      throw error;
    }
  }

  public async createDailyAdjustmentReport(
    payload: ReportRequest
  ): Promise<ReportResponse> {
    const response = await postHttp(
      this.config.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `daily-adjustments/report`,
      payload
    );
    const responseJson: ReportResponse = await response.json();
    return responseJson;
  }
}
