import { createAsyncThunk } from "@reduxjs/toolkit";
import appConfig from "config/app";
import { CountingHTTPRepository } from "core/physicalCount/repositories/http/counting";
import { GetCountingDetailsByIdsUseCase } from "core/physicalCount/useCases/counting/getCountingDetailsByIdsUseCase";
import { GetLastFinishedCountingsUseCase } from "core/physicalCount/useCases/counting/getLastinishedCountingsUseCase";

export const getFinishedCountingsBetweenDates = createAsyncThunk(
  "counting/getFinishedCountingsBetweenDates",
  async (payload: { initDate: Date; endDate: Date }) => {
    const countingHTTPRepository = new CountingHTTPRepository(appConfig);

    const getLastFinishedCountingsUseCase = new GetLastFinishedCountingsUseCase(
      countingHTTPRepository
    );

    const countings = await getLastFinishedCountingsUseCase.execute(
      payload.initDate,
      payload.endDate
    );
    return { countings };
  }
);

export const getCountingDetailsByIds = createAsyncThunk(
  "counting/getCountingDetailsByIds",
  async (payload: { ids: Array<number> }) => {
    const countingHTTPRepository = new CountingHTTPRepository(appConfig);

    const getCountingDetailsByIds = new GetCountingDetailsByIdsUseCase(
      countingHTTPRepository
    );

    const details = await getCountingDetailsByIds.execute(payload.ids);
    return { details };
  }
);
