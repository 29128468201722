import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from "@mui/material";
import { commons, physicalCountSchedule } from "app/i18n/types";
import {
  CountingType,
  MassiveData,
} from "core/physicalCount/entities/Counting";
import { Supply } from "core/supplies/entities/Supply";
import { FunctionComponent, memo } from "react";
import { useTranslation } from "react-i18next";
import {
  PhysicalCountScheduleLocation,
  getIsRecountOrMassive,
} from "utils/general";
import { CustomTableCell, CustomTableRow } from "utils/generalUI";

interface FinalStepProps {
  selectedCountingType: CountingType;
  locations: PhysicalCountScheduleLocation[];
  massiveData: MassiveData;
  dates: string[];
  selectedSupplies: Supply[];
  name?: string;
}

const FinalStep: FunctionComponent<FinalStepProps> = (props) => {
  const {
    selectedCountingType: type,
    locations,
    massiveData,
    dates,
    selectedSupplies,
    name,
  } = props;

  const { t } = useTranslation();
  const isReCountOrDaily = getIsRecountOrMassive(type);
  const massiveKeys = Object.keys(massiveData);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {!!name && (
            <CustomTableRow>
              <CustomTableCell align="right">
                <Typography variant="h6">{t(commons.NAME)}</Typography>
              </CustomTableCell>
              <CustomTableCell>
                <Typography variant="body1">{name}</Typography>
              </CustomTableCell>
            </CustomTableRow>
          )}
          <CustomTableRow>
            <CustomTableCell align="right">
              <Typography variant="h6">
                {t(physicalCountSchedule.STEP_LABEL_1)}
              </Typography>
            </CustomTableCell>
            <CustomTableCell>
              <Typography variant="body1">{type.name}</Typography>
            </CustomTableCell>
          </CustomTableRow>
          {!isReCountOrDaily && (
            <CustomTableRow>
              <CustomTableCell align="right">
                <Typography variant="h6">
                  {t(physicalCountSchedule.STEP_LABEL_2)}
                </Typography>
              </CustomTableCell>
              <CustomTableCell>
                <ul style={{ margin: 0, paddingLeft: "inherit" }}>
                  {locations.map((location, index) => (
                    <li key={`FinalStepLocation${index}`}>
                      {`${t(commons.SELECT_COUNTRY)}: ${location.country} - ${t(
                        commons.SELECT_CITY
                      )}: ${location.city} - ${t(commons.SELECT_LOCATION)}: ${
                        location.kitchenName
                      }`}
                    </li>
                  ))}
                </ul>
              </CustomTableCell>
            </CustomTableRow>
          )}
          <CustomTableRow>
            <CustomTableCell align="right">
              <Typography variant="h6">
                {t(physicalCountSchedule.STEP_LABEL_3)}
              </Typography>
            </CustomTableCell>
            <CustomTableCell>
              <Typography variant="body1">{dates.join(", ")}</Typography>
            </CustomTableCell>
          </CustomTableRow>
          {!isReCountOrDaily && (
            <CustomTableRow>
              <CustomTableCell align="right">
                <Typography variant="h6">
                  {t(physicalCountSchedule.STEP_LABEL_4)}
                </Typography>
              </CustomTableCell>
              <CustomTableCell>
                {!selectedSupplies.length && (
                  <Typography variant="body1">{t(commons.ALL)}</Typography>
                )}
                {!!selectedSupplies.length && (
                  <ul style={{ margin: 0, paddingLeft: "inherit" }}>
                    {selectedSupplies.map((supply, index) => (
                      <li key={`FinalStepSupplies${index}`}>
                        {`${t(commons.SKU)}: ${supply.sku} - ${t(
                          commons.NAME
                        )}: ${supply.name} - ${t(commons.UNIT)}: ${
                          supply.unit
                        } - ${t(commons.GROUP)}: ${supply.group}`}
                      </li>
                    ))}
                  </ul>
                )}
              </CustomTableCell>
            </CustomTableRow>
          )}
          {isReCountOrDaily && (
            <CustomTableRow>
              <CustomTableCell align="right">
                <Typography variant="h6">{t(commons.RE_COUNT)}</Typography>
              </CustomTableCell>
              <CustomTableCell>
                {!!massiveKeys.length && (
                  <ul style={{ margin: 0, paddingLeft: "inherit" }}>
                    {massiveKeys.map((massiveKey, index) => (
                      <li key={`FinalStepReCountData-${index}`}>
                        {`${t(commons.SELECT_LOCATION)}: ${massiveKey} - 
                        ${t(commons.SKU)}: ${massiveData[massiveKey].join(
                          ", "
                        )}`}
                      </li>
                    ))}
                  </ul>
                )}
              </CustomTableCell>
            </CustomTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(FinalStep);
