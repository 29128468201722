import Close from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { commons, supplyReception } from "app/i18n/types";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface CreateSupplyingModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const CreateSupplyingModal: FunctionComponent<CreateSupplyingModalProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Paper sx={{ p: 3, display: "flex", flexDirection: "column", gap: 2 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5">
              {t(supplyReception.REQUEST_SUPPLIES_TAB)}
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
          <Alert severity="info">
            {t(supplyReception.CONFIRM_SUPPLY_CREATION_INFO)}
          </Alert>
          <Box display="flex" justifyContent="space-between" gap={1}>
            <Button variant="outlined" color="error" onClick={onClose}>
              {t(commons.CANCEL)}
            </Button>
            <Button variant="contained" color="primary" onClick={onConfirm}>
              {t(commons.CONFIRM)}
            </Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default CreateSupplyingModal;
