import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { transferPackingDialog } from "app/i18n/types";
import { ProductionLabel } from "core/productions/entities/Productions";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import CustomDialogTitle from "../../../../Dialog/CustomDialogTitle";
import ProductionLabelList from "./List";

interface ProductionLabelDialogProps {
  open: boolean;
  labels: ProductionLabel[] | null | undefined;
  onClose: () => void;
  showName?: boolean;
}

const ProductionLabelDialog: FunctionComponent<ProductionLabelDialogProps> = (
  props
) => {
  const { open, labels, onClose, showName } = props;

  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <CustomDialogTitle
        title={transferPackingDialog.SUBTITLE_FOR_KITCHEN}
        status="succeeded"
        onClose={onClose}
      />

      <DialogContent>
        <ProductionLabelList labels={labels ?? []} showName={showName} />
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
          color="error"
          startIcon={<CloseOutlinedIcon />}
          fullWidth
        >
          {t(transferPackingDialog.CANCEL)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductionLabelDialog;
