import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Chip, Grid } from "@mui/material";
import { productionManagement } from "app/i18n/types";
import { unitShortNameToUnitName } from "core/common/utils/unitShortNameToUnitName";
import { ProductionRecord } from "core/productions/entities/Productions";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import ProgressBar from "../../ProgressBar";

interface Props {
  production: ProductionRecord;
}

const ProductionCardLabelProgress: FunctionComponent<Props> = (props) => {
  const { production } = props;
  const { labels, producedQuantity, status } = production;

  const [color, setColor] = useState<string>("");
  const { t } = useTranslation();

  const quantityPacked = labels.reduce((sum, label) => sum + label.quantity, 0);

  const getPacketProgress = (): number => {
    return producedQuantity ? (quantityPacked * 100) / producedQuantity : 0;
  };

  const getQuantityForPackaging = (): number => {
    return status === "FINISHED" ? producedQuantity - quantityPacked : 0;
  };

  const unit = unitShortNameToUnitName(production.unit);

  const quantityForPackaging = getQuantityForPackaging();

  const packedProgress = getPacketProgress();

  return (
    <Grid container>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <Chip
          icon={
            !quantityForPackaging ? (
              <CheckCircleOutlineOutlinedIcon
                sx={{ color: "white !important" }}
              />
            ) : (
              <ErrorOutlineOutlinedIcon sx={{ color: "white !important" }} />
            )
          }
          label={
            !quantityForPackaging
              ? `${t(productionManagement.PACKAGING_COMPLETE)}`
              : `${t(
                  productionManagement.PACKAGING_PARTIAL
                )} ${quantityForPackaging} ${unit.toLowerCase()}`
          }
          sx={{
            backgroundColor: color,
            color: "white",
            fontWeight: "bold",
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ProgressBar
          percent={packedProgress}
          setColor={setColor}
          compact
          hidePercentLabel
        />
      </Grid>
    </Grid>
  );
};

export default ProductionCardLabelProgress;
