import { AppConfig } from "config/app";
import { Country } from "core/common/entities/Country";
import {
  Supply,
  SupplyInactiveKitchen,
  UpdateSupplyStatusPayload,
} from "core/supplies/entities/Supply";
import { CanGetSuppliesByCountry } from "core/supplies/interfaces/supplies/canGetSuppliesByCountry";
import { CanGetSupplyInactiveStatusForKitchens } from "core/supplies/interfaces/supplies/canGetSupplyInactiveStatusForKitchens";
import { CanSetSupliesStateByKitchens } from "core/supplies/interfaces/supplies/canSetSupliesStateByKitchens";
import { ApiVersion, getHttp, postHttp } from "utils/http";

export class SupplyHTTPRepository
  implements
    CanGetSuppliesByCountry,
    CanSetSupliesStateByKitchens,
    CanGetSupplyInactiveStatusForKitchens
{
  constructor(private readonly config: AppConfig) {}

  public async getSuppliesByCountry(country: Country): Promise<Array<Supply>> {
    try {
      const suppliesResponse = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `supplies-management/${Country[country]}`
      );
      const suppliesResponseJson: Array<Supply> = await suppliesResponse.json();
      return suppliesResponseJson;
    } catch (err) {
      console.error("getSuppliesByCountry error", err);
      throw err;
    }
  }

  public async setSupliesStateByKitchens(
    payload: Array<UpdateSupplyStatusPayload>
  ): Promise<boolean> {
    try {
      const suppliesResponse = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `supplies-management/update-state`,
        payload
      );
      const suppliesResponseJson: boolean = await suppliesResponse.json();
      return suppliesResponseJson;
    } catch (err) {
      console.error("setSupliesStateByKitchens error", err);
      throw err;
    }
  }

  public async getSupplyInactiveStatusForKitchens(
    sku: string
  ): Promise<Array<SupplyInactiveKitchen>> {
    try {
      const suppliesResponse = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `supplies-management/inactive/${sku}`
      );
      const suppliesResponseJson: Array<SupplyInactiveKitchen> =
        await suppliesResponse.json();
      return suppliesResponseJson;
    } catch (err) {
      console.error("getSupplyInactiveStatusForKitchens error", err);
      throw err;
    }
  }

  public async getInactiveSuppliesByLocation(
    locationId: string
  ): Promise<Array<SupplyInactiveKitchen>> {
    try {
      const suppliesResponse = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `supplies-management/inactive/location/${locationId}`
      );
      const suppliesResponseJson: Array<SupplyInactiveKitchen> =
        await suppliesResponse.json();
      return suppliesResponseJson;
    } catch (err) {
      console.error("getInactiveSuppliesByLocation error", err);
      throw err;
    }
  }
}
