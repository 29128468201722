import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { getProductionsCatalogByKitchen } from "app/store/slices/productions/thunks";
import { ProductionCatalog } from "core/productions/entities/Catalog";
import { FunctionComponent, ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";

interface ProductionSelectorProps {
  kitchenId: string;
  selectedProduction: ProductionCatalog | null;
  setSelectedProduction: (selectedProduction: ProductionCatalog | null) => void;
  list?: ProductionCatalog[];
  label?: string;
  size?: "medium" | "small";
  skusToDisabled?: string[];
  endAdornment?: ReactNode;
}

const ProductionSelector: FunctionComponent<ProductionSelectorProps> = (
  props
) => {
  const {
    kitchenId,
    selectedProduction,
    setSelectedProduction,
    label,
    size,
    list,
    skusToDisabled,
    endAdornment,
  } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const productionsState = useAppSelector((state) => state.productions.status);
  const productions = useAppSelector(
    (state) => state.productions.productionCatalog
  );

  const onSelectProductionCatalog = (production: ProductionCatalog | null) => {
    setSelectedProduction(production);
  };

  useEffect(() => {
    if (kitchenId && !list) {
      dispatch(getProductionsCatalogByKitchen(kitchenId));
    }
  }, [kitchenId]);

  return (
    <Autocomplete
      size={size ?? "medium"}
      options={list ?? productions ?? []}
      loading={productionsState === "loading"}
      getOptionLabel={(production: ProductionCatalog) =>
        `${production.sku} - ${production.name}`
      }
      getOptionDisabled={(option) => !!skusToDisabled?.includes(option.sku)}
      onChange={(_, value) => onSelectProductionCatalog(value)}
      value={selectedProduction}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label ? t(label) : undefined}
          variant="outlined"
          name="name"
        />
      )}
      popupIcon={
        <>
          {!!endAdornment && endAdornment}
          {productionsState === "loading" ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <ArrowDropDownOutlinedIcon />
          )}
        </>
      }
      disabled={!productions && !list}
    />
  );
};

export default ProductionSelector;
