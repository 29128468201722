import { AdjustmentPayload } from "core/adjustments/entities/Adjusment";
import { CanSendAdjustments } from "core/adjustments/interfaces/adjustment/canSendAdjustments";
import { UseCase } from "core/common/interfaces/useCase";

export class SendAdjustmentsUseCase implements UseCase {
  constructor(private readonly repository: CanSendAdjustments) {}

  public execute(fileName: string, adjustments: Array<AdjustmentPayload>) {
    return this.repository.sendAdjustments(fileName, adjustments);
  }
}
