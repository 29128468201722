import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Box, Chip, Paper } from "@mui/material";
import ProgressBar from "app/components/ProgressBar";
import { purchaseOrderLine } from "app/i18n/types";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

interface POLProgressBarProps {
  percentage: number;
  pendingLines: number;
}

const POLProgressBar: FunctionComponent<POLProgressBarProps> = (props) => {
  const { percentage, pendingLines } = props;
  const { t } = useTranslation();

  const [color, setColor] = useState<string>("");

  const isComplete = color === "#E1F1E1";

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Chip
          icon={
            isComplete ? (
              <CheckCircleOutlineOutlinedIcon
                sx={{ color: "white !important" }}
              />
            ) : (
              <ErrorOutlineOutlinedIcon sx={{ color: "white !important" }} />
            )
          }
          label={t(
            isComplete
              ? purchaseOrderLine.RECEIPT_COMPLETE
              : purchaseOrderLine.RECEIPT_PENDING,
            { pendingLines }
          )}
          size="small"
          sx={{
            backgroundColor: color,
            color: "white",
            fontWeight: "bold",
            borderRadius: 2,
          }}
        />
        <Box sx={{ width: "100%", pt: 0.5 }}>
          <Paper sx={{ p: 0.2 }}>
            <ProgressBar
              percent={percentage}
              setColor={setColor}
              compact
              hidePercentLabel
              fullWidth
            />
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default POLProgressBar;
