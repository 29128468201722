import { array, number, object, string } from "yup";

export const rowSchema = array().of(
  object({
    sku: number().required(),
    quantity: number().required(),
    scheduledDate: string().required(),
    timeSlot: string().required(),
  })
);
