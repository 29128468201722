import { AlertColor, SnackbarOrigin } from "@mui/material";
import { StoreRequestError, StoreRequestStatus } from "app/store/types";
import { Product } from "core/dailyAdjustments/entities/Catalog";
import { Kitchen } from "core/supplies/entities/Kitchen";

export interface Alert {
  show?: boolean;
  title?: string | null;
  message?: string | null;
  duration?: number | null;
  severity?: AlertColor;
  position?: SnackbarOrigin;
  icon?: React.ReactNode;
  action?: React.ReactNode;
  audioOn?: boolean | null;
}

export interface KitchensState {
  data: Kitchen[];
  status: StoreRequestStatus;
  error: StoreRequestError;
}

export interface ProductsState {
  data: Product[];
  status: StoreRequestStatus;
  error: StoreRequestError;
}

export interface GlobalState {
  alert: Alert;
  title: string | null;
  kitchens: KitchensState;
  selectedKitchen?: Kitchen;
  products: ProductsState;
  canSearchOnTopBar: boolean;
  textSearchOnTopBar: string;
  textSearchOnFullDialog: string;
  canSearchOnFullDialog: boolean;
}

export const initialState: GlobalState = {
  alert: { show: false },
  selectedKitchen: undefined,
  kitchens: {
    data: [],
    status: "idle",
    error: null,
  },
  products: {
    data: [],
    status: "idle",
    error: null,
  },
  title: null,
  canSearchOnTopBar: false,
  textSearchOnTopBar: "",
  textSearchOnFullDialog: "",
  canSearchOnFullDialog: false,
};
