import { UseCase } from "core/common/interfaces/useCase";
import { CanGetItemCountHistory } from "core/physicalCount/interfaces/product/canGetItemHistory";

export class GetItemCountHistory implements UseCase {
  constructor(private readonly repository: CanGetItemCountHistory) {}

  public execute(countingId: string, sku: string) {
    return this.repository.getItemCountHistory(countingId, sku);
  }
}
