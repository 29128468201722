import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import { CommentType } from "core/comment/entity";
import CommentArea from ".";

interface Props {
  sourceId: string;
  type: CommentType;
  kitchenId: string;
  openDrawer: boolean;
  setOpenDrawer: (open: boolean) => void;
  onChangeMessagesCount: (count: number) => void;
}

const CommentDrawer = (props: Props) => {
  const {
    sourceId,
    type,
    kitchenId,
    openDrawer,
    setOpenDrawer,
    onChangeMessagesCount,
  } = props;

  const onCloseDrawer = () => setOpenDrawer(false);

  return (
    <>
      <CommentArea
        sourceId={sourceId}
        type={type}
        kitchenId={kitchenId}
        onChangeMessagesCount={onChangeMessagesCount}
        noRender
      />
      <Drawer
        anchor="right"
        onClose={onCloseDrawer}
        open={openDrawer}
        style={{ zIndex: "1399" }}
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            width: 480,
            zIndex: 20000,
          },
        }}
      >
        <IconButton
          onClick={onCloseDrawer}
          color="default"
          sx={{
            position: "absolute",
            top: 8,
            left: 8,
            backgroundColor: "white",
            "&:hover": {
              backgroundColor: "white",
            },
          }}
        >
          <Icon>close</Icon>
        </IconButton>

        <Box sx={{ mt: 4, p: 2 }}>
          <CommentArea
            sourceId={sourceId}
            type={type}
            kitchenId={kitchenId}
            onChangeMessagesCount={onChangeMessagesCount}
            inDrawer
          />
        </Box>
      </Drawer>
    </>
  );
};

export default CommentDrawer;
