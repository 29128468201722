import { Group } from "core/physicalCount/entities/Group";
import { Product } from "core/physicalCount/entities/Product";

export const getGroup = (
  groups: Array<Group>,
  sku: string
): { group: Group; groupIndex: number } => {
  const groupIndex = groups.findIndex((group) =>
    group.groupItems.some((item) => item.SKU === sku)
  );

  if (groupIndex < 0) {
    throw new Error(`Group with item SKU ${sku} not found`);
  }

  const group: Group = groups[groupIndex];

  return {
    group,
    groupIndex,
  };
};

export const getItem = (
  items: Array<Product>,
  sku: string
): { item: Product; itemIndex: number } => {
  const itemIndex = items.findIndex((item) => item.SKU === sku);

  if (itemIndex < 0) {
    throw new Error(`Item with ${sku} not found`);
  }

  const item: Product = items[itemIndex];

  return {
    item,
    itemIndex,
  };
};
