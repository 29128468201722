import Grid from "@mui/material/Grid";
import { ProductionLabel } from "core/productions/entities/Productions";
import { FunctionComponent } from "react";
import ProductionLabelItem from "./Item";

interface ProductionLabelListProps {
  labels: ProductionLabel[];
  showName?: boolean;
}

const ProductionLabelList: FunctionComponent<ProductionLabelListProps> = (
  props
) => {
  const { labels, showName } = props;

  return (
    <Grid container spacing={1}>
      {labels.map((label) => (
        <Grid xs={12} md={12} item key={`GridProductionLabelList${label.id}`}>
          <ProductionLabelItem
            key={`ProductionLabelList${label.id}`}
            label={label}
            showName={showName}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ProductionLabelList;
