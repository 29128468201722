import { UseCase } from "core/common/interfaces/useCase";
import { CanGetPendingTransferOrdersByLocationId } from "core/transfer/interfaces/transfer/canGetPendingTransferOrdersByLocationId";

export class GetPendingTransferOrdersByLocationIdUseCase implements UseCase {
  constructor(
    private readonly repository: CanGetPendingTransferOrdersByLocationId
  ) {}

  public execute(locationId: string) {
    return this.repository.getPendingTransferOrdersByLocationId(locationId);
  }
}
