import { LocalShipping } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PendingIcon from "@mui/icons-material/Pending";
import Chip from "@mui/material/Chip";
import { FunctionComponent } from "react";

import { useTranslation } from "react-i18next";
import { DataFilterTransferOrder } from "utils/general";

interface TransferChipStatusProps {
  status: string;
}

const TransferChipStatus: FunctionComponent<TransferChipStatusProps> = ({
  status,
}) => {
  const { t } = useTranslation();
  const labelStatus = t(`status.${status}`);

  switch (status) {
    case DataFilterTransferOrder.RETURNED:
    case DataFilterTransferOrder.RELEASED:
    case DataFilterTransferOrder.IN_PROCESS:
      return (
        <Chip
          icon={<PendingIcon />}
          label={labelStatus}
          color={"warning"}
          variant="outlined"
        />
      );
    case DataFilterTransferOrder.PACKED:
      return (
        <Chip
          icon={<NotificationsIcon />}
          label={labelStatus}
          color={"info"}
          variant="outlined"
        />
      );
    case DataFilterTransferOrder.IN_TRANSIT:
      return (
        <Chip
          icon={<LocalShipping />}
          label={labelStatus}
          color={"primary"}
          variant="outlined"
        />
      );
    case DataFilterTransferOrder.PARTIAL_RECEIVED:
    case DataFilterTransferOrder.RECEIVED:
      return (
        <Chip
          icon={<CheckCircleIcon />}
          label={labelStatus}
          color={"success"}
          variant="outlined"
        />
      );
    default:
      return <></>;
  }
};

export default TransferChipStatus;
