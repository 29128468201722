import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import KitchenSelector from "app/components/Kitchen/Selector";
import PageHeader from "app/components/PageHeader";
import TransferOrdersTable from "app/components/TransferOrders/TransferOrdersTable";
import InputSearchCustom from "app/components/common/InputSearchCustom";
import { transferModule, transferOrderModule } from "app/i18n/types";
import { Kitchen } from "core/supplies/entities/Kitchen";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataFilterTransferOrder } from "utils/general";
import { PageBox } from "utils/generalUI";

interface Props {}

const TransferOrderPage: FunctionComponent<Props> = () => {
  const { t } = useTranslation();

  const [search, setSearch] = useState<string>("");
  const [kitchen, setKitchen] = useState<Kitchen | null>(null);

  const [selectedStatus, setSelectedStatus] = useState<DataFilterTransferOrder>(
    DataFilterTransferOrder.ALL
  );

  const handleStatusInputChange = (_: unknown, value: any) => {
    setSelectedStatus(value);
  };

  useEffect(() => {
    setSearch("");
    setSelectedStatus(DataFilterTransferOrder.ALL);
  }, [kitchen]);

  return (
    <PageBox>
      <PageHeader
        title={t(transferOrderModule.TITLE)}
        subtitle={t(transferOrderModule.DESCRIPTION)}
      />
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item sm={9} xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {t(transferOrderModule.FILTER_ORDERS)}
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={9}>
                <KitchenSelector onKitchenChange={setKitchen} />
              </Grid>
              <Grid item sm={3}>
                <Autocomplete
                  defaultValue={DataFilterTransferOrder.ALL}
                  value={selectedStatus}
                  options={[
                    DataFilterTransferOrder.ALL,
                    DataFilterTransferOrder.IN_PROCESS,
                    DataFilterTransferOrder.PACKED,
                    DataFilterTransferOrder.IN_TRANSIT,
                    DataFilterTransferOrder.RECEIVED,
                    DataFilterTransferOrder.PARTIAL_RECEIVED,
                    DataFilterTransferOrder.RETURNED,
                    DataFilterTransferOrder.RELEASED,
                  ]}
                  onChange={handleStatusInputChange}
                  getOptionLabel={(option) => t(`status.${option}`)}
                  renderInput={(params) => (
                    <TextField {...params} label={t(transferModule.STATUS)} />
                  )}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item sm={3} xs={6}>
          <InputSearchCustom
            title={transferOrderModule.FIND_ORDERS}
            label={transferOrderModule.FIND_ORDER_LABEL}
            search={search}
            setSearch={setSearch}
          />
        </Grid>
      </Grid>
      {kitchen && (
        <TransferOrdersTable
          kitchenId={kitchen.kitchenId}
          search={search}
          status={selectedStatus}
        />
      )}
    </PageBox>
  );
};

export default TransferOrderPage;
