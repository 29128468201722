import Box from "@mui/system/Box";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import {
  clearInactiveKitchensState,
  clearSuppliesByCountryState,
} from "app/store/slices/supplies";
import {
  getSuppliesByCountry,
  getSupplyInactiveKitchens,
  setSupliesStateByKitchens,
} from "app/store/slices/supplies/thunks";
import { Country } from "core/common/entities/Country";
import {
  Supply,
  UpdateSupplyStatusPayload,
} from "core/supplies/entities/Supply";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import BySuppliesTable from "./BySuppliesTable";
import SuppliesManagementUpdateStateDialog from "./SuppliesManagementUpdateDialog";
import SupplyInfoDrawer from "./SupplyInfoDrawer";
import Toolbar from "./Toolbar";

interface BySuppliesTabProps {}

const BySuppliesTab: FunctionComponent<BySuppliesTabProps> = () => {
  const dispatch = useAppDispatch();

  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const [selectedSupplies, setSelectedSupplies] = useState<Array<Supply>>([]);
  const [checkedSupplies, setCheckedSupplies] = useState<Array<string>>([]);
  const [openUpdateDialog, setOpenUpdateDialog] = useState<boolean>(false);
  const [selectedSupplyInfo, setSelectedSupplyInfo] = useState<Supply | null>(
    null
  );

  const kitchens = useAppSelector((state) => state.global.kitchens.data);

  const setSupliesStateByKitchensRequest = useAppSelector(
    (state) => state.supplies.setSupliesStateByKitchens
  );

  const suppliesRequest = useAppSelector(
    (state) => state.supplies.suppliesByCountry
  );

  const getSupplies = useCallback(() => {
    if (selectedCountry) {
      const country = Country[selectedCountry as keyof typeof Country];
      dispatch(getSuppliesByCountry(country));
    }
  }, [selectedCountry]);

  const onSupplyChange = useCallback(
    (supply: Supply | null) => {
      if (supply === null) {
        return;
      }

      if (selectedSupplies.some((supp) => supp.sku === supply.sku)) {
        return;
      }

      setSelectedSupplies((prev) => [...prev, supply]);
    },
    [selectedSupplies]
  );

  const toggleChecked = useCallback(
    (sku: string) => {
      if (checkedSupplies.includes(sku)) {
        setCheckedSupplies((prevState) =>
          prevState.filter((prevSelected) => prevSelected !== sku)
        );

        return;
      }

      setCheckedSupplies((prevState) => [...prevState, sku]);
    },
    [checkedSupplies]
  );

  const toggleSelectAll = useCallback(() => {
    if (checkedSupplies.length === selectedSupplies?.length) {
      setCheckedSupplies([]);
      return;
    }

    if (selectedSupplies === null) {
      return;
    }

    setCheckedSupplies(selectedSupplies.map((supply) => supply.sku));
  }, [selectedSupplies, checkedSupplies]);

  const unselectSupplies = useCallback(
    (skus: Array<string>) => {
      if (selectedSupplies === null) {
        return;
      }

      setSelectedSupplies((prevSupplies) =>
        prevSupplies.filter((prevSupply) => !skus.includes(prevSupply.sku))
      );

      setCheckedSupplies((prevCheckedSupplies) =>
        prevCheckedSupplies.filter(
          (prevCheckedSupply) => !skus.includes(prevCheckedSupply)
        )
      );
    },
    [selectedSupplies]
  );

  const clear = useCallback(() => {
    setOpenUpdateDialog(false);
    setSelectedSupplyInfo(null);
    setSelectedCountry(null);
    setSelectedSupplies([]);
    setCheckedSupplies([]);
    dispatch(clearSuppliesByCountryState());
  }, []);

  const onUpdateStatus = useCallback(() => {
    setOpenUpdateDialog(true);
  }, []);

  const updateSuppliesActiveStatus = useCallback(
    (
      kitchenIds: Array<string>,
      suppliesSkus: Array<string>,
      status: boolean
    ) => {
      const payload: Array<UpdateSupplyStatusPayload> = kitchenIds
        .map((kitchenId) =>
          suppliesSkus.map((sku) => ({
            sku,
            kitchenId,
            state: status,
            comments: "",
          }))
        )
        .flat();

      dispatch(setSupliesStateByKitchens(payload));
    },
    []
  );

  const supplyInactiveKitchensRequest = useAppSelector(
    (state) => state.supplies.supplyInactiveStatusForKitchens
  );

  const getInactiveKitchens = useCallback(() => {
    if (selectedSupplyInfo !== null) {
      dispatch(getSupplyInactiveKitchens(selectedSupplyInfo.sku));
    }
  }, [selectedSupplyInfo]);

  useEffect(() => {
    if (selectedSupplyInfo !== null) {
      getInactiveKitchens();
    } else {
      dispatch(clearInactiveKitchensState());
    }
  }, [selectedSupplyInfo]);

  useEffect(() => {
    getSupplies();
  }, [getSupplies, selectedCountry]);

  return (
    <Box display="flex" gap={2} flexDirection="column">
      <Toolbar
        suppliesOptions={
          selectedCountry === null ? [] : suppliesRequest.supplies
        }
        onCountryChange={setSelectedCountry}
        selectedSupplies={selectedSupplies}
        onSupplyChange={onSupplyChange}
        unselectSupplies={unselectSupplies}
        checkedSupplies={checkedSupplies}
        loading={suppliesRequest.status === "loading"}
        onUpdateStatus={onUpdateStatus}
      />

      <Box>
        <BySuppliesTable
          supplies={selectedSupplies}
          loading={suppliesRequest.status === "loading"}
          checkedSupplies={checkedSupplies}
          onSupplyCheck={toggleChecked}
          toggleSelectAll={toggleSelectAll}
          unselectSupplies={unselectSupplies}
          onClickInfo={(supply) => setSelectedSupplyInfo(supply)}
        />
      </Box>

      <SuppliesManagementUpdateStateDialog
        open={openUpdateDialog}
        onClose={() => setOpenUpdateDialog(false)}
        selectedSupplies={selectedSupplies}
        kitchens={
          kitchens.filter((kitchen) =>
            selectedCountry !== null
              ? kitchen.country === selectedCountry
              : false
          ) ?? []
        }
        onContinue={(selectedKitchens, activeStatus) => {
          updateSuppliesActiveStatus(
            selectedKitchens,
            selectedSupplies.map((supply) => supply.sku),
            activeStatus
          );
          clear();
        }}
        loading={setSupliesStateByKitchensRequest.status === "loading"}
      />

      <SupplyInfoDrawer
        supply={selectedSupplyInfo}
        open={selectedSupplyInfo !== null}
        onClose={() => setSelectedSupplyInfo(null)}
        inactiveKitchens={supplyInactiveKitchensRequest.inactiveKitchens}
        getInactiveKitchens={getInactiveKitchens}
        kitchens={
          kitchens?.filter((kitchen) =>
            selectedCountry !== null
              ? kitchen.country === selectedCountry
              : false
          ) ?? []
        }
        onSwitchChange={(
          sku: string,
          kitchenId: string,
          switchState: boolean
        ) => updateSuppliesActiveStatus([kitchenId], [sku], switchState)}
        loading={
          setSupliesStateByKitchensRequest.status === "loading" ||
          supplyInactiveKitchensRequest.status === "loading"
        }
      />
    </Box>
  );
};

export default BySuppliesTab;
