import { ObjectParams } from "utils/http";

export interface PaginationParams {
  page: number;
  rows: number;
  search?: string;
  searchBy?: string[];
  extra?: ObjectParams;
}

export const paginationParamsToObjectParams = (
  params: PaginationParams
): ObjectParams => {
  const { extra } = params;
  const objectParams: ObjectParams = {};
  const extraKey = "extra";
  Object.keys(params).forEach((key) => {
    if (key !== extraKey) {
      const value = params[key as keyof PaginationParams];
      objectParams[key] = value !== undefined ? String(value) : "";
    }
  });
  if (extra) {
    const extras: string[] = [];
    Object.keys(extra).forEach((key) => {
      const value = extra[key];
      if (value) extras.push(`${key}:${value}`);
    });
    if (extras.length) objectParams[extraKey] = String(extras);
  }
  return objectParams;
};
