import { UseCase } from "core/common/interfaces/useCase";
import { AuthorizeAdjustmentsPayload } from "core/dailyAdjustments/entities/Adjustment";
import { CanAuthorizeAdjustments } from "core/dailyAdjustments/interfaces/adjustment/canAuthorizeAdjustments";

export class AuthorizeAdjustments implements UseCase {
  constructor(private readonly repository: CanAuthorizeAdjustments) {}

  public execute(adjustments: Array<AuthorizeAdjustmentsPayload>) {
    return this.repository.authorizeAdjustments(adjustments);
  }
}
