import { TextField } from "@mui/material";
import { Purchase } from "core/purchaseOrders/entities/PurchaseOrder";
import { updatePurchaseOrderEstimatedDate } from "core/purchaseOrders/repositories/http/purchase";
import { FunctionComponent, useEffect, useState } from "react";

interface Props {
  purchase: Purchase;
  onUpdateItem?: (id: string, purchase: Purchase) => void;
}

const UpdateExpectedReceiptAtTextField: FunctionComponent<Props> = (props) => {
  const { purchase, onUpdateItem } = props;
  const [newEstimatedDate, setNewEstimatedDate] = useState<string>();

  const handleUpdateEstimatedDate = async (expectedReceiptAt: string) => {
    if (expectedReceiptAt === purchase.expectedReceiptAt?.toString()) return;
    if (!purchase.orderId || !expectedReceiptAt) return;

    updatePurchaseOrderEstimatedDate(
      Number(purchase.orderId),
      expectedReceiptAt
    );
    onUpdateItem?.(purchase.orderId, {
      ...purchase,
      expectedReceiptAt,
    });
  };

  useEffect(() => {
    setNewEstimatedDate(purchase.expectedReceiptAt?.toString());
  }, [purchase]);

  useEffect(() => {
    if (newEstimatedDate) {
      handleUpdateEstimatedDate(newEstimatedDate);
    }
  }, [newEstimatedDate]);

  return (
    <TextField
      type="date"
      value={newEstimatedDate}
      onChange={(e) => {
        setNewEstimatedDate(e.target.value || newEstimatedDate);
      }}
      autoComplete="off"
    />
  );
};

export default UpdateExpectedReceiptAtTextField;
