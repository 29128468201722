import CloudSyncOutlinedIcon from "@mui/icons-material/CloudSyncOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  FormControl,
  Grid,
  Paper,
  TableContainer,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CountrySelectorDropdown from "app/components/CountrySelector/CountrySelectorDropdown";
import DocumentsViewer from "app/components/DocumentViewer";
import PageHeader from "app/components/PageHeader";
import CommonDialog from "app/components/common/CommonDialog";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import {
  commons,
  dailyAdjustmentsReport,
  kitchenInventoryManagement,
} from "app/i18n/types";
import {
  createDailyAdjustmentReport,
  sendToValidationAdjustments,
} from "app/store/slices/dailyAdjustments/thunks";
import dayjs from "app/utils/dayjs";
import { Movement, allReasons } from "config/dailyAdjustment";
import { AttachmentTypeEnum } from "core/attachment/entity";
import { downloadTextAsCsv, json2Csv } from "core/common/utils/fileUtils";
import { Status } from "core/dailyAdjustments/entities/Adjustment";
import {
  ReportRecord,
  ReportRequest,
} from "core/dailyAdjustments/entities/Report";
import { OriginType } from "core/purchaseOrders/entities/PurchaseOrder";
import { Kitchen } from "core/supplies/entities/Kitchen";
import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { getUserImage } from "utils/general";
import { PageBox } from "utils/generalUI";
import { TypeOfRole, isSuperRole } from "utils/role";
import InputSearchCustom from "../../components/common/InputSearchCustom";

const DailyAdjustmentReport = () => {
  const { t } = useTranslation();

  const createDailyReport = useAppSelector(
    (state) => state.dailyAdjustments.createDailyReport
  );

  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const [selectedKitchen, setSelectedKitchen] = useState<Array<Kitchen> | null>(
    null
  );
  const [selectedTypes, setSelectedType] = useState<Array<string> | null>(null);

  const user = useAppSelector((state) => state.session.user.data);
  const kitchens = useAppSelector((state) => state.global.kitchens.data);

  const dispatch = useAppDispatch();

  const [initDate, setInitDate] = React.useState<Date | null>(null);
  const [endDate, setEndDate] = React.useState<Date | null>(null);
  const [textToSearch, setTextToSearch] = React.useState<string>("");
  const [selectedItems, setSelectedItems] = React.useState<number[]>([]);
  const [confirmDialogOpen, setConfirmDialogOpen] =
    React.useState<boolean>(false);
  const [sourceId, setSourceId] = React.useState("");
  const [kitchenId, setKitchenId] = React.useState("");
  const [openDocumentList, setOpenDocumentList] = React.useState(false);

  const handleInitDate = (newValue: Date | null) => {
    setInitDate(newValue);
  };
  const handleEndDate = (newValue: Date | null) => {
    setEndDate(newValue);
  };

  const columns: GridColDef[] = [
    { field: "sku", headerName: t(commons.SKU).toUpperCase(), width: 60 },
    { field: "name", headerName: t(commons.SUPPLY).toUpperCase(), width: 300 },
    {
      field: "quantity",
      headerName: t(commons.REPORTED_AMOUNT).toUpperCase(),
      width: 180,
    },
    { field: "unit", headerName: t(commons.UNITS).toUpperCase(), width: 100 },
    {
      field: "type",
      headerName: t(commons.TYPE).toUpperCase(),
      width: 200,
      renderCell: (params) => {
        const adjustment = params.row as ReportRecord;
        if (adjustment.type === AttachmentTypeEnum.LOSS) {
          return (
            <Box sx={{ display: "table-cell" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  textTransform="uppercase"
                  color="text.secondary"
                  sx={{ px: 2, paddingLeft: "0 !important" }}
                >
                  {t(`adjustmentType.${adjustment.type}`)}
                </Typography>
                <Button
                  variant="text"
                  color="info"
                  size="small"
                  onClick={() => {
                    setSourceId(adjustment.id.toString());
                    setKitchenId(adjustment.location);
                    setOpenDocumentList(true);
                  }}
                >
                  <VisibilityOutlinedIcon />
                </Button>
              </Box>
              {adjustment.motiveId && (
                <Typography textTransform="uppercase" color="text.secondary">
                  {t(allReasons[adjustment.motiveId])}
                </Typography>
              )}
            </Box>
          );
        }
        return <>{t(`adjustmentType.${adjustment.type}`)}</>;
      },
    },
    { field: "location", headerName: t(commons.SELECT_LOCATION), width: 200 },
    {
      field: "responsible",
      headerName: t(commons.PERSON_IN_CHARGE).toUpperCase(),
      width: 300,
      renderCell: (params) => {
        const adjustment = params.row;
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Avatar
              alt={adjustment.responsible}
              sx={{ width: 32, height: 32, mx: "auto" }}
              src={getUserImage(adjustment.responsibleId)}
            />
            <Typography>{adjustment.responsible}</Typography>
          </Box>
        );
      },
    },
    {
      field: "date",
      headerName: t(commons.DATE).toUpperCase(),
      width: 110,
    },
    {
      field: "status",
      headerName: t(commons.STATUS).toUpperCase(),
      width: 100,
      renderCell: (params) => {
        const adjustment = params.row;
        return <>{t(`dailyAdjustmentsReport.${adjustment.status}`)}</>;
      },
    },
  ];

  const filterData = useMemo(() => {
    if (createDailyReport.data === null) {
      return [];
    }
    if (textToSearch !== null && textToSearch.length > 0) {
      return createDailyReport.data.filter(
        (item) =>
          item.name.toLowerCase().includes(textToSearch.toLowerCase()) ||
          item.sku.includes(textToSearch)
      );
    }
    return createDailyReport.data;
  }, [createDailyReport.data, t, textToSearch]);

  const getCSV = () => {
    if (createDailyReport.data != null) {
      const data = createDailyReport.data.map((detail) => {
        return {
          sku: detail.sku,
          name: detail.name,
          unit: detail.unit,
          location: detail.location,
          quantity: detail.quantity,
          type: t(`adjustmentType.${detail.type}`),
          motive: t(detail.motiveId ? allReasons[detail.motiveId] : ""),
          responsible: detail.responsible,
          date: detail.date,
          status: t(`dailyAdjustmentsReport.${detail.status}`),
        };
      });
      downloadTextAsCsv(
        json2Csv(data),
        `daily-adjustment-${new Date().getTime()}.csv`
      );
    }
  };

  const filteredKitchens: Array<Kitchen> = useMemo(() => {
    if (selectedCountry && kitchens.length) {
      setSelectedKitchen(null);

      return kitchens.filter((kitchen) => kitchen.country === selectedCountry);
    }
    return [];
  }, [kitchens, selectedCountry]);

  const countries: Array<string> | null = useMemo(() => {
    if (kitchens.length) {
      return [...new Set(kitchens.map((item) => item.country))];
    }
    return null;
  }, [kitchens]);

  const getReport = useCallback(() => {
    if (initDate === null || endDate === null || selectedCountry === null)
      return;
    const report: ReportRequest = {
      country: selectedCountry,
      startDate: dayjs(initDate).format("YYYY-MM-DD"),
      endDate: dayjs(endDate).format("YYYY-MM-DD"),
      location:
        selectedKitchen !== null
          ? selectedKitchen.map((kitchen) => kitchen.kitchenId)
          : [],
      kind: selectedTypes !== null ? selectedTypes : [],
    };

    dispatch(createDailyAdjustmentReport({ report }));
  }, [
    dispatch,
    endDate,
    initDate,
    selectedCountry,
    selectedKitchen,
    selectedTypes,
  ]);

  const kitchenOptions = useMemo(
    () =>
      filteredKitchens
        .reduce<Array<Kitchen>>((acc, kitchen) => acc.concat(kitchen), [])
        .sort((a, b) => {
          const kitchenA = a.country + a.name.toUpperCase();
          const kitchenB = a.country + b.name.toUpperCase();
          if (kitchenA < kitchenB) {
            return -1;
          }
          if (kitchenA > kitchenB) {
            return 1;
          }
          return 0;
        }),
    [filteredKitchens]
  );

  useEffect(() => {
    if (initDate === null || endDate === null || selectedCountry === null)
      return;
    getReport();
  }, [
    initDate,
    endDate,
    selectedCountry,
    selectedKitchen,
    selectedTypes,
    getReport,
  ]);

  const sendToValidation = async () => {
    const adjustments = selectedItems.filter((id) => {
      const adjustment = filterData.find((el) => el.id === id);
      return adjustment
        ? [Status.APPROVED, Status.VALIDATED].includes(
            adjustment.status as Status
          )
        : false;
    });
    await dispatch(sendToValidationAdjustments({ adjustments }));
    getReport();
    setConfirmDialogOpen(false);
  };

  const isSuperUser = isSuperRole(user?.role as TypeOfRole);

  return (
    <PageBox>
      <PageHeader
        title={t(kitchenInventoryManagement.INVENTORY_INVENTORY_TITLE)}
        subtitle={t(kitchenInventoryManagement.DAILYMOVS_MODULE_DESCRIPTION)}
        rightArea={[
          {
            children: t(commons.SEND_TO_VALIDATION),
            variant: "contained",
            color: "info",
            startIcon: <CloudSyncOutlinedIcon />,
            onClick: () => setConfirmDialogOpen(true),
            disabled: !selectedItems.length,
            show: isSuperUser,
          },
          {
            children: t(commons.EXPORT),
            variant: "contained",
            startIcon: <FileDownloadOutlinedIcon />,
            onClick: getCSV,
          },
          {
            children: t(commons.UPDATE),
            variant: "outlined",
            startIcon: <RestartAltOutlinedIcon />,
            onClick: getReport,
          },
        ]}
      />

      {isSuperUser && !!selectedItems.length && (
        <CommonDialog
          open={confirmDialogOpen}
          handleClose={() => setConfirmDialogOpen(false)}
          handleConfirm={sendToValidation}
          title={t(commons.SEND_TO_VALIDATION)}
          message={t(dailyAdjustmentsReport.SEND_TO_VALIDATION_MESSAGE, {
            supplyQuantity: selectedItems.length,
          })}
          icon={
            <WarningAmberOutlinedIcon
              sx={{
                height: "80px",
                width: "80px",
                paddingRight: "5px",
                color: "orange",
              }}
            />
          }
          showCancelButton={true}
        />
      )}

      {openDocumentList && (
        <DocumentsViewer
          sourceId={sourceId ?? ""}
          origin={OriginType.KIS}
          type={AttachmentTypeEnum.LOSS}
          onClose={() => setOpenDocumentList(false)}
          kitchenId={kitchenId}
        />
      )}

      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={1}>
          <CountrySelectorDropdown
            countries={countries}
            onChange={(countryValue) => setSelectedCountry(countryValue)}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {t(dailyAdjustmentsReport.FILTERS_LABEL)}
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={5} xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <DesktopDatePicker
                        label={t(commons.INIT_DATE)}
                        inputFormat="dd/MM/yyyy"
                        value={initDate}
                        onChange={handleInitDate}
                        renderInput={(params: any) => <TextField {...params} />}
                        disabled={selectedCountry === null}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <DesktopDatePicker
                        label={t(commons.FINAL_DATE)}
                        inputFormat="dd/MM/yyyy"
                        value={endDate}
                        onChange={handleEndDate}
                        renderInput={(params: any) => <TextField {...params} />}
                        disabled={selectedCountry === null}
                      />
                    </Grid>
                  </Grid>
                </LocalizationProvider>
              </Grid>
              <Grid item sm={3} xs={12}>
                <FormControl sx={{ width: "100%" }}>
                  <Autocomplete
                    multiple
                    ChipProps={{
                      sx: {
                        maxWidth: "calc(100% - 48px)!important",
                      },
                    }}
                    limitTags={1}
                    options={Object.keys(Movement)}
                    getOptionLabel={(option) => {
                      return t(`adjustmentType.${option}`);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label={t(commons.TYPE)} />
                    )}
                    onChange={(e: SyntheticEvent<Element, Event>, type) => {
                      setSelectedType(type);
                    }}
                    disabled={
                      selectedCountry === null ||
                      initDate === null ||
                      endDate === null
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item sm={4} xs={12}>
                <FormControl sx={{ width: "100%" }}>
                  <Autocomplete
                    multiple
                    ChipProps={{
                      sx: {
                        maxWidth: "calc(100% - 48px)!important",
                      },
                    }}
                    limitTags={1}
                    options={kitchenOptions}
                    getOptionLabel={(kitchen) => kitchen.name}
                    value={selectedKitchen ?? undefined}
                    disabled={
                      selectedCountry === null ||
                      initDate === null ||
                      endDate === null
                    }
                    onChange={(e: SyntheticEvent<Element, Event>, kitchen) => {
                      setSelectedKitchen(kitchen);
                    }}
                    isOptionEqualToValue={(kitchen, newKitchen) => {
                      return kitchen.kitchenId === newKitchen.kitchenId;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t(commons.SELECT_LOCATION)}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputSearchCustom
            title={commons.FIND_INPUT}
            label={commons.FIND_SUPPLY_LABEL}
            setSearch={setTextToSearch}
          />
        </Grid>
      </Grid>

      {createDailyReport.error !== null && (
        <Alert severity="error">{createDailyReport.error}</Alert>
      )}
      <TableContainer
        style={{ height: "100%", width: "100%", padding: 15 }}
        component={Paper}
      >
        <DataGrid
          loading={createDailyReport.status === "loading"}
          style={{ width: "100%" }}
          columns={columns}
          rows={filterData}
          getRowId={(row) => row.id}
          disableColumnMenu
          disableSelectionOnClick
          checkboxSelection={isSuperUser}
          onSelectionModelChange={(selected) => {
            setSelectedItems(selected as number[]);
          }}
        />
      </TableContainer>
    </PageBox>
  );
};

export default DailyAdjustmentReport;
