import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import AddItemDialog from "app/components/Transfers/AddItemDialog";
import TransferOrdersPackedReviewTable from "app/components/Transfers/TransferOrdersPackedReviewTable";
import CommonDialog from "app/components/common/CommonDialog";
import SearchOnTopBar from "app/components/common/SearchOnTopBar";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { commons, transferModule } from "app/i18n/types";
import { clearExtraItem } from "app/store/slices/transfer";
import {
  archiveTransferOrder,
  getTransferOrderById,
} from "app/store/slices/transfer/thunks";
import {
  TransferOrderLineRefined,
  TransferOrderResponse,
} from "core/transfer/entities/TransferOrder";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  transferLineCompleteValidator,
  transferLineNoSentValidator,
  transferLinePartialValidator,
} from "utils/general";
import Chips from "../Packing/Chips";
import PackingFilters from "../Packing/Filters";
import TransferLinesSummary from "../Summary";

interface Props {
  kitchenId: string;
  transferId: number;
  updateLines: (transferId: number, lines: TransferOrderLineRefined[]) => void;
  updateDelete: (transferId: number) => void;
}

const TransferOrdersReviewPackedPage: FunctionComponent<Props> = (props) => {
  const { kitchenId, transferId, updateDelete, updateLines } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [loadingTransfer, setLoadingTransfer] = useState<boolean>(false);

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [transferOrder, setTransferOrder] =
    useState<TransferOrderResponse | null>(null);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [openAddItemDialog, setOpenAddItemDialog] = useState<boolean>(false);
  const [dataFilter, setDataFilter] = useState<number>(-1);
  const [lines, setLines] = useState<TransferOrderLineRefined[]>([]);
  const [linesFiltered, setLinesFiltered] = useState<
    TransferOrderLineRefined[]
  >([]);

  const [chips, setChips] = useState<string[]>([]);

  useEffect(() => {
    updateSelected();
  }, [transferId]);

  useEffect(() => {
    updateLines(transferId, transferOrder?.lines ?? []);
  }, [transferOrder]);

  const updateSelected = async () => {
    setLoadingTransfer(true);
    const transfer = (await (
      await dispatch(
        getTransferOrderById({ transferId: transferId.toString() })
      )
    ).payload) as TransferOrderResponse;
    if (transfer) {
      setLines(transfer?.lines ?? []);
      setTransferOrder(transfer);
    }
    setLoadingTransfer(false);
  };

  const filterValidate = (line: TransferOrderLineRefined): boolean => {
    switch (dataFilter) {
      case 1:
        return transferLineCompleteValidator(line);
      case 2:
        return transferLinePartialValidator(line);
      case 3:
        return transferLineNoSentValidator(line);
      default:
        return true;
    }
  };

  useEffect(() => {
    setLinesFiltered(
      lines.filter((line) => {
        return (
          filterValidate(line) &&
          chips.includes(t(line.itemGroup)) &&
          chips.includes(t(line.storage ?? transferModule.UNSPECIFIED))
        );
      })
    );
  }, [lines, dataFilter, chips]);

  const onCloseAddExtraItems = async (updateItems: boolean) => {
    setOpenAddItemDialog(false);
    if (kitchenId && updateItems) {
      await updateSelected();
    }
  };

  const updateLine = (id: number, sendQuantity: number) => {
    if (!lines.length) return;
    const tempLines = lines.map((line) => {
      let sendQuantityTmp = line.sendQuantity;
      if (line.id === id) {
        sendQuantityTmp = sendQuantity;
      }
      return { ...line, sendQuantity: sendQuantityTmp };
    });
    setLines(tempLines);
  };

  const handleArchiveTransferOrder = async () => {
    if (transferId) {
      await dispatch(
        archiveTransferOrder({
          transferOrderId: transferId,
        })
      );
      setOpenDeleteDialog(false);
      updateDelete(transferId);
    }
  };

  return (
    <Box>
      <SearchOnTopBar onSearch={setSearchTerm} />
      <Box display="flex" justifyContent="space-between">
        <Chips chips={chips} setChips={setChips} />
        <PackingFilters
          lines={lines}
          chips={chips}
          setChips={setChips}
          compact
        />
      </Box>
      <Box mt={1} textAlign="end">
        <Button
          variant="outlined"
          size="medium"
          color="error"
          startIcon={<DeleteOutlinedIcon />}
          onClick={() => {
            setOpenDeleteDialog(true);
          }}
        >
          {t(commons.DELETE)}
        </Button>
        <Button
          sx={{ ml: 2 }}
          variant="outlined"
          size="medium"
          startIcon={<AddOutlinedIcon />}
          onClick={() => {
            dispatch(clearExtraItem());
            setOpenAddItemDialog(true);
          }}
        >
          {t(commons.ADD)}
        </Button>
      </Box>
      <Box>
        <TransferLinesSummary lines={lines} setDataFilter={setDataFilter} />
      </Box>
      <TransferOrdersPackedReviewTable
        lines={linesFiltered}
        updateLine={updateLine}
        linesStatus={{}}
        searchTerm={searchTerm}
        loading={loadingTransfer}
      />

      {transferOrder && (
        <>
          <AddItemDialog
            lines={lines}
            open={openAddItemDialog}
            kitchenId={kitchenId ?? ""}
            handleClose={onCloseAddExtraItems}
            transferOrderId={transferOrder.id}
          />

          <CommonDialog
            open={openDeleteDialog}
            title={t(transferModule.DELETE_TRANSFER)}
            message={`${t(transferModule.DELETE_TRANSFER_CONFIRM)} ${
              transferOrder.destinationKitchenName
            }`}
            icon={
              <DeleteOutlinedIcon
                sx={{
                  height: "80px",
                  width: "80px",
                  paddingRight: "5px",
                  color: "error.main",
                }}
              />
            }
            showCancelButton={true}
            handleConfirm={handleArchiveTransferOrder}
            handleClose={() => setOpenDeleteDialog(false)}
          />
        </>
      )}
    </Box>
  );
};

export default TransferOrdersReviewPackedPage;
