import {
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSEmptyState,
  KOSRowData,
} from "@foodology-co/alejandria";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import { Box, Button } from "@mui/material";
import { useNavigator } from "app/hooks/useNavigator";
import { commons, vendor } from "app/i18n/types";
import { VendorWithSuppliesCount } from "core/vendors/entities/Vendor";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Actions, VendorModal } from "utils/modal";

interface Props {
  items: VendorWithSuppliesCount[];
  loading?: boolean;
  setModal: (modal: VendorModal) => void;
}

const VendorTable: FunctionComponent<Props> = (props) => {
  const { items: vendors, loading, setModal } = props;

  const navigator = useNavigator();
  const { t } = useTranslation();

  const getHeader = (): KOSBaseTableHeader[] => [
    {
      label: t(commons.CODE),
      field: "externalCode",
      align: "center",
    },
    {
      label: t(commons.SUPPLIER),
      field: "name",
    },
    {
      label: t(vendor.PAYMENT_DAYS),
      field: "paymentDays",
      align: "center",
    },
    {
      label: t(vendor.SUPPLIES_QUANTITY),
      field: "suppliesCount",
      align: "center",
      type: "number",
    },
    {
      label: t(commons.ACTIONS),
      align: "center",
      component: (item: KOSRowData) => (
        <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
          <Button
            onClick={() =>
              setModal({
                open: true,
                action: Actions.edit,
                itemId: item.id,
              })
            }
            variant="outlined"
            color="warning"
          >
            <EditOutlinedIcon />
          </Button>
          <Button
            onClick={() =>
              navigator.toByLocationType(`/vendor/${item.id}/supplies`)
            }
            variant="outlined"
            startIcon={<Inventory2OutlinedIcon />}
          >
            {t(vendor.SHOW_SUPPLIES)}
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <KOSBaseTable
      columns={getHeader()}
      rows={{
        data: vendors,
        total: vendors.length,
        showNumber: true,
        loading: !!loading,
      }}
      pagination={{ enable: true }}
      emptyState={
        <KOSEmptyState
          icon={InfoOutlinedIcon}
          message={t(commons.TABLE_EMPTY)}
        />
      }
    />
  );
};

export default VendorTable;
