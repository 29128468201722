import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { commons } from "app/i18n/types";
import { updateTransferOrderLineQuantity } from "app/store/slices/transfer/thunks";
import { MissingReason } from "core/productions/entities/Supply";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface UpdateReasonAutocompleteProps {
  transferOrderLineId: number;
  reason?: string;
  quantityToReceive: number;
  updateLineStatus: (
    lineId: number,
    quantityToReceive: number,
    returnReason?: string
  ) => void;
}

const UpdateReasonAutocomplete: FunctionComponent<
  UpdateReasonAutocompleteProps
> = ({ transferOrderLineId, reason, quantityToReceive, updateLineStatus }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const updateReason = async (newReason: string | null) => {
    if (newReason) {
      await dispatch(
        updateTransferOrderLineQuantity({
          transferLineId: transferOrderLineId,
          updateQuantityAndReason: {
            quantity: quantityToReceive,
            transferOrderStep: "RECEIPT",
            returnReason: newReason,
            sku: "",
          },
        })
      );
      updateLineStatus(transferOrderLineId, quantityToReceive, newReason);
    }
  };

  return (
    <Autocomplete
      fullWidth
      value={reason}
      options={Object.values(MissingReason).filter(
        (reason) => reason !== MissingReason.SPARE_PRODUCT
      )}
      getOptionLabel={(option) => {
        return option ? t(`missingReason.${option}`) : "";
      }}
      renderInput={(params) => (
        <TextField {...params} label={t(commons.REASON)} error={!reason} />
      )}
      onChange={(_, newValue) => {
        updateReason(newValue);
      }}
    />
  );
};

export default UpdateReasonAutocomplete;
