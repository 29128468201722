import { KOSSelectedResult } from "@foodology-co/alejandria";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import KitchenSelector from "app/components/Kitchen/Selector";
import CountingTypeStep from "app/components/PhysicalCount/Schedule/Step/CountingType";
import { useAlert } from "app/hooks/useAlert";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import {
  kitchenInventoryManagement,
  physicalCountSchedule,
} from "app/i18n/types";
import {
  archiveCounts,
  getCountingByLocation,
} from "app/store/slices/counting/thunks";
import {
  CountingResponse,
  CountingType,
  InventoryResponse,
} from "core/physicalCount/entities/Counting";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getLocationAndType } from "utils/general";
import ArchiveCountsDialogTable from "./Table";

interface ArchiveCountsDialogProps {
  onClose: () => void;
}

const ArchiveCountsDialog: FunctionComponent<ArchiveCountsDialogProps> = (
  props
) => {
  const { t } = useTranslation();
  const { onClose } = props;
  const dispatch = useAppDispatch();

  const alert = useAlert();

  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [selectedCity, setSelectedCity] = useState<string>("");
  const [selectedKitchen, setSelectedKitchen] = useState<string>("");
  const [counts, setCounts] = useState<CountingResponse[]>([]);
  const [filteredCounts, setFilteredCounts] = useState<CountingResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [archiving, setArchiving] = useState<boolean>(false);
  const [selectedCountingType, setSelectedCountingType] =
    useState<CountingType | null>(null);
  const [selectedItems, setSelectedItems] = useState<KOSSelectedResult[]>([]);

  const getData = () => {
    const params = getLocationAndType({
      selectedCountry,
      selectedCity,
      selectedKitchen,
    });
    if (params) {
      setLoading(true);
      setSelectedCountingType(null);
      setSelectedItems([]);
      dispatch(
        getCountingByLocation({
          locationType: params.type,
          location: params.location,
        })
      )
        .then((response) => {
          setCounts(response.payload as CountingResponse[]);
        })
        .catch(() => {
          setCounts([]);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setCounts([]);
    }
  };

  useEffect(() => {
    getData();
  }, [selectedCountry, selectedCity, selectedKitchen]);

  useEffect(() => {
    setFilteredCounts(
      counts.filter((el) => {
        if (!selectedCountingType) return true;
        return el.type === selectedCountingType.code;
      })
    );
  }, [counts, selectedCountingType]);

  const onMultipleArchive = (selected: KOSSelectedResult[]) => {
    setArchiving(true);
    const ids = selected.map((item) => Number(item.id));
    dispatch(archiveCounts(ids))
      .then((response) => {
        const result = response.payload as InventoryResponse;
        if (result.ok) {
          alert.successWithMsg({
            title: t(physicalCountSchedule.ARCHIVE_SUCCESS),
          });
          setCounts(counts.filter((el) => !ids.includes(Number(el.id))));
        }
      })
      .catch(() => {
        alert.errorWithMsg({ title: t(physicalCountSchedule.ARCHIVE_ERROR) });
      })
      .finally(() => {
        setSelectedItems([]);
        setArchiving(false);
      });
  };

  return (
    <Dialog open onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle color="primary.main">
        {t(kitchenInventoryManagement.KITCHEN_INVENTORY_DESCRIPTION)}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseOutlinedIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={8} sx={{ mt: 1 }}>
            <KitchenSelector
              onCountryChange={(country) => setSelectedCountry(country ?? "")}
              onCityChange={(city) => setSelectedCity(city ?? "")}
              onKitchenChange={(kitchen) =>
                setSelectedKitchen(kitchen?.kitchenId ?? "")
              }
            />
          </Grid>
          <Grid item xs={3} sx={{ mt: 1 }}>
            <CountingTypeStep
              selectedCountingType={selectedCountingType}
              setSelectedCountingType={setSelectedCountingType}
            />
          </Grid>
          <Grid item xs={1} sx={{ mt: 1 }}>
            <Button
              onClick={() => onMultipleArchive(selectedItems)}
              variant="outlined"
              color="error"
              size="small"
              sx={{ height: "100%" }}
              disabled={archiving || !selectedItems.length}
              fullWidth
            >
              <DeleteOutlineOutlinedIcon />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <ArchiveCountsDialogTable
              items={filteredCounts}
              loading={loading}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              onArchive={onMultipleArchive}
              archiving={archiving}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ArchiveCountsDialog;
