import { FunctionComponent, useEffect } from "react";
import {
  PreparationTimeProps,
  getPreparationTime,
} from "utils/productionSchedule";

interface Props {
  data: PreparationTimeProps;
  preparationTime: string;
  setPreparationTime: (preparationTime: string) => void;
}

const ProductionTime: FunctionComponent<Props> = (props) => {
  const { data, preparationTime, setPreparationTime } = props;

  useEffect(() => {
    const startedAt = data.startDate;
    if (startedAt) {
      const updatePreparationTime = () => {
        setPreparationTime(getPreparationTime(data) ?? "");
      };

      updatePreparationTime();

      if (!data.finishedAt) {
        const interval = setInterval(updatePreparationTime, 30000);
        return () => clearInterval(interval);
      }
    }
  }, [data, preparationTime]);

  return <></>;
};

export default ProductionTime;
