import { CoreBaseResponse } from "core/common/interfaces/core";

export enum VendorTypeEnum {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  E = "E",
  F = "F",
  G = "G",
}

export type VendorType = `${VendorTypeEnum}`;

export interface VendorSupply {
  id: number;
  supplySku: string;
  supplyName: string;
  supplyUnit: string;
  supplyCategory: string;
  supplyUnitPrice: number;
  supplyQuantity: number;
  vendorId: number;
  vendorSupplyName: string;
  vendorUnit: string;
  vendorUnitPrice: number;
  vendorType: VendorType;
  vendorMinQuantityToOrder: number;
  createdAt: Date;
  lastUpdatedAt: Date;
  updatedBy: string;
  city: string;
  vendorOrdersPerWeek?: number;
  leadTime: number;
}

export interface VendorSupplyDistinct {
  supplySku: string;
  supplyName: string;
  supplyUnit: string;
  supplyCategory: string;
  city: string;
  countVendors?: number;
}

export interface CreateOrModifyDataResponse {
  current: VendorSupply;
  refinedSupplies: VendorSupply[];
}

export interface CreateOrModifyResponse extends CoreBaseResponse {
  data?: CreateOrModifyDataResponse;
}

export interface SkuAndTypeAndCityValidatorPayload {
  sku: string;
  type: string;
  city: string;
  excludeId?: number;
}

export interface VendorSupplyResponse extends CoreBaseResponse {
  data?: VendorSupply[];
}
