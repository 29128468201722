import QrCodeScannerOutlinedIcon from "@mui/icons-material/QrCodeScannerOutlined";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { commons, transferOrdersPackedReviewTable } from "app/i18n/types";
import { ProductionLabel } from "core/productions/entities/Productions";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface ProductionLabelItemProps {
  label: ProductionLabel;
  showName?: boolean;
}

const ProductionLabelItem: FunctionComponent<ProductionLabelItemProps> = (
  props
) => {
  const { label, showName } = props;
  const { t } = useTranslation();

  return (
    <Box
      border="1px solid #D7D3D3"
      borderRadius={1}
      py={1}
      px={2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <QrCodeScannerOutlinedIcon fontWeight={700} />
        <Typography fontWeight={700}>{label.id}</Typography>
        {showName && (
          <Typography variant="body2" color="text.secondary">
            {label.name}
          </Typography>
        )}
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography variant="body2" color="text.secondary">
          {t(
            showName
              ? commons.QUANTITY
              : transferOrdersPackedReviewTable.PACKED_QUANTITY
          )}
        </Typography>
        <Typography fontWeight={700} align="right">
          {label.quantity}
          {label.unit}.
        </Typography>
      </Box>
    </Box>
  );
};

export default ProductionLabelItem;
