import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useNavigator } from "app/hooks/useNavigator";
import { LinesPurchaseOrder } from "core/linesPurchaseOrders/entities/LinesPurchaseOrder";
import { FunctionComponent } from "react";
import UpdateRecivedQuantityPurchaseTextField from "./UpdateRecivedQuantityPurchaseTextField";
import UpdateRecivedQuantityTextField from "./UpdateRecivedQuantityTextField";

interface Props {
  item: LinesPurchaseOrder;
  isPartial: boolean;
}

const PurchaseLinesTableitem: FunctionComponent<Props> = (props) => {
  const { item, isPartial } = props;
  const navigator = useNavigator();
  const { kitchenId, orderId, origin } = navigator.params();

  const getColorRow = () => {
    if (item.status === "REJECTED") {
      return "rgba(211, 47, 47, 0.04)";
    } else if (item.status === "RECEIVED") {
      return "rgba(18, 167, 0, 0.04)";
    } else {
      return undefined;
    }
  };

  const showPendingQuantity = () => isPartial && origin === "KIS";

  return (
    <TableRow
      key={item.lineId}
      sx={{
        bgcolor: getColorRow(),
      }}
    >
      <TableCell align="left">
        <Typography>{item.sku}</Typography>
      </TableCell>
      <TableCell align="center">
        <Box display="flex" alignItems="center" gap={2}>
          <Typography>{item.description}</Typography>
        </Box>
      </TableCell>
      <TableCell align="left">{item.quantity.toString()}</TableCell>
      <TableCell align="left">
        {showPendingQuantity() ? (
          item.quantityReceived
        ) : origin === "KIS" ? (
          <UpdateRecivedQuantityPurchaseTextField
            item={item}
            kitchenId={kitchenId ?? ""}
            orderId={orderId ?? ""}
          />
        ) : (
          <UpdateRecivedQuantityTextField
            item={item}
            kitchenId={kitchenId ?? ""}
            origin={origin ?? ""}
            orderId={orderId ?? ""}
            autoSave
          />
        )}
      </TableCell>

      <TableCell align="left">
        {showPendingQuantity() && item.status !== "RECEIVED" && (
          <UpdateRecivedQuantityPurchaseTextField
            item={item}
            kitchenId={kitchenId ?? ""}
            orderId={orderId ?? ""}
          />
        )}
      </TableCell>

      <TableCell align="left">
        <Typography fontWeight={700}>{item.measureUnit}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default PurchaseLinesTableitem;
