import { Alert } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { unitShortNameToUnitName } from "core/common/utils/unitShortNameToUnitName";
import { ProductionRecord } from "core/productions/entities/Productions";
import dayjs from "dayjs";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { productionManagement } from "../../../i18n/types";

interface Props {
  production: ProductionRecord;
  requiresValidation?: boolean;
}

const ProductionCardLabelsList: FunctionComponent<Props> = (props) => {
  const { production, requiresValidation } = props;

  const { t } = useTranslation();

  const unit = unitShortNameToUnitName(production.unit);

  return (
    <>
      {["FINISHED", "APPROVED", "APPROVED_WITH_NOVELTY"].includes(
        production.status
      ) && (
        <>
          <Box
            key={`ProductionCardLabelsListTitle-${production.id}`}
            display="flex"
            justifyContent="space-between"
            py={2}
            gap={2}
            alignItems="center"
            width={"100%"}
          >
            <Typography
              fontWeight={700}
              component="div"
              textAlign="center"
              bgcolor="#eeeeee"
              color="primary.main"
              textTransform="uppercase"
              width="100%"
            >
              {t(productionManagement.LABELS_HISTORY)}
            </Typography>
          </Box>

          <Box maxHeight={248} sx={{ overflowY: "auto" }}>
            {!production.labels.length &&
              (requiresValidation
                ? production.status === "APPROVED" ||
                  production.status === "APPROVED_WITH_NOVELTY"
                : true) && (
                <Alert severity="info" sx={{ mx: 2 }}>
                  {t(productionManagement.LABELS_HISTORY_EMPTY)}
                </Alert>
              )}
            {production.labels.map((label) => (
              <Box
                key={`ProductionCardLabelsListDetail-${label.id}`}
                display="flex"
                justifyContent="space-between"
                py={0.5}
                px={2}
                gap={2}
                alignItems="center"
              >
                <Typography>
                  {dayjs(label.createdAt).format("YYYY-MM-DD")}
                </Typography>
                <Typography sx={{ flexShrink: 0 }}>
                  {label.quantity} {unit}
                </Typography>
              </Box>
            ))}
          </Box>
        </>
      )}
    </>
  );
};

export default ProductionCardLabelsList;
