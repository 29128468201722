import { UseCase } from "core/common/interfaces/useCase";
import { CanSaveScannedTransfer } from "core/transfer/interfaces/transfer/canSaveScannedTransfer";

export class SaveScannedTransferUseCase implements UseCase {
  constructor(private readonly repository: CanSaveScannedTransfer) {}

  public execute(transferOrderId: number, productionLabelId: string) {
    return this.repository.saveScannedTransfer(
      transferOrderId,
      productionLabelId
    );
  }
}
