import { KOSSelectedResult } from "@foodology-co/alejandria";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CommentDrawerWithFloatButton from "app/components/CommentArea/DrawerWithFloatButton";
import DocumentsViewer from "app/components/DocumentViewer";
import PageHeader from "app/components/PageHeader/PageHeader";
import PurchaseOrderStatusSelector from "app/components/PurchaseOrder/Status/Selector";
import PurchaseLineTable from "app/components/PurchaseRecord/PurchaseLineTable";
import CommonDialog from "app/components/common/CommonDialog";
import SearchOnFullDialog from "app/components/common/SearchOnFullDialog";
import SearchOnTopBar from "app/components/common/SearchOnTopBar";
import {
  commons,
  purchaseOrderCreation,
  purchaseOrderLine,
} from "app/i18n/types";
import { AttachmentTypeEnum } from "core/attachment/entity";
import { viewPurchaseOrderPdf } from "core/attachment/repository/http";
import { CommentTypeEnum } from "core/comment/entity";
import {
  OriginType,
  Purchase,
  PurchaseLine,
} from "core/purchaseOrders/entities/PurchaseOrder";
import {
  getPurchaseOrderLines,
  updateStatusOfPurchaseOrder,
} from "core/purchaseOrders/repositories/http/purchase";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { compareSearchText } from "utils/general";
import { PageBox } from "utils/generalUI";
import PurchaseOrderLinePrincipal from "../Principal";

interface Props {
  kitchenId: string;
  orderId: string;
  isFullDialog: boolean;
  onUpdate: () => void;
}

const PurchaseOrderConciliationDetail: FunctionComponent<Props> = (
  props: Props
) => {
  const { kitchenId, orderId, isFullDialog, onUpdate } = props;
  const { t } = useTranslation();

  const [order, setOrder] = useState<Purchase>();
  const [lines, setLines] = useState<PurchaseLine[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<string>();
  const [openDocumentList, setOpenDocumentList] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<KOSSelectedResult[]>([]);
  const [editionMode, setEditionMode] = useState<boolean>(false);

  const getData = async (kitchenId: string, orderId: string) => {
    setLoading(true);
    const result = await getPurchaseOrderLines(
      kitchenId,
      orderId,
      OriginType.KIS
    );
    setOrder(result);
    setLoading(false);
  };

  useEffect(() => {
    if (kitchenId && orderId) {
      getData(kitchenId, orderId);
    }
  }, [kitchenId, orderId]);

  const searchValidate = (line: PurchaseLine, toSearch: string): boolean => {
    if (!toSearch) return true;
    return (
      compareSearchText(line.sku, toSearch) ||
      compareSearchText(line.description, toSearch)
    );
  };

  useEffect(() => {
    setLines(
      order?.lines?.filter((line) => {
        return searchValidate(line, search);
      }) ?? []
    );
  }, [order, search]);

  useEffect(() => {
    setSelectedStatus(undefined);
  }, [open]);

  const handleConfirm = async () => {
    if (orderId && selectedStatus) {
      await updateStatusOfPurchaseOrder(orderId, selectedStatus);
      onUpdate();
    }
  };

  const sourceId =
    order?.origin === OriginType.KIS ? order?.code : order?.orderId;

  const reload = () => {
    if (kitchenId && orderId) {
      getData(kitchenId, orderId);
    }
  };

  return (
    <PageBox>
      <PageHeader
        title={t(purchaseOrderLine.TITLE)}
        isFullDialog={isFullDialog}
        rightArea={[
          {
            children: t(commons.MODIFY),
            variant: "outlined",
            color: "warning",
            startIcon: <EditOutlinedIcon />,
            onClick: () => setEditionMode(true),
            show: !editionMode,
          },
          {
            children: t(commons.SAVE),
            variant: "outlined",
            color: "success",
            startIcon: <CheckOutlinedIcon />,
            onClick: () => setEditionMode(false),
            show: editionMode,
          },
          {
            children: t(commons.VIEW_PDF),
            variant: "outlined",
            color: "primary",
            startIcon: <InsertDriveFileOutlinedIcon />,
            disabled: !order,
            onClick: () => {
              order && viewPurchaseOrderPdf(order);
            },
          },
          {
            children: t(purchaseOrderCreation.DOCUMENT_LIST_BUTTON_LABEL),
            variant: "outlined",
            color: "primary",
            disabled: !order,
            startIcon: <VisibilityOutlinedIcon />,
            onClick: () => setOpenDocumentList(true),
          },
          {
            children: t(commons.CHANGE_STATUS),
            variant: "contained",
            color: "primary",
            disabled: !order,
            startIcon: <FactCheckOutlinedIcon />,
            onClick: () => setOpen(true),
          },
        ]}
      />
      {order && (
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12}>
            {isFullDialog ? (
              <SearchOnFullDialog onSearch={setSearch} />
            ) : (
              <SearchOnTopBar onSearch={setSearch} />
            )}
            <PurchaseOrderLinePrincipal
              order={order}
              isConciliation
              isModify={editionMode}
              reload={reload}
            />
          </Grid>
        </Grid>
      )}

      <CommonDialog
        open={open}
        handleClose={() => setOpen(false)}
        handleConfirm={handleConfirm}
        bodyElement={
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {t(commons.SELECT_A_NEW_STATUS)}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <PurchaseOrderStatusSelector
                selected={selectedStatus}
                setSelected={setSelectedStatus}
                disabled={!order}
                isErrorsOnly
                isValidated
              />
            </Grid>
          </Grid>
        }
        title={t(commons.CHANGE_STATUS)}
        showCancelButton
      />
      {order && (
        <>
          <PurchaseLineTable
            order={order}
            setOrder={setOrder}
            items={lines}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            loading={loading}
            isConciliation
          />

          <CommentDrawerWithFloatButton
            sourceId={order.orderId}
            type={CommentTypeEnum.PURCHASE_ORDER}
            kitchenId={order.kitchenId}
          />

          {openDocumentList && (
            <DocumentsViewer
              sourceId={sourceId ?? ""}
              origin={order.origin}
              type={AttachmentTypeEnum.PURCHASE_ORDER}
              title={order.vendorName}
              onClose={() => setOpenDocumentList(false)}
              kitchenId={kitchenId ?? ""}
              modeToAdd
            />
          )}
        </>
      )}
    </PageBox>
  );
};

export default PurchaseOrderConciliationDetail;
