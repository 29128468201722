import { Session } from "core/account/entities/Session";
import { CanGetSession } from "core/account/interfaces/session/canGetSession";
import { CanRemoveSession } from "core/account/interfaces/session/canRemoveSession";
import {
  getSession as getSessionUtils,
  removeSession as removeSessionUtils,
} from "utils/session";

export class SessionCookieRepository
  implements CanGetSession, CanRemoveSession
{
  public getSession(): Session {
    return getSessionUtils();
  }

  public removeSession(): void {
    removeSessionUtils();
  }
}
