import { UseCase } from "core/common/interfaces/useCase";
import { CanGetInactiveSuppliesByLocation } from "core/supplies/interfaces/supplies/canGetInactiveSuppliesByLocation";

export class GetInactiveSuppliesByLocationUseCase implements UseCase {
  constructor(private readonly repository: CanGetInactiveSuppliesByLocation) {}

  public execute(locationId: string) {
    return this.repository.getInactiveSuppliesByLocation(locationId);
  }
}
