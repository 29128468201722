import { UseCase } from "core/common/interfaces/useCase";
import { CanGetTheoreticalInventoryMovementsResume } from "../interfaces/canGetMovementsDetailsResume";

export class GetTheoreticalInventoryMovementsResumeUseCase implements UseCase {
  constructor(
    private readonly repository: CanGetTheoreticalInventoryMovementsResume
  ) {}

  public execute(kitchenId: string, sku: string) {
    return this.repository.getTheoreticalInventoryMovementsResume(
      kitchenId,
      sku
    );
  }
}
