import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { commons, physicalCountSchedule } from "app/i18n/types";
import { Supply } from "core/supplies/entities/Supply";
import { FunctionComponent, memo } from "react";
import { useTranslation } from "react-i18next";
import { tableHeaderSX } from "utils/general";

interface SupplyStepTableProps {
  supplies: Supply[];
  checkedSupplies: string[];
  toggleSelectAll: (skus: string[]) => void;
  toggleChecked: (sku: string) => void;
}

const SupplyStepTable: FunctionComponent<SupplyStepTableProps> = (props) => {
  const { supplies, checkedSupplies, toggleSelectAll, toggleChecked } = props;

  const { t } = useTranslation();
  const suppliesTmp = [...supplies];
  suppliesTmp.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <TableContainer component={Paper} sx={{ mt: 1 }}>
      <Table>
        <TableHead>
          <TableRow sx={tableHeaderSX}>
            <TableCell align="center" sx={{ textTransform: "uppercase" }}>
              <Checkbox
                onChange={() =>
                  toggleSelectAll(supplies.map((supply) => supply.sku))
                }
                checked={supplies.every((supply) =>
                  checkedSupplies.includes(supply.sku)
                )}
              />
            </TableCell>
            <TableCell sx={{ textTransform: "uppercase" }}>
              {t(commons.SKU)}
            </TableCell>
            <TableCell sx={{ textTransform: "uppercase" }}>
              {t(commons.NAME)}
            </TableCell>
            <TableCell sx={{ textTransform: "uppercase" }}>
              {t(commons.UNIT)}
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {!suppliesTmp.length && (
            <TableRow>
              <TableCell
                align="center"
                colSpan={4}
                sx={{ textTransform: "uppercase" }}
              >
                {t(physicalCountSchedule.STEP_4_TABLE_EMPTY)}
              </TableCell>
            </TableRow>
          )}
          {suppliesTmp.map((supply, index) => (
            <TableRow key={`DateRow${index}`}>
              <TableCell align="center" sx={{ textTransform: "uppercase" }}>
                <Checkbox
                  onChange={() => toggleChecked(supply.sku)}
                  checked={checkedSupplies.includes(supply.sku)}
                />
              </TableCell>
              <TableCell>{supply.sku}</TableCell>
              <TableCell>{supply.name}</TableCell>
              <TableCell>{supply.unit}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(SupplyStepTable);
