import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Theme {
    customComponents: {
      orderSummary: {
        height: number;
      };
    };
  }

  interface ThemeOptions {
    customComponents?: {
      orderSummary?: {
        height?: number;
      };
    };
  }
}

const breakpointsTheme = createTheme();

const theme = createTheme({
  breakpoints: {
    values: {
      ...breakpointsTheme.breakpoints.values,
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1300,
      xl: 1536,
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#121F56",
      light: "#3C81DB",
      dark: "#121F56",
    },
    secondary: {
      main: "#1DB3D1",
      light: "#B3EFFF",
      dark: "#052550",
    },
    text: {
      primary: "#232323",
      secondary: "#7B7B7B",
    },
    background: {
      default: "#F3F8FF",
    },
    divider: "#A7A7A7",
    error: { main: "#D32F2F", light: "#F5D7D7", dark: "#C62828" },
    warning: { main: "#ED6C02", light: "#FF9800" },
    success: { main: "#2E7D32", light: "#4CAF50", dark: "#1B5E20" },
    info: { main: "#0288D1", light: "#03A9F4", dark: "#01579B" },
  },
  typography: {
    h1: {
      fontFamily: "Inter, Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 500,
      fontSize: "26px",
      lineHeight: "32px",

      [breakpointsTheme.breakpoints.up("md")]: {
        fontSize: "40px",
        fontWeight: 300,
        lineHeight: "50px",
      },
    },
    h2: {
      fontFamily: "Inter, Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 300,
      fontSize: "42px",
      lineHeight: "56px",
    },
    h3: {
      fontFamily: "Poppins, Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 500,
      fontSize: "30px",
      lineHeight: "36px",
    },
    h4: {
      fontFamily: "Poppins, Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 600,
      fontSize: "28px",
      lineHeight: "32px",
    },
    h5: {
      fontFamily: "Inter, Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 500,
      fontSize: "22px",
      lineHeight: "26px",
    },
    h6: {
      fontFamily: "Poppins, Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "20px",
    },
    body1: {
      fontFamily: "Inter, Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
    },
    body2: {
      fontFamily: "Inter, Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "16px",
    },
    subtitle1: {
      fontFamily: "Inter, Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 300,
      fontSize: "16px",
      lineHeight: "24px",
    },
    subtitle2: {
      fontFamily: "Inter, Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "16px",
    },
    caption: {
      fontFamily: "Inter, Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
    },
    overline: {
      fontFamily: "Inter, Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "16px",
    },
  },
  mixins: {
    toolbar: {
      height: 56,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.size === "large" && {
            fontWeight: 500,
            fontSize: "20px",
          }),
        }),
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "46px",
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        item: {
          paddingTop: 12,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button':
            {
              "-webkit-appearance": "none",
              margin: 0,
            },
          '& input[type="number"]': {
            "-moz-appearance": "textfield",
          },
        },
      },
    },
  },
  customComponents: {
    orderSummary: {
      height: 62,
    },
  },
});

export default theme;
