import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Autocomplete, Grid, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { commons, productionManagement } from "app/i18n/types";
import { updateProductionManager } from "app/store/slices/productions/thunks";
import { User } from "core/account/entities/User";
import { ProductionRecord } from "core/productions/entities/Productions";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

interface ProductionUserManagerProps {
  production: ProductionRecord;
  users: User[];
  onClose: () => void;
}

const ProductionUserManager: FunctionComponent<ProductionUserManagerProps> = (
  props
) => {
  const { t } = useTranslation();
  const { onClose, production, users } = props;
  const dispatch = useAppDispatch();

  const [user, setUser] = useState<User | null>(null);

  const onupdateProductionManager = async () => {
    await dispatch(
      updateProductionManager({
        productionId: production.id,
        userId: user?._id ?? "",
        userName: user?.profile.name ?? "",
      })
    );
  };

  return (
    <Dialog open onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle color="primary.main">
        {t(productionManagement.USER_CREATED_LABELS)}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseOutlinedIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              getOptionLabel={(option) => option.profile.name}
              options={users}
              value={user}
              onChange={(_: any, newValue: User | null) => setUser(newValue)}
              sx={{ width: "100%", mt: 1.5 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t(productionManagement.SELECT_USER_CREATED_LABELS)}
                  size="medium"
                />
              )}
              disabled={!users.length}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Box display="flex" gap={2} flexDirection="column" width="100%">
          <Button
            variant="contained"
            onClick={onupdateProductionManager}
            startIcon={<ChevronRightOutlinedIcon />}
            fullWidth
            disabled={!user}
          >
            {t(commons.ACCEPT)}
          </Button>

          <Button
            variant="outlined"
            onClick={onClose}
            color="error"
            startIcon={<CloseOutlinedIcon />}
            fullWidth
          >
            {t(commons.CANCEL)}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ProductionUserManager;
