import { commons } from "app/i18n/types";
import { number, object, string } from "yup";

export const handleValidations = (country: string) => {
  const documentValidator = getDocumentValidator(country);

  let document = string().required(commons.REQUIRED_FIELD);

  if (documentValidator) {
    document = document
      .length(documentValidator.length)
      .matches(documentValidator.pattern);
  }

  return object({
    purchaseEmail: string().required(commons.REQUIRED_FIELD),
    email: string().required(commons.REQUIRED_FIELD),
    paymentDays: number()
      .min(1)
      .typeError(commons.QUANTITY_ERROR)
      .required(commons.REQUIRED_FIELD),
    name: string().required(commons.REQUIRED_FIELD),
    address: string().required(commons.REQUIRED_FIELD),
    phone: string().required(commons.REQUIRED_FIELD),
    document,
  });
};

export interface DocumentValidator {
  country: string;
  length: number;
  pattern: RegExp;
  validator: (value: string) => boolean;
}

export const documentValidators: DocumentValidator[] = [
  {
    country: "COL",
    length: 9,
    pattern: /^\d*$/,
    validator: function (value: string): boolean {
      return this.pattern.test(value) && value.length <= this.length;
    },
  },
  {
    country: "PER",
    length: 11,
    pattern: /^\d*$/,
    validator: function (value: string): boolean {
      return this.pattern.test(value) && value.length <= this.length;
    },
  },
];

export const getDocumentValidator = (
  country: string
): DocumentValidator | undefined => {
  return documentValidators.find((el) => el.country === country);
};
