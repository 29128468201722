import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FunctionComponent } from "react";

interface FinishDrawerResumeProps {
  receiptNo: string;
  linesCount: number;
}

const FinishDrawerResume: FunctionComponent<FinishDrawerResumeProps> = (
  props
) => {
  const { receiptNo, linesCount } = props;

  return (
    <Box
      py={1}
      px={2}
      bgcolor="white"
      border="1px solid #D7D3D3"
      borderRadius={1}
      display="flex"
      gap={2}
      width="100%"
      justifyContent="space-between"
      flexWrap="wrap"
    >
      <Box display="flex" flexDirection="column">
        <Typography color="text.secondary" fontWeight={300} gutterBottom>
          ID Órden
        </Typography>
        <Typography fontWeight={600}>{receiptNo}</Typography>
      </Box>

      <Box display="flex" flexDirection="column">
        <Typography color="text.secondary" fontWeight={300} gutterBottom>
          Artículos
        </Typography>
        <Typography fontWeight={600}>{linesCount}</Typography>
      </Box>
    </Box>
  );
};

export default FinishDrawerResume;
