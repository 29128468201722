import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { commons } from "app/i18n/types";
import { updateTransferOrderLineQuantity } from "app/store/slices/transfer/thunks";
import {
  FocusEvent,
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

interface Props {
  transferLineId: number;
  receivedQuantity: number;
  sendQuantity: number;
  status: string;
  returnReason?: string;
  isReturn: boolean;
  updateLineStatus: (
    lineId: number,
    quantityToReceive: number,
    returnReason?: string
  ) => void;
}

const UpdateRecivedQuantityTransferTextField: FunctionComponent<Props> = (
  props
) => {
  const {
    receivedQuantity,
    transferLineId,
    status,
    sendQuantity,
    returnReason,
    isReturn,
    updateLineStatus,
  } = props;
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [quantity, setQuantity] = useState<string | undefined>(undefined);
  const [validation, setValidation] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const getInputColor = useCallback(() => {
    if (validation !== null) {
      return "error";
    }

    if (status === "IN_PROCESS") {
      return "success";
    }

    return "warning";
  }, [validation, status]);

  const getInputAddornement = useCallback((): ReactNode => {
    if (!status) {
      return null;
    }

    if (loading) {
      return <CircularProgress size={24} />;
    }

    if (validation != null) {
      return <ErrorOutlineIcon color="error" />;
    }

    if (status === "IN_PROCESS") {
      return <CheckCircleOutlineIcon color="success" />;
    }
    return undefined;
  }, [loading, validation, status]);

  const updateItemQuantity = async (event: FocusEvent<HTMLInputElement>) => {
    setValidation(null);
    const quantityToReceive = Number(event.target.value);
    if (quantityToReceive > sendQuantity) {
      setValidation(`Cantidad máxima permitida ${sendQuantity.toFixed(1)}`);
      return;
    }

    if (quantityToReceive === receivedQuantity && quantityToReceive > 0) {
      return;
    }
    setLoading(true);
    let reason;
    if (isReturn) {
      reason = returnReason;
    } else {
      reason = quantityToReceive === sendQuantity ? undefined : returnReason;
    }
    const update = await dispatch(
      updateTransferOrderLineQuantity({
        transferLineId: transferLineId,
        updateQuantityAndReason: {
          quantity: quantityToReceive,
          transferOrderStep: "RECEIPT",
          returnReason: reason,
          sku: "",
        },
      })
    );
    setLoading(false);
    if (update.payload === true) {
      updateLineStatus(transferLineId, quantityToReceive, reason);
    } else {
      setValidation("Intente de nuevo");
    }
  };

  const setQuantityNumber = useCallback((quantity: string) => {
    if (quantity === "" || isNaN(Number(quantity))) {
      setQuantity(undefined);
      return;
    }
    setQuantity(Number(quantity).toString());
  }, []);

  useEffect(() => {
    setQuantity(receivedQuantity.toString());
  }, [receivedQuantity]);

  return (
    <TextField
      id={transferLineId.toString()}
      name="value"
      onChange={(e) => {
        setQuantityNumber(e.target.value);
      }}
      value={quantity ?? ""}
      size="small"
      label={t(commons.ENTER_QUANTITY)}
      InputProps={{
        endAdornment: getInputAddornement(),
        required: true,
        type: "number",
      }}
      color={getInputColor()}
      error={getInputColor() === "error"}
      helperText={validation}
      sx={{ width: 150 }}
      onBlur={(event: FocusEvent<HTMLInputElement>) => {
        updateItemQuantity(event);
      }}
      disabled={status === "loading"}
      focused
      autoComplete="off"
    />
  );
};

export default UpdateRecivedQuantityTransferTextField;
