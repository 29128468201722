import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { commons } from "app/i18n/types";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  selected: string | undefined;
  setSelected: (selected: string) => void;
}

const ReceiptTypeSelector: FunctionComponent<Props> = (props) => {
  const { selected, setSelected } = props;

  const { t } = useTranslation();
  const [reset, setReset] = useState<number>(new Date().getTime());

  const options: string[] = ["PURCHASE_ORDER", "TRANSFER_ORDER", "ALL"];

  useEffect(() => {
    if (!selected) {
      setReset(new Date().getTime());
    }
  }, [selected]);

  return (
    <Autocomplete
      key={reset}
      renderInput={(params) => (
        <TextField {...params} label={t(commons.TYPE)} />
      )}
      value={selected}
      options={options}
      getOptionLabel={(option) => t(`commons.receiptType.${option}`)}
      onChange={(_, value) => {
        setSelected(value ?? "ALL");
      }}
    />
  );
};

export default ReceiptTypeSelector;
