import { LinearProgress, TableHead } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { getSummaryAdjustment } from "app/store/slices/adjustments/thunks";
import { convertDate } from "app/utils/dateUtils";
import { Fragment, FunctionComponent, useEffect, useState } from "react";

interface SummaryAdjustmentsTableProps {}

const SummaryAdjustmentsTable: FunctionComponent<
  SummaryAdjustmentsTableProps
> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();

  const summary = useAppSelector((state) => state.adjustments.summary);

  const successSendFile = useAppSelector((state) => state.adjustments.success);

  // const [value, setValue] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    dispatch(getSummaryAdjustment());
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    if (summary.length > 0) {
      if (summary[0].recordsCount !== summary[0].sendedPacman) {
        const timer = setTimeout(() => {
          dispatch(getSummaryAdjustment());
        }, 20000);

        return () => clearTimeout(timer);
      }
    }
    setLoading(false);
  }, [dispatch, successSendFile, summary]);

  return (
    <TableContainer component={isMobile ? "div" : Paper}>
      <Table aria-label="physical count table">
        <Box component={isMobile ? Fragment : TableBody}>
          <TableContainer
            component={Paper}
            sx={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <Table aria-label="counting-history">
              <TableHead>
                <TableRow key="main">
                  <TableCell align="center" width="5%">
                    <Box alignItems="center" sx={{ fontWeight: "bold" }}>
                      Id
                    </Box>
                  </TableCell>
                  <TableCell align="center" width="19%">
                    <Box alignItems="center" sx={{ fontWeight: "bold" }}>
                      Fecha
                    </Box>
                  </TableCell>
                  <TableCell align="center" width="12%">
                    <Box alignItems="center" sx={{ fontWeight: "bold" }}>
                      Total
                    </Box>
                  </TableCell>
                  <TableCell align="center" width="12%">
                    <Box alignItems="center" sx={{ fontWeight: "bold" }}>
                      Procesados
                    </Box>
                  </TableCell>
                  <TableCell align="center" width="12%">
                    <Box alignItems="center" sx={{ fontWeight: "bold" }}>
                      Enviados BC
                    </Box>
                  </TableCell>
                  <TableCell align="center" width="49%">
                    <Box alignItems="center" sx={{ fontWeight: "bold" }}>
                      Nombre Archivo
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              {loading === true && (
                <TableRow>
                  <TableCell colSpan={6} sx={{ p: 0 }}>
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              )}
              <TableBody>
                {summary.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell align="left" width="5%">
                      <Box alignItems="left">
                        <Typography variant="caption">{row.id}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left" width="19%">
                      <Box alignItems="left">
                        <Typography variant="caption">
                          {convertDate(row.createdAt, "MM/dd/yyyy hh:mm a")}
                        </Typography>
                      </Box>
                    </TableCell>

                    <TableCell align="right" width="12%">
                      <Box alignItems="end">
                        <Typography variant="caption">
                          {row.recordsCount}
                        </Typography>
                      </Box>
                    </TableCell>

                    <TableCell align="right" width="12%">
                      <Box alignItems="end">
                        <Typography variant="caption">
                          {row.sendedPacman}
                        </Typography>
                      </Box>
                    </TableCell>

                    <TableCell align="right" width="12%">
                      <Box alignItems="end">
                        <Typography variant="caption">
                          {row.sendedBc}
                        </Typography>
                      </Box>
                    </TableCell>

                    <TableCell align="center" width="40%">
                      <Box alignItems="center">
                        <Typography variant="caption">
                          {row.fileName}
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Table>
    </TableContainer>
  );
};

export default SummaryAdjustmentsTable;
