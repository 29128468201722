import appConfig from "config/app";
import { CoreBaseResponse } from "core/common/interfaces/core";
import {
  CreateOrModifyResponse,
  SkuAndTypeAndCityValidatorPayload,
  VendorSupply,
  VendorSupplyDistinct,
  VendorSupplyResponse,
} from "core/vendors/entities/VendorSupply";
import { ApiVersion, getHttp, postHttp } from "utils/http";

export const getVendorSupplyByVendorId = async (
  vendorId: number
): Promise<VendorSupply[]> => {
  try {
    const response = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `vendor/supply/vendor-id/${vendorId}`
    );
    const result: VendorSupply[] = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error getVendorSupplyByVendorId | ${ex.message}`);
    return [];
  }
};

export const modifyById = async (
  id: number,
  data: VendorSupply
): Promise<CreateOrModifyResponse> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `vendor/supply/modify/${id}`,
      data
    );
    const result: CreateOrModifyResponse = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error modifyById | ${ex.message}`);
    return { ok: false, message: "Error" };
  }
};

export const distinctByCity = async (
  city: string
): Promise<VendorSupplyDistinct[]> => {
  try {
    const response = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `vendor/supply/distinct/by-city/${city}`
    );
    const result: VendorSupplyDistinct[] = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error distinctByCity | ${ex.message}`);
    return [];
  }
};

export const archiveById = async (id: number): Promise<CoreBaseResponse> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `vendor/supply/archive/${id}`
    );
    const result: CoreBaseResponse = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error archiveById | ${ex.message}`);
    return { ok: false, message: "Error" };
  }
};

export const createVendorSupply = async (
  data: VendorSupply
): Promise<CreateOrModifyResponse> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `vendor/supply/create/by-vendor`,
      data
    );
    const result: CreateOrModifyResponse = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error createVendorSupply | ${ex.message}`);
    return { ok: false, message: "Error" };
  }
};

export const skuTypeCityValidator = async (
  data: SkuAndTypeAndCityValidatorPayload
): Promise<CoreBaseResponse> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `vendor/supply/validator/sku-type-city`,
      data
    );
    const result: CoreBaseResponse = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error skuTypeCityValidator | ${ex.message}`);
    return { ok: false, message: "Error" };
  }
};

export const findBySkuAndVendorIdAndCity = async (
  skus: string, //skus separated by hyphens
  vendorId: number,
  city: string
): Promise<VendorSupplyResponse> => {
  try {
    const response = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `vendor/supply/skus/${skus}/vendor/${vendorId}/city/${city}`
    );
    const result: VendorSupplyResponse = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error findBySkuAndVendorIdAndCity | ${ex.message}`);
    return { ok: false, message: "Error" };
  }
};
