import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import appConfig from "config/app";
import { LinesPurchaseOrder } from "core/linesPurchaseOrders/entities/LinesPurchaseOrder";
import { LinesPurchaseOrdersHTTPRepository } from "core/linesPurchaseOrders/repositories/http/linesPurchaseOrders";
import { LinesPurchaseOrdersDTO } from "core/linesPurchaseOrders/traits/linesPurchaseOrder/canGetLinesPurchaseOrders";
import { GetLinesPurchaseOrdersUseCase } from "core/linesPurchaseOrders/useCases/linesPurchaseOrder/getLinesPurchaseOrdersUseCase";
import {
  ExistsLabelLogUseCase,
  RemoveLabelLogUseCase,
  ScannedItemReceiptUseCase,
} from "core/linesPurchaseOrders/useCases/linesPurchaseOrder/scannedItemReceipt";
import { UpdatePurchasOrderLineUseCase } from "core/linesPurchaseOrders/useCases/linesPurchaseOrder/updatePurchaseOrderLineUseCase";
import { ProductionScannedtResponse } from "core/productions/entities/Productions";
import { addNewLabelFromResponse } from "utils/scanner";
import { StoreRequestError, StoreRequestStatus } from "../../types";

interface LinesPurchaseState {
  detail: any;
  lines: LinesPurchaseOrdersDTO | null;
  linesStatus: {
    [sku: string]: {
      status: StoreRequestStatus;
      error: StoreRequestError;
    };
  } | null;
  filter: string | null;
  status: StoreRequestStatus;
  error: StoreRequestError;
}

const initialState: LinesPurchaseState = {
  detail: null,
  lines: null,
  linesStatus: null,
  filter: null,
  status: "idle",
  error: null,
};

export const getLinesPurchase = createAsyncThunk(
  "linesPurchase/getLinesPurchase",
  async (payload: { kitchenId: string; orderId: string; origin: string }) => {
    const httpRepository = new LinesPurchaseOrdersHTTPRepository(appConfig);
    const getLinesPurchaseOrdersUseCase = new GetLinesPurchaseOrdersUseCase(
      httpRepository
    );
    const linesPurchaseOrder = await getLinesPurchaseOrdersUseCase.execute(
      payload.kitchenId,
      payload.orderId,
      payload.origin
    );
    return linesPurchaseOrder;
  }
);

export const updatePurchaseOrderLine = createAsyncThunk(
  "linesPurchase/updatePurchaseOrderLine",
  async (payload: {
    id: number;
    lineNo: number;
    kitchenId: string;
    sku: string;
    quantityReceived: number;
    status: "IDLE" | "IN_PROCESS" | "REJECTED" | "FINISHED" | null;
    receiptNo: string;
    userId: string;
    externalCode: string;
    origin: string;
  }) => {
    const httpRepository = new LinesPurchaseOrdersHTTPRepository(appConfig);
    const updatePurchaseOrderLineUseCase = new UpdatePurchasOrderLineUseCase(
      httpRepository
    );
    const linesPurchaseOrder = await updatePurchaseOrderLineUseCase.execute(
      payload.id,
      payload.lineNo,
      payload.kitchenId,
      payload.sku,
      payload.quantityReceived ?? 0,
      payload.status,
      payload.receiptNo,
      payload.userId,
      payload.externalCode,
      payload.origin
    );
    return linesPurchaseOrder;
  }
);

export const scannedItemReceipt = createAsyncThunk(
  "linesPurchase/scannedItemReceipt",
  async (payload: {
    orderId: string;
    productionLabelId: string;
  }): Promise<ProductionScannedtResponse> => {
    const httpRepository = new LinesPurchaseOrdersHTTPRepository(appConfig);
    const addScannedItemCount = new ScannedItemReceiptUseCase(httpRepository);
    const addScannedItemCountResult = await addScannedItemCount.execute(
      payload.orderId,
      payload.productionLabelId
    );
    return addScannedItemCountResult;
  }
);

export const existsLabelLogReceipt = createAsyncThunk(
  "linesPurchase/existsLabelLogReceipt",
  async (payload: {
    orderId: string;
    originId: string;
    productionLabelId: string;
  }): Promise<ProductionScannedtResponse> => {
    const httpRepository = new LinesPurchaseOrdersHTTPRepository(appConfig);
    const useCase = new ExistsLabelLogUseCase(httpRepository);
    const result = await useCase.execute(
      payload.orderId,
      payload.originId,
      payload.productionLabelId
    );
    return result;
  }
);

export const removeLabelLogReceipt = createAsyncThunk(
  "linesPurchase/removeLabelLogReceipt",
  async (payload: {
    originId: string;
    deleteLabelsIds: number[];
  }): Promise<boolean> => {
    const httpRepository = new LinesPurchaseOrdersHTTPRepository(appConfig);
    const useCase = new RemoveLabelLogUseCase(httpRepository);
    const result = await useCase.execute(
      payload.deleteLabelsIds,
      payload.originId
    );
    return result;
  }
);

export const linesPurchaseSlice = createSlice({
  name: "linesPurchase",
  initialState,
  reducers: {
    setPurchaseLines: (
      state,
      action: PayloadAction<LinesPurchaseOrdersDTO | null>
    ) => {
      state.lines = action.payload;
    },
    setLinesWhenScanned: (
      state,
      action: PayloadAction<ProductionScannedtResponse>
    ) => {
      const { sum, reasonId } = action.payload;
      const newLines =
        state.lines?.map((lineFiltered) => {
          if (lineFiltered.lineId === reasonId) {
            addNewLabelFromResponse(lineFiltered.labels, action.payload);
            return {
              ...lineFiltered,
              quantityReceived: sum,
              status: "IN_PROCESS",
            } as LinesPurchaseOrder;
          }
          return lineFiltered;
        }) ?? null;
      state.lines = newLines;
    },
  },
  extraReducers(builder) {
    builder
      // getLinesPurchase
      .addCase(getLinesPurchase.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getLinesPurchase.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.lines = action.payload;
      })
      .addCase(getLinesPurchase.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ?? null;
      })
      // updatePurchaseOrderLine
      .addCase(updatePurchaseOrderLine.pending, (state, action) => {
        if (state.linesStatus === null) {
          state.linesStatus = {};
        }

        state.linesStatus[action.meta.arg.id] = {
          status: "loading",
          error: null,
        };
      })
      .addCase(updatePurchaseOrderLine.fulfilled, (state, action) => {
        if (state.linesStatus === null) {
          state.linesStatus = {};
        }

        if (state.lines) {
          const itemIndex = state.lines?.findIndex(
            (line) => line.lineId === action.meta.arg.id
          );

          if (
            action.meta.arg.status === "REJECTED" &&
            itemIndex !== undefined &&
            itemIndex > -1
          ) {
            state.lines[itemIndex].status = "REJECTED";
            state.lines[itemIndex].quantityReceived = 0;
          }

          if (action.meta.arg.status === "IN_PROCESS") {
            state.lines[itemIndex].status = "IN_PROCESS";
            if (action.meta.arg.origin === "KIS") {
              state.lines[itemIndex].quantityToReceive =
                action.meta.arg.quantityReceived;
            } else {
              state.lines[itemIndex].quantityReceived =
                action.meta.arg.quantityReceived;
            }
          }
        }

        state.linesStatus[action.meta.arg.id] = {
          status: "succeeded",
          error: null,
        };
      })
      .addCase(updatePurchaseOrderLine.rejected, (state, action) => {
        if (state.linesStatus === null) {
          state.linesStatus = {};
        }

        state.linesStatus[action.meta.arg.id] = {
          status: "failed",
          error: action.error.message ?? "",
        };
      })
      .addCase(removeLabelLogReceipt.fulfilled, (state, action) => {
        const {
          meta: {
            arg: { deleteLabelsIds },
          },
        } = action;

        const newLines =
          state.lines?.map((line) => {
            const newLabels = line.labels.filter(
              (label) => !deleteLabelsIds.includes(label.id)
            );
            return {
              ...line,
              quantityReceived: newLabels.reduce(
                (sum, label) => sum + label.quantity,
                0
              ),
              labels: newLabels,
            };
          }) ?? null;
        state.lines = newLines;
      });
  },
});

export const { setPurchaseLines, setLinesWhenScanned } =
  linesPurchaseSlice.actions;
export const { reducer } = linesPurchaseSlice;

export default linesPurchaseSlice;
