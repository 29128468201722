import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  Alert,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import ManualAdjustmentsBulkLoad from "app/components/Adjustments/Manual/BulkLoad";
import CustomTabs from "app/components/common/Tabs";
import CountrySelector from "app/components/CountrySelector";
import PageHeader from "app/components/PageHeader";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import {
  adjustmentsModule,
  commons,
  kitchenInventoryManagement,
} from "app/i18n/types";
import { clearAddManualAdjustment } from "app/store/slices/adjustments";
import { AddManualAdjustment } from "app/store/slices/adjustments/thunks";
import { getSuppliesByCountry } from "app/store/slices/supplies/thunks";
import dayjs from "app/utils/dayjs";
import {
  EntryType,
  ManualAdjustment,
  ManualAdjustmentEntryType,
} from "core/adjustments/entities/ManualAdjustment";
import { Country } from "core/common/entities/Country";
import { Kitchen } from "core/supplies/entities/Kitchen";
import { Supply } from "core/supplies/entities/Supply";
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { PageBox } from "utils/generalUI";

interface ManualAdjustementsProps {}

const ManualAdjustements: FunctionComponent<ManualAdjustementsProps> = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const kitchens = useAppSelector((state) => state.global.kitchens.data);
  const addManualAdjustmentRequest = useAppSelector(
    (state) => state.adjustments.addManualAdjustment
  );
  const suppliesRequest = useAppSelector(
    (state) => state.supplies.suppliesByCountry
  );
  const [manualAdjustments, setManualAdjustments] = useState<
    Array<ManualAdjustment>
  >([]);
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const [selectedKitchen, setSelectedKitchen] = useState<Kitchen | null>(null);
  const [selectedSupply, setSelectedSupply] = useState<Supply | null>(null);
  const [adjustmentQuantity, setAdjustmentQuantity] = useState<number | null>(
    null
  );
  const [selectedAdjustmentType, setSelectedAdjustmentType] =
    useState<ManualAdjustmentEntryType | null>(null);
  const [documentCode, setDocumentCode] = useState<string | null>(null);
  const [tab, setTab] = useState<number>(-1);

  const getSupplies = useCallback(() => {
    if (selectedKitchen !== null) {
      const country = Country[selectedKitchen.country as keyof typeof Country];
      dispatch(getSuppliesByCountry(country));
    }
  }, [dispatch, selectedKitchen]);

  const addManualAdjustment = useCallback(() => {
    if (manualAdjustments.length > 0) {
      dispatch(
        AddManualAdjustment({
          adjustments: manualAdjustments,
        })
      );
    }
  }, [dispatch, manualAdjustments]);

  const addManualAdjustmentToList = useCallback(() => {
    if (
      selectedKitchen !== null &&
      selectedSupply !== null &&
      adjustmentQuantity !== null &&
      selectedAdjustmentType !== null &&
      documentCode !== null
    ) {
      const actualSendedAdjustment: ManualAdjustment = {
        quantity: adjustmentQuantity,
        sku: selectedSupply.sku,
        kitchenId: selectedKitchen.kitchenId,
        entryType: selectedAdjustmentType,
        name: selectedSupply.name,
        unit: selectedSupply.unit,
        documentCode: documentCode,
        key: new Date().toTimeString(),
      };

      const adjustments = [...manualAdjustments, actualSendedAdjustment];

      setManualAdjustments(adjustments);

      setSelectedSupply(null);
      setAdjustmentQuantity(null);
      setSelectedAdjustmentType(null);
      setSelectedKitchen(null);
      setDocumentCode(null);
    }
  }, [
    adjustmentQuantity,
    manualAdjustments,
    selectedAdjustmentType,
    selectedKitchen,
    selectedSupply,
    documentCode,
  ]);

  const removeManualAdjustment = useCallback(
    (key: string) => {
      const adjustments = manualAdjustments.filter(
        (adjustment) => adjustment.key !== key
      );

      setManualAdjustments(adjustments);
    },
    [manualAdjustments]
  );

  const countries: Array<string> | null = useMemo(() => {
    if (kitchens.length) {
      return [...new Set(kitchens.map((item) => item.country))];
    }
    return null;
  }, [kitchens]);

  useEffect(() => {
    getSupplies();
  }, [getSupplies, selectedKitchen]);

  useEffect(() => {
    if (addManualAdjustmentRequest.status === "succeeded") {
      setManualAdjustments([]);

      const timer = setTimeout(() => {
        dispatch(clearAddManualAdjustment());
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [addManualAdjustmentRequest.status, dispatch]);

  return (
    <PageBox>
      <PageHeader
        title={t(kitchenInventoryManagement.SINGLEADJUSTS_MODULE)}
        subtitle={t(
          kitchenInventoryManagement.SINGLEADJUSTS_MODULE_DESCRIPTION
        )}
        rightArea={[
          {
            children: t(commons.SAVE),
            onClick: () => addManualAdjustment(),
            variant: "contained",
            size: "large",
            disabled:
              manualAdjustments.length === 0 ||
              addManualAdjustmentRequest.status === "loading",
          },
        ]}
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTabs
            tabs={[
              {
                children: t(kitchenInventoryManagement.SINGLEADJUSTS_MODULE),
                startIcon: <CalendarMonthOutlinedIcon />,
              },
              {
                children: t(commons.BULK_LOAD),
                startIcon: <ArchiveOutlinedIcon />,
              },
            ]}
            onChange={setTab}
          />
        </Grid>
        <Grid item xs={12}>
          {tab === 0 && (
            <>
              <Box>
                <CountrySelector
                  countries={countries}
                  value={selectedCountry}
                  onChange={(country) => setSelectedCountry(country)}
                  disabled={addManualAdjustmentRequest.status === "loading"}
                />
              </Box>

              <TableContainer component={Paper} sx={{ mt: 2 }}>
                {suppliesRequest.status === "loading" ||
                  (addManualAdjustmentRequest.status === "loading" && (
                    <LinearProgress />
                  ))}
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        "& > th": {
                          bgcolor: "#F9FAFC",
                          borderBottom: "2px solid #E3E5E8",
                        },
                      }}
                    >
                      <TableCell align="center">{t(commons.DATE)}</TableCell>
                      <TableCell align="center">
                        {t(commons.SELECT_LOCATION)}
                      </TableCell>
                      <TableCell align="center">{t(commons.SUPPLY)}</TableCell>
                      <TableCell align="center">{t(commons.UNITS)}</TableCell>
                      <TableCell align="center">
                        {t(commons.QUANTITY)}
                      </TableCell>
                      <TableCell align="center">
                        {t(commons.DOCUMENT_CODE)}
                      </TableCell>
                      <TableCell align="center">{t(commons.TYPE)}</TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {addManualAdjustmentRequest.status === "succeeded" && (
                      <TableRow>
                        <TableCell align="center" colSpan={7}>
                          <Alert color="success">
                            {t(adjustmentsModule.SUCCESSFULLY_SAVE)}
                          </Alert>
                        </TableCell>
                      </TableRow>
                    )}

                    <TableRow>
                      <TableCell align="center">
                        {dayjs().format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell align="center" width={250}>
                        <Autocomplete
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t(commons.SELECT_LOCATION)}
                              size="small"
                              sx={{ bgcolor: "white" }}
                            />
                          )}
                          value={selectedKitchen}
                          options={kitchens.filter(
                            (kitchen) => kitchen.country === selectedCountry
                          )}
                          getOptionLabel={(option) => option.name}
                          onChange={(_, value) => setSelectedKitchen(value)}
                          disabled={
                            addManualAdjustmentRequest.status === "loading" ||
                            selectedCountry === null
                          }
                        />
                      </TableCell>

                      <TableCell align="center" width={250}>
                        <Autocomplete
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t(commons.SUPPLY)}
                              size="small"
                              sx={{ bgcolor: "white" }}
                            />
                          )}
                          value={selectedSupply}
                          options={suppliesRequest.supplies ?? []}
                          getOptionLabel={(option) =>
                            `${option.sku} - ${option.name}`
                          }
                          onChange={(_, value) => setSelectedSupply(value)}
                          disabled={
                            suppliesRequest.status === "loading" ||
                            selectedKitchen === null ||
                            addManualAdjustmentRequest.status === "loading"
                          }
                        />
                      </TableCell>
                      <TableCell align="center">
                        {selectedSupply !== null
                          ? t(
                              `measureUnits.${selectedSupply.unit.toLocaleLowerCase()}`
                            )
                          : " -"}
                      </TableCell>

                      <TableCell align="center">
                        <TextField
                          label={t(commons.QUANTITY)}
                          size="small"
                          onChange={(e) =>
                            setAdjustmentQuantity(+e.target.value)
                          }
                          value={adjustmentQuantity ?? ""}
                          InputProps={{
                            type: "number",
                          }}
                          disabled={
                            addManualAdjustmentRequest.status === "loading"
                          }
                          autoComplete="off"
                        />
                      </TableCell>

                      <TableCell align="center">
                        <TextField
                          label={t(commons.DOCUMENT_CODE)}
                          size="small"
                          inputProps={{
                            maxLength: 20,
                            style: { textTransform: "uppercase" },
                          }}
                          onChange={(e) =>
                            setDocumentCode(e.target.value.toUpperCase())
                          }
                          value={documentCode ?? ""}
                          disabled={
                            addManualAdjustmentRequest.status === "loading"
                          }
                          autoComplete="off"
                        />
                      </TableCell>

                      <TableCell align="center" width={250}>
                        <FormControl
                          size="small"
                          disabled={
                            addManualAdjustmentRequest.status === "loading"
                          }
                          fullWidth
                        >
                          <InputLabel id="demo-simple-select-label">
                            {t(commons.TYPE)}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label={t(commons.TYPE)}
                            size="small"
                            value={selectedAdjustmentType ?? ""}
                            onChange={(e) =>
                              setSelectedAdjustmentType(
                                e.target.value as ManualAdjustmentEntryType
                              )
                            }
                          >
                            <MenuItem value={EntryType.POSITIVE}>
                              <Typography fontWeight={700} color="success.main">
                                {t(`adjustmentType.${EntryType.POSITIVE}`)}
                              </Typography>
                            </MenuItem>
                            <MenuItem value={EntryType.NEGATIVE}>
                              <Typography fontWeight={700} color="error.main">
                                {t(`adjustmentType.${EntryType.NEGATIVE}`)}
                              </Typography>
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>

                      <TableCell align="center">
                        <Button
                          variant="contained"
                          onClick={addManualAdjustmentToList}
                          disabled={
                            selectedKitchen === null ||
                            selectedSupply === null ||
                            selectedAdjustmentType === null ||
                            adjustmentQuantity === null ||
                            adjustmentQuantity === 0 ||
                            documentCode === null ||
                            documentCode === ""
                          }
                        >
                          {t(commons.ADD)}
                        </Button>
                      </TableCell>
                    </TableRow>

                    {manualAdjustments?.map((adjustment) => (
                      <TableRow key={adjustment.key ?? ""}>
                        <TableCell align="center">
                          {dayjs().format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell align="center">
                          {adjustment.kitchenId}
                        </TableCell>
                        <TableCell align="center">
                          {adjustment.sku} - {adjustment.name}
                        </TableCell>
                        <TableCell align="center">{adjustment.unit}</TableCell>
                        <TableCell align="center">
                          {adjustment.quantity}
                        </TableCell>
                        <TableCell align="center">
                          {adjustment.documentCode}
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            fontWeight={700}
                            color={
                              adjustment.entryType === EntryType.POSITIVE
                                ? "success.main"
                                : "error.main"
                            }
                          >
                            {t(`adjustmentType.${adjustment.entryType}`)}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            onClick={() =>
                              removeManualAdjustment(adjustment.key ?? "")
                            }
                            color="error"
                          >
                            <DeleteOutlineOutlinedIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
          {tab === 1 && <ManualAdjustmentsBulkLoad />}
        </Grid>
      </Grid>
    </PageBox>
  );
};

export default ManualAdjustements;
