import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typograhpy from "@mui/material/Typography";
import { commons, productionManagement } from "app/i18n/types";
import { convertDate } from "app/utils/dateUtils";
import { ProductionRecord } from "core/productions/entities/Productions";
import { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SelectedProductionActionType } from "utils/ratatouille";
import ProductionCardHeaderActions from "./HeaderActions";
import ProductionCardLabelProgress from "./LabelProgress";
import ProductionCardResponsible from "./Responsible";
import ProductionTimer from "./Timer";

interface Props {
  production: ProductionRecord;
  kitchenId: string | null;
  openDelete: () => void;
  setSelectedProduction: (action: SelectedProductionActionType) => void;
}

const ProductionCardContent: FunctionComponent<Props> = (props) => {
  const { production, kitchenId, openDelete, setSelectedProduction } = props;
  const { t } = useTranslation();

  const quantity = useMemo(() => {
    let q = production.quantityToProduce ?? 0;

    if (
      production.status === "FINISHED" &&
      production.producedQuantity !== null
    ) {
      q = production.producedQuantity;
    }

    return `${q}`;
  }, [production]);

  const units = t(`measureUnits.${production.unit.toLowerCase()}`);

  const flexColumn = {
    display: "flex",
    flexDirection: "column",
    gap: 0.5,
  };

  const finalStatuses = ["FINISHED", "APPROVED", "APPROVED_WITH_NOVELTY"];

  return (
    <Box
      sx={{ cursor: "pointer" }}
      onDoubleClick={() =>
        setSelectedProduction(SelectedProductionActionType.DRAWER_INFO)
      }
    >
      <Grid container spacing={0.5}>
        <Grid item xs={8} sx={flexColumn}>
          <Typograhpy variant="caption" color="text.secondary">
            {t(productionManagement.PRODUCTION_AND_QUANTITY)}
          </Typograhpy>
          <Typograhpy
            fontWeight={700}
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
            }}
          >
            {production.name}
          </Typograhpy>
          <Typograhpy>{`${quantity} ${units}`}</Typograhpy>
        </Grid>
        <Grid item xs={4} sx={{ ...flexColumn, alignItems: "flex-end" }}>
          <Typograhpy variant="caption" color="text.secondary">
            {t(
              finalStatuses.includes(production.status)
                ? commons.TIME
                : productionManagement.CHRONOMETER
            )}
          </Typograhpy>
          <ProductionTimer production={production} />
        </Grid>
        <Grid item xs={5} sx={flexColumn}>
          <Typograhpy variant="caption" color="text.secondary">
            {t(commons.DATE)}
          </Typograhpy>
          <Typograhpy>
            {convertDate(production.createdAt, "dd/MM/YYY")}
          </Typograhpy>
        </Grid>
        <Grid item xs={7} sx={{ ...flexColumn, alignItems: "flex-end" }}>
          {finalStatuses.includes(production.status) && (
            <ProductionCardHeaderActions
              kitchenId={kitchenId}
              openDelete={openDelete}
              production={production}
              setSelectedProduction={setSelectedProduction}
              isFinished
            />
          )}

          {!finalStatuses.includes(production.status) &&
            production.type === "MANUAL" && (
              <ProductionCardHeaderActions
                kitchenId={kitchenId}
                openDelete={openDelete}
                production={production}
                setSelectedProduction={setSelectedProduction}
              />
            )}

          {!finalStatuses.includes(production.status) &&
            production.type === "PLANNED" && (
              <ProductionCardResponsible production={production} compact />
            )}
        </Grid>
      </Grid>
      {finalStatuses.includes(production.status) && (
        <>
          <ProductionCardResponsible production={production} />
          <ProductionCardLabelProgress production={production} />
        </>
      )}
    </Box>
  );
};
export default ProductionCardContent;
