import {
  Add,
  CalendarMonthOutlined,
  DeleteOutline,
  DomainOutlined,
  EventAvailable,
  ExpandLess,
  ExpandMore,
  Grid3x3Outlined,
  SendOutlined,
  StorefrontOutlined,
} from "@mui/icons-material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { commons, purchaseOrderCreation } from "app/i18n/types";
import appConfig from "config/app";
import { CommentTypeEnum } from "core/comment/entity";
import { Country } from "core/common/entities/Country";
import {
  NewIndividualPurchaseOrderLine,
  PurchaseOrder,
} from "core/purchaseOrders/entities/PurchaseOrder";
import {
  createPurchaseOrderLine,
  deletePurchase,
  deletePurchaseOrderLines,
  updatePurchaseOrderEstimatedDate,
} from "core/purchaseOrders/repositories/http/purchase";
import { Supply } from "core/supplies/entities/Supply";
import { SupplyHTTPRepository } from "core/supplies/repositories/http/supply";
import { VendorSupply } from "core/vendors/entities/VendorSupply";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatPrice, localeData } from "utils/currency";
import { OnChange } from "utils/general";
import CommentArea from "../../../CommentArea";
import VendorSupplyPriceRangeChipAlert from "../../../Vendor/Supply/PriceRange/ChipAlert";
import VendorSupplyPriceRangeInPOValidator from "../../../Vendor/Supply/PriceRange/PurchaseOrder/Validator";
import {
  ValidationItemResponse,
  purchaseOrderLineToSupplyToValidate,
} from "../../../Vendor/Supply/PriceRange/utils";
import CommonDialog from "../../../common/CommonDialog";
import AddLineModal from "../../AddLineModal";
import ConfirmPurchaseOrderModal from "../../ConfirmPurchaseOrderModal";
import DeleteLinesModal from "../../DeleteLinesModal";
import PurchaseOrderLinesTable from "../../PurchaseOrderLinesTable";
import UpdateEstimateDateModal from "../../UpdateEstimateDateModal";

interface Props {
  purchase: PurchaseOrder;
  checkedPurchaseOrders: number[];
  openAddLineModal: boolean;
  toggleChecked: (purchaseId: number) => void;
  onPurchaseOrderChange: (change: OnChange) => void;
  updateOrders: () => void;
}

const PurchaseOrderItem = (props: Props) => {
  const {
    purchase,
    checkedPurchaseOrders,
    openAddLineModal,
    toggleChecked,
    onPurchaseOrderChange,
    updateOrders,
  } = props;

  const { t } = useTranslation();

  const withoutLines = !purchase.lines.length;
  const isOrderSelected = checkedPurchaseOrders.includes(purchase.id);
  const http = new SupplyHTTPRepository(appConfig);

  const [open, setOpen] = useState<boolean>(withoutLines);
  const [checkedLines, setCheckedLines] = useState<Array<number>>([]);
  const [openUpdateEstimatedDate, setOpenUpdateEstimatedDate] =
    useState<boolean>(false);
  const [openDeleteLines, setOpenDeleteLines] = useState<boolean>(false);
  const [openAddLine, setOpenAddLine] = useState<boolean>(openAddLineModal);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openDeleteOrder, setOpenDeleteOrder] = useState<boolean>(false);
  const [warns, setWarns] = useState<ValidationItemResponse[]>([]);
  const [vendorSupplies, setVendorSupplies] = useState<VendorSupply[]>([]);
  const [supplies, setSupplies] = useState<Supply[]>([]);

  const getPurchaseTotal = useCallback(() => {
    return purchase.lines.reduce(
      (acc, line) => acc + line.quantity * line.unitPrice,
      0
    );
  }, [purchase]);

  const updatePurchaseEstimatedDate = (id: number, date: string) => {
    updatePurchaseOrderEstimatedDate(id, date).then((response) => {
      if (response.ok) {
        onPurchaseOrderChange({
          id,
          label: "expectedReceiptAt",
          value: date,
        });
      }
    });
  };

  const onPurchaseOrderRemove = useCallback(
    (ids: number[]) => {
      onPurchaseOrderChange({ label: "removeMany", value: ids });
    },
    [onPurchaseOrderChange]
  );

  const archivePurchaseOrder = () => {
    deletePurchase(purchase.id).then((response) => {
      if (response.ok) {
        updateOrders();
        setOpenDeleteOrder(false);
      }
    });
  };

  const handleDeleteSelectedLines = useCallback(async () => {
    const response = await deletePurchaseOrderLines(checkedLines);
    if (response.ok) {
      onPurchaseOrderChange({
        id: purchase.id,
        label: "detailRemoveMany",
        value: checkedLines,
      });
      setOpenDeleteLines(false);
      setCheckedLines([]);
    }
  }, [onPurchaseOrderChange, purchase, checkedLines]);

  const handleAddLine = useCallback(
    async (newPurchaseOrderLine: NewIndividualPurchaseOrderLine) => {
      const response = await createPurchaseOrderLine(newPurchaseOrderLine);
      if (response.ok) {
        onPurchaseOrderChange({
          id: purchase.id,
          label: "detailNew",
          value: {
            id: response.id,
            sku: newPurchaseOrderLine.sku,
            productName: newPurchaseOrderLine.productName,
            quantity: newPurchaseOrderLine.quantity,
            unitPrice: newPurchaseOrderLine.unitPrice,
            measureUnit: newPurchaseOrderLine.measureUnit,
          },
        });
        setOpenAddLine(false);
      }
    },
    [onPurchaseOrderChange, purchase]
  );

  useEffect(() => {
    if (withoutLines && isOrderSelected) {
      toggleChecked(purchase.id);
    }
  }, [JSON.stringify(purchase.lines)]);

  useEffect(() => {
    if (purchase.country) {
      const country = Country[purchase.country as keyof typeof Country];
      http.getSuppliesByCountry(country).then((response) => {
        setSupplies(response);
      });
    }
  }, [purchase.country]);

  return (
    <Paper
      sx={{ p: 2, mb: 2, display: "flex", flexDirection: "column", gap: 1 }}
    >
      <VendorSupplyPriceRangeInPOValidator
        kitchenId={purchase.locationCode}
        vendorId={Number(purchase.vendorCode)}
        supplyToValidate={purchaseOrderLineToSupplyToValidate(purchase.lines)}
        onValidation={setWarns}
        onVendorSupplies={setVendorSupplies}
      />
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center" gap={1}>
          <DomainOutlined />
          <Typography variant="h6">{purchase.vendorName}</Typography>
        </Box>
        <Box display="flex">
          <Typography variant="h6">
            {formatPrice(getPurchaseTotal(), localeData[purchase.country])}
          </Typography>
          <Checkbox
            sx={{ paddingY: 0 }}
            onChange={() => toggleChecked(purchase.id)}
            value={isOrderSelected}
            checked={isOrderSelected}
            disabled={withoutLines}
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" gap={1} alignItems="center">
          <Box display="flex" alignItems="center">
            <Grid3x3Outlined sx={{ color: "text.secondary" }} />
            <Typography variant="body1" fontWeight={600} color="text.secondary">
              {purchase.id}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <StorefrontOutlined color="success" />
            <Typography
              variant="body2"
              fontWeight={600}
              color="success.main"
              mr={2}
            >
              {purchase.locationCode}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <CalendarMonthOutlined sx={{ color: "text.secondary" }} />
            <Typography variant="body2" fontWeight={600} color="text.secondary">
              {`${t(purchaseOrderCreation.ESTIMATED_DELIVERY_DATE_LABEL)} ${
                purchase.expectedReceiptAt
              }`}
            </Typography>
          </Box>
          {!!warns.length && (
            <Box display="flex" alignItems="center">
              <VendorSupplyPriceRangeChipAlert warns={warns} />
            </Box>
          )}
        </Box>
        <IconButton onClick={() => setOpen(!open)}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Box>
      {open && (
        <Box display="flex" flexDirection="column" gap={2}>
          <Alert severity="info">
            {t(purchaseOrderCreation.VALIDATE_ITEM_MESSAGE)}
          </Alert>
          <Box display="flex" justifyContent="space-between" gap={2}>
            <Box display="flex" gap={2}>
              <Button
                variant="outlined"
                color="error"
                startIcon={<DeleteOutline />}
                onClick={() => setOpenDeleteLines(true)}
                disabled={checkedLines.length === 0}
              >
                {t(commons.DELETE)}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<Add />}
                onClick={() => setOpenAddLine(true)}
              >
                {t(commons.ADD)}
              </Button>
              <Button
                variant="outlined"
                color="warning"
                startIcon={<EventAvailable />}
                onClick={() => setOpenUpdateEstimatedDate(true)}
              >
                {t(commons.DELIVERY_DATE)}
              </Button>
            </Box>
            <Box>
              <Button
                variant="outlined"
                color="error"
                disableElevation
                startIcon={<DeleteOutline />}
                onClick={() => setOpenDeleteOrder(true)}
                sx={{ mr: "16px" }}
              >
                {t(purchaseOrderCreation.DELETE_ORDER)}
              </Button>
              <Button
                variant="contained"
                color="info"
                disableElevation
                startIcon={<SendOutlined />}
                onClick={() => setOpenConfirm(true)}
                sx={{ color: "white" }}
                disabled={withoutLines}
              >
                {t(purchaseOrderCreation.CONFIRM_AND_SEND_BUTTON)}
              </Button>
            </Box>
          </Box>
          <PurchaseOrderLinesTable
            purchase={purchase}
            setCheckedLines={setCheckedLines}
            onPurchaseOrderChange={onPurchaseOrderChange}
            warns={warns}
          />
          <CommentArea
            sourceId={purchase.id.toString()}
            type={CommentTypeEnum.PURCHASE_ORDER}
            kitchenId={purchase.locationCode}
          />
        </Box>
      )}
      {openUpdateEstimatedDate && (
        <UpdateEstimateDateModal
          purchaseOrder={purchase}
          onClose={() => setOpenUpdateEstimatedDate(false)}
          updatePurchaseEstimatedDate={updatePurchaseEstimatedDate}
        />
      )}
      <DeleteLinesModal
        open={openDeleteLines}
        purchaseOrder={purchase}
        checkedLines={checkedLines}
        onClose={() => setOpenDeleteLines(false)}
        handleDeleteSelectedLines={handleDeleteSelectedLines}
      />
      <CommonDialog
        open={openDeleteOrder}
        title={t(purchaseOrderCreation.DELETE_PURCHASE_ORDER_TITLE)}
        message={`${t(purchaseOrderCreation.DELETE_PURCHASE_ORDER_MESSAGE)} ${
          purchase.vendorName
        }`}
        icon={
          <DeleteOutlinedIcon
            sx={{
              height: "80px",
              width: "80px",
              paddingRight: "5px",
              color: "error.main",
            }}
          />
        }
        showCancelButton={true}
        handleConfirm={archivePurchaseOrder}
        handleClose={() => setOpenDeleteOrder(false)}
      />

      {openAddLine && (
        <AddLineModal
          purchaseOrder={purchase}
          supplies={supplies}
          vendorSupplies={vendorSupplies}
          onClose={() => setOpenAddLine(false)}
          handleAddLine={handleAddLine}
        />
      )}
      {openConfirm && (
        <ConfirmPurchaseOrderModal
          purchaseOrder={purchase}
          onClose={() => setOpenConfirm(false)}
          onPurchaseOrderConfirmAndSend={onPurchaseOrderRemove}
        />
      )}
    </Paper>
  );
};

export default PurchaseOrderItem;
