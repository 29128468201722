import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { physicalCountSchedule } from "app/i18n/types";
import React, { FunctionComponent, memo } from "react";
import { useTranslation } from "react-i18next";
import { getDatesBetween } from "utils/general";
import DateStepTable from "./Table";

interface DateStepProps {
  dates: string[];
  setDates: (dates: string[]) => void;
  size?: "small" | "medium";
}

const DateStep: FunctionComponent<DateStepProps> = (props) => {
  const { dates, setDates, size } = props;

  const { t } = useTranslation();

  const now = new Date();
  now.setHours(0, 0, 0);
  const today = now.toISOString().split("T")[0];

  const [startDate, setStartDate] = React.useState<string>(today);
  const [endDate, setEndDate] = React.useState<string>(today);
  const [option, setOption] = React.useState<number>(1);

  React.useEffect(() => {
    setStartDate(today);
    setEndDate(today);
  }, [option]);

  React.useEffect(() => {
    if (option === 2) {
      const startTime = new Date(startDate).getTime();
      const endTime = new Date(endDate).getTime();
      if (endTime < startTime) {
        setEndDate(startDate);
      }
    }
    if (!startDate) setStartDate(today);
    if (!endDate) setEndDate(today);
  }, [startDate, endDate]);

  const handleAddDate = () => {
    const datesToAdd = [...dates];
    if (option === 1) {
      if (!datesToAdd.includes(startDate)) {
        datesToAdd.push(startDate);
      }
    }
    if (option === 2) {
      const datesBetween = getDatesBetween(startDate, endDate);
      datesBetween.forEach((date) => {
        if (!datesToAdd.includes(date)) {
          datesToAdd.push(date);
        }
      });
    }
    setDates(datesToAdd);
  };

  const removeDate = (index: number) => {
    const datesToRm = [...dates];
    datesToRm.splice(index, 1);
    setDates(datesToRm);
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} xs={12}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={(event) => {
              setOption(parseInt(event.target.value) ?? 1);
            }}
          >
            <FormControlLabel
              value={1}
              checked={option === 1}
              control={<Radio />}
              label={t(physicalCountSchedule.SINGLE_DATE)}
            />
            <FormControlLabel
              value={2}
              checked={option === 2}
              control={<Radio />}
              label={t(physicalCountSchedule.DATE_RANGE)}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item sm={3} xs={12}>
        <TextField
          sx={{
            width: "100%",
            backgroundColor: "white",
          }}
          size={size}
          label={t(
            option === 1
              ? physicalCountSchedule.COUNT_DATE
              : physicalCountSchedule.START_DATE
          )}
          type="date"
          onChange={(event) => setStartDate(event.target.value ?? today)}
          inputProps={{
            min: today,
          }}
          value={startDate}
          autoComplete="off"
        />
      </Grid>
      {option === 2 && (
        <Grid item sm={3} xs={12}>
          <TextField
            sx={{
              width: "100%",
              backgroundColor: "white",
            }}
            size={size}
            label={t(physicalCountSchedule.END_DATE)}
            type="date"
            onChange={(event) => setEndDate(event.target.value ?? today)}
            inputProps={{
              min: today,
            }}
            value={endDate}
            autoComplete="off"
          />
        </Grid>
      )}
      <Grid item sm={3} xs={12}>
        <Button
          onClick={handleAddDate}
          variant="outlined"
          size={size}
          fullWidth
          sx={{ height: "100%" }}
          startIcon={<CalendarMonthOutlinedIcon />}
        >
          {t(
            option === 1
              ? physicalCountSchedule.SCHEDULE_DATE
              : physicalCountSchedule.SCHEDULE_DATES
          )}
        </Button>
      </Grid>
      <Grid item sm={12} xs={12}>
        <DateStepTable dates={dates} removeDate={removeDate} size={size} />
      </Grid>
    </Grid>
  );
};

export default memo(DateStep);
