import { InventoryAdjustment } from "core/adjustments/entities/ManualAdjustment";
import { CoreBaseResponse } from "core/common/interfaces/core";
import { VendorSupply } from "core/vendors/entities/VendorSupply";

export enum BulkLoadAction {
  CREATE = "CREATE",
  MODIFY = "MODIFY",
  NOTHING = "NOTHING",
  NOTHING_BY_ERROR = "NOTHING_BY_ERROR",
}

export type BulkLoadActionType = `${BulkLoadAction}`;

export interface BulkLoadError {
  index: number;
  content: string;
}

export interface BulkLoadLineValidator {
  index: number;
  action: BulkLoadActionType;
  error?: string;
}

export interface BulkLoadVendorSupplyValidator extends BulkLoadLineValidator {
  data: VendorSupply;
}

export interface BulkLoadVendorSupplyValidatorResponse
  extends CoreBaseResponse {
  data?: BulkLoadVendorSupplyValidator[];
}

export interface BulkLoadLineSave {
  index: number;
  success: boolean;
}

export interface BulkLoadVendorSupplySaveResponse extends CoreBaseResponse {
  data?: BulkLoadLineSave[];
}

export interface BulkLoadLineTable
  extends BulkLoadLineValidator,
    Partial<VendorSupply> {
  error: string;
}

export interface BulkLoadAdjustmentValidatorResponse extends CoreBaseResponse {
  data?: DataAdjustmentValidator[];
}

export interface DataAdjustmentValidator extends BulkLoadLineValidator {
  data: InventoryAdjustment;
}
