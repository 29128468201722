import { useAppSelector } from "app/hooks/useAppSelector";
import {
  citiesOptions,
  countriesOptions,
  locationsOptionsByCity,
  locationsOptionsByCountry,
} from "app/utils/kitchenSelector.utils";
import { Kitchen } from "core/supplies/entities/Kitchen";
import { FunctionComponent, memo, useEffect, useState } from "react";
import { getHashJSON, setHash } from "utils/route";
import KitchenSelectorAutoCompletes from "../Autocomplete";

interface KitchenSelectorProps {
  selectedCountry?: string | null;
  selectedCity?: string | null;
  selectedLocation?: Kitchen | null;
  onCountryChange?: (country: string | null) => void;
  onCityChange?: (city: string | null) => void;
  onKitchenChange?: (kitchen: Kitchen | null) => void;
  disabled?: boolean;
  size?: "small" | "medium";
  hideCountrySelector?: boolean;
  hideCitySelector?: boolean;
  hideLocationSelector?: boolean;
  noHash?: boolean;
}

interface SelectorHash {
  selectedCountry?: string;
  selectedCity?: string;
  selectedKitchen?: string;
}

const KitchenSelector: FunctionComponent<KitchenSelectorProps> = (props) => {
  const {
    onCountryChange,
    onCityChange,
    onKitchenChange,
    selectedCountry: _selectedCountry,
    selectedCity: _selectedCity,
    selectedLocation: _selectedLocation,
    disabled,
    size,
    hideCountrySelector,
    hideCitySelector,
    hideLocationSelector,
    noHash,
  } = props;

  const hashParam = getHashJSON() as SelectorHash;
  const kitchens = useAppSelector((state) => state.global.kitchens.data);

  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const [selectedCity, setSelectedCity] = useState<string | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<Kitchen | null>(
    null
  );
  const [isManualChange, setIsManualChange] = useState<boolean>(false);

  useEffect(() => {
    if (noHash) return;
    let data = {};
    if (selectedCountry) {
      data = Object.assign(data, { selectedCountry });
    }
    if (selectedCity) {
      data = Object.assign(data, { selectedCity });
    }
    if (selectedLocation) {
      data = Object.assign(data, {
        selectedKitchen: selectedLocation.kitchenId,
      });
    }
    if (Object.keys(data).length) {
      setHash(data);
    }
  }, [selectedCountry, selectedCity, selectedLocation]);

  useEffect(() => {
    onCountryChange?.(selectedCountry);
    if (isManualChange) {
      setSelectedCity(null);
      setSelectedLocation(null);
    }
  }, [selectedCountry]);

  useEffect(() => {
    onCityChange?.(selectedCity);
    if (isManualChange) {
      setSelectedLocation(null);
    }
  }, [selectedCity]);

  useEffect(() => {
    onKitchenChange?.(selectedLocation);
  }, [selectedLocation]);

  useEffect(() => {
    if (kitchens.length) {
      let selectedKitchen = undefined;
      if (hashParam?.selectedKitchen) {
        selectedKitchen = kitchens.find(
          (el) => el.kitchenId === hashParam.selectedKitchen
        );
      }
      setSelectedCountry(
        hashParam?.selectedCountry ?? _selectedCountry ?? null
      );
      setSelectedCity(hashParam?.selectedCity ?? _selectedCity ?? null);
      setSelectedLocation(selectedKitchen ?? _selectedLocation ?? null);
    }
  }, [kitchens.length]);

  useEffect(() => {
    if (_selectedLocation) {
      setSelectedLocation(_selectedLocation);
    }
  }, [_selectedLocation]);

  let countriesOptionsTmp = !hideCountrySelector
    ? countriesOptions(kitchens)
    : [];
  let citiesOptionsTmp = !hideCitySelector
    ? citiesOptions(selectedCountry, kitchens)
    : [];
  let locationsOptionsTmp = !hideLocationSelector
    ? locationsOptionsByCity(selectedCity, kitchens, null, {})
    : [];
  if (hideCitySelector) {
    locationsOptionsTmp = locationsOptionsByCountry(
      selectedCountry,
      kitchens,
      null,
      {}
    );
  }
  if (hideCountrySelector && hideCitySelector) {
    locationsOptionsTmp = kitchens;
  }

  return (
    <KitchenSelectorAutoCompletes
      countriesOptions={countriesOptionsTmp}
      citiesOptions={citiesOptionsTmp}
      locationsOptions={locationsOptionsTmp}
      selectedCountry={selectedCountry}
      selectedCity={selectedCity}
      selectedLocation={selectedLocation}
      setSelectedCountry={(selected) => {
        setIsManualChange(true);
        setSelectedCountry(selected);
      }}
      setSelectedCity={(selected) => {
        setIsManualChange(true);
        setSelectedCity(selected);
      }}
      setSelectedLocation={(selected) => {
        setIsManualChange(true);
        setSelectedLocation(selected);
      }}
      disabled={disabled}
      size={size}
      hideCountrySelector={hideCountrySelector}
      hideCitySelector={hideCitySelector}
      hideLocationSelector={hideLocationSelector}
    />
  );
};

export default memo(KitchenSelector);
