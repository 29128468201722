import FilterListIcon from "@mui/icons-material/FilterList";
import { Paper } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { commons, transferModule } from "app/i18n/types";
import { arrayComparator } from "app/utils/array";
import {
  PickingDetailResponse,
  TransferOrderLineRefined,
} from "core/transfer/entities/TransferOrder";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DividerMenu } from "utils/generalUI";

interface PackingFiltersProps {
  lines: (PickingDetailResponse | TransferOrderLineRefined)[];
  chips: string[];
  setChips: (chips: string[]) => void;
  compact?: boolean;
}

const PackingFilters: FunctionComponent<PackingFiltersProps> = (props) => {
  const { lines, chips, setChips, compact } = props;

  const { t } = useTranslation();

  const [storages, setStorages] = useState<(string | undefined)[]>([]);
  const [groups, setGroups] = useState<string[]>([]);
  const [filters, setFilters] = useState<(string | undefined)[]>([]);
  const [isRefresh, setIsRefresh] = useState<boolean>(false);

  useEffect(() => {
    const storagesTmp: (string | undefined)[] = lines.map(
      (line) => line.storage
    );
    const storageDataSet = new Set(storagesTmp);
    const storagesArray = [...storageDataSet];

    const groupsTmp: string[] = lines.map((line) => line.itemGroup);
    const groupDataSet = new Set(groupsTmp);
    const groupsArray = [...groupDataSet];

    if (
      arrayComparator(storages, storagesArray) ||
      arrayComparator(groups, groupsArray)
    ) {
      setIsRefresh(true);
      setFilters([...storagesArray, ...groupsArray]);
    }

    setStorages(storagesArray);
    setGroups(groupsArray);
  }, [lines]);

  useEffect(() => {
    setChips(filters.map((el) => t(el ?? transferModule.UNSPECIFIED)));
  }, [filters]);

  useEffect(() => {
    if (chips.length !== filters.length && !isRefresh) {
      setFilters(
        filters.filter((el) => {
          return chips.includes(t(el ?? transferModule.UNSPECIFIED));
        })
      );
    }
    if (isRefresh) setIsRefresh(false);
  }, [chips]);

  const toggleGroupFilter = (item: string | undefined) => {
    let filtersTmp = [...filters];
    if (filters.includes(item)) {
      filtersTmp = filtersTmp.filter((filter) => filter !== item);
    } else {
      filtersTmp.push(item);
    }
    setFilters(filtersTmp);
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? new Date().getTime().toString() : undefined;

  return (
    <Paper sx={{ p: compact ? 0 : 2, height: "100%" }}>
      <Box sx={{ height: "100%" }}>
        <Box display="flex" gap={2} sx={{ height: "100%" }}>
          <Button
            size="medium"
            fullWidth
            variant="outlined"
            sx={{ bgcolor: "white", px: 8, height: "100%" }}
            onClick={handleClick}
          >
            <Box component={FilterListIcon} mr={2} /> {t(commons.FILTERS)}
            <Avatar
              sx={{
                width: 24,
                height: 24,
                fontSize: 12,
                bgcolor: "primary.main",
                ml: 1,
              }}
            >
              {filters.length}
            </Avatar>
          </Button>
        </Box>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box p={3} width={{ xs: "calc(100vw - 48px)", sm: 330 }}>
            <Typography variant="h5" gutterBottom>
              {t(commons.FILTERS)}
            </Typography>

            <DividerMenu />

            <Typography variant="h6" gutterBottom>
              {t(transferModule.STORAGE)}
            </Typography>

            <FormGroup>
              {storages.map((storage) => (
                <FormControlLabel
                  key={`storage-${storage}`}
                  control={
                    <Checkbox
                      checked={filters.includes(storage)}
                      onChange={() => toggleGroupFilter(storage)}
                    />
                  }
                  label={t(storage ?? transferModule.UNSPECIFIED)}
                />
              ))}
            </FormGroup>

            <DividerMenu />

            <Typography variant="h6" gutterBottom>
              {t(commons.GROUP)}
            </Typography>

            <FormGroup>
              {groups.map((group) => (
                <FormControlLabel
                  key={`group-${group}`}
                  control={
                    <Checkbox
                      checked={filters.includes(group)}
                      onChange={() => toggleGroupFilter(group)}
                    />
                  }
                  label={t(group)}
                />
              ))}
            </FormGroup>
          </Box>
        </Popover>
      </Box>
    </Paper>
  );
};

export default PackingFilters;
