import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import GreenSwitch from "app/components/GreenSwitch";
import { commons } from "app/i18n/types";
import { getCitiesCodenamesByNames, getCityName } from "app/utils/city.utils";
import { Kitchen } from "core/supplies/entities/Kitchen";
import { Supply } from "core/supplies/entities/Supply";
import KitchensAutocomplete from "./KitchensAutocomplete";

interface SuppliesManagementUpdateStateDialogProps {
  open: boolean;
  onClose: () => void;
  selectedSupplies: Array<Supply>;
  kitchens: Array<Kitchen> | null;
  onContinue: (selectedKitchens: Array<string>, activeStatus: boolean) => void;
  loading: boolean;
}

const SuppliesManagementUpdateStateDialog: FunctionComponent<
  SuppliesManagementUpdateStateDialogProps
> = (props) => {
  const { open, onClose, selectedSupplies, kitchens, onContinue, loading } =
    props;

  const { t } = useTranslation();

  const [selectedCities, setSelectedCities] = useState<Array<string>>([]);
  const [selectedKitchens, setSelectedKitchens] = useState<Array<Kitchen>>([]);

  const [activeStatusCheckbox, setActiveStatusCheckbox] =
    useState<boolean>(true);

  const handleActiveStatusCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setActiveStatusCheckbox(event.target.checked);
  };

  const citiesOptions = useMemo(() => {
    return [
      "Seleccionar todos",
      ...new Set(kitchens?.map((kitchen) => getCityName(kitchen.city))),
    ];
  }, [kitchens]);

  const kitchensOptions = useMemo(() => {
    const kitchenOpts =
      kitchens
        ?.filter((kitchen) =>
          getCitiesCodenamesByNames(selectedCities ?? [])?.includes(
            kitchen.city
          )
        )
        .sort((a, b) => (a.type === "PRODUCTION_CENTER" ? -1 : 1)) ?? [];

    const allKitchensOption: Kitchen = {
      kitchenId: "",
      name: "Seleccionar todos",
      country: "",
      city: "",
      address: "",
      locationCode: "",
      state: {
        completed: false,
        status: "loading",
        error: null,
      },
      type: "PRODUCTION_CENTER",
    };

    return [allKitchensOption, ...kitchenOpts];
  }, [kitchens, selectedCities]);

  useEffect(() => {
    if (selectedCities === null || selectedCities.length === 0) {
      setSelectedKitchens([]);
    }
  }, [selectedCities]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      {loading === true && <LinearProgress />}

      <DialogTitle>Gestionar insumos</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Selecciona las cocinas en las que deseas activar o desactivar los
          insumos seleccionados.
        </DialogContentText>

        <Box my={2} display="flex" gap={2}>
          <KitchensAutocomplete<string>
            options={citiesOptions}
            value={selectedCities}
            label="Ciudad"
            onChange={(value) => setSelectedCities(value)}
            disabled={loading}
          />

          <KitchensAutocomplete<Kitchen>
            options={kitchensOptions}
            value={selectedKitchens}
            label="Cocinas"
            onChange={(value) => setSelectedKitchens(value)}
            disabled={loading}
          />
        </Box>

        <Box display="flex" gap={1} alignItems="center">
          <Box bgcolor="#F1EFEF" p={1} borderRadius={1}>
            <Typography variant="body2">
              Estatus de inventario para{" "}
              <strong>
                {selectedSupplies.length}{" "}
                {selectedSupplies.length === 1 ? "Insumo" : "Insumos"}
              </strong>{" "}
              con el filtro seleccionado:
            </Typography>
          </Box>

          <Box
            display="flex"
            gap={1}
            alignItems="center"
            flexBasis={160}
            flexShrink={0}
          >
            <GreenSwitch
              checked={activeStatusCheckbox}
              onChange={handleActiveStatusCheckboxChange}
              inputProps={{ "aria-label": "controlled" }}
              disabled={selectedKitchens.length === 0 || loading}
            />

            <Typography fontWeight={700} variant="body2">
              {activeStatusCheckbox === true ? "Activar" : "Desactivar"}
            </Typography>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          color="error"
          onClick={onClose}
          disabled={loading}
          fullWidth
        >
          {t(commons.CANCEL)}
        </Button>

        <Button
          variant="contained"
          onClick={() =>
            onContinue(
              selectedKitchens.map((kitchen) => kitchen.kitchenId),
              activeStatusCheckbox
            )
          }
          disabled={loading}
          fullWidth
        >
          {t(commons.CONTINUE)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuppliesManagementUpdateStateDialog;
