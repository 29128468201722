/* eslint-disable react/require-default-props */
import React from "react";

interface IconProp {
  fontSize?: string;
}

const AuthorizationRecountIcon = (props: IconProp): React.ReactElement => {
  const { fontSize } = props;
  return (
    <svg
      width={fontSize ?? 40}
      height={fontSize ?? 40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width={fontSize ?? 40}
        height={fontSize ?? 40}
        rx="20"
        fill="#F1EFEF"
      />
      <mask
        id="mask0_5932_353"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="24"
        height="24"
      >
        <rect x="8" y="8" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5932_353)">
        <path
          d="M13.55 27.0008L10 23.4508L11.4 22.0508L13.525 24.1758L17.775 19.9258L19.175 21.3508L13.55 27.0008ZM13.55 19.0008L10 15.4508L11.4 14.0508L13.525 16.1758L17.775 11.9258L19.175 13.3508L13.55 19.0008ZM21 25.0008V23.0008H30V25.0008H21ZM21 17.0008V15.0008H30V17.0008H21Z"
          fill="#232323"
        />
      </g>
    </svg>
  );
};

export default AuthorizationRecountIcon;
