import { UseCase } from "core/common/interfaces/useCase";
import { canGetUsersByKitchenId } from "../interfaces/canGetUsersByKitchenId";

export class GetUsersByKitchenIdUseCase implements UseCase {
  constructor(private readonly repository: canGetUsersByKitchenId) {}

  public execute(kitchenId: string) {
    return this.repository.getUsersByKitchenId(kitchenId);
  }
}
