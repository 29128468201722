import {
  CalendarMonth,
  CancelOutlined,
  CheckCircleOutline,
  Close,
} from "@mui/icons-material";
import { LinearProgress } from "@mui/material";
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { commons, supplyReception } from "app/i18n/types";
import { reportNovelty } from "app/store/slices/productions/Supply/thunks";
import {
  MissingReason,
  ProductionSupplyDetail,
} from "core/productions/entities/Supply";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface NoveltySupplyReceptionModal {
  supplyDetail: ProductionSupplyDetail | null;
  open: boolean;
  onClose: () => void;
  onRefresh: () => void;
}

const NoveltySupplyReception: FunctionComponent<
  NoveltySupplyReceptionModal
> = ({ supplyDetail, open, onClose, onRefresh }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [selectedMissingReason, setSelectedMissingReason] =
    useState<MissingReason | null>(null);
  const [receivedQuantity, setReceivedQuantity] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  const handleReportNovelty = () => {
    if (!!selectedMissingReason && !!supplyDetail) {
      setLoading(true);
      dispatch(
        reportNovelty({
          supplyDetailId: supplyDetail?.id,
          receivedQuantity,
          missingReason: selectedMissingReason,
        })
      );
      setLoading(false);
      onRefresh();
    }
  };

  useEffect(() => {
    if (supplyDetail) {
      setReceivedQuantity(supplyDetail.receivedQuantity);
      const reason =
        MissingReason[supplyDetail.missingReason as keyof typeof MissingReason];
      setSelectedMissingReason(reason);
    }
  }, [supplyDetail]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Paper sx={{ p: 3, display: "flex", flexDirection: "column", gap: 2 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontWeight={600} color="primary">
              {t(supplyReception.NOVELTY_MODAL_TITLE)}
            </Typography>
            <Close onClick={onClose} />
          </Box>
          <Alert severity="info">{t(supplyReception.NOVELTY_MODAL_INFO)}</Alert>
          <Box display="flex" gap={2} alignItems="center">
            <CalendarMonth />
            <Box display="flex" flexDirection="column">
              <Typography color="text.secondary">
                {t(commons.SUPPLY)}
              </Typography>
              <Typography fontWeight={700}>{supplyDetail?.name}</Typography>
            </Box>
          </Box>
          <Box display="flex" gap={2} alignItems="center">
            <TextField
              label={t(supplyReception.RECEIVED_QUANTITY)}
              fullWidth
              value={receivedQuantity}
              onChange={(e) => setReceivedQuantity(Number(e.target.value))}
            />
            <Typography fontWeight={700} textTransform="uppercase">
              {t(`measureUnits.${supplyDetail?.measureUnit.toLowerCase()}`)}
            </Typography>
          </Box>
          <Autocomplete
            fullWidth
            value={selectedMissingReason}
            options={Object.values(MissingReason)}
            getOptionLabel={(option) => t(`missingReason.${option}`)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t(supplyReception.MISSING_REASON_LABEL)}
              />
            )}
            onChange={(_, newValue) => {
              setSelectedMissingReason(newValue);
            }}
          />
          {loading && <LinearProgress />}
          <Box display="flex" justifyContent="space-between" gap={2}>
            <Button
              variant="outlined"
              color="error"
              startIcon={<CancelOutlined />}
              fullWidth
              onClick={onClose}
            >
              {t(commons.CANCEL)}
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<CheckCircleOutline />}
              fullWidth
              onClick={handleReportNovelty}
              disabled={loading}
            >
              {t(commons.UPDATE)}
            </Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default NoveltySupplyReception;
