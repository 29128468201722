import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ToggleOnOutlinedIcon from "@mui/icons-material/ToggleOnOutlined";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { Supply } from "core/supplies/entities/Supply";
import { FunctionComponent, useCallback, useState } from "react";
import KitchenSelector from "../Kitchen/Selector";

interface ToolbarProps {
  suppliesOptions: Array<Supply> | null;
  onCountryChange: (value: string | null) => void;
  onSupplyChange: (value: Supply | null) => void;
  selectedSupplies: Array<Supply>;
  checkedSupplies: Array<string>;
  unselectSupplies: (skus: Array<string>) => void;
  loading: boolean;
  onUpdateStatus: () => void;
}

const Toolbar: FunctionComponent<ToolbarProps> = (props) => {
  const {
    suppliesOptions,
    onCountryChange,
    onSupplyChange,
    selectedSupplies,
    unselectSupplies,
    checkedSupplies,
    loading,
    onUpdateStatus,
  } = props;

  const [selectedSupply, setSelectedSupply] = useState<Supply | null>(null);
  const onSupplyChangeHandler = useCallback(
    (value: Supply | null) => {
      onSupplyChange(value);
      setSelectedSupply(value);
    },
    [onSupplyChange]
  );

  return (
    <Box
      display="flex"
      gap={1}
      justifyContent="space-between"
      alignItems="center"
      sx={{ mt: 1 }}
    >
      <Box display="flex" gap={1} flexDirection={{ xs: "column", md: "row" }}>
        <Paper elevation={0}>
          <IconButton
            aria-label="delete"
            onClick={() => unselectSupplies(checkedSupplies)}
            disabled={checkedSupplies.length === 0}
          >
            <DeleteOutlinedIcon />
          </IconButton>
        </Paper>

        <Paper elevation={0}>
          <IconButton
            aria-label="turn-on"
            disabled={checkedSupplies.length === 0}
            onClick={() => onUpdateStatus()}
          >
            <ToggleOnOutlinedIcon />
          </IconButton>
        </Paper>
      </Box>

      <Box
        display="flex"
        gap={2}
        flexDirection={{ xs: "column", md: "row" }}
        width={{ xs: "100%", md: "auto" }}
      >
        <Box sx={{ minWidth: { xs: "100%", md: 200 } }}>
          <KitchenSelector
            hideCitySelector
            hideLocationSelector
            size="small"
            onCountryChange={onCountryChange}
          />
        </Box>

        <Autocomplete
          options={suppliesOptions ?? []}
          getOptionLabel={(option) => `${option.sku} - ${option.name}`}
          getOptionDisabled={(option) =>
            selectedSupplies.some(
              (selectedSupply) => selectedSupply.sku === option.sku
            )
          }
          sx={{ minWidth: { xs: "100%", md: 400 } }}
          onChange={(_, value) => onSupplyChangeHandler(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={loading ? "Cargando..." : "Buscar insumo"}
              size="small"
              sx={{ bgcolor: "white" }}
            />
          )}
          value={
            suppliesOptions !== null && suppliesOptions.length > 0
              ? selectedSupply
              : null
          }
          disabled={
            suppliesOptions === null || suppliesOptions.length === 0 || loading
          }
          clearOnBlur
          disablePortal
        />
      </Box>
    </Box>
  );
};

export default Toolbar;
