/* eslint-disable react/no-array-index-key */
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { Button, DialogActions, Grid } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import { commons } from "app/i18n/types";
import { AttachmentFile } from "core/attachment/entity";
import { ProductRequest } from "core/dailyAdjustments/entities/Catalog";
import { OriginType } from "core/purchaseOrders/entities/PurchaseOrder";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMount } from "react-use";
import AttachmentContent from "../Attachment/AttachmentContent";

interface Props {
  onCloseDialog: () => void;
  productToSave: ProductRequest;
  setProductToSave: (productToSave: ProductRequest) => void;
  openDocument: (name: string) => void;
}

const AddImageDialogContent = (props: Props): React.ReactElement => {
  const { onCloseDialog, productToSave, setProductToSave, openDocument } =
    props;
  const { t } = useTranslation();

  const [attachments, setAttachments] = useState<AttachmentFile[]>([]);

  useMount(() => {
    if (productToSave.folder) {
      setAttachments(
        productToSave.folder.attachments.map((attachment) => {
          return {
            name: attachment.name,
            path: attachment.path,
          } as AttachmentFile;
        })
      );
    }
  });

  useEffect(() => {
    setProductToSave({
      ...productToSave,
      folder: {
        code: "",
        origin: OriginType.KIS,
        type: productToSave.typeLoss.toString(),
        invoiceNo: "",
        kitchenId: productToSave.kitchenId,
        attachments,
      },
    });
  }, [attachments]);

  return (
    <>
      <DialogContent>
        <AttachmentContent
          attachments={attachments}
          setAttachments={setAttachments}
          openDocument={openDocument}
        />
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              onClick={onCloseDialog}
              variant="outlined"
              color="error"
              startIcon={<HighlightOffOutlinedIcon />}
              fullWidth
            >
              {t(commons.CLOSE)}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

export default AddImageDialogContent;
