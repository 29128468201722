import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { commons } from "app/i18n/types";
import { getCountryName } from "core/common/entities/Country";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { menuPaperProps } from "utils/generalUI";

interface CountrySelectorDropdownProps {
  countries: Array<string> | null;
  onChange: (selectedCountry: string) => void;
}

const CountrySelectorDropdown: FunctionComponent<
  CountrySelectorDropdownProps
> = (props) => {
  const { countries, onChange } = props;

  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [tooltip, setTooltip] = React.useState<string>(
    t(commons.SELECT_COUNTRY)
  );
  const [selectedCountry, setSelectedCountry] = React.useState(
    <PublicOutlinedIcon sx={{ width: 56, height: 56, p: 0.75 }} />
  );
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const getCountryImage = (country: string, compact?: boolean) => {
    const size = compact ? 40 : 56;
    return (
      <Avatar
        sx={{ width: size, height: size, p: 0.75 }}
        src={`/assets/images/country/${country}.svg`}
      />
    );
  };

  const selectCountry = (country: string) => {
    setTooltip(getCountryName(country));
    setSelectedCountry(getCountryImage(country));
    onChange(country);
    handleCloseMenu();
  };

  return (
    <>
      <Box>
        <Tooltip title={tooltip}>
          <Paper sx={{ p: 2, cursor: "pointer" }} onClick={handleClick}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {t(commons.SELECT_COUNTRY)}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {selectedCountry}
            </Box>
          </Paper>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          PaperProps={{
            ...menuPaperProps,
            sx: {
              ...menuPaperProps.sx,
              maxHeight: "70%",
              overflowY: "auto",
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {countries?.map((country) => (
            <MenuItem
              key={`SelectCountry${country}`}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 2,
              }}
              onClick={() => selectCountry(country)}
            >
              <Tooltip title={getCountryName(country)}>
                <>
                  <ListItemIcon>{getCountryImage(country, true)}</ListItemIcon>
                  <ListItemText>{getCountryName(country)}</ListItemText>
                </>
              </Tooltip>
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </>
  );
};

export default CountrySelectorDropdown;
