import { CanGetSummaryAdjustments } from "core/adjustments/interfaces/adjustment/canGetSummaryAdjustments";
import { UseCase } from "core/common/interfaces/useCase";

export class GetSummaryAdjustmentsUseCase implements UseCase {
  constructor(private readonly repository: CanGetSummaryAdjustments) {}

  public execute() {
    return this.repository.getSummaryAdjustments();
  }
}
