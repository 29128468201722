import { UseCase } from "core/common/interfaces/useCase";
import {
  CanCompleteTransfersByKitchen,
  CanSetTransferOrderAsPacked,
} from "core/transfer/interfaces/transfer/canSetTransferOrderAsPacked";

export class SetTransferOrderAsPackedUseCase implements UseCase {
  constructor(private readonly repository: CanSetTransferOrderAsPacked) {}

  public execute(locationId: string) {
    return this.repository.setTransferOrderAsPacked(locationId);
  }
}
export class CompleteTransfersByKitchenUseCase implements UseCase {
  constructor(private readonly repository: CanCompleteTransfersByKitchen) {}

  public execute(transferId: number) {
    return this.repository.completeTransfersByKitchen(transferId);
  }
}
