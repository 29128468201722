import { Grid } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { commons } from "app/i18n/types";
import { getCountryName } from "core/common/entities/Country";
import { Kitchen } from "core/supplies/entities/Kitchen";
import { FunctionComponent, memo } from "react";
import { useTranslation } from "react-i18next";
import { allCitiesLabel, allKitchens } from "utils/general";

interface KitchenSelectorAutoCompletesProps {
  countriesOptions: string[];
  citiesOptions: string[];
  locationsOptions: Kitchen[];
  selectedCountry: string | null;
  selectedCity: string | null;
  selectedLocation: Kitchen | null;
  setSelectedCountry: (country: string | null) => void;
  setSelectedCity: (city: string | null) => void;
  setSelectedLocation: (location: Kitchen | null) => void;
  disabled?: boolean;
  size?: "small" | "medium";
  hideCountrySelector?: boolean;
  hideCitySelector?: boolean;
  hideLocationSelector?: boolean;
  allCityOrKitchens?: boolean;
}

const KitchenSelectorAutoCompletes: FunctionComponent<
  KitchenSelectorAutoCompletesProps
> = (props) => {
  const {
    countriesOptions,
    citiesOptions,
    locationsOptions,
    selectedCountry,
    selectedCity,
    selectedLocation,
    setSelectedCountry,
    setSelectedCity,
    setSelectedLocation,
    disabled,
    size,
    hideCountrySelector,
    hideCitySelector,
    hideLocationSelector,
    allCityOrKitchens,
  } = props;

  const { t } = useTranslation();

  const countriesOptionsTmp = [...countriesOptions];
  const citiesOptionsTmp = [...citiesOptions];
  const locationsOptionsTmp = [...locationsOptions];

  countriesOptionsTmp.sort((a, b) => a.localeCompare(b));
  citiesOptionsTmp.sort((a, b) => a.localeCompare(b));
  locationsOptionsTmp.sort((a, b) => a.name.localeCompare(b.name));

  if (allCityOrKitchens) {
    if (
      !!citiesOptionsTmp.length &&
      !citiesOptionsTmp.includes(allCitiesLabel)
    ) {
      citiesOptionsTmp.unshift(allCitiesLabel);
    }
    if (
      !!locationsOptionsTmp.length &&
      !locationsOptionsTmp.find((el) => el.kitchenId === allKitchens.kitchenId)
    ) {
      locationsOptionsTmp.unshift(allKitchens);
    }
  }

  const hideArray = [
    !!hideCountrySelector,
    !!hideCitySelector,
    !!hideLocationSelector,
  ];
  const hides = hideArray.filter((el) => !el).length;

  let smColumns = hides > 0 ? 12 / hides : 0;

  return (
    <Grid container spacing={2}>
      {!hideCountrySelector && (
        <Grid item sm={smColumns} xs={12}>
          <Autocomplete
            id={`location-selector-country-autocomplete`}
            getOptionLabel={(option) =>
              getCountryName(option).toLocaleUpperCase()
            }
            options={countriesOptionsTmp}
            value={selectedCountry}
            onChange={(_: any, newValue: string | null) =>
              setSelectedCountry(newValue ?? null)
            }
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label={t(commons.COUNTRY)} size={size} />
            )}
            disabled={disabled || countriesOptionsTmp.length === 0}
          />
        </Grid>
      )}
      {!hideCitySelector && (
        <Grid item sm={smColumns} xs={12}>
          <Autocomplete
            id={`location-selector-city-autocomplete`}
            options={citiesOptionsTmp}
            getOptionLabel={(option) => option.toLocaleUpperCase()}
            value={selectedCity}
            onChange={(_: any, newValue: string | null) =>
              setSelectedCity(newValue ?? null)
            }
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label={t(commons.CITY)} size={size} />
            )}
            disabled={disabled || citiesOptions.length === 0}
          />
        </Grid>
      )}
      {!hideLocationSelector && (
        <Grid item sm={smColumns} xs={12}>
          <Autocomplete
            id={`location-selector-location-autocomplete`}
            options={locationsOptionsTmp}
            value={selectedLocation}
            getOptionLabel={(option) => option.name.toLocaleUpperCase()}
            onChange={(_: any, newValue: Kitchen | null) =>
              setSelectedLocation(newValue ?? null)
            }
            sx={{ width: "100%" }}
            isOptionEqualToValue={(option, value) =>
              option.kitchenId === value.kitchenId
            }
            renderInput={(params) => (
              <TextField {...params} label={t(commons.LOCATION)} size={size} />
            )}
            disabled={disabled || locationsOptions.length === 0}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default memo(KitchenSelectorAutoCompletes);
