import appConfig from "config/app";
import { InventoryAdjustment } from "core/adjustments/entities/ManualAdjustment";
import {
  BulkLoadAdjustmentValidatorResponse,
  BulkLoadVendorSupplySaveResponse,
  BulkLoadVendorSupplyValidator,
  BulkLoadVendorSupplyValidatorResponse,
  DataAdjustmentValidator,
} from "core/bulkLoad/entities/BulkLoad";
import { VendorSupply } from "core/vendors/entities/VendorSupply";
import { ApiVersion, postHttp } from "utils/http";

export const bulkLoadVendorSupplyValidator = async (
  data: Partial<VendorSupply>[]
): Promise<BulkLoadVendorSupplyValidatorResponse> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `bulk-load/vendor/supply/validator`,
      { data }
    );
    const result: BulkLoadVendorSupplyValidatorResponse = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error bulkLoadVendorSupplyValidator | ${ex.message}`);
    return { ok: false, message: "Error" };
  }
};

export const bulkLoadVendorSupplySave = async (
  data: BulkLoadVendorSupplyValidator[]
): Promise<BulkLoadVendorSupplySaveResponse> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `bulk-load/vendor/supply/save`,
      { data }
    );
    const result: BulkLoadVendorSupplySaveResponse = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error bulkLoadVendorSupplySave | ${ex.message}`);
    return { ok: false, message: "Error" };
  }
};

export const adjustmentsValidator = async (
  data: Partial<InventoryAdjustment>[]
): Promise<BulkLoadAdjustmentValidatorResponse> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `bulk-load/adjustments/validator`,
      { data }
    );
    const result: BulkLoadAdjustmentValidatorResponse = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error adjustmentsValidator | ${ex.message}`);
    return { ok: false, message: "Error" };
  }
};

export const saveInventoryAdjustments = async (
  data: DataAdjustmentValidator[]
): Promise<BulkLoadVendorSupplySaveResponse> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `bulk-load/adjustments/save`,
      { data }
    );
    const result: BulkLoadVendorSupplySaveResponse = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error bulkLoadVendorSupplySave | ${ex.message}`);
    return { ok: false, message: "Error" };
  }
};
