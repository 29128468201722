import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import {
  clearSearchOnFullDialog,
  hideSearchOnFullDialog,
  showSearchOnFullDialog,
} from "app/store/slices/global";
import { FunctionComponent, useEffect } from "react";
import { useMount, useUnmount } from "react-use";
import { clearSpecialCharacters } from "utils/general";

interface Props {
  onSearch: (search: string) => void;
  clean?: number | string;
}

const SearchOnFullDialog: FunctionComponent<Props> = (props) => {
  const { onSearch, clean } = props;

  const dispatch = useAppDispatch();

  const searchTerm = useAppSelector(
    (state) => state.global.textSearchOnFullDialog
  );

  useEffect(() => {
    onSearch(clearSpecialCharacters(searchTerm.toLocaleLowerCase()));
  }, [searchTerm]);

  useEffect(() => {
    dispatch(clearSearchOnFullDialog());
  }, [clean]);

  useMount(() => {
    dispatch(showSearchOnFullDialog());
  });

  useUnmount(() => {
    dispatch(hideSearchOnFullDialog());
  });

  return <></>;
};

export default SearchOnFullDialog;
