import CardCarrousel from "app/components/common/CardCarrousel";
import { PackingKitchen } from "core/transfer/entities/TransferOrder";
import { FunctionComponent } from "react";
import PackingKitchenCard from "./PackingKitchenCard";

interface PackingSelectKitchenProps {
  packingKitchens: PackingKitchen[];
  selectedTransferOrderId: number;
  setSelectedTransferOrderId: (transferid: number) => void;
}

const PackingSelectKitchen: FunctionComponent<PackingSelectKitchenProps> = (
  props
) => {
  const {
    packingKitchens,
    selectedTransferOrderId,
    setSelectedTransferOrderId,
  } = props;

  return (
    <>
      {!!packingKitchens.length && (
        <CardCarrousel
          content={
            <>
              {packingKitchens.map((kitchen) => (
                <PackingKitchenCard
                  key={kitchen.kitchenId}
                  kitchen={kitchen}
                  selectedTransferOrderId={selectedTransferOrderId}
                  setSelectedTransferOrderId={setSelectedTransferOrderId}
                />
              ))}
            </>
          }
        />
      )}
    </>
  );
};

export default PackingSelectKitchen;
