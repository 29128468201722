import { Grid, LinearProgress, Typography } from "@mui/material";
import NumberField from "app/components/common/Field/Number";
import { ManagerDialogFooter } from "app/components/common/Manager/Dialog/Footer";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { commons, transferModule } from "app/i18n/types";
import { setTransferOrderAsSend } from "app/store/slices/transfer/thunks";
import { ResponsePayload } from "core/transfer/entities/TransferOrder";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BaseDialogProps } from "./constants";

export const HandleForm = (props: BaseDialogProps) => {
  const { transferId, onError, onSuccess, onClose } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [numberOfBaskets, setNumberOfBaskets] = useState<number>(0);

  const handleSubmit = () => {
    if (transferId) {
      setIsLoading(true);
      dispatch(
        setTransferOrderAsSend({
          transferId,
          numberOfBaskets,
        })
      )
        .then((resp) => {
          const response = resp.payload as ResponsePayload;
          if (response.ok) {
            onSuccess(transferId);
          } else {
            let message = "";
            if (response.errorCode != null) {
              message += `${t(response.errorCode)}`;
            } else {
              message += response.message;
            }
            if (response.details.length > 0) {
              message += "<br/>";
              message += "<br/>";
              response.details.forEach(
                (detail: string) => (message += "<li>" + detail + "</li>")
              );
            }
            onError(message);
          }
        })
        .catch((exception) => {
          onError(exception);
        })
        .finally(() => {
          setIsLoading(false);
          onClose();
        });
    }
  };

  return (
    <Grid container spacing={3}>
      {isLoading && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="body2">
          {t(transferModule.NUMBER_OF_BASKETS_QUESTION)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <NumberField
          label={t(transferModule.NUMBER_OF_BASKETS)}
          value={numberOfBaskets}
          onChangeValue={setNumberOfBaskets}
          disabled={isLoading}
          inputProps={{ maxLength: 3 }}
          instantUpdate
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        <ManagerDialogFooter
          onCancel={props.onClose}
          mainButton={{
            children: t(commons.CONFIRM),
            onClick: handleSubmit,
            disabled: isLoading || !numberOfBaskets,
          }}
          loading={isLoading}
        />
      </Grid>
    </Grid>
  );
};
