import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import {
  BulkLoadAction,
  BulkLoadActionType,
} from "core/bulkLoad/entities/BulkLoad";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  index: number;
  action: BulkLoadActionType;
  onChangeAction: (index: number, action: BulkLoadActionType) => void;
  disabled?: boolean;
}

const BulkLoadActionSelect = (props: Props) => {
  const { index, action, onChangeAction, disabled } = props;

  const { t } = useTranslation();

  const [value, setValue] = useState<string>(action);

  return (
    <Select
      value={value}
      disabled={disabled}
      fullWidth
      onChange={(event: SelectChangeEvent<string>) => {
        setValue(event.target.value);
        onChangeAction(index, event.target.value as BulkLoadActionType);
      }}
    >
      <MenuItem value={action}>{t(`bulkLoadAction.${action}`)}</MenuItem>
      <MenuItem value={BulkLoadAction.NOTHING}>
        {t(`bulkLoadAction.${BulkLoadAction.NOTHING}`)}
      </MenuItem>
    </Select>
  );
};

export default BulkLoadActionSelect;
