import { AppConfig } from "config/app";
import {
  ItemDailyAdjustmentsResponse,
  Product,
  ProductRequest,
} from "core/dailyAdjustments/entities/Catalog";
import { CanGetListProducts } from "core/dailyAdjustments/interfaces/product/canGetListProducts";
import { CanSetItemLosses } from "core/dailyAdjustments/interfaces/product/canSetItemLosses";
import { ApiVersion, getHttp, postHttp } from "utils/http";

export class ProductHTTPRepository
  implements CanGetListProducts, CanSetItemLosses
{
  constructor(private readonly config: AppConfig) {}

  public async getListProducts(kitchenId: string): Promise<Array<Product>> {
    try {
      const response = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `products/active/kitchen/${kitchenId}`
      );

      const responseJson: Array<Product> = await response.json();

      return responseJson;
    } catch (err) {
      console.error("getListProduct error", err);
      throw err;
    }
  }

  public async setItemLosses(
    product: ProductRequest
  ): Promise<ItemDailyAdjustmentsResponse> {
    try {
      const response = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `daily-adjustments`,
        product
      );
      const setItemLossText = await response.json();
      return { success: setItemLossText, sku: product.sku };
    } catch (err) {
      console.error("setItemLoss error", err);
      throw err;
    }
  }
}
