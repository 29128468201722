import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import SoupKitchenOutlinedIcon from "@mui/icons-material/SoupKitchenOutlined";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ProductionRecord } from "core/productions/entities/Productions";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  production: ProductionRecord;
}

const ProductionCardHeader: FunctionComponent<Props> = (props) => {
  const { production } = props;

  const { t } = useTranslation();

  return (
    <Box display="flex" justifyContent="space-between" color="white">
      <Typography fontWeight={700}> #{production.id}</Typography>
      <Box display="flex" gap={1}>
        {production.status === "SCHEDULED" ? (
          <CalendarMonthOutlinedIcon />
        ) : (
          <SoupKitchenOutlinedIcon />
        )}
        <Typography fontWeight={700}>
          {t(`productions.${production.status}`)}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProductionCardHeader;
