import {
  PriceRange,
  SupplyToValidate,
  ValidationItemResponse,
  VendorSupplyToValidate,
  getValidation,
} from "app/components/Vendor/Supply/PriceRange/utils";
import { VendorSupply } from "core/vendors/entities/VendorSupply";
import { getVendorSupplyByVendorId } from "core/vendors/repositories/http/vendorSupply";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  config: PriceRange;
  vendorId: number;
  country: string;
  city: string;
  supplyToValidate: SupplyToValidate[];
  onValidation?: (values: ValidationItemResponse[]) => void;
  vendorSupplies?: VendorSupply[];
  onVendorSupplies?: (values: VendorSupply[]) => void;
}

const VendorSupplyPriceRangeValidator = (props: Props) => {
  const {
    config,
    supplyToValidate,
    vendorId,
    country,
    city,
    onValidation,
    vendorSupplies: propVendorSupplies,
    onVendorSupplies,
  } = props;
  const { t } = useTranslation();

  const jsonValidate = JSON.stringify(supplyToValidate);

  const [vendorSupplies, setVendorSupplies] = useState<
    VendorSupplyToValidate[]
  >([]);

  const setSupplies = (supplies: VendorSupply[]) => {
    const list = supplies.map((el) => ({
      id: el.id,
      sku: el.supplySku,
      unit: el.vendorUnit,
      price: el.supplyUnitPrice,
    }));
    setVendorSupplies(list);
  };

  useEffect(() => {
    if (propVendorSupplies?.length) {
      setSupplies(propVendorSupplies);
      return;
    }

    getVendorSupplyByVendorId(vendorId).then((response) => {
      const result = response.filter((el) => el.city === city);
      if (result.length) {
        onVendorSupplies?.(result);
        setSupplies(result);
      }
    });
  }, [vendorId, city]);

  useEffect(() => {
    if (!vendorSupplies.length) return;
    if (!onValidation) return;

    const validation = getValidation(
      supplyToValidate,
      vendorSupplies,
      config,
      country,
      t
    );
    onValidation(validation);
  }, [vendorSupplies, jsonValidate]);

  return <></>;
};

export default VendorSupplyPriceRangeValidator;
