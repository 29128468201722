import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { OrderLine } from "./SingleTransfer";

interface SingleTransferTableRowProps {
  supply: OrderLine;
  removeSelectedSupply: (sku: string) => void;
  onSupplyChange: (supply: OrderLine) => void;
}

const SingleTransferTableRow = (props: SingleTransferTableRowProps) => {
  const { supply, removeSelectedSupply, onSupplyChange } = props;

  const { t } = useTranslation();

  const [quantity, setQuantity] = useState<string | undefined>(undefined);

  const setQuantityNumber = useCallback((quantity: string) => {
    if (quantity === "" || isNaN(Number(quantity))) {
      setQuantity(undefined);
      return;
    }
    // Convert string to number, removing leading zeros
    setQuantity(Number(quantity).toString());
    onSupplyChange({ ...supply, quantity: Number(quantity) });
  }, []);

  useEffect(() => {
    setQuantity(supply.quantity.toString());
  }, [supply.quantity]);

  return (
    <TableRow
      key={supply.sku}
      sx={{
        "&.selected > td": {
          borderBottomColor: "#ccdef1",
        },
      }}
    >
      <TableCell align="center">
        <Typography>{supply.sku}</Typography>
      </TableCell>

      <TableCell align="left">
        <Typography>{supply.name}</Typography>
      </TableCell>

      <TableCell align="left">
        <TextField
          label="Cantidad a trasladar"
          size="small"
          name="value"
          value={quantity}
          onChange={(e) => setQuantityNumber(e.target.value)}
          InputProps={{
            type: "number",
          }}
          autoComplete="off"
        />
      </TableCell>

      <TableCell align="center">
        {supply.unit !== "" && (
          <Typography>
            {t(`measureUnits.${supply.unit.toLocaleLowerCase()}`)}
          </Typography>
        )}
      </TableCell>

      <TableCell align="center" width={200}>
        <IconButton
          aria-label="delete"
          color="error"
          onClick={() => removeSelectedSupply(supply.sku)}
        >
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default SingleTransferTableRow;
