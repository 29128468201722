import { AppConfig } from "config/app";
import {
  KitchenStatus,
  KitchensResponse,
} from "core/supplies/entities/Kitchen";
import {
  CanGetActiveKitchens,
  CanGetKitchens,
} from "core/supplies/interfaces/kitchen/canGetListKitchens";
import { ApiVersion, getHttp } from "utils/http";

export class KitchenHTTPRepository
  implements CanGetKitchens, CanGetActiveKitchens
{
  constructor(private readonly config: AppConfig) {}

  public async getKitchensByStatus(
    status: KitchenStatus
  ): Promise<KitchensResponse> {
    try {
      const kitchensResponse = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `kitchens/` + status
      );
      const result: KitchensResponse = await kitchensResponse.json();
      return result;
    } catch (err) {
      console.error("getKitchensByStatus error", err);
      throw err;
    }
  }
  public async getAllKitchens(): Promise<KitchensResponse> {
    try {
      const kitchensResponse = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `kitchens`
      );
      const result: KitchensResponse = await kitchensResponse.json();
      return result;
    } catch (err) {
      console.error("getListKitchens error", err);
      throw err;
    }
  }
}
