import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { transferPackingDialog } from "app/i18n/types";
import { updateTransferOrderPackedLineQuantity } from "app/store/slices/transfer/thunks";
import { castStringToNumber } from "app/utils/input";
import { refinedKitchenName } from "app/utils/string.utils";
import {
  PickingDetailResponse,
  PickingLocationsResponse,
} from "core/transfer/entities/TransferOrder";
import { FunctionComponent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getTransferItemServiceLevel } from "utils/general";

interface Props {
  line: PickingLocationsResponse;
  selectedLine: PickingDetailResponse | null;
  isSelected?: boolean;
  handleClicK?: () => void;
  hideHeader?: boolean;
  setStatusLocation?: (
    id: number,
    status: "RELEASED" | "PACKED",
    packedQuantity: number
  ) => void;
}

const TransferPackingDialogRow: FunctionComponent<Props> = (props) => {
  const {
    line,
    selectedLine,
    isSelected,
    handleClicK,
    hideHeader,
    setStatusLocation,
  } = props;
  const defaultValue = line.status === "PACKED" ? line.packedQuantity : null;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.session.data?.userId);
  const [value, setValue] = useState<number | null>(defaultValue);

  const updateValue = useCallback(() => {
    if (
      selectedLine !== null &&
      value !== null &&
      value > -1 &&
      !isNaN(value)
    ) {
      dispatch(
        updateTransferOrderPackedLineQuantity({
          transferLineId: line.id,
          updateQuantityAndReason: {
            quantity: value ?? 0,
            transferOrderStep: "PACKING",
            sku: selectedLine.sku,
          },
        })
      );
      if (setStatusLocation) setStatusLocation(line.id, "PACKED", value ?? 0);
    }
  }, [dispatch, line.id, selectedLine, userId, value]);

  const inputColor = useMemo(() => {
    if (value !== null && value >= line.expectedQuantity) {
      return "success";
    }

    if (value !== null && value > 0 && value < line.expectedQuantity) {
      return "warning";
    }

    if (value === null || value === 0) {
      return "error";
    }

    return "primary";
  }, [line.expectedQuantity, value]);

  const serviceLevelResult = getTransferItemServiceLevel(
    value,
    line.expectedQuantity
  );

  return (
    <Grid
      container
      sx={{
        border: "1px solid #D7D3D3",
        borderRadius: 1,
        py: 1,
        px: 2,
        backgroundColor: isSelected ? "#3c81db1f" : undefined,
      }}
    >
      {!hideHeader && (
        <>
          <Grid item xs={11} onClick={handleClicK} sx={{ cursor: "pointer" }}>
            <Typography variant="h6" textTransform="capitalize">
              {refinedKitchenName(line.kitchenName)}
            </Typography>
          </Grid>
          <Grid
            item
            xs={1}
            sx={{ textAlign: "end", cursor: "pointer" }}
            onClick={handleClicK}
          >
            {isSelected ? (
              <CheckBoxOutlinedIcon color="success" />
            ) : (
              <CheckBoxOutlineBlankOutlinedIcon color="disabled" />
            )}
          </Grid>
        </>
      )}
      <Grid item xs={4}>
        <Typography>{t(transferPackingDialog.REQUESTED_QUANTITY)}</Typography>
        <Typography fontWeight={700}>
          {line.expectedQuantity} {line.measureUnit}.
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <TextField
          value={value !== null ? value.toString() : ""}
          onChange={(e) => setValue(castStringToNumber(e.target.value))}
          onBlur={updateValue}
          type="number"
          size="small"
          label={t(transferPackingDialog.QUANTITY_SENT)}
          color={inputColor}
          disabled={line.labels.length > 0}
          sx={{ px: 1 }}
          focused
          fullWidth
          autoComplete="off"
        />
      </Grid>
      <Grid item xs={4}>
        <Typography sx={{ textAlign: "end" }}>
          {t(transferPackingDialog.SERVICE_LEVEL)}
        </Typography>
        <Typography
          fontWeight={700}
          sx={{ textAlign: "end" }}
          color={serviceLevelResult.color}
        >
          {serviceLevelResult.serviceLevelPercentage}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TransferPackingDialogRow;
