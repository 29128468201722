import { PaginationParams } from "app/utils/pagination";
import { UseCase } from "core/common/interfaces/useCase";
import { CanGetMadeTransferOrdersByLocationId } from "core/transfer/interfaces/transfer/canGetMadeTransferOrdersByLocationId";

export class GetMadeTransferOrdersByLocationIdUseCase implements UseCase {
  constructor(
    private readonly repository: CanGetMadeTransferOrdersByLocationId
  ) {}

  public execute(locationId: string, params: PaginationParams) {
    return this.repository.getMadeTransferOrdersByLocationId(
      locationId,
      params
    );
  }
}
