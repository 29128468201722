import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import { IconButton } from "@mui/material";
import { BulkLoadLineSave } from "core/bulkLoad/entities/BulkLoad";

interface Props {
  saveCompleted: boolean;
  response: BulkLoadLineSave | undefined;
}

const BulkLoadResult = (props: Props) => {
  const { saveCompleted, response } = props;

  return (
    <>
      {saveCompleted && response && (
        <>
          {response.success && (
            <IconButton>
              <CheckCircleOutlineOutlinedIcon color="success" />
            </IconButton>
          )}
          {!response.success && (
            <IconButton>
              <HighlightOffOutlinedIcon color="error" />
            </IconButton>
          )}
        </>
      )}
      {!saveCompleted && (
        <IconButton>
          <PendingOutlinedIcon color="disabled" />
        </IconButton>
      )}
    </>
  );
};

export default BulkLoadResult;
