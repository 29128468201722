import { Box } from "@mui/material";
import { physicalCountSchedule } from "app/i18n/types";
import { FunctionComponent, memo } from "react";
import { useTranslation } from "react-i18next";
import { AlertDefault } from "utils/generalUI";

interface StepBodyInfoProps {
  index: number;
  isRecountOrDaily: boolean;
}

const StepBodyInfo: FunctionComponent<StepBodyInfoProps> = (props) => {
  const { index, isRecountOrDaily } = props;

  const { t } = useTranslation();
  let message = "";

  switch (index) {
    case 0:
      message = t(physicalCountSchedule.STEP_1_INFO);
      break;
    case 1:
      message = t(
        isRecountOrDaily
          ? physicalCountSchedule.STEP_IMPORT_INFO
          : physicalCountSchedule.STEP_2_INFO
      );
      break;
    case 2:
      message = t(physicalCountSchedule.STEP_3_INFO);
      break;
    case 3:
      message = t(physicalCountSchedule.STEP_4_INFO);
      break;
    default:
      message = t(physicalCountSchedule.STEP_FINAL_INFO);
      break;
  }
  return (
    <Box sx={{ my: 2 }}>
      <AlertDefault message={message} />
    </Box>
  );
};

export default memo(StepBodyInfo);
