import { Message } from "@mui/icons-material";
import Badge from "@mui/material/Badge";
import Fab from "@mui/material/Fab";
import { fabStyle } from "utils/generalUI";
import KeyFrameButtonPulse from "../common/KeyFrame/Button/Pulse";

interface Props {
  messagesCount: number;
  onOpenDrawer: () => void;
}

const CommentFloatButton = (props: Props) => {
  const { messagesCount, onOpenDrawer } = props;

  return (
    <KeyFrameButtonPulse colorHex="#808080" repeat={3} sx={fabStyle} isRound>
      <Badge
        color="primary"
        badgeContent={messagesCount}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Fab color="info" aria-label="messages" onClick={onOpenDrawer}>
          <Message />
        </Fab>
      </Badge>
    </KeyFrameButtonPulse>
  );
};

export default CommentFloatButton;
