import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Alert } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { transferModule, transferPackingDialog } from "app/i18n/types";
import { removeLabelLogReceipt } from "app/store/slices/purchase/lines.slice";
import { removeLabelLog } from "app/store/slices/transfer/thunks";
import { ProductionLabel } from "core/productions/entities/Productions";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import DialogTitle from "../Dialog/CustomDialogTitle";
import TransferPackingLabelItemBox from "../Productions/Label/Dialog/Purchase/Item";

interface TransferPackingDeleteLabelsProps {
  open: boolean;
  deleteLabels: ProductionLabel[];
  originId: string;
  onClose: () => void;
  isReceipt?: boolean;
}

const TransferPackingDeleteLabels: FunctionComponent<
  TransferPackingDeleteLabelsProps
> = (props) => {
  const { open, deleteLabels, originId, onClose, isReceipt } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleClick = async () => {
    const deleteLabelsIds = deleteLabels.map((el) => el.id);
    const data = { deleteLabelsIds, originId };
    await dispatch(
      isReceipt ? removeLabelLogReceipt(data) : removeLabelLog(data)
    );
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle
        title={transferModule.DELETE_LABELS}
        status={"succeeded"}
        onClose={onClose}
      />

      <DialogContent>
        <Grid container spacing={1}>
          <Grid xs={12} sm={12} item>
            <Alert severity="info">
              {t(transferPackingDialog.SUBTITLE_DELETE_LABELS)}
            </Alert>
          </Grid>
          {deleteLabels.map((label) => (
            <Grid
              xs={12}
              md={12}
              item
              key={`GridContainerTransferPackingDeleteDialogLabel${label.id}`}
            >
              <TransferPackingLabelItemBox
                key={`ContainerTransferPackingDeleteDialogLabel${label.id}`}
                label={label}
                showName
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          onClick={handleClick}
          startIcon={<ChevronRightOutlinedIcon />}
          disabled={!deleteLabels.length}
          fullWidth
        >
          {t(transferPackingDialog.CONTINUE)}
        </Button>
        <Button
          variant="outlined"
          onClick={onClose}
          color="error"
          startIcon={<CloseOutlinedIcon />}
          fullWidth
        >
          {t(transferPackingDialog.CANCEL)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransferPackingDeleteLabels;
