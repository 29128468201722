import Box from "@mui/material/Box";
import Typograhpy from "@mui/material/Typography";
import { ProductionRecord } from "core/productions/entities/Productions";
import { FunctionComponent, useState } from "react";
import ProductionTime from "../ProductionTime";

interface Props {
  production: ProductionRecord;
}

const ProductionTimer: FunctionComponent<Props> = (props) => {
  const { production } = props;
  const [preparationTime, setPreparationTime] = useState("00h 00m");

  return (
    <>
      <ProductionTime
        data={{
          startDate: production.startDate,
          finishedAt: production.finishedAt,
        }}
        preparationTime={preparationTime}
        setPreparationTime={setPreparationTime}
      />
      <Box
        bgcolor={
          [
            "SCHEDULED",
            "FINISHED",
            "APPROVED",
            "APPROVED_WITH_NOVELTY",
          ].includes(production.status)
            ? "text.disabled"
            : "warning.main"
        }
        width={88}
        height={64}
        borderRadius={2}
        display="flex"
        flexDirection={"column"}
        alignItems="center"
        justifyContent="center"
      >
        <Typograhpy color="white" fontWeight={700}>
          {preparationTime}
        </Typograhpy>
      </Box>
    </>
  );
};

export default ProductionTimer;
