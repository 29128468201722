import { bcIntegrations } from "app/i18n/types";
import { ConciliationError } from "core/BusinessCentralIntegration/entities/BusinessCentralIntegration";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import BcIntegrationTable from "./BcIntegrationTable";
import { getPurchaseErrorHeader } from "./utils";

interface Props {
  conciliations: ConciliationError[];
  loading: boolean;
}

const ConciliationErrors: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const { conciliations, loading } = props;

  return (
    <BcIntegrationTable
      data={conciliations}
      headers={getPurchaseErrorHeader(t)}
      loading={loading}
      title={t(bcIntegrations.CONCILIATION)}
    />
  );
};
export default ConciliationErrors;
