import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FunctionComponent } from "react";

interface ReportTransferOrdersSuccessDialogProps {
  open: boolean;
  onClose: () => void;
  onContinue: () => void;
}

const ReportTransferOrdersSuccessDialog: FunctionComponent<
  ReportTransferOrdersSuccessDialogProps
> = (props) => {
  const { open, onClose, onContinue } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title" color="primary.main">
        {"Traslado Programado"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" color="text.primary">
          El traslado a las cocinas seleccionadas se programó con éxito en el
          sistema de inventarios.
        </DialogContentText>

        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          my={2}
        >
          <CheckCircleOutlineOutlinedIcon
            sx={{ color: "success.light", width: 96, height: 96 }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onContinue}
          size="large"
          variant="contained"
          startIcon={<ChevronRightOutlinedIcon />}
          fullWidth
        >
          Continuar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportTransferOrdersSuccessDialog;
