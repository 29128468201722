import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import { Grid, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { useNavigator } from "app/hooks/useNavigator";
import { commons } from "app/i18n/types";
import { clearTitle, setTitle } from "app/store/slices/global";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useUnmount } from "react-use";
import { getCurrentWeekNumber } from "utils/general";
import { ObjectParams } from "utils/http";
import { InventoryModule, getNavs } from "utils/module";
import { TypeOfRole } from "utils/role";

export type PageHeaderCustomButton = ButtonProps & {
  show?: boolean;
};

interface Props {
  title: string;
  subtitle?: string;
  centerArea?: React.ReactElement;
  rightArea?: PageHeaderCustomButton[];
  isFullDialog?: boolean;
}

const PageHeader = (props: Props) => {
  const { title, subtitle, centerArea, rightArea, isFullDialog } = props;
  const dispatch = useAppDispatch();
  const navigator = useNavigator();
  const pathname = navigator.location();
  const params = navigator.params();
  const { t } = useTranslation();
  const selectedKitchen = useAppSelector(
    (state) => state.global.selectedKitchen
  );

  const user = useAppSelector((state) => state.session.user.data);
  const role = user?.role as TypeOfRole;
  const [navs, setNavs] = useState<InventoryModule[]>([]);

  React.useEffect(() => {
    const labelsTmp: string[] = [];

    if (selectedKitchen) {
      labelsTmp.push(selectedKitchen.name.toUpperCase());
    }

    if (title) labelsTmp.push(t(title));

    const weekPrefix = t(commons.WEEK).toUpperCase().slice(0, 1);
    const weekNumber = getCurrentWeekNumber();
    labelsTmp.push(`${weekPrefix}${weekNumber}`);

    dispatch(setTitle(labelsTmp.join(" - ")));
    if (!isFullDialog) {
      setNavs(getNavs([], role, pathname, params as ObjectParams));
    }
  }, [selectedKitchen, title]);

  useUnmount(() => {
    if (isFullDialog) return;
    dispatch(clearTitle());
  });

  const goTo = (link?: string) => {
    if (link) {
      navigator.toByLocationType(link);
    }
  };

  const rightAreaLength = rightArea?.filter((el) => el.show ?? true).length;

  return (
    <Grid container spacing={1} sx={{ mb: 2 }}>
      {!!navs.length && (
        <Grid
          item
          md={12}
          xs={12}
          sx={{ display: "flex", gap: 1, alignItems: "center" }}
        >
          {navs.map((nav, index) => (
            <>
              <Typography
                variant="body2"
                onClick={() => index + 1 !== navs.length && goTo(nav.link)}
                sx={{
                  cursor: index + 1 !== navs.length ? "pointer" : undefined,
                }}
              >
                {t(nav.moduleName)}
              </Typography>
              {index + 1 !== navs.length && <KeyboardArrowRightOutlinedIcon />}
            </>
          ))}
        </Grid>
      )}
      <Grid
        item
        md={centerArea || rightAreaLength ? 4 : 12}
        xs={12}
        sx={{ display: "flex", gap: 2 }}
      >
        <Tooltip title={t(subtitle ?? "")}>
          <Typography variant="h3">{t(title)}</Typography>
        </Tooltip>
      </Grid>
      {centerArea && (
        <Grid item md={rightAreaLength ? 4 : 8} xs={12}>
          {centerArea}
        </Grid>
      )}
      {!!rightAreaLength && (
        <Grid item md={centerArea ? 4 : 8} xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
              gap: 1,
            }}
          >
            {rightArea?.map((actionButton, index) => (
              <>
                {(actionButton.show ?? true) && (
                  <Button
                    key={`PageHeaderButton${index}`}
                    {...actionButton}
                    {...{ size: "medium" }}
                  />
                )}
              </>
            ))}
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default PageHeader;
