import { Box, Grid } from "@mui/material";
import { excelToJson } from "app/utils/excel";
import { MassiveData } from "core/physicalCount/entities/Counting";
import React, { FunctionComponent, memo } from "react";
import UploadArea from "../../../UploadArea/UploadArea";

interface importStepProps {
  massiveData: MassiveData;
  setMassiveData: (massiveData: MassiveData) => void;
}

const ImportStep: FunctionComponent<importStepProps> = (props) => {
  const { massiveData, setMassiveData } = props;

  const [isLoadingExcel, setIsLoadingExcel] = React.useState<boolean>(false);

  const onUpload = async (files: File[] | null) => {
    setIsLoadingExcel(true);
    if (!files) return;

    const fileRows = await Promise.all(
      files.map((file) =>
        excelToJson(file, {
          skipRows: 1,
        })
      )
    );

    const groupByLocation: MassiveData = {};

    fileRows
      .flat()
      .filter((row) => row[0] !== null && row[1] !== null)
      .forEach((row) => {
        const locationId = String(row[0]);
        const sku = String(row[1]);
        groupByLocation[locationId] ||= [];
        groupByLocation[locationId].push(sku);
      });

    setMassiveData(groupByLocation);

    setIsLoadingExcel(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} xs={12}>
        <Box display="flex" justifyContent="center" mt={8}>
          <UploadArea
            accept=".xlsx, .xls, .csv"
            onUpload={onUpload}
            loading={isLoadingExcel}
            disabled={!!Object.keys(massiveData).length}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default memo(ImportStep);
