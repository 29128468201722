import { CommentType } from "core/comment/entity";
import { useState } from "react";
import CommentDrawer from "./Drawer";
import CommentFloatButton from "./FloatButton";

interface Props {
  sourceId: string;
  type: CommentType;
  kitchenId: string;
}

const CommentDrawerWithFloatButton = (props: Props) => {
  const { sourceId, type, kitchenId } = props;

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [messagesCount, setMessagesCount] = useState<number>(0);

  return (
    <>
      <CommentDrawer
        kitchenId={kitchenId}
        sourceId={sourceId}
        type={type}
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        onChangeMessagesCount={setMessagesCount}
      />

      <CommentFloatButton
        messagesCount={messagesCount}
        onOpenDrawer={() => setOpenDrawer(true)}
      />
    </>
  );
};

export default CommentDrawerWithFloatButton;
