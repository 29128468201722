/* eslint-disable react/no-array-index-key */
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import { Button, DialogActions, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import { commons, takePhotoLocale } from "app/i18n/types";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import Camera, { CameraUtils } from "../Camera";

interface TakePhotoDialogContentProps {
  onContinue: (img: string) => void;
  onCloseDialog: () => void;
  setFullScreen: (fullScreen: boolean) => void;
}

const TakePhotoDialogContent = (
  props: TakePhotoDialogContentProps
): React.ReactElement => {
  const { onContinue: onContinueParam, onCloseDialog, setFullScreen } = props;
  const { t } = useTranslation();

  const [imgSrc, setImgSrc] = React.useState<string>("");

  const cameraContainer = React.useRef<HTMLDivElement>(null);

  const onSuccess = (img: string) => {
    setImgSrc(img);
    setFullScreen(false);
  };

  const onContinue = async () => {
    onContinueParam(imgSrc);
    onCloseDialog();
  };

  const [widthWebCam, setWidthWebCam] = React.useState(0);
  const [heightWebCam, setHeightWebCam] = React.useState(0);

  React.useEffect(() => {
    if (!imgSrc && cameraContainer.current) {
      setWidthWebCam(cameraContainer.current.clientWidth);
      setHeightWebCam(cameraContainer.current.clientHeight);
    }
  }, [imgSrc, cameraContainer]);

  const onRetry = () => {
    setImgSrc("");
    setFullScreen(true);
  };

  const cameraRef = useRef<CameraUtils>(null);

  const handleClickTakePhoto = () => {
    cameraRef.current?.takePhoto();
  };

  return (
    <>
      <DialogContent sx={{ background: "#F1EFEF" }}>
        {!imgSrc && (
          <Box
            ref={cameraContainer}
            sx={{ width: "calc(100vw - 60px)", height: "calc(100vh - 180px)" }}
          >
            {!!widthWebCam && !!heightWebCam && (
              <Camera
                width={widthWebCam}
                height={heightWebCam}
                ref={cameraRef}
                onTakePhoto={onSuccess}
                image={imgSrc}
              />
            )}
          </Box>
        )}

        <Grid container spacing={2}>
          {imgSrc && (
            <Grid item xs={12}>
              <Box
                component="div"
                sx={{
                  backgroundImage: `url(${imgSrc})`,
                  width: "100%",
                  height: "calc(100vh - 300px)",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          {imgSrc && (
            <>
              <Grid item xs={6}>
                <Button
                  onClick={onRetry}
                  variant="outlined"
                  startIcon={<CachedOutlinedIcon />}
                  fullWidth
                >
                  {t(takePhotoLocale.RETRY)}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  onClick={onContinue}
                  variant="contained"
                  startIcon={<CheckCircleOutlinedIcon />}
                  fullWidth
                  disabled={!imgSrc}
                >
                  {t(takePhotoLocale.CONTINUE)}
                </Button>
              </Grid>
            </>
          )}
          {!imgSrc && (
            <>
              <Grid item xs={12} md={6}>
                <Button
                  variant="contained"
                  onClick={handleClickTakePhoto}
                  startIcon={<PhotoCameraOutlinedIcon />}
                  size="large"
                  fullWidth
                >
                  {t(takePhotoLocale.TAKE_PHOTO)}
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  variant="outlined"
                  onClick={onCloseDialog}
                  startIcon={<HighlightOffOutlinedIcon />}
                  size="large"
                  color="error"
                  fullWidth
                >
                  {t(commons.CANCEL)}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </DialogActions>
    </>
  );
};

TakePhotoDialogContent.defaultProps = {
  onPhotoLoaded() {},
};

export default TakePhotoDialogContent;
