import { UseCase } from "core/common/interfaces/useCase";
import { CanGetTransferOrders } from "core/transfer/interfaces/transfer/CanGetTransferOrders";

export class CanGetTransferOrderUseCase implements UseCase {
  constructor(private readonly repository: CanGetTransferOrders) {}

  public execute(transferId: string) {
    return this.repository.getTransferOrder(transferId);
  }
}
