import CommonDialog from "app/components/common/CommonDialog";
import { commons } from "app/i18n/types";
import { SkuAndTypeAndCityValidatorPayload } from "core/vendors/entities/VendorSupply";
import { skuTypeCityValidator } from "core/vendors/repositories/http/vendorSupply";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMount } from "react-use";

interface Props extends SkuAndTypeAndCityValidatorPayload {
  onValidator: (value: boolean) => void;
  onClose: () => void;
}

const VendorSupplyManagerValidatorModal = (props: Props) => {
  const { sku, type, city, excludeId, onValidator, onClose } = props;

  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  useMount(() => {
    skuTypeCityValidator({ sku, type, city, excludeId }).then((response) => {
      const { ok, message: msg } = response;
      setOpen(!ok);
      ok && onValidator(ok);
      setMessage(t(`common.${msg}`));
    });
  });

  return (
    <CommonDialog
      open={open}
      title={t(commons.CONFIRM)}
      message={message}
      handleClose={onClose}
      handleConfirm={() => {
        onValidator(true);
        onClose();
      }}
      showCancelButton
    />
  );
};

export default VendorSupplyManagerValidatorModal;
