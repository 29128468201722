import {
  CalendarMonthOutlined,
  DomainOutlined,
  StorefrontOutlined,
} from "@mui/icons-material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { purchaseOrderCreation } from "app/i18n/types";
import { PurchaseOrder } from "core/purchaseOrders/entities/PurchaseOrder";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface PurchaseOrderInfoHeaderProps {
  purchaseOrder: PurchaseOrder;
}

const PurchaseOrderInfoHeader: FunctionComponent<
  PurchaseOrderInfoHeaderProps
> = ({ purchaseOrder }) => {
  const { t } = useTranslation();
  return (
    <>
      <Box display="flex" alignItems="center" gap={1}>
        <DomainOutlined />
        <Typography variant="h6">{purchaseOrder.vendorName}</Typography>
      </Box>
      <Box display="flex" gap={1} alignItems="center">
        <StorefrontOutlined color="success" />
        <Typography
          variant="body2"
          fontWeight={600}
          color="success.main"
          mr={2}
        >
          {purchaseOrder.locationCode}
        </Typography>
        <CalendarMonthOutlined sx={{ color: "text.secondary" }} />
        <Typography
          variant="body2"
          fontWeight={600}
          color="text.secondary"
        >{`${t(purchaseOrderCreation.ESTIMATED_DELIVERY_DATE_LABEL)} ${
          purchaseOrder.expectedReceiptAt
        }`}</Typography>
      </Box>
    </>
  );
};

export default PurchaseOrderInfoHeader;
