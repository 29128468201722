import {
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSRowData,
} from "@foodology-co/alejandria";
import PageHeader from "app/components/PageHeader";
import { commons } from "app/i18n/types";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { PageBox } from "utils/generalUI";
import { AllCustomRoute, allCustomRoutes } from "utils/route";

interface Props {}

const CurrentRoutes: FunctionComponent<Props> = () => {
  const { t } = useTranslation();

  const list: AllCustomRoute[] = [];
  allCustomRoutes(list);

  const getHeader = (): KOSBaseTableHeader[] => [
    {
      label: "ROUTE",
      field: "path",
    },
    {
      label: "MODULE",
      text: (item: KOSRowData) => {
        const titles = item.titles as string[];
        return titles.map((el) => t(el)).join(" > ");
      },
    },
    {
      label: "ROLES",
      component: (item: KOSRowData) => {
        const roles = item.roles as string[];
        return (
          <ul style={{ margin: 0, paddingLeft: "inherit" }}>
            {roles.map((role) => (
              <li key={`${item.path}-${role}`}>{role}</li>
            ))}
          </ul>
        );
      },
    },
  ];

  return (
    <PageBox>
      <PageHeader title={commons.ROUTES} />

      <KOSBaseTable
        columns={getHeader()}
        rows={{
          data: list,
          total: list.length,
          showNumber: true,
        }}
      />
    </PageBox>
  );
};

export default CurrentRoutes;
