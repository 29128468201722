import { UseCase } from "core/common/interfaces/useCase";
import { ProductRequest } from "core/dailyAdjustments/entities/Catalog";
import { CanSetItemLosses } from "core/dailyAdjustments/interfaces/product/canSetItemLosses";

export class SetItemLossesUseCase implements UseCase {
  constructor(private readonly repository: CanSetItemLosses) {}

  public execute(product: ProductRequest) {
    return this.repository.setItemLosses(product);
  }
}
