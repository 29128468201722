/* eslint-disable react/require-default-props */
import React from "react";

interface IconProp {
  fontSize?: string;
}

const TheoreticalInventorycomparisonIcon = (
  props: IconProp
): React.ReactElement => {
  const { fontSize } = props;
  return (
    <svg
      width={fontSize ?? 40}
      height={fontSize ?? 40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width={fontSize ?? 40}
        height={fontSize ?? 40}
        rx="20"
        fill="#F1EFEF"
      />
      <mask
        id="mask0_5932_71"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="24"
        height="24"
      >
        <rect x="8" y="8" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5932_71)">
        <path
          d="M20.5 19H22.5V17H24.5V15H22.5V13H20.5V15H18.5V17H20.5V19ZM18.5 23H24.5V21H18.5V23ZM16 27C15.45 27 14.9792 26.8042 14.5875 26.4125C14.1958 26.0208 14 25.55 14 25V11C14 10.45 14.1958 9.97917 14.5875 9.5875C14.9792 9.19583 15.45 9 16 9H23L29 15V25C29 25.55 28.8042 26.0208 28.4125 26.4125C28.0208 26.8042 27.55 27 27 27H16ZM16 25H27V16L22 11H16V25ZM12 31C11.45 31 10.9792 30.8042 10.5875 30.4125C10.1958 30.0208 10 29.55 10 29V15H12V29H23V31H12Z"
          fill="#232323"
        />
      </g>
    </svg>
  );
};

export default TheoreticalInventorycomparisonIcon;
