import { commons } from "app/i18n/types";
import { number, object, string } from "yup";

export const handleValidations = object({
  supplySku: string().required(commons.REQUIRED_FIELD),
  vendorType: string().required(commons.REQUIRED_FIELD),
  vendorUnit: string().required(commons.REQUIRED_FIELD),
  vendorSupplyName: string().required(commons.REQUIRED_FIELD),
  city: string().required(commons.REQUIRED_FIELD),
  supplyQuantity: number()
    .min(1)
    .typeError(commons.QUANTITY_ERROR)
    .required(commons.REQUIRED_FIELD),
  vendorUnitPrice: number()
    .min(0.000000001)
    .typeError(commons.QUANTITY_ERROR)
    .required(commons.REQUIRED_FIELD),
  supplyUnitPrice: number()
    .min(0.000000001)
    .typeError(commons.QUANTITY_ERROR)
    .required(commons.REQUIRED_FIELD),
  vendorOrdersPerWeek: number()
    .min(0.1)
    .typeError(commons.QUANTITY_ERROR)
    .required(commons.REQUIRED_FIELD),
  vendorMinQuantityToOrder: number()
    .min(0)
    .typeError(commons.QUANTITY_ERROR)
    .required(commons.REQUIRED_FIELD),
  leadTime: number()
    .min(1)
    .typeError(commons.QUANTITY_ERROR)
    .required(commons.REQUIRED_FIELD),
});
