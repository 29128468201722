import { CloseOutlined, ScienceOutlined } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import {
  commons,
  productionLiberation,
  productionSchedule,
} from "app/i18n/types";
import { ProductionRecord } from "core/productions/entities/Productions";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import ProductionInfoDialogContent from "../InfoDialog/Content";
import ProductionRealPerformance from "../InfoDialog/RealPerformance";
import ProductionTime from "../ProductionTime";

interface Props {
  production: ProductionRecord;
  onClose: () => void;
  onEvaluate: () => void;
}

const ProductionLiberationApprovementModal: FunctionComponent<Props> = (
  props: Props
) => {
  const { production, onClose, onEvaluate } = props;
  const { t } = useTranslation();
  const [preparationTime, setPreparationTime] = useState("");

  return (
    <Dialog open onClose={onClose}>
      <Box display="flex" width="100%" justifyContent="space-between">
        <DialogTitle color="primary.main">
          {t(productionSchedule.TITLE_FINISHED)}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseOutlined />
        </IconButton>
      </Box>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={1}>
          <ProductionTime
            data={{
              startDate: production.startDate,
              finishedAt: production.finishedAt,
            }}
            preparationTime={preparationTime}
            setPreparationTime={setPreparationTime}
          />
          <ProductionRealPerformance
            scheduledQuantity={production.quantityToProduce}
            producedQuantity={production.producedQuantity}
          />
          <ProductionInfoDialogContent
            data={{
              name: production.name,
              quantity: production.quantityToProduce,
              measureUnit: production.unit,
              producedQuantity: production.producedQuantity,
              startDate: production.startDate?.toString(),
              finishedAt: production.finishedAt?.toString(),
              assignedUserName: production.productionManagerName,
              productionStatus: production.status,
            }}
            preparationTime={preparationTime}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 2 }}>
        <Button onClick={onClose} variant="outlined" color="error" fullWidth>
          {t(commons.CANCEL)}
        </Button>
        <Button
          onClick={onEvaluate}
          variant="contained"
          startIcon={<ScienceOutlined />}
          color="primary"
          fullWidth
        >
          {t(productionLiberation.EVALUATE_BUTTON)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductionLiberationApprovementModal;
