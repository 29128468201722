import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CircularProgress from "@mui/material/CircularProgress";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { commons, transferPackingDialog } from "app/i18n/types";
import { PurchaseOrderLine } from "core/purchaseOrders/entities/PurchaseOrder";
import { updatePurchaseOrderLineQuantity } from "core/purchaseOrders/repositories/http/purchase";
import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import NumberField from "../common/Field/Number";

interface Props {
  item: PurchaseOrderLine;
  isCreate?: boolean;
  autoSave?: boolean;
  setQuantity?: (id: number, quantity: number) => void;
}

const UpdateQuantityTextField: FunctionComponent<Props> = (props) => {
  const { item, isCreate, autoSave, setQuantity: setQuantityProp } = props;
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const linesStatus = useAppSelector(
    (state) => state.linesPurchase.linesStatus
  );

  const [quantity, setQuantity] = useState<string | undefined>(undefined);

  const getInputAddornement = useCallback((): ReactNode => {
    if (linesStatus === null || linesStatus[item.id] === undefined) {
      return null;
    }

    if (linesStatus[item.id].status === "loading") {
      return <CircularProgress size={24} />;
    }

    if (linesStatus[item.id].status === "failed") {
      return <ErrorOutlineIcon color="error" />;
    }

    if (linesStatus[item.id].status === "succeeded") {
      return <CheckCircleOutlineIcon color="success" />;
    }

    return undefined;
  }, [item.id, linesStatus]);

  const save = (lineId: number, quantityParam: number) => {
    if (setQuantityProp) {
      setQuantityProp(lineId, quantityParam);
    }
  };

  const updateItemQuantity = useCallback(
    (quantity: number, item: PurchaseOrderLine) => {
      if (quantity < 0 || item.quantity === quantity) {
        return;
      }

      if (autoSave) {
        const updatePromise = updatePurchaseOrderLineQuantity(
          item.id,
          quantity
        );
        updatePromise.then((response) => {
          if (response.ok) {
            save(item.id, quantity);
          }
        });
      } else {
        save(item.id, quantity);
      }
    },
    [dispatch]
  );

  const setQuantityNumber = useCallback((quantity: string) => {
    if (quantity === "" || isNaN(Number(quantity))) {
      setQuantity(undefined);
      return;
    }
    setQuantity(Number(quantity).toString());
  }, []);

  useEffect(() => {
    setQuantity(item.quantity.toString());
  }, [item.quantity]);

  return (
    <NumberField
      value={item.quantity}
      onChangeValue={(newValue) => updateItemQuantity(newValue, item)}
      size="small"
      label={t(
        isCreate ? commons.QUANTITY : transferPackingDialog.REQUESTED_QUANTITY
      )}
      endAdornment={getInputAddornement()}
    />
  );
};

export default UpdateQuantityTextField;
