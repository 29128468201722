import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { commons } from "app/i18n/types";
import { Supply } from "core/supplies/entities/Supply";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface MassiveSupplyTableProps {
  supplies: Supply[];
}

const MassiveSupplyTable: FunctionComponent<MassiveSupplyTableProps> = (
  props
) => {
  const { supplies } = props;

  const { t } = useTranslation();

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 650 }}
        aria-label="supply-table"
        size="small"
        stickyHeader
      >
        <TableHead>
          <TableRow
            sx={{
              "& > th": {
                bgcolor: "#F9FAFC",
                borderBottom: "2px solid #E3E5E8",
              },
            }}
          >
            <TableCell>{t(commons.KITCHEN)}</TableCell>
            <TableCell align="center">{t(commons.SKU)}</TableCell>
            <TableCell align="left">{t(commons.NAME)}</TableCell>
            <TableCell align="left">{t(commons.GROUP)}</TableCell>
            <TableCell align="center">{t(commons.UNIT)}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {supplies.map((supply) => (
            <TableRow key={supply.sku}>
              <TableCell>
                <Typography>{supply.location}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>{supply.sku}</Typography>
              </TableCell>

              <TableCell align="left">
                <Typography>{supply.name}</Typography>
              </TableCell>

              <TableCell align="left">
                <Typography textTransform="capitalize">
                  {supply.group.toLowerCase()}
                </Typography>
              </TableCell>

              <TableCell align="center">
                {supply.unit !== "" && (
                  <Typography>
                    {t(`measureUnits.${supply.unit.toLocaleLowerCase()}`)}
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MassiveSupplyTable;
