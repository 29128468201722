import { StoreRequestError, StoreRequestStatus } from "app/store/types";
import { RawCounting } from "core/physicalCount/entities/Counting";

export interface ConsolidatedExportState {
  countings: Array<RawCounting> | null;

  searchCountingStatus: StoreRequestStatus;
  exportCountingStatus: StoreRequestStatus;
  error: StoreRequestError;
  selectedCountings: Array<RawCounting>;
}

export const initialState: ConsolidatedExportState = {
  countings: null,
  searchCountingStatus: "idle",
  exportCountingStatus: "idle",
  error: null,
  selectedCountings: [],
};
