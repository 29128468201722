import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useAlert } from "app/hooks/useAlert";
import { commons, productionScannedError } from "app/i18n/types";
import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMount, useUnmount } from "react-use";

export interface OnActionResponse {
  success: boolean;
  message?: string | null;
}

interface BarCodeScannerProps {
  onAction: (codeScan: string) => Promise<OnActionResponse>;
}

const BarCodeScanner: FunctionComponent<BarCodeScannerProps> = (props) => {
  const { onAction } = props;

  const { t } = useTranslation();
  const alert = useAlert();

  const [codeScan, setCodeScan] = useState("");
  const [loading, setLoading] = useState(false);

  const prefixes = ["PROD", "prod"];
  const regExp = `(${prefixes.join("|")})('|-)([0-9]){1,50}`;

  const getCode = (text: string) => {
    const partsOfText = text
      .replace(/Enter/g, "")
      .replace(/Shift/g, "")
      .match(new RegExp(regExp, "g"));
    if (partsOfText?.length) {
      const rawCode = partsOfText[0];
      const result = rawCode.split(new RegExp("'|-"));
      if (result.length) {
        return result[1];
      }
    }
    return "";
  };

  let barCode = "";
  const keyDownFunction = (event: any) => {
    const keyValue = event.key;
    const codeValue = event.code;
    barCode += keyValue;
    if (codeValue === "Enter") {
      const code = getCode(barCode);
      setCodeScan(code);
      barCode = "";
    }
  };

  let initializeKeyDownEvent = false;
  useMount(() => {
    if (!initializeKeyDownEvent) {
      initializeKeyDownEvent = true;
      document.addEventListener("keydown", keyDownFunction, true);
    }
  });

  useUnmount(() => {
    if (initializeKeyDownEvent) {
      initializeKeyDownEvent = false;
      document.removeEventListener("keydown", keyDownFunction, true);
    }
  });

  const onActionScan = async () => {
    if (codeScan !== "") {
      setLoading(true);

      const { success, message: msg } = await onAction(codeScan);
      const message = t(msg ?? productionScannedError.INTERNAL_ERROR);
      const audioOn = true;
      if (success) {
        alert.successWithMsg({
          title: t(commons.SCANNED_SUCCESSFULLY),
          message,
          audioOn,
        });
      } else {
        alert.successWithMsg({
          title: t(commons.SCANNING_ERROR),
          message,
          audioOn,
        });
      }

      setLoading(false);
      setCodeScan("");
    }
  };

  React.useEffect(() => {
    onActionScan();
  }, [codeScan]);

  return (
    <Dialog open={loading} title={t(commons.READING_BARCODE_SCANNER)}>
      <DialogTitle>{t(commons.READING_BARCODE_SCANNER)}</DialogTitle>
      <DialogContent>
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default BarCodeScanner;
