import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { commons } from "app/i18n/types";
import { onInputKeyDown } from "app/utils/input";
import { useTranslation } from "react-i18next";
import { OrderLine } from "./SingleTransfer";
import SingleTransferTableRow from "./SingleTransferTableRow";

interface SingleTransferTableProps {
  supplies: OrderLine[];
  loading: boolean;
  unselectSupplies: (skus: Array<string>) => void;
  onSupplyChange: (supply: OrderLine) => void;
}

const SingleTransferTable = (props: SingleTransferTableProps) => {
  const { supplies, loading, unselectSupplies, onSupplyChange } = props;

  const { t } = useTranslation();

  return (
    <TableContainer component={Paper}>
      {loading && <LinearProgress />}

      <Table
        sx={{ minWidth: 650 }}
        aria-label="counting-history"
        size="small"
        stickyHeader
      >
        <TableHead>
          <TableRow
            sx={{
              "& > th": {
                bgcolor: "#F9FAFC",
                borderBottom: "2px solid #E3E5E8",
              },
            }}
          >
            <TableCell align="center">{t(commons.SKU)}</TableCell>
            <TableCell align="left">{t(commons.NAME)}</TableCell>
            <TableCell align="left">{t(commons.QUANTITY)}</TableCell>
            <TableCell align="center">{t(commons.UNIT)}</TableCell>
            <TableCell align="center" />
          </TableRow>
        </TableHead>

        <TableBody onKeyDown={(e) => onInputKeyDown(e, 2)}>
          {supplies.map((supply) => (
            <SingleTransferTableRow
              key={supply.sku}
              supply={supply}
              removeSelectedSupply={(sku) => unselectSupplies([sku])}
              onSupplyChange={onSupplyChange}
            />
          ))}

          {supplies.length === 0 && (
            <TableRow>
              <TableCell colSpan={7}>
                <Typography textAlign="center" color="text.secondary">
                  Seleccione los insumos a trasladar
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SingleTransferTable;
