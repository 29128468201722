import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { commons, purchaseOrderCreation } from "app/i18n/types";
import { Vendor } from "core/vendors/entities/Vendor";
import { getVendorsByCountry } from "core/vendors/repositories/http/vendor";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { clearSpecialCharacters } from "utils/general";

interface VendorSelectorProps {
  country: string;
  selectedVendor: Vendor | undefined;
  setSelectedVender: (selectedVendor?: Vendor) => void;
  isShortTitle?: boolean;
  disabled?: boolean;
}

const VendorSelector: FunctionComponent<VendorSelectorProps> = (props) => {
  const { selectedVendor, setSelectedVender, country, isShortTitle, disabled } =
    props;

  const { t } = useTranslation();
  const [fdlgyCountry, setFdlgyCountry] = useState<string>("");
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [reset, setReset] = useState<number>(new Date().getTime());

  useEffect(() => {
    if (country) {
      setFdlgyCountry(country);
    }
  }, [country]);

  useEffect(() => {
    if (fdlgyCountry) {
      getVendorsByCountry(fdlgyCountry).then((vendors) => {
        setVendors(vendors);
      });
    }
  }, [fdlgyCountry]);

  useEffect(() => {
    if (!selectedVendor) {
      setReset(new Date().getTime());
    }
  }, [selectedVendor]);

  return (
    <Autocomplete
      key={reset}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t(
            isShortTitle
              ? commons.SUPPLIER
              : purchaseOrderCreation.CREATE_ORDER_MODAL_VENDOR_LABEL
          )}
          sx={{ bgcolor: "white" }}
          autoComplete="off"
        />
      )}
      value={selectedVendor}
      options={vendors}
      disabled={!!disabled || !country}
      getOptionLabel={(option) =>
        `(${clearSpecialCharacters(
          option.externalCode
        )}) ${clearSpecialCharacters(option.name)}`
      }
      onChange={(_, value) => {
        setSelectedVender(value ?? undefined);
      }}
    />
  );
};

export default VendorSelector;
