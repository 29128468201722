import appConfig from "config/app";
import {
  CommentType,
  CreateCommentPayload,
  CreateCommnetResponse,
  GetCommentsResponse,
} from "core/comment/entity";
import { ApiVersion, getHttp, postHttp } from "utils/http";

export const getComments = async (
  sourceId: string,
  type: CommentType,
  kitchenId: string
): Promise<GetCommentsResponse> => {
  try {
    const response = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `comment/list/${sourceId}/${type}/${kitchenId}`
    );
    const responseJSON: GetCommentsResponse = await response.json();
    return responseJSON;
  } catch (err) {
    console.error("Error in getComments", err);
    return { ok: false, message: "Error", data: [] };
  }
};

export const createComment = async (
  data: CreateCommentPayload
): Promise<CreateCommnetResponse> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `comment/create`,
      data
    );
    const responseJSON: CreateCommnetResponse = await response.json();
    return responseJSON;
  } catch (err) {
    console.error("Error in createComment", err);
    return { ok: false, message: "Error", data: undefined };
  }
};
