import { KOSEmptyState } from "@foodology-co/alejandria";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { commons } from "app/i18n/types";
import { Supply } from "core/supplies/entities/Supply";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface BySuppliesTableProps {
  supplies: Supply[];
  loading: boolean;
  onSupplyCheck: (sku: string) => void;
  checkedSupplies: Array<string>;
  toggleSelectAll: () => void;
  unselectSupplies: (skus: Array<string>) => void;
  onClickInfo: (supply: Supply) => void;
}

const BySuppliesTable: FunctionComponent<BySuppliesTableProps> = (props) => {
  const {
    supplies,
    loading,
    onSupplyCheck,
    checkedSupplies,
    toggleSelectAll,
    unselectSupplies,
    onClickInfo,
  } = props;

  const { t } = useTranslation();

  return (
    <TableContainer component={Paper}>
      {loading && <LinearProgress />}

      <Table
        sx={{ minWidth: 650 }}
        aria-label="counting-history"
        size="small"
        stickyHeader
      >
        <TableHead>
          <TableRow
            sx={{
              "& > th": {
                bgcolor: "#F9FAFC",
                borderBottom: "2px solid #E3E5E8",
              },
            }}
          >
            <TableCell align="center" width={80}>
              <Checkbox
                onChange={toggleSelectAll}
                checked={
                  checkedSupplies.length > 0 &&
                  checkedSupplies.length === supplies.length
                }
              />
            </TableCell>

            <TableCell align="center">{t(commons.SKU)}</TableCell>
            <TableCell align="left">{t(commons.NAME)}</TableCell>
            <TableCell align="left">{t(commons.GROUP)}</TableCell>
            <TableCell align="center">{t(commons.UNIT)}</TableCell>
            <TableCell align="center" />
          </TableRow>
        </TableHead>

        <TableBody>
          {supplies.map((supply) => (
            <TableRow
              key={supply.sku}
              className={
                checkedSupplies.includes(supply.sku) ? "selected" : undefined
              }
              sx={{
                bgcolor: checkedSupplies.includes(supply.sku)
                  ? "#E2F0FF"
                  : "white",

                "&.selected > td": {
                  borderBottomColor: "#ccdef1",
                },

                "&:hover": {
                  bgcolor: checkedSupplies.includes(supply.sku)
                    ? "#ccdef1"
                    : "#fafafa",

                  "& .show-on-hover": {
                    display: "flex",
                  },
                },
              }}
            >
              <TableCell align="center" width={80}>
                <Checkbox
                  onChange={() => onSupplyCheck(supply.sku)}
                  checked={checkedSupplies.includes(supply.sku)}
                />
              </TableCell>

              <TableCell align="center">
                <Typography>{supply.sku}</Typography>
              </TableCell>

              <TableCell align="left">
                <Typography>{supply.name}</Typography>
              </TableCell>

              <TableCell align="left">
                <Typography textTransform="capitalize">
                  {supply.group.toLowerCase()}
                </Typography>
              </TableCell>

              <TableCell align="center">
                {supply.unit !== "" && (
                  <Typography>
                    {t(`measureUnits.${supply.unit.toLocaleLowerCase()}`)}
                  </Typography>
                )}
              </TableCell>

              <TableCell align="center" width={200}>
                <Box className="show-on-hover" sx={{ display: "none" }}>
                  <IconButton
                    aria-label="delete"
                    onClick={() => unselectSupplies([supply.sku])}
                  >
                    <DeleteOutlineOutlinedIcon />
                  </IconButton>

                  <IconButton
                    aria-label="information"
                    onClick={() => onClickInfo(supply)}
                  >
                    <InfoOutlinedIcon />
                  </IconButton>
                </Box>
              </TableCell>
            </TableRow>
          ))}

          {supplies.length === 0 && (
            <TableRow>
              <TableCell colSpan={7}>
                <KOSEmptyState
                  icon={InfoOutlinedIcon}
                  instruction="Busca un insumo para continuar."
                  message="No hay insumos seleccionados."
                />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BySuppliesTable;
