import AlarmOnOutlinedIcon from "@mui/icons-material/AlarmOnOutlined";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import HourglassTopOutlinedIcon from "@mui/icons-material/HourglassTopOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import ScaleOutlinedIcon from "@mui/icons-material/ScaleOutlined";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import Grid from "@mui/material/Grid";
import { commons, productionManagement } from "app/i18n/types";
import { convertLocalFullDate } from "app/utils/dateUtils";
import { FunctionComponent } from "react";
import GridItem from "./GridItem";

interface Props {
  data: {
    name: string;
    timeSlot?: string;
    quantity: number;
    measureUnit: string;
    producedQuantity?: number;
    startDate?: string;
    finishedAt?: string;
    assignedUserName?: string;
    productionStatus?: string;
  };
  preparationTime: string;
}

const ProductionInfoDialogContent: FunctionComponent<Props> = (props) => {
  const { data, preparationTime } = props;

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <GridItem
          icon={<Inventory2OutlinedIcon />}
          title={commons.NAME}
          body={data.name}
        />
      </Grid>
      <Grid item xs={6}>
        <GridItem
          icon={<WorkHistoryOutlinedIcon />}
          title={productionManagement.TIME_SLOT}
          body={data.timeSlot ?? "-"}
        />
      </Grid>
      <Grid item xs={6}>
        <GridItem
          icon={<EventAvailableOutlinedIcon />}
          title={productionManagement.SCHEDULED_QUANTITY}
          body={`${data.quantity} ${data.measureUnit}`}
        />
      </Grid>
      <Grid item xs={6}>
        <GridItem
          icon={<ScaleOutlinedIcon />}
          title={productionManagement.PRODUCED_QUANTITY}
          body={
            data.producedQuantity
              ? `${data.producedQuantity} ${data.measureUnit}`
              : "-"
          }
        />
      </Grid>
      <Grid item xs={6}>
        <GridItem
          icon={<TimerOutlinedIcon />}
          title={productionManagement.STARTED_PRODUCTION}
          body={
            data.startDate
              ? `${convertLocalFullDate(data.startDate.toString())}`
              : "-"
          }
        />
      </Grid>
      <Grid item xs={6}>
        <GridItem
          icon={<AlarmOnOutlinedIcon />}
          title={productionManagement.FINISHED_PRODUCTION}
          body={
            data.finishedAt
              ? `${convertLocalFullDate(data.finishedAt.toString())}`
              : "-"
          }
        />
      </Grid>
      <Grid item xs={6}>
        <GridItem
          icon={<AssignmentIndOutlinedIcon />}
          title={productionManagement.RESPONSIBLE}
          body={data.assignedUserName ?? "-"}
        />
      </Grid>
      <Grid item xs={6}>
        <GridItem
          icon={<HourglassTopOutlinedIcon />}
          title={productionManagement.PRODUCTION_TIME}
          body={data.productionStatus === "FINISHED" ? preparationTime : "-"}
        />
      </Grid>
    </Grid>
  );
};

export default ProductionInfoDialogContent;
