import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { clearDeleteProduction } from "app/store/slices/productions";
import { archiveProduction } from "app/store/slices/productions/thunks";
import { AmplitudeEvent, logEvent } from "core/common/utils/analytics";
import {
  getRemoteValueBoolean,
  RemoteConfigKey,
} from "core/common/utils/remoteConfig";
import {
  ProductionRecord,
  ProductionStatus,
} from "core/productions/entities/Productions";
import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  SelectedProductionActionType,
  SelectedProductionAndAction,
} from "utils/ratatouille";
import { productionManagement } from "../../../i18n/types";
import ProductionDialog from "../ProductionDialog";
import ProductionCardContent from "./Content";
import ProductionCardFooterActions from "./FooterActions";
import ProductionCardHeader from "./Header";
import ProductionCardIngredients from "./Ingredients";
import ProductionCardLabelsList from "./LabelsList";

interface Props {
  production: ProductionRecord;
  kitchenId: string | null;
  setSelectedProductionAndAction: (
    selected?: SelectedProductionAndAction
  ) => void;
  onRefresh?: () => void;
}

const ProductionCard: FunctionComponent<Props> = (props) => {
  const { production, kitchenId, setSelectedProductionAndAction, onRefresh } =
    props;

  const dispatch = useAppDispatch();

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [productionName, setProductionName] = useState<string>("");

  const { t } = useTranslation();

  const requiresValidation = getRemoteValueBoolean(
    RemoteConfigKey.VALIDATION_REQUIRED
  );

  const openDelete = () => {
    logEvent(AmplitudeEvent.OpenDeleteProduction, {
      kitchenId: kitchenId,
      productionId: production.id,
      sku: production.sku,
      productName: production.name,
    });
    setProductionName(production.name);
    setOpenDeleteDialog(true);
  };

  const onArchiveProduction = async () => {
    logEvent(AmplitudeEvent.ConfirmDeleteProduction, {
      kitchenId: kitchenId,
      productionId: production.id,
      sku: production.sku,
      productName: production.name,
    });
    await dispatch(archiveProduction({ productionId: production.id }));
    setOpenDeleteDialog(false);
    dispatch(clearDeleteProduction(production));
  };

  const setSelectedProduction = (action: SelectedProductionActionType) => {
    setSelectedProductionAndAction({
      production,
      action: action,
    });
  };

  const getHeaderColor = (status: ProductionStatus): string => {
    switch (status) {
      case "SCHEDULED":
        return "info.main";
      case "FINISHED":
        return "success.main";
      case "APPROVED":
        return "success.main";
      case "APPROVED_WITH_NOVELTY":
        return "success.main";
      default:
        return "info.dark";
    }
  };

  return (
    <Card
      sx={{
        flexBasis: 320,
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        borderRadius: "8px",
        maxHeight: "100%",
      }}
    >
      <CardHeader
        sx={{
          backgroundColor: getHeaderColor(production.status),
        }}
        subheader={<ProductionCardHeader production={production} />}
      />
      <CardContent sx={{ pb: 1, overflowY: "auto" }}>
        <ProductionCardContent
          production={production}
          openDelete={openDelete}
          kitchenId={kitchenId}
          setSelectedProduction={setSelectedProduction}
        />
        {["IN_PROCESS", "SCHEDULED"].includes(production.status) && (
          <Box py={2}>
            <ProductionCardIngredients
              key={`ProductionCardIngredients${production.id}`}
              production={production}
            />
          </Box>
        )}

        <ProductionCardLabelsList
          key={`ProductionCardLabelsList${production.id}`}
          requiresValidation={requiresValidation}
          production={production}
        />

        {requiresValidation && production.status === "FINISHED" && (
          <Alert severity="info" sx={{ m: 2 }}>
            {t(productionManagement.VALIDATION_REQUIRED_INFO)}
          </Alert>
        )}

        <ProductionCardFooterActions
          key={`ProductionCardFooterActions${production.id}`}
          production={production}
          setSelectedProduction={setSelectedProduction}
          onRefresh={onRefresh}
          requiresValidation={requiresValidation}
          contract
        />
      </CardContent>
      <ProductionDialog
        handleClose={() => setOpenDeleteDialog(false)}
        handleConfirm={onArchiveProduction}
        message={t(productionManagement.DELETE_DIALOG_MESSAGE, {
          productionName,
        })}
        title={t(productionManagement.DELETE_DIALOG_TITLE)}
        open={openDeleteDialog}
        icon={<DeleteOutlinedIcon color="error" sx={{ fontSize: 96 }} />}
        showCancelButton={true}
      />
    </Card>
  );
};

export default React.memo(ProductionCard);
