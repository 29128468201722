import { Button, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import {
  getSuppliesByCountry,
  setSupliesStateByKitchens,
} from "app/store/slices/supplies/thunks";
import { Country } from "core/common/entities/Country";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import KitchenSelector from "../Kitchen/Selector";

import {
  CancelOutlined,
  CheckCircleOutline,
  InfoOutlined,
} from "@mui/icons-material";
import { commons, suppliesManagerModule } from "app/i18n/types";
import { excelToJson } from "app/utils/excel";
import {
  Supply,
  UpdateSupplyStatusPayload,
} from "core/supplies/entities/Supply";
import { useTranslation } from "react-i18next";
import UploadArea from "../UploadArea";
import CommonDialog from "../common/CommonDialog";
import MassiveSupplyTable from "./MassiveSupplyTable";

interface MassiveSupplyTabProps {}

const MassiveSupplyTab: FunctionComponent<MassiveSupplyTabProps> = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const [reset, setReset] = useState<number>(new Date().getTime());
  const [isLoadingExcel, setIsLoadingExcel] = useState<boolean>(false);
  const [supplies, setSupplies] = useState<Array<Supply>>([]);
  const [openSuccessDialog, setOpenSuccessDialog] = useState<boolean>(false);

  const suppliesRequest = useAppSelector(
    (state) => state.supplies.suppliesByCountry
  );

  const onUpload = async (files: File[] | null) => {
    if (!files) return;
    setIsLoadingExcel(true);

    const fileRows = await Promise.all(
      files.map((file) =>
        excelToJson(file, {
          skipRows: 1,
        })
      )
    );
    const productsByCountry = suppliesRequest.supplies;
    const supplies = fileRows
      .flat()
      .map((row) => {
        const locationId = String(row[0]);
        const sku = String(row[1]);
        const product = productsByCountry?.find((prod) => prod.sku === sku);
        if (product) {
          return {
            active: false,
            category: product.category,
            group: product.group,
            location: locationId,
            name: product.name,
            sku: sku,
            unit: product.unit,
          } as Supply;
        }
      })
      .filter((item): item is Supply => !!item);

    setSupplies(supplies);
    setIsLoadingExcel(false);
  };

  const getSupplies = useCallback(() => {
    if (selectedCountry) {
      const country = Country[selectedCountry as keyof typeof Country];
      dispatch(getSuppliesByCountry(country));
    }
  }, [selectedCountry]);

  const clearSupplies = useCallback(() => {
    setSelectedCountry(null);
    setSupplies([]);
    setReset(new Date().getTime());
  }, [selectedCountry]);

  const getUpdateSupplyPayload = (supply: Supply, active: boolean) => {
    return {
      sku: supply.sku,
      kitchenId: supply.location,
      comments: "",
      state: active,
    } as UpdateSupplyStatusPayload;
  };

  const activateSupplies = async () => {
    const payload = supplies.map((supply) =>
      getUpdateSupplyPayload(supply, true)
    );
    await dispatch(setSupliesStateByKitchens(payload));
    setOpenSuccessDialog(true);
    clearSupplies();
  };

  const inactivateSupplies = async () => {
    const payload = supplies.map((supply) =>
      getUpdateSupplyPayload(supply, false)
    );
    await dispatch(setSupliesStateByKitchens(payload));
    setOpenSuccessDialog(true);
    clearSupplies();
  };

  useEffect(() => {
    getSupplies();
  }, [getSupplies, selectedCountry]);
  const emptyGrid = <Grid item xs={0} md={4}></Grid>;

  return (
    <Box display="flex" gap={2} flexDirection="column">
      {supplies.length === 0 ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {emptyGrid}
          <Grid item xs={12} md={4}>
            <KitchenSelector
              hideCitySelector
              hideLocationSelector
              size="small"
              onCountryChange={setSelectedCountry}
            />
          </Grid>
          {emptyGrid}
          {emptyGrid}
          <Grid item xs={12} md={4} textAlign={"center"}>
            <UploadArea
              width="100%"
              key={reset}
              accept=".xlsx, .xls, .csv"
              onUpload={onUpload}
              label={commons.SELECT_FILE}
              loading={isLoadingExcel}
              disabled={!selectedCountry}
            />
          </Grid>
          {emptyGrid}
        </Grid>
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={0} md={6}></Grid>
          <Grid item xs={12} md={6} textAlign={"right"}>
            <Button
              variant="outlined"
              startIcon={<CheckCircleOutline />}
              sx={{ ml: "4px" }}
              onClick={activateSupplies}
            >
              <Typography>
                <Typography>{t(commons.ACTIVATE)}</Typography>
              </Typography>
            </Button>
            <Button
              variant="outlined"
              startIcon={<CheckCircleOutline />}
              sx={{ ml: "4px" }}
              color="warning"
              onClick={inactivateSupplies}
            >
              <Typography>
                <Typography>{t(commons.INACTIVATE)}</Typography>
              </Typography>
            </Button>
            <Button
              variant="outlined"
              startIcon={<CancelOutlined />}
              color="error"
              onClick={clearSupplies}
              sx={{ ml: "4px" }}
            >
              <Typography>{t(commons.CANCEL)}</Typography>
            </Button>
          </Grid>

          <Grid item xs={12} md={12}>
            <MassiveSupplyTable supplies={supplies}></MassiveSupplyTable>
          </Grid>
        </Grid>
      )}
      <CommonDialog
        open={openSuccessDialog}
        handleClose={() => setOpenSuccessDialog(false)}
        handleConfirm={() => setOpenSuccessDialog(false)}
        title={t(suppliesManagerModule.MASSIVE_SUCCESS_TITLE)}
        message={t(suppliesManagerModule.MASSIVE_SUCCESS_MESSAGE)}
        icon={
          <InfoOutlined
            sx={{
              height: "80px",
              width: "80px",
              paddingRight: "5px",
            }}
            color="primary"
          />
        }
        showCancelButton={false}
      />
    </Box>
  );
};

export default MassiveSupplyTab;
