import { UseCase } from "core/common/interfaces/useCase";
import { CanGetByKitchenAndSkus } from "../interfaces/canGetByKitchenAndSkus";

export class GetByKitchenAndSkusUseCase implements UseCase {
  constructor(private readonly repository: CanGetByKitchenAndSkus) {}

  public execute(kitchenId: string, skus: string[]) {
    return this.repository.getByKitchenAndSkus(kitchenId, skus);
  }
}
