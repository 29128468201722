/* eslint-disable react/no-array-index-key */
import { Box, DialogTitle, Divider, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { takePhotoLocale } from "app/i18n/types";
import React from "react";
import { useTranslation } from "react-i18next";
import TakePhotoDialogContent from "./TakePhotoDialogContent";

interface TakePhotoDialogProps {
  onCloseDialog: () => void;
  onContinue: (img: string) => void;
}

const TakePhotoDialog = (props: TakePhotoDialogProps): React.ReactElement => {
  const { onCloseDialog, onContinue } = props;
  const [fullScreen, setFullScreen] = React.useState(true);
  const { t } = useTranslation();

  return (
    <Dialog
      sx={{ zIndex: 99999 }}
      fullScreen={fullScreen}
      fullWidth
      maxWidth="md"
      open
      onClose={onCloseDialog}
    >
      <Box>
        <DialogTitle>
          <Typography variant="h5">{t(takePhotoLocale.TITLE)}</Typography>
        </DialogTitle>
        <Divider variant="fullWidth" />
        <TakePhotoDialogContent
          onContinue={onContinue}
          onCloseDialog={onCloseDialog}
          setFullScreen={setFullScreen}
        />
      </Box>
    </Dialog>
  );
};

export default TakePhotoDialog;
