import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Alert } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useNavigator } from "app/hooks/useNavigator";
import { transferPackingDialog } from "app/i18n/types";
import { pickingLinesByIds } from "app/store/slices/transfer/thunks";
import {
  PickingDetailResponse,
  PickingLocationsResponse,
} from "core/transfer/entities/TransferOrder";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DialogTitle from "../Dialog/CustomDialogTitle";
import TransferPackingDialogRow from "./TransferPackingDialogRow";
import TransferPackingSupplyBox from "./TransferPackingSupplyBox";

interface TransferPackingSupplyDialogProps {
  selectedLine: PickingDetailResponse | null;
  transferId: number;
  setSelectedTransfer: (selectedTransferId: number | null) => void;
  onClose: () => void;
  setStatus: (
    id: number,
    status: "PENDING" | "COMPLETE",
    packedQuantity: number
  ) => void;
}

const TransferPackingForSupplyDialog: FunctionComponent<
  TransferPackingSupplyDialogProps
> = (props) => {
  const { selectedLine, transferId, setSelectedTransfer, onClose, setStatus } =
    props;

  const { t } = useTranslation();
  const navigator = useNavigator();
  const { kitchenId } = navigator.params();
  const dispatch = useAppDispatch();

  const [pickingItemLocations, setPickingItemLocations] = useState<
    PickingLocationsResponse[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleClicK = (location: PickingLocationsResponse) => {
    setSelectedTransfer(
      location.transferId === transferId ? null : location.transferId
    );
  };

  useEffect(() => {
    if (selectedLine && kitchenId) {
      setLoading(true);
      dispatch(
        pickingLinesByIds({
          lineIds: selectedLine.lineIds,
        })
      )
        .then((response) => {
          setPickingItemLocations(
            response.payload as PickingLocationsResponse[]
          );
        })
        .catch(() => {
          setPickingItemLocations([]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selectedLine, kitchenId, dispatch]);

  const setStatusLocation = (
    id: number,
    status: "RELEASED" | "PACKED",
    packedQuantity: number
  ) => {
    setPickingItemLocations(
      pickingItemLocations.map((el) => {
        if (el.id === id) {
          return { ...el, status, packedQuantity };
        }
        return el;
      })
    );
  };

  const onCloseModal = () => {
    if (!pickingItemLocations.length) return;
    setStatus(
      Number(selectedLine?.sku ?? "0"),
      pickingItemLocations.every((el) => el.status === "PACKED")
        ? "COMPLETE"
        : "PENDING",
      pickingItemLocations.reduce((a, b) => a + b.packedQuantity, 0)
    );
    onClose();
  };

  return (
    <Dialog open onClose={onCloseModal} maxWidth="sm" fullWidth>
      <DialogTitle
        title={transferPackingDialog.RESUME}
        status={loading ? "loading" : "succeeded"}
        onClose={onCloseModal}
      />

      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <TransferPackingSupplyBox selectedLine={selectedLine} />
          </Grid>
          <Grid xs={12} sm={12} item>
            <Alert severity="info">{t(transferPackingDialog.SUBTITLE)}</Alert>
          </Grid>
          {pickingItemLocations.map((location) => (
            <Grid
              xs={12}
              md={12}
              item
              key={`GridContainerTransferPackingDialogRow${location.id}`}
            >
              <TransferPackingDialogRow
                key={`TransferPackingDialogRow${location.id}`}
                line={location}
                selectedLine={selectedLine}
                isSelected={location.transferId === transferId}
                handleClicK={() => handleClicK(location)}
                setStatusLocation={setStatusLocation}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          onClick={onCloseModal}
          startIcon={<ChevronRightOutlinedIcon />}
          disabled={loading}
          fullWidth
        >
          {t(transferPackingDialog.CONTINUE)}
        </Button>
        <Button
          variant="outlined"
          onClick={onCloseModal}
          color="error"
          startIcon={<CloseOutlinedIcon />}
          fullWidth
        >
          {t(transferPackingDialog.CANCEL)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransferPackingForSupplyDialog;
