import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import Box from "@mui/material/Box";
import InfoCard from "app/components/InfoCard/InfoCard";
import PageHeader from "app/components/PageHeader";
import ReportTransferOrdersSuccessDialog from "app/components/Transfers/ReportTransferOrdersSuccessDialog";
import TransferOrdersGroups from "app/components/Transfers/TransferOrdersGroups";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { useNavigator } from "app/hooks/useNavigator";
import { clearTransfersState } from "app/store/slices/transfer";
import {
  getPendingTransferOrdersByLocationId,
  reportTransferOrder,
} from "app/store/slices/transfer/thunks";
import dayjs from "app/utils/dayjs";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { PageBox } from "utils/generalUI";

interface TransferOrdersReviewProps {}

const TransferOrdersReviewPage: FunctionComponent<
  TransferOrdersReviewProps
> = () => {
  const navigator = useNavigator();
  const { kitchenId } = navigator.params();
  const dispatch = useAppDispatch();

  const [openSuccessDialog, setOpenSuccessDialog] = useState<boolean>(false);

  const pendingTransferOrdersRequest = useAppSelector(
    (state) => state.transfer.pendingTransferOrders
  );

  const reportTransferOrdersRequest = useAppSelector(
    (state) => state.transfer.reportTransferOrder
  );

  const reportTransferOrders = useCallback(() => {
    if (
      pendingTransferOrdersRequest.transferOrders !== null &&
      pendingTransferOrdersRequest.transferOrders.length > 0
    ) {
      dispatch(
        reportTransferOrder({
          userId: "",
          transferOrderIds: pendingTransferOrdersRequest.transferOrders.map(
            (transferOrder) => transferOrder.id
          ),
        })
      );
    }
  }, [dispatch, pendingTransferOrdersRequest.transferOrders]);

  const clearAndNavigate = useCallback(() => {
    dispatch(clearTransfersState());
    navigator.toByLocationType(`/transfers/${kitchenId}`);
  }, [dispatch, kitchenId]);

  useEffect(() => {
    if (
      kitchenId !== undefined &&
      pendingTransferOrdersRequest.status === "idle"
    ) {
      dispatch(getPendingTransferOrdersByLocationId(kitchenId));
    }
  }, [dispatch, kitchenId, pendingTransferOrdersRequest.status]);

  useEffect(() => {
    if (
      pendingTransferOrdersRequest.status === "succeeded" &&
      (pendingTransferOrdersRequest.transferOrders === null ||
        pendingTransferOrdersRequest.transferOrders.length === 0)
    ) {
      navigator.toByLocationType(`/transfers/${kitchenId}/CP`);
    }
  }, [
    kitchenId,
    pendingTransferOrdersRequest.status,
    pendingTransferOrdersRequest.transferOrders,
  ]);

  useEffect(() => {
    if (reportTransferOrdersRequest.status === "succeeded") {
      setOpenSuccessDialog(true);
    }
  }, [dispatch, kitchenId, reportTransferOrdersRequest.status]);

  return (
    <PageBox>
      <PageHeader
        title={`Revisión de Traslado Masivo`}
        subtitle="Valida los insumos y cocinas que forman parte del traslado."
        rightArea={[
          {
            children: "Programar traslado",
            startIcon: <FactCheckOutlinedIcon />,
            size: "large",
            variant: "contained",
            onClick: () => reportTransferOrders(),
            disabled:
              reportTransferOrdersRequest.status === "loading" ||
              pendingTransferOrdersRequest.status === "loading",
          },
        ]}
      />

      <Box
        my={2}
        display="flex"
        gap={2}
        flexDirection={{
          xs: "column",
          md: "row",
        }}
      >
        <InfoCard
          icon="pin"
          label="Insumos detectados"
          value={`${
            pendingTransferOrdersRequest.transferOrders?.flatMap(
              (order) => order.lines
            ).length ?? 0
          } insumos`}
        />

        <InfoCard
          icon="countertops"
          label="Cocinas detectadas"
          value={`${
            pendingTransferOrdersRequest.transferOrders?.length ?? 0
          } cocinas`}
        />

        <InfoCard
          icon="calendar_month"
          label="Fecha traslado"
          value={dayjs(Date.now()).format("DD/MM/YYYY")}
        />
      </Box>

      <TransferOrdersGroups
        transferOrders={pendingTransferOrdersRequest?.transferOrders ?? []}
        loading={
          reportTransferOrdersRequest.status === "loading" ||
          pendingTransferOrdersRequest.status === "loading"
        }
      />
      <ReportTransferOrdersSuccessDialog
        open={openSuccessDialog}
        onClose={() => setOpenSuccessDialog(false)}
        onContinue={clearAndNavigate}
      />
    </PageBox>
  );
};

export default TransferOrdersReviewPage;
