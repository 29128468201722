import { ManualAdjustment } from "core/adjustments/entities/ManualAdjustment";
import { UseCase } from "core/common/interfaces/useCase";
import { CanValidateCounting } from "core/physicalCount/interfaces/counting/canValidateCounting";

export class validateCountingUseCase implements UseCase {
  constructor(private readonly repository: CanValidateCounting) {}
  public execute(adjustments: Array<ManualAdjustment>) {
    return this.repository.validateCounting(adjustments);
  }
}
