import { OfflinePinOutlined, TimerOutlined } from "@mui/icons-material";
import QrCodeOutlinedIcon from "@mui/icons-material/QrCodeOutlined";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { startProduction } from "app/store/slices/productions/thunks";
import { ProductionRecord } from "core/productions/entities/Productions";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectedProductionActionType } from "utils/ratatouille";
import { commons } from "../../../i18n/types";
import FinishProductionDIalog from "../FinishDialog";

interface Props {
  production: ProductionRecord;
  setSelectedProduction: (action: SelectedProductionActionType) => void;
  onRefresh?: () => void;
  contract?: boolean;
  requiresValidation?: boolean;
}

const ProductionCardFooterActions: FunctionComponent<Props> = (props) => {
  const {
    production,
    setSelectedProduction,
    onRefresh,
    requiresValidation,
    contract,
  } = props;
  const { labels, producedQuantity } = production;

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const [showFinishProduction, setShowFinishProduction] =
    useState<boolean>(false);
  const quantityPacked = labels.reduce((sum, label) => sum + label.quantity, 0);

  const handleStartProduction = () => {
    dispatch(startProduction({ productionId: production.id }));
  };

  return (
    <CardActions sx={{ p: contract ? 0 : 2, marginTop: "auto" }}>
      {production.status === "SCHEDULED" && (
        <Button
          sx={{ p: "12px" }}
          startIcon={<TimerOutlined />}
          size="large"
          variant="contained"
          onClick={handleStartProduction}
          fullWidth
        >
          <Typography fontWeight={500}>{t(commons.START)}</Typography>
        </Button>
      )}
      {production.status === "IN_PROCESS" && (
        <>
          <Button
            sx={{ p: "12px" }}
            startIcon={<OfflinePinOutlined />}
            size="large"
            variant="contained"
            onClick={() => setShowFinishProduction(true)}
            fullWidth
          >
            <Typography fontWeight={500}>{t(commons.FINISH)}</Typography>
          </Button>

          {showFinishProduction && (
            <FinishProductionDIalog
              production={production}
              setShowModal={setShowFinishProduction}
              onSuccess={onRefresh}
            />
          )}
        </>
      )}
      {(production.status === "FINISHED" ||
        production.status === "APPROVED" ||
        production.status === "APPROVED_WITH_NOVELTY") && (
        <Button
          sx={{ p: "12px", mt: 2 }}
          startIcon={<QrCodeOutlinedIcon />}
          size="large"
          variant="contained"
          color="primary"
          onClick={() =>
            setSelectedProduction(SelectedProductionActionType.CREATE_LABEL)
          }
          fullWidth
          disabled={
            (requiresValidation && production.status === "FINISHED") ||
            quantityPacked === producedQuantity
          }
        >
          <Typography fontWeight={500}>{t(commons.PACK)}</Typography>
        </Button>
      )}
    </CardActions>
  );
};

export default ProductionCardFooterActions;
