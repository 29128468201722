import { UseCase } from "core/common/interfaces/useCase";
import { canGetProductionById } from "../interfaces/canGetProductionbyId";

export class GetProductionById implements UseCase {
  constructor(private readonly repository: canGetProductionById) {}

  public execute(productionId: string, kitchenId: string) {
    return this.repository.getProductionById(productionId, kitchenId);
  }
}
