import { Check } from "@mui/icons-material";
import { Grid } from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { commons, purchaseOrderCreation } from "app/i18n/types";
import { PurchaseOrder } from "core/purchaseOrders/entities/PurchaseOrder";
import { releasePurchaseOrders } from "core/purchaseOrders/repositories/http/purchase";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MultipleFieldWithChips } from "../common/Field/Multiple";
import { ManagerDialog } from "../common/Manager/Dialog";
import { ManagerDialogFooter } from "../common/Manager/Dialog/Footer";
import PurchaseOrderInfoHeader from "./PurchaseOrderInfoHeader";

interface Props {
  purchaseOrder: PurchaseOrder;
  onClose: () => void;
  onPurchaseOrderConfirmAndSend: (ids: number[]) => void;
}

const ConfirmPurchaseOrderModal: FunctionComponent<Props> = (props) => {
  const { purchaseOrder, onClose, onPurchaseOrderConfirmAndSend } = props;

  const [loadingConfirm, setLoadingConfirm] = useState<boolean>(false);
  const [confirmSuccess, setConfirmSuccess] = useState<boolean>(false);
  const [purchaseEmails, setPurchaseEmails] = useState<string[]>([]);

  useEffect(() => {
    if (purchaseOrder.purchaseEmail) {
      setPurchaseEmails(purchaseOrder.purchaseEmail.split(";"));
    }
  }, [purchaseOrder]);

  const { t } = useTranslation();

  const handleConfirmPurchaseOrder = useCallback(async () => {
    setLoadingConfirm(true);

    const response = await releasePurchaseOrders([
      {
        purchaseOrderId: purchaseOrder.id,
        email: purchaseEmails.length ? purchaseEmails.join(";") : undefined,
      },
    ]);
    if (response.ok) {
      setLoadingConfirm(false);
      setConfirmSuccess(true);
    }
  }, [purchaseEmails, purchaseOrder]);

  return (
    <ManagerDialog
      title={purchaseOrderCreation.SEND_ORDER_MODAL_TITLE}
      onClose={onClose}
      content={
        <Grid container spacing={2}>
          {!confirmSuccess ? (
            <>
              <Grid item xs={12}>
                <PurchaseOrderInfoHeader purchaseOrder={purchaseOrder} />
              </Grid>
              <Grid item xs={12}>
                <Alert severity="info">
                  {t(purchaseOrderCreation.SEND_ORDER_MODAL_INFO)}
                </Alert>
              </Grid>
              {loadingConfirm && (
                <>
                  <Grid item xs={12}>
                    <Alert severity="info">
                      {t(purchaseOrderCreation.SEND_LOADING_MODAL)}
                    </Alert>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                      <CircularProgress size={40} />
                    </Box>
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <MultipleFieldWithChips
                  label={commons.EMAIL_LABEL}
                  items={purchaseEmails}
                  setItems={setPurchaseEmails}
                />
              </Grid>
              <Grid item xs={12}>
                <ManagerDialogFooter
                  mainButton={{
                    children: t(commons.SEND),
                    onClick: handleConfirmPurchaseOrder,
                    disabled: loadingConfirm,
                  }}
                  onCancel={onClose}
                  loading={loadingConfirm}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Alert severity="info">
                  <Typography>
                    {t(purchaseOrderCreation.ORDER_CONFIRMED)}
                  </Typography>
                </Alert>
              </Grid>
              <Grid item xs={12}>
                <Button
                  startIcon={<Check />}
                  variant="contained"
                  onClick={() => {
                    onPurchaseOrderConfirmAndSend([purchaseOrder.id]);
                    onClose();
                  }}
                  fullWidth
                  color="success"
                >
                  <Typography>
                    {t(purchaseOrderCreation.ORDERD_CONFIRMED_OK)}
                  </Typography>
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      }
    />
  );
};
export default ConfirmPurchaseOrderModal;
