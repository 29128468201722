import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import BoxTitleTable from "app/components/PurchaseRecord/BoxTitleTable";
import PurchaseOrdersTableSkeleton from "app/components/PurchaseRecord/PurchaseOrdersSkeleton";
import { useNavigator } from "app/hooks/useNavigator";
import { commons, supplyingManagement } from "app/i18n/types";
import { convertLocalDate } from "app/utils/dateUtils";
import { ProductionSupplyResponse } from "core/productions/entities/Supply";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { ColorType, sxCustomBlue } from "utils/generalUI";

interface Props {
  items: ProductionSupplyResponse[];
  loading: boolean;
}

const ProductionSupplyTable: FunctionComponent<Props> = (props) => {
  const { items, loading } = props;
  const { t } = useTranslation();
  const navigator = useNavigator();

  const statusChip = (item: ProductionSupplyResponse) => {
    const chipProps = {
      icon: <CheckCircleOutlineOutlinedIcon />,
      label: t(supplyingManagement.COMPLETE),
      color: "success",
    };
    if (item.status === "PENDING") {
      chipProps.icon = <ErrorOutlineOutlinedIcon />;
      chipProps.label = t(supplyingManagement.PENDING);
      chipProps.color = "warning";
    }
    return (
      <Chip
        icon={chipProps.icon}
        label={chipProps.label}
        color={chipProps.color as ColorType}
        variant="filled"
        sx={{ color: "white", fontWeight: "bold" }}
      />
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 650, maxHeight: 600 }}
        aria-label="inventory"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <BoxTitleTable data={t(commons.ID)} />
            <BoxTitleTable data={t(supplyingManagement.CP_NAME)} />
            <BoxTitleTable data={t(commons.DATE)} align="center" />
            <BoxTitleTable data={t(commons.STATUS)} align="center" />
            <BoxTitleTable data={t(commons.ACTIONS)} align="center" />
          </TableRow>
        </TableHead>

        {loading && <PurchaseOrdersTableSkeleton columns={5} />}

        <TableBody>
          {!loading && !items.length && (
            <TableRow>
              <TableCell align="center" colSpan={5}>
                <Typography fontWeight={300} color="#454B54">
                  {t(commons.TABLE_EMPTY)}
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {!loading &&
            items.map((item) => (
              <TableRow key={`ProductionSupplyRow${item.id}`}>
                <TableCell align="left">
                  <Typography fontWeight={300} color="#454B54">
                    {item.id}
                  </Typography>
                </TableCell>

                <TableCell align="left">
                  <Typography fontWeight={300} color="#454B54">
                    {item.locationName}
                  </Typography>
                </TableCell>

                <TableCell align="center">
                  <Typography fontWeight={300} color="#454B54">
                    {convertLocalDate(item.productionDate.toString(), "date")}
                  </Typography>
                </TableCell>

                <TableCell align="center">{statusChip(item)}</TableCell>

                <TableCell align="center">
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: 2,
                      fontWeight: 700,
                      ...sxCustomBlue,
                    }}
                    startIcon={<ArchiveOutlinedIcon />}
                    onClick={() => {
                      navigator.toByLocationType(
                        `/supplying/${item.locationId}/detail/${item.id}`
                      );
                    }}
                  >
                    {t(
                      item.status === "PENDING"
                        ? commons.PREPARE
                        : commons.DETAILS
                    )}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductionSupplyTable;
