import { UseCase } from "core/common/interfaces/useCase";
import { CanSetTransferOrderAsSend } from "core/transfer/interfaces/transfer/canSetTransferOrderAsSended";

export class SetTransferOrderAsSendUseCase implements UseCase {
  constructor(private readonly repository: CanSetTransferOrderAsSend) {}

  public execute(transferOrderId: number, numberOfBaskets: number) {
    return this.repository.setTransferOrderAsSend(
      transferOrderId,
      numberOfBaskets
    );
  }
}
