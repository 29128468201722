import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { commons } from "app/i18n/types";
import { Reason } from "config/dailyAdjustment";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  value: string | null;
  onChange: (value: string | null) => void;
  size?: "small" | "medium";
  reasons: Reason;
}

const ReasonSelect = ({ value, onChange, size, reasons }: Props) => {
  const { t } = useTranslation();

  const [key, setKey] = React.useState<number>(0);

  React.useEffect(() => {
    if (!value) {
      setKey(new Date().getTime());
    }
  }, [value]);

  return (
    <FormControl fullWidth>
      <InputLabel>{t(commons.REASON)}</InputLabel>
      <Select
        key={key}
        fullWidth
        value={value}
        size={size}
        onChange={(e) => {
          if (e.target.value) {
            onChange(e.target.value);
          }
        }}
        label={t(commons.REASON)}
      >
        {Object.keys(reasons).map((key) => (
          <MenuItem key={`ReasonSelect${key}`} value={key}>
            {t(reasons[key])}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ReasonSelect;
