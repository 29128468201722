import Box from "@mui/material/Box";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { updateDateAndSlot } from "app/store/slices/productions/plan/thunks";
import { getToday } from "app/utils/dateUtils";
import { ProductionPlan } from "core/productions/entities/Plan";
import React, { useState } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { getDatesToCalendar } from "utils/productionSchedule";
import ProductionInfoDialog from "../InfoDialog";
import ProductionScheduleDayOfWeek from "./DayOfWeek";
import { ProductionDialogProps } from "./Manager/useHandleProductions";
import ProductionScheduleSlotLabel from "./SlotLabel";

export interface CalendarData {
  [key: string]: ProductionPlan[];
}

export interface DragAndDropAction {
  source: string;
  destination: string;
  elementId: number;
  element?: ProductionPlan;
}

export const SEPARATOR = "&";

export interface DragAndDropData {
  data?: DragAndDropAction;
  setElement: (element?: ProductionPlan) => void;
  clearData: () => void;
}

export interface ShowPlanDetail {
  planId: number;
}

interface Props {
  startPlus: number;
  openAddProductionDialog: (props: ProductionDialogProps) => void;
  kitchenId: string;
  onlyView?: boolean;
}

const ProductionScheduleCalendar = (props: Props): React.ReactElement => {
  const { startPlus, openAddProductionDialog, kitchenId, onlyView } = props;
  const dispatch = useAppDispatch();

  const [dragAndDropAction, setDragAndDropAction] =
    useState<DragAndDropAction>();
  const [showPlanDetail, setShowPlanDetail] = useState<ShowPlanDetail>();

  const openInfoDialog = (planId: number) => {
    setShowPlanDetail({ planId });
  };

  const today = getToday();
  const dates = getDatesToCalendar(startPlus);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const action = {
      source: result.source.droppableId,
      destination: result.destination.droppableId,
      elementId: Number(result.draggableId),
    };
    if (action.source !== action.destination) {
      setDragAndDropAction(action);
      const destination = action.destination.split(SEPARATOR);
      dispatch(
        updateDateAndSlot({
          id: result.draggableId,
          slot: destination.at(0) ?? "",
          scheduledDate: destination.at(1) ?? "",
        })
      );
    }
  };

  const setDragAndDropElement = (element?: ProductionPlan) => {
    if (!dragAndDropAction) return;
    setDragAndDropAction({ ...dragAndDropAction, element });
  };

  const clearDragAndDropAction = () => {
    setDragAndDropAction(undefined);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <ProductionScheduleSlotLabel />
      <DragDropContext onDragEnd={handleDragEnd}>
        {!!kitchenId &&
          dates.map((date, index) => (
            <ProductionScheduleDayOfWeek
              key={`ProductionScheduleDayOfWeek${index}`}
              date={date}
              openAddProductionDialog={openAddProductionDialog}
              kitchenId={kitchenId}
              dragAndDropData={{
                data: dragAndDropAction,
                setElement: setDragAndDropElement,
                clearData: clearDragAndDropAction,
              }}
              today={today}
              openInfoDialog={openInfoDialog}
              onlyView={onlyView}
            />
          ))}
      </DragDropContext>
      <ProductionInfoDialog
        open={!!showPlanDetail}
        planId={showPlanDetail?.planId ?? 0}
        onClose={() => setShowPlanDetail(undefined)}
      />
    </Box>
  );
};

export default React.memo(ProductionScheduleCalendar);
