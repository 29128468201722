import { UseCase } from "core/common/interfaces/useCase";
import { ExistsLabelLog } from "core/transfer/interfaces/transfer/existsLabelLog";

export class ExistsLabelLogUseCase implements UseCase {
  constructor(private readonly repository: ExistsLabelLog) {}

  public execute(originId: string, productionLabelId: string) {
    return this.repository.existsLabelLog(originId, productionLabelId);
  }
}
