import { UseCase } from "core/common/interfaces/useCase";
import { CanSendToValidationAdjustments } from "core/dailyAdjustments/interfaces/adjustment/canSendToValidationAdjustments";

export class SendToValidationAdjustmentsUseCase implements UseCase {
  constructor(private readonly repository: CanSendToValidationAdjustments) {}

  public execute(adjustments: Array<number>) {
    return this.repository.sendToValidationAdjustments(adjustments);
  }
}
