import appConfig from "config/app";
import { AttachmentFile, DownloadZipPayload } from "core/attachment/entity";
import { Purchase } from "core/purchaseOrders/entities/PurchaseOrder";
import { clearSpecialCharacters, openUrl } from "utils/general";
import { ApiVersion, getHttp, postHttp } from "utils/http";

export const getReceiptAttachments = async (
  sourceId: string,
  origin: string,
  type: string,
  kitchenId: string
): Promise<AttachmentFile[]> => {
  try {
    const response = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `attachment/list/source/${sourceId}/type/${type}/origin/${origin}/kitchenId/${kitchenId}`
    );
    const responseJSON: AttachmentFile[] = await response.json();
    return responseJSON;
  } catch (err) {
    console.error("Error in getReceiptAttachments", err);
    throw err;
  }
};

export const viewFile = (
  sourceId: string,
  origin: string,
  type: string,
  name: string
): void => {
  try {
    openUrl(
      `${appConfig.kitchenInventory.apiBaseUrl}${ApiVersion.V1}attachment/${sourceId}/${type}/${origin}/${name}`
    );
  } catch (err) {
    console.error("Error in viewFile", err);
    throw err;
  }
};

export const getPurchaseOrderPdfName = (
  code: string,
  kitchenId: string,
  vendorName: string
): string => {
  return `${code} | ${clearSpecialCharacters(vendorName)} | ${kitchenId}`;
};

export const viewPurchaseOrderPdf = (order: Purchase): void => {
  try {
    const { country, orderId, code, kitchenId, vendorName } = order;
    const name = getPurchaseOrderPdfName(code, kitchenId, vendorName);
    openUrl(
      `${appConfig.kitchenInventory.apiBaseUrl}${ApiVersion.V1}storage/purchase/${country}/${orderId}/pdf/${name}`
    );
  } catch (err) {
    console.error("Error in viewPurchaseOrderPdf", err);
    throw err;
  }
};

export const downloadPurchaseOrdersZip = async (
  data: DownloadZipPayload[]
): Promise<void> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `storage/purchase/zip`,
      { data }
    );
    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      const time = new Date().getTime();
      a.download = `purchase-order-${time}.zip`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  } catch (err) {
    console.error("Error in downloadPurchaseOrdersZip", err);
    throw err;
  }
};
