import readXlsxFile from "read-excel-file";

export type options = {
  sheet?: number | string;
  skipRows?: number;
};

export const excelToJson = async (file: File | Blob, options: options) => {
  const rows = await readXlsxFile(file, {
    sheet: options.sheet ?? 1,
  });

  if (options.skipRows !== undefined && options.skipRows > 0) {
    return rows.slice(options.skipRows);
  }

  return rows;
};
