import { UseCase } from "core/common/interfaces/useCase";
import { ArchiveLinesPayload } from "core/transfer/entities/TransferOrder";
import { CanArchiveLines } from "core/transfer/interfaces/transfer/canArchiveLines";

export class ArchiveLinesUseCase implements UseCase {
  constructor(private readonly repository: CanArchiveLines) {}

  public execute(payload: ArchiveLinesPayload) {
    return this.repository.archiveLines(payload);
  }
}
