import { UseCase } from "core/common/interfaces/useCase";
import { ReportRequest } from "core/dailyAdjustments/entities/Report";
import { CanCreateDailyAdjustmentReport } from "core/dailyAdjustments/interfaces/adjustment/canCreateDailyAdjustmentReport";

export class CreateDailyAdjustmentReportUseCase implements UseCase {
  constructor(private readonly repository: CanCreateDailyAdjustmentReport) {}

  public execute(payload: ReportRequest) {
    return this.repository.createDailyAdjustmentReport(payload);
  }
}
