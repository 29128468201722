import { configureStore } from "@reduxjs/toolkit";
import { reducer as adjustmentsReducer } from "./slices/adjustments";
import { reducer as consolidatedExportReducer } from "./slices/consolidatedReport/index";
import { reducer as countingReducer } from "./slices/counting";
import { reducer as countingHistoryReducer } from "./slices/countingHistory/";
import { reducer as dailyAdjustmentsReducer } from "./slices/dailyAdjustments";
import { reducer as globalReducer } from "./slices/global";
import { reducer as menuReducer } from "./slices/menu";
import { reducer as productionsReducer } from "./slices/productions";
import { reducer as linesPurchaseReducer } from "./slices/purchase/lines.slice";
import { reducer as purchaseReducer } from "./slices/purchase/purchase.slice";
import { reducer as sessionReducer } from "./slices/session";
import { reducer as suppliesReducer } from "./slices/supplies";
import { reducer as theoricalInventoryReducer } from "./slices/theoricalInventory";
import { reducer as transferReducer } from "./slices/transfer";

const store = configureStore({
  reducer: {
    global: globalReducer,
    session: sessionReducer,
    menu: menuReducer,
    counting: countingReducer,
    purchase: purchaseReducer,
    linesPurchase: linesPurchaseReducer,
    countingHistory: countingHistoryReducer,
    consolidatedExport: consolidatedExportReducer,
    supplies: suppliesReducer,
    adjustments: adjustmentsReducer,
    dailyAdjustments: dailyAdjustmentsReducer,
    theoricalInventory: theoricalInventoryReducer,
    productions: productionsReducer,
    transfer: transferReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
