import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { productionPreparation } from "app/i18n/types";
import { ProductionRecord } from "core/productions/entities/Productions";
import React from "react";
import { useTranslation } from "react-i18next";
import { ProductPreparation, getProductPreparation } from "utils/ratatouille";

interface Props {
  production: ProductionRecord;
  componentRef?: React.MutableRefObject<null>;
  prepareToPrint?: boolean;
}

const ProductionPreparation = (props: Props): React.ReactElement => {
  const { production, componentRef, prepareToPrint } = props;

  const { t } = useTranslation();
  const [productPreparation, setProductPreparation] =
    React.useState<ProductPreparation | null>(null);

  const getProductionPreparation = async () => {
    if (production) {
      const response = await getProductPreparation(
        production.sku,
        production.locationId
      );
      setProductPreparation(response);
    }
  };

  React.useEffect(() => {
    setProductPreparation(null);
    getProductionPreparation();
  }, [production]);

  const listBoxSx = prepareToPrint ? {} : { maxHeight: 248, overflowY: "auto" };

  return (
    <>
      {productPreparation ? (
        <Box ref={componentRef}>
          {prepareToPrint && (
            <Typography
              fontWeight={700}
              component="div"
              bgcolor="#eeeeee"
              color="primary.main"
              width="100%"
              pl={1}
              mb={1}
            >
              {production.name}
            </Typography>
          )}
          <Typography
            fontWeight={700}
            component="div"
            bgcolor="#eeeeee"
            color="primary.main"
            width="100%"
            pl={1}
          >
            {t(productionPreparation.INSTRUCTIONS_FOR_PREPARATION)}:
          </Typography>
          {!productPreparation?.preparation?.length && (
            <Box p={2}>
              <Alert severity="warning">
                {t(productionPreparation.NO_PREPARATION_INSTRUCTIONS_FOUND)}
              </Alert>
            </Box>
          )}
          <Box sx={listBoxSx}>
            <List dense>
              {productPreparation?.preparation?.map(
                (line: string, i: number) => (
                  <ListItem
                    key={line}
                    sx={{
                      px: 1,
                      mb: 1,
                      bgcolor: i % 2 === 0 ? "background.paper" : "#eeeeee",
                      display: "flex",
                      alignContent: "center",
                      gap: 1,
                    }}
                  >
                    <Box
                      width={24}
                      height={24}
                      borderRadius={16}
                      px={1}
                      py={0.5}
                      bgcolor="info.main"
                      display="flex"
                      alignContent="center"
                      justifyContent="center"
                    >
                      <Typography variant="h6" component="span" color="white">
                        {i + 1}
                      </Typography>
                    </Box>
                    <Typography component="span">
                      {line?.replace(/^\d{0,2}\. /gm, "")}
                    </Typography>
                  </ListItem>
                )
              )}
            </List>
          </Box>
        </Box>
      ) : (
        <Box width="100%" display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default ProductionPreparation;
