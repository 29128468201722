import { Chip } from "@mui/material";
import { vendor } from "app/i18n/types";
import { useTranslation } from "react-i18next";
import { ValidationItemResponse } from "./utils";

interface Props {
  warns: ValidationItemResponse[];
}

const VendorSupplyPriceRangeChipAlert = (props: Props) => {
  const { warns } = props;
  const { t } = useTranslation();

  return (
    <Chip
      label={t(vendor.SUPPLY_VALIDATOR_CHIP_LABEL, {
        number: warns.length,
      })}
      color="warning"
      variant="outlined"
    />
  );
};

export default VendorSupplyPriceRangeChipAlert;
