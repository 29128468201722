import { UseCase } from "core/common/interfaces/useCase";
import { canGetProductionsCatalogByKitchen } from "core/productions/interfaces/canGetProductionsCatalogByKitchen";

export class GetProductionsCatalogByKitchen implements UseCase {
  constructor(private readonly repository: canGetProductionsCatalogByKitchen) {}

  public execute(kitchenId: string) {
    return this.repository.getProductionsCatalogByKitchen(kitchenId);
  }
}
