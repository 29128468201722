import { UseCase } from "core/common/interfaces/useCase";
import { CanGetComparisonByKitchen } from "../interfaces/canGetComparisonByKitchen";

export class GetComparisonByKitchenUseCase implements UseCase {
  constructor(private readonly repository: CanGetComparisonByKitchen) {}

  public execute(kitchenId: string) {
    return this.repository.getComparisonByKitchen(kitchenId);
  }
}
