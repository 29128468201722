import { UseCase } from "core/common/interfaces/useCase";
import { canFinishProduction } from "../interfaces/canFinishProduction";

export class FinishProduction implements UseCase {
  constructor(private readonly repository: canFinishProduction) {}

  public execute(
    productionId: string,
    producedQuantity: number,
    comment: string
  ) {
    return this.repository.finishProduction(
      productionId,
      producedQuantity,
      comment
    );
  }
}
