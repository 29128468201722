import { AssignmentLateOutlined } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PageHeader from "app/components/PageHeader";
import ProductionSupplyReceptionTable from "app/components/Productions/Supply/Receipt/ProductionSupplyReceptionTable";
import NumberField from "app/components/common/Field/Number";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useNavigator } from "app/hooks/useNavigator";
import { supplyReception } from "app/i18n/types";
import { getPackedByKitchenId } from "app/store/slices/productions/Supply/thunks";
import { ProductionSupplyResponse } from "core/productions/entities/Supply";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageBox } from "utils/generalUI";

interface ProductionSupplyReceptionProps {}

const ProductionSupplyReception: FunctionComponent<
  ProductionSupplyReceptionProps
> = () => {
  const navigator = useNavigator();
  const { kitchenId } = navigator.params();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [productionSupplies, setProductionSupplies] = useState<
    ProductionSupplyResponse[]
  >([]);

  const [search, setSearch] = useState<number>();
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    if (kitchenId) {
      const response = await dispatch(getPackedByKitchenId({ kitchenId }));
      const data = response.payload as ProductionSupplyResponse[];
      setProductionSupplies(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [kitchenId]);

  return (
    <PageBox>
      <PageHeader title={t(supplyReception.TITLE)} />
      <Grid container spacing={2} mb={2}>
        <Grid item sm={8}>
          <Paper
            sx={{ display: "flex", p: 2, justifyContent: "space-between" }}
            elevation={0}
          >
            <Box display="flex" alignItems="center" gap={2}>
              <AssignmentLateOutlined />
              <Box>
                <Typography color="text.secondary">
                  {t(supplyReception.PACKED_REQUISITION_LABEL)}
                </Typography>
                <Typography fontWeight={700}>
                  {productionSupplies.length}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item sm={4}>
          <Paper sx={{ p: 2 }} elevation={0}>
            <NumberField
              label={t(supplyReception.REQUISITION_NUMBER_LABEL)}
              fullWidth
              onChangeValue={(newValue) => setSearch(newValue)}
            />
          </Paper>
        </Grid>
      </Grid>
      {loading && <LinearProgress />}
      {!loading && (
        <ProductionSupplyReceptionTable
          productionSupplies={productionSupplies}
          kitchenId={kitchenId}
          search={search}
        />
      )}
    </PageBox>
  );
};

export default ProductionSupplyReception;
