import { AppConfig } from "config/app";
import {
  DetailToSave,
  MissingReason,
  ProductionSupplyDetail,
  ProductionSupplyResponse,
  ProductionSupplyWithDetails,
  ProductionSupplyWithDetailsPayload,
} from "core/productions/entities/Supply";
import { ApiVersion, getHttp, postHttp } from "utils/http";

export class ProductionSupplyHTTPRepository {
  constructor(private readonly config: AppConfig) {}

  public async getWithDetails(
    supplyId: string
  ): Promise<ProductionSupplyWithDetails> {
    try {
      const response = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `production/supply/${supplyId}`
      );
      const responseJSON: ProductionSupplyWithDetails = await response.json();
      return responseJSON;
    } catch (err) {
      console.error("getWithDetails error", err);
      throw err;
    }
  }

  public async getForPackagingByKitchenId(
    kitchenId: string
  ): Promise<ProductionSupplyResponse[]> {
    try {
      const response = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `production/supply/${kitchenId}/for-packaging`
      );
      const responseJSON: ProductionSupplyResponse[] = await response.json();
      return responseJSON;
    } catch (err) {
      console.error("getForPackagingByKitchenId error", err);
      throw err;
    }
  }

  public async getDetail(supplyId: string): Promise<ProductionSupplyDetail[]> {
    try {
      const response = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `production/supply/detail/${supplyId}`
      );
      const responseJSON: ProductionSupplyDetail[] = await response.json();
      return responseJSON;
    } catch (err) {
      console.error("getDetail error", err);
      throw err;
    }
  }

  public async getPackedByKitchenId(
    kitchenId: string
  ): Promise<ProductionSupplyResponse[]> {
    try {
      const response = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `production/supply/${kitchenId}/packed`
      );
      const responseJSON: ProductionSupplyResponse[] = await response.json();
      return responseJSON;
    } catch (err) {
      console.error("getPakcedByKitchenId error", err);
      throw err;
    }
  }

  public async updatePackedQuantity(
    id: number,
    packedQuantity: number
  ): Promise<boolean> {
    try {
      const response = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `production/supply/update/packed-quantity`,
        { id, packedQuantity }
      );
      return (await response.text()) === "true";
    } catch (err) {
      console.error("updatePackedQuantity error", err);
      throw err;
    }
  }

  public async reportNovelty(
    supplyDetailId: number,
    missingReason: MissingReason,
    receivedQuantity: number
  ): Promise<boolean> {
    try {
      const response = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `production/supply/detail/report-novelty`,
        { supplyDetailId, missingReason, receivedQuantity }
      );
      return (await response.text()) === "true";
    } catch (err) {
      console.error("reportnovelty error", err);
      throw err;
    }
  }

  public async complete(productionSupplyId: number): Promise<boolean> {
    try {
      const response = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `production/supply/packaging/completed`,
        { productionSupplyId }
      );
      return (await response.text()) === "true";
    } catch (err) {
      console.error("complete error", err);
      throw err;
    }
  }

  public async receptionCompleted(
    productionSupplyId: number
  ): Promise<boolean> {
    try {
      const response = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `production/supply/reception/completed`,
        { productionSupplyId }
      );
      return (await response.text()) === "true";
    } catch (err) {
      console.error("complete error", err);
      throw err;
    }
  }

  public async saveDetail(data: DetailToSave): Promise<boolean> {
    try {
      const response = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `production/supply/add-extra-detail`,
        data
      );
      return (await response.text()) === "true";
    } catch (err) {
      console.error("complete error", err);
      throw err;
    }
  }

  public async create(
    data: ProductionSupplyWithDetailsPayload
  ): Promise<boolean> {
    try {
      const response = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `production/supply`,
        { ...data }
      );
      return (await response.text()) === "true";
    } catch (err) {
      console.error("complete error", err);
      throw err;
    }
  }
}
