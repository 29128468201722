import { Grid, TextField } from "@mui/material";
import { commons } from "app/i18n/types";
import {
  CountingType,
  MassiveData,
} from "core/physicalCount/entities/Counting";
import { Supply } from "core/supplies/entities/Supply";
import { FunctionComponent, memo } from "react";
import { useTranslation } from "react-i18next";
import {
  CountingTypeCode,
  PhysicalCountScheduleLocation,
  getIsRecountOrMassive,
} from "utils/general";
import CountingTypeStep from "./Step/CountingType";
import DateStep from "./Step/Date";
import FinalStep from "./Step/FinalStep";
import ImportStep from "./Step/ImportStep";
import LocationStep from "./Step/Location";
import SupplyStep from "./Step/Supply";

interface StepBodyProps {
  selectedCountingType: CountingType | null;
  setSelectedCountingType: (selectedCountingType: CountingType | null) => void;
  locations: PhysicalCountScheduleLocation[];
  setLocations: (locations: PhysicalCountScheduleLocation[]) => void;
  dates: string[];
  setDates: (dates: string[]) => void;
  selectedSupplies: Supply[];
  setSelectedSupplies: (selectedSupplies: Supply[]) => void;
  massiveData: MassiveData;
  setMassiveData: (massiveData: MassiveData) => void;
  index: number;
  setName: (name: string) => void;
  name: string;
}

const StepBody: FunctionComponent<StepBodyProps> = (props) => {
  const {
    selectedCountingType,
    setSelectedCountingType,
    locations,
    setLocations,
    dates,
    setDates,
    selectedSupplies,
    setSelectedSupplies,
    massiveData,
    setMassiveData,
    name,
    setName,
    index,
  } = props;

  const isReCountOrDaily = getIsRecountOrMassive(selectedCountingType);

  const { t } = useTranslation();

  switch (index) {
    case 0:
      return (
        <Grid container spacing={2}>
          <Grid item sm={4} xs={12}>
            <CountingTypeStep
              selectedCountingType={selectedCountingType}
              setSelectedCountingType={setSelectedCountingType}
              isForCreate
              isRefined
            />
          </Grid>
          {(selectedCountingType?.code === CountingTypeCode.other_individual ||
            selectedCountingType?.code === CountingTypeCode.other_massive) && (
            <Grid item sm={4} xs={12}>
              <TextField
                onChange={(e) => setName(e.target.value)}
                value={name}
                fullWidth
                label={t(commons.NAME)}
                autoComplete="off"
              />
            </Grid>
          )}
        </Grid>
      );
    case 1:
      return isReCountOrDaily ? (
        <ImportStep massiveData={massiveData} setMassiveData={setMassiveData} />
      ) : (
        <LocationStep locations={locations} setLocations={setLocations} />
      );
    case 2:
      return <DateStep dates={dates} setDates={setDates} />;
    case 3:
      return (
        <SupplyStep
          locations={locations}
          selectedSupplies={selectedSupplies}
          setSelectedSupplies={setSelectedSupplies}
        />
      );
    default:
      return (
        <>
          {selectedCountingType && (
            <FinalStep
              selectedCountingType={selectedCountingType}
              locations={locations}
              massiveData={massiveData}
              dates={dates}
              selectedSupplies={selectedSupplies}
              name={name}
            />
          )}
        </>
      );
  }
};

export default memo(StepBody);
