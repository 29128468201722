import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PendingIcon from "@mui/icons-material/Pending";
import Chip from "@mui/material/Chip";
import {
  Purchase,
  PurchaseOrderStatus,
} from "core/purchaseOrders/entities/PurchaseOrder";
import { useTranslation } from "react-i18next";
import { ColorType } from "utils/generalUI";

interface Props {
  purchase: Purchase;
}

const PurchaseOrderStatusChip = (props: Props): React.ReactElement => {
  const { purchase } = props;

  const { t } = useTranslation();

  const chipProps = {
    icon: <NotificationsIcon />,
    color: "error",
  };

  switch (purchase.status) {
    case PurchaseOrderStatus.PENDING:
    case PurchaseOrderStatus.RECEIVED:
    case PurchaseOrderStatus.WITHOUT_INVOICE:
      chipProps.icon = <PendingIcon />;
      chipProps.color = "info";
      break;
    case PurchaseOrderStatus.COMPLETE:
    case PurchaseOrderStatus.VALIDATED:
    case PurchaseOrderStatus.CORRECTED:
    case PurchaseOrderStatus.PRE_VALIDATED:
      chipProps.icon = <CheckCircleIcon />;
      chipProps.color = "success";
      break;
    case PurchaseOrderStatus.PARTIAL:
      chipProps.color = "warning";
      break;
    default:
      break;
  }

  return (
    <Chip
      icon={chipProps.icon}
      label={t(`commons.${purchase.status}`)}
      color={chipProps.color as ColorType}
      variant="filled"
      sx={{ fontWeight: "bold" }}
    />
  );
};

export default PurchaseOrderStatusChip;
