import PendingActionsOutlinedIcon from "@mui/icons-material/PendingActionsOutlined";
import { Box, Grid } from "@mui/material";
import StatisticCard from "app/components/StatisticCard/StatisticCard";
import { commons } from "app/i18n/types";
import {
  MovementType,
  RegisterOption,
  registerOptions,
} from "config/dailyAdjustment";
import { AdjustmentRecord } from "core/dailyAdjustments/entities/Adjustment";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface DailyAdjusmentsSummaryProps {
  items: AdjustmentRecord[];
  selectedOption: RegisterOption;
  setSelectedOption: (option: RegisterOption) => void;
}

const DailyAdjusmentsSummary: FunctionComponent<DailyAdjusmentsSummaryProps> = (
  props
) => {
  const { t } = useTranslation();
  const { items, selectedOption, setSelectedOption } = props;

  const getCount = (type: MovementType): string => {
    return items
      .filter((el) => el.typeLoss === type.toUpperCase())
      .length.toString();
  };

  return (
    <Grid item xs={12}>
      <Box display={"flex"} mb={2} gap={2} flexWrap={"wrap"}>
        {registerOptions.map((option) => (
          <StatisticCard
            key={`StatisticCard-DailyAdjusmentsSummary-${option.type}`}
            icon={PendingActionsOutlinedIcon}
            label={t(`adjustmentType.${option.type}`)}
            value={getCount(option.type)}
            count={t(commons.TO_AUTHORIZE)}
            color="primary"
            selected={selectedOption.type === option.type}
            onClick={() => setSelectedOption(option)}
          />
        ))}
      </Box>
    </Grid>
  );
};

export default DailyAdjusmentsSummary;
