import Close from "@mui/icons-material/Close";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import WarningAmber from "@mui/icons-material/WarningAmber";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { commons, purchaseOrderCreation } from "app/i18n/types";
import { PurchaseOrder } from "core/purchaseOrders/entities/PurchaseOrder";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface DeleteLinesModalProps {
  open: boolean;
  checkedLines: Array<number>;
  purchaseOrder: PurchaseOrder;
  onClose: () => void;
  handleDeleteSelectedLines: () => void;
}

const DeleteLinesModal: FunctionComponent<DeleteLinesModalProps> = (props) => {
  const {
    open,
    checkedLines,
    purchaseOrder,
    onClose,
    handleDeleteSelectedLines,
  } = props;

  const { t } = useTranslation();

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Paper
          sx={{
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            gap: 1,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" color="primary">
              {t(purchaseOrderCreation.DELETE_LINE_MODAL_TITLE)}
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
          <Typography>
            {t(purchaseOrderCreation.DELETE_LINE_MODAL_SUBTITLE)}
          </Typography>
          <List>
            {checkedLines.map((lineId) => {
              const line = purchaseOrder.lines.find(
                (line: { id: number }) => line.id === lineId
              );
              return (
                <Typography key={lineId} variant="body1" fontWeight="bold">
                  {" "}
                  {line?.productName}
                </Typography>
              );
            })}
          </List>
          <Box display="flex" justifyContent="center">
            <WarningAmber color="warning" sx={{ fontSize: 96 }} />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Button
              startIcon={<Close />}
              variant="outlined"
              color="primary"
              onClick={onClose}
            >
              {t(commons.CANCEL)}
            </Button>
            <Button
              startIcon={<DeleteOutline />}
              variant="contained"
              color="error"
              onClick={handleDeleteSelectedLines}
            >
              {t(commons.DELETE)}
            </Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default DeleteLinesModal;
