import { AppConfig } from "config/app";
import {
  AdjustmentPayload,
  AdjustmentSendResponse,
  SummaryAdjustmentResponse,
} from "core/adjustments/entities/Adjusment";
import { CanGetSummaryAdjustments } from "core/adjustments/interfaces/adjustment/canGetSummaryAdjustments";
import { CanSendAdjustments } from "core/adjustments/interfaces/adjustment/canSendAdjustments";
import { ApiVersion, getHttp, postHttp } from "utils/http";

export class AdjustmentsHTTPRepository
  implements CanSendAdjustments, CanGetSummaryAdjustments
{
  constructor(private readonly config: AppConfig) {}

  public async sendAdjustments(
    fileName: string,
    adjustments: Array<AdjustmentPayload>
  ): Promise<AdjustmentSendResponse> {
    const response = await postHttp(
      this.config.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `adjustments`,
      {
        fileName: fileName,
        adjustments: adjustments,
      }
    );
    const responseJSON: AdjustmentSendResponse = await response.json();
    return responseJSON;
  }

  public async getSummaryAdjustments(): Promise<
    Array<SummaryAdjustmentResponse>
  > {
    const response = await getHttp(
      this.config.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `adjustments/summary`
    );
    const responseJSON: SummaryAdjustmentResponse[] = await response.json();
    return responseJSON;
  }
}
