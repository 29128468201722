import { VendorManagerDialog } from "app/components/Vendor/Manager";
import VendorTable from "app/components/Vendor/Table";
import SearchOnTopBar from "app/components/common/SearchOnTopBar";
import { Vendor, VendorWithSuppliesCount } from "core/vendors/entities/Vendor";
import { findWithSuppliesCountByCountry } from "core/vendors/repositories/http/vendor";
import { useCallback, useEffect, useState } from "react";
import { compareSearchText } from "utils/general";
import { Actions, VendorModal } from "utils/modal";

interface Props {
  selectedCountry: string;
  openCreate: boolean;
  onCloseCreate: () => void;
}

const ByVendorsView = (props: Props) => {
  const { selectedCountry, openCreate, onCloseCreate } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<VendorWithSuppliesCount[]>([]);
  const [dataFiltered, setDataFiltered] = useState<VendorWithSuppliesCount[]>(
    []
  );
  const [search, setSearch] = useState<string>("");
  const [modal, setModal] = useState<VendorModal>({ open: false });

  useEffect(() => {
    if (openCreate) {
      setModal({ open: true, action: Actions.add });
    } else {
      setModal({ open: false });
    }
  }, [openCreate]);

  useEffect(() => {
    if (selectedCountry) {
      setLoading(true);
      findWithSuppliesCountByCountry(selectedCountry)
        .then((vendors) => {
          setData(vendors);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selectedCountry]);

  useEffect(() => {
    setDataFiltered(
      data.filter((el) => {
        if (!search) return true;
        return (
          compareSearchText(el.externalCode, search) ||
          compareSearchText(el.name, search)
        );
      })
    );
  }, [data, search]);

  const onSuccess = useCallback(
    (result: Vendor) => {
      if (!data) return;
      if (modal.action === Actions.add) {
        setData([{ ...result, suppliesCount: 0 }, ...data]);
      }
      if (modal.action === Actions.edit) {
        setData(
          data.map((el) => {
            if (el.id === result.id)
              return { ...result, suppliesCount: el.suppliesCount };
            return el;
          })
        );
      }
    },
    [modal]
  );

  return (
    <>
      <SearchOnTopBar onSearch={setSearch} />
      <VendorTable
        items={selectedCountry ? dataFiltered : []}
        loading={loading}
        setModal={setModal}
      />
      {modal.open && (
        <VendorManagerDialog
          {...modal}
          country={selectedCountry}
          onClose={() => {
            setModal({ open: false });
            onCloseCreate();
          }}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
};

export default ByVendorsView;
