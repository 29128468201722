import { UseCase } from "core/common/interfaces/useCase";
import { ProductionLogSearchType } from "../entities/Productions";
import { canGetProductionLogByType } from "../interfaces/canGetProductionLogByType";

export class GetProductionLogByTypeUseCase implements UseCase {
  constructor(private readonly repository: canGetProductionLogByType) {}

  public execute(type: ProductionLogSearchType, searchTerm: string) {
    return this.repository.getProductionLogByType(type, searchTerm);
  }
}
