import appConfig from "config/app";
import { Product } from "core/dailyAdjustments/entities/Catalog";
import {
  Organoleptics,
  ProductionRecord,
} from "core/productions/entities/Productions";
import { ApiVersion, getHttp, postHttp } from "./http";

export enum SelectedProductionActionType {
  CREATE_LABEL = "CREATE_LABEL",
  LABEL_HISTORY = "LABEL_HISTORY",
  DRAWER_INFO = "DRAWER_INFO",
  LIBERATION_DIALOG = "LIBERATION_DIALOG",
  ORGANOLEPTIC_DIALOG = "ORGANOLEPTIC_DIALOG",
}

export type SelectedProductionAction = `${SelectedProductionActionType}`;

export interface SelectedProductionAndAction {
  production: ProductionRecord;
  action: SelectedProductionAction;
}

export interface ProductPreparation {
  sku: string;
  name: string;
  preparation: string[] | null;
}

export interface ProductionDetail {
  organoleptic: Organoleptics;
}

export const getProductPreparation = async (
  sku: string,
  kitchenId: string
): Promise<ProductPreparation | null> => {
  try {
    const response = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `product/preparation/${sku}/${kitchenId}`
    );
    const responseJSON: ProductPreparation = await response.json();
    return responseJSON;
  } catch (ex: any) {
    console.error(`Error getProductPreparation | ${ex.message}`);
    return null;
  }
};

export const getFinishedProductsByCountry = async (
  country: string
): Promise<Product[]> => {
  try {
    const response = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `products/finished/country/${country}`
    );
    const responseJSON: Product[] = await response.json();
    return responseJSON;
  } catch (ex: any) {
    console.error(`Error getFinishedProductsByContry | ${ex.message}`);
    return [];
  }
};

export const getFinishedProductsByCountryAndSkus = async (
  country: string,
  skus: string[]
): Promise<Product[]> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `products/finished`,
      {
        country: country,
        skus: skus,
      }
    );
    const responseJSON: Product[] = await response.json();
    return responseJSON;
  } catch (ex: any) {
    console.error(`Error getFinishedProductsByCountryAndSkus | ${ex.message}`);
    return [];
  }
};

export const preApprroveDailyAdjustment = async (adjustment: {
  id: number;
  quantity: number;
  sku: string;
  type: string;
  kitchenId: string;
  ingredients: string[];
}) => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `daily-adjustments/pre-approve`,
      adjustment
    );
    const responseJSON = await response.json();
    return responseJSON;
  } catch (ex: any) {
    console.error(`Error preApprroveDailyAdjustment | ${ex.message}`);
    return [];
  }
};

export const getProductionDetail = async (sku: string, country: string) => {
  try {
    const response = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `product/production-detail/organoleptics/${country}/${sku}`
    );
    const responseJSON: Organoleptics = await response.json();
    return responseJSON;
  } catch (ex: any) {
    console.error(`Error getProductionDetail | ${ex.message}`);
    return null;
  }
};
