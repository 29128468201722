import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import { FunctionComponent } from "react";

interface CountrySelectorProps {
  countries: Array<string> | null;
  value: string | null;
  onChange: (selectedCountry: string) => void;
  disabled?: boolean;
}

const CountrySelector: FunctionComponent<CountrySelectorProps> = (props) => {
  const { countries, value, onChange, disabled } = props;

  return (
    <Box>
      {countries &&
        countries.map((country) => (
          <Checkbox
            key={country}
            onClick={() => onChange(country)}
            disabled={disabled}
            icon={
              <Avatar
                sx={{ width: 40, height: 40, p: 0.75 }}
                src={`/assets/images/country/${country}.svg`}
              />
            }
            sx={{ opacity: disabled ? 0.75 : 1 }}
            checked={country === value}
            checkedIcon={
              <Avatar
                sx={{
                  width: 40,
                  height: 40,
                  bgcolor: "#011A5B",
                  p: 0.75,
                }}
                src={`/assets/images/country/${country}.svg`}
              />
            }
          />
        ))}
    </Box>
  );
};

export default CountrySelector;
