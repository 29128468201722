import { UseCase } from "core/common/interfaces/useCase";
import { CanGetListProducts } from "core/dailyAdjustments/interfaces/product/canGetListProducts";

export class GetListProductsUseCase implements UseCase {
  constructor(private readonly repository: CanGetListProducts) {}

  public execute(kitchenId: string) {
    return this.repository.getListProducts(kitchenId);
  }
}
