import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typograhpy from "@mui/material/Typography";
import { productionManagement } from "app/i18n/types";
import { ProductionRecord } from "core/productions/entities/Productions";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { getUserImage } from "utils/general";

interface Props {
  production: ProductionRecord;
  compact?: boolean;
}

const ProductionCardResponsible: FunctionComponent<Props> = (props) => {
  const { production, compact } = props;
  const { t } = useTranslation();

  const sxBox = compact
    ? {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
      }
    : {};

  return (
    <>
      {production.productionManager && (
        <Box sx={sxBox}>
          <Typograhpy variant="caption" color="text.secondary">
            {t(productionManagement.RESPONSIBLE)}
          </Typograhpy>
          <Box
            display="flex"
            gap={1}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Avatar
              src={getUserImage(production.productionManager)}
              alt={production.productionManagerName}
              sx={{ width: 32, height: 32, my: 0.5 }}
            />
            <Box width={compact ? 80 : 248}>
              <Typograhpy
                sx={{
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {production.productionManagerName}
              </Typograhpy>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
export default ProductionCardResponsible;
