import { UseCase } from "core/common/interfaces/useCase";
import { CanExportInventory } from "../interfaces/canGetExportInventory";

export class ExportInventoryUseCase implements UseCase {
  constructor(private readonly repository: CanExportInventory) {}

  public execute(country: string) {
    return this.repository.exportInventory(country);
  }
}
