import {
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSEmptyState,
  KOSRowData,
} from "@foodology-co/alejandria";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ListAltIcon from "@mui/icons-material/ListAlt";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { useAppSelector } from "app/hooks/useAppSelector";
import { useNavigator } from "app/hooks/useNavigator";
import { commons } from "app/i18n/types";
import { localizeDayjs } from "app/utils/dayjs";
import { TheoreticalInventory } from "core/theoricalInventory/entities/ThereticalInventory";
import { FunctionComponent, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { compareSearchText } from "utils/general";
import { TypeOfRole } from "utils/role";

interface TheoreticalInventoryTableProps {
  items: TheoreticalInventory[] | null;
  kitchenId: string;
  loading: boolean;
  searchTerm: string | null;
}

const TheoreticalInventoryTable: FunctionComponent<
  TheoreticalInventoryTableProps
> = (props) => {
  const navigator = useNavigator();
  const { items, kitchenId, loading, searchTerm } = props;

  const { t, i18n } = useTranslation();

  const user = useAppSelector((state) => state.session.user);

  const filter = useCallback(
    (row: TheoreticalInventory) => {
      if (!searchTerm) return true;
      return (
        compareSearchText(row.productName, searchTerm) ||
        compareSearchText(row.sku, searchTerm)
      );
    },
    [searchTerm]
  );

  const getColor = useCallback(
    (quantity: number): "warning" | "error" | "success" => {
      if (quantity === 0) {
        return "warning";
      }

      if (quantity < 0) {
        return "error";
      }

      return "success";
    },
    []
  );

  useEffect(() => {
    localizeDayjs(i18n.language);
  }, [i18n.language]);

  const getHeader = (): KOSBaseTableHeader[] => {
    const noAccess = [TypeOfRole.JEFE_CP, TypeOfRole.SEGUNDO_CP];
    const role = (user.data?.role ?? "") as TypeOfRole;
    const showQuantity = !noAccess.includes(role);

    return [
      {
        label: t(commons.SKU),
        field: "sku",
      },
      {
        label: t(commons.NAME),
        field: "productName",
      },
      {
        label: t(commons.GROUP),
        field: "group",
      },
      {
        label: t(commons.QUANTITY),
        text: (item: KOSRowData) =>
          showQuantity ? `${item.quantity} ${item.measureUnit}` : `--`,
        align: "right",
        weight: "bold",
        color: (item: KOSRowData) =>
          showQuantity ? `${getColor(Number(item.quantity))}.main` : undefined,
      },
      {
        label: t(commons.DETAILS),
        align: "center",
        component: (item: KOSRowData) => (
          <IconButton
            color={`${getColor(Number(item.quantity))}`}
            aria-label="detail"
            onClick={() =>
              navigator.toByLocationType(
                `/theorical-inventory/${kitchenId}/${item.sku}`
              )
            }
          >
            <ListAltIcon />
          </IconButton>
        ),
      },
    ];
  };

  const itemsFiltered = items?.filter(filter) ?? [];

  return (
    <Paper>
      <KOSBaseTable
        columns={getHeader()}
        rows={{
          data: itemsFiltered,
          total: itemsFiltered.length,
          loading,
        }}
        pagination={{
          enable: true,
        }}
        emptyState={
          <KOSEmptyState
            icon={InfoOutlinedIcon}
            message={t(commons.TABLE_EMPTY)}
          />
        }
      />
    </Paper>
  );
};

export default TheoreticalInventoryTable;
