import { KOSBaseTableHeader } from "@foodology-co/alejandria";
import { commons } from "app/i18n/types";
import { TFunction } from "i18next";

export const textHeader = (
  t: TFunction,
  label: string,
  field: string
): KOSBaseTableHeader => ({
  label: t(label),
  field,
});

export const dateUtcHeader = (
  t: TFunction,
  label: string,
  field: string
): KOSBaseTableHeader => ({
  label: t(label),
  field,
  type: "datetime_utc",
});

export const getPurchaseErrorHeader = (t: TFunction): KOSBaseTableHeader[] => {
  return [
    textHeader(t, commons.COUNTRY, "country"),
    textHeader(t, commons.LOCATION, "kitchenId"),
    textHeader(t, commons.CODE, "purchaseCode"),
    dateUtcHeader(t, commons.DATE, "createdAt"),
    textHeader(t, commons.STATUS, "status"),
    textHeader(t, commons.ERROR, "error"),
  ];
};
