import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import TagOutlinedIcon from "@mui/icons-material/TagOutlined";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { commons, vendor } from "app/i18n/types";
import { getCityName } from "app/utils/city.utils";
import { getCountryName } from "core/common/entities/Country";
import { VendorJoinWithSupply } from "core/vendors/entities/Vendor";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { formatPrice, localeData } from "utils/currency";

interface Props {
  data: VendorJoinWithSupply;
  vendorCount: number;
  vendorUnitPriceMin: number;
  supplyUnitPriceMin: number;
}

const VendorBySkuAndCityResume: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const { data, vendorCount, vendorUnitPriceMin, supplyUnitPriceMin } = props;

  return (
    <Grid item xs={12}>
      <Paper sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6">{data.supply.supplyName}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            mb: 1,
            gap: 1,
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <TagOutlinedIcon />
            <Box>
              <Typography>{t(commons.SKU)}</Typography>
              <Typography sx={{ fontWeight: "bold" }}>
                {data.supply.supplySku}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <LocationOnOutlinedIcon />
            <Box>
              <Typography>{t(commons.COUNTRY)}</Typography>
              <Typography sx={{ fontWeight: "bold" }}>
                {getCountryName(data.vendor.country)}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <LocationOnOutlinedIcon />
            <Box>
              <Typography>{t(commons.CITY)}</Typography>
              <Typography sx={{ fontWeight: "bold" }}>
                {getCityName(data.supply.city)}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <HomeWorkOutlinedIcon />
            <Box>
              <Typography>{t(vendor.VENDORS_QUANTITY)}</Typography>
              <Typography sx={{ fontWeight: "bold" }}>{vendorCount}</Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <PaymentsOutlinedIcon />
            <Box>
              <Typography>
                {`${t(commons.BETTER)} ${t(vendor.VENDOR_UNIT_PRICE)}`}
              </Typography>
              <Typography sx={{ fontWeight: "bold" }}>
                {formatPrice(
                  vendorUnitPriceMin,
                  localeData[data.vendor.country]
                )}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <PaymentsOutlinedIcon />
            <Box>
              <Typography>
                {`${t(commons.BETTER)} ${t(vendor.SUPPLY_UNIT_PRICE)}`}
              </Typography>
              <Typography sx={{ fontWeight: "bold" }}>
                {formatPrice(
                  supplyUnitPriceMin,
                  localeData[data.vendor.country],
                  4
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

export default VendorBySkuAndCityResume;
