import { UseCase } from "core/common/interfaces/useCase";
import { canCreateProductionlabel } from "../interfaces/canCreateProductionLabel";

export class CreateProductionLabelUseCase implements UseCase {
  constructor(private readonly repository: canCreateProductionlabel) {}

  public execute(
    productionId: string,
    quantity: number,
    quantityOfPacking: number
  ) {
    return this.repository.createProductionLabels(
      productionId,
      quantity,
      quantityOfPacking
    );
  }
}
