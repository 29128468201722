import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { forwardRef } from "react";

import Divider from "@mui/material/Divider";
import { commons, measureUnits, productionManagement } from "app/i18n/types";
import { getSupplyQuantity, measureUnitsConvert } from "app/utils/measureUnits";
import { ProductionRecord } from "core/productions/entities/Productions";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { DividerPrint, print80mm } from "utils/generalUI";

interface Props {
  production: ProductionRecord;
}

const ProductionIngredientsPrintTemplate = forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { production } = props;

    const { t } = useTranslation();

    return (
      <Box sx={print80mm} ref={ref}>
        <Box className="printable">
          <Box sx={{ textAlign: "center", mt: 2, mb: 1 }}>
            <img
              src="https://images-kos.s3.amazonaws.com/logo_Foodology/logo-foodology.png"
              alt="Foodology SAS1"
              width="50%"
            />
          </Box>
          <Box sx={{ textAlign: "center", textTransform: "uppercase" }}>
            <Typography
              textAlign="center"
              fontWeight={700}
              variant="caption"
              display="block"
            >
              {t(productionManagement.PPRODUCTION_ORDER)}
            </Typography>

            <Typography textAlign="center" variant="caption" display="block">
              {production.locationId}
            </Typography>

            <Typography
              textAlign="center"
              variant="caption"
              sx={{ mb: 3 }}
              display="block"
            >
              {t(commons.PRODUCTION).toUpperCase()}:{" "}
              {String(production.id).padStart(13, "0")}
            </Typography>

            <Typography
              textAlign="center"
              textTransform="uppercase"
              fontWeight={700}
              variant="caption"
              display="block"
            >
              {t(commons.DATE).toUpperCase()}: {dayjs().format("DD/MM/YYYY")}
            </Typography>

            <Typography
              textAlign="center"
              textTransform="uppercase"
              fontWeight={700}
              variant="caption"
              display="block"
            >
              {t(commons.HOUR).toUpperCase()}: {dayjs().format("hh:mm:ss A")}
            </Typography>

            <DividerPrint />

            <Typography textAlign="center" fontWeight={700}>
              {production.name}
            </Typography>

            <Typography textAlign="center">
              {" "}
              {t(commons.PRODUCTION_QUANTITY)}:
            </Typography>

            <Typography textAlign="center" fontWeight={700}>
              {production.quantityToProduce} {production.unit}
            </Typography>

            <DividerPrint />

            {production.productionDetails.map((productionDetail) => (
              <Box
                key={productionDetail.id}
                display="flex"
                gap={1}
                alignItems="center"
                mb={1}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-around"
                >
                  <Typography
                    textTransform="uppercase"
                    textAlign="left"
                    variant="caption"
                  >
                    {t(commons.SKU).toUpperCase()}:{" "}
                    <strong>{productionDetail.sku}</strong>
                  </Typography>
                  <Typography
                    textTransform="uppercase"
                    textAlign="left"
                    variant="caption"
                  >
                    {productionDetail.name}
                  </Typography>
                </Box>

                <Divider sx={{ my: 1, flexGrow: 1, borderStyle: "dotted" }} />

                <Box
                  flexBasis={80}
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-around"
                  alignItems="center"
                  // bgcolor="#f8f8f8"
                  borderRadius={1}
                  p={0.25}
                >
                  <Typography
                    textTransform="uppercase"
                    fontWeight={700}
                    variant="caption"
                  >
                    {measureUnitsConvert(
                      getSupplyQuantity(
                        production.quantityToProduce,
                        productionDetail.quantity
                      ),
                      productionDetail.unit,
                      productionDetail.displayUnit ?? production.unit
                    )}{" "}
                    {t(
                      measureUnits[
                        (productionDetail.displayUnit as keyof typeof measureUnits) ??
                          (productionDetail.unit as keyof typeof measureUnits)
                      ]
                    )}{" "}
                  </Typography>

                  <Box
                    sx={{
                      borderBottom: "1px solid black",
                      height: 15,
                      width: 60,
                    }}
                  />
                </Box>
              </Box>
            ))}

            <Divider
              sx={{ mt: 1, mb: 2, borderStyle: "dashed", borderWidth: 2 }}
            />

            <Typography
              textAlign="center"
              fontWeight={700}
              textTransform="uppercase"
            >
              {t(productionManagement.GENERATED_QUANTITY)}
            </Typography>

            <Box
              sx={{
                borderBottom: "1px solid black",
                height: 30,
                width: 180,
                mx: "auto",
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  }
);

export default ProductionIngredientsPrintTemplate;
