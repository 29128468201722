import { UseCase } from "core/common/interfaces/useCase";
import { CanReceiveTransferOrders } from "core/transfer/interfaces/transfer/CanReceiveTransferOrders";

export class CanReceiveTransferOrderUseCase implements UseCase {
  constructor(private readonly repository: CanReceiveTransferOrders) {}

  public execute(
    transferId: number,
    haveExtraOrLeftover: boolean,
    comment?: string
  ) {
    return this.repository.receiveTransferOrder(
      transferId,
      haveExtraOrLeftover,
      comment
    );
  }
}
