import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import QrCodeScannerOutlinedIcon from "@mui/icons-material/QrCodeScannerOutlined";
import ScaleOutlinedIcon from "@mui/icons-material/ScaleOutlined";
import TrackChangesOutlinedIcon from "@mui/icons-material/TrackChangesOutlined";
import {
  Box,
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ProgressBar from "app/components/ProgressBar";
import {
  commons,
  productionDialog,
  productionManagement,
} from "app/i18n/types";
import { convertDate, getDaysOfDifference } from "app/utils/dateUtils";
import { ProductionLogSearchResponse } from "core/productions/entities/Productions";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

interface ProductionLogDetailProps {
  logDetail: ProductionLogSearchResponse;
}

const ProductionLogDetail: FunctionComponent<ProductionLogDetailProps> = (
  props
) => {
  const { logDetail } = props;
  const { t } = useTranslation();

  const [color, setColor] = useState<string>("");

  const sx = {
    display: "flex",
    gap: 1,
    alignItems: "flex-end",
  };
  const sxLeft = { ...sx, justifyContent: "flex-start" };
  const sxRight = { ...sx, justifyContent: "flex-end" };

  const nowStr = new Date().toString();

  const utilLife = getDaysOfDifference(
    logDetail.label?.expirationDate ?? nowStr,
    nowStr
  );

  const getPercentage = (): number => {
    if (utilLife >= 3 && utilLife <= 7) return 40;
    if (utilLife > 7) return 100;
    return 0;
  };

  return (
    <>
      <Paper
        sx={{
          backgroundColor: "#0288D1",
          p: 2,
          mt: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ ...sxLeft }}>
          <TrackChangesOutlinedIcon sx={{ color: "white" }} />
          <Typography variant="h6" sx={{ color: "white" }}>
            {t(productionManagement.LOG_DETECTED_MOVS)}
          </Typography>
        </Box>
        <Chip label={logDetail.logs.length} sx={{ backgroundColor: "white" }} />
      </Paper>
      <Paper sx={{ p: 2, width: "100%", mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} sx={{ ...sxLeft }}>
            <Inventory2OutlinedIcon />
            <Typography variant="h6">
              {t(commons.SKU).toUpperCase()}:
            </Typography>
            <Typography variant="body1">{logDetail.label?.sku}</Typography>
          </Grid>
          <Grid item xs={12} md={6} sx={{ ...sxRight }}>
            <CategoryOutlinedIcon />
            <Typography variant="h6">
              {t(productionDialog.NAME).toUpperCase()}:
            </Typography>
            <Typography variant="body1">{logDetail.label?.name}</Typography>
          </Grid>
          <Grid item xs={12} md={6} sx={{ ...sxLeft }}>
            <QrCodeScannerOutlinedIcon />
            <Typography variant="h6">
              {t(productionDialog.BATCH).toUpperCase()}:
            </Typography>
            <Typography variant="body1">{logDetail.label?.batch}</Typography>
          </Grid>
          <Grid item xs={12} md={6} sx={{ ...sxRight }}>
            <ScaleOutlinedIcon />
            <Typography variant="h6">
              {t(productionDialog.WEIGHT).toUpperCase()}:
            </Typography>
            <Typography variant="body1">
              {logDetail.label?.quantity} {logDetail.label?.unit}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} sx={{ ...sxLeft }}>
            <EventAvailableOutlinedIcon />
            <Typography variant="h6">
              {t(productionDialog.PRODUCTION_DATE).toUpperCase()}:
            </Typography>
            <Typography variant="body1">
              {logDetail.label?.productionDate}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} sx={{ ...sxRight }}>
            <EventBusyOutlinedIcon />
            <Typography variant="h6">
              {t(productionDialog.EXPIRATION_DATE).toUpperCase()}:
            </Typography>
            <Typography variant="body1">
              {logDetail.label?.expirationDate}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Chip
              icon={
                utilLife > 7 ? (
                  <CheckCircleOutlineOutlinedIcon
                    sx={{ color: "white !important" }}
                  />
                ) : (
                  <ErrorOutlineOutlinedIcon
                    sx={{ color: "white !important" }}
                  />
                )
              }
              label={t(
                utilLife < 0
                  ? productionManagement.LOG_INVALIDITY_LABEL
                  : productionManagement.LOG_VALIDITY_LABEL,
                {
                  days: (utilLife < 0 ? utilLife * -1 : utilLife).toFixed(0),
                }
              )}
              sx={{
                backgroundColor: color,
                color: "white",
                fontWeight: "bold",
              }}
            />
            <Box sx={{ width: "100%", pt: 0.5 }}>
              <ProgressBar
                percent={getPercentage()}
                setColor={setColor}
                compact
                hidePercentLabel
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <TableContainer component={Paper}>
              <Table sx={{ width: "100%" }}>
                <TableHead>
                  <TableRow>
                    <TableCell width={300}>
                      {t(commons.DATETIME).toUpperCase()}
                    </TableCell>
                    <TableCell>
                      {t(productionManagement.LOG_MOV).toUpperCase()}
                    </TableCell>
                    <TableCell>
                      {t(commons.SELECT_LOCATION).toUpperCase()}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {logDetail.logs.map((log) => (
                    <TableRow key={log.createdAt}>
                      <TableCell>
                        {convertDate(
                          log.createdAt,
                          "dd/MMM/yyyy hh:mm:ss aaaaa'm'"
                        )}
                      </TableCell>
                      <TableCell>
                        {t(`productionLogReason.${log.reason}`)}
                      </TableCell>
                      <TableCell>{log.kitchenName}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default ProductionLogDetail;
