import { ManagerDialog } from "app/components/common/Manager/Dialog";
import { commons } from "app/i18n/types";
import { useTranslation } from "react-i18next";
import { Actions } from "utils/modal";
import { BaseDialogProps } from "./constants";
import { HandleForm } from "./form";

export const VendorSupplyManagerDialog = (props: BaseDialogProps) => {
  const { onClose, action } = props;

  const { t } = useTranslation();

  const dialogTitle = {
    [Actions.add]: t(commons.ADD),
    [Actions.edit]: t(commons.MODIFY),
    [Actions.view]: t(commons.VENDOR_INFO),
  };
  return (
    <ManagerDialog
      size="md"
      title={dialogTitle[action ?? Actions.add]}
      onClose={onClose}
      content={<>{!!props.action && <HandleForm {...props} />}</>}
    />
  );
};
