import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { FunctionComponent, useState } from "react";

import {
  FileUpload,
  InfoOutlined,
  Inventory,
  Print,
} from "@mui/icons-material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import AddDocumentsDialog from "app/components/AddDocumentsDialog";
import PageHeader from "app/components/PageHeader";
import CommonDialog from "app/components/common/CommonDialog";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useNavigator } from "app/hooks/useNavigator";
import { commons, purchaseOrderModule, transferModule } from "app/i18n/types";
import { receiveTransferOrder } from "app/store/slices/transfer/thunks";
import { AttachmentFile, AttachmentTypeEnum } from "core/attachment/entity";
import { OriginType } from "core/purchaseOrders/entities/PurchaseOrder";
import { AttachmentsDetailResponse } from "core/purchaseOrders/entities/ReceiptOrder";
import {
  TransferLine,
  TransferResponse,
} from "core/transfer/entities/TransferOrder";
import { useTranslation } from "react-i18next";
import { sxModalIcon } from "utils/generalUI";
interface Props {
  transferOrder: TransferResponse;
  setOpenDeleteLabels: (openDeleteLabels: boolean) => void;
  kitchenId: string;
  initialAttachments: AttachmentFile[];
  setNewAttachments: (detail: AttachmentsDetailResponse | null) => void;
  handlePrint: () => void;
}

const FinishTransferOrderReceipt: FunctionComponent<Props> = (props) => {
  const {
    transferOrder,
    kitchenId,
    setOpenDeleteLabels,
    initialAttachments,
    setNewAttachments,
    handlePrint,
  } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigator = useNavigator();

  const [openAddDocuments, setOpenAddDocuments] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openResponseModal, setOpenResponseModal] = useState<boolean>(false);

  const [savingTransfer, setSavingTransfer] = useState<boolean>(false);
  const [haveExtraOrLeftover, setHaveExtraOrLeftover] =
    useState<boolean>(false);
  const [comment, setComment] = useState<string>();

  const isReadyToFinish = () => {
    return transferOrder?.lines?.every(
      (line) =>
        line.quantityToReceive === line.sendQuantity || line.returnReason
    );
  };

  const getPartialItems = (): TransferLine[] => {
    const partial = transferOrder?.lines?.filter(
      (line) =>
        line.status === "IN_PROCESS" &&
        line.sendQuantity > (line.quantityToReceive ?? 0)
    );
    return partial;
  };

  const onFinish = async () => {
    setSavingTransfer(true);
    try {
      await dispatch(
        receiveTransferOrder({
          transferOrderId: transferOrder.id,
          haveExtraOrLeftover,
          comment: haveExtraOrLeftover ? comment : undefined,
        })
      );
      setOpen(false);
      setOpenResponseModal(true);
    } catch (e) {
      console.error("Error in onFinish", e);
    } finally {
      setSavingTransfer(false);
    }
  };

  const handleConfirm = () => {
    setOpenResponseModal(false);
    navigator.toByLocationType(`/receipt-order/${kitchenId}`);
  };

  const isShowDeleteButton = !!transferOrder.lines?.reduce(
    (sum, line) => sum + line.labels.length,
    0
  );

  return (
    <>
      <PageHeader
        title={transferModule.RECEIPT}
        rightArea={[
          {
            variant: "outlined",
            color: "info",
            startIcon: <Print />,
            children: t(commons.PRINT),
            onClick: () => handlePrint(),
          },
          {
            variant: "outlined",
            color: "error",
            startIcon: <FactCheckOutlinedIcon />,
            children: t(transferModule.DELETE_LABELS),
            onClick: () => setOpenDeleteLabels(true),
            show: isShowDeleteButton,
          },
          {
            children: t(purchaseOrderModule.ADD_DOCUMENTS),
            variant: initialAttachments.length ? "contained" : "outlined",
            color: initialAttachments.length ? "success" : "primary",
            startIcon: <FileUpload />,
            sx: { height: "100%", borderRadius: 2 },
            onClick: () => setOpenAddDocuments(true),
          },

          {
            children: t(commons.FINISH),
            variant: "contained",
            onClick: () => setOpen(true),
            disabled: !isReadyToFinish(),
            startIcon: <FactCheckOutlinedIcon />,
          },
        ]}
      />

      {openAddDocuments && kitchenId && (
        <AddDocumentsDialog
          origin={OriginType.KIS}
          code={transferOrder.id.toString()}
          type={AttachmentTypeEnum.PURCHASE_ORDER}
          initialAttachments={initialAttachments}
          setDetail={setNewAttachments}
          kitchenId={kitchenId}
          onCloseDialog={() => setOpenAddDocuments(false)}
          noClose
        />
      )}

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        sx={{ zIndex: 1202 }}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle
          sx={{ p: 3 }}
          id="authorization-dialog-title"
          color="primary.dark"
          variant="h5"
          fontWeight={600}
        >
          {t(transferModule.RECEIPT_TITLE)}
        </DialogTitle>
        <DialogContent sx={{ px: 3, py: 0 }}>
          {savingTransfer && <LinearProgress sx={{ mb: 2 }} />}
          <Box display="flex" gap={2} alignItems="center">
            <Typography>{t(transferModule.RECEIPT_MESSAGE)}</Typography>
          </Box>
          <Box display="flex" gap={2} flexDirection="column" width="100%">
            {getPartialItems().length > 0 ? (
              <>
                <Typography sx={{ mt: 2 }}>
                  {t(transferModule.RETURN_MESSAGE)}{" "}
                  <strong>{transferOrder.originKitchenName}</strong>
                </Typography>
                <List
                  dense
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                  }}
                >
                  {transferOrder.lines
                    .filter(
                      (line) =>
                        line.sendQuantity > (line.quantityToReceive ?? 0)
                    )
                    .map((line: TransferLine) => (
                      <ListItem key={line.id}>
                        <ListItemIcon>
                          <Inventory color="primary" />
                        </ListItemIcon>
                        <Typography component="span">
                          {line.sku} - {line.name} |{" "}
                          {line.sendQuantity - (line.quantityToReceive ?? 0)}{" "}
                          {line.measureUnit}
                        </Typography>
                      </ListItem>
                    ))}
                </List>
              </>
            ) : (
              <Box display="flex" justifyContent="center" pt={2}>
                <InfoOutlined
                  sx={{
                    ...sxModalIcon,
                    color: "#ED6C02",
                  }}
                />
              </Box>
            )}
            <FormControl>
              <FormLabel>
                {t(transferModule.HAVE_EXTRA_OR_LEFTOVER_QUESTION)}
              </FormLabel>
              <RadioGroup
                row
                value={String(haveExtraOrLeftover)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setHaveExtraOrLeftover(event.target.value === "true");
                }}
              >
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label={t(commons.NO)}
                />
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label={t(commons.YES)}
                />
              </RadioGroup>
            </FormControl>
            {haveExtraOrLeftover && (
              <TextField
                label={t(transferModule.HAVE_EXTRA_OR_LEFTOVER_COMMENT)}
                multiline
                rows={4}
                value={comment}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setComment(event.target.value);
                }}
              />
            )}
          </Box>
        </DialogContent>

        <DialogActions sx={{ p: 3 }}>
          <Box width="100%" display="flex" gap={1}>
            <Button
              variant="outlined"
              color="error"
              startIcon={<CloseOutlinedIcon />}
              fullWidth
              sx={{ p: "12px", fontWeight: "600" }}
              onClick={() => setOpen(false)}
              disabled={savingTransfer}
            >
              {t(commons.CANCEL)}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onFinish}
              disabled={savingTransfer || (haveExtraOrLeftover && !comment)}
              sx={{ p: "12px", fontWeight: "600" }}
              startIcon={
                savingTransfer ? (
                  <CircularProgress size={20} />
                ) : (
                  <ArrowForwardIosIcon />
                )
              }
              fullWidth
            >
              {t(commons.ACCEPT)}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <CommonDialog
        open={openResponseModal}
        handleClose={() => handleConfirm()}
        handleConfirm={() => handleConfirm()}
        title={t(transferModule.RECEIPT_CONFIRM_TITLE)}
        message={t(transferModule.RECEIPT_CONFIRM_MESSAGE)}
        icon={
          <CheckCircleOutlinedIcon
            sx={{
              ...sxModalIcon,
              color: "green",
            }}
          />
        }
        showCancelButton={false}
      />
    </>
  );
};

export default FinishTransferOrderReceipt;
