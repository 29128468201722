import { UseCase } from "core/common/interfaces/useCase";
import { CanGetPickingLinesByIds } from "core/transfer/interfaces/transfer/CanGetPickingLinesByIds";

export class GetPickingLinesByIdsUseCase implements UseCase {
  constructor(private readonly repository: CanGetPickingLinesByIds) {}

  public execute(lineIds: number[]) {
    return this.repository.pickingLinesByIds(lineIds);
  }
}
