/* eslint-disable react/require-default-props */
import React from "react";

interface IconProp {
  fontSize?: string;
}

const SupplyingCP = (props: IconProp): React.ReactElement => {
  const { fontSize } = props;
  return (
    <svg
      width={fontSize ?? 40}
      height={fontSize ?? 40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_6310_1110"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_6310_1110)">
        <path
          d="M5 8H13.45H13H13.35H5ZM5.4 6H18.6L17.75 5H6.25L5.4 6ZM10 12.75L12 11.75L14 12.75V8H10V12.75ZM14.55 21H5C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V6.525C3 6.29167 3.0375 6.06667 3.1125 5.85C3.1875 5.63333 3.3 5.43333 3.45 5.25L4.7 3.725C4.88333 3.49167 5.1125 3.3125 5.3875 3.1875C5.6625 3.0625 5.95 3 6.25 3H17.75C18.05 3 18.3375 3.0625 18.6125 3.1875C18.8875 3.3125 19.1167 3.49167 19.3 3.725L20.55 5.25C20.7 5.43333 20.8125 5.63333 20.8875 5.85C20.9625 6.06667 21 6.29167 21 6.525V11.425C20.6833 11.3083 20.3583 11.2167 20.025 11.15C19.6917 11.0833 19.35 11.05 19 11.05V8H16V11.825C15.4167 12.1583 14.9083 12.5708 14.475 13.0625C14.0417 13.5542 13.7 14.1083 13.45 14.725L12 14L8 16V8H5V19H13.35C13.4833 19.3833 13.65 19.7417 13.85 20.075C14.05 20.4083 14.2833 20.7167 14.55 21ZM18 21V18H15V16H18V13H20V16H23V18H20V21H18Z"
          fill="#232323"
        />
      </g>
    </svg>
  );
};

export default SupplyingCP;
