import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { AlertTitle, Button, Container, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import UploadArea from "app/components/UploadArea/UploadArea";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { useNavigator } from "app/hooks/useNavigator";
import { massiveTransfer, transferModule } from "app/i18n/types";
import { clearTransfersState } from "app/store/slices/transfer";
import {
  createTransferOrder,
  getPendingTransferOrdersByLocationId,
} from "app/store/slices/transfer/thunks";
import { excelToJson } from "app/utils/excel";
import { TransferOrder } from "core/transfer/entities/TransferOrder";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CommonDialog from "../common/CommonDialog";

interface MassiveTransferProps {}

const MassiveTransfer: FunctionComponent<MassiveTransferProps> = () => {
  const { t } = useTranslation();
  const navigator = useNavigator();
  const { kitchenId, origin } = navigator.params();

  const dispatch = useAppDispatch();

  const createTransferOrderRequest = useAppSelector(
    (state) => state.transfer.createTransferOrder
  );

  const pendingTransferOrdersRequest = useAppSelector(
    (state) => state.transfer.pendingTransferOrders
  );

  const [isLoadingExcel, setIsLoadingExcel] = useState<boolean>(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState<boolean>(false);
  const [transferType, setTransferType] = useState<string | null>(null);
  const [fileErrors, setFileErrors] = useState<string[]>([]);
  const [reset, setReset] = useState<number>(new Date().getTime());

  const refinedTransferType = (type: string): string => {
    if (type === "PEDIDO TRANSFERENCIA") {
      return "TRANSFER";
    }
    return type;
  };

  const onUpload = async (files: File[] | null) => {
    setOpenSuccessDialog(false);
    setIsLoadingExcel(true);
    setFileErrors([]);
    if (!files) return;

    const rawFile = await Promise.all(
      files.map((file) => excelToJson(file, {}))
    );
    const transferTypeTmp = rawFile[0][0];
    const fileRows = await Promise.all(
      files.map((file) =>
        excelToJson(file, {
          skipRows: 2,
        })
      )
    );

    const groupByLocation: Record<string, any[]> = {};

    let errors: string[] = [];

    fileRows
      .flat()
      .filter((row) => row[4] !== null)
      .forEach((row, index) => {
        let error = validateLine(row);
        if (error) {
          errors.push(
            `${t(transferModule.ERROR_LINE)} ${index + 3} : ${error}`
          );
        }
        const locationId = String(row[5]);
        groupByLocation[locationId] ||= [];
        groupByLocation[locationId].push(row);
      });

    if (errors.length > 0) {
      setIsLoadingExcel(false);
      setFileErrors(errors);
      return;
    }

    const type = refinedTransferType(
      transferTypeTmp[0].toString().toUpperCase()
    );

    const transferOrders: TransferOrder[] = Object.entries(groupByLocation).map(
      ([locationId, lines]) => ({
        kitchenId: locationId,
        type: type,
        userId: "",
        lines: lines.map(([_, sku, __, measureUnit, quantity, ___]) => ({
          sku,
          quantity,
          measureUnit,
          sendQuantity: 0,
          packedQuantity: 0,
          itemGroup: "",
        })),
      })
    );

    setIsLoadingExcel(false);
    setTransferType(type);
    dispatch(
      createTransferOrder({ locationId: kitchenId ?? "", transferOrders })
    );
  };

  const validateLine = (row: any[]) => {
    if (!row[1]) {
      return t(transferModule.ERROR_SKU);
    }
    if (!row[3]) {
      return t(transferModule.ERROR_MEASURE_UNIT);
    }
  };

  const reloadTransferOrders = () => {
    if (kitchenId !== undefined) {
      setOpenSuccessDialog(false);
      if (transferType === "TRANSFER") {
        setReset(new Date().getTime());
        dispatch(clearTransfersState());
      } else {
        navigator.toByLocationType(
          `/transfers/${kitchenId}/review-packed/${origin}`
        );
      }
    }
  };

  useEffect(() => {
    if (createTransferOrderRequest.status === "succeeded") {
      setOpenSuccessDialog(true);
    }
  }, [createTransferOrderRequest.status, dispatch]);

  const goToReview = useCallback(() => {
    navigator.toByLocationType(`/transfers/${kitchenId}/review`);
  }, [kitchenId]);

  useEffect(() => {
    if (
      kitchenId !== undefined &&
      pendingTransferOrdersRequest.status === "idle"
    ) {
      setOpenSuccessDialog(false);
      dispatch(getPendingTransferOrdersByLocationId(kitchenId));
    }
  }, [dispatch, kitchenId, pendingTransferOrdersRequest.status]);

  return (
    <Box>
      {fileErrors.length > 0 && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {fileErrors.map((error) => (
            <Typography fontWeight={700} color="text.secondary">
              {error}
            </Typography>
          ))}
        </Alert>
      )}

      {createTransferOrderRequest.status === "failed" && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {createTransferOrderRequest.error}
        </Alert>
      )}

      {pendingTransferOrdersRequest.status === "loading" && (
        <Box>
          <LinearProgress />
        </Box>
      )}

      <CommonDialog
        open={openSuccessDialog}
        handleClose={reloadTransferOrders}
        handleConfirm={reloadTransferOrders}
        title={t(massiveTransfer.LOAD_TRANSFER_SUCCESS_TITLE)}
        message={t(massiveTransfer.LOAD_TRANSFER_SUCCESS_MESSAGE)}
        icon={
          <InfoOutlined
            sx={{
              height: "80px",
              width: "80px",
              paddingRight: "5px",
            }}
            color="primary"
          />
        }
        showCancelButton={false}
      />

      {pendingTransferOrdersRequest.status === "succeeded" &&
      pendingTransferOrdersRequest.transferOrders !== null &&
      pendingTransferOrdersRequest.transferOrders.length > 0 ? (
        <Container>
          <Alert
            severity="info"
            action={
              <Button
                variant="contained"
                onClick={goToReview}
                sx={{ alignSelf: "center" }}
              >
                {t(massiveTransfer.REVIEW_PENDING_TRANSFER_ALERT_BUTTON)}
              </Button>
            }
          >
            <AlertTitle>
              {t(massiveTransfer.REVIEW_PENDING_TRANSFER_ALERT_TITLE)}
            </AlertTitle>
            {t(massiveTransfer.REVIEW_PENDING_TRANSFER_ALERT_SUBTITLE)}
          </Alert>
        </Container>
      ) : (
        <Box display="flex" justifyContent="center" mt={8}>
          <UploadArea
            key={reset}
            accept=".xlsx, .xls, .csv"
            onUpload={onUpload}
            label={massiveTransfer.SELECT_MOVE_FILE}
            loading={
              isLoadingExcel ||
              createTransferOrderRequest.status === "loading" ||
              pendingTransferOrdersRequest.status === "loading"
            }
            disabled={
              pendingTransferOrdersRequest.status === "succeeded" &&
              pendingTransferOrdersRequest.transferOrders !== null &&
              pendingTransferOrdersRequest.transferOrders.length > 0
            }
          />
        </Box>
      )}
    </Box>
  );
};

export default MassiveTransfer;
