import { UseCase } from "core/common/interfaces/useCase";
import { CanArchiveAdjustments } from "core/dailyAdjustments/interfaces/adjustment/canArchiveAdjustments";

export class ArchiveAdjustmentsUseCase implements UseCase {
  constructor(private readonly repository: CanArchiveAdjustments) {}

  public execute(adjustments: Array<number>) {
    return this.repository.archiveAdjustments(adjustments);
  }
}
