export enum AttachmentTypeEnum {
  TRANSFER_ORDER = "TRANSFER_ORDER",
  LOSS = "LOSS",
  EVENT = "EVENT",
  PURCHASE_ORDER = "PURCHASE_ORDER",
}

export type AttachmentType = `${AttachmentTypeEnum}`;

export interface DownloadZipPayload {
  id: string;
  country: string;
  name: string;
}

export interface AttachmentFile {
  id?: number;
  name: string;
  type?: string;
  base64?: string;
  path?: string;
  createdAt?: Date;
  verified: boolean;
}
