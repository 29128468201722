import { UseCase } from "core/common/interfaces/useCase";
import { canGetProductionsByKitchen } from "core/productions/interfaces/canGetProductionsByKitchen";

export class GetProductionsByKitchen implements UseCase {
  constructor(private readonly repository: canGetProductionsByKitchen) {}

  public execute(
    kitchenId: string,
    status: "ACTIVES" | "TO_APPROVE" | "FINISHED"
  ) {
    return this.repository.getProductionsByKitchen(kitchenId, status);
  }
}
