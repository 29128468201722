import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { FunctionComponent, ReactElement, useCallback, useState } from "react";

import { StoreRequestError, StoreRequestStatus } from "app/store/types";
import { TransferOrderLine } from "core/transfer/entities/TransferOrder";
import { useTranslation } from "react-i18next";
import NumberField from "../common/Field/Number";

interface TransferOrdersTableRowProps {
  line: TransferOrderLine & {
    id: number;
    name: string;
  };
  checkedLines: Array<number>;
  toggleChecked: (lineId: number) => void;
  onUpdateLineQuantity: (lineId: number, quantity: number) => void;
  lineStatus: {
    status: StoreRequestStatus;
    error: StoreRequestError;
  } | null;
  disabled: boolean;
}

const TransferOrdersTableRow: FunctionComponent<TransferOrdersTableRowProps> = (
  props
) => {
  const {
    line,
    checkedLines,
    toggleChecked,
    onUpdateLineQuantity,
    lineStatus,
    disabled,
  } = props;

  const { t } = useTranslation();

  const [quantity, setQuantity] = useState<number | null>(line.quantity);

  const getInputAddornement = useCallback((): ReactElement | undefined => {
    if (lineStatus === null || lineStatus === undefined) {
      return undefined;
    }

    if (lineStatus.status === "loading") {
      return <CircularProgress size={32} />;
    }

    if (lineStatus.status === "failed") {
      return <ErrorOutlineIcon color="error" />;
    }

    if (lineStatus.status === "succeeded") {
      return <CheckCircleOutlineIcon color="success" />;
    }

    return undefined;
  }, [lineStatus]);

  const getInputColor = useCallback(() => {
    if (lineStatus === null || lineStatus === undefined) {
      return "primary";
    }

    if (lineStatus.status === "failed") {
      return "error";
    }

    if (lineStatus.status === "succeeded") {
      return "success";
    }

    return "primary";
  }, [lineStatus]);

  return (
    <TableRow
      className={checkedLines.includes(line.id) ? "selected" : undefined}
      sx={{
        bgcolor: checkedLines.includes(line.id) ? "#E2F0FF" : "white",

        "&.selected > td": {
          borderBottomColor: "#ccdef1",
        },

        "&:hover": {
          bgcolor: checkedLines.includes(line.id) ? "#ccdef1" : "#fafafa",

          "& .show-on-hover": {
            display: "flex",
          },
        },
      }}
    >
      <TableCell align="center" width={80}>
        <Checkbox
          onChange={() => toggleChecked(line.id)}
          checked={checkedLines.includes(line.id)}
          disabled={disabled}
        />
      </TableCell>
      <TableCell>{line.sku}</TableCell>
      <TableCell>
        <Typography>{line.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography
          textTransform="uppercase"
          fontWeight={700}
          color="text.secondary"
        >
          {t(`measureUnits.${line.measureUnit.toLowerCase()}`)}
        </Typography>
      </TableCell>

      <TableCell align="center" sx={{ width: { xs: "100%", md: 330 } }}>
        <Box display="flex" alignItems="center" justifyContent="start" gap={1}>
          <NumberField
            name="value"
            value={quantity ?? 0}
            onChangeValue={(newValue) => setQuantity(newValue)}
            label="Cantidad reportada"
            size="small"
            endAdornment={getInputAddornement()}
            color={getInputColor()}
            error={
              lineStatus !== null &&
              lineStatus !== undefined &&
              lineStatus.error !== null
            }
            onBlur={(event) =>
              onUpdateLineQuantity(line.id, Number(event.target.value))
            }
            sx={{ width: 180 }}
            disabled={disabled}
            focused={lineStatus !== null && lineStatus?.status !== "idle"}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default TransferOrdersTableRow;
