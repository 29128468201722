import { StoreRequestError, StoreRequestStatus } from "app/store/types";
import { ProductionCatalog } from "core/productions/entities/Catalog";
import {
  ProductionLabelResponse,
  ProductionRecord,
} from "core/productions/entities/Productions";

export interface ProductionsState {
  status: StoreRequestStatus;
  error: StoreRequestError;
  productionCatalog: Array<ProductionCatalog> | null;
  productionList: {
    status: StoreRequestStatus;
    error: StoreRequestError;
    list: Array<ProductionRecord> | null;
  };
  production: {
    status: StoreRequestStatus;
    error: StoreRequestError;
    data: ProductionRecord | null;
    detailsStatus: {
      [detailId: string]: StoreRequestStatus;
    };
  };
  createProduction: {
    status: StoreRequestStatus;
    error: StoreRequestError;
  };
  updateProduction: {
    status: StoreRequestStatus;
    error: StoreRequestError;
  };
  deleteProduction: {
    status: StoreRequestStatus;
    error: StoreRequestError;
  };
  finishProduction: {
    status: StoreRequestStatus;
    error: StoreRequestError;
  };
  selectedProduction: ProductionCatalog | null;
  labels: Array<ProductionLabelResponse> | null;
  getLabels: {
    status: StoreRequestStatus;
    error: StoreRequestError;
  };
  createLabels: {
    status: StoreRequestStatus;
    error: StoreRequestError;
  };
  sendProductionPrintEvent: {
    status: StoreRequestStatus;
    error: StoreRequestError;
  };
  updateProductionManager: StoreRequestStatus;
}

export const initialState: ProductionsState = {
  status: "idle",
  error: null,
  productionCatalog: null,
  selectedProduction: null,
  productionList: {
    list: null,
    status: "idle",
    error: null,
  },
  production: {
    status: "idle",
    error: null,
    data: null,
    detailsStatus: {},
  },
  createProduction: {
    status: "idle",
    error: null,
  },
  updateProduction: {
    status: "idle",
    error: null,
  },
  deleteProduction: {
    status: "idle",
    error: null,
  },
  finishProduction: {
    status: "idle",
    error: null,
  },
  labels: null,
  getLabels: {
    status: "idle",
    error: null,
  },
  createLabels: {
    status: "idle",
    error: null,
  },
  sendProductionPrintEvent: {
    status: "idle",
    error: null,
  },
  updateProductionManager: "idle",
};
