import { UseCase } from "core/common/interfaces/useCase";
import { UpdateLineQuantityAndReason } from "core/transfer/entities/TransferOrder";
import { CanUpdateTransferOrderLineQuantity } from "core/transfer/interfaces/transfer/canUpdateTransferOrderLineQuantity";

export class UpdateTransferOrderLineQuantityUseCase implements UseCase {
  constructor(
    private readonly repository: CanUpdateTransferOrderLineQuantity
  ) {}

  public execute(transferLineId: number, payload: UpdateLineQuantityAndReason) {
    return this.repository.updateTransferOrderLineQuantity(
      transferLineId,
      payload
    );
  }
}
