import { KOSEmptyState } from "@foodology-co/alejandria";
import InboxOutlinedIcon from "@mui/icons-material/InboxOutlined";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { theoricalInventoryModule } from "app/i18n/types";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  isTableEmpty: boolean;
  tableCellNumber: number;
}

const EmptyAuthorizationTable: FunctionComponent<Props> = (props) => {
  const { isTableEmpty, tableCellNumber } = props;
  const { t } = useTranslation();

  return (
    <>
      {isTableEmpty && (
        <TableRow>
          <TableCell colSpan={tableCellNumber}>
            <KOSEmptyState
              icon={InboxOutlinedIcon}
              instruction={t(theoricalInventoryModule.NO_ELEMENTS)}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default EmptyAuthorizationTable;
