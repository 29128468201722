import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Kitchen } from "core/supplies/entities/Kitchen";
import {
  LocalStorageItem,
  getLocalStorageString,
  removeLocalStorage,
  setLocalStorage,
} from "utils/localStorage";
import { getListProducts } from "../dailyAdjustments/thunks";
import { getActiveKitchens } from "../supplies/thunks";
import { Alert, initialState } from "./state";

const getKitchen = (
  list: Kitchen[],
  kitchenId?: string
): Kitchen | undefined => {
  const selectedKitchenId =
    kitchenId ?? getLocalStorageString(LocalStorageItem.selectedKitchenId);
  return list.find((el) => el.kitchenId === selectedKitchenId);
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    showAlert: (state, alert: PayloadAction<Alert>) => {
      state.alert = { ...alert.payload, show: true };
    },
    showSearchOnTopBar: (state) => {
      state.textSearchOnTopBar = "";
      state.canSearchOnTopBar = true;
    },
    hideSearchOnTopBar: (state) => {
      state.textSearchOnTopBar = "";
      state.canSearchOnTopBar = false;
    },
    setSearchOnTopBar: (state, action: PayloadAction<string>) => {
      state.textSearchOnTopBar = action.payload;
    },
    clearSearchOnTopBar: (state) => {
      state.textSearchOnTopBar = "";
    },
    setSearchOnFullDialog: (state, action: PayloadAction<string>) => {
      state.textSearchOnFullDialog = action.payload;
    },
    clearSearchOnFullDialog: (state) => {
      state.textSearchOnFullDialog = "";
    },
    showSearchOnFullDialog: (state) => {
      state.textSearchOnFullDialog = "";
      state.canSearchOnFullDialog = true;
    },
    hideSearchOnFullDialog: (state) => {
      state.textSearchOnFullDialog = "";
      state.canSearchOnFullDialog = false;
    },
    hideAlert: (state) => {
      state.alert = { show: false };
    },
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    clearTitle: (state) => {
      state.title = null;
    },
    setSelectedKitchen: (state, action: PayloadAction<string | undefined>) => {
      const kitchenId = action.payload;
      if (kitchenId) {
        setLocalStorage(LocalStorageItem.selectedKitchenId, kitchenId);
        state.selectedKitchen = getKitchen(state.kitchens.data, kitchenId);
      } else {
        removeLocalStorage(LocalStorageItem.selectedKitchenId);
        state.selectedKitchen = undefined;
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getActiveKitchens.pending, (state, action) => {
        state.kitchens.status = "loading";
      })
      .addCase(getActiveKitchens.fulfilled, (state, action) => {
        state.kitchens.status = "succeeded";
        const { data } = action.payload.kitchens;
        state.kitchens.data = data;
        state.selectedKitchen = getKitchen(data);
      })
      .addCase(getActiveKitchens.rejected, (state, action) => {
        state.kitchens.status = "failed";
        state.kitchens.error = action.error.message ?? null;
      })
      .addCase(getListProducts.pending, (state) => {
        state.products.status = "loading";
      })
      .addCase(getListProducts.fulfilled, (state, action) => {
        state.products.status = "succeeded";
        state.products.data = action.payload.productsForKitchen;
      })
      .addCase(getListProducts.rejected, (state, action) => {
        state.products.status = "failed";
        state.products.error = action.error.message ?? null;
      });
  },
});

export const {
  showAlert,
  hideAlert,
  setTitle,
  clearTitle,
  setSelectedKitchen,
  showSearchOnTopBar,
  hideSearchOnTopBar,
  setSearchOnTopBar,
  clearSearchOnTopBar,
  setSearchOnFullDialog,
  clearSearchOnFullDialog,
  showSearchOnFullDialog,
  hideSearchOnFullDialog,
} = globalSlice.actions;
export const { reducer } = globalSlice;

export default globalSlice;
