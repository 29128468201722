import CountingTypeSelector from "app/components/CountingTypeSelector";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { getCountingType } from "app/store/slices/counting/thunks";
import { CountingType } from "core/physicalCount/entities/Counting";
import React, { FunctionComponent, memo } from "react";
import { useTranslation } from "react-i18next";
import { useMount } from "react-use";
import { CountingTypeCode } from "utils/general";
import { TypeOfRole } from "utils/role";

interface CountingTypeStepProps {
  selectedCountingType: CountingType | null;
  setSelectedCountingType: (selectedCountingType: CountingType | null) => void;
  isRefined?: boolean;
  isForCreate?: boolean;
}

const CountingTypeStep: FunctionComponent<CountingTypeStepProps> = (props) => {
  const {
    selectedCountingType,
    setSelectedCountingType,
    isRefined,
    isForCreate,
  } = props;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.session.user.data);
  const [options, setOptions] = React.useState<CountingType[]>([]);

  const getOptionRefined = (option: CountingType): CountingType => {
    return { ...option, name: t(option.code) };
  };

  const getOptionsRefined = (dbOptions: CountingType[]): CountingType[] => {
    const newOptions: CountingType[] = [];
    dbOptions.forEach((option) => {
      if (option.code === CountingTypeCode.daily) {
        newOptions.push({
          ...option,
          code: CountingTypeCode.daily_individual,
          name: t(CountingTypeCode.daily_individual),
        });
        newOptions.push({
          ...option,
          code: CountingTypeCode.daily_massive,
          name: t(CountingTypeCode.daily_massive),
        });
      } else if (option.code === CountingTypeCode.other) {
        newOptions.push({
          ...option,
          code: CountingTypeCode.other_individual,
          name: t(CountingTypeCode.other_individual),
        });
        newOptions.push({
          ...option,
          code: CountingTypeCode.other_massive,
          name: t(CountingTypeCode.other_massive),
        });
      } else {
        newOptions.push(getOptionRefined(option));
      }
    });
    return newOptions;
  };

  const getDbOptions = (dbOptions: CountingType[]): CountingType[] => {
    if (isForCreate && user?.role === TypeOfRole.OPS) {
      return dbOptions.filter((el) => el.code === CountingTypeCode.other);
    }
    return dbOptions;
  };

  const getData = async () => {
    const result = await dispatch(getCountingType());
    const dbOptions = getDbOptions(result.payload as CountingType[]);
    setOptions(
      isRefined
        ? getOptionsRefined(dbOptions)
        : dbOptions.map((option) => getOptionRefined(option))
    );
  };

  useMount(() => {
    getData();
  });

  return (
    <CountingTypeSelector
      options={options}
      selectedCountingType={selectedCountingType}
      setSelectedCountingType={setSelectedCountingType}
    />
  );
};

export default memo(CountingTypeStep);
