import { Close } from "@mui/icons-material";
import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import { supplyingManagement } from "app/i18n/types";
import { Product } from "core/dailyAdjustments/entities/Catalog";
import { useTranslation } from "react-i18next";
import { AddExtraSupplyDialogContent } from "./Content";

interface Props {
  suppliesToAdd: Product[];
  skusToDisabled?: string[];
  onClose: () => void;
  onRefresh: () => void;
}

export const AddExtraSupplyDialog = (props: Props) => {
  const { suppliesToAdd, onClose, onRefresh, skusToDisabled } = props;

  const { t } = useTranslation();

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle
        sx={{
          px: 3,
          py: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {t(supplyingManagement.ADD_EXTRA_SUPPLY)}

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ px: 3 }}>
        <Alert
          severity="info"
          sx={{ mb: 3, display: "flex", alignItems: "center" }}
        >
          {t(supplyingManagement.ADD_EXTRA_SUPPLY_INFO)}
        </Alert>

        <AddExtraSupplyDialogContent
          suppliesToAdd={suppliesToAdd}
          skusToDisabled={skusToDisabled}
          onClose={onClose}
          onRefresh={onRefresh}
        />
      </DialogContent>
    </Dialog>
  );
};
