/* eslint-disable react/require-default-props */
import React from "react";

interface IconProp {
  fontSize?: string;
}

const KitchenPacktransfersIcon = (props: IconProp): React.ReactElement => {
  const { fontSize } = props;
  return (
    <svg
      width={fontSize ?? 40}
      height={fontSize ?? 40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width={fontSize ?? 40}
        height={fontSize ?? 40}
        rx="20"
        fill="#F1EFEF"
      />
      <mask
        id="mask0_5932_463"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="24"
        height="24"
      >
        <rect x="8" y="8" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5932_463)">
        <path
          d="M13 16H21.45H21H21.35H13ZM13.4 14H26.6L25.75 13H14.25L13.4 14ZM18 20.75L20 19.75L22 20.75V16H18V20.75ZM22.55 29H13C12.45 29 11.9792 28.8042 11.5875 28.4125C11.1958 28.0208 11 27.55 11 27V14.525C11 14.2917 11.0375 14.0667 11.1125 13.85C11.1875 13.6333 11.3 13.4333 11.45 13.25L12.7 11.725C12.8833 11.4917 13.1125 11.3125 13.3875 11.1875C13.6625 11.0625 13.95 11 14.25 11H25.75C26.05 11 26.3375 11.0625 26.6125 11.1875C26.8875 11.3125 27.1167 11.4917 27.3 11.725L28.55 13.25C28.7 13.4333 28.8125 13.6333 28.8875 13.85C28.9625 14.0667 29 14.2917 29 14.525V19.425C28.6833 19.3083 28.3583 19.2167 28.025 19.15C27.6917 19.0833 27.35 19.05 27 19.05V16H24V19.825C23.4167 20.1583 22.9083 20.5708 22.475 21.0625C22.0417 21.5542 21.7 22.1083 21.45 22.725L20 22L16 24V16H13V27H21.35C21.4833 27.3833 21.65 27.7417 21.85 28.075C22.05 28.4083 22.2833 28.7167 22.55 29ZM26 29V26H23V24H26V21H28V24H31V26H28V29H26Z"
          fill="#232323"
        />
      </g>
    </svg>
  );
};

export default KitchenPacktransfersIcon;
