import { createAsyncThunk } from "@reduxjs/toolkit";
import appConfig from "config/app";
import { Session } from "core/account/entities/Session";
import {
  AddManualAdjustmentResponse,
  ManualAdjustment,
} from "core/adjustments/entities/ManualAdjustment";
import {
  CountingType,
  MassiveDataToSent,
} from "core/physicalCount/entities/Counting";
import { ProductCountResultDTO } from "core/physicalCount/interfaces/counting/canSetProductCount";
import { CountingHTTPRepository } from "core/physicalCount/repositories/http/counting";
import { ProductHTTPRepository } from "core/physicalCount/repositories/http/product";
import {
  CreateCountingUseCase,
  CreateSchedulePhysicalCountingUseCase,
} from "core/physicalCount/useCases/counting/createCountingUseCase";
import {
  ArchiveCountsUseCase,
  FinishCountingUseCase,
} from "core/physicalCount/useCases/counting/finishCountingUseCase";
import { FinishItemCountingUseCase } from "core/physicalCount/useCases/counting/finishItemCountingUseCase";
import {
  CanGetCountingByLocationUseCase,
  GetLastReCount,
} from "core/physicalCount/useCases/counting/getCountingHistory";
import { GetCountingTypeUseCase } from "core/physicalCount/useCases/counting/getCountingTypeUseCase";
import { SetProductCountUseCase } from "core/physicalCount/useCases/counting/setProductCountUseCase";
import { validateCountingUseCase } from "core/physicalCount/useCases/counting/validateCountingUseCase";
import { AddItemCount } from "core/physicalCount/useCases/product/addItemCount";
import { AddScannedItemCount } from "core/physicalCount/useCases/product/addScannedItemCount";
import { GetItemCountHistory } from "core/physicalCount/useCases/product/getItemCountHistory";
import { GetProductsForKitchenUseCase } from "core/physicalCount/useCases/product/getProductsForKitchenUseCase";
import { ProductionScannedtResponse } from "core/productions/entities/Productions";
import { LocationType } from "utils/general";
import { UpdateScheduledCountingUseCase } from "../../../../core/physicalCount/useCases/counting/UpdateScheduledCounting";
import { GetValidateQuantityUseCase } from "../../../../core/physicalCount/useCases/counting/getValidateQuantity";

export const createCounting = createAsyncThunk(
  "counting/getCountingDetails",
  async (payload: { kitchenId: string; session: Session; type: string }) => {
    const httpRepository = new CountingHTTPRepository(appConfig);

    const createCountingUseCase = new CreateCountingUseCase(httpRepository);

    const productsForKitchen = await createCountingUseCase.execute(
      payload.kitchenId,
      payload.type,
      payload.session
    );
    return productsForKitchen;
  }
);

export const createSchedulePhysicalCounting = createAsyncThunk(
  "counting/by/type/create",
  async (payload: {
    countingTypeCode: string;
    name: string;
    kitchenIds: string[];
    dates: string[];
    supplies: string[];
    massiveDataToSent: MassiveDataToSent[];
  }) => {
    const httpRepository = new CountingHTTPRepository(appConfig);

    const useCase = new CreateSchedulePhysicalCountingUseCase(httpRepository);

    const response = await useCase.execute(
      payload.countingTypeCode,
      payload.name,
      payload.kitchenIds,
      payload.dates,
      payload.supplies,
      payload.massiveDataToSent
    );
    return response;
  }
);

export const getCountItemHistory = createAsyncThunk(
  "counting/getCountItemHistory",
  async (payload: { countingId: string; sku: string }) => {
    const httpRepository = new ProductHTTPRepository(appConfig);

    const getItemCountHistory = new GetItemCountHistory(httpRepository);

    const itemCountHistory = await getItemCountHistory.execute(
      payload.countingId,
      payload.sku
    );
    return itemCountHistory;
  }
);

export const getProductsForKitchen = createAsyncThunk(
  "counting/getProductsForKitchen",
  async (payload: { kitchenId: string; countingId: string }) => {
    const productHttpRepository = new ProductHTTPRepository(appConfig);
    const getProductsForKitchenUseCase = new GetProductsForKitchenUseCase(
      productHttpRepository
    );
    const productsForKitchen = await getProductsForKitchenUseCase.execute(
      payload.countingId
    );

    return { productsForKitchen };
  }
);

export const setProductCount = createAsyncThunk(
  "counting/setProductCount",
  async (payload: {
    countingId: number;
    sku: string;
    unit: string;
    quantity: number;
    comment: string;
  }): Promise<ProductCountResultDTO> => {
    const httpRepository = new CountingHTTPRepository(appConfig);
    const setProductCountUseCase = new SetProductCountUseCase(httpRepository);
    const setProductCountResult = await setProductCountUseCase.execute(
      payload.countingId,
      payload.sku,
      payload.unit,
      payload.quantity,
      payload.comment
    );
    return setProductCountResult;
  }
);

export const addItemCount = createAsyncThunk(
  "counting/addItemCount",
  async (payload: {
    countingId: string;
    sku: string;
    unit: string;
    quantity: number;
    comments: string;
    status: string;
  }): Promise<number> => {
    const httpRepository = new ProductHTTPRepository(appConfig);
    const addItemCount = new AddItemCount(httpRepository);
    const addItemCountResult = await addItemCount.execute(
      payload.countingId,
      payload.sku,
      payload.unit,
      payload.quantity,
      payload.comments,
      payload.status
    );
    return addItemCountResult;
  }
);

export const addScannedItemCount = createAsyncThunk(
  "counting/addScannedItemCount",
  async (payload: {
    countingId: string;
    productionLabelId: string;
  }): Promise<ProductionScannedtResponse> => {
    const httpRepository = new ProductHTTPRepository(appConfig);
    const addScannedItemCount = new AddScannedItemCount(httpRepository);
    const addScannedItemCountResult = await addScannedItemCount.execute(
      payload.countingId,
      payload.productionLabelId
    );
    return addScannedItemCountResult;
  }
);

export const getCountingType = createAsyncThunk("counting/types", async () => {
  const httpRepository = new CountingHTTPRepository(appConfig);
  const useCase = new GetCountingTypeUseCase(httpRepository);
  const result: CountingType[] = await useCase.execute();
  return result;
});

export const finishCounting = createAsyncThunk(
  "counting/finishCounting",
  async (payload: string) => {
    const httpRepository = new CountingHTTPRepository(appConfig);
    const setProductInactivenUseCase = new FinishCountingUseCase(
      httpRepository
    );

    const setProductInactivenResult = await setProductInactivenUseCase.execute(
      payload
    );

    return setProductInactivenResult;
  }
);

export const finishItemCounting = createAsyncThunk(
  "counting/finishItemCounting",
  async (payload: { countingId: number; sku: string }) => {
    const httpRepository = new CountingHTTPRepository(appConfig);
    const finishItemUseCase = new FinishItemCountingUseCase(httpRepository);

    const finishItemCountingResult = await finishItemUseCase.execute(
      payload.countingId,
      payload.sku
    );

    return finishItemCountingResult;
  }
);

export const getValidateQuantity = createAsyncThunk(
  "counting/getValidateQuantity",
  async (payload: { sku: string; kitchenId: string }) => {
    const httpRepository = new CountingHTTPRepository(appConfig);

    const getValidateQuantityUseCase = new GetValidateQuantityUseCase(
      httpRepository
    );

    const quantityValues = await getValidateQuantityUseCase.execute(
      payload.sku,
      payload.kitchenId
    );

    return quantityValues;
  }
);

export const startScheduledCounting = createAsyncThunk(
  "counting/scheduled",
  async (payload: {
    countingId: string;
    userId: string;
    kitchenId: string;
  }) => {
    const httpRepository = new CountingHTTPRepository(appConfig);
    const useCase = new UpdateScheduledCountingUseCase(httpRepository);
    const result = await useCase.execute(
      payload.countingId,
      payload.userId,
      payload.kitchenId
    );
    return result;
  }
);

export const getLastCountingByKitchen = createAsyncThunk(
  "counting/getLastCountingByKitchen",
  async (payload: { kitchenId: string }) => {
    const httpRepository = new CountingHTTPRepository(appConfig);
    const useCase = new GetLastReCount(httpRepository);
    const result = await useCase.execute(payload.kitchenId);
    return result;
  }
);

export const validateCounting = createAsyncThunk(
  "counting/validateCounting",
  async (payload: {
    adjustments: Array<ManualAdjustment>;
  }): Promise<AddManualAdjustmentResponse> => {
    const httpRepository = new CountingHTTPRepository(appConfig);
    const useCase = new validateCountingUseCase(httpRepository);
    const result = await useCase.execute(payload.adjustments);
    return result;
  }
);

export const archiveCounts = createAsyncThunk(
  "counting/archiveCounts",
  async (payload: number[]) => {
    const httpRepository = new CountingHTTPRepository(appConfig);
    const useCase = new ArchiveCountsUseCase(httpRepository);
    const result = await useCase.execute(payload);
    return result;
  }
);

export const getCountingByLocation = createAsyncThunk(
  "counting/getCountingByLocation",
  async (payload: { locationType: LocationType; location: string }) => {
    const httpRepository = new CountingHTTPRepository(appConfig);
    const useCase = new CanGetCountingByLocationUseCase(httpRepository);
    const result = await useCase.execute(
      payload.locationType,
      payload.location
    );
    return result;
  }
);
