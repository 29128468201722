import { UseCase } from "core/common/interfaces/useCase";
import { CanGetErrorSummary } from "../interfaces/canGetErrorSummary";

export class CanGetErrorSummaryUseCase implements UseCase {
  constructor(private readonly repository: CanGetErrorSummary) {}

  public execute() {
    return this.repository.all();
  }
}
