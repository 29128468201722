import { createAsyncThunk } from "@reduxjs/toolkit";
import appConfig from "config/app";
import { CountingHTTPRepository } from "core/physicalCount/repositories/http/counting";
import { ProductHTTPRepository } from "core/physicalCount/repositories/http/product";
import {
  GetCountingHistoryByStatusUseCase,
  GetCountingHistoryUseCase,
} from "core/physicalCount/useCases/counting/getCountingHistory";
import { GetDetailsByCountingUseCase } from "core/physicalCount/useCases/counting/getDetailsByCounting";
import { GetCountingDetailExportUseCase } from "core/physicalCount/useCases/product/getCountingDetailExportUseCase";

export const getCountingHistory = createAsyncThunk(
  "counting/getCountingHistory",
  async (payload: { kitchenId: string }) => {
    const countingHTTPRepository = new CountingHTTPRepository(appConfig);

    const createCountingUseCase = new GetCountingHistoryUseCase(
      countingHTTPRepository
    );

    const countingHistory = await createCountingUseCase.execute(
      payload.kitchenId
    );
    return { countingHistory };
  }
);

export const getCountingHistoryByStatus = createAsyncThunk(
  "counting/getCountingHistory",
  async (payload: { kitchenId: string }) => {
    const countingHTTPRepository = new CountingHTTPRepository(appConfig);

    const createCountingUseCase = new GetCountingHistoryByStatusUseCase(
      countingHTTPRepository
    );

    const countingHistoryFinished = await createCountingUseCase.execute(
      payload.kitchenId,
      "FINISHED"
    );
    const countingHistoryNotFinished = await createCountingUseCase.execute(
      payload.kitchenId,
      "NOT_FINISHED"
    );
    return {
      countingHistory: [
        ...countingHistoryFinished,
        ...countingHistoryNotFinished,
      ],
    };
  }
);

export const getCountingDetailExport = createAsyncThunk(
  "prducts/getCountingDetailExport",
  async (payload: { countingId: string }) => {
    const httpRepository = new ProductHTTPRepository(appConfig);

    const getCountingDetailExport = new GetCountingDetailExportUseCase(
      httpRepository
    );

    const details = await getCountingDetailExport.execute(payload.countingId);
    return { details };
  }
);

export const getDetailsByCounting = createAsyncThunk(
  "counting/getDetailsByCounting",
  async (payload: { countingId: number }) => {
    const countingHTTPRepository = new CountingHTTPRepository(appConfig);

    const getDetailsByCounting = new GetDetailsByCountingUseCase(
      countingHTTPRepository
    );

    const detailsByCounting = await getDetailsByCounting.execute(
      payload.countingId
    );
    return detailsByCounting;
  }
);
