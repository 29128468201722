import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { transferOrdersPackedReviewTable } from "app/i18n/types";
import { StoreRequestError, StoreRequestStatus } from "app/store/types";
import { onInputKeyDown } from "app/utils/input";
import { TransferOrderLineRefined } from "core/transfer/entities/TransferOrder";
import { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { compareSearchText } from "utils/general";
import TransferOrdersReviewPackedTableRow from "./TransferOrdersReviewPackedTableRow";

interface TransferOrdersReviewPackedTableProps {
  lines: TransferOrderLineRefined[] | null;
  linesStatus: {
    [sku: string]: {
      status: StoreRequestStatus;
      error: StoreRequestError;
    };
  } | null;
  searchTerm: string;
  showSendQuantity?: boolean;
  loading: boolean;
  updateLine: (id: number, sendQuantity: number) => void;
}

const TransferOrdersPackedReviewTable: FunctionComponent<
  TransferOrdersReviewPackedTableProps
> = (props) => {
  const { lines, linesStatus, searchTerm, loading, updateLine } = props;

  const { t } = useTranslation();

  const filteredLines = useMemo(() => {
    if (searchTerm === "") {
      return lines;
    }

    return (
      lines?.filter(
        (line) =>
          compareSearchText(line.name, searchTerm) ||
          compareSearchText(line.sku, searchTerm)
      ) ?? []
    );
  }, [lines, searchTerm]);

  return (
    <TableContainer component={Paper}>
      {loading === true && <LinearProgress />}

      <Table>
        <TableHead>
          <TableRow
            sx={{
              "& > th": {
                bgcolor: "#F9FAFC",
                borderBottom: "2px solid #E3E5E8",
                color: "text.secondary",
                fontWeight: 700,
                textTransform: "uppercase",
              },
            }}
          >
            <TableCell>{t(transferOrdersPackedReviewTable.SKU)}</TableCell>
            <TableCell>{t(transferOrdersPackedReviewTable.NAME)}</TableCell>
            <TableCell>{t(transferOrdersPackedReviewTable.UNIT)}</TableCell>
            <TableCell>
              {t(transferOrdersPackedReviewTable.ESTIMATED_QUANTITY)}
            </TableCell>
            <TableCell>
              {t(transferOrdersPackedReviewTable.PACKED_QUANTITY)}
            </TableCell>
            <TableCell>
              {t(transferOrdersPackedReviewTable.QUANTITY_TO_SEND)}
            </TableCell>
            <TableCell>
              {t(transferOrdersPackedReviewTable.SERVICE_LEVEL)}
            </TableCell>
            <TableCell>{t(transferOrdersPackedReviewTable.STATUS)}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody onKeyDown={(e) => onInputKeyDown(e, 2)}>
          {filteredLines?.map((line) => (
            <TransferOrdersReviewPackedTableRow
              key={line.id}
              line={line}
              lineStatus={
                linesStatus !== null && linesStatus[line.id] !== undefined
                  ? linesStatus[line.id]
                  : null
              }
              disabled={loading}
              updateLine={updateLine}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TransferOrdersPackedReviewTable;
