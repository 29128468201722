export function json2Csv(items: any): string {
  const replacer = (key: any, value: any) => (value === null ? "" : value);
  const header = Object.keys(items[0]);
  return [
    header.join(","),
    ...items.map((row: any) =>
      header
        .map((fieldName: string) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    ),
  ].join("\r\n");
}

export function json2CSVContent<T extends object>(items: Array<T>): string {
  if (items.length === 0) {
    return "";
  }

  const replacer = (key: any, value: any) => (value === null ? "" : value);
  const header = Object.keys(items[0]);
  return [
    header.join(","),
    ...items.map((row: any) =>
      header
        .map((fieldName: string) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    ),
  ].join("\r\n");
}

export function downloadTextAsCsv(text: string, fileName: string): void {
  const blob = new Blob([text], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", fileName);
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
