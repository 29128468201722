/* eslint-disable react/require-default-props */
import React from "react";

interface IconProp {
  fontSize?: string;
}

const KitchenLoadTransfersIcon = (props: IconProp): React.ReactElement => {
  const { fontSize } = props;
  return (
    <svg
      width={fontSize ?? 40}
      height={fontSize ?? 40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width={fontSize ?? 40}
        height={fontSize ?? 40}
        rx="20"
        fill="#F1EFEF"
      />
      <mask
        id="mask0_5928_561"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="24"
        height="24"
      >
        <rect x="8" y="8" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5928_561)">
        <path
          d="M26 21L24.6 19.6L26.75 17.45C26.0333 17.3167 24.8875 17.3917 23.3125 17.675C21.7375 17.9583 19.9667 19.0583 18 20.975C18.5333 19.025 19.6083 17.4583 21.225 16.275C22.8417 15.0917 24.6667 14.5 26.7 14.5L24.6 12.4L26 11L31 16L26 21ZM20 30C17.3167 27.7167 15.3125 25.5958 13.9875 23.6375C12.6625 21.6792 12 19.8667 12 18.2C12 15.9333 12.775 14 14.325 12.4C15.875 10.8 17.7667 10 20 10C20.5833 10 21.1667 10.0625 21.75 10.1875C22.3333 10.3125 22.8917 10.5 23.425 10.75L21.875 12.3C21.575 12.2 21.2667 12.125 20.95 12.075C20.6333 12.025 20.3167 12 20 12C18.3167 12 16.8958 12.6042 15.7375 13.8125C14.5792 15.0208 14 16.4833 14 18.2C14 19.3833 14.4917 20.7333 15.475 22.25C16.4583 23.7667 17.9667 25.4583 20 27.325C21 26.4083 21.875 25.5333 22.625 24.7C23.375 23.8667 23.9917 23.0667 24.475 22.3L25.925 23.75C25.2583 24.7333 24.4333 25.7417 23.45 26.775C22.4667 27.8083 21.3167 28.8833 20 30Z"
          fill="#232323"
        />
      </g>
    </svg>
  );
};

export default KitchenLoadTransfersIcon;
