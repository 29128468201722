import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { IconButton } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { commons, measureUnits } from "app/i18n/types";
import { convertLocalDate } from "app/utils/dateUtils";
import {
  AdjustmentQuantities,
  allReasons,
  registerOptions,
} from "config/dailyAdjustment";
import { AdjustmentRecord } from "core/dailyAdjustments/entities/Adjustment";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { getUserImage } from "utils/general";

interface Props {
  adjustment: AdjustmentRecord;
  toggle: (adjustmentId?: number) => void;
  selected: number[];
  setOpenDocumentList: (open: boolean) => void;
  quantities: AdjustmentQuantities | null;
  setQuantity: (adjustmentId: number, quantity: number) => void;
  setAdjustment: (adjustment: AdjustmentRecord) => void;
  handleOpenProductDrawer: (adjustment: AdjustmentRecord) => void;
}

const AuthorizationTableRow: FunctionComponent<Props> = (props) => {
  const {
    adjustment,
    toggle,
    selected,
    setOpenDocumentList,
    quantities,
    setQuantity,
    setAdjustment,
    handleOpenProductDrawer,
  } = props;
  const { t } = useTranslation();

  const option = registerOptions.find((el) => el.type === adjustment.typeLoss);

  return (
    <TableRow
      sx={{
        bgcolor: selected.includes(adjustment.id) ? "#E2F0FF" : "white",
      }}
    >
      <TableCell align="center">
        {!adjustment.isFinishedProduct && (
          <Checkbox
            onChange={() => toggle(adjustment.id)}
            checked={selected.includes(adjustment.id)}
          />
        )}
      </TableCell>
      <TableCell align="left">
        <Typography>{adjustment.name}</Typography>
      </TableCell>
      <TableCell align="center">
        <TextField
          name="value"
          value={
            quantities !== null
              ? quantities[adjustment.id]
              : adjustment.quantity
          }
          label={t(commons.REPORTED_AMOUNT)}
          size="small"
          type="tel"
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
            min: 0,
          }}
          onChange={(e) => setQuantity(adjustment.id, Number(e.target.value))}
          sx={{ maxWidth: 160 }}
          autoComplete="off"
        />
      </TableCell>
      <TableCell align="center">
        <Typography fontWeight={600} color="text.secondary">
          {t(measureUnits[adjustment.unit as keyof typeof measureUnits])}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            textTransform="uppercase"
            color="text.secondary"
            sx={{ px: 2 }}
          >
            {t(`adjustmentType.${adjustment.typeLoss}`)}
          </Typography>
          {option?.updaloadEvicence && (
            <IconButton
              color="info"
              onClick={() => {
                setAdjustment(adjustment);
                setOpenDocumentList(true);
              }}
            >
              <VisibilityOutlinedIcon />
            </IconButton>
          )}
          {adjustment.isFinishedProduct && (
            <IconButton onClick={() => handleOpenProductDrawer(adjustment)}>
              <FactCheckOutlinedIcon />
            </IconButton>
          )}
        </Box>

        {adjustment.motiveId && (
          <Typography textTransform="uppercase" color="text.secondary">
            {t(allReasons[adjustment.motiveId])}
          </Typography>
        )}
      </TableCell>
      <TableCell align="center">
        <Typography fontWeight={700} textTransform="uppercase">
          {convertLocalDate(adjustment.createdAt.toString(), "date")}
        </Typography>
        <Typography fontWeight={700} textTransform="uppercase">
          {convertLocalDate(adjustment.createdAt.toString(), "time")}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Avatar
          alt={adjustment.username}
          sx={{ width: 32, height: 32, mx: "auto" }}
          src={getUserImage(adjustment.userId)}
        />
        <Typography>{adjustment.username}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default AuthorizationTableRow;
