import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/es";
import "dayjs/locale/pt";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const localizeDayjs = (locale: string) => dayjs.locale(locale);

export default dayjs;
