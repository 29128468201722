import { AlertColor } from "@mui/material";
import { commons, transferOrdersPackedReviewTable } from "app/i18n/types";
import { getCityCodenameByName } from "app/utils/city.utils";
import {
  FdlgyCountry,
  FoodologyCountry,
  inventoryAccuracyConfig,
} from "config/accuracy";
import {
  AdjustmentFromStore,
  AdjustmentRecord,
} from "core/dailyAdjustments/entities/Adjustment";
import { Product } from "core/dailyAdjustments/entities/Catalog";
import { CountingType } from "core/physicalCount/entities/Counting";
import { PurchaseLine } from "core/purchaseOrders/entities/PurchaseOrder";
import { Kitchen } from "core/supplies/entities/Kitchen";
import { Supply, SupplyByGroup } from "core/supplies/entities/Supply";
import { TheoreticalInventoryComparisonByKitchen } from "core/theoricalInventory/entities/ThereticalInventory";
import { TransferOrderLineRefined } from "core/transfer/entities/TransferOrder";
import { ColorType } from "./generalUI";

export interface ServiceLevelResult {
  serviceLevel: number;
  serviceLevelPercentage: string;
  color: string;
}

export const getTransferItemServiceLevel = (
  value: number | undefined | null,
  total: number
): ServiceLevelResult => {
  let color = "red";
  const serviceLevel = total > 0 ? ((value ?? 0) * 100) / total : 0;

  if (86 <= serviceLevel && serviceLevel <= 99) {
    color = "orange";
  }
  if (100 <= serviceLevel && serviceLevel <= 115) {
    color = "green";
  }
  return {
    serviceLevel,
    serviceLevelPercentage: `${serviceLevel.toFixed(0)}%`,
    color,
  };
};

export const tableHeaderSX = {
  "& > th": {
    bgcolor: "#F9FAFC",
    borderBottom: "2px solid #E3E5E8",
    color: "text.secondary",
    fontWeight: 700,
    textTransform: "uppercase",
  },
};

export const sortStringOfDate = (a: string, b: string) => {
  const aTime = new Date(a).getTime();
  const bTime = new Date(b).getTime();
  return aTime - bTime;
};

export const getDatesBetween = (
  startDateStr: string,
  endDateStr: string
): string[] => {
  const dates: string[] = [];
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  while (startDate <= endDate) {
    dates.push(startDate.toISOString().split("T")[0]);
    startDate.setDate(startDate.getDate() + 1);
  }

  return dates;
};

export interface PhysicalCountScheduleLocation {
  country: string;
  city: string;
  kitchenName: string;
  kitchenId: string;
  kitchens: Kitchen[];
}

export const allCitiesLabel = "Todas";

export const allKitchens = {
  kitchenId: "all",
  name: "Todas",
  country: "",
  city: "",
  address: "",
} as Kitchen;

export enum CountingTypeCode {
  daily_massive = "daily_massive",
  daily_individual = "daily_individual",
  daily = "daily",
  weekly = "weekly",
  monthly = "monthly",
  reCount = "reCount",
  other = "other",
  other_individual = "other_individual",
  other_massive = "other_massive",
}

export const obtainSuppliesByGroups = (
  suppliesArray: Supply[],
  lastGroup: string,
  suppliesByGroupArray: SupplyByGroup[]
) => {
  let suppliesTmp = [...suppliesArray];
  if (suppliesTmp.length) {
    let group = lastGroup;
    const found = suppliesTmp.find((el) => el.group !== group);
    if (found) group = found.group;
    if (group) {
      suppliesByGroupArray.push({
        group,
        supplies: suppliesTmp.filter((el) => el.group === group),
      });
      suppliesTmp = suppliesTmp.filter((el) => el.group !== group);
      obtainSuppliesByGroups(suppliesTmp, group, suppliesByGroupArray);
    }
  }
};

export const replaceLineBreak = (param: any) =>
  typeof param === "string" ? param.replace(/\n/g, "").trim() : param;

export const replaceCommasWithPeriods = (param: string) =>
  param.replace(/,/g, ".");

export const clearSpecialCharacters = (text: string): string => {
  const result = text
    .replace(/(.)/g, (format) => format.toUpperCase())
    // .replace(/\s/g, "-")
    .replace(/\./g, "")
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
  return replaceLineBreak(result);
};

export const getFoodologyCountry = (country: string) => {
  const text = replaceLineBreak(country || FdlgyCountry.COL);
  return clearSpecialCharacters(text).slice(0, 3);
};

export type PurchaseLineStatus =
  | commons.COMPLETE
  | commons.PARTIAL
  | commons.PENDING;

export const getPurchaseLineStatus = (
  line: PurchaseLine
): PurchaseLineStatus => {
  if (line.status === "COMPLETE" || line.quantityReceive >= line.quantity) {
    return commons.COMPLETE;
  }
  if (line.status === "PARTIAL" || line.quantityReceive) {
    return commons.PARTIAL;
  }

  return commons.PENDING;
};

export type DataFilterPurchaseOrder =
  | "all"
  | "pending"
  | "partial"
  | "complete";

export type DataFilterTransfer =
  | "all"
  | transferOrdersPackedReviewTable.COMPLETE
  | transferOrdersPackedReviewTable.PARTIAL
  | transferOrdersPackedReviewTable.NO_SENT;

export enum DataFilterTransferOrder {
  ALL = "ALL",
  RELEASED = "RELEASED",
  IN_PROCESS = "IN_PROCESS",
  PACKED = "PACKED",
  IN_TRANSIT = "IN_TRANSIT",
  RECEIVED = "RECEIVED",
  RETURNED = "RETURNED",
  PARTIAL_RECEIVED = "PARTIAL_RECEIVED",
}

export const transferLineCompleteValidator = (line: TransferOrderLineRefined) =>
  line.sendQuantity >= line.quantity;

export const transferLinePartialValidator = (line: TransferOrderLineRefined) =>
  line.sendQuantity < line.quantity && line.sendQuantity > 0;

export const transferLineNoSentValidator = (line: TransferOrderLineRefined) =>
  !transferLineCompleteValidator(line) && !transferLinePartialValidator(line);

export type DataFilterTheoricalComparision =
  | "all"
  | "none"
  | "zero"
  | "low"
  | "high";

export const roundNumber = (num?: number | null): number => {
  if (!num) {
    return 0;
  }

  return Math.round((num + Number.EPSILON) * 100) / 100;
};

export const getInventoryAccuracyConfig = (
  item: TheoreticalInventoryComparisonByKitchen,
  country: FoodologyCountry
) => {
  const config = inventoryAccuracyConfig.find(
    (config) => config.country === country
  );

  let exceptionValidator = false;
  let percentageMin = 0;
  let percentageMax = 0;
  if (config) {
    exceptionValidator =
      exceptionValidator || config.exceptions.skus.includes(item.sku);
    exceptionValidator =
      exceptionValidator || config.exceptions.groups.includes(item.group);

    const tolerance = config.tolerance.find((el) => el.unit === item.unit);
    if (tolerance) {
      percentageMin = tolerance.percentage.min;
      percentageMax = tolerance.percentage.max;
    }
  }
  return { exceptionValidator, percentageMin, percentageMax };
};

export const anyValueToString = (value: unknown): string => {
  const typeStr = typeof value;
  switch (typeStr) {
    case "object":
      return JSON.stringify(value);
    case "undefined":
      return "";
    default:
      return String(value);
  }
};

export const getIsRecountOrMassive = (
  selectedCountingType: CountingType | null
) =>
  selectedCountingType?.code === CountingTypeCode.reCount ||
  selectedCountingType?.code === CountingTypeCode.daily_massive ||
  selectedCountingType?.code === CountingTypeCode.other_massive;

export const s3Url = "https://images-kos.s3.amazonaws.com/";

export const getUserImage = (userId?: string) => {
  return `${s3Url}users/${userId}.jpg`;
};

export const getProductImageDefault = (): string => {
  return `${s3Url}img_productos/default.jpg`;
};

export const getProductImageUrl = (product: Product) => {
  return `${s3Url}${product.country.toLowerCase()}/img_productos/${
    product.sku
  }.jpeg`;
};

export const refinedAdjustments = (
  adjustments?: AdjustmentFromStore[] | null
): AdjustmentRecord[] => {
  return (
    adjustments?.map((el) => {
      return { ...el, createdAt: new Date(el.createdAt) };
    }) ?? []
  );
};

export const stringToNumber = (value: string) => {
  const quantityTmp = Number(value);
  return !quantityTmp ? 0 : quantityTmp;
};

export const getCurrentWeekNumber = (date?: Date) => {
  const day = date ?? new Date();
  day.setHours(0, 0, 0, 0);
  day.setDate(day.getDate() + 4 - (day.getDay() || 7));
  const dateInMilliseconds = day.getTime();
  const year = day.getFullYear();
  const startOfYearInMilliseconds = new Date(year, 0, 1).getTime();
  return Math.ceil(
    ((dateInMilliseconds - startOfYearInMilliseconds) / 8.64e7 + 1) / 7
  );
};

export const slugsForKitchenSelector = [":kitchenId", ":cpId", ":locationId"];

export const dayOfTheWeek = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
];

export const monthOfYear = [
  "JANUARY",
  "FEBRUARY",
  "MARCH",
  "APRIL",
  "MAY",
  "JUNE",
  "JULY",
  "AUGUST",
  "SEPTEMBER",
  "OCTOBER",
  "NOVEMBER",
  "DECEMBER",
];

export interface S3Folder {
  templates: {
    root: string;
    files: {
      productionPlan: string;
    };
  };
}

export const s3Folder: S3Folder = {
  templates: {
    root: "templates",
    files: {
      productionPlan: "production-plan.xlsx",
    },
  },
};

export const openUrl = (url: string) => {
  if (!url) return;
  window.open(url, "_blank");
};

export const compareSearchText = (
  word: string | number | null,
  search: string
): boolean => {
  const toSearch = clearSpecialCharacters(search.toLowerCase());
  return clearSpecialCharacters((word ?? "").toString().toLowerCase()).includes(
    toSearch
  );
};

export type OnChangeLabel =
  | "-"
  | "expectedReceiptAt"
  | "removeMany"
  | "detailNew"
  | "detailUnitPrice"
  | "detailQuantity"
  | "packedQuantity"
  | "status"
  | "progress"
  | "unitCost"
  | "quantityReceive"
  | "quantity"
  | "detailRemoveMany";

export interface OnChange {
  id?: number;
  detailId?: number;
  label: OnChangeLabel;
  value?: unknown;
  quantity?: number;
}

export const onChangeDefault: OnChange = {
  id: undefined,
  detailId: undefined,
  label: "-",
  value: undefined,
};

export const getAlertColor = (accuracy: number): AlertColor => {
  let color: AlertColor = "warning";
  if (accuracy >= 85) {
    color = "success";
  }
  if (accuracy < 50) {
    color = "error";
  }
  return color;
};

export const getVendorCategoryColor = (type: string): ColorType => {
  if (type === "A") return "success";
  if (type === "B") return "info";
  if (type === "C") return "warning";
  return "default";
};

export type LocationType = "COUNTRY" | "CITY" | "KITCHEN";

interface LocationAndType {
  location: string;
  type: LocationType;
}

export interface LocationAndTypeParams {
  selectedKitchen?: string | null;
  selectedCity?: string | null;
  selectedCountry?: string | null;
}

export const getLocationAndType = (
  params: LocationAndTypeParams
): LocationAndType | undefined => {
  const { selectedKitchen, selectedCity, selectedCountry } = params;
  if (selectedKitchen) return { location: selectedKitchen, type: "KITCHEN" };
  if (selectedCity)
    return {
      location: getCityCodenameByName(selectedCity)[0] ?? "",
      type: "CITY",
    };
  if (selectedCountry) return { location: selectedCountry, type: "COUNTRY" };
  return undefined;
};
