/* eslint-disable react/require-default-props */
import React from "react";

interface IconProp {
  fontSize?: string;
}

const SuppliesManagementIcon = (props: IconProp): React.ReactElement => {
  const { fontSize } = props;
  return (
    <svg
      width={fontSize ?? 40}
      height={fontSize ?? 40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width={fontSize ?? 40}
        height={fontSize ?? 40}
        rx="20"
        fill="#F1EFEF"
      />
      <mask
        id="mask0_5932_431"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="24"
        height="24"
      >
        <rect x="8" y="8" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5932_431)">
        <path
          d="M15 26C13.3333 26 11.9167 25.4167 10.75 24.25C9.58333 23.0833 9 21.6667 9 20C9 18.3333 9.58333 16.9167 10.75 15.75C11.9167 14.5833 13.3333 14 15 14H25C26.6667 14 28.0833 14.5833 29.25 15.75C30.4167 16.9167 31 18.3333 31 20C31 21.6667 30.4167 23.0833 29.25 24.25C28.0833 25.4167 26.6667 26 25 26H15ZM15 24H25C26.1 24 27.0417 23.6083 27.825 22.825C28.6083 22.0417 29 21.1 29 20C29 18.9 28.6083 17.9583 27.825 17.175C27.0417 16.3917 26.1 16 25 16H15C13.9 16 12.9583 16.3917 12.175 17.175C11.3917 17.9583 11 18.9 11 20C11 21.1 11.3917 22.0417 12.175 22.825C12.9583 23.6083 13.9 24 15 24ZM25 23C25.8333 23 26.5417 22.7083 27.125 22.125C27.7083 21.5417 28 20.8333 28 20C28 19.1667 27.7083 18.4583 27.125 17.875C26.5417 17.2917 25.8333 17 25 17C24.1667 17 23.4583 17.2917 22.875 17.875C22.2917 18.4583 22 19.1667 22 20C22 20.8333 22.2917 21.5417 22.875 22.125C23.4583 22.7083 24.1667 23 25 23Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  );
};

export default SuppliesManagementIcon;
