import { Box, CardContent } from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardCarrousel from "app/components/common/CardCarrousel";
import { commons } from "app/i18n/types";
import { KitchenSelection } from "core/transfer/entities/TransferOrder";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import StoreOutlinedIcon from "utils/icons/StoreOutlinedIcon";

interface KitchenSelectorProps {
  kitchens: KitchenSelection[];
  selectedTransfer: number;
  setSelectedTransfer: (transferId: number) => void;
}

const KitchenSelector: FunctionComponent<KitchenSelectorProps> = (props) => {
  const { kitchens, selectedTransfer, setSelectedTransfer } = props;
  const { t } = useTranslation();

  return (
    <>
      {!!kitchens.length && (
        <CardCarrousel
          content={
            <>
              {kitchens.map((kitchen) => (
                <Card
                  key={`KitchenSelector-${kitchen.transferId}`}
                  sx={{
                    minWidth: "fit-content",
                    cursor: "pointer",
                    backgroundColor:
                      selectedTransfer === kitchen.transferId
                        ? "#3c81db1f"
                        : undefined,
                  }}
                  onClick={() => setSelectedTransfer(kitchen.transferId)}
                >
                  <CardContent
                    sx={{
                      display: "inline-flex",
                      padding: "8px 16px !important",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ display: "grid", pr: 2 }}>
                      <Typography variant="body2" sx={{ lineHeight: "24px" }}>
                        #{kitchen.transferId.toString().slice(-2)}
                      </Typography>
                      <StoreOutlinedIcon fontSize="24" />
                    </Box>
                    <Box>
                      <Typography fontSize={16} fontWeight={700}>
                        {kitchen.name}
                      </Typography>
                      <Typography fontSize={16} color="primary">
                        {`${kitchen.lines} ${t(commons.SUPPLIES)}`}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              ))}
            </>
          }
        />
      )}
    </>
  );
};

export default KitchenSelector;
