import { UseCase } from "core/common/interfaces/useCase";
import { canSetProductionDetailUsedQuantity } from "../interfaces/canSetProductionDetailUsedQUantity";

export class SetProductionDetailUsedQuantity implements UseCase {
  constructor(
    private readonly repository: canSetProductionDetailUsedQuantity
  ) {}

  public execute(productionId: string, usedQuantity: number) {
    return this.repository.setProductionDetailUsedQuantity(
      productionId,
      usedQuantity
    );
  }
}
