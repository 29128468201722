import { ManagerDialog } from "app/components/common/Manager/Dialog";
import { commons, productionManagement } from "app/i18n/types";
import { ProductionPlanSelectedSlot } from "core/productions/entities/Plan";
import { useTranslation } from "react-i18next";
import { Actions } from "utils/modal";
import { FormState } from "./constants";
import { CreateDialogContent } from "./createDialogContent";
import { EditDialogContent } from "./editDialogContent";

interface Props {
  action: Actions;
  open: boolean;
  selectedSlot?: ProductionPlanSelectedSlot;
  isSlotAdded: boolean;
  onClose: () => void;
  currentPlan?: FormState;
  refreshCalendar?: () => void;
}

export const HandleDialog = (props: Props) => {
  const {
    onClose,
    open,
    action,
    selectedSlot,
    isSlotAdded,
    currentPlan,
    refreshCalendar,
  } = props;

  const { t } = useTranslation();

  const dialogTitle = {
    [Actions.add]: t(productionManagement.ADD_PRODUCTION_TITLE),
    [Actions.edit]: t(productionManagement.EDIT_PRODUCTION_TITLE),
    [Actions.view]: t(commons.VENDOR_INFO),
  };
  return (
    <ManagerDialog
      open={open}
      title={dialogTitle[action]}
      onClose={onClose}
      content={
        <>
          {action === Actions.add && (
            <CreateDialogContent
              action={action}
              onClose={onClose}
              selectedSlot={selectedSlot}
              isSlotAdded={isSlotAdded}
              refreshCalendar={refreshCalendar}
            />
          )}

          {action === Actions.edit && (
            <EditDialogContent
              action={action}
              onClose={onClose}
              currentPlan={currentPlan}
              refreshCalendar={refreshCalendar}
            />
          )}
        </>
      }
    />
  );
};
