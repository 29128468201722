import { AppConfig } from "config/app";
import { ApiVersion, getHttp } from "utils/http";
import {
  TheoreticalInventory,
  TheoreticalInventoryComparisonByCityResponse,
  TheoreticalInventoryComparisonByCountry,
  TheoreticalInventoryComparisonByKitchenResponse,
  TheoreticalInventoryMovementsResumeResponse,
} from "../entities/ThereticalInventory";
import { CanGetByKitchen } from "../interfaces/canGetByKitchen";

import { CanGetByKitchenAndSkus } from "../interfaces/canGetByKitchenAndSkus";
import { CanGetComparisonByCountry } from "../interfaces/canGetComparisonByCountry";
import { CanGetComparisonByKitchen } from "../interfaces/canGetComparisonByKitchen";
import { CanExportInventory } from "../interfaces/canGetExportInventory";
import { CanGetTheoreticalInventoryMovementsResume } from "../interfaces/canGetMovementsDetailsResume";

export class TheoreticalInventoryHTTPRepository
  implements
    CanGetByKitchen,
    CanExportInventory,
    CanGetTheoreticalInventoryMovementsResume,
    CanGetComparisonByCountry,
    CanGetComparisonByKitchen,
    CanGetByKitchen,
    CanGetByKitchenAndSkus
{
  constructor(private readonly config: AppConfig) {}

  public async getTheoreticalInventoryMovementsResume(
    kitchenId: string,
    sku: string
  ): Promise<TheoreticalInventoryMovementsResumeResponse> {
    try {
      const theoreticalInventoryMovementsResume = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `theoretical-inventory/${kitchenId}/${sku}`
      );
      const theoreticalInventoryMovementsResumeJson: TheoreticalInventoryMovementsResumeResponse =
        await theoreticalInventoryMovementsResume.json();
      return theoreticalInventoryMovementsResumeJson;
    } catch (err) {
      console.error("get Theoretical Inventory Movements Resume error", err);
      throw err;
    }
  }

  public async getByKitchen(
    kitchenId: string
  ): Promise<Array<TheoreticalInventory>> {
    try {
      const inventory = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `theoretical-inventory/${kitchenId}`
      );
      const inventoryJson: Array<TheoreticalInventory> = await inventory.json();
      return inventoryJson;
    } catch (err) {
      console.error("Get Theoretical inventory error", err);
      throw err;
    }
  }

  public async getByKitchenAndSkus(
    kitchenId: string,
    skus: string[]
  ): Promise<Array<TheoreticalInventory>> {
    try {
      const inventory = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `theoretical-inventory/kitchen/${kitchenId}/skus/${skus.join(",")}`
      );
      const inventoryJson: Array<TheoreticalInventory> = await inventory.json();
      return inventoryJson;
    } catch (err) {
      console.error("Get Theoretical inventory error", err);
      throw err;
    }
  }

  public async getComparisonByKitchen(
    kitchenId: string
  ): Promise<TheoreticalInventoryComparisonByKitchenResponse> {
    try {
      const inventory = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `theoretical-inventory/${kitchenId}/comparison`
      );
      const inventoryJson: TheoreticalInventoryComparisonByKitchenResponse =
        await inventory.json();
      return inventoryJson;
    } catch (err) {
      console.error("Get Theoretical inventory comparison error", err);
      throw err;
    }
  }

  public async getComparisonByCity(
    city: string
  ): Promise<TheoreticalInventoryComparisonByCityResponse> {
    try {
      const inventory = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `theoretical-inventory/city/${city}/comparison`
      );
      const inventoryJson: TheoreticalInventoryComparisonByCityResponse =
        await inventory.json();
      return inventoryJson;
    } catch (err) {
      console.error("Get Theoretical inventory city comparison error", err);
      throw err;
    }
  }

  public async getComparisonByCountry(
    country: string
  ): Promise<Array<TheoreticalInventoryComparisonByCountry>> {
    try {
      const inventory = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `theoretical-inventory/${country}/comparison/report`
      );
      const inventoryJson: Array<TheoreticalInventoryComparisonByCountry> =
        await inventory.json();
      return inventoryJson;
    } catch (err) {
      console.error("Get Theoretical inventory comparison error", err);
      throw err;
    }
  }

  public async exportInventory(
    country: string
  ): Promise<Array<TheoreticalInventory>> {
    try {
      const inventory = await getHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `theoretical-inventory/country/${country}`
      );
      const inventoryJson: Array<TheoreticalInventory> = await inventory.json();
      return inventoryJson;
    } catch (err) {
      console.error("Get Theoretical inventory by country error", err);
      throw err;
    }
  }
}
