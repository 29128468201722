import { UseCase } from "core/common/interfaces/useCase";
import {
  CanArchiveCounts,
  CanFinishCounting,
} from "core/physicalCount/interfaces/counting/canFinishCounting";

export class FinishCountingUseCase implements UseCase {
  constructor(private readonly repository: CanFinishCounting) {}

  public execute(countingId: string) {
    return this.repository.finishCounting(countingId);
  }
}

export class ArchiveCountsUseCase implements UseCase {
  constructor(private readonly repository: CanArchiveCounts) {}

  public execute(countingIds: number[]) {
    return this.repository.archiveCounts(countingIds);
  }
}
