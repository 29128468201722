import { AttachmentFile } from "core/attachment/entity";
import { PurchaseOrderOrigin } from "./PurchaseOrder";

export interface ReceiptOrder {
  purchaseOrderId?: number;
  receiptNo: string;
  vendorName: string;
  type: string;
  sourceId: string;
  createAt: Date;
  expectedAt: Date;
  partial: boolean;
  origin: PurchaseOrderOrigin;
  invoiceNo: string;
  attachments: AttachmentFile[];
  isReturn: boolean;
}

export interface AttachmentsDetailResponse {
  invoiceNo: string;
  attachments: AttachmentFile[];
}

export enum ReceiptType {
  TRANSFER_ORDER = "TRANSFER_ORDER",
  PURCHASE_ORDER = "PURCHASE_ORDER",
}

export interface AttachmentSavePayload {
  code: string;
  origin: string;
  type: string;
  invoiceNo: string;
  kitchenId: string;
  attachments: AttachmentFile[];
}

export interface ReceivedOrder {
  id: number;
  type: string;
  code: string;
  receivedById: string | null;
  receivedByName: string | null;
  receivedAt: Date | null;
  country: string;
  vendorName: string;
}
