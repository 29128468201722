import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { commons } from "app/i18n/types";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface ListHeaderProps {
  label: string;
  count: number;
  sticky: boolean;
  percent?: number;
  selected?: number;
  open?: boolean;
  onClick?: () => void;
}

const ListHeader: FunctionComponent<ListHeaderProps> = (props) => {
  const { label, count, sticky, percent, selected, onClick, open } = props;

  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      gap={1}
      alignItems="center"
      bgcolor="#F1EFEF"
      zIndex={1099}
      position={sticky ? "sticky" : "initial"}
      justifyContent="space-between"
      top={-16}
      px={2}
      py={1}
      width="100%"
      sx={{
        cursor: onClick ? "pointer" : "inherit",
      }}
      onClick={onClick}
    >
      <Box display="flex" gap={1} alignItems="center">
        <Avatar
          sx={{
            width: 32,
            height: 32,
            bgcolor: "primary.main",
            fontSize: 16,
          }}
        >
          {count}
        </Avatar>
        <Typography variant="h6">{label}</Typography>
      </Box>

      <Box display="flex" gap={2} alignItems="center">
        {percent !== undefined && percent >= 0 && (
          <Typography variant="h6">{percent}%</Typography>
        )}
        {selected !== undefined && selected >= 0 && (
          <Typography variant="h6">
            {t(commons.SELECTED)}: {selected}
          </Typography>
        )}
        {open !== undefined && (
          <>{open === true ? <ExpandMoreIcon /> : <ChevronRightIcon />}</>
        )}
      </Box>
    </Box>
  );
};

export default ListHeader;
