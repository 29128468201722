import { MovementType } from "config/dailyAdjustment";

export enum Status {
  IN_REVIEW = "IN_REVIEW",
  PRE_APPROVED = "PRE_APPROVED",
  APPROVED = "APPROVED",
  ARCHIVED = "ARCHIVED",
  IN_VALIDATION = "IN_VALIDATION",
  VALIDATED = "VALIDATED",
}

export type StatusType = `${Status}`;

export type Adjustment = {
  typeLoss: MovementType;
  kitchenId: string;
  sku: string;
  motiveId: string | null;
  quantity: number;
  image: string | null;
  userId: string;
  status: StatusType;
  name: string;
  unit: string;
  username: string;
  isFinishedProduct: boolean;
};

export type AdjustmentRecord = Adjustment & {
  id: number;
  createdAt: Date;
};

export type AdjustmentFromStore = Adjustment & {
  id: number;
  createdAt: string;
};

export type AuthorizeAdjustmentsPayload = {
  id: number;
  quantity: number;
  type: String;
  kitchenId: String;
  documentDate: String;
  documentCode: string;
  entryType: string;
  sku: String;
  quantityAfterValidation?: number;
  quantityDifference?: number;
};
