import { KOSEmptyState } from "@foodology-co/alejandria";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import BoxTitleTable from "app/components/PurchaseRecord/BoxTitleTable";
import PurchaseOrdersTableSkeleton from "app/components/PurchaseRecord/PurchaseOrdersSkeleton";
import {
  commons,
  measureUnits,
  productionManagement,
  supplyingManagement,
  transferOrdersPackedReviewTable,
} from "app/i18n/types";
import { ProductionSupplyDetail } from "core/productions/entities/Supply";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import UpdatePackedQuantityTextField from "../UpdatePackedQuantityTextField";

interface Props {
  supplies: ProductionSupplyDetail[];
  filteredSupplies: ProductionSupplyDetail[];
  updatePackedQuantity: (id: number, packedQuantity: number) => void;
  search: string;
  loading: boolean;
}

const ProductionSupplyDetailTable: FunctionComponent<Props> = (props) => {
  const { supplies, filteredSupplies, loading, updatePackedQuantity, search } =
    props;
  const { t } = useTranslation();

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 650, maxHeight: 600 }}
        aria-label="inventory"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <BoxTitleTable data={t(commons.SKU)} />
            <BoxTitleTable data={t(commons.PRODUCT_NAME)} />
            <BoxTitleTable
              data={t(productionManagement.TIME_SLOT)}
              align="center"
            />
            <BoxTitleTable data={t(commons.REQUIRED_QUANTITY)} align="center" />
            <BoxTitleTable
              data={t(supplyingManagement.OVER_QUANTITY)}
              align="center"
            />
            <BoxTitleTable
              data={t(supplyingManagement.SUGGESTED_QUANTITY)}
              align="center"
            />
            <BoxTitleTable
              data={t(transferOrdersPackedReviewTable.PACKED_QUANTITY)}
              align="center"
            />
            <BoxTitleTable data={t(commons.UNIT)} align="center" />
          </TableRow>
        </TableHead>

        {loading && (
          <PurchaseOrdersTableSkeleton columns={8} hideActionColumn />
        )}

        <TableBody>
          {!loading && !filteredSupplies.length && (
            <TableRow>
              <TableCell colSpan={8} align="center">
                <KOSEmptyState
                  icon={InfoOutlinedIcon}
                  message={
                    !supplies.length
                      ? t(commons.TABLE_EMPTY)
                      : t(commons.NO_SEARCH_RESULTS, { search })
                  }
                />
              </TableCell>
            </TableRow>
          )}
          {!loading &&
            filteredSupplies.map((item) => (
              <TableRow key={`ProductionSupplyRow${item.id}`}>
                <TableCell align="left">
                  <Typography fontWeight={300} color="#454B54">
                    {item.sku}
                  </Typography>
                </TableCell>

                <TableCell align="left">
                  <Typography fontWeight={300} color="#454B54">
                    {item.name}
                  </Typography>
                </TableCell>

                <TableCell align="center">
                  <Typography fontWeight={300} color="#454B54">
                    {item.timeSlot}
                  </Typography>
                </TableCell>

                <TableCell align="center">{item.quantity}</TableCell>

                <TableCell align="center">{item.overQuantity}</TableCell>

                <TableCell align="center">{item.suggestedQuantity}</TableCell>

                <TableCell align="center">
                  {item.status === "PENDING" ? (
                    <UpdatePackedQuantityTextField
                      item={item}
                      setPackedQuantity={updatePackedQuantity}
                      onlyPlaceholder
                    />
                  ) : (
                    <Typography fontWeight={300} color="#454B54">
                      {item.packedQuantity}
                    </Typography>
                  )}
                </TableCell>

                <TableCell align="center">
                  {t(
                    measureUnits[item.measureUnit as keyof typeof measureUnits]
                  ).toUpperCase()}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductionSupplyDetailTable;
