/* eslint-disable react/require-default-props */
import React from "react";

interface IconProp {
  fontSize?: string;
}

const KitchenAdjustmtnsAuthorizationIcon = (
  props: IconProp
): React.ReactElement => {
  const { fontSize } = props;
  return (
    <svg
      width={fontSize ?? 40}
      height={fontSize ?? 40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width={fontSize ?? 40}
        height={fontSize ?? 40}
        rx="20"
        fill="#F1EFEF"
      />
      <mask
        id="mask0_5928_527"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="24"
        height="24"
      >
        <rect x="8" y="8" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5928_527)">
        <path
          d="M22.4 28.0008L21 26.6008L23.6 24.0008L21 21.4008L22.4 20.0008L25 22.6008L27.6 20.0008L29 21.4008L26.4 24.0008L29 26.6008L27.6 28.0008L25 25.4008L22.4 28.0008ZM24.375 19.0008L20.825 15.4508L22.225 14.0508L24.35 16.1758L28.6 11.9258L30 13.3508L24.375 19.0008ZM10 25.0008V23.0008H19V25.0008H10ZM10 17.0008V15.0008H19V17.0008H10Z"
          fill="#232323"
        />
      </g>
    </svg>
  );
};

export default KitchenAdjustmtnsAuthorizationIcon;
