import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { convertDateUtc } from "app/utils/dateUtils";
import { CommentDTO } from "core/comment/entity";
import { getUserImage } from "utils/general";

interface Props {
  comment: CommentDTO;
}

const CommentCardContent = (props: Props) => {
  const { comment } = props;
  const date = convertDateUtc(comment.createdAt);

  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Avatar
          alt={comment.createdByName}
          src={getUserImage(comment.createdBy)}
        />
      </ListItemAvatar>
      <ListItemText
        primary={comment.content}
        secondary={`${comment.createdByName} - ${date}`}
      />
    </ListItem>
  );
};

export default CommentCardContent;
