import { ManualAdjustment } from "core/adjustments/entities/ManualAdjustment";
import { CanAddManualAdjustment } from "core/adjustments/interfaces/adjustment/canAddManualAdjustment";
import { UseCase } from "core/common/interfaces/useCase";

export class AddManualAdjustmentUseCase implements UseCase {
  constructor(private readonly repository: CanAddManualAdjustment) {}

  public execute(adjustments: Array<ManualAdjustment>) {
    return this.repository.addManualAdjustment(adjustments);
  }
}
