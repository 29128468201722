import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FocusEvent, FunctionComponent } from "react";

import { useAppSelector } from "app/hooks/useAppSelector";
import { commons } from "app/i18n/types";
import { onInputKeyDown } from "app/utils/input";
import { Product } from "core/physicalCount/entities/Product";
import { useTranslation } from "react-i18next";
import GroupTableRow from "./GroupTableRow";

interface PhysicalCountGroupTableProps {
  items: Array<Product>;
  openSumModalSku: string | null;
  onChange: (event: FocusEvent<HTMLInputElement>, product: Product) => void;
  onCountIncrement: (item: Product, increment: number) => void;
  onFinishItemCounting: (sku: string) => void;
  onCloseSumModal: () => void;
}

const PhysicalCountGroupTable: FunctionComponent<
  PhysicalCountGroupTableProps
> = (props) => {
  const { t } = useTranslation();

  const {
    items,
    onChange,
    onCountIncrement,
    onFinishItemCounting,
    openSumModalSku,
    onCloseSumModal,
  } = props;
  const searchFilter = useAppSelector((state) => state.counting.filters.search);

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table
        aria-label="physical count table"
        sx={{
          "& thead": {
            display: {
              xs: "none",
              sm: "table-header-group",
            },
          },

          "& tr": {
            display: {
              xs: "grid",
              sm: "table-row",
            },
            gridTemplateColumns: "100px 1fr",
            gridTemplateRows: "auto",
            gridTemplateAreas: `
                    "thname name"
                    "thunit unit"
                    "thsku sku"`,
            borderBottom: {
              xs: "1rem solid #F3F8FF",
              sm: "inherit",
            },
          },
          "& .th-name": {
            gridArea: "thname",
            display: {
              xs: "block",
              sm: "none",
            },
          },
          "& .th-unit": {
            gridArea: "thunit",
            display: {
              xs: "block",
              sm: "none",
            },
          },
          "& .th-sku": {
            gridArea: "thsku",
            display: {
              xs: "block",
              sm: "none",
            },
          },
          "& .td-name": {
            gridArea: "name",
          },
          "& .td-unit": {
            gridArea: "unit",
          },
          "& .td-sku": {
            gridArea: "sku",
          },
        }}
      >
        <TableHead sx={{ display: { xs: "none", md: "table-header-group" } }}>
          <TableRow>
            <TableCell>{t(commons.NAME)}</TableCell>
            <TableCell align="center" sx={{ width: { xs: "100%", md: 330 } }}>
              {t(commons.MEASURE_UNIT)}
            </TableCell>
            <TableCell>{t(commons.SKU)}</TableCell>
          </TableRow>
        </TableHead>

        <Box component={TableBody} onKeyDown={onInputKeyDown}>
          {items
            .filter(
              (row) =>
                row.name
                  .toLowerCase()
                  .includes(searchFilter?.toLocaleLowerCase() ?? "") ||
                row.SKU.includes(searchFilter ?? "")
            )
            .map((row) => (
              <GroupTableRow
                item={row}
                key={row.SKU}
                onChange={onChange}
                onCountIncrement={onCountIncrement}
                onFinishItemCounting={onFinishItemCounting}
                openSumModalExternal={openSumModalSku}
                onCloseSumModal={onCloseSumModal}
              />
            ))}
        </Box>
      </Table>
    </TableContainer>
  );
};

export default PhysicalCountGroupTable;
