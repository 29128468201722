import { AppConfig } from "config/app";
import { User } from "core/account/entities/User";
import { CanGetUser } from "core/account/interfaces/user/canGetUser";
import { ApiVersion, getHttp } from "utils/http";

export interface UserProfileResponse {
  firstName: string;
  lastName: string;
  name: string;
  position: string;
  identification: string;
  scope: string;
  country: string;
  kitchen: { kitchenId: string };
}

export interface UserResponse {
  _id: string;
  username: string;
  emails: Array<{
    address: string;
  }>;
  profile: UserProfileResponse;
  rol: string;
  roleName: string;
  profileImage: string;
}

export class UserHTTPRepository implements CanGetUser {
  constructor(private readonly config: AppConfig) {}

  public async getUser(): Promise<User> {
    const userResponse = await getHttp(
      this.config.kitchenDisplay.url,
      ApiVersion.API,
      "userInfo"
    );

    const userJson: UserResponse = await userResponse.json();

    const user: User = {
      _id: userJson._id,
      profile: userJson.profile,
      role: userJson.rol,
      profileImage: userJson.profileImage,
      roleName: userJson.roleName,
    };

    return user;
  }
}
