import { CheckCircleOutline, ChevronRight, Close } from "@mui/icons-material";
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { commons, productionLiberation } from "app/i18n/types";
import {
  createOrganolepticsResults,
  getOrganolepticsResults,
  liberateProduction,
} from "app/store/slices/productions/thunks";
import {
  OrganolepticResults,
  Organoleptics,
  OrganolepticsResultResponse,
  ProductionApprovementResult,
  ProductionRecord,
} from "core/productions/entities/Productions";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useMount } from "react-use";
import { getProductionDetail } from "utils/ratatouille";

interface OrganolepticModalProps {
  production: ProductionRecord;
  onClose: () => void;
}

const OrganolepticModal: FunctionComponent<OrganolepticModalProps> = ({
  production,
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { kitchenId } = useParams();
  const [approvementResult, setApprovementResult] =
    useState<ProductionApprovementResult | null>();
  const [novelty, setNovelty] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [result, setResult] = useState<OrganolepticResults>({
    appearance: null,
    color: null,
    smell: null,
    taste: null,
    texture: null,
    viscosity: null,
  });

  const kitchen = useAppSelector((state) => state.global.kitchens.data).find(
    (kitchen) => kitchen.kitchenId === kitchenId
  );

  const [organoleptics, setOrganoleptics] = useState<Organoleptics | null>(
    null
  );

  const handleClose = useCallback(() => {
    setResult({
      appearance: null,
      color: null,
      smell: null,
      taste: null,
      texture: null,
      viscosity: null,
    });
    onClose();
  }, []);

  const handleLiberateProduction = useCallback(async () => {
    if (approvementResult) {
      setLoading(true);
      await dispatch(
        liberateProduction({
          productionId: production.id,
          result: approvementResult,
          novelty: novelty,
        })
      );
      setLoading(false);
      handleClose();
    }
  }, [approvementResult, novelty, production.id, dispatch]);

  const handleChange = useCallback(
    (value: boolean) => {
      setResult((prev) => ({
        ...prev,
        [Object.keys(result)[step]]: value,
      }));
    },
    [result, step]
  );

  const getOrganoleptics = useCallback(async () => {
    setLoading(true);
    if (kitchen) {
      const result = await getProductionDetail(production.sku, kitchen.country);
      if (result) {
        setOrganoleptics(result);
      }
      setLoading(false);
    }
  }, [kitchen]);

  const getResults = useCallback(async () => {
    const response = await dispatch(
      getOrganolepticsResults({ productionId: production.id })
    );
    if (result) {
      const organilepticsResults =
        response.payload as OrganolepticsResultResponse[];
      let organolepticResultsTemp = {
        appearance: null,
        color: null,
        smell: null,
        taste: null,
        texture: null,
        viscosity: null,
      };
      organilepticsResults.forEach((organolepticResult) => {
        organolepticResultsTemp = {
          ...organolepticResultsTemp,
          [organolepticResult.type.toLowerCase()]: organolepticResult.result,
        };
      });
      setResult(organolepticResultsTemp);
      setStep(
        Object.values(organolepticResultsTemp).filter((value) => value !== null)
          .length
      );
    }
  }, [production]);

  const handleNext = useCallback(async () => {
    if (organoleptics) {
      await dispatch(
        createOrganolepticsResults({
          productionId: production.id,
          type: Object.keys(result)[step].toUpperCase(),
          description:
            organoleptics[Object.keys(result)[step] as keyof Organoleptics],
          result: result[
            Object.keys(result)[step] as keyof OrganolepticResults
          ] as boolean,
        })
      );
      setStep((prev) => prev + 1);
    }
  }, [organoleptics, result, step]);

  useEffect(() => {
    if (Object.values(result).every((value) => value === true)) {
      setApprovementResult(ProductionApprovementResult.APPROVED);
    } else {
      setApprovementResult(ProductionApprovementResult.APPROVED_WITH_NOVELTY);
    }
  }, [result]);

  useMount(() => {
    getOrganoleptics();
    getResults();
  });

  return (
    <Dialog open>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" color="primary">
            {t(productionLiberation.ORGANOLEPTIC_MODAL_TITLE)}
          </Typography>
          <IconButton onClick={handleClose} color="primary">
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      {organoleptics ? (
        <>
          <DialogContent
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            {loading && <LinearProgress />}
            {step < Object.keys(result).length ? (
              <>
                <Typography>
                  {t(productionLiberation.ORGANOLEPTIC_MODAL_SUBTITLE)}
                </Typography>
                <Stepper activeStep={step} alternativeLabel>
                  {Object.keys(result).map((key) => (
                    <Step key={key}>
                      <StepLabel>{t(`organoleptics.${key}`)}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <Box display="flex" justifyContent="space-between" px={1}>
                  <Box display="flex" flexDirection="column">
                    <Typography color="primary" fontWeight={600}>
                      {t(`organoleptics.${Object.keys(result)[step]}`)}
                    </Typography>
                    <Typography sx={{ flex: 2 }}>
                      {Object.values(organoleptics)[step]}
                    </Typography>
                  </Box>
                  <RadioGroup
                    value={Object.values(result)[step]}
                    onChange={(e) => handleChange(e.target.value === "true")}
                    row
                    sx={{ flex: 1, justifyContent: "flex-end", gap: 1 }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label={t(commons.YES)}
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label={t(commons.NO)}
                    />
                  </RadioGroup>
                </Box>
              </>
            ) : (
              <>
                <Typography>
                  {t(productionLiberation.ORGANOLEPTIC_MODAL_SUBTITLE_2)}
                </Typography>
                <List sx={{ listStyleType: "disc", ml: 2, py: 0 }}>
                  {Object.keys(result).map((key) => (
                    <ListItem
                      key={key}
                      sx={{ display: "list-item", px: 1, py: 0.5 }}
                    >
                      <Typography key={key}>
                        {t(`organoleptics.${key}`)}:{" "}
                        {result[key as keyof OrganolepticResults]
                          ? t(commons.YES)
                          : t(commons.NO)}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
                <Autocomplete
                  value={approvementResult}
                  sx={{ mt: 2 }}
                  onChange={(
                    _: any,
                    newValue: ProductionApprovementResult | null
                  ) => {
                    setApprovementResult(newValue ?? null);
                  }}
                  options={Object.values(ProductionApprovementResult)}
                  filterOptions={(options) =>
                    options.filter((option) => {
                      if (
                        Object.values(result).some((value) => value === false)
                      ) {
                        return option !== ProductionApprovementResult.APPROVED;
                      } else {
                        return option === ProductionApprovementResult.APPROVED;
                      }
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                      label={t(productionLiberation.RESULT_APPROVEMENT_LABEL)}
                    />
                  )}
                  getOptionLabel={(option) =>
                    t(`productionLiberation.${option}`)
                  }
                />
                {(approvementResult ===
                  ProductionApprovementResult.APPROVED_WITH_NOVELTY ||
                  approvementResult ===
                    ProductionApprovementResult.REJECTED) && (
                  <>
                    <Typography sx={{ mt: 2 }}>
                      {t(productionLiberation.NOVELTY_LABEL)}
                    </Typography>
                    <TextField
                      label={t(commons.COMMENT)}
                      value={novelty}
                      onChange={(e) => setNovelty(e.target.value)}
                      multiline
                      minRows={5}
                    />
                  </>
                )}
              </>
            )}
          </DialogContent>
          <DialogActions>
            {step < Object.keys(result).length ? (
              <Button
                endIcon={<ChevronRight />}
                variant="contained"
                disabled={Object.values(result)[step] === null}
                onClick={handleNext}
              >
                {t(commons.CONTINUE)}
              </Button>
            ) : (
              <Button
                onClick={handleLiberateProduction}
                variant="contained"
                startIcon={<CheckCircleOutline />}
                disabled={
                  loading ||
                  approvementResult ===
                    ProductionApprovementResult.APPROVED_WITH_NOVELTY ||
                  approvementResult === ProductionApprovementResult.REJECTED
                    ? !novelty
                    : approvementResult === null
                }
              >
                {t(commons.SAVE)}
              </Button>
            )}
          </DialogActions>
        </>
      ) : (
        <>
          <DialogContent>
            <Alert severity="error">
              {t(productionLiberation.PRODUCTION_ORGANOLEPTIC_ERROR)}
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t(commons.CLOSE)}</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default OrganolepticModal;
