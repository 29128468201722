import { KOSBaseTableHeader, KOSRowData } from "@foodology-co/alejandria";
import { Typography } from "@mui/material";
import BulkLoad from "app/components/common/BulkLoad";
import {
  isErrorToRequiredColumns,
  rowToNumber,
  rowToString,
} from "app/components/common/BulkLoad/util";
import { useAlert } from "app/hooks/useAlert";
import { bulkLoadModule, commons } from "app/i18n/types";
import { excelToJson } from "app/utils/excel";
import {
  EntryType,
  InventoryAdjustment,
} from "core/adjustments/entities/ManualAdjustment";
import {
  BulkLoadActionType,
  BulkLoadError,
  BulkLoadLineSave,
  BulkLoadLineValidator,
  DataAdjustmentValidator,
} from "core/bulkLoad/entities/BulkLoad";
import {
  adjustmentsValidator,
  saveInventoryAdjustments,
} from "core/bulkLoad/repositories/http/bulkLoad";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row } from "read-excel-file/types";
import { replaceLineBreak } from "utils/general";

interface Props {}

const ManualAdjustmentsBulkLoad = (props: Props) => {
  const { t } = useTranslation();
  const alert = useAlert();

  const requiredColumns = 4;
  const [loading, setLoading] = useState<boolean>(false);
  const [responseValidator, setResponseValidator] =
    useState<DataAdjustmentValidator[]>();
  const [responseSave, setResponseSave] = useState<BulkLoadLineSave[]>();
  const [fileErrors, setFileErrors] = useState<BulkLoadError[]>([]);
  const [actions, setActions] = useState<BulkLoadLineValidator[]>([]);

  useEffect(() => {
    if (responseValidator?.length) {
      setActions(
        responseValidator.map((line) => ({
          index: line.index,
          action: line.action,
        }))
      );
    }
  }, [responseValidator]);

  const onValidator = async (files: File[] | null) => {
    setLoading(true);
    setFileErrors([]);
    if (!files) return;

    const fileRows = await Promise.all(
      files.map((file) =>
        excelToJson(file, {
          skipRows: 1,
        })
      )
    );
    const rows: Row[] = [];

    const errors: BulkLoadError[] = [];

    fileRows.flat().forEach((row, index) => {
      const error = isErrorToRequiredColumns(row, requiredColumns);
      if (error) {
        errors.push({
          index,
          content: t(bulkLoadModule.REQUIRED_COLUMNS, { num: requiredColumns }),
        });
      }

      rows.push(replaceLineBreak(row));
    });

    setFileErrors(errors);

    const records: Partial<InventoryAdjustment>[] = rows.map((row) => {
      return {
        kitchenId: rowToString(row, 0),
        sku: rowToString(row, 1),
        documentCode: rowToString(row, 2),
        transactionType: rowToString(row, 3),
        quantity: rowToNumber(row, 4),
        entryType: rowToString(row, 5) as EntryType,
      };
    });

    const response = await adjustmentsValidator(records);
    response.ok && response.data && setResponseValidator(response.data);
    setLoading(false);
  };

  const getHeaders = (): KOSBaseTableHeader[] => {
    return [
      {
        label: t(commons.KITCHEN),
        field: "kitchenId",
      },
      {
        label: t(commons.SKU),
        field: "sku",
      },
      {
        label: t(commons.NAME),
        field: "name",
      },
      {
        label: t(commons.UNIT),
        field: "unit",
        align: "center",
      },
      {
        label: t(commons.QUANTITY),
        field: "quantity",
        align: "center",
      },
      {
        label: t(commons.DOCUMENT_CODE),
        field: "documentCode",
        align: "center",
      },
      {
        label: t(commons.TYPE),
        field: "transactionType",
        align: "center",
      },
      {
        label: t(commons.TYPE),
        align: "center",
        component: (item: KOSRowData) => {
          if (item.entryType === "EGRESS") {
            return (
              <Typography fontWeight={700} color="error.main">
                {t(`adjustmentType.${EntryType.NEGATIVE}`)}
              </Typography>
            );
          } else {
            return (
              <Typography fontWeight={700} color="success.main">
                {t(`adjustmentType.${EntryType.POSITIVE}`)}
              </Typography>
            );
          }
        },
      },
    ];
  };

  const onSave = useCallback(() => {
    if (!responseValidator?.length) return;
    setLoading(true);
    const data = responseValidator.map((line) => {
      const currentAction = actions.find((el) => el.index === line.index);
      return { ...line, action: currentAction?.action ?? line.action };
    });
    saveInventoryAdjustments(data)
      .then((response) => {
        if (response.ok) {
          alert.success();
        } else {
          alert.error();
        }
        setResponseValidator(data);
        response.data && setResponseSave(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [responseValidator, actions]);

  const onReset = () => {
    setResponseValidator(undefined);
    setResponseSave(undefined);
  };

  const onChangeAction = (index: number, newAction: BulkLoadActionType) => {
    setActions((prev) =>
      prev.map((action) => {
        if (action.index === index) {
          return { ...action, action: newAction };
        }
        return action;
      })
    );
  };

  return (
    <BulkLoad
      loading={loading}
      responseHeaders={getHeaders()}
      responseLines={responseValidator?.map((line) => {
        const { index, action, data, error } = line;
        const fileError = fileErrors.find((el) => el.index === index);
        const serverError = error ? t(`bulkLoadError.${error}`) : undefined;
        return {
          ...data,
          index,
          action,
          error: fileError?.content ?? serverError,
        };
      })}
      responseSave={responseSave}
      templateUrl="https://docs.google.com/spreadsheets/d/1WvQNoSx1Mis-lIjaDPs8vT9V82pO1bqhO3tGXd77JXs/copy"
      onValidator={onValidator}
      onSave={onSave}
      onChangeAction={onChangeAction}
      onReset={onReset}
    />
  );
};

export default ManualAdjustmentsBulkLoad;
