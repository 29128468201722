import { UseCase } from "core/common/interfaces/useCase";
import {
  DetailToSave,
  MissingReason,
  ProductionSupplyWithDetailsPayload,
} from "../entities/Supply";
import { ProductionSupplyHTTPRepository } from "../repositories/http/Supply";

export class GetByKitchenIdUseCase implements UseCase {
  constructor(private readonly repository: ProductionSupplyHTTPRepository) {}

  public execute(kitchenId: string) {
    return this.repository.getForPackagingByKitchenId(kitchenId);
  }
}

export class GetDetailUseCase implements UseCase {
  constructor(private readonly repository: ProductionSupplyHTTPRepository) {}

  public execute(supplyId: string) {
    return this.repository.getDetail(supplyId);
  }
}

export class GetPackedByKitchenIdUseCase implements UseCase {
  constructor(private readonly repository: ProductionSupplyHTTPRepository) {}

  public execute(kitchenId: string) {
    return this.repository.getPackedByKitchenId(kitchenId);
  }
}

export class GetWithDetailsUseCase implements UseCase {
  constructor(private readonly repository: ProductionSupplyHTTPRepository) {}

  public execute(supplyId: string) {
    return this.repository.getWithDetails(supplyId);
  }
}

export class UpdatePackedQuantityUseCase implements UseCase {
  constructor(private readonly repository: ProductionSupplyHTTPRepository) {}

  public execute(id: number, packedQuantity: number) {
    return this.repository.updatePackedQuantity(id, packedQuantity);
  }
}

export class ReportNoveltyUseCase implements UseCase {
  constructor(private readonly repository: ProductionSupplyHTTPRepository) {}

  public execute(
    supplyDetailId: number,
    missingReason: MissingReason,
    receivedQuantity: number
  ) {
    return this.repository.reportNovelty(
      supplyDetailId,
      missingReason,
      receivedQuantity
    );
  }
}

export class CompleteUseCase implements UseCase {
  constructor(private readonly repository: ProductionSupplyHTTPRepository) {}

  public execute(productionSupplyId: number) {
    return this.repository.complete(productionSupplyId);
  }
}

export class ReceptionCompleted implements UseCase {
  constructor(private readonly repository: ProductionSupplyHTTPRepository) {}

  public execute(productionSupplyId: number) {
    return this.repository.receptionCompleted(productionSupplyId);
  }
}

export class SaveDetailUseCase implements UseCase {
  constructor(private readonly repository: ProductionSupplyHTTPRepository) {}

  public execute(data: DetailToSave) {
    return this.repository.saveDetail(data);
  }
}

export class CreateUseCase implements UseCase {
  constructor(private readonly repository: ProductionSupplyHTTPRepository) {}

  public execute(data: ProductionSupplyWithDetailsPayload) {
    return this.repository.create(data);
  }
}
