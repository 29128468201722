import { EventReasonType, commons } from "app/i18n/types";

export type AdjustmentQuantities = {
  [id: number]: number;
};

export enum Movement {
  LOSS = "LOSS",
  DISCARDED = "DISCARDED",
  INTERNAL_CONSUMPTION = "INTERNAL_CONSUMPTION",
  EVENT = "EVENT",
  MANUAL_ADJUSTMENT = "MANUAL_ADJUSTMENT",
}

export type MovementType = `${Movement}`;

export enum Product {
  SUPPLY = "SUPPLY",
  FINISHED = "FINISHED",
  BOTH = "BOTH",
}

export type ProductType = `${Product}`;

export type Reason = { [key in string]: string };

export interface RegisterOption {
  type: MovementType;
  product: ProductType;
  reasons?: Reason;
  updaloadEvicence?: boolean;
}

export const lossReasons: Reason = {
  damaged: EventReasonType.DAMAGED,
  expired: EventReasonType.EXPIRED,
  obsolete: EventReasonType.OBSOLETE,
};

export const eventReasons: Reason = {
  training: EventReasonType.TRAINING,
  marketing: EventReasonType.MARKETING,
  qualityTests: EventReasonType.QUALITY_TESTS,
};

export const allReasons: Reason = {
  ...lossReasons,
  ...eventReasons,
};

export const registerOptions: RegisterOption[] = [
  {
    type: Movement.DISCARDED,
    product: Product.SUPPLY,
  },
  {
    type: Movement.LOSS,
    product: Product.BOTH,
    reasons: lossReasons,
    updaloadEvicence: true,
  },
  {
    type: Movement.INTERNAL_CONSUMPTION,
    product: Product.SUPPLY,
  },
  {
    type: Movement.EVENT,
    product: Product.BOTH,
    reasons: eventReasons,
    updaloadEvicence: true,
  },
];

export type AuthorizationType = `${Movement}` | "ALL" | "FOR_VALIDATE";

export enum AuthorizationFilter {
  TYPE = "TYPE",
  NOTHING = "NOTHING",
  FOR_VALIDATION = "FOR_VALIDATION",
}

export type AuthorizationFilterType = `${AuthorizationFilter}`;

export interface AuthorizationOption {
  type: AuthorizationType;
  label?: string;
  filterFor: AuthorizationFilterType;
}

export const authorizationOptions: AuthorizationOption[] = [
  {
    type: "ALL",
    label: commons.ALL,
    filterFor: "NOTHING",
  },
  {
    type: "DISCARDED",
    filterFor: "TYPE",
  },
  {
    type: "LOSS",
    filterFor: "TYPE",
  },
  {
    type: "INTERNAL_CONSUMPTION",
    filterFor: "TYPE",
  },
  {
    type: "EVENT",
    filterFor: "TYPE",
  },
  {
    type: "FOR_VALIDATE",
    label: commons.FOR_VALIDATE,
    filterFor: "FOR_VALIDATION",
  },
];
