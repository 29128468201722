import { UseCase } from "core/common/interfaces/useCase";
import { CanRetryIntegration } from "../interfaces/canRetryIntegration";

export class CanRetryIntegrationUseCase implements UseCase {
  constructor(private readonly repository: CanRetryIntegration) {}

  public execute(url: string) {
    return this.repository.retryIntegration(url);
  }
}
