import { AttachmentSavePayload } from "core/purchaseOrders/entities/ReceiptOrder";
import {
  CanGetReceiptOrderByReceiptNo,
  CansaveReceiptAttachments,
  canGetReceiptOrdersForKitchen,
} from "core/purchaseOrders/traits/receiptOrders/canGetReceiptOrdersForKitchen";

export class GetReceiptOrdersForKitchenUseCase {
  constructor(private readonly repository: canGetReceiptOrdersForKitchen) {}

  public execute(kitchenId: string) {
    return this.repository.getReceiptOrdersForKitchen(kitchenId);
  }
}

export class GetReceiptOrderByReceiptNoUseCase {
  constructor(private readonly repository: CanGetReceiptOrderByReceiptNo) {}

  public execute(receiptNo: string, origin: string, kitchenId: string) {
    return this.repository.getReceiptOrderByReceiptNo(
      receiptNo,
      origin,
      kitchenId
    );
  }
}

export class SaveReceiptAttachmentsUseCase {
  constructor(private readonly repository: CansaveReceiptAttachments) {}

  public execute(data: AttachmentSavePayload) {
    return this.repository.saveReceiptAttachments(data);
  }
}
