import Box from "@mui/material/Box";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { FunctionComponent } from "react";

interface ProgressBarProps {
  label?: string;
  percent: number;
  hidePercentLabel?: boolean;
  compact?: boolean;
  setColor?: (color: string) => void;
  fullWidth?: boolean;
}

const ProgressBar: FunctionComponent<ProgressBarProps> = (props) => {
  const { label, percent, hidePercentLabel, compact, setColor, fullWidth } =
    props;

  const getColor = (theme: Theme, element: "container" | "bar"): string => {
    if (element === "container") {
      if (percent <= 30) {
        return `#F5D7D7`;
      }

      if (percent >= 30 && percent < 99) {
        return `#FCE7D7`;
      }
      if (percent >= 99) {
        return `#E1F1E1`;
      }
    }

    if (element === "bar") {
      if (percent <= 30) {
        return theme.palette.error.main;
      }

      if (percent >= 30 && percent < 99) {
        return theme.palette.warning.light;
      }
      if (percent >= 99) {
        return theme.palette.success.light;
      }
    }

    return "#eee";
  };

  return (
    <Box
      sx={{
        maxWidth: fullWidth ? undefined : 600,
        px: compact ? 0 : 2,
        py: compact ? 0 : 1,
        bgcolor: "white",
        borderRadius: 1,
        display: "flex",
        gap: 2,
        alignItems: "center",
        height: "100%",
      }}
    >
      {label && <Typography color="text.secondary">{label}</Typography>}

      <Box
        sx={{
          position: "relative",
          display: "inline-block",
          bgcolor: (t) => getColor(t, "container"),
          height: compact ? 12 : 24,
          borderRadius: 2,
          width: "100%",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: `${percent}%`,
            minWidth: "1%",
            background: (t) => {
              const color = getColor(t, "bar");
              if (setColor) setColor(color);
              return color;
            },
            borderRadius: 2,
          }}
        >
          {!hidePercentLabel && (
            <Typography
              variant="h6"
              color="white"
              lineHeight="24px"
              textAlign="center"
            >
              {percent}%
            </Typography>
          )}
          <Box
            component="progress"
            max="100"
            value={percent}
            sx={{ opacity: 0 }}
          >
            {percent}%
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProgressBar;

export interface ProgressBarData {
  progress: number;
  total: number;
  percentage: string;
}

export const progressBarDataDefault: ProgressBarData = {
  progress: 0,
  total: 0,
  percentage: "0",
};
