import { StoreRequestError, StoreRequestStatus } from "app/store/types";
import { Kitchen } from "core/supplies/entities/Kitchen";
import {
  TheoreticalInventory,
  TheoreticalInventoryComparisonByCity,
  TheoreticalInventoryComparisonByCountry,
  TheoreticalInventoryComparisonByKitchenResponse,
  TheoreticalInventoryMovementsResumeResponse,
} from "core/theoricalInventory/entities/ThereticalInventory";

export interface SuppliesState {
  kitchenId: string | null;
  inventory: Array<TheoreticalInventory> | null;
  maxCounting: number;
  inventoryMovementsResume: {
    data: TheoreticalInventoryMovementsResumeResponse | null;
    status: StoreRequestStatus;
    error: StoreRequestError;
  };
  comaprisonByKitchen: {
    data: TheoreticalInventoryComparisonByKitchenResponse;
    status: StoreRequestStatus;
    error: StoreRequestError;
  };
  comaprisonByCity: {
    data: Array<TheoreticalInventoryComparisonByCity> | null;
    status: StoreRequestStatus;
    error: StoreRequestError;
  };
  comaprisonByCountry: {
    data: Array<TheoreticalInventoryComparisonByCountry> | null;
    status: StoreRequestStatus;
    error: StoreRequestError;
  };
  exportInventory: Array<TheoreticalInventory> | null;
  status: StoreRequestStatus;
  statusInventory: StoreRequestStatus;
  statusExport: StoreRequestStatus;
  error: StoreRequestError;
  country: string | null;
  kitchen: Kitchen | null;
}

export const initialState: SuppliesState = {
  kitchenId: null,
  inventory: null,
  maxCounting: 0,
  inventoryMovementsResume: {
    data: null,
    status: "idle",
    error: null,
  },
  comaprisonByKitchen: {
    data: {
      lastCounting: null,
      data: null,
    },
    status: "idle",
    error: null,
  },
  comaprisonByCity: {
    data: [],
    status: "idle",
    error: null,
  },
  comaprisonByCountry: {
    data: null,
    status: "idle",
    error: null,
  },
  exportInventory: null,
  status: "idle",
  statusInventory: "idle",
  statusExport: "idle",
  error: null,
  country: null,
  kitchen: null,
};
