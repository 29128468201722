import { FunctionComponent, useState } from "react";

import FormatListNumberedRtlOutlinedIcon from "@mui/icons-material/FormatListNumberedRtlOutlined";
import MicrowaveOutlinedIcon from "@mui/icons-material/MicrowaveOutlined";
import { Grid } from "@mui/material";
import PageHeader from "app/components/PageHeader";
import { TabPanel } from "app/components/PageTabs";
import ByKitchenTab from "app/components/SuppliesManagement/ByKitchenTab";
import BySuppliesTab from "app/components/SuppliesManagement/BySuppliesTab";
import MassiveSupplyTab from "app/components/SuppliesManagement/MassiveSupplyTab";
import CustomTabs from "app/components/common/Tabs";
import { kitchenInventoryManagement } from "app/i18n/types";
import { useTranslation } from "react-i18next";
import { PageBox } from "utils/generalUI";

const SuppliesManagement: FunctionComponent = () => {
  const [tab, setTab] = useState<number>(0);

  const { t } = useTranslation();

  return (
    <PageBox>
      <PageHeader
        title={t(kitchenInventoryManagement.SUPPLIESMANAGEMENT_MODULE)}
        subtitle={t(
          kitchenInventoryManagement.SUPPLIESMANAGEMENT_MODULE_DESCRIPTION
        )}
      />

      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <CustomTabs
            tabs={[
              {
                children: "Por Cocina",
                startIcon: <MicrowaveOutlinedIcon />,
              },
              {
                children: "Por insumo",
                startIcon: <FormatListNumberedRtlOutlinedIcon />,
              },
              {
                children: "Masivo",
                startIcon: <FormatListNumberedRtlOutlinedIcon />,
              },
            ]}
            onChange={setTab}
          />
        </Grid>
        <Grid item sm={12} xs={12} sx={{ overflowX: "auto" }}>
          <TabPanel value={tab} index={0}>
            <ByKitchenTab />
          </TabPanel>

          <TabPanel value={tab} index={1}>
            <BySuppliesTab />
          </TabPanel>

          <TabPanel value={tab} index={2}>
            <MassiveSupplyTab />
          </TabPanel>
        </Grid>
      </Grid>
    </PageBox>
  );
};

export default SuppliesManagement;
