import { CoreBaseResponse } from "core/common/interfaces/core";

export enum CommentTypeEnum {
  PURCHASE_ORDER = "PURCHASE_ORDER",
  TRANSFER_ORDER = "TRANSFER_ORDER",
}

export type CommentType = `${CommentTypeEnum}`;

export interface CreateCommentPayload {
  sourceId: string;
  type: string;
  content: string;
  kitchenId: string;
  parentId?: number;
}

export interface CommentDTO {
  id: number;
  sourceId: string;
  type: CommentType;
  content: string;
  country: string;
  createdAt: Date;
  createdBy: string;
  createdByName: string;
  children: CommentDTO[];
}

export interface GetCommentsResponse extends CoreBaseResponse {
  data: CommentDTO[];
}

export interface CreateCommnetResponse extends CoreBaseResponse {
  data?: CommentDTO;
}
