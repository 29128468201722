import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Typography } from "@mui/material";
import { productionManagement } from "app/i18n/types";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

const ProductionLogEmpty: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
      <Box
        sx={{
          p: 3,
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
        }}
      >
        <InfoOutlinedIcon fontSize="large" />
        <Typography variant="body1">
          {t(productionManagement.LOG_EMPTY_LABEL_1)}
        </Typography>
        <Typography variant="h6">
          {t(productionManagement.LOG_EMPTY_LABEL_2)}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProductionLogEmpty;
