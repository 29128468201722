import { StoreRequestError, StoreRequestStatus } from "app/store/types";
import { FdlgyCountry } from "config/accuracy";
import { RawCounting } from "core/physicalCount/entities/Counting";
import { TheoreticalInventoryComparisonByKitchen } from "core/theoricalInventory/entities/ThereticalInventory";

export interface CountingHistoryState {
  countings: Array<RawCounting> | null;
  status: StoreRequestStatus;
  error: StoreRequestError;
  countingStatus: StoreRequestStatus;
  newCountingId: string | null;
  detailsByCountingRequest: {
    data: Array<TheoreticalInventoryComparisonByKitchen> | null;
    accuracy: number;
    country: FdlgyCountry;
    status: StoreRequestStatus;
    error: StoreRequestError;
  };
}

export const initialState: CountingHistoryState = {
  countings: null,
  status: "idle",
  countingStatus: "idle",
  error: null,
  newCountingId: null,
  detailsByCountingRequest: {
    accuracy: 0,
    data: null,
    country: FdlgyCountry.COL,
    status: "idle",
    error: null,
  },
};
