import { UseCase } from "core/common/interfaces/useCase";
import { CanGetProductsForKitchen } from "core/physicalCount/interfaces/product/canGetProductsForKitchen";

export class GetProductsForKitchenUseCase implements UseCase {
  constructor(private readonly repository: CanGetProductsForKitchen) {}

  public execute(countingId: string) {
    return this.repository.getProductForKitchen(countingId);
  }
}
