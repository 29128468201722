import { createAsyncThunk } from "@reduxjs/toolkit";
import appConfig from "config/app";
import {
  AdjustmentPayload,
  AdjustmentSendResponse,
  SummaryAdjustmentResponse,
} from "core/adjustments/entities/Adjusment";
import {
  AddManualAdjustmentResponse,
  ManualAdjustment,
} from "core/adjustments/entities/ManualAdjustment";
import { AdjustmentsHTTPRepository } from "core/adjustments/repositories/http/adjustments";
import { ManualAdjustmentHTTPRepository } from "core/adjustments/repositories/http/manualAdjustment";
import { AddManualAdjustmentUseCase } from "core/adjustments/usesCases/adjustments/addManualAdjustment";
import { GetSummaryAdjustmentsUseCase } from "core/adjustments/usesCases/adjustments/getSummaryAdjustmentsUseCase";
import { SendAdjustmentsUseCase } from "core/adjustments/usesCases/adjustments/sendAdjustmentsUseCase";
import { ValidateAdjustmentsUseCase } from "core/adjustments/usesCases/adjustments/validateAdjustmentsUseCase";

export const sendAdjustments = createAsyncThunk(
  "adjustments/sendAdjustments",
  async (payload: {
    fileName: string;
    adjustments: Array<AdjustmentPayload>;
  }): Promise<AdjustmentSendResponse> => {
    const httpRepository = new AdjustmentsHTTPRepository(appConfig);
    const sendAdjustmentsUseCase = new SendAdjustmentsUseCase(httpRepository);

    const sendAdjustmentsResult = await sendAdjustmentsUseCase.execute(
      payload.fileName,
      payload.adjustments
    );
    return sendAdjustmentsResult;
  }
);

export const getSummaryAdjustment = createAsyncThunk(
  "adjustments/getSummaryAdjustment",
  async (): Promise<Array<SummaryAdjustmentResponse>> => {
    const httpRepository = new AdjustmentsHTTPRepository(appConfig);
    const getSummaryAdjustmentsUseCase = new GetSummaryAdjustmentsUseCase(
      httpRepository
    );

    const getSummaryAdjustmentsResult =
      await getSummaryAdjustmentsUseCase.execute();
    return getSummaryAdjustmentsResult;
  }
);

export const AddManualAdjustment = createAsyncThunk(
  "adjustments/AddManualAdjustment",
  async (payload: {
    adjustments: Array<ManualAdjustment>;
  }): Promise<AddManualAdjustmentResponse> => {
    const httpRepository = new ManualAdjustmentHTTPRepository(appConfig);
    const addManualAdjustmentsUseCase = new AddManualAdjustmentUseCase(
      httpRepository
    );

    const sendAdjustmentsResult = await addManualAdjustmentsUseCase.execute(
      payload.adjustments
    );
    return sendAdjustmentsResult;
  }
);

export const validateAdjustments = createAsyncThunk(
  "adjustments/validateAdjustments",
  async (payload: {
    adjustments: Array<ManualAdjustment>;
  }): Promise<AddManualAdjustmentResponse> => {
    const httpRepository = new ManualAdjustmentHTTPRepository(appConfig);
    const useCase = new ValidateAdjustmentsUseCase(httpRepository);

    const sendAdjustmentsResult = await useCase.execute(payload.adjustments);
    return sendAdjustmentsResult;
  }
);
