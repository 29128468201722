import {
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSEmptyState,
} from "@foodology-co/alejandria";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box } from "@mui/material";
import PageHeader from "app/components/PageHeader/PageHeader";
import { commons } from "app/i18n/types";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  data: any[];
  loading: boolean;
  title: string;
  headers: KOSBaseTableHeader[];
}

const BcIntegrationTable: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const { data, loading, title, headers } = props;

  return (
    <Box>
      <PageHeader title={title} />

      {data && (
        <KOSBaseTable
          columns={headers}
          rows={{
            data: data ?? [],
            total: data.length,
            loading,
          }}
          pagination={{
            enable: true,
          }}
          emptyState={
            <KOSEmptyState
              icon={InfoOutlinedIcon}
              message={t(commons.TABLE_EMPTY)}
            />
          }
        />
      )}
    </Box>
  );
};
export default BcIntegrationTable;
