import { AppConfig } from "config/app";
import { SummaryResponse } from "core/BusinessCentralIntegration/entities/BusinessCentralIntegration";
import { CanGetErrorSummary } from "core/BusinessCentralIntegration/interfaces/canGetErrorSummary";
import { CanRetryIntegration } from "core/BusinessCentralIntegration/interfaces/canRetryIntegration";

import { ApiVersion, getHttp } from "utils/http";

export class BusinesCentralIntegrationHTTPRepository
  implements CanGetErrorSummary, CanRetryIntegration
{
  constructor(private readonly config: AppConfig) {}

  public async byKitchen(kitchenId: string): Promise<SummaryResponse> {
    try {
      const errorSummaryResponse = await getHttp(
        this.config.bcIntegrator.apiBaseUrl,
        ApiVersion.V1,
        `summary`
      );
      const errorSummary: SummaryResponse = await errorSummaryResponse.json();
      return {
        ...errorSummary,
        transfers: errorSummary.transfers.filter(
          (transferError) => transferError.originKitchenId === kitchenId
        ),
      };
    } catch (err) {
      console.error("getErrorSummary error", err);
      throw err;
    }
  }

  public async all(): Promise<SummaryResponse> {
    try {
      const errorSummaryResponse = await getHttp(
        this.config.bcIntegrator.apiBaseUrl,
        ApiVersion.V1,
        `summary`
      );
      const errorSummary: SummaryResponse = await errorSummaryResponse.json();
      return errorSummary;
    } catch (err) {
      console.error("getErrorSummary error", err);
      throw err;
    }
  }

  public async retryIntegration(url: string): Promise<unknown> {
    try {
      const retryResponse = await getHttp(
        this.config.bcIntegrator.apiBaseUrl,
        ApiVersion.V1,
        url
      );
      return retryResponse;
    } catch (err) {
      console.error("retryIntegration error", err);
      throw err;
    }
  }
}
