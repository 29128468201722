/* eslint-disable react/require-default-props */
import React from "react";

interface IconProp {
  fontSize?: string;
}

const PurchaseOrderConciliationIcon = (props: IconProp): React.ReactElement => {
  const { fontSize } = props;
  return (
    <svg
      width={fontSize ?? 40}
      height={fontSize ?? 40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width={fontSize ?? 40}
        height={fontSize ?? 40}
        rx="20"
        fill="#F1EFEF"
      />
      <mask
        id="mask0_5943_385"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="24"
        height="24"
      >
        <rect x="8" y="8" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5943_385)">
        <path
          d="M13 30C12.45 30 11.9792 29.8042 11.5875 29.4125C11.1958 29.0208 11 28.55 11 28V16C11 15.45 11.1958 14.9792 11.5875 14.5875C11.9792 14.1958 12.45 14 13 14H15C15 12.6167 15.4875 11.4375 16.4625 10.4625C17.4375 9.4875 18.6167 9 20 9C21.3833 9 22.5625 9.4875 23.5375 10.4625C24.5125 11.4375 25 12.6167 25 14H27C27.55 14 28.0208 14.1958 28.4125 14.5875C28.8042 14.9792 29 15.45 29 16V28C29 28.55 28.8042 29.0208 28.4125 29.4125C28.0208 29.8042 27.55 30 27 30H13ZM13 28H27V16H13V28ZM20 22C21.3833 22 22.5625 21.5125 23.5375 20.5375C24.5125 19.5625 25 18.3833 25 17H23C23 17.8333 22.7083 18.5417 22.125 19.125C21.5417 19.7083 20.8333 20 20 20C19.1667 20 18.4583 19.7083 17.875 19.125C17.2917 18.5417 17 17.8333 17 17H15C15 18.3833 15.4875 19.5625 16.4625 20.5375C17.4375 21.5125 18.6167 22 20 22ZM17 14H23C23 13.1667 22.7083 12.4583 22.125 11.875C21.5417 11.2917 20.8333 11 20 11C19.1667 11 18.4583 11.2917 17.875 11.875C17.2917 12.4583 17 13.1667 17 14Z"
          fill="#232323"
        />
      </g>
    </svg>
  );
};

export default PurchaseOrderConciliationIcon;
