import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import Grid from "@mui/material/Grid";
import PageHeader from "app/components/PageHeader/PageHeader";
import ProductionSupplyTable from "app/components/Productions/Supply/Table";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useNavigator } from "app/hooks/useNavigator";
import { commons, supplyingManagement } from "app/i18n/types";
import { getForPackagingByKitchenId } from "app/store/slices/productions/Supply/thunks";
import { ProductionSupplyResponse } from "core/productions/entities/Supply";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageBox } from "utils/generalUI";

interface SupplyingPageProps {}

const SupplyingPage: FunctionComponent<SupplyingPageProps> = () => {
  const navigator = useNavigator();
  const { kitchenId } = navigator.params();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [supplies, setSupplies] = useState<ProductionSupplyResponse[]>([]);

  const getData = async () => {
    setLoading(true);
    if (kitchenId) {
      const response = await dispatch(
        getForPackagingByKitchenId({ kitchenId })
      );
      const data = response.payload as ProductionSupplyResponse[];
      setSupplies(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [kitchenId]);

  return (
    <PageBox>
      <PageHeader
        title={t(supplyingManagement.MODULE_TITLE)}
        subtitle={t(supplyingManagement.MODULE_DESCRIPTION)}
        rightArea={[
          {
            children: t(commons.UPDATE),
            variant: "outlined",
            startIcon: <RestartAltOutlinedIcon />,
            onClick: () => getData(),
          },
        ]}
      />

      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <ProductionSupplyTable items={supplies} loading={loading} />
        </Grid>
      </Grid>
    </PageBox>
  );
};

export default SupplyingPage;
