import { SlotEnum, SlotType } from "core/productions/entities/Plan";

export const column = { display: "flex", flexDirection: "column" };

export const slots = {
  height: 400,
  backgroundColor: "#f9fafc",
  boxShadow: "inset -2px -2px 0px #e3e5e8",
  p: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const slotBox = {
  ...slots,
  width: 480,
};

export const slotBoxWithCard = {
  ...slotBox,
  alignItems: "flex-start",
};

export const corner = {
  height: 48,
  backgroundColor: "#f9fafc",
  boxShadow: "inset -2px -2px 0px #e3e5e8",
};

export const dayOfWeek = {
  ...corner,
  display: "flex",
  gap: 1,
  alignItems: "center",
  justifyContent: "center",
  width: 480,
};

export const slotTitle = {
  height: 40,
  backgroundColor: "#f9fafc",
  boxShadow: "inset -2px -2px 0px #e3e5e8",
  display: "flex",
  gap: 1,
  alignItems: "center",
  justifyContent: "center",
  width: 480,
};

export const getSlotColor = (slot: SlotType) => {
  switch (slot) {
    case SlotEnum.AM:
      return "#faedcd";
    case SlotEnum.PM:
      return "#dbcdf0";
    default:
      return "#c6def1";
  }
};

export const DATE_PLUS = 3;
const DATE_RANGE_LENGTH = 3;

const getDate = (startPlus: number, plus: number): Date => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() + startPlus + plus);
  return date;
};

export const getDatesToCalendar = (startPlus: number): Date[] => {
  const result: Date[] = [];
  Array.from(Array(DATE_RANGE_LENGTH)).forEach((_, plus) =>
    result.push(getDate(startPlus, plus))
  );
  return result;
};

export interface PreparationTimeProps {
  startDate?: Date;
  finishedAt?: Date;
}

export const getPreparationTime = (
  data: PreparationTimeProps
): string | undefined => {
  const startedAt = data.startDate;
  if (startedAt) {
    const endDate = !data.finishedAt
      ? new Date()
      : new Date(data.finishedAt.toString());
    const difference =
      endDate.getTime() - new Date(startedAt.toString()).getTime();

    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

    const formattedTime = `${hours.toString().padStart(2, "0")}h ${minutes
      .toString()
      .padStart(2, "0")}m`;
    return formattedTime;
  }
  return undefined;
};
