import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useAppSelector } from "app/hooks/useAppSelector";
import { useNavigator } from "app/hooks/useNavigator";
import {
  citiesOptions,
  countriesOptions,
  locationsOptionsByCity,
} from "app/utils/kitchenSelector.utils";
import { Kitchen } from "core/supplies/entities/Kitchen";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { commons } from "../../../i18n/types";
import KitchenSelectorAutoCompletes from "../Autocomplete";

interface KitchenSelectorDialogProps {
  open: boolean;
}

const filterLocationOptions = {
  cp: "PRODUCTION_CENTER",
  kitchen: "KITCHEN",
};

const KitchenSelectorDialog: FunctionComponent<KitchenSelectorDialogProps> = (
  props
) => {
  const { open } = props;
  const { t } = useTranslation();
  const navigator = useNavigator();
  const { inventoryModule, kitchenId } = navigator.params();

  const kitchens = useAppSelector((state) => state.global.kitchens.data);

  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const [selectedCity, setSelectedCity] = useState<string | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<Kitchen | null>(
    null
  );

  const selectorOptions = useMemo(() => {
    if (inventoryModule === "production-center-inventory-management") {
      return "cp";
    }

    return kitchenId?.replace(":", "").replace("Id", "") ?? null;
  }, [inventoryModule, kitchenId]);

  useEffect(() => {
    setSelectedCity(null);
    setSelectedLocation(null);
  }, [selectedCountry]);

  useEffect(() => {
    setSelectedLocation(null);
  }, [selectedCity]);

  useEffect(() => {
    if (kitchenId && selectedLocation) {
      navigator.to(
        `/modules/${inventoryModule}/${selectedLocation?.kitchenId}`
      );
      setSelectedCountry(null);
      setSelectedCity(null);
      setSelectedLocation(null);
    }
  }, [kitchenId, selectedLocation]);

  return (
    <Dialog open={open} onClose={() => {}} maxWidth="md" fullWidth>
      <DialogTitle>{t(commons.SELECT_LOCATIONS)}</DialogTitle>
      <DialogContent sx={{ padding: "20px 24px!important" }}>
        <KitchenSelectorAutoCompletes
          countriesOptions={countriesOptions(kitchens)}
          citiesOptions={citiesOptions(selectedCountry, kitchens)}
          locationsOptions={locationsOptionsByCity(
            selectedCity,
            kitchens,
            selectorOptions,
            filterLocationOptions
          )}
          selectedCountry={selectedCountry}
          selectedCity={selectedCity}
          selectedLocation={selectedLocation}
          setSelectedCountry={setSelectedCountry}
          setSelectedCity={setSelectedCity}
          setSelectedLocation={setSelectedLocation}
        />
      </DialogContent>
    </Dialog>
  );
};

export default KitchenSelectorDialog;
