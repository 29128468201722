import { CancelOutlined, InfoOutlined } from "@mui/icons-material";
import { Box, LinearProgress, Typography } from "@mui/material";
import { ManagerDialogFooter } from "app/components/common/Manager/Dialog/Footer";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useNavigator } from "app/hooks/useNavigator";
import { commons, productionManagement } from "app/i18n/types";
import { productionScheduleSave } from "app/store/slices/productions/plan/thunks";
import { excelToJson } from "app/utils/excel";
import dayjs from "dayjs";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { rowSchema } from "./bulkUploaderValidations";

interface Props {
  onClose: () => void;
  refreshCalendar?: () => void;
}

export const BulkUploader = (props: Props) => {
  const { onClose, refreshCalendar } = props;
  const dispatch = useAppDispatch();
  const navigator = useNavigator();
  const { kitchenId } = navigator.params();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      "file/xls": [".xls", ".xlsx"],
    },
  });

  const handleSubmit = useCallback(async () => {
    if (!acceptedFiles.length) return;

    const data = await excelToJson(acceptedFiles[0], { sheet: 1 });

    try {
      const errors: string[] = [];

      const transformedData = data.slice(1).map((row) => ({
        sku: String(row[0]),
        quantity: row[3] as number,
        scheduledDate: dayjs.utc(row[4] as string).format("YYYY-MM-DD"),
        timeSlot: row[5] as string,
        userAssigned: row[6] as string,
      }));
      setIsLoading(true);
      await rowSchema
        .validate(transformedData, { abortEarly: true })
        .catch(() => {
          errors.push(t(productionManagement.INVALID_DOCUMENT_DATA));
        });

      if (errors.length > 0) {
        throw errors;
      }

      if (kitchenId) {
        await dispatch(
          productionScheduleSave({
            kitchenId,
            body: transformedData,
          })
        );
        setIsLoading(false);
        if (refreshCalendar) refreshCalendar();
      }
    } catch (error) {
      setIsLoading(false);
      if (Array.isArray(error) && error.length > 0) alert(error.join("\n"));
    }
  }, [acceptedFiles, dispatch, kitchenId, t]);

  return (
    <>
      {isLoading && <LinearProgress sx={{ mb: 2 }} />}
      <Box
        sx={{
          width: "100%",
          height: 160,
          backgroundColor: "#F1EFEF",
          borderRadius: 2,
          border: "black dashed",
          mb: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 4,
            gap: 2,
            alignItems: "center",
            height: "100%",
            justifyContent: "center",
          }}
          {...getRootProps()}
        >
          {!acceptedFiles.at(0) && <InfoOutlined />}

          <input {...getInputProps({ className: "dropzone" })} />

          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="caption" style={{ fontSize: 16 }}>
              {acceptedFiles.at(0)
                ? acceptedFiles.at(0)?.name
                : t(productionManagement.DRAG_AND_DROP_FILE)}
            </Typography>

            {Boolean(acceptedFiles.at(0)) && <CancelOutlined />}
          </Box>
        </Box>
      </Box>

      <ManagerDialogFooter
        onCancel={onClose}
        mainButton={{
          children: t(commons.IMPORT),
          onClick: handleSubmit,
          disabled: isLoading || !acceptedFiles.length,
        }}
        loading={isLoading}
      />
    </>
  );
};
