import { Grid } from "@mui/material";
import CommentDrawerWithFloatButton from "app/components/CommentArea/DrawerWithFloatButton";
import SearchOnTopBar from "app/components/common/SearchOnTopBar";
import PurchaseLinesTables from "app/components/PurchaseLinesTable";
import FinishDrawer from "app/components/PurchaseLinesTable/FinishDrawer";
import PurchaseOrderLineResume from "app/components/PurchaseOrder/Line/Resume";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { useNavigator } from "app/hooks/useNavigator";
import { commons } from "app/i18n/types";
import { setTitle } from "app/store/slices/global";
import { getLinesPurchase } from "app/store/slices/purchase/lines.slice";
import { getReceiptOrderByReceiptNo } from "app/store/slices/purchase/purchase.slice";
import { CommentTypeEnum } from "core/comment/entity";
import { LinesPurchaseOrder } from "core/linesPurchaseOrders/entities/LinesPurchaseOrder";
import { OriginType } from "core/purchaseOrders/entities/PurchaseOrder";
import {
  AttachmentsDetailResponse,
  ReceiptOrder,
} from "core/purchaseOrders/entities/ReceiptOrder";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { compareSearchText } from "utils/general";
import { PageBox } from "utils/generalUI";

interface PurchaseOrderLinesProps {}

const PurchaseOrderLines: FunctionComponent<PurchaseOrderLinesProps> = () => {
  const { t } = useTranslation();
  const navigator = useNavigator();
  const { kitchenId, orderId, origin } = navigator.params();

  const dispatch = useAppDispatch();

  const lines = useAppSelector((state) => state.linesPurchase.lines);
  const linesStatus = useAppSelector((state) => state.linesPurchase.status);
  const [detail, setDetail] = useState<ReceiptOrder | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [linesFiltered, setLinesFiltered] = useState<LinesPurchaseOrder[]>([]);

  const filterLines = (line: LinesPurchaseOrder): boolean => {
    if (!searchQuery) return true;
    return (
      compareSearchText(line.sku, searchQuery) ||
      compareSearchText(line.description, searchQuery)
    );
  };

  useEffect(() => {
    setLinesFiltered(lines?.filter(filterLines) ?? []);
  }, [lines, searchQuery]);

  const getDetail = async () => {
    if (orderId && kitchenId && origin) {
      const response = await dispatch(
        getReceiptOrderByReceiptNo({ kitchenId, receiptNo: orderId, origin })
      );
      setDetail(response.payload as ReceiptOrder);
    }
  };

  useEffect(() => {
    getDetail();
  }, [dispatch, kitchenId, orderId]);

  useEffect(() => {
    if (orderId && kitchenId && origin) {
      dispatch(getLinesPurchase({ kitchenId, orderId, origin }));
    }
  }, [dispatch, kitchenId, orderId, origin]);

  useEffect(() => {
    dispatch(setTitle(t(commons.PURCHASE_ORDER)));
  }, [dispatch, t]);

  const [percentage, setPercentage] = useState<number>(0);

  const setDetailAttachments = (response: AttachmentsDetailResponse | null) => {
    if (response) {
      setDetail({
        ...detail,
        invoiceNo: response.invoiceNo,
        attachments: response.attachments,
      } as ReceiptOrder);
    }
  };

  return (
    <PageBox>
      <FinishDrawer
        detail={detail}
        setDetail={setDetailAttachments}
        lines={lines}
        disabled={
          origin === OriginType.BC &&
          (linesStatus === "loading" || percentage < 100)
        }
      />

      <SearchOnTopBar onSearch={setSearchQuery} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PurchaseOrderLineResume
            detail={detail}
            lines={lines}
            onChangePercentage={setPercentage}
          />
        </Grid>

        {detail && (
          <>
            <Grid item xs={12} md={12}>
              <PurchaseLinesTables
                items={linesFiltered}
                loading={linesStatus === "loading"}
                isPartial={detail.partial}
                origin={detail.origin}
              />
            </Grid>
            {detail.origin === OriginType.KIS && (
              <CommentDrawerWithFloatButton
                sourceId={detail.purchaseOrderId?.toString() ?? ""}
                type={CommentTypeEnum.PURCHASE_ORDER}
                kitchenId={kitchenId ?? ""}
              />
            )}
          </>
        )}
      </Grid>
    </PageBox>
  );
};

export default PurchaseOrderLines;
