import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { transferPackingDialog } from "app/i18n/types";
import { PickingDetailResponse } from "core/transfer/entities/TransferOrder";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface TransferPackingSupplyBoxProps {
  selectedLine: PickingDetailResponse | null;
}

const TransferPackingSupplyBox: FunctionComponent<
  TransferPackingSupplyBoxProps
> = (props) => {
  const { selectedLine } = props;
  const { t } = useTranslation();

  return (
    <Box
      border="1px solid #D7D3D3"
      borderRadius={1}
      py={1}
      px={2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        <Typography variant="body2" color="text.secondary">
          {t(transferPackingDialog.SUPPLY)}
        </Typography>
        <Typography fontWeight={700}>{selectedLine?.name}</Typography>
      </Box>

      <Box>
        <Typography variant="body2" color="text.secondary">
          {t(transferPackingDialog.TOTAL_QUANTITY)}
        </Typography>
        <Typography fontWeight={700} align="right">
          {selectedLine?.quantity}
          {selectedLine?.measureUnit}.
        </Typography>
      </Box>
    </Box>
  );
};

export default TransferPackingSupplyBox;
