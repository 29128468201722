import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import {
  Alert,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

interface Props {
  warnMessages: string[];
}

const VendorSupplyPriceRangeAlert = (props: Props) => {
  const { warnMessages } = props;

  return (
    <>
      {!!warnMessages.length && (
        <Alert severity="warning" icon={false} sx={{ p: 0 }}>
          <List sx={{ p: 0 }}>
            {warnMessages.map((item) => (
              <ListItem key={item}>
                <ListItemIcon>
                  <ErrorOutlineOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        </Alert>
      )}
    </>
  );
};

export default VendorSupplyPriceRangeAlert;
