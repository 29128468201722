import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/Pending";
import Chip from "@mui/material/Chip";
import { Purchase } from "core/purchaseOrders/entities/PurchaseOrder";
import { useState } from "react";
import { ColorType } from "utils/generalUI";
import { PurchaseOrderInvoiceSearch } from "../../Invoice/Search";

interface Props {
  purchase: Purchase;
  reloadInvoice: () => void;
}

const PurchaseOrderStatusSyncronized = (props: Props): React.ReactElement => {
  const { purchase, reloadInvoice } = props;

  const [openModal, setOpenModal] = useState(false);

  const chipProps = {
    icon: <PendingIcon />,
    color: "info",
    label: "Buscar factura",
  };

  if (purchase.legalDocumentNo) {
    chipProps.icon = <CheckCircleIcon />;
    chipProps.color = "success";
    chipProps.label = "Con factura";
  }

  const closeModal = (reload: boolean) => {
    setOpenModal(false);
    if (reload) {
      reloadInvoice();
    }
  };

  return (
    <>
      <Chip
        sx={{ mr: "10px" }}
        icon={chipProps.icon}
        label={chipProps.label}
        color={chipProps.color as ColorType}
        variant="outlined"
        onClick={() => setOpenModal(true)}
      />
      {openModal && (
        <PurchaseOrderInvoiceSearch
          open={openModal}
          purchase={purchase}
          onClose={closeModal}
        />
      )}
    </>
  );
};

export default PurchaseOrderStatusSyncronized;
