import * as Legology from "@foodology-co/legology";
import HermesFacade from "@foodology-co/legology/dist/hermes";
import { HermesFacadeEnvironment } from "@foodology-co/legology/dist/hermes/types";
import appConfig from "config/app";
import { Analytics, getAnalytics } from "firebase/analytics";
import { FirebaseApp, initializeApp } from "firebase/app";

const { HermesClient } = Legology;

const firebaseConfig = {
  apiKey: appConfig.firebase.apiKey,
  authDomain: appConfig.firebase.authDomain,
  projectId: appConfig.firebase.projectId,
  storageBucket: appConfig.firebase.storageBucket,
  messagingSenderId: appConfig.firebase.messagingSenderId,
  appId: appConfig.firebase.appId,
};

export const envHermes: { [role: string]: HermesFacadeEnvironment } = {
  production: "prod",
  staging: "stg",
  training: "stg",
  development: "dev",
};

let app: FirebaseApp;
let legologyHermesInstance: HermesFacade;
let analytics: Analytics;

const firebaseApp = (): {
  app: FirebaseApp;
  legologyHermesInstance: HermesFacade;
} => {
  if (!app) {
    const instanceApp = initializeApp(firebaseConfig);
    app = instanceApp;
    analytics = getAnalytics();

    legologyHermesInstance = new HermesClient({
      env: envHermes[appConfig.env],
      firebaseInstanceApp: app,
      vapidKey: appConfig.firebase.vapidKey,
    });

    return {
      app,
      legologyHermesInstance,
    };
  }

  return {
    legologyHermesInstance,
    app,
  };
};

export { analytics, firebaseApp };
