import { UseCase } from "core/common/interfaces/useCase";
import { CanGetDetailsByCounting } from "core/physicalCount/interfaces/counting/canGetDetailsByCounting";

export class GetDetailsByCountingUseCase implements UseCase {
  constructor(private readonly repository: CanGetDetailsByCounting) {}

  public execute(countingId: number) {
    return this.repository.getDetailsByCountingId(countingId);
  }
}
