import { User } from "core/account/entities/User";
import { Kitchen } from "core/supplies/entities/Kitchen";
import { clearSpecialCharacters } from "utils/general";
import { getCityCodenameByName, getCityName } from "./city.utils";

export const countriesOptions = (kitchens: Array<Kitchen> | null) => {
  return [...new Set(kitchens?.map((kitchen) => kitchen.country) ?? [])];
};

export const citiesOptions = (
  selectedCountry: string | null,
  kitchens: Array<Kitchen> | null
) => {
  if (selectedCountry === null) {
    return [];
  }

  return [
    ...new Set(
      kitchens
        ?.filter((kitchen) => kitchen.country === selectedCountry)
        .map((kitchen) => clearSpecialCharacters(getCityName(kitchen.city))) ??
        []
    ),
  ];
};

export const locationsOptionsByCity = (
  selectedCity: string | null,
  kitchens: Array<Kitchen> | null,
  selectorOptions: string | null,
  filterLocationOptions: { [key: string]: string }
) => {
  if (selectedCity === null) {
    return [];
  }

  return (
    kitchens
      ?.filter((kitchen) =>
        getCityCodenameByName(selectedCity)?.includes(kitchen.city)
      )
      .filter((kitchen) =>
        selectorOptions === "location" || selectorOptions === null
          ? true
          : kitchen.type ===
            filterLocationOptions[
              selectorOptions as keyof typeof filterLocationOptions
            ]
      )
      .sort((a, b) => (a.type === "PRODUCTION_CENTER" ? -1 : 1)) ?? []
  );
};

export const locationsOptionsByCountry = (
  selectedCountry: string | null,
  kitchens: Array<Kitchen> | null,
  selectorOptions: string | null,
  filterLocationOptions: { [key: string]: string }
) => {
  if (selectedCountry === null) {
    return [];
  }

  return (
    kitchens
      ?.filter((kitchen) => selectedCountry?.includes(kitchen.country))
      .filter((kitchen) =>
        selectorOptions === "location" || selectorOptions === null
          ? true
          : kitchen.type ===
            filterLocationOptions[
              selectorOptions as keyof typeof filterLocationOptions
            ]
      )
      .sort((a, b) => (a.type === "PRODUCTION_CENTER" ? -1 : 1)) ?? []
  );
};

interface FilterKitchensByUser {
  kitchens: Array<Kitchen>;
  country?: string;
}

export const filterKitchensByUser = (
  user: User,
  kitchens: Array<Kitchen>
): FilterKitchensByUser => {
  const kitchenId = user.profile?.kitchen?.kitchenId;
  if (!kitchenId) {
    return { kitchens };
  }
  const country = kitchens.find(
    (kitchen) => kitchen.kitchenId === kitchenId
  )?.country;

  const kitchensFiltered = kitchens.filter(
    (kitchen) => kitchen.country === country
  );

  return { kitchens: kitchensFiltered, country };
};
