import { UseCase } from "core/common/interfaces/useCase";
import { canGetProductionInventory } from "core/productions/interfaces/canGetProductionInventory";

export class GetProductionInventory implements UseCase {
  constructor(private readonly repository: canGetProductionInventory) {}

  public execute(kitchenId: string, sku: string) {
    return this.repository.getProductionInventory(kitchenId, sku);
  }
}
