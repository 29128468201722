/* eslint-disable react/no-array-index-key */
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { Button, DialogActions, Grid, TextField } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { commons, purchaseOrderCreation } from "app/i18n/types";
import { saveReceiptAttachments } from "app/store/slices/purchase/purchase.slice";
import { AttachmentFile } from "core/attachment/entity";
import {
  getReceiptAttachments,
  viewFile,
} from "core/attachment/repository/http";
import { AttachmentsDetailResponse } from "core/purchaseOrders/entities/ReceiptOrder";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AttachmentContent from "../Attachment/AttachmentContent";

interface Props {
  code: string;
  origin: string;
  type: string;
  initialAttachments?: AttachmentFile[];
  kitchenId: string;
  documentNo: string;
  showDocumentNo?: boolean;
  setDetail?: (detail: AttachmentsDetailResponse) => void;
  onCloseDialog: () => void;
}

const AddDocumentsDialogContent = (props: Props): React.ReactElement => {
  const {
    onCloseDialog,
    initialAttachments,
    setDetail,
    kitchenId,
    code,
    origin,
    type,
    documentNo,
    showDocumentNo,
  } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [key, setKey] = useState<string>("");
  const [attachments, setAttachments] = useState<AttachmentFile[]>([]);
  const [document, setDocument] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setKey(`${code}|${origin}|${type}`);
  }, [code, origin, type]);

  const getDocuments = async () => {
    const response = await getReceiptAttachments(code, origin, type, kitchenId);
    setAttachments(response);
  };

  const saveAttachments = async () => {
    setLoading(true);
    const response = await dispatch(
      saveReceiptAttachments({
        code: code,
        type: type,
        origin: origin,
        invoiceNo: document,
        kitchenId,
        attachments,
      })
    );
    setLoading(false);
    const attachmentsRsp = response.payload as AttachmentFile[];
    setDetail?.({
      invoiceNo: attachmentsRsp.length ? document : "",
      attachments: attachmentsRsp,
    });
    onCloseDialog();
  };

  const openDocument = (name: string) => {
    viewFile(code, origin, type, name);
  };

  useEffect(() => {
    if (!key) return;
    setDocument(documentNo);
    if (!initialAttachments) {
      getDocuments();
      return;
    }
    setAttachments(
      initialAttachments.map((attachment) => {
        return {
          name: attachment.name,
          path: attachment.path,
          id: attachment.id,
        } as AttachmentFile;
      })
    );
  }, [key]);

  return (
    <>
      <DialogContent>
        {showDocumentNo && (
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                sx={{ backgroundColor: "white" }}
                label={t(commons.ENTER_INVOICE_OR_REMISSION_NO)}
                type="text"
                onChange={(event) => {
                  setDocument(event.target.value);
                }}
                inputProps={{ style: { height: "100%" }, maxLength: 34 }}
                value={document}
                autoComplete="off"
              />
            </Grid>
          </Grid>
        )}
        <AttachmentContent
          attachments={attachments}
          setAttachments={setAttachments}
          openDocument={openDocument}
        />
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              onClick={saveAttachments}
              variant="contained"
              startIcon={<CheckCircleOutlinedIcon />}
              fullWidth
              disabled={
                (showDocumentNo && !document) || loading || !attachments.length
              }
            >
              {t(purchaseOrderCreation.CONFIRM_AND_SEND_BUTTON)}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={onCloseDialog}
              variant="outlined"
              color="error"
              startIcon={<HighlightOffOutlinedIcon />}
              fullWidth
            >
              {t(commons.CANCEL)}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

export default AddDocumentsDialogContent;
