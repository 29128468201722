import { PaginationParams } from "app/utils/pagination";
import { UseCase } from "core/common/interfaces/useCase";
import { CanGetTransferOrdersByKitchenId } from "core/transfer/interfaces/transfer/canGetTransferOrdersByKitchenId";

export class GetTransferOrdersByKitchenIdUseCase implements UseCase {
  constructor(private readonly repository: CanGetTransferOrdersByKitchenId) {}

  public execute(kitchenId: string, params: PaginationParams) {
    return this.repository.getTransferOrdersByKitchenId(kitchenId, params);
  }
}
