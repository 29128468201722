import { Button, Card, CardContent, Chip, Collapse, List } from "@mui/material";
import { commentArea } from "app/i18n/types";
import { CommentDTO } from "core/comment/entity";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CommentCardContent from "./CardContent";
import AnswerForm from "./form/Answer";

interface Props {
  comment: CommentDTO;
  createAnswer: (commentId: number, content: string) => void;
  inDrawer?: boolean;
}

const CommentCard = (props: Props) => {
  const { comment, createAnswer, inDrawer } = props;
  const { t } = useTranslation();
  const [expand, setExpand] = useState(false);

  const onClick = () => {
    setExpand(!expand);
  };

  return (
    <Card sx={{ mb: 1 }}>
      <CardContent>
        <List key={`CommentList${comment.id}`} sx={{ width: "100%" }}>
          <CommentCardContent
            key={`CommentListItem${comment.id}`}
            comment={comment}
          />
        </List>
        <Button
          onClick={onClick}
          endIcon={
            <>
              {!!comment.children.length && (
                <Chip
                  label={comment.children.length}
                  color="primary"
                  size="small"
                />
              )}
            </>
          }
        >
          {t(expand ? commentArea.HIDE_ANSWER : commentArea.SHOW_ANSWER)}
        </Button>
        <Collapse in={expand} timeout="auto" unmountOnExit>
          <Card sx={{ mt: 1 }}>
            <CardContent>
              <List sx={{ width: "100%" }}>
                {comment.children.map((answer) => (
                  <CommentCardContent
                    key={`AnswerListItem${comment.id}`}
                    comment={answer}
                  />
                ))}
              </List>
              <AnswerForm
                onSuccess={createAnswer}
                commentId={comment.id}
                inDrawer={inDrawer}
              />
            </CardContent>
          </Card>
        </Collapse>
      </CardContent>
    </Card>
  );
};

export default CommentCard;
