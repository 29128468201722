import { KOSBaseTableHeader } from "@foodology-co/alejandria";
import { bcIntegrations, commons } from "app/i18n/types";
import { TransferOrderError } from "core/BusinessCentralIntegration/entities/BusinessCentralIntegration";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import BcIntegrationTable from "./BcIntegrationTable";

interface Props {
  transferErrors: TransferOrderError[];
  loading: boolean;
}

const TransferOrderErrors: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const { transferErrors, loading } = props;

  const getTransferHeader = (): KOSBaseTableHeader[] => {
    return [
      {
        label: t(commons.COUNTRY),
        field: "country",
      },
      {
        label: t(commons.LOCATION),
        field: "originKitchenId",
      },
      {
        label: t(commons.CODE),
        field: "transferCode",
      },
      {
        label: t(commons.STATUS),
        field: "status",
      },
      {
        label: t(commons.ERROR),
        field: "error",
      },
    ];
  };

  return (
    <BcIntegrationTable
      data={transferErrors}
      headers={getTransferHeader()}
      loading={loading}
      title={t(bcIntegrations.TRANSFER)}
    />
  );
};
export default TransferOrderErrors;
