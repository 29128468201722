import { UseCase } from "core/common/interfaces/useCase";
import { CanGetByKitchen } from "../interfaces/canGetByKitchen";

export class GetByKitchenUseCase implements UseCase {
  constructor(private readonly repository: CanGetByKitchen) {}

  public execute(kitchenId: string) {
    return this.repository.getByKitchen(kitchenId);
  }
}
