import CloseIcon from "@mui/icons-material/Close";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Product } from "core/physicalCount/entities/Product";
import { FunctionComponent, SyntheticEvent } from "react";

interface FinishItemCountingModalProps {
  open: boolean;
  item: Product;
  message: string;
  title: string;
  cancelButtonText: string;
  continueButtonText: string;
  onClose: () => void;
  onContinue: (event: SyntheticEvent<Element, Event>, item: Product) => void;
}

const FinishItemCountingModal: FunctionComponent<
  FinishItemCountingModalProps
> = (props) => {
  const {
    item,
    open,
    onClose,
    onContinue,
    message,
    title,
    cancelButtonText,
    continueButtonText,
  } = props;

  return (
    <Dialog maxWidth="xs" open={open}>
      <DialogTitle id="alert-dialog-title" sx={{ pb: 0 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="h5" color="primary.main" mb={1}>
              {title}
            </Typography>
          </Box>
          <IconButton
            color="primary"
            aria-label="close"
            onClick={() => onClose()}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography mb={1} variant="h5">
          {item.name}
        </Typography>
        <Typography mb={1} variant="h5">
          {item.quantity} {item.unitShortName}
        </Typography>
        <Typography mb={1}>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{ display: "flex", flexDirection: "column" }}
          width="100%"
          gap={1}
          px={2}
          mb={1}
        >
          <Button
            size="large"
            variant="contained"
            onClick={(e) => {
              onContinue(e, item);
              onClose();
            }}
            fullWidth
          >
            {continueButtonText} <NavigateNextIcon />
          </Button>
          <Button
            size="large"
            color="error"
            variant="contained"
            onClick={(e) => {
              onClose();
            }}
            fullWidth
          >
            {cancelButtonText} <CloseIcon />
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default FinishItemCountingModal;
