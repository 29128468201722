import { Grid, Paper, Skeleton, Typography } from "@mui/material";
import POLProgressBar from "app/pages/ReceiptOrder/PurchaseOrderLines/POLProgressBar";
import { LinesPurchaseOrder } from "core/linesPurchaseOrders/entities/LinesPurchaseOrder";
import { LinesPurchaseOrdersDTO } from "core/linesPurchaseOrders/traits/linesPurchaseOrder/canCompletePurchaseOrder";
import { ReceiptOrder } from "core/purchaseOrders/entities/ReceiptOrder";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { purchaseOrderModule } from "../../../../i18n/types";
import PurchaseOrderLineResumeItem from "./Item";

interface Props {
  detail: ReceiptOrder | null;
  lines: LinesPurchaseOrdersDTO | null;
  onChangePercentage: (percentage: number) => void;
}

const PurchaseOrderLineResume: FunctionComponent<Props> = (props) => {
  const { detail, lines, onChangePercentage } = props;
  const { t } = useTranslation();

  const [percentage, setPercentage] = useState<number>(0);
  const [pendingLines, setPendingLines] = useState<number>(0);

  const loading = !detail;
  const vendorName = detail?.vendorName ?? "";
  const sourceId = detail?.sourceId ?? "";
  const createdAt = detail?.createAt.toString() ?? "";

  const getPercentage = (): number => {
    const linesInProgress =
      lines?.filter(
        (line: LinesPurchaseOrder) =>
          line.status === "IN_PROCESS" ||
          line.status === "REJECTED" ||
          line.status === "RECEIVED"
      ).length ?? 0;

    const totalLines = lines?.length ?? 0;
    setPendingLines(totalLines - linesInProgress);

    if (totalLines === 0) {
      return 100;
    }

    const percent = (linesInProgress / totalLines) * 100;

    return parseFloat(percent.toFixed(2));
  };

  useEffect(() => {
    const percentage = getPercentage();
    setPercentage(percentage);
    onChangePercentage(percentage);
  }, [lines]);

  useEffect(() => {}, []);

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6" sx={{ mb: 2.5 }}>
        {loading ? <Skeleton sx={{ width: 360 }} /> : <>{vendorName}</>}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <PurchaseOrderLineResumeItem
            label={t(purchaseOrderModule.ORDER_ID)}
            value={sourceId}
            icon="pin"
            isLoading={loading}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <PurchaseOrderLineResumeItem
            label={t(purchaseOrderModule.REQUEST_DATE)}
            value={createdAt}
            icon="event"
            isLoading={loading}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <POLProgressBar percentage={percentage} pendingLines={pendingLines} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PurchaseOrderLineResume;
