import CountertopsOutlinedIcon from "@mui/icons-material/CountertopsOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import {
  Alert,
  Button,
  Chip,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { unwrapResult } from "@reduxjs/toolkit";
import PageTabs from "app/components/PageTabs";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { useNavigator } from "app/hooks/useNavigator";
import {
  commons,
  kitchenInventoryManagement,
  physicalCountModule,
} from "app/i18n/types";
import { clearCountingHistoryState } from "app/store/slices/countingHistory";
import {
  getCountingDetailExport,
  getCountingHistoryByStatus,
} from "app/store/slices/countingHistory/thunks";
import { convertDate } from "app/utils/dateUtils";
import { AmplitudeEvent, logEvent } from "core/common/utils/analytics";
import { statusMapper } from "core/common/utils/statusMapper";
import {
  CountingKitchenExportDetail,
  RawCounting,
} from "core/physicalCount/entities/Counting";
import dayjs from "dayjs";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CountingTypeCode } from "utils/general";
import writeXlsxFile from "write-excel-file";
import { PageBox } from "../../../utils/generalUI";
import PageHeader from "../../components/PageHeader";
import { startScheduledCounting } from "../../store/slices/counting/thunks";

interface PhysicalCountHistoryProps {}

const PhysicalCountHistory: FunctionComponent<
  PhysicalCountHistoryProps
> = () => {
  const navigator = useNavigator();
  const { kitchenId } = navigator.params();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [pendingCountings, setPendingCountings] = useState<RawCounting[]>([]);
  const [finishedCountings, setFinishedCountings] = useState<RawCounting[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [value, setValue] = React.useState(0);
  const counting = useAppSelector((state) => state.countingHistory.countings);

  const status = useAppSelector((state) => state.countingHistory.status);

  const newCountingId = useAppSelector(
    (state) => state.countingHistory.newCountingId
  );

  useEffect(() => {
    if (status === "idle" && kitchenId) {
      dispatch(getCountingHistoryByStatus({ kitchenId }));
    }
  }, [dispatch, kitchenId, status]);

  useEffect(() => {
    if (counting !== null && counting.length > 0) {
      const pending = counting.filter((row) => row.status !== "FINISHED");
      setPendingCountings(pending);

      const finished = counting.filter((row) => row.status === "FINISHED");
      setFinishedCountings(finished);
    }
  }, [counting]);

  useEffect(() => {
    if (newCountingId != null) {
      dispatch(clearCountingHistoryState());
      navigator.toByLocationType(
        `/physical-count/${kitchenId}/${newCountingId}`
      );
    }
  }, [dispatch, newCountingId, kitchenId]);

  const goToDetail = (counting: RawCounting) => {
    dispatch(clearCountingHistoryState());
    navigator.toByLocationType(`/physical-count/${kitchenId}/${counting.id}`);
  };

  const downloadExcel = async (data: any, counting: RawCounting) => {
    await writeXlsxFile(data, {
      fileName: `conteo-${counting.id}.xlsx`,
    });
  };

  const goToDetailAccuracy = async (counting: RawCounting) => {
    navigator.toByLocationType(
      `/physical-count/${kitchenId}/accuracy/${counting.id}`
    );
  };

  const exportCounting = async (counting: RawCounting) => {
    if (counting) {
      setLoading(true);
      const responseDetails = await dispatch(
        getCountingDetailExport({
          countingId: counting.id,
        })
      );

      if (responseDetails) {
        let response = unwrapResult(responseDetails);
        const excelData = [];
        const headerRow = [
          { value: "ItemID", fontWeight: "bold" },
          { value: "Quantity", fontWeight: "bold" },
          { value: "Product Name", fontWeight: "bold" },
        ];
        excelData.push(headerRow);

        response.details.forEach((item: CountingKitchenExportDetail) => {
          excelData.push([
            { type: String, value: item.sku },
            { type: String, value: item.quantity || "0" },
            { type: String, value: item.productName },
          ]);
        });

        downloadExcel(excelData, counting).catch((err) => {
          console.error("Error in downloadExcel", err);
        });
        setLoading(false);
      }
    }
  };

  const countingLogProperties = (row: RawCounting) => {
    return {
      kitchenId: kitchenId,
      countingId: row.id,
      countingType: row.countingTypeCode,
    };
  };

  const startCounting = (row: RawCounting) => {
    dispatch(
      startScheduledCounting({
        countingId: row.id,
        userId: row.userId,
        kitchenId: row.kitchenId,
      })
    );
    navigator.toByLocationType(`/physical-count/${kitchenId}/${row.id}`);
  };

  const isFutureCounting = (row: RawCounting) => {
    if (dayjs.utc(row.scheduledDate).format() >= dayjs.utc().local().format()) {
      return true;
    }
    return false;
  };

  const renderRow = (row: RawCounting, t: (key: string) => string) => {
    const accuracy = (row.accuracy * 100).toFixed(2);

    return (
      <TableRow key={row.id}>
        <TableCell align="left">
          <Typography sx={{ textAlign: "left" }}>
            {statusMapper(row.status)}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography>{t(row.countingTypeCode).toUpperCase()}</Typography>
            {row.countingTypeCode === CountingTypeCode.other && (
              <Typography variant="body2">({row.name})</Typography>
            )}
          </Box>
        </TableCell>
        <TableCell align="center">{row.scheduledDate}</TableCell>
        <TableCell align="center">
          {row.endAt && convertDate(row.endAt, "MM/dd/yyyy hh:mm a")}
        </TableCell>
        {value === 1 && (
          <TableCell align="center">
            {row.accuracy !== null && (
              <Chip
                onClick={() => {
                  logEvent(
                    AmplitudeEvent.ViewCountingAccuracy,
                    countingLogProperties(row)
                  );
                  goToDetailAccuracy(row);
                }}
                label={`${accuracy} %`}
              ></Chip>
            )}
          </TableCell>
        )}

        <TableCell align="center">
          {row.status === "IN_PROCESS" && (
            <Button
              variant="outlined"
              color="warning"
              sx={{ px: 4, width: "50%" }}
              onClick={() => {
                logEvent(
                  AmplitudeEvent.ResumeCounting,
                  countingLogProperties(row)
                );
                goToDetail(row);
              }}
            >
              {t(commons.CONTINUE)}
            </Button>
          )}
          {row.status === "SCHEDULED" && (
            <Button
              variant="outlined"
              sx={{ px: 4, width: "50%" }}
              onClick={() => {
                logEvent(
                  AmplitudeEvent.InitCounting,
                  countingLogProperties(row)
                );
                startCounting(row);
              }}
              disabled={isFutureCounting(row)}
            >
              {t(commons.START)}
            </Button>
          )}
          {row.status === "FINISHED" && (
            <Button
              variant="outlined"
              sx={{ px: 4, width: "50%" }}
              onClick={() => {
                logEvent(
                  AmplitudeEvent.ExportCounting,
                  countingLogProperties(row)
                );
                exportCounting(row);
              }}
              disabled={loading}
            >
              {t(commons.EXPORT)}
            </Button>
          )}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <PageBox>
      <PageHeader
        title={t(kitchenInventoryManagement.KITCHEN_INVENTORY_TITLE)}
        subtitle={t(kitchenInventoryManagement.KITCHEN_INVENTORY_DESCRIPTION)}
      />
      <Grid spacing={2} container>
        <Grid xs={12} item>
          <PageTabs
            tabs={[
              {
                label: "PENDIENTES",
                icon: <ListAltOutlinedIcon />,
                iconPosition: "start",
              },
              {
                label: "FINALIZADOS",
                icon: <CountertopsOutlinedIcon />,
                iconPosition: "start",
              },
            ]}
            value={value}
            onChange={(value) => setValue(value)}
          />
        </Grid>

        <Grid xs={12} order={{ xs: 2, md: 3 }} item>
          <Box
            flexGrow={1}
            position="relative"
            height="100%"
            sx={{
              overflowY: "scroll",
            }}
          >
            {value === 0 ? (
              <>
                {!pendingCountings || pendingCountings.length === 0 ? (
                  <Box px={2}>
                    <Alert severity="info">
                      {t(physicalCountModule.EMPTY_COUNTINGS)}
                    </Alert>
                  </Box>
                ) : (
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 650 }}
                      aria-label="counting-schedule"
                    >
                      {tableHeader()}
                      <TableBody>
                        {pendingCountings.map((row) => renderRow(row, t))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </>
            ) : (
              <TableContainer component={Paper}>
                {loading && <LinearProgress></LinearProgress>}
                <Table sx={{ minWidth: 650 }} aria-label="counting-history">
                  {tableHeader()}
                  <TableBody>
                    {finishedCountings.map((row) => renderRow(row, t))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </Grid>
      </Grid>
    </PageBox>
  );

  function tableHeader() {
    return (
      <TableHead>
        <TableRow>
          <TableCell align="left">{t(commons.STATUS)}</TableCell>
          <TableCell align="center">{t(commons.TYPE)}</TableCell>
          <TableCell align="center">{t(commons.SCHEDULE_DATE)}</TableCell>
          <TableCell align="center">{t(commons.FINAL_DATE)}</TableCell>
          {value === 1 && (
            <TableCell align="center">{t(commons.ACCURACY)}</TableCell>
          )}

          <TableCell align="center">{t(commons.ACTIONS)}</TableCell>
        </TableRow>
      </TableHead>
    );
  }
};

export default PhysicalCountHistory;
