import { canCompletePurchaseOrderTrait } from "core/linesPurchaseOrders/traits/linesPurchaseOrder/canCompletePurchaseOrder";

export class CompletePurchaseOrderUseCase {
  constructor(private readonly repository: canCompletePurchaseOrderTrait) {}

  public execute(
    kitchenId: string,
    orderId: string,
    invoiceNumber: string,
    originId: string,
    originBD: string
  ) {
    return this.repository.completePurchaseOrder(
      kitchenId,
      orderId,
      invoiceNumber,
      originId,
      originBD
    );
  }
}
