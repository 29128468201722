import { UseCase } from "core/common/interfaces/useCase";
import { canGetProductionRecipe } from "../interfaces/canGetProductionRecipe";

export class GetProductionsRecipe implements UseCase {
  constructor(private readonly repository: canGetProductionRecipe) {}

  public execute(productionId: string, kitchenId: string) {
    return this.repository.getProductionRecipe(productionId, kitchenId);
  }
}
