import { SlotType } from "./Plan";

export interface ProductionSupplyResponse {
  id: number;
  productionDate: Date;
  status: string;
  locationId: string;
  locationName: string;
}

export interface ProductionSupplyDetail {
  id: number;
  sku: string;
  name: string;
  quantity: number;
  overQuantity: number;
  suggestedQuantity: number;
  measureUnit: string;
  timeSlot: SlotType;
  packedQuantity: number;
  receivedQuantity: number;
  supplyId: number;
  status: string;
  locationId: string;
  missingReason: string;
}

export type ProductionSupplyWithDetails = ProductionSupplyResponse & {
  details: ProductionSupplyDetail[];
};

export enum MissingReason {
  MISSING_PRODUCT = "MISSING_PRODUCT",
  PRODUCT_EXPIRED = "PRODUCT_EXPIRED",
  DAMAGED_PRODUCT = "DAMAGED_PRODUCT",
  SPARE_PRODUCT = "SPARE_PRODUCT",
}

export interface ProductionSupplyWithDetailsPayload {
  productionDate: Date;
  locationId: string;
  details: DetailToSave[];
}

export interface DetailToSave {
  sku: string;
  name: string;
  quantity: number;
  packedQuantity: number;
  measureUnit: string;
  timeSlot: string;
  supplyId: number;
}
