import { KOSEmptyState } from "@foodology-co/alejandria";
import GetAppIcon from "@mui/icons-material/GetApp";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import {
  CircularProgress,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import KitchenSelector from "app/components/Kitchen/Selector";
import PageHeader from "app/components/PageHeader/PageHeader";
import StatisticCard from "app/components/StatisticCard";
import TheoreticalInventoryTable from "app/components/TheoreticalInventory/TheoreticalInventoryTable";
import InputSearchCustom from "app/components/common/InputSearchCustom";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { useNavigator } from "app/hooks/useNavigator";
import { commons, kitchenInventoryManagement } from "app/i18n/types";
import { setCountry, setKitchen } from "app/store/slices/theoricalInventory";
import {
  exportInventoryByCountry,
  getTheoreticalInventoryByKitchen,
} from "app/store/slices/theoricalInventory/thunks";
import { downloadTextAsCsv, json2Csv } from "core/common/utils/fileUtils";
import { Kitchen } from "core/supplies/entities/Kitchen";
import { TheoreticalInventory } from "core/theoricalInventory/entities/ThereticalInventory";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageBox } from "utils/generalUI";
import { TypeOfRole } from "utils/role";

interface Props {}

const TheoricalInventoryPage: FunctionComponent<Props> = () => {
  const navigator = useNavigator();
  const { kitchenId } = navigator.params();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const kitchens = useAppSelector((state) => state.global.kitchens.data);
  const status = useAppSelector((state) => state.theoricalInventory.status);

  const exportDetail = useAppSelector(
    (state) => state.theoricalInventory.exportInventory
  );
  const statusInventory = useAppSelector(
    (state) => state.theoricalInventory.statusInventory
  );
  const statusExport = useAppSelector(
    (state) => state.theoricalInventory.statusExport
  );
  const inventories = useAppSelector(
    (state) => state.theoricalInventory.inventory
  );

  const selectedCountry = useAppSelector(
    (state) => state.theoricalInventory.country
  );

  const selectedKitchen = useAppSelector(
    (state) => state.theoricalInventory.kitchen
  );

  const user = useAppSelector((state) => state.session.user);

  const [dataFilter, setDataFilter] = useState<"all" | "zero" | "low" | "high">(
    "all"
  );

  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    if (status === "succeeded" && kitchenId) {
      const kitchen = kitchens.find(
        (kitchen: Kitchen) => kitchen.kitchenId === kitchenId
      );
      if (kitchen) {
        dispatch(setCountry(kitchen.country));
        dispatch(setKitchen(kitchen));
      }
    }
  }, [dispatch, status, kitchens, kitchenId]);

  useEffect(() => {
    if (selectedKitchen != null) {
      dispatch(
        getTheoreticalInventoryByKitchen({
          kitchenId: selectedKitchen.kitchenId,
        })
      );
    }
  }, [dispatch, selectedKitchen]);

  const exportInventory = () => {
    if (selectedCountry) {
      dispatch(exportInventoryByCountry({ country: selectedCountry }));
    }
  };

  useEffect(() => {
    if (exportDetail != null) {
      const data = exportDetail.map((detail) => {
        return {
          sku: detail.sku,
          producto: detail.productName,
          cocina: detail.kitchen,
          cantidad: detail.quantity,
          unidad: detail.measureUnit,
          grupo: detail.group,
        };
      });
      const kitchenId = selectedKitchen
        ? selectedKitchen.kitchenId.toUpperCase()
        : null;
      const filteredData = selectedKitchen
        ? data.filter((it) => it.cocina.toUpperCase() === kitchenId)
        : data;
      downloadTextAsCsv(json2Csv(filteredData), "inventario-teorico.csv");
    }
  }, [dispatch, exportDetail, selectedKitchen]);

  const roundNumber = useCallback((num: number | null) => {
    if (num === null) {
      return 0;
    }

    return Math.round((num + Number.EPSILON) * 100) / 100;
  }, []);

  const comparissonDataPercentage = useCallback(
    (difference: "all" | "zero" | "low" | "high") => {
      const data = inventories ?? [];

      const total = data.length;

      if (total === 0) {
        return "0%";
      }

      if (difference === "all") {
        const all = data.length;
        return `${roundNumber((all / total) * 100)}%`;
      }

      if (difference === "zero") {
        const zero = data.filter((row) => row.quantity === 0).length;
        return `${roundNumber((zero / total) * 100)}%`;
      }

      if (difference === "low") {
        const low = data.filter((row) => row.quantity < 0).length;
        return `${roundNumber((low / total) * 100)}%`;
      }

      if (difference === "high") {
        const high = data.filter((row) => row.quantity > 0).length;
        return `${roundNumber((high / total) * 100)}%`;
      }

      return "100%";
    },
    [inventories, roundNumber]
  );

  const comparissonDataFilter = useCallback(
    (row: TheoreticalInventory) => {
      if (dataFilter === "zero") {
        return row.quantity === 0;
      }

      if (dataFilter === "low") {
        return row.quantity < 0;
      }

      if (dataFilter === "high") {
        return row.quantity > 0;
      }

      return true;
    },
    [dataFilter]
  );

  return (
    <PageBox>
      {status === "loading" && <LinearProgress />}

      <PageHeader
        title={t(kitchenInventoryManagement.INVENTORYREVIEW_MODULE)}
        subtitle={t(
          kitchenInventoryManagement.INVENTORYREVIEW_MODULE_DESCRIPTION
        )}
        rightArea={[
          {
            children: "Exportar",
            onClick: () => exportInventory(),
            variant: "contained",
            size: "large",
            disabled:
              !selectedCountry ||
              statusExport === "loading" ||
              user.data?.role === TypeOfRole.JEFE_CP ||
              user.data?.role === TypeOfRole.SEGUNDO_CP,
            startIcon:
              statusExport === "loading" ? (
                <CircularProgress size={20} />
              ) : (
                <GetAppIcon />
              ),
          },
        ]}
      />

      <Box
        width="100%"
        display="flex"
        gap={2}
        alignItems="center"
        justifyContent="space-between"
        flexWrap={{ xs: "wrap", md: "nowrap" }}
        my={2}
      >
        <Box width="100%" maxWidth={800}>
          <Paper
            sx={{ p: 2, display: "flex", flexDirection: "column", gap: 1 }}
          >
            <Typography variant="h6">{t(commons.FILTERS)}</Typography>
            <KitchenSelector
              selectedCountry={selectedCountry}
              selectedCity={selectedKitchen?.city}
              selectedLocation={selectedKitchen}
              onCountryChange={(countryValue) => {
                dispatch(setCountry(countryValue));
              }}
              onKitchenChange={(kitchen) => {
                dispatch(setKitchen(kitchen));
              }}
            />
          </Paper>
        </Box>
        <InputSearchCustom
          search={searchTerm}
          setSearch={setSearchTerm}
          title={t(commons.FIND_INPUT)}
          label={t(commons.FIND_SUPPLY_LABEL)}
          fullWidth
        />
      </Box>

      {selectedCountry && selectedKitchen && (
        <Box display={"flex"} mb={2} gap={2} flexWrap={"wrap"}>
          <StatisticCard
            icon={TrendingUpOutlinedIcon}
            label={"Todos"}
            value={`${inventories?.length ?? 0}`}
            count={``}
            color="primary"
            selected={dataFilter === "all"}
            onClick={() => setDataFilter("all")}
            loading={statusInventory === "loading"}
            disabled={statusInventory !== "succeeded"}
          />

          <StatisticCard
            icon={TrendingUpOutlinedIcon}
            label={"Con inventario"}
            value={comparissonDataPercentage("high")}
            count={`${
              inventories?.filter((it) => it.quantity > 0).length ?? 0
            } insumos`}
            color="success"
            selected={dataFilter === "high"}
            onClick={() => setDataFilter("high")}
            loading={statusInventory === "loading"}
            disabled={statusInventory !== "succeeded"}
          />

          <StatisticCard
            icon={TrendingUpOutlinedIcon}
            label={"Sin inventario"}
            value={comparissonDataPercentage("zero")}
            count={`${
              inventories?.filter((it) => it.quantity === 0).length ?? 0
            } insumos`}
            color="warning"
            selected={dataFilter === "zero"}
            onClick={() => setDataFilter("zero")}
            loading={statusInventory === "loading"}
            disabled={statusInventory !== "succeeded"}
          />

          <StatisticCard
            icon={TrendingUpOutlinedIcon}
            label={"Inventario negativo"}
            value={comparissonDataPercentage("low")}
            count={`${
              inventories?.filter((it: TheoreticalInventory) => it.quantity < 0)
                .length ?? 0
            } insumos`}
            color="error"
            selected={dataFilter === "low"}
            onClick={() => setDataFilter("low")}
            loading={statusInventory === "loading"}
            disabled={statusInventory !== "succeeded"}
          />
        </Box>
      )}

      {(!selectedCountry || !selectedKitchen) && (
        <Box>
          <KOSEmptyState
            icon={InfoOutlinedIcon}
            instruction="Selecciona pais y cocina"
          />
        </Box>
      )}
      {selectedCountry && selectedKitchen && (
        <TheoreticalInventoryTable
          loading={statusInventory === "loading"}
          kitchenId={selectedKitchen.kitchenId}
          searchTerm={searchTerm}
          items={inventories?.filter(comparissonDataFilter) ?? []}
        />
      )}
    </PageBox>
  );
};

export default TheoricalInventoryPage;
