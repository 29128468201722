import { UseCase } from "core/common/interfaces/useCase";
import { CanGetComparisonByCountry } from "../interfaces/canGetComparisonByCountry";

export class GetComparisonByCountryUseCase implements UseCase {
  constructor(private readonly repository: CanGetComparisonByCountry) {}

  public execute(country: string) {
    return this.repository.getComparisonByCountry(country);
  }
}
