/* eslint-disable react/require-default-props */
import React from "react";

interface IconProp {
  fontSize?: string;
}

const InventoryReviewIcon = (props: IconProp): React.ReactElement => {
  const { fontSize } = props;
  return (
    <svg
      width={fontSize ?? 40}
      height={fontSize ?? 40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width={fontSize ?? 40}
        height={fontSize ?? 40}
        rx="20"
        fill="#F1EFEF"
      />
      <mask
        id="mask0_5932_297"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="24"
        height="24"
      >
        <rect x="8" y="8" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5932_297)">
        <path
          d="M10 27V25H20V27H10ZM10 22V20H15V22H10ZM10 17V15H15V17H10ZM28.6 27L24.75 23.15C24.35 23.4333 23.9125 23.6458 23.4375 23.7875C22.9625 23.9292 22.4833 24 22 24C20.6167 24 19.4375 23.5125 18.4625 22.5375C17.4875 21.5625 17 20.3833 17 19C17 17.6167 17.4875 16.4375 18.4625 15.4625C19.4375 14.4875 20.6167 14 22 14C23.3833 14 24.5625 14.4875 25.5375 15.4625C26.5125 16.4375 27 17.6167 27 19C27 19.4833 26.9292 19.9625 26.7875 20.4375C26.6458 20.9125 26.4333 21.35 26.15 21.75L30 25.6L28.6 27ZM22 22C22.8333 22 23.5417 21.7083 24.125 21.125C24.7083 20.5417 25 19.8333 25 19C25 18.1667 24.7083 17.4583 24.125 16.875C23.5417 16.2917 22.8333 16 22 16C21.1667 16 20.4583 16.2917 19.875 16.875C19.2917 17.4583 19 18.1667 19 19C19 19.8333 19.2917 20.5417 19.875 21.125C20.4583 21.7083 21.1667 22 22 22Z"
          fill="#232323"
        />
      </g>
    </svg>
  );
};

export default InventoryReviewIcon;
