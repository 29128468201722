import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import appConfig from "config/app";
import { ModifyPayload } from "core/purchaseOrders/entities/PurchaseOrder";
import {
  AttachmentSavePayload,
  ReceiptOrder,
} from "core/purchaseOrders/entities/ReceiptOrder";
import { PurchaseOrdersHTTPRepository } from "core/purchaseOrders/repositories/http/purchaseOrders";
import {
  GetReceiptOrderByReceiptNoUseCase,
  GetReceiptOrdersForKitchenUseCase,
  SaveReceiptAttachmentsUseCase,
} from "core/purchaseOrders/useCases/purchaseOrder/getPurchaseOrdersForKitchenUseCase";
import { UpdateLinesQuantityAndUnitPriceUseCase } from "core/purchaseOrders/useCases/purchaseOrder/updateLinesQuantityAndUnitPriceUseCase";
import { StoreRequestError, StoreRequestStatus } from "../../types";

interface PurchaseState {
  orders: Array<ReceiptOrder> | null;
  filter: string | null;
  status: StoreRequestStatus;
  error: StoreRequestError;
}

const initialState: PurchaseState = {
  orders: null,
  filter: null,
  status: "idle",
  error: null,
};

export const updateLinesQuantityAndUnitPrice = createAsyncThunk(
  "purchase/updateLinesQuantityAndUnitPrice",
  async (payload: ModifyPayload) => {
    const httpRepository = new PurchaseOrdersHTTPRepository(appConfig);
    const updateCase = new UpdateLinesQuantityAndUnitPriceUseCase(
      httpRepository
    );
    const result = await updateCase.execute(payload);
    return result;
  }
);

export const getPurchaseForKitchen = createAsyncThunk(
  "purchase/getPurchaseForKitchen",
  async (kitchenId: string) => {
    const httpRepository = new PurchaseOrdersHTTPRepository(appConfig);
    const getPurchaseOrdersForKitchenUseCase =
      new GetReceiptOrdersForKitchenUseCase(httpRepository);
    const productsForKitchen = await getPurchaseOrdersForKitchenUseCase.execute(
      kitchenId
    );
    return productsForKitchen;
  }
);

export const getReceiptOrderByReceiptNo = createAsyncThunk(
  "purchase/getReceiptOrderByReceiptNo",
  async (payload: { receiptNo: string; origin: string; kitchenId: string }) => {
    const httpRepository = new PurchaseOrdersHTTPRepository(appConfig);
    const useCase = new GetReceiptOrderByReceiptNoUseCase(httpRepository);
    const productsForKitchen = await useCase.execute(
      payload.receiptNo,
      payload.origin,
      payload.kitchenId
    );
    return productsForKitchen;
  }
);

export const saveReceiptAttachments = createAsyncThunk(
  "purchase/saveReceiptAttachments",
  async (data: AttachmentSavePayload) => {
    const httpRepository = new PurchaseOrdersHTTPRepository(appConfig);
    const useCase = new SaveReceiptAttachmentsUseCase(httpRepository);
    const save = await useCase.execute(data);
    return save;
  }
);

export const purchaseSlice = createSlice({
  name: "purchase",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPurchaseForKitchen.pending, (state, action) => {
        state.status = "loading";
        state.orders = null;
        state.error = null;
      })
      .addCase(getPurchaseForKitchen.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.orders = action.payload;
        state.error = null;
      })
      .addCase(getPurchaseForKitchen.rejected, (state, action) => {
        state.status = "failed";
        state.orders = null;
        state.error = action.error.message ?? null;
      });
  },
});

export const { setFilter } = purchaseSlice.actions;

export const { reducer } = purchaseSlice;

export default purchaseSlice;
