import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { FunctionComponent } from "react";

interface Props {
  label: string;
  value: string | null;
  icon: string;
  isLoading: boolean;
}

const PurchaseOrderLineResumeItem: FunctionComponent<Props> = (props) => {
  const { label, value, icon, isLoading } = props;

  return (
    <Box display="flex" gap={2}>
      <Icon baseClassName="material-icons-outlined">{icon}</Icon>
      <Box>
        <Typography color="text.secondary" fontWeight={300} gutterBottom>
          {label}
        </Typography>
        <Typography fontWeight={600}>
          {isLoading || value === null ? (
            <Skeleton sx={{ width: 96 }} />
          ) : (
            <>{value}</>
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default PurchaseOrderLineResumeItem;
