import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  icon: React.ReactElement;
  title: string;
  body: string;
}

const GridItem = (props: Props): React.ReactElement => {
  const { icon, title, body } = props;
  const { t } = useTranslation();

  const sxTypography = {
    display: "-webkit-box",
    overflow: "hidden",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        gap: 1,
      }}
    >
      {icon}
      <Box>
        <Typography sx={sxTypography} variant="body1">
          {t(title)}
        </Typography>
        <Typography sx={sxTypography} variant="body1" fontWeight={600}>
          {t(body)}
        </Typography>
      </Box>
    </Box>
  );
};

export default GridItem;
