import { UseCase } from "core/common/interfaces/useCase";
import { ReportTransferOrderPayload } from "core/transfer/entities/TransferOrder";
import { CanReportTransferOrder } from "core/transfer/interfaces/transfer/canReportTransferOrder";

export class ReportTransferOrderUseCase implements UseCase {
  constructor(private readonly repository: CanReportTransferOrder) {}

  public execute(payload: ReportTransferOrderPayload) {
    return this.repository.reportTransferOrder(payload);
  }
}
