import TrackChangesOutlinedIcon from "@mui/icons-material/TrackChangesOutlined";
import { Button, Grid, Paper, Typography } from "@mui/material";
import InputSearchCustom from "app/components/common/InputSearchCustom";
import { productionManagement } from "app/i18n/types";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface ProductionLogHeaderProps {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  handleClick: () => void;
}

const ProductionLogHeader: FunctionComponent<ProductionLogHeaderProps> = (
  props
) => {
  const { searchTerm, setSearchTerm, handleClick } = props;
  const { t } = useTranslation();

  return (
    <Paper sx={{ p: 2, width: "100%" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h6">
            {t(productionManagement.LOG_TITLE_INPUT)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
          <InputSearchCustom
            label={productionManagement.LOG_LABEL_INPUT}
            search={searchTerm}
            setSearch={setSearchTerm}
            compact
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            variant="contained"
            startIcon={<TrackChangesOutlinedIcon />}
            fullWidth
            sx={{ height: "100%", borderRadius: 2 }}
            disabled={!searchTerm}
            onClick={handleClick}
          >
            {t(productionManagement.LOG_TRACK)}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ProductionLogHeader;
