import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { countingTypeSelector } from "app/i18n/types";
import { CountingType } from "core/physicalCount/entities/Counting";
import { FunctionComponent, memo } from "react";
import { useTranslation } from "react-i18next";

interface CountingTypeSelectorProps {
  options: CountingType[];
  selectedCountingType: CountingType | null;
  setSelectedCountingType: (selectedCountingType: CountingType | null) => void;
  disabled?: boolean;
  size?: "small" | "medium";
}

const CountingTypeSelector: FunctionComponent<CountingTypeSelectorProps> = (
  props
) => {
  const {
    options,
    selectedCountingType,
    setSelectedCountingType,
    disabled,
    size,
  } = props;

  const { t } = useTranslation();

  options.sort((a, b) => {
    const aName = a.name ?? "";
    const bName = b.name ?? "";
    return aName.localeCompare(bName);
  });

  return (
    <Autocomplete
      id={`counting-type-selector-autocomplete`}
      options={options}
      getOptionLabel={(option) => {
        const name = option.name ?? "";
        return name.toLocaleUpperCase();
      }}
      value={selectedCountingType}
      onChange={(_: any, newValue: CountingType | null) =>
        setSelectedCountingType(newValue ?? null)
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={t(countingTypeSelector.LABEL)}
          size={size}
        />
      )}
      fullWidth
      disabled={disabled || options.length === 0}
    />
  );
};

export default memo(CountingTypeSelector);
