import { Box, Chip } from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import React, { FunctionComponent, useState } from "react";
import { useMount } from "react-use";
import { sxCustomWhite } from "utils/generalUI";

type CustomTab = ButtonProps & {
  hide?: boolean;
  childrenWithChip?: {
    startIcon: React.ReactElement;
    text: string;
    chipLabel: string | number;
  };
};

interface CustomTabsProps {
  key?: string;
  tabs: CustomTab[];
  onChange: (tab: number) => void;
}

const CustomTabs: FunctionComponent<CustomTabsProps> = (props) => {
  const { key, tabs, onChange } = props;
  const [currentTab, setCurrentTab] = useState<number>(-1);

  const onClick = (index: number) => {
    setCurrentTab(index);
    onChange(index);
  };

  useMount(() => {
    onClick(tabs.findIndex((el) => !el.hide));
  });

  return (
    <ButtonGroup size="large" sx={{ width: "100%" }}>
      {tabs.map((tab, index) => (
        <>
          {!tab.hide && (
            <Button
              key={`${key ?? "CustomTabs"}-${index}`}
              children={
                <>
                  {tab.childrenWithChip && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 1,
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          fontSize: "15px",
                          fontWeight: "700",
                        }}
                      >
                        {tab.childrenWithChip.startIcon}
                        {tab.childrenWithChip.text}
                      </Box>
                      {!!tab.childrenWithChip.chipLabel && (
                        <Chip
                          sx={{ ml: 3, color: "white" }}
                          color={currentTab === index ? "secondary" : "primary"}
                          label={tab.childrenWithChip.chipLabel}
                        />
                      )}
                    </Box>
                  )}
                </>
              }
              {...tab}
              fullWidth
              startIcon={tab.startIcon}
              onClick={() => onClick(index)}
              sx={{
                textTransform: "none",
                boxShadow:
                  "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
                border: "hidden",
                ...(currentTab === index ? sxCustomWhite : {}),
              }}
            />
          )}
        </>
      ))}
    </ButtonGroup>
  );
};

export default CustomTabs;
