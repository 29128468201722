/* eslint-disable react/require-default-props */
import React from "react";

interface IconProp {
  fontSize?: string;
}

const DailyMovsIcon = (props: IconProp): React.ReactElement => {
  const { fontSize } = props;
  return (
    <svg
      width={fontSize ?? 40}
      height={fontSize ?? 40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width={fontSize ?? 40}
        height={fontSize ?? 40}
        rx="20"
        fill="#F1EFEF"
      />
      <mask
        id="mask0_5932_311"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="24"
        height="24"
      >
        <rect x="8" y="8" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5932_311)">
        <path
          d="M16 21V13.825L13.425 16.4L12 15L17 10L22 15L20.575 16.4L18 13.825V21H16ZM23 30L18 25L19.425 23.6L22 26.175V19H24V26.175L26.575 23.6L28 25L23 30Z"
          fill="#232323"
        />
      </g>
    </svg>
  );
};

export default DailyMovsIcon;
