import { AppConfig } from "config/app";
import {
  AddManualAdjustmentResponse,
  ManualAdjustment,
} from "core/adjustments/entities/ManualAdjustment";
import { CanAddManualAdjustment } from "core/adjustments/interfaces/adjustment/canAddManualAdjustment";
import { ApiVersion, postHttp } from "utils/http";

export class ManualAdjustmentHTTPRepository implements CanAddManualAdjustment {
  constructor(private readonly config: AppConfig) {}

  public async addManualAdjustment(
    adjustments: Array<ManualAdjustment>
  ): Promise<AddManualAdjustmentResponse> {
    try {
      const response = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `daily-adjustments/manual-adjustments`,
        adjustments
      );
      const responseJSON: AddManualAdjustmentResponse = await response.json();
      return responseJSON;
    } catch (err) {
      console.error("Error adding manual adjustment", err);
      throw err;
    }
  }

  public async validateAdjustments(
    adjustments: Array<ManualAdjustment>
  ): Promise<AddManualAdjustmentResponse> {
    try {
      const response = await postHttp(
        this.config.kitchenInventory.apiBaseUrl,
        ApiVersion.V1,
        `daily-adjustments/validate-many`,
        adjustments
      );
      const responseJson: AddManualAdjustmentResponse = await response.json();
      return responseJson;
    } catch (err) {
      console.error("Error validate adjustments", err);
      throw err;
    }
  }
}
