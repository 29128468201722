import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Chip, Grid, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import ProgressBar from "app/components/ProgressBar";
import { ProgressBarData } from "app/components/ProgressBar/ProgressBar";
import { commons } from "app/i18n/types";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

interface PackingProgressProps {
  progBar: ProgressBarData;
  label: string;
}

const PackingProgress: FunctionComponent<PackingProgressProps> = (props) => {
  const { progBar, label } = props;

  const { t } = useTranslation();
  const [color, setColor] = useState<string>("");

  return (
    <Paper sx={{ p: 2, height: "100%" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h6">{t(commons.PACKING_PROGRESS)}</Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Chip
            icon={
              progBar.progress === progBar.total ? (
                <CheckCircleOutlineOutlinedIcon
                  sx={{ color: "white !important" }}
                />
              ) : (
                <ErrorOutlineOutlinedIcon sx={{ color: "white !important" }} />
              )
            }
            label={t(label, {
              packing: progBar.progress,
              total: progBar.total,
              percentage: `${progBar.percentage}%`,
            })}
            sx={{
              backgroundColor: color,
              color: "white",
              fontWeight: "bold",
              borderRadius: "4px",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ProgressBar
            percent={Number(progBar.percentage)}
            setColor={setColor}
            compact
            hidePercentLabel
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PackingProgress;
