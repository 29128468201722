import { createAsyncThunk } from "@reduxjs/toolkit";
import appConfig from "config/app";
import {
  ProductionPlanSave,
  UpdateDateAndSlotPayload,
} from "core/productions/entities/Plan";
import { ProductionPlanHTTPRepository } from "core/productions/repositories/http/Plan";
import {
  ArchiveProductionPlanUseCase,
  SendProductionsScheduleUseCase,
  UpdateDateAndSlotUseCase,
  UpdateProductionPlanUseCase,
} from "core/productions/useCases/sendProductionScheduleUseCase";

export const productionScheduleSave = createAsyncThunk(
  "production/productionScheduleSave",
  async (payload: { kitchenId: string; body: ProductionPlanSave[] }) => {
    const httpRepository = new ProductionPlanHTTPRepository(appConfig);
    const useCase = new SendProductionsScheduleUseCase(httpRepository);

    const useCaseResult = await useCase.execute(
      payload.kitchenId,
      payload.body
    );

    return useCaseResult;
  }
);

export const updateDateAndSlot = createAsyncThunk(
  "production/updateDateAndSlot",
  async (payload: UpdateDateAndSlotPayload) => {
    const httpRepository = new ProductionPlanHTTPRepository(appConfig);
    const useCase = new UpdateDateAndSlotUseCase(httpRepository);

    const useCaseResult = await useCase.execute(payload);

    return useCaseResult;
  }
);

export const updateProductionPlan = createAsyncThunk(
  "production/updateProductionPlan",
  async (payload: { productionPlanId: number; plan: ProductionPlanSave }) => {
    const httpRepository = new ProductionPlanHTTPRepository(appConfig);
    const useCase = new UpdateProductionPlanUseCase(httpRepository);

    const useCaseResult = await useCase.execute(
      payload.productionPlanId,
      payload.plan
    );

    return useCaseResult;
  }
);

export const archiveProductionPlan = createAsyncThunk(
  "production/archiveProductionPlan",
  async (payload: { productionPlanId: number }) => {
    const httpRepository = new ProductionPlanHTTPRepository(appConfig);
    const useCase = new ArchiveProductionPlanUseCase(httpRepository);
    const useCaseResult = await useCase.execute(payload.productionPlanId);
    return useCaseResult;
  }
);
