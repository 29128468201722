import { UseCase } from "core/common/interfaces/useCase";
import { CanGetSupplyInactiveStatusForKitchens } from "core/supplies/interfaces/supplies/canGetSupplyInactiveStatusForKitchens";

export class GetSupplyInactiveStatusForKitchensUseCase implements UseCase {
  constructor(
    private readonly repository: CanGetSupplyInactiveStatusForKitchens
  ) {}

  public execute(sku: string) {
    return this.repository.getSupplyInactiveStatusForKitchens(sku);
  }
}
