import { Grid } from "@mui/material";
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useAppSelector } from "app/hooks/useAppSelector";
import { commons, purchaseOrderCreation } from "app/i18n/types";
import { filterKitchensByUser } from "app/utils/kitchenSelector.utils";
import { NewPurchaseOrder } from "core/purchaseOrders/entities/PurchaseOrder";
import { createPurchaseOrders } from "core/purchaseOrders/repositories/http/purchase";
import { Kitchen } from "core/supplies/entities/Kitchen";
import { Vendor } from "core/vendors/entities/Vendor";
import dayjs from "dayjs";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TypeOfRole, isSuperRole } from "utils/role";
import VendorSelector from "../Vendor/Selector";
import { ManagerDialog } from "../common/Manager/Dialog";
import { ManagerDialogFooter } from "../common/Manager/Dialog/Footer";

interface Props {
  onClose: () => void;
  onPurchaseOrderCreated: () => void;
}

const CreatePurchaseOrderModal: FunctionComponent<Props> = (props) => {
  const { onClose, onPurchaseOrderCreated } = props;

  const { t } = useTranslation();

  const kitchensFromStore = useAppSelector(
    (state) => state.global.kitchens.data
  );
  const user = useAppSelector((state) => state.session.user.data);

  const [newPurchaseOrder, setNewPurchaseOrder] = useState<NewPurchaseOrder>({
    vendorCode: "",
    expectedReceiptAt: dayjs().format("YYYY-MM-DD"),
    invoiceNo: "",
    lines: [],
    locationCode: "",
    orderDate: dayjs().format("YYYY-MM-DD"),
    postingDate: dayjs().format("YYYY-MM-DD"),
  });
  const [selectedVendor, setSelectedVendor] = useState<Vendor>();
  const [kitchens, setKitchens] = useState<Array<Kitchen>>([]);
  const [country, setCountry] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleCreatePurchaseOrder = () => {
    setLoading(true);
    createPurchaseOrders([newPurchaseOrder], "MANUAL")
      .then(() => {
        onPurchaseOrderCreated();
        onClose();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (newPurchaseOrder.locationCode !== "") {
      const kitchen = kitchens.find(
        (k) => k.kitchenId === newPurchaseOrder.locationCode
      );
      if (kitchen) {
        setCountry(kitchen.country);
      }
    }
  }, [newPurchaseOrder.locationCode, kitchens]);

  useEffect(() => {
    if (user && kitchensFromStore) {
      if (isSuperRole(user.role as TypeOfRole)) {
        setKitchens(kitchensFromStore);
      } else {
        const { kitchens, country } = filterKitchensByUser(
          user,
          kitchensFromStore
        );
        setKitchens(kitchens);
        setCountry(country ?? "");
      }
    }
  }, [user, kitchensFromStore]);

  useEffect(() => {
    setNewPurchaseOrder((prevState) => {
      return {
        ...prevState,
        vendorCode: selectedVendor?.externalCode ?? "",
      };
    });
  }, [selectedVendor]);

  return (
    <ManagerDialog
      title={purchaseOrderCreation.CREATE_ORDER_MODAL_TITLE}
      onClose={onClose}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Alert severity="info">
              {t(purchaseOrderCreation.CREATE_ORDER_MODAL_INFO)}
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t(
                    purchaseOrderCreation.CREATE_ORDER_MODAL_LOCATION_LABEL
                  )}
                  sx={{ bgcolor: "white" }}
                />
              )}
              value={kitchens.find(
                (k) => k.kitchenId === newPurchaseOrder.locationCode
              )}
              options={kitchens}
              getOptionLabel={(option) => option.name}
              onChange={(_, value) =>
                setNewPurchaseOrder((prevState) => {
                  return {
                    ...prevState,
                    locationCode: value?.kitchenId ?? "",
                  };
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <VendorSelector
              country={country}
              selectedVendor={selectedVendor}
              setSelectedVender={setSelectedVendor}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              value={newPurchaseOrder.invoiceNo}
              label={t(purchaseOrderCreation.CREATE_ORDER_MODAL_INVOICE_LABEL)}
              onChange={(e) => {
                setNewPurchaseOrder((prevState) => {
                  return {
                    ...prevState,
                    invoiceNo: e.target.value,
                  };
                });
              }}
              autoComplete="off"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="date"
              label={t(purchaseOrderCreation.ESTIMATED_DATE_LABEL)}
              value={newPurchaseOrder.expectedReceiptAt}
              onChange={(e) =>
                setNewPurchaseOrder((prevState) => {
                  return {
                    ...prevState,
                    expectedReceiptAt: e.target.value,
                  };
                })
              }
              autoComplete="off"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <ManagerDialogFooter
              mainButton={{
                children: t(commons.ADD),
                onClick: handleCreatePurchaseOrder,
                disabled:
                  !newPurchaseOrder.locationCode ||
                  !newPurchaseOrder.vendorCode ||
                  !newPurchaseOrder.invoiceNo ||
                  !newPurchaseOrder.expectedReceiptAt ||
                  loading,
              }}
              onCancel={onClose}
              loading={loading}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

export default CreatePurchaseOrderModal;
