import { CancelOutlined, CheckCircleOutline } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import NumberField from "app/components/common/Field/Number";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useNavigator } from "app/hooks/useNavigator";
import {
  commons,
  measureUnits,
  productionManagement,
  transferOrdersPackedReviewTable,
} from "app/i18n/types";
import { saveDetail } from "app/store/slices/productions/Supply/thunks";
import { Product } from "core/dailyAdjustments/entities/Catalog";
import { ProductionCatalog } from "core/productions/entities/Catalog";
import { SlotEnum } from "core/productions/entities/Plan";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ProductionSelector from "../../Selector";

interface Props {
  suppliesToAdd: Product[];
  onClose: () => void;
  onRefresh: () => void;
  skusToDisabled?: string[];
}

export const AddExtraSupplyDialogContent = (props: Props) => {
  const { suppliesToAdd, onClose, onRefresh, skusToDisabled } = props;
  const navigator = useNavigator();
  const { kitchenId, supplyId } = navigator.params();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<number>(0);
  const [timeSlot, setTimeSlot] = useState<string>(SlotEnum.AM);
  const [selectedProduction, setSelectedProduction] =
    useState<ProductionCatalog | null>(null);

  const readyToSave = !!selectedProduction && !!quantity && !!timeSlot;

  const onSave = async () => {
    if (supplyId && readyToSave) {
      setIsLoading(true);
      await dispatch(
        saveDetail({
          supplyId: Number(supplyId),
          sku: selectedProduction.sku,
          name: selectedProduction.name,
          measureUnit: selectedProduction.unit,
          quantity: quantity,
          packedQuantity: quantity,
          timeSlot,
        })
      );
      onRefresh();
      onClose();
      setIsLoading(false);
    }
  };

  return (
    <Grid container spacing={3} width="527px">
      <Grid item xs={12}>
        <ProductionSelector
          kitchenId={kitchenId ?? ""}
          selectedProduction={selectedProduction}
          setSelectedProduction={setSelectedProduction}
          label={commons.FIND_SUPPLY_LABEL}
          list={suppliesToAdd.map((el) => {
            const { _id, ingredients, ...rest } = el;
            return {
              ...rest,
              id: _id,
              ingredients: [],
              inventory: null,
              quantityToProduce: null,
            } as ProductionCatalog;
          })}
          skusToDisabled={skusToDisabled}
        />
      </Grid>

      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel>{t(productionManagement.TIME_SLOT)}</InputLabel>
          <Select
            name="timeSlot"
            label={t(productionManagement.TIME_SLOT)}
            value={timeSlot}
            onChange={(e) => setTimeSlot(e.target.value)}
          >
            {Object.keys(SlotEnum).map((slot) => (
              <MenuItem key={`timeSlot-${slot}`} value={slot}>
                {slot}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={8}>
        <Box display="flex" alignItems="center" gap={3}>
          <FormControl variant="outlined" fullWidth>
            <NumberField
              name="quantity"
              value={quantity}
              label={t(transferOrdersPackedReviewTable.QUANTITY_TO_SEND)}
              onChangeValue={(newValue) => setQuantity(newValue)}
            />
          </FormControl>

          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "24px",
              fontFamily: "Inter",
            }}
          >
            {selectedProduction?.unit ?? t(measureUnits.UN)}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={6}>
        <Button
          endIcon={<CancelOutlined />}
          color="error"
          type="button"
          onClick={onClose}
          variant="outlined"
          fullWidth
        >
          {t(commons.CANCEL)}
        </Button>
      </Grid>

      <Grid item xs={6}>
        <Button
          endIcon={<CheckCircleOutline />}
          variant="contained"
          fullWidth
          disabled={!readyToSave || isLoading}
          onClick={onSave}
        >
          {t(commons.CONFIRM)}
        </Button>
      </Grid>
    </Grid>
  );
};
