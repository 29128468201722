import { canUpdatePurchaseOrderLineTrait } from "core/linesPurchaseOrders/traits/linesPurchaseOrder/canUpdatePurchaseOrderLineTrait";

export class UpdatePurchasOrderLineUseCase {
  constructor(private readonly repository: canUpdatePurchaseOrderLineTrait) {}

  public execute(
    id: number,
    lineNo: number,
    kitchenId: string,
    sku: string,
    quantityReceived: number,
    status: "REJECTED" | "IDLE" | "IN_PROCESS" | "FINISHED" | null,
    receiptNo: string,
    userId: string,
    externalCode: string,
    origin: string
  ) {
    return this.repository.updatePurchaseOrderLine(
      id,
      lineNo,
      kitchenId,
      sku,
      quantityReceived,
      status,
      receiptNo,
      userId,
      externalCode,
      origin
    );
  }
}
