import { UseCase } from "core/common/interfaces/useCase";
import { CanGetOrganolepticsResults } from "../interfaces/canGetOrganolepticsResults";

export class GetOrganolepticsResultsUseCase implements UseCase {
  constructor(private readonly repository: CanGetOrganolepticsResults) {}

  public execute(productionId: string) {
    return this.repository.getOrganolepticsResults(productionId);
  }
}
