/* eslint-disable react/require-default-props */
import React from "react";

interface IconProp {
  fontSize?: string;
}

const KitchenInventoryIcon = (props: IconProp): React.ReactElement => {
  const { fontSize } = props;
  return (
    <svg
      width={fontSize ?? 40}
      height={fontSize ?? 40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width={fontSize ?? 40}
        height={fontSize ?? 40}
        rx="20"
        fill="#F1EFEF"
      />
      <mask
        id="mask0_5915_547"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="24"
        height="24"
      >
        <rect x="8" y="8" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5915_547)">
        <path
          d="M16.15 25.85H17.35V23.85H19.35V22.65H17.35V20.65H16.15V22.65H14.15V23.85H16.15V25.85ZM21.15 25.1H25.85V23.9H21.15V25.1ZM21.15 22.6H25.85V21.4H21.15V22.6ZM22.1 18.725L23.5 17.325L24.9 18.725L25.75 17.875L24.35 16.45L25.75 15.05L24.9 14.2L23.5 15.6L22.1 14.2L21.25 15.05L22.65 16.45L21.25 17.875L22.1 18.725ZM14.4 17.05H19.1V15.85H14.4V17.05ZM13.3 28.5C12.8 28.5 12.375 28.325 12.025 27.975C11.675 27.625 11.5 27.2 11.5 26.7V13.3C11.5 12.8 11.675 12.375 12.025 12.025C12.375 11.675 12.8 11.5 13.3 11.5H26.7C27.2 11.5 27.625 11.675 27.975 12.025C28.325 12.375 28.5 12.8 28.5 13.3V26.7C28.5 27.2 28.325 27.625 27.975 27.975C27.625 28.325 27.2 28.5 26.7 28.5H13.3ZM13.3 27H26.7C26.7667 27 26.8333 26.9667 26.9 26.9C26.9667 26.8333 27 26.7667 27 26.7V13.3C27 13.2333 26.9667 13.1667 26.9 13.1C26.8333 13.0333 26.7667 13 26.7 13H13.3C13.2333 13 13.1667 13.0333 13.1 13.1C13.0333 13.1667 13 13.2333 13 13.3V26.7C13 26.7667 13.0333 26.8333 13.1 26.9C13.1667 26.9667 13.2333 27 13.3 27Z"
          fill="#232323"
        />
      </g>
    </svg>
  );
};

export default KitchenInventoryIcon;
