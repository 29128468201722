import { UseCase } from "core/common/interfaces/useCase";
import {
  CanGetCountingByLocation,
  canGetCountingHistory,
  canGetCountingHistoryByStatus,
  canGetLastReCount,
} from "core/physicalCount/interfaces/counting/canGetCountingHistory";
import { LocationType } from "utils/general";

export class GetCountingHistoryUseCase implements UseCase {
  constructor(private readonly repository: canGetCountingHistory) {}

  public execute(kitchenId: string) {
    return this.repository.getCountingHistory(kitchenId);
  }
}

export class GetCountingHistoryByStatusUseCase implements UseCase {
  constructor(private readonly repository: canGetCountingHistoryByStatus) {}

  public execute(kitchenId: string, status: "FINISHED" | "NOT_FINISHED") {
    return this.repository.getCountingHistoryByStatus(kitchenId, status);
  }
}

export class GetLastReCount implements UseCase {
  constructor(private readonly repository: canGetLastReCount) {}

  public execute(kitchenId: string) {
    return this.repository.getLastReCount(kitchenId);
  }
}

export class CanGetCountingByLocationUseCase implements UseCase {
  constructor(private readonly repository: CanGetCountingByLocation) {}

  public execute(locationType: LocationType, location: string) {
    return this.repository.getCountingByLocation(locationType, location);
  }
}
