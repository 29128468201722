import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import { productionManagement } from "app/i18n/types";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import ProgressBar from "../../ProgressBar";

interface Props {
  scheduledQuantity: number;
  producedQuantity?: number;
}

const ProductionRealPerformance: FunctionComponent<Props> = (props) => {
  const { scheduledQuantity, producedQuantity } = props;

  const [color, setColor] = useState<string>("");
  const { t } = useTranslation();

  const getRealPerformance = (): number => {
    return producedQuantity ? (producedQuantity * 100) / scheduledQuantity : 0;
  };

  const realPerformance = getRealPerformance();
  const label = t(productionManagement.FINISH_REAL_PERFORMANCE);

  return (
    <Grid container sx={{ mb: 2 }}>
      <Grid
        item
        xs={12}
        sx={{ display: "flex", justifyContent: "center", pt: 0 }}
      >
        <Chip
          icon={<InfoOutlinedIcon sx={{ color: "white !important" }} />}
          label={`${label}: ${realPerformance.toFixed(1)}%`}
          sx={{
            backgroundColor: color,
            color: "white",
            fontWeight: "bold",
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ProgressBar
          percent={realPerformance}
          setColor={setColor}
          compact
          hidePercentLabel
        />
      </Grid>
    </Grid>
  );
};

export default ProductionRealPerformance;
