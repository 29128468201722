import CountertopsOutlinedIcon from "@mui/icons-material/CountertopsOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { FunctionComponent, useCallback, useEffect, useState } from "react";

import LinearProgress from "@mui/material/LinearProgress";
import TransferOrdersTable from "app/components/Transfers/TransferOrdersTable";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import {
  archiveLines,
  archiveTransferOrder,
  getPendingTransferOrdersByLocationId,
  updateTransferOrderLineQuantity,
} from "app/store/slices/transfer/thunks";
import { TransferOrderResponse } from "core/transfer/entities/TransferOrder";
import ArchiveLinesCheckDialog from "./ArchiveLinesCheckDialog";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigator } from "app/hooks/useNavigator";
import { transferModule } from "app/i18n/types";
import { clearExtraItem } from "app/store/slices/transfer";
import { useTranslation } from "react-i18next";
import CommonDialog from "../common/CommonDialog";
import AddItemDialog from "./AddItemDialog";

interface TransferOrdersGroupsProps {
  transferOrders: Array<TransferOrderResponse>;
  loading: boolean;
}

const TransferOrdersGroups: FunctionComponent<TransferOrdersGroupsProps> = (
  props
) => {
  const { transferOrders, loading } = props;
  const { t } = useTranslation();
  const navigator = useNavigator();
  const { kitchenId } = navigator.params();

  const dispatch = useAppDispatch();

  const archiveLinesState = useAppSelector(
    (state) => state.transfer.archiveLines
  );
  const updateLinesQuantityState = useAppSelector(
    (state) => state.transfer.linesStatus
  );
  const archiveTransferOrderState = useAppSelector(
    (state) => state.transfer.archiveTransferOrder
  );

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [openAccordion, setOpenAccordion] = useState<Array<number>>([]);
  const [checkedLines, setCheckedLines] = useState<Array<number>>([]);
  const [openArchiveLinesCheckDialog, setOpenArchiveLinesCheckDialog] =
    useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [transferOrder, setTransferOrder] =
    useState<TransferOrderResponse | null>(null);
  const [openAddItemDialog, setOpenAddItemDialog] = useState<boolean>(false);

  const toggleAccordion = useCallback(
    (accordionId: number) => {
      if (openAccordion.includes(accordionId)) {
        setOpenAccordion((open) =>
          open.filter((accordion) => accordion !== accordionId)
        );
      } else {
        setOpenAccordion((open) => [...open, accordionId]);
      }
    },
    [openAccordion]
  );

  const toggleChecked = useCallback(
    (lineId: number) => {
      if (checkedLines.includes(lineId)) {
        setCheckedLines((prevState) =>
          prevState.filter((prevSelected) => prevSelected !== lineId)
        );

        return;
      }

      setCheckedLines((prevState) => [...prevState, lineId]);
    },
    [checkedLines]
  );

  const toggleSelectAll = useCallback(
    (lineIds: Array<number>) => {
      if (lineIds.every((lineId) => checkedLines.includes(lineId))) {
        setCheckedLines((current) =>
          current.filter((lineId) => !lineIds.includes(lineId))
        );
      } else if (lineIds.some((lineId) => checkedLines.includes(lineId))) {
        setCheckedLines((current) => {
          const filtered = current.filter(
            (lineId) => !lineIds.includes(lineId)
          );

          return [...filtered, ...lineIds];
        });
      } else {
        lineIds.forEach(toggleChecked);
      }
    },
    [checkedLines, toggleChecked]
  );

  const handleArchiveLines = useCallback(() => {
    dispatch(
      archiveLines({
        lineIds: checkedLines,
        userId: "",
      })
    );
    setCheckedLines([]);
    setOpenArchiveLinesCheckDialog(false);
  }, [checkedLines, dispatch]);

  const handleArchiveTransferOrder = useCallback(() => {
    if (transferOrder) {
      dispatch(
        archiveTransferOrder({
          transferOrderId: transferOrder.id,
        })
      );
    }
  }, [dispatch, transferOrder]);

  const handleUpdateLineQuantity = useCallback(
    (lineId: number, quantity: number) => {
      if (quantity !== null && quantity !== undefined && !isNaN(quantity)) {
        dispatch(
          updateTransferOrderLineQuantity({
            transferLineId: lineId,
            updateQuantityAndReason: {
              quantity,
              transferOrderStep: "REVIEW",
              sku: "",
            },
          })
        );
      }
    },
    [dispatch]
  );

  const openDelete = (order: TransferOrderResponse) => {
    setTitle(order.destinationKitchenName);
    setMessage(
      `${t(transferModule.DELETE_TRANSFER_CONFIRM)} ${
        order.destinationKitchenName
      }`
    );
    setTransferOrder(order);
    setOpenDeleteDialog(true);
  };

  const openAddItem = (order: TransferOrderResponse) => {
    dispatch(clearExtraItem());
    setTransferOrder(order);
    setOpenAddItemDialog(true);
  };

  const onCloseAddExtraItems = (updateItems: boolean) => {
    setOpenAddItemDialog(false);
    if (kitchenId && updateItems) {
      dispatch(getPendingTransferOrdersByLocationId(kitchenId));
    }
  };

  useEffect(() => {
    if (transferOrders.length === 1) {
      setOpenAccordion([transferOrders[0].id]);
    }
  }, [transferOrders]);

  useEffect(() => {
    setOpenDeleteDialog(false);
    if (archiveTransferOrderState.status === "succeeded" && kitchenId) {
      dispatch(getPendingTransferOrdersByLocationId(kitchenId));
    }
  }, [archiveTransferOrderState, dispatch, kitchenId]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box
          bgcolor="white"
          borderRadius={2}
          p={0.5}
          pr={checkedLines.length > 0 ? 2 : 0.5}
          display="flex"
          gap={1}
          alignItems="center"
        >
          <IconButton
            disabled={checkedLines.length === 0}
            color="error"
            onClick={() => setOpenArchiveLinesCheckDialog(true)}
          >
            <DeleteOutlinedIcon />
          </IconButton>

          {checkedLines.length > 0 && (
            <Typography color="text.secondary">
              {checkedLines.length} seleccionados
            </Typography>
          )}
        </Box>

        <Paper>
          <InputBase
            placeholder="Buscar insumo"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            endAdornment={<SearchOutlinedIcon />}
            sx={{
              py: 1,
              px: 2,
            }}
          />
        </Paper>
      </Box>

      {(loading || archiveLinesState.status === "loading") && (
        <Box my={2}>
          <LinearProgress />
        </Box>
      )}

      {transferOrders.map((order) => (
        <Box key={order.id} mt={2} mb={1}>
          <Box
            sx={{
              bgcolor: "#F1EFEF",
              py: 1,
              px: 2,
              display: "flex",
              gap: 2,
              cursor: "pointer",
            }}
            onClick={() => toggleAccordion(order.id)}
          >
            <CountertopsOutlinedIcon />
            <Box sx={{ flexGrow: 1, alignItems: "flex-start" }}>
              <Typography fontWeight={500}>
                {`${order.destinationKitchenName} - (${order.lines.length} INSUMOS)`}
              </Typography>
            </Box>

            <Box>
              {!openAccordion.includes(order.id) && <ExpandMoreIcon />}
              {openAccordion.includes(order.id) && <ExpandLessIcon />}
            </Box>
          </Box>

          {(openAccordion.includes(order.id) || searchTerm !== "") && (
            <Box sx={{ pt: 2, pb: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  sx={{ mr: 2 }}
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    openAddItem(order);
                  }}
                >
                  <AddOutlinedIcon />
                  <Typography>Agregar Items</Typography>
                </Button>
                <Button
                  color="error"
                  variant="outlined"
                  onClick={() => openDelete(order)}
                  startIcon={<DeleteOutlinedIcon />}
                >
                  {t(transferModule.DELETE_TRANSFER)}
                </Button>
              </Box>

              <TransferOrdersTable
                lines={order.lines}
                checkedLines={checkedLines}
                toggleSelectAll={toggleSelectAll}
                toggleChecked={toggleChecked}
                disabled={archiveLinesState.status === "loading"}
                onUpdateLineQuantity={handleUpdateLineQuantity}
                linesStatus={updateLinesQuantityState}
                searchTerm={searchTerm}
              />
            </Box>
          )}
        </Box>
      ))}

      <ArchiveLinesCheckDialog
        onClose={() => setOpenArchiveLinesCheckDialog(false)}
        onContinue={handleArchiveLines}
        open={openArchiveLinesCheckDialog}
        selectedLines={checkedLines.length}
      />

      <CommonDialog
        handleClose={() => setOpenDeleteDialog(false)}
        handleConfirm={handleArchiveTransferOrder}
        open={openDeleteDialog}
        title={title}
        message={message}
        icon={<DeleteOutlinedIcon color="error" sx={{ fontSize: 96 }} />}
        showCancelButton={true}
      />

      {transferOrder && (
        <AddItemDialog
          lines={transferOrder?.lines ?? null}
          open={openAddItemDialog}
          kitchenId={kitchenId || ""}
          handleClose={onCloseAddExtraItems}
          transferOrderId={transferOrder.id}
        />
      )}
    </>
  );
};

export default TransferOrdersGroups;
