import { UseCase } from "core/common/interfaces/useCase";
import { CanUpdateScheduledCounting } from "../../interfaces/counting/canStartScheduledCounting";

export class UpdateScheduledCountingUseCase implements UseCase {
  constructor(private readonly repository: CanUpdateScheduledCounting) {}

  public execute(countingId: string, userId: string, kitchenId: string) {
    return this.repository.UpdateScheduledCounting(
      countingId,
      userId,
      kitchenId
    );
  }
}
