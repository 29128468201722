import {
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSEmptyState,
  KOSRowData,
} from "@foodology-co/alejandria";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Chip, IconButton } from "@mui/material";
import { commons, vendor } from "app/i18n/types";
import { VendorJoinWithSupply } from "core/vendors/entities/Vendor";
import { VendorSupply } from "core/vendors/entities/VendorSupply";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { formatPrice, localeData } from "utils/currency";
import { getVendorCategoryColor } from "utils/general";
import { Actions, VendorSupplyModal } from "utils/modal";
import { numberFormat } from "utils/number";

interface Props {
  vendors: VendorJoinWithSupply[];
  loading?: boolean;
  setModal: (modal: VendorSupplyModal) => void;
}

const VendorBySkuAndCityTable: FunctionComponent<Props> = (props) => {
  const { vendors, loading, setModal } = props;

  const { t } = useTranslation();

  const getHeader = (): KOSBaseTableHeader[] => [
    {
      label: t(commons.CODE),
      align: "center",
      text: (item: KOSRowData) => item.vendor.externalCode,
    },
    {
      label: t(commons.SUPPLIER),
      text: (item: KOSRowData) => item.vendor.name,
    },
    {
      label: t(vendor.VENDOR_SUPPLY_NAME),
      align: "center",
      text: (item: KOSRowData) => item.supply.vendorSupplyName,
    },
    {
      label: t(vendor.VENDOR_UNIT),
      align: "center",
      text: (item: KOSRowData) => {
        const vendorUnit = item.supply.vendorUnit as string;
        if (!vendorUnit.trim()) return undefined;
        return t(`vendorMeasureUnit.${vendorUnit.trim()}`);
      },
    },
    {
      label: t(vendor.SUPPLY_QUANTITY),
      align: "right",
      text: (item: KOSRowData) => {
        const supplyQuantity = numberFormat(
          item.supply.supplyQuantity,
          localeData[item.vendor.country]
        );
        return `${supplyQuantity} ${item.supply.supplyUnit}`;
      },
    },
    {
      label: t(vendor.VENDOR_UNIT_PRICE),
      align: "right",
      text: (item: KOSRowData) =>
        formatPrice(
          item.supply.vendorUnitPrice,
          localeData[item.vendor.country]
        ),
    },
    {
      label: t(vendor.SUPPLY_UNIT),
      align: "center",
      text: (item: KOSRowData) => item.supply.supplyUnit,
    },

    {
      label: t(vendor.SUPPLY_UNIT_PRICE),
      align: "right",
      text: (item: KOSRowData) =>
        formatPrice(
          item.supply.supplyUnitPrice,
          localeData[item.vendor.country],
          5
        ),
    },
    {
      label: t(vendor.CATEGORY),
      align: "center",
      component: (item: KOSRowData) => {
        const type = item.supply.vendorType.toUpperCase();
        return (
          <Chip
            label={`${t(commons.TYPE)} ${type}`}
            color={getVendorCategoryColor(type)}
          />
        );
      },
    },
    {
      label: t(commons.ACTIONS),
      align: "center",
      component: (item: KOSRowData) => {
        return (
          <IconButton
            onClick={() =>
              setModal({
                open: true,
                action: Actions.edit,
                item: item.supply as VendorSupply,
              })
            }
            color="warning"
          >
            <EditOutlinedIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <KOSBaseTable
      columns={getHeader()}
      rows={{
        data: vendors,
        total: vendors.length,
        loading: !!loading,
      }}
      pagination={{ enable: true }}
      emptyState={
        <KOSEmptyState
          icon={InfoOutlinedIcon}
          message={t(commons.TABLE_EMPTY)}
        />
      }
    />
  );
};

export default VendorBySkuAndCityTable;
