/* eslint-disable react/require-default-props */
import React from "react";

interface IconProp {
  fontSize?: string;
}

const TransferOrderIcon = (props: IconProp): React.ReactElement => {
  const { fontSize } = props;
  return (
    <svg
      width={fontSize ?? 40}
      height={fontSize ?? 40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width={fontSize ?? 40}
        height={fontSize ?? 40}
        rx="20"
        fill="#F1EFEF"
      />
      <mask
        id="mask0_5943_325"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="24"
        height="24"
      >
        <rect x="8" y="8" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5943_325)">
        <path
          d="M13.8496 27.55C13.0663 27.55 12.4036 27.2793 11.8616 26.738C11.3203 26.196 11.0496 25.5333 11.0496 24.75H9.34961V14.3C9.34961 13.8 9.52461 13.375 9.87461 13.025C10.2246 12.675 10.6496 12.5 11.1496 12.5H24.7746V16.3H27.4246L30.6496 20.625V24.75H28.8496C28.8496 25.5333 28.5746 26.196 28.0246 26.738C27.4746 27.2793 26.8079 27.55 26.0246 27.55C25.2579 27.55 24.5996 27.2793 24.0496 26.738C23.4996 26.196 23.2246 25.5333 23.2246 24.75H16.6496C16.6496 25.5333 16.3789 26.196 15.8376 26.738C15.2956 27.2793 14.6329 27.55 13.8496 27.55ZM13.8496 26.05C14.2163 26.05 14.5246 25.925 14.7746 25.675C15.0246 25.425 15.1496 25.1167 15.1496 24.75C15.1496 24.3833 15.0246 24.075 14.7746 23.825C14.5246 23.575 14.2163 23.45 13.8496 23.45C13.4829 23.45 13.1746 23.575 12.9246 23.825C12.6746 24.075 12.5496 24.3833 12.5496 24.75C12.5496 25.1167 12.6746 25.425 12.9246 25.675C13.1746 25.925 13.4829 26.05 13.8496 26.05ZM10.8496 23.25H11.5746C11.7913 22.8833 12.0996 22.575 12.4996 22.325C12.8996 22.075 13.3496 21.95 13.8496 21.95C14.3329 21.95 14.7789 22.0707 15.1876 22.312C15.5956 22.554 15.9079 22.8667 16.1246 23.25H23.2746V14H11.1496C11.0829 14 11.0163 14.0333 10.9496 14.1C10.8829 14.1667 10.8496 14.2333 10.8496 14.3V23.25ZM26.0496 26.05C26.4163 26.05 26.7246 25.925 26.9746 25.675C27.2246 25.425 27.3496 25.1167 27.3496 24.75C27.3496 24.3833 27.2246 24.075 26.9746 23.825C26.7246 23.575 26.4163 23.45 26.0496 23.45C25.6829 23.45 25.3706 23.575 25.1126 23.825C24.8539 24.075 24.7246 24.3833 24.7246 24.75C24.7246 25.1167 24.8539 25.425 25.1126 25.675C25.3706 25.925 25.6829 26.05 26.0496 26.05ZM24.7746 21.25H29.2496L26.6496 17.8H24.7746V21.25Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  );
};

export default TransferOrderIcon;
