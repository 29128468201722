import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import QrCodeScannerOutlinedIcon from "@mui/icons-material/QrCodeScannerOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import { Button, Checkbox, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { commons, productionManagement } from "app/i18n/types";
import { convertDate } from "app/utils/dateUtils";
import {
  ProductionLabelResponse,
  ProductionLabelgroup,
} from "core/productions/entities/Productions";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ProductionLabelsDialog from "../Dialog";

interface Props {
  labelGroup: ProductionLabelgroup;
  labelGroupKey: string;
  total: number;
  openGroup: string;
  toggleOpenGroup: (key: string) => void;
  onlyView?: boolean;
}

const ProductionLabelHistoryItem: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const {
    labelGroup,
    labelGroupKey,
    total,
    openGroup,
    toggleOpenGroup,
    onlyView,
  } = props;
  const labels = labelGroup[labelGroupKey];

  const [selected, setSelected] = useState<number[]>([]);
  const [showLabels, setShowLabels] = useState<boolean>(false);
  const [labelsToPrint, setLabelsToPrint] = useState<ProductionLabelResponse[]>(
    []
  );

  const toggleSelected = useCallback(
    (id: number) => {
      setSelected(
        selected.includes(id)
          ? selected.filter((el) => el !== id)
          : [...selected, id]
      );
    },
    [selected]
  );

  const sxHeader = {
    onClick: () => toggleOpenGroup(labelGroupKey),
    sx: { cursor: "pointer" },
  };

  useEffect(() => {
    setLabelsToPrint(labels.filter((label) => selected.includes(label.id)));
  }, [selected]);

  useEffect(() => {
    setSelected([]);
  }, [openGroup]);

  return (
    <Box key={labelGroupKey} mb={1}>
      <Box
        sx={{
          border: "solid #D7D3D3",
          borderRadius: 1,
          p: 1,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} {...sxHeader}>
            <Typography
              variant="h6"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <ReceiptLongOutlinedIcon />
              {t(productionManagement.LABELS_OF, {
                quantity: labels[0].quantity,
                unit: labels[0].measureUnit,
              })}
            </Typography>
          </Grid>
          <Grid item xs={3} {...sxHeader}>
            <Typography
              variant="h6"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <CalendarMonthOutlinedIcon />
              {convertDate(labelGroupKey, "dd/MM/YYY")}
            </Typography>
          </Grid>
          <Grid item xs={3} {...sxHeader}>
            <Typography
              variant="h6"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <AccessTimeOutlinedIcon />
              {convertDate(labelGroupKey, "HH:mm:ss")}
            </Typography>
          </Grid>
          <Grid item xs={5} {...sxHeader}>
            <Typography
              variant="h6"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <QrCodeScannerOutlinedIcon />
              {labels.length} {t(productionManagement.LABELS_GENERATED)}
            </Typography>
          </Grid>
          <Grid item xs={1} {...sxHeader}>
            {openGroup === labelGroupKey ? (
              <ExpandMoreIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </Grid>
          {openGroup === labelGroupKey && (
            <>
              {labels.map((label) => (
                <Grid item xs={4} key={label.id}>
                  <Checkbox onClick={() => toggleSelected(label.id)} />
                  {t(productionManagement.LABEL)}: {label.batch.split("|")[2]}{" "}
                  de {total}
                </Grid>
              ))}
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  onClick={() => setShowLabels(true)}
                  startIcon={<LocalPrintshopOutlinedIcon />}
                  disabled={!selected.length}
                  fullWidth
                >
                  {t(
                    onlyView
                      ? commons.VIEW_LABELS
                      : productionManagement.REPRINT_LABELS
                  )}
                </Button>
              </Grid>
              <ProductionLabelsDialog
                open={showLabels}
                onClose={() => setShowLabels(false)}
                labels={labelsToPrint}
                total={total}
                onlyView={onlyView}
              />
            </>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default ProductionLabelHistoryItem;
