import { ManagerDialog } from "app/components/common/Manager/Dialog";
import { transferModule } from "app/i18n/types";
import { BaseDialogProps } from "./constants";
import { HandleForm } from "./form";

export const TransferAuthorizeConfirmDialog = (props: BaseDialogProps) => {
  const { onClose } = props;

  return (
    <ManagerDialog
      title={transferModule.CONFIRM}
      size="xs"
      onClose={onClose}
      content={<HandleForm {...props} />}
    />
  );
};
