import TextField from "@mui/material/TextField";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { commons, transferOrdersPackedReviewTable } from "app/i18n/types";
import { updatePackedQuantity } from "app/store/slices/productions/Supply/thunks";
import { ProductionSupplyDetail } from "core/productions/entities/Supply";
import {
  FocusEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

interface Props {
  item: ProductionSupplyDetail;
  setPackedQuantity?: (id: number, packedQuantity: number) => void;
  onlyPlaceholder?: boolean;
}

const UpdatePackedQuantityTextField: FunctionComponent<Props> = (props) => {
  const { item, setPackedQuantity, onlyPlaceholder } = props;
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [quantity, setQuantity] = useState<string>();
  const [validation, setValidation] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const save = (lineId: number, quantityReceived: number) => {
    if (setPackedQuantity) {
      setPackedQuantity(lineId, quantityReceived);
    }
  };

  const updateItemQuantity = useCallback(
    (event: FocusEvent<HTMLInputElement>, element: ProductionSupplyDetail) => {
      const packedQuantity = Number(event.target.value);

      if (packedQuantity <= 0) {
        setValidation(t(commons.QUANTITY_ERROR));
        return;
      }

      if (packedQuantity === element.packedQuantity && packedQuantity > 0) {
        return;
      }

      setLoading(true);

      dispatch(
        updatePackedQuantity({
          itemId: element.id,
          packedQuantity,
        })
      );

      save(element.id, packedQuantity);

      setValidation("");
      setLoading(false);
    },
    []
  );

  const setQuantityNumber = useCallback((quantity: string) => {
    if (quantity === "" || isNaN(Number(quantity))) {
      setQuantity(undefined);
      return;
    }
    setQuantity(Number(quantity).toString());
  }, []);

  useEffect(() => {
    setQuantity(item.packedQuantity.toString());
  }, [item.packedQuantity]);

  const title = t(transferOrdersPackedReviewTable.PACKED_QUANTITY);

  return (
    <TextField
      id={`quantity-${item.sku}`}
      name="value"
      onChange={(e) => setQuantityNumber(e.target.value)}
      value={quantity || ""}
      size="small"
      label={onlyPlaceholder ? undefined : title}
      placeholder={title}
      InputProps={{
        required: true,
        type: "number",
      }}
      error={!!validation}
      helperText={validation}
      onBlur={(event: FocusEvent<HTMLInputElement>) =>
        updateItemQuantity(event, item)
      }
      sx={{ width: 150 }}
      disabled={loading}
      focused
      autoComplete="off"
    />
  );
};

export default UpdatePackedQuantityTextField;
