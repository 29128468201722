import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { ManagerDialog } from "app/components/common/Manager/Dialog";
import { ManagerDialogFooter } from "app/components/common/Manager/Dialog/Footer";
import { commons, purchaseOrderCreation } from "app/i18n/types";
import { PurchaseOrder } from "core/purchaseOrders/entities/PurchaseOrder";
import { releasePurchaseOrders } from "core/purchaseOrders/repositories/http/purchase";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  open: boolean;
  onClose: () => void;
  purchaseOrders: PurchaseOrder[];
  checkedPurchaseOrders: number[];
  onSuccess: (removes: number[]) => void;
}

const CreatePurchaseOrderCheckedDialog = (props: Props) => {
  const { open, onClose, purchaseOrders, checkedPurchaseOrders, onSuccess } =
    props;

  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const handleConfirmSelectedLines = useCallback(async () => {
    setLoading(true);
    const response = await releasePurchaseOrders(
      checkedPurchaseOrders.map((purchaseOrderId) => ({ purchaseOrderId }))
    );
    setLoading(false);
    if (response.ok) {
      onSuccess(checkedPurchaseOrders);
    }
  }, [checkedPurchaseOrders]);

  return (
    <>
      {open && (
        <ManagerDialog
          title={purchaseOrderCreation.CONFIRM_MULTIPLE_MODAL_TITLE}
          onClose={onClose}
          content={
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>
                  {t(purchaseOrderCreation.CONFIRM_MULTIPLE_MODAL_SUBTITLE)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <List>
                  {checkedPurchaseOrders.map((purchaseOrderId) => {
                    const purchaseOrder = purchaseOrders.find(
                      (purchaseOrder) => purchaseOrder.id === purchaseOrderId
                    );
                    return (
                      <Typography
                        key={purchaseOrder?.id}
                        variant="body1"
                        fontWeight="bold"
                      >
                        {`${purchaseOrder?.vendorName} - ${purchaseOrder?.locationCode}`}
                      </Typography>
                    );
                  })}
                </List>
              </Grid>
              <Grid item xs={12}>
                <ManagerDialogFooter
                  mainButton={{
                    children: t(commons.CONFIRMATION),
                    onClick: handleConfirmSelectedLines,
                  }}
                  onCancel={onClose}
                  loading={loading}
                />
              </Grid>
            </Grid>
          }
        />
      )}
    </>
  );
};

export default CreatePurchaseOrderCheckedDialog;
