import { Group } from "core/physicalCount/entities/Group";

export const getCompletedForGroup = (group: Group): number => {
  const completedItems = group.groupItems.filter(
    (item) => item.state.completed === true
  ).length;

  const totalItems = group.groupItems.length;

  return getCompletedPercent(completedItems, totalItems);
};

export const getCompletedPercent = (
  completed: number,
  total: number
): number => {
  return Math.round((completed / total) * 100);
};
