/* eslint-disable react/require-default-props */
import React from "react";

interface IconProp {
  fontSize?: string;
}

const MassiveAdjustsIcon = (props: IconProp): React.ReactElement => {
  const { fontSize } = props;
  return (
    <svg
      width={fontSize ?? 40}
      height={fontSize ?? 40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width={fontSize ?? 40}
        height={fontSize ?? 40}
        rx="20"
        fill="#F1EFEF"
      />
      <mask
        id="mask0_5932_33"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="24"
        height="24"
      >
        <rect x="8" y="8" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5932_33)">
        <path
          d="M18 25H22V23H18V25ZM18 21H25V19H18V21ZM15 17H25V15H15V17ZM14.625 30C13.3083 30 12.1875 29.5375 11.2625 28.6125C10.3375 27.6875 9.875 26.5667 9.875 25.25C9.875 24.3 10.1208 23.45 10.6125 22.7C11.1042 21.95 11.75 21.3833 12.55 21H10V19H16V25H14V22.575C13.3833 22.7083 12.875 23.025 12.475 23.525C12.075 24.025 11.875 24.6 11.875 25.25C11.875 26.0167 12.1458 26.6667 12.6875 27.2C13.2292 27.7333 13.875 28 14.625 28V30ZM18 29V27H27V13H13V17H11V13C11 12.45 11.1958 11.9792 11.5875 11.5875C11.9792 11.1958 12.45 11 13 11H27C27.55 11 28.0208 11.1958 28.4125 11.5875C28.8042 11.9792 29 12.45 29 13V27C29 27.55 28.8042 28.0208 28.4125 28.4125C28.0208 28.8042 27.55 29 27 29H18Z"
          fill="#232323"
        />
      </g>
    </svg>
  );
};

export default MassiveAdjustsIcon;
