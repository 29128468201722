import { StoreOutlined } from "@mui/icons-material";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ContentPasteOutlinedIcon from "@mui/icons-material/ContentPasteOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import TagOutlinedIcon from "@mui/icons-material/TagOutlined";
import { Box, IconButton, Paper, Typography } from "@mui/material";
import TextField from "app/components/common/Field/Text";
import PurchaseOrderStatusChip from "app/components/PurchaseOrder/Status/Chip";
import PurchaseOrderStatusSyncronized from "app/components/PurchaseOrder/Status/Syncronized";
import { VendorManagerDialog } from "app/components/Vendor/Manager";
import {
  commons,
  purchaseOrderCreation,
  purchaseOrderModule,
  transferPackingDialog,
} from "app/i18n/types";
import {
  OriginType,
  Purchase,
} from "core/purchaseOrders/entities/PurchaseOrder";
import { updateInvoiceNroOfReceipt } from "core/purchaseOrders/repositories/http/purchase";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatPrice, localeData } from "utils/currency";
import { Actions, VendorModal } from "utils/modal";
import { finishedStatuses, unreceivedStatuses } from "utils/purchase";

interface Props {
  order: Purchase;
  isConciliation?: boolean;
  showReceiptNro?: boolean;
  isModify?: boolean;
  reload: () => void;
}

const flexStyleBox = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 1,
};

const PurchaseOrderLinePrincipal = (props: Props) => {
  const { t } = useTranslation();
  const { order, isConciliation, showReceiptNro, isModify, reload } = props;

  const [modal, setModal] = useState<VendorModal>({ open: false });
  const [lastInvoiceNo, setLastInvoiceNo] = useState<string>(order.invoiceNo);
  const [invoiceNo, setInvoiceNo] = useState<string>(order.invoiceNo);

  const lines = order.lines ?? [];
  const quantity = lines.reduce((sum, line) => sum + line.quantity, 0);
  const quantityReceive = lines.reduce(
    (sum, line) => sum + line.quantityReceive,
    0
  );
  const percentage = quantity > 0 ? (quantityReceive * 100) / quantity : 0;

  const isReceived = !unreceivedStatuses.includes(order.status);
  const isActive = !finishedStatuses.includes(order.status);

  const total = lines.reduce((sum, line) => {
    const quantity = isReceived ? line.quantityReceive : line.quantity;
    return sum + quantity * line.unitCost;
  }, 0);

  useEffect(() => {
    if (!invoiceNo) return;
    if (isModify) return;
    if (lastInvoiceNo === invoiceNo) return;
    updateInvoiceNroOfReceipt(Number(order.orderId), invoiceNo).then(
      (response) => {
        if (response.ok) {
          const value = response.data ?? "";
          setLastInvoiceNo(value);
          setInvoiceNo(value);
        }
      }
    );
  }, [isModify]);

  return (
    <Paper sx={{ display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">{order?.vendorName ?? ""}</Typography>
          <IconButton
            onClick={() => {
              setModal({
                open: true,
                action: Actions.view,
                itemId: Number(order.vendorId),
              });
            }}
          >
            <InfoOutlinedIcon color="info" />
          </IconButton>
          {modal.open && (
            <VendorManagerDialog
              {...modal}
              country={order.country}
              onClose={() => setModal({ open: false })}
              onSuccess={() => {}}
            />
          )}
        </Box>
        <Box>
          <PurchaseOrderStatusSyncronized
            purchase={order}
            reloadInvoice={reload}
          />
          <PurchaseOrderStatusChip purchase={order} />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          mb: 1,
          gap: 1,
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Box sx={flexStyleBox}>
          <TagOutlinedIcon />
          <Box>
            <Typography>{t(commons.ORDER_ID)}</Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              {order.origin === OriginType.KIS ? order?.code : order?.orderId}
            </Typography>
          </Box>
        </Box>
        <Box sx={flexStyleBox}>
          <StoreOutlined />
          <Box>
            <Typography>{t(commons.KITCHEN)}</Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              {order.kitchenId}
            </Typography>
          </Box>
        </Box>
        <Box sx={flexStyleBox}>
          <CalendarMonthOutlinedIcon />
          <Box>
            <Typography>{t(purchaseOrderModule.REQUEST_DATE)}</Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              {order.dateDocument.toString()}
            </Typography>
          </Box>
        </Box>
        <Box sx={flexStyleBox}>
          <CalendarMonthOutlinedIcon />
          <Box>
            <Typography>
              {t(purchaseOrderCreation.ESTIMATED_DATE_LABEL)}
            </Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              {order.expectedReceiptAt?.toString()}
            </Typography>
          </Box>
        </Box>

        {(isConciliation || showReceiptNro) && (
          <Box sx={flexStyleBox}>
            <InsertDriveFileOutlinedIcon />
            <Box>
              <Typography>
                {t(purchaseOrderCreation.CREATE_ORDER_MODAL_INVOICE_LABEL)}
              </Typography>
              {isReceived && isActive && isModify ? (
                <TextField
                  value={invoiceNo}
                  onChangeValue={setInvoiceNo}
                  size="small"
                  placeholder="---"
                  instantUpdate
                />
              ) : (
                <Typography sx={{ fontWeight: "bold" }}>
                  {invoiceNo || "---"}
                </Typography>
              )}
            </Box>
          </Box>
        )}

        <Box sx={flexStyleBox}>
          <PaymentsOutlinedIcon />
          <Box>
            <Typography>{t(commons.TOTAL_PRICE)}</Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              {formatPrice(total, localeData[order.country])}
            </Typography>
          </Box>
        </Box>

        <Box sx={flexStyleBox}>
          <ContentPasteOutlinedIcon />
          <Box>
            <Typography>
              {t(
                isConciliation
                  ? transferPackingDialog.SERVICE_LEVEL
                  : purchaseOrderModule.PROGRESS
              )}
            </Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              {percentage.toFixed(0)}%
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default PurchaseOrderLinePrincipal;
