import { Row } from "read-excel-file";
import { replaceCommasWithPeriods } from "utils/general";

export const isErrorToRequiredColumns = (row: Row, requiredColumns: number) => {
  let error = false;
  Array.from(Array(requiredColumns)).forEach((_, index) => {
    error = error || !row.at(index);
  });
  return error;
};

export const rowToStringOrEmpty = (
  row: Row,
  index: number
): string | undefined => {
  return row.at(index)?.toString();
};

export const rowToString = (row: Row, index: number): string => {
  return rowToStringOrEmpty(row, index) ?? "";
};

export const rowToNumberOrEmpty = (
  row: Row,
  index: number
): number | undefined => {
  const value = row.at(index);
  if (!value) return undefined;
  const result = Number(replaceCommasWithPeriods(value.toString()));
  if (isNaN(result)) return undefined;
  return result;
};

export const rowToNumber = (row: Row, index: number): number => {
  return rowToNumberOrEmpty(row, index) ?? 0;
};
