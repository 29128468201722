import { KOSEmptyState } from "@foodology-co/alejandria";
import CalculateIcon from "@mui/icons-material/CalculateOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import LinearProgress from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import PageHeader from "app/components/PageHeader/PageHeader";
import ProductionCalculator from "app/components/Productions/Calculator";
import ProductionCatalogTable from "app/components/Productions/ProductionCatalogTable";
import ProductionSelector from "app/components/Productions/Selector";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { useNavigator } from "app/hooks/useNavigator";
import {
  clearCreateProduction,
  setSelectedProduction,
} from "app/store/slices/productions";
import {
  createProduction,
  getProductionInventory,
} from "app/store/slices/productions/thunks";
import {
  RemoteConfigKey,
  getRemoteValueBoolean,
} from "core/common/utils/remoteConfig";
import { ProductionCatalog } from "core/productions/entities/Catalog";
import { ProductionPayload } from "core/productions/entities/Productions";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageBox } from "utils/generalUI";
import {
  commons,
  kitchenInventoryManagement,
  productionManagement,
  purchaseOrderModule,
} from "../../i18n/types";

interface ProductionCreateProps {}

const ProductionCreate: FunctionComponent<ProductionCreateProps> = () => {
  const navigator = useNavigator();
  let { kitchenId } = navigator.params();

  const dispatch = useAppDispatch();
  const productionsState = useAppSelector((state) => state.productions.status);
  const { t } = useTranslation();

  const showInventoryOnNewProductions = getRemoteValueBoolean(
    RemoteConfigKey.SHOW_INVENTORY_ON_NEW_PRODUCTIONS
  );

  const selectedProduction = useAppSelector(
    (state) => state.productions.selectedProduction
  );
  const createProductionStatus = useAppSelector(
    (state) => state.productions.createProduction.status
  );

  const [quantityToProduce, setQuantityToProduce] = useState<number | null>(
    null
  );

  const [openCalculator, setOpenCalculator] = useState<boolean>(false);

  const onSelectProductionCatalog = useCallback(
    (newValue: ProductionCatalog | null) => {
      if (kitchenId !== undefined && newValue !== null) {
        dispatch(getProductionInventory({ kitchenId, sku: newValue.sku }));
      }
    },
    []
  );

  const onSetQuantityToProduce = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const quantity =
        event.target.value === "" ? null : Number(event.target.value);
      setQuantityToProduce(quantity);
    },
    []
  );

  const startProduction = useCallback(() => {
    try {
      if (kitchenId) {
        if (selectedProduction !== null && kitchenId !== undefined) {
          const newProduction: ProductionPayload = {
            sku: selectedProduction.sku,
            name: selectedProduction.name,
            quantityToProduce: quantityToProduce ?? 0,
            locationId: kitchenId,
            unit: selectedProduction.unit,
            userId: "",
            productionDetails: selectedProduction.ingredients.map((supply) => ({
              sku: supply.sku,
              name: supply.name,
              quantity: supply.quantity,
              requiredQuantity: Math.ceil(
                (quantityToProduce ?? 1) * supply.quantity
              ),
              unit: supply.unit,
              displayUnit: supply.displayUnit ?? supply.unit,
              userId: "",
            })),
          };

          dispatch(createProduction({ production: newProduction }));
        }
      }
    } catch (e) {
      throw e;
    }
  }, [kitchenId, quantityToProduce, selectedProduction]);

  useEffect(() => {
    if (createProductionStatus === "succeeded") {
      dispatch(setSelectedProduction(null));
      dispatch(clearCreateProduction(null));
      navigator.toByLocationType(`/productions/${kitchenId}`);
    }
  }, [createProductionStatus]);

  const handleClose = () => {
    setOpenCalculator(false);
  };

  const handleConfirm = (estimatedQuantityParam: number) => {
    setQuantityToProduce(estimatedQuantityParam);
    setOpenCalculator(false);
  };

  return (
    <PageBox>
      <PageHeader
        title={t(kitchenInventoryManagement.PRODUCT_INVENTORY_TITLE)}
        subtitle={t(kitchenInventoryManagement.PRODUCT_INVENTORY_DESCRIPTION)}
        rightArea={[
          {
            children: t(productionManagement.START_PRODUCTION),
            variant: "contained",
            size: "large",
            startIcon: <TimerOutlinedIcon />,
            onClick: startProduction,
            disabled:
              quantityToProduce === null ||
              quantityToProduce === 0 ||
              createProductionStatus === "loading",
          },
        ]}
      />

      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={8}>
          <Box
            p={2}
            borderRadius={2}
            bgcolor="white"
            display="flex"
            gap={2}
            justifyContent="space-between"
            alignItems="center"
            border="1px solid #D7D3D3"
            height="100%"
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography
                  color="text.secondary"
                  fontWeight={300}
                  gutterBottom
                >
                  {t(purchaseOrderModule.FIND_ITEM)}
                </Typography>
                <ProductionSelector
                  kitchenId={kitchenId ?? ""}
                  selectedProduction={selectedProduction}
                  setSelectedProduction={onSelectProductionCatalog}
                  size="small"
                />
              </Grid>
              {showInventoryOnNewProductions && (
                <Grid item xs={3}>
                  <Typography
                    color="text.secondary"
                    fontWeight={300}
                    textAlign="center"
                    gutterBottom
                  >
                    {t(commons.EXISTENCE)}
                  </Typography>
                  <Typography
                    color="text.secondary"
                    textAlign="center"
                    fontWeight={500}
                    gutterBottom
                  >
                    {selectedProduction?.inventory ?? "-"}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={3}>
                <Typography
                  color="text.secondary"
                  fontWeight={300}
                  textAlign="center"
                  gutterBottom
                >
                  {t(commons.UNITS)}
                </Typography>
                <Typography
                  color="text.secondary"
                  textAlign="center"
                  fontWeight={500}
                  gutterBottom
                >
                  {selectedProduction?.unit ?? "UNI"}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={3}>
          <Box
            px={2}
            py={2}
            borderRadius={2}
            bgcolor="white"
            display="flex"
            gap={2}
            justifyContent="space-between"
            alignItems="center"
            border="1px solid #D7D3D3"
            height="100%"
          >
            <TextField
              label={t(commons.PRODUCTION_QUANTITY)}
              value={quantityToProduce ?? ""}
              onChange={onSetQuantityToProduce}
              InputProps={{
                type: "number",
                endAdornment: (
                  <InputAdornment position="start">
                    {selectedProduction?.unit ?? "UNI"}
                  </InputAdornment>
                ),
              }}
              fullWidth={true}
              color="warning"
              disabled={
                selectedProduction === null ||
                selectedProduction.ingredients === null
              }
              focused
              autoComplete="off"
            />
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box
            px={2}
            py={2}
            borderRadius={2}
            bgcolor="white"
            display="flex"
            gap={2}
            justifyContent="space-between"
            textAlign="center"
            alignItems="center"
            border="1px solid #D7D3D3"
            height="100%"
          >
            <Button
              color="primary"
              aria-label="close"
              disabled={
                selectedProduction === null ||
                selectedProduction.ingredients === null
              }
              onClick={() => {
                setOpenCalculator(true);
              }}
            >
              <CalculateIcon fontSize="large" />
            </Button>
          </Box>
        </Grid>
      </Grid>

      {(productionsState === "loading" ||
        createProductionStatus === "loading") && <LinearProgress />}

      <Box display="flex" width="100%">
        {productionsState !== "loading" && selectedProduction === null && (
          <Box sx={{ maxWidth: 600, mx: "auto", my: 5 }}>
            <KOSEmptyState
              icon={InfoOutlinedIcon}
              instruction="Selecciona una producción para ver los ingredientes necesarios."
            />
          </Box>
        )}

        {selectedProduction !== null &&
          selectedProduction.ingredients === null && (
            <Box sx={{ maxWidth: 600, mx: "auto", my: 5 }}>
              <KOSEmptyState
                icon={InfoOutlinedIcon}
                instruction="No se han encontrado ingredientes para generar esta producción"
              />
            </Box>
          )}

        {selectedProduction !== null &&
          selectedProduction.ingredients !== null && (
            <ProductionCatalogTable
              production={selectedProduction}
              quantityToProduce={quantityToProduce}
              showInventoryOnNewProductions={showInventoryOnNewProductions}
            />
          )}
      </Box>

      <ProductionCalculator
        handleClose={handleClose}
        ingredients={selectedProduction?.ingredients!!}
        openCalculator={openCalculator}
        handleConfirm={handleConfirm}
      ></ProductionCalculator>
    </PageBox>
  );
};

export default ProductionCreate;
