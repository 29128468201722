import { createAsyncThunk } from "@reduxjs/toolkit";
import appConfig from "config/app";
import {
  DetailToSave,
  MissingReason,
  ProductionSupplyWithDetailsPayload,
} from "core/productions/entities/Supply";
import { ProductionSupplyHTTPRepository } from "core/productions/repositories/http/Supply";
import {
  CompleteUseCase,
  CreateUseCase,
  GetByKitchenIdUseCase,
  GetDetailUseCase,
  GetPackedByKitchenIdUseCase,
  GetWithDetailsUseCase,
  ReceptionCompleted,
  ReportNoveltyUseCase,
  SaveDetailUseCase,
  UpdatePackedQuantityUseCase,
} from "core/productions/useCases/productionSupplyUseCase";

export const getWithDetails = createAsyncThunk(
  "production/supply/getWithDetails",
  async (payload: { supplyId: string }) => {
    const httpRepository = new ProductionSupplyHTTPRepository(appConfig);
    const useCase = new GetWithDetailsUseCase(httpRepository);

    const useCaseResult = await useCase.execute(payload.supplyId);

    return useCaseResult;
  }
);

export const getForPackagingByKitchenId = createAsyncThunk(
  "production/supply/getForPackagingByKitchenId",
  async (payload: { kitchenId: string }) => {
    const httpRepository = new ProductionSupplyHTTPRepository(appConfig);
    const useCase = new GetByKitchenIdUseCase(httpRepository);

    const useCaseResult = await useCase.execute(payload.kitchenId);

    return useCaseResult;
  }
);

export const getDetail = createAsyncThunk(
  "production/supply/getDetail",
  async (payload: { supplyId: string }) => {
    const httpRepository = new ProductionSupplyHTTPRepository(appConfig);
    const useCase = new GetDetailUseCase(httpRepository);

    const useCaseResult = await useCase.execute(payload.supplyId);

    return useCaseResult;
  }
);

export const getPackedByKitchenId = createAsyncThunk(
  "production/supply/getPackedByKitchenId",
  async (payload: { kitchenId: string }) => {
    const httpRepository = new ProductionSupplyHTTPRepository(appConfig);
    const useCase = new GetPackedByKitchenIdUseCase(httpRepository);

    const useCaseResult = await useCase.execute(payload.kitchenId);
    return useCaseResult;
  }
);

export const updatePackedQuantity = createAsyncThunk(
  "production/supply/updatePackedQuantity",
  async (payload: { itemId: number; packedQuantity: number }) => {
    const httpRepository = new ProductionSupplyHTTPRepository(appConfig);
    const useCase = new UpdatePackedQuantityUseCase(httpRepository);

    const useCaseResult = await useCase.execute(
      payload.itemId,
      payload.packedQuantity
    );

    return useCaseResult;
  }
);

export const reportNovelty = createAsyncThunk(
  "production/supply/reportNovelty",
  async (payload: {
    supplyDetailId: number;
    missingReason: MissingReason;
    receivedQuantity: number;
  }) => {
    const httpRepository = new ProductionSupplyHTTPRepository(appConfig);
    const useCase = new ReportNoveltyUseCase(httpRepository);

    const useCaseResult = await useCase.execute(
      payload.supplyDetailId,
      payload.missingReason,
      payload.receivedQuantity
    );

    return useCaseResult;
  }
);

export const complete = createAsyncThunk(
  "production/supply/complete",
  async (payload: { productionSupplyId: number }) => {
    const httpRepository = new ProductionSupplyHTTPRepository(appConfig);
    const useCase = new CompleteUseCase(httpRepository);

    const useCaseResult = await useCase.execute(payload.productionSupplyId);

    return useCaseResult;
  }
);

export const receptionCompleted = createAsyncThunk(
  "production/supply/receptionCompleted",
  async (payload: { productionSupplyId: number }) => {
    const httpRepository = new ProductionSupplyHTTPRepository(appConfig);
    const useCase = new ReceptionCompleted(httpRepository);

    const useCaseResult = await useCase.execute(payload.productionSupplyId);

    return useCaseResult;
  }
);

export const saveDetail = createAsyncThunk(
  "production/supply/saveDetail",
  async (payload: DetailToSave) => {
    const httpRepository = new ProductionSupplyHTTPRepository(appConfig);
    const useCase = new SaveDetailUseCase(httpRepository);

    const useCaseResult = await useCase.execute(payload);
    return useCaseResult;
  }
);

export const create = createAsyncThunk(
  "production/supply/create",
  async (payload: ProductionSupplyWithDetailsPayload) => {
    const httpRepository = new ProductionSupplyHTTPRepository(appConfig);
    const useCase = new CreateUseCase(httpRepository);

    const useCaseResult = await useCase.execute(payload);
    return useCaseResult;
  }
);
