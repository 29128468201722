import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { commons } from "app/i18n/types";
import { niceBytes } from "app/utils/file";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface UploadAreaProps {
  onUpload: (files: Array<File> | null) => void;
  accept?: string;
  disabled?: boolean;
  loading?: boolean;
  label?: string;
  autoUpload?: boolean;
  maxSizeInMb?: number;
  width?: string;
}

const UploadArea: FunctionComponent<UploadAreaProps> = (props) => {
  const {
    accept,
    onUpload,
    disabled,
    loading,
    label,
    autoUpload,
    maxSizeInMb,
    width,
  } = props;

  const sizeinMb = maxSizeInMb ?? 1;
  const maxSize = sizeinMb * 1024 * 1024;

  const { t } = useTranslation();
  const isDisabled = !!disabled || !!loading;
  const [selectedFiles, setSelectedFiles] = useState<Array<File> | null>(null);
  const [error, setError] = useState<boolean>(false);

  const removeFile = useCallback(
    (fileName: string) => {
      if (selectedFiles === null || selectedFiles.length === 0) {
        return;
      }

      setSelectedFiles(
        (currentSelectedFiles) =>
          currentSelectedFiles?.filter((file) => file.name !== fileName) ?? null
      );
    },
    [selectedFiles]
  );

  useEffect(() => {
    if (selectedFiles && autoUpload) {
      onUpload(selectedFiles);
      setSelectedFiles(null);
    }
  }, [selectedFiles, autoUpload]);

  return (
    <Box
      sx={{
        border: "1px dashed",
        borderColor: "primary.main",
        width: width ?? 450,
        py: 4,
        px: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
        borderRadius: 2,
        bgcolor: "#E2F0FF",
      }}
    >
      {loading && (
        <Box width="100%">
          <LinearProgress
            sx={{ height: 12, borderRadius: 2 }}
            color="warning"
          />
        </Box>
      )}

      <InfoOutlinedIcon fontSize="large" />

      {(selectedFiles === null || selectedFiles.length === 0) && (
        <>
          <Typography textAlign="center" variant="h5">
            {t(!label ? commons.SELECT_MOVE_TEMPLATE : label)}
          </Typography>
          {accept && (
            <Typography textAlign="center" variant="body1" gutterBottom>
              {t(commons.ONLY_FILE_TYPE, { type: accept })}
            </Typography>
          )}

          {error && (
            <Typography textAlign="center" color="error">
              {t(commons.FILE_TOO_BIG, { size: sizeinMb })}
            </Typography>
          )}

          <Button
            variant="contained"
            startIcon={<UploadFileOutlinedIcon />}
            sx={{ py: 2 }}
            component="label"
            disabled={isDisabled}
          >
            {t(commons.SELECT_FILE)}
            <input
              hidden
              accept={accept}
              type="file"
              onChange={(e) => {
                const {
                  target: { files },
                } = e;
                const isTooBig =
                  !!maxSizeInMb && !!files?.length && files[0].size > maxSize;
                setError(isTooBig);
                if (isTooBig) return;
                setSelectedFiles(Array.from(files ?? []));
              }}
              disabled={isDisabled}
            />
          </Button>
        </>
      )}

      {selectedFiles !== null && selectedFiles.length > 0 && (
        <>
          <Box width="100%">
            <Typography textAlign="center" variant="h5">
              {t(commons.SELECTED_FILE)}:
            </Typography>
            {accept && (
              <Typography textAlign="center" variant="body1" gutterBottom>
                {t(commons.ONLY_FILE_TYPE, { type: accept })}
              </Typography>
            )}

            {Array.from(selectedFiles).map((file) => (
              <Box
                key={file.name}
                sx={{
                  display: "flex",
                  p: 1,
                  gap: 2,
                  width: "100%",
                  borderRadius: 2,

                  "&:hover": {
                    bgcolor: "rgba(0,0,0,.05)",
                  },
                }}
              >
                <Box width="100%">
                  <Typography variant="body2" fontWeight={700}>
                    {file.name}
                  </Typography>

                  <Typography variant="body2" color="text.secondary">
                    {niceBytes(file.size)}
                  </Typography>
                </Box>

                <IconButton
                  onClick={() => removeFile(file.name)}
                  disabled={isDisabled}
                >
                  <ClearOutlinedIcon />
                </IconButton>
              </Box>
            ))}
          </Box>

          <Button
            variant="contained"
            startIcon={<UploadFileOutlinedIcon />}
            sx={{ py: 2 }}
            onClick={() => onUpload(selectedFiles)}
            disabled={isDisabled}
          >
            {t(commons.UPLOAD_FILE)}
          </Button>
        </>
      )}
    </Box>
  );
};

export default UploadArea;
