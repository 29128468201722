import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { FunctionComponent, SyntheticEvent } from "react";

import { commons, physicalCountModule } from "app/i18n/types";
import { Product } from "core/physicalCount/entities/Product";
import { useTranslation } from "react-i18next";

interface SetInactiveDialogProps {
  open: boolean;
  item: Product;
  onClose: () => void;
  onContinue: (event: SyntheticEvent<Element, Event>, item: Product) => void;
}

const SetInactiveDialog: FunctionComponent<SetInactiveDialogProps> = (
  props
) => {
  const { item, open, onClose, onContinue } = props;

  const { t } = useTranslation();

  return (
    <Dialog maxWidth="xs" open={open}>
      <DialogTitle id="alert-dialog-title" sx={{ pb: 0 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="h5" color="primary.main" mb={1}>
              {t(physicalCountModule.DISARD_ITEM_MODAL_TITLE)}
            </Typography>
          </Box>
          <IconButton
            color="primary"
            aria-label="close"
            onClick={() => onClose()}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>{" "}
      <DialogContent>
        <Typography mb={1} variant="h5">
          {item.name}
        </Typography>
        <Typography mb={4}>
          {t(physicalCountModule.DISARD_ITEM_MODAL_DESCRIPTION)}
        </Typography>

        <Box display="flex" justifyContent="center">
          <ErrorOutlineIcon sx={{ fontSize: 80, color: "warning.main" }} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          size="large"
          variant="contained"
          onClick={(e) => {
            onContinue(e, item);
            onClose();
          }}
          fullWidth
        >
          {t(commons.CONTINUE)} <NavigateNextIcon />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SetInactiveDialog;
