/* eslint-disable react/require-default-props */
import React from "react";

interface IconProp {
  fontSize?: string;
}

const KitchenPurchaseOrdersReceptionIcon = (
  props: IconProp
): React.ReactElement => {
  const { fontSize } = props;
  return (
    <svg
      width={fontSize ?? 40}
      height={fontSize ?? 40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width={fontSize ?? 40}
        height={fontSize ?? 40}
        rx="20"
        fill="#F1EFEF"
      />
      <mask
        id="mask0_5923_445"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="24"
        height="24"
      >
        <rect x="8" y="8" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5923_445)">
        <path
          d="M14 28C13.1667 28 12.4583 27.7083 11.875 27.125C11.2917 26.5417 11 25.8333 11 25H9V14C9 13.45 9.19583 12.9792 9.5875 12.5875C9.97917 12.1958 10.45 12 11 12H25V16H28L31 20V25H29C29 25.8333 28.7083 26.5417 28.125 27.125C27.5417 27.7083 26.8333 28 26 28C25.1667 28 24.4583 27.7083 23.875 27.125C23.2917 26.5417 23 25.8333 23 25H17C17 25.8333 16.7083 26.5417 16.125 27.125C15.5417 27.7083 14.8333 28 14 28ZM14 26C14.2833 26 14.5208 25.9042 14.7125 25.7125C14.9042 25.5208 15 25.2833 15 25C15 24.7167 14.9042 24.4792 14.7125 24.2875C14.5208 24.0958 14.2833 24 14 24C13.7167 24 13.4792 24.0958 13.2875 24.2875C13.0958 24.4792 13 24.7167 13 25C13 25.2833 13.0958 25.5208 13.2875 25.7125C13.4792 25.9042 13.7167 26 14 26ZM11 23H11.8C12.0833 22.7 12.4083 22.4583 12.775 22.275C13.1417 22.0917 13.55 22 14 22C14.45 22 14.8583 22.0917 15.225 22.275C15.5917 22.4583 15.9167 22.7 16.2 23H23V14H11V23ZM26 26C26.2833 26 26.5208 25.9042 26.7125 25.7125C26.9042 25.5208 27 25.2833 27 25C27 24.7167 26.9042 24.4792 26.7125 24.2875C26.5208 24.0958 26.2833 24 26 24C25.7167 24 25.4792 24.0958 25.2875 24.2875C25.0958 24.4792 25 24.7167 25 25C25 25.2833 25.0958 25.5208 25.2875 25.7125C25.4792 25.9042 25.7167 26 26 26ZM25 21H29.25L27 18H25V21Z"
          fill="#232323"
        />
      </g>
    </svg>
  );
};

export default KitchenPurchaseOrdersReceptionIcon;
