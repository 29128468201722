import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { productionPreparation } from "app/i18n/types";
import { ProductionRecord } from "core/productions/entities/Productions";
import React from "react";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import ProductionPreparation from "./Preparation";

interface Props {
  production: ProductionRecord;
}

const ProductionPreparationToPrint = (props: Props): React.ReactElement => {
  const { production } = props;

  const { t } = useTranslation();

  const componentRef = React.useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <Button
        color="primary"
        variant="outlined"
        onClick={handlePrint}
        startIcon={<ListAltOutlinedIcon />}
      >
        {t(productionPreparation.PRINT_PREAPARATION)}
      </Button>
      <Box sx={{ display: "none" }}>
        <ProductionPreparation
          production={production}
          componentRef={componentRef}
          prepareToPrint
        />
      </Box>
    </>
  );
};

export default ProductionPreparationToPrint;
