import { FileUpload, InfoOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import HourglassTopOutlinedIcon from "@mui/icons-material/HourglassTopOutlined";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import AddDocumentsDialog from "app/components/AddDocumentsDialog";
import PageHeader from "app/components/PageHeader";
import CommonDialog from "app/components/common/CommonDialog";
import { useNavigator } from "app/hooks/useNavigator";
import { commons, purchaseOrderModule } from "app/i18n/types";
import { StoreRequestStatus } from "app/store/types";
import appConfig from "config/app";
import { AmplitudeEvent, logEvent } from "core/common/utils/analytics";
import { LinesPurchaseOrdersHTTPRepository } from "core/linesPurchaseOrders/repositories/http/linesPurchaseOrders";
import { LinesPurchaseOrdersDTO } from "core/linesPurchaseOrders/traits/linesPurchaseOrder/canGetLinesPurchaseOrders";
import { CompletePurchaseOrderUseCase } from "core/linesPurchaseOrders/useCases/linesPurchaseOrder/completePurchaseOrderUseCase";
import { OriginType } from "core/purchaseOrders/entities/PurchaseOrder";
import {
  AttachmentsDetailResponse,
  ReceiptOrder,
  ReceiptType,
} from "core/purchaseOrders/entities/ReceiptOrder";
import { PurchaseOrdersHTTPRepository } from "core/purchaseOrders/repositories/http/purchaseOrders";
import { DeleteReceiptOrderUseCase } from "core/purchaseOrders/useCases/completePurchaseOrderUseCase";
import { MarkPurchaseOrderAsReceivedUseCase } from "core/purchaseOrders/useCases/purchaseOrder/markPurchaseOrderAsReceivedUseCase";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FinishDrawerInfoItem from "./FinishDrawerInfoItem";
import FinishDrawerResume from "./FinishDrawerResume";

interface FinishDrawerProps {
  detail: ReceiptOrder | null;
  lines: LinesPurchaseOrdersDTO | null;
  disabled: boolean;
  setDetail: (detail: AttachmentsDetailResponse | null) => void;
}

const FinishDrawer: FunctionComponent<FinishDrawerProps> = (props) => {
  const { detail, lines, disabled, setDetail } = props;
  const { t } = useTranslation();
  const navigator = useNavigator();
  const { kitchenId, orderId, origin } = navigator.params();

  const [open, setOpen] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openMarkReceived, setOpenMarkReceived] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState(1);
  const [invoiceNumber, setInvoiceNumber] = useState<string | null>(null);
  const [requestStatus, setRequestStatus] =
    useState<StoreRequestStatus>("idle");

  const onClose = () => {
    setOpen(false);
  };

  const onExited = () => {
    setInvoiceNumber(null);
    setActiveStep(1);
    setRequestStatus("idle");
  };

  const handleNext = () => {
    const totalSteps = 3;

    if (activeStep > totalSteps) {
      onClose();
      return;
    }

    if (activeStep < totalSteps) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    if (activeStep === totalSteps - 1) {
      onFinish();
    }

    if (activeStep === totalSteps) {
      navigator.toByLocationType(`/receipt-order/${kitchenId}`);
    }
  };

  const getExtraItems = (): number => {
    const extra =
      lines?.filter(
        (line) =>
          line.status === "IN_PROCESS" && line.quantity < line.quantityReceived
      ).length ?? 0;
    return extra;
  };

  const getCompletedItems = (): number => {
    const completed =
      lines?.filter(
        (line) =>
          line.status === "IN_PROCESS" &&
          line.quantity === line.quantityReceived
      ).length ?? 0;
    return completed;
  };

  const getPartialItems = (): number => {
    const partial =
      lines?.filter((line) => {
        const receivedQuantity =
          line.quantityReceived + (line.quantityToReceive ?? 0);
        return (
          line.status === "IN_PROCESS" &&
          receivedQuantity > 0 &&
          line.quantity > receivedQuantity
        );
      }).length ?? 0;
    return partial;
  };

  const getMissingItems = (): number => {
    const missing =
      lines?.filter(
        (line) => line.quantityReceived + (line.quantityToReceive ?? 0) === 0
      ).length ?? 0;
    return missing;
  };

  const completeOrder = async () => {
    if (kitchenId && orderId && invoiceNumber) {
      const httpRepository = new LinesPurchaseOrdersHTTPRepository(appConfig);
      const updatePurchaseOrderLineUseCase = new CompletePurchaseOrderUseCase(
        httpRepository
      );
      const linesPurchaseOrder = await updatePurchaseOrderLineUseCase.execute(
        kitchenId,
        orderId,
        invoiceNumber,
        detail?.sourceId ?? "",
        origin ?? OriginType.BC
      );
      return linesPurchaseOrder;
    }
  };

  const deleteOrder = async () => {
    if (kitchenId && detail?.receiptNo) {
      const httpRepository = new PurchaseOrdersHTTPRepository(appConfig);
      const deleteReceiptOrderUseCase = new DeleteReceiptOrderUseCase(
        httpRepository
      );
      const response = await deleteReceiptOrderUseCase.execute(
        kitchenId,
        detail?.receiptNo
      );
      return response;
    }
  };

  const markPurchaseOrderAsRecieved = async () => {
    if (kitchenId && detail?.purchaseOrderId) {
      const httpRepository = new PurchaseOrdersHTTPRepository(appConfig);
      const useCase = new MarkPurchaseOrderAsReceivedUseCase(httpRepository);
      const response = await useCase.execute(detail?.purchaseOrderId);
      return response;
    }
  };

  const onDelete = async () => {
    setRequestStatus("loading");
    try {
      const response = await deleteOrder();

      if (response?.ok) {
        setRequestStatus("succeeded");
        navigator.toByLocationType(`/receipt-order/${kitchenId}`);
      } else {
        setError(response?.message ?? "");
        setRequestStatus("failed");
      }
    } catch (e) {
      console.error(e);
      setRequestStatus("failed");
      throw e;
    }
  };

  const onMarkRecived = async () => {
    setRequestStatus("loading");
    try {
      const response = await markPurchaseOrderAsRecieved();

      if (response?.ok) {
        setRequestStatus("succeeded");
        navigator.toByLocationType(`/receipt-order/${kitchenId}`);
      } else {
        setError(response?.message ?? "");
        setRequestStatus("failed");
      }
    } catch (e) {
      console.error(e);
      setRequestStatus("failed");
      throw e;
    }
  };

  const onFinish = async () => {
    setRequestStatus("loading");
    try {
      logEvent(AmplitudeEvent.ReceivePurchaseOrder, {
        kitchenId: kitchenId,
        receiptNo: detail?.receiptNo,
        purchaseOrderId: detail?.sourceId,
      });

      const response = await completeOrder();
      if (response?.ok) {
        setRequestStatus("succeeded");
      } else {
        setError(response?.message ?? "");
        setRequestStatus("failed");
      }
    } catch (e) {
      console.error("Error in onFinish", e);
      setRequestStatus("failed");
      throw e;
    }
  };

  useEffect(() => {
    if (detail) {
      let document = detail.invoiceNo;
      if (detail.type === ReceiptType.TRANSFER_ORDER) {
        document = `REC-${detail.sourceId}`;
      }
      setInvoiceNumber(document);
    }
  }, [detail, open]);

  const [openAddDocuments, setOpenAddDocuments] = useState<boolean>(false);

  useEffect(() => {
    const isPurchaseOrder = detail?.type === ReceiptType.PURCHASE_ORDER;
    if (activeStep === 2 && isPurchaseOrder && !invoiceNumber) {
      setOpenAddDocuments(true);
    }
  }, [activeStep]);

  useEffect(() => {
    if (!openAddDocuments && !detail?.invoiceNo) {
      onClose();
    }
  }, [openAddDocuments]);

  return (
    <>
      <PageHeader
        title={purchaseOrderModule.RECEIPT}
        rightArea={[
          {
            children: t(commons.MARK_RECEIVED),
            variant: "outlined",
            onClick: () => setOpenMarkReceived(true),
            color: "info",
            startIcon: <FactCheckOutlinedIcon />,
            show:
              detail?.partial &&
              detail?.type !== ReceiptType.TRANSFER_ORDER &&
              origin === "KIS",
          },
          {
            children: t(commons.DELETE),
            variant: "outlined",
            onClick: () => setOpenDelete(true),
            color: "error",
            startIcon: <DeleteForeverIcon />,
            show:
              detail?.partial &&
              detail?.type !== ReceiptType.TRANSFER_ORDER &&
              origin === "BC",
          },
          {
            children: t(purchaseOrderModule.ADD_DOCUMENTS),
            variant: detail?.attachments.length ? "contained" : "outlined",
            color: detail?.attachments.length ? "success" : "primary",
            startIcon: <FileUpload />,
            sx: { height: "100%", borderRadius: 2 },
            disabled: !detail,
            onClick: () => setOpenAddDocuments(true),
          },
          {
            children: t(commons.FINISH),
            variant: "contained",
            onClick: () => {
              setOpen(true);
              logEvent(AmplitudeEvent.FinishPurchaseOrderDrawer, {
                kitchenId: kitchenId,
                receiptNo: detail?.receiptNo,
                purchaseOrderId: detail?.sourceId,
              });
            },
            disabled: disabled,
            startIcon: <FactCheckOutlinedIcon />,
          },
        ]}
      />

      {detail && openAddDocuments && kitchenId && (
        <AddDocumentsDialog
          code={detail.sourceId}
          origin={detail.origin}
          type={detail.type}
          initialAttachments={detail.attachments}
          kitchenId={kitchenId}
          setDetail={setDetail}
          onCloseDialog={() => setOpenAddDocuments(false)}
          noClose
          showDocumentNo
          documentNo={detail.invoiceNo}
        />
      )}

      <CommonDialog
        open={openMarkReceived}
        handleClose={() => setOpenMarkReceived(false)}
        handleConfirm={onMarkRecived}
        title={t(purchaseOrderModule.MARK_ASRECEIVED_TITLE)}
        message={t(purchaseOrderModule.MARK_ASRECEIVED_MESSAGE)}
        icon={
          <InfoOutlined
            sx={{
              height: "80px",
              width: "80px",
              paddingRight: "5px",
              color: "#ED6C02",
            }}
          />
        }
        showCancelButton={true}
      />

      <CommonDialog
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        handleConfirm={onDelete}
        title={t(purchaseOrderModule.DELETE_RECEIPT_TITLE)}
        message={t(purchaseOrderModule.DELETE_RECEIPT_MESSAGE, {
          receiptNo: detail?.receiptNo,
        })}
        icon={
          <DeleteForeverIcon
            color="error"
            sx={{
              height: "80px",
              width: "80px",
              paddingRight: "5px",
            }}
          />
        }
        showCancelButton={true}
      />

      <Dialog
        open={open}
        onClose={() => onClose()}
        sx={{ zIndex: 1202 }}
        fullWidth={true}
        maxWidth="xs"
        TransitionProps={{
          onExited: onExited,
        }}
      >
        <Box display="flex" flexDirection="column" height="100%">
          <Box
            px={2}
            py={2}
            display="flex"
            flexDirection="column"
            gap={4}
            width="100%"
          >
            {activeStep === 1 && (
              <Box display="flex" flexDirection="column" gap={2}>
                <Box display="flex" gap={2} alignItems="center" width="100%">
                  <Box flexGrow={1}>
                    <Typography variant="h6" color="primary">
                      Resumen de Órden
                    </Typography>
                  </Box>

                  <IconButton onClick={() => setOpen(false)}>
                    <CloseIcon sx={{ color: "primary" }} />
                  </IconButton>
                </Box>

                <Box display="flex" gap={2} alignItems="center">
                  <Typography>
                    Valida el resumen de la órden para continuar.
                  </Typography>
                </Box>

                <FinishDrawerResume
                  receiptNo={detail?.receiptNo ?? ""}
                  linesCount={lines?.length ?? 0}
                />

                <Box display="flex" gap={2} flexDirection="column" width="100%">
                  {getExtraItems() > 0 && (
                    <FinishDrawerInfoItem
                      label="Artículos Sobrantes"
                      value={getExtraItems().toString()}
                      foregroundColor="#0288D1"
                      backgroundColor="#B4DCF2"
                    />
                  )}

                  {getCompletedItems() > 0 && (
                    <FinishDrawerInfoItem
                      label="Artículos Completos"
                      value={getCompletedItems().toString()}
                      foregroundColor="#2E7D32"
                      backgroundColor="#E1F1E1"
                    />
                  )}

                  {getPartialItems() > 0 && (
                    <FinishDrawerInfoItem
                      label="Artículos Parciales"
                      value={getPartialItems().toString()}
                      foregroundColor="#FF9800"
                      backgroundColor="#FCE7D7"
                    />
                  )}

                  {getMissingItems() > 0 && (
                    <FinishDrawerInfoItem
                      label="Artículos Faltantes"
                      value={getMissingItems().toString()}
                      foregroundColor="#D32F2F"
                      backgroundColor="#F5D7D7"
                    />
                  )}
                </Box>
              </Box>
            )}

            {activeStep === 2 && (
              <Box display="flex" flexDirection="column" gap={4}>
                <Box display="flex" gap={2} alignItems="center" width="100%">
                  <Box flexGrow={1}>
                    <Typography variant="h6" color="primary">
                      {t(commons.ENTER_INVOICE_OR_REMISSION_NO)}
                    </Typography>
                  </Box>

                  <IconButton onClick={() => setOpen(false)}>
                    <CloseIcon sx={{ color: "primary" }} />
                  </IconButton>
                </Box>

                <Box display="flex" gap={2} alignItems="center">
                  <Typography>
                    Ingresa el número de factura o remisión asignado a esta
                    órden de recepción.
                  </Typography>
                </Box>

                <Box display="flex" gap={2} flexDirection="column" width="100%">
                  <TextField
                    label={t(commons.ENTER_INVOICE_OR_REMISSION_NO)}
                    variant="outlined"
                    value={invoiceNumber}
                    onChange={(e) =>
                      setInvoiceNumber(
                        e.target.value !== "" ? e.target.value : null
                      )
                    }
                    inputProps={{ maxLength: 34 }}
                    fullWidth
                    autoComplete="off"
                  />
                </Box>
              </Box>
            )}

            {activeStep === 3 && requestStatus === "loading" && (
              <Box display="flex" flexDirection="column" gap={2}>
                <Box display="flex" gap={2} alignItems="center" width="100%">
                  <Box flexGrow={1}>
                    <Typography variant="h6" color="primary">
                      Guardando la órden de recepción
                    </Typography>
                  </Box>
                  {/* <IconButton onClick={() => setOpen(false)}>
                    <CloseIcon sx={{ color: "primary" }} />
                  </IconButton> */}
                </Box>

                <Box display="flex" flexDirection="column" mb={4}>
                  <Typography fontWeight={700} fontSize={18}>
                    No. Órden: {invoiceNumber}
                  </Typography>

                  <Typography>
                    La órden se esta guardando en tu inventario.
                  </Typography>
                </Box>

                <Box display="flex" gap={2} flexDirection="column" width="100%">
                  <HourglassTopOutlinedIcon
                    sx={{ color: "success.info", fontSize: 120, mx: "auto" }}
                  />
                </Box>
              </Box>
            )}
            {activeStep === 3 && requestStatus === "succeeded" && (
              <Box display="flex" flexDirection="column" gap={2}>
                <Box display="flex" gap={2} alignItems="center" width="100%">
                  <Box flexGrow={1}>
                    <Typography variant="h6" color="primary">
                      Órden Recibida
                    </Typography>
                  </Box>

                  <IconButton onClick={() => setOpen(false)}>
                    <CloseIcon sx={{ color: "primary" }} />
                  </IconButton>
                </Box>

                <Box display="flex" flexDirection="column" mb={4}>
                  <Typography fontWeight={700} fontSize={18}>
                    {invoiceNumber}
                  </Typography>

                  <Typography>
                    La órden se recibió con éxito en tu inventario.
                  </Typography>
                </Box>

                <Box display="flex" gap={2} flexDirection="column" width="100%">
                  <TaskAltOutlinedIcon
                    sx={{ color: "success.main", fontSize: 120, mx: "auto" }}
                  />
                </Box>
              </Box>
            )}

            {activeStep === 3 && requestStatus === "failed" && (
              <Box display="flex" flexDirection="column" gap={2}>
                <Box display="flex" gap={2} alignItems="center" width="100%">
                  <Box flexGrow={1}>
                    <Typography variant="h6" color="error">
                      Ha ocurrido un error al finalizar recepción
                    </Typography>
                  </Box>

                  <IconButton
                    onClick={() => {
                      setOpen(false);
                      logEvent(AmplitudeEvent.ErrorPostingOrder, {
                        kitchenId: kitchenId,
                        receiptNo: detail?.receiptNo,
                        purchaseOrderId: detail?.sourceId,
                        error: error,
                        userAction: "CLOSE",
                      });
                    }}
                  >
                    <CloseIcon sx={{ color: "primary" }} />
                  </IconButton>
                </Box>
                <Box display="flex" gap={2} flexDirection="column" width="100%">
                  <ErrorOutlineOutlinedIcon
                    sx={{ color: "error.main", fontSize: 120, mx: "auto" }}
                  />
                </Box>

                <Box display="flex" flexDirection="column" mb={4}>
                  <Typography>{error}</Typography>
                </Box>

                <Button
                  onClick={() => {
                    onFinish();
                    logEvent(AmplitudeEvent.ErrorPostingOrder, {
                      kitchenId: kitchenId,
                      receiptNo: detail?.receiptNo,
                      purchaseOrderId: detail?.sourceId,
                      error: error,
                      userAction: "RETRY",
                    });
                  }}
                  variant="outlined"
                >
                  Reintentar
                </Button>
              </Box>
            )}

            <Box display="flex" flexDirection="column" gap={1.5} width="100%">
              <Button
                size="large"
                variant="contained"
                onClick={handleNext}
                disabled={
                  disabled ||
                  (activeStep === 2 && invoiceNumber === null) ||
                  requestStatus === "loading" ||
                  requestStatus === "failed"
                }
                fullWidth
              >
                {t(commons.CONTINUE)}
                <Box component={NavigateNextOutlinedIcon} ml={2} />
              </Button>

              {activeStep <= 2 && (
                <Button
                  size="large"
                  variant="outlined"
                  onClick={() => onClose()}
                  color="error"
                  disabled={requestStatus === "loading"}
                  fullWidth
                >
                  {t(commons.CANCEL)}
                  <Box component={CloseIcon} ml={2} />
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default FinishDrawer;
