import appConfig from "config/app";
import {
  CreateOrUpdateVendorResponse,
  Vendor,
  VendorJoinWithSupply,
  VendorWithSupplies,
  VendorWithSuppliesCount,
} from "core/vendors/entities/Vendor";
import { ApiVersion, getHttp, postHttp } from "utils/http";

export const getVendorsByCountry = async (
  country: string
): Promise<Vendor[]> => {
  try {
    const response = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `vendor/country/${country}`
    );
    const result: Vendor[] = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error getVendorsByCountry | ${ex.message}`);
    return [];
  }
};

export const getVendorWithSuppliesById = async (
  id: number
): Promise<VendorWithSupplies | undefined> => {
  try {
    const response = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `vendor/${id}/with-supplies`
    );
    const result: VendorWithSupplies = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error getVendorSupplyByVendorId | ${ex.message}`);
    return undefined;
  }
};

export const create = async (
  data: Vendor
): Promise<CreateOrUpdateVendorResponse> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `vendor/create`,
      data
    );
    const result: CreateOrUpdateVendorResponse = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error create | ${ex.message}`);
    return { ok: false, message: "Error" };
  }
};

export const updateById = async (
  id: number,
  data: Vendor
): Promise<CreateOrUpdateVendorResponse> => {
  try {
    const response = await postHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `vendor/${id}/update`,
      data
    );
    const result: CreateOrUpdateVendorResponse = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error modifyById | ${ex.message}`);
    return { ok: false, message: "Error" };
  }
};

export const bySkuAndCity = async (
  sku: string,
  city: string
): Promise<VendorJoinWithSupply[]> => {
  try {
    const response = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `vendor/by/sku/${sku}/city/${city}`
    );
    const result: VendorJoinWithSupply[] = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error getVendorSupplyByVendorId | ${ex.message}`);
    return [];
  }
};

export const findWithSuppliesCountByCountry = async (
  country: string
): Promise<VendorWithSuppliesCount[]> => {
  try {
    const response = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `vendor/country/${country}/with-supplies-count`
    );
    const result: VendorWithSuppliesCount[] = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error findWithSuppliesCountByCountry | ${ex.message}`);
    return [];
  }
};

export const findVendorById = async (
  id: number
): Promise<Vendor | undefined> => {
  try {
    const response = await getHttp(
      appConfig.kitchenInventory.apiBaseUrl,
      ApiVersion.V1,
      `vendor/${id}`
    );
    const result: Vendor = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error findVendorById | ${ex.message}`);
    return undefined;
  }
};
