import PageHeader from "app/components/PageHeader";
import TheoreticalInventoryComparisonTable from "app/components/TheoreticalInventory/TheoreticalInventoryComparisonTable";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { useNavigator } from "app/hooks/useNavigator";
import { kitchenInventoryManagement } from "app/i18n/types";
import { getDetailsByCounting } from "app/store/slices/countingHistory/thunks";
import { TheoreticalInventoryComparisonByKitchen } from "core/theoricalInventory/entities/ThereticalInventory";
import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataFilterTheoricalComparision } from "utils/general";
import { PageBox, getTheoricalInventoryConfig } from "utils/generalUI";

const PhysicalCountAccuracy: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigator = useNavigator();
  const { countingId, kitchenId } = navigator.params();
  const [data, setData] = useState<TheoreticalInventoryComparisonByKitchen[]>(
    []
  );
  const [dataFiltered, setDataFiltered] = useState<
    TheoreticalInventoryComparisonByKitchen[]
  >([]);
  const [dataFilter, setDataFilter] =
    useState<DataFilterTheoricalComparision>("all");

  React.useEffect(() => {
    setDataFiltered(
      data.filter((line) => {
        return filterValidate(line, dataFilter);
      })
    );
  }, [data, dataFilter]);

  React.useEffect(() => {
    if (countingId) {
      dispatch(getDetailsByCounting({ countingId: parseInt(countingId) }));
    }
  }, [dispatch, countingId]);

  const detailsByCountingRequest = useAppSelector(
    (state) => state.countingHistory.detailsByCountingRequest
  );

  React.useEffect(() => {
    if (detailsByCountingRequest.status === "succeeded") {
      setData(detailsByCountingRequest.data ?? []);
    }
  }, [detailsByCountingRequest]);

  const filterValidate = (
    item: TheoreticalInventoryComparisonByKitchen,
    dataFilterParam: DataFilterTheoricalComparision
  ): boolean => {
    const config = getTheoricalInventoryConfig(
      item,
      detailsByCountingRequest.country
    );

    const { percentage, exceptionValidator, percentageMin, percentageMax } =
      config;

    switch (dataFilterParam) {
      case "none":
        return exceptionValidator;
      case "zero":
        return percentage === 0 && !exceptionValidator;
      case "low":
        return (
          percentageMin <= percentage &&
          percentage <= percentageMax &&
          percentage !== 0 &&
          !exceptionValidator
        );
      case "high":
        return (
          (percentage < percentageMin || percentageMax < percentage) &&
          !exceptionValidator
        );
      default:
        return true;
    }
  };

  return (
    <PageBox>
      <PageHeader
        title={t(kitchenInventoryManagement.KITCHEN_INVENTORY_TITLE)}
        subtitle={t(kitchenInventoryManagement.KITCHEN_INVENTORY_DESCRIPTION)}
      />

      {kitchenId && (
        <TheoreticalInventoryComparisonTable
          kitchenId={kitchenId}
          loading={detailsByCountingRequest.status === "loading"}
          items={dataFiltered}
          country={detailsByCountingRequest.country}
          accuracy={detailsByCountingRequest.accuracy}
        />
      )}
    </PageBox>
  );
};

export default PhysicalCountAccuracy;
