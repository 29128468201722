/* eslint-disable react/no-array-index-key */
import { CloudDownloadOutlined } from "@mui/icons-material";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { AttachmentFile } from "core/attachment/entity";
import React from "react";
import { openUrl } from "utils/general";

interface AttachmentCardProps {
  attachment: AttachmentFile;
  onDelete: (attachment: AttachmentFile) => void;
  openDocument: (name: string) => void;
}

const AttachmentCard = (props: AttachmentCardProps): React.ReactElement => {
  const { attachment, onDelete, openDocument } = props;
  const [showButton, setShowButton] = React.useState(false);

  return (
    <Tooltip key={attachment.name} title={attachment.name}>
      <Box
        onMouseEnter={() => setShowButton(true)}
        onMouseLeave={() => setShowButton(false)}
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {!attachment.id && showButton && (
          <IconButton
            color="error"
            sx={{
              position: "absolute",
              top: 1,
              left: 1,
            }}
            onClick={() => onDelete(attachment)}
          >
            <CloseOutlinedIcon />
          </IconButton>
        )}
        {!!attachment.id && showButton && (
          <IconButton
            color="info"
            sx={{
              position: "absolute",
              top: 1,
              left: 1,
            }}
            onClick={() => openUrl(attachment.path ?? "")}
          >
            <CloudDownloadOutlined />
          </IconButton>
        )}
        <Box
          sx={{
            background: "white",
            border: "1px solid",
            borderRadius: 1,
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            cursor: attachment.id ? "pointer" : undefined,
          }}
          onClick={() => attachment.id && openDocument(attachment.name)}
        >
          <AttachFileOutlinedIcon />
          <Typography
            variant="caption"
            sx={{
              width: "100px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {attachment.name}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  );
};

export default AttachmentCard;
