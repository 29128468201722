import { CancelOutlined, CheckCircleOutline, Close } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { commons, supplyReception } from "app/i18n/types";
import { receptionCompleted } from "app/store/slices/productions/Supply/thunks";
import {
  MissingReason,
  ProductionSupplyWithDetails,
} from "core/productions/entities/Supply";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface ConfirmSupplyingModalProps {
  productionSupply?: ProductionSupplyWithDetails;
  open: boolean;
  onClose: () => void;
}

const ConfirmSupplyingModal: FunctionComponent<ConfirmSupplyingModalProps> = ({
  productionSupply,
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Paper sx={{ p: 3, display: "flex", flexDirection: "column", gap: 2 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontWeight={600} color="primary">
              {t(supplyReception.CONFIRM_SUPPLY_MODAL_TITLE)}
            </Typography>
            <Close onClick={onClose} />
          </Box>
          <Typography>
            {t(supplyReception.CONFIRM_SUPPLY_MODAL_SUBTITLE)}
          </Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            p={2}
            border={1}
            borderColor="#D7D3D3"
            borderRadius={1}
          >
            <Box>
              <Typography color="text.secondary">{t(commons.ID)}</Typography>
              <Typography fontWeight={700}>{productionSupply?.id}</Typography>
            </Box>
            <Box>
              <Typography color="text.secondary">
                {t(supplyReception.ITEMS)}
              </Typography>
              <Typography fontWeight={700}>
                {productionSupply?.details.length}
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            p={2}
            border={1}
            borderColor="info.main"
            bgcolor="#E7F4FB"
            color="info.main"
            borderRadius={1}
          >
            <Typography>{t(supplyReception.SPARE_PRODUCTS)}</Typography>
            <Typography fontWeight={700}>
              {
                productionSupply?.details.filter(
                  (detail) =>
                    detail.missingReason === MissingReason.SPARE_PRODUCT
                ).length
              }
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            p={2}
            border={1}
            borderColor="success.main"
            bgcolor="#E1F1E1"
            color="success.main"
            borderRadius={1}
          >
            <Typography>{t(supplyReception.COMPLETE_PRODUCTS)}</Typography>
            <Typography fontWeight={700}>
              {
                productionSupply?.details.filter(
                  (detail) => detail.missingReason === null
                ).length
              }
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            p={2}
            border={1}
            borderColor="warning.main"
            bgcolor="#FCE7D7"
            color="warning.main"
            borderRadius={1}
          >
            <Typography>{t(supplyReception.PARTIAL_PRODUCTS)}</Typography>
            <Typography fontWeight={700}>
              {
                productionSupply?.details.filter(
                  (detail) =>
                    detail.missingReason === MissingReason.PRODUCT_EXPIRED ||
                    detail.missingReason === MissingReason.DAMAGED_PRODUCT
                ).length
              }
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            p={2}
            border={1}
            borderColor="error.main"
            bgcolor="#F5D7D7"
            color="error.main"
            borderRadius={1}
          >
            <Typography>{t(supplyReception.MISSING_PRODUCTS)}</Typography>
            <Typography fontWeight={700}>
              {
                productionSupply?.details.filter(
                  (detail) =>
                    detail.missingReason === MissingReason.MISSING_PRODUCT
                ).length
              }
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" gap={2}>
            <Button
              variant="outlined"
              color="error"
              startIcon={<CancelOutlined />}
              onClick={onClose}
              fullWidth
            >
              {t(commons.CANCEL)}
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<CheckCircleOutline />}
              onClick={() => {
                dispatch(
                  receptionCompleted({
                    productionSupplyId: productionSupply?.id!,
                  })
                );
                onClose();
                window.history.back();
              }}
              fullWidth
            >
              {t(commons.CONFIRM)}
            </Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default ConfirmSupplyingModal;
