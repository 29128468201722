export enum EntryType {
  POSITIVE = "POSITIVE",
  NEGATIVE = "NEGATIVE",
}

export type ManualAdjustmentEntryType = `${EntryType}`;

export type ManualAdjustment = {
  id?: number;
  quantity: number;
  quantityAfterValidation?: number;
  sku: string;
  kitchenId: string;
  entryType: ManualAdjustmentEntryType;
  name: string;
  unit: string;
  documentCode: string;
  key?: string;
};

export type AddManualAdjustmentResponse = {
  ok: boolean;
  message: string;
};

export type InventoryAdjustment = {
  kitchenId: string;
  sku: string;
  documentCode: string;
  transactionType: string;
  quantity: number;
  entryType: string;
  name: string;
  unit: string;
};
