import Collapse from "@mui/material/Collapse";
import Icon from "@mui/material/Icon";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { commons } from "app/i18n/types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "react-use";
import useUnmount from "react-use/esm/useUnmount";

interface SearchFieldProps {
  collapsible?: boolean;
  setFlexRightArea?: (value: number) => void;
  onSearch: (text: string) => void;
  searchText: string;
}

const SearchField = (props: SearchFieldProps): React.ReactElement => {
  const { collapsible, setFlexRightArea, onSearch, searchText } = props;

  const { t } = useTranslation();

  const [showSearchField, setShowSearchField] = React.useState(false);
  const [showClear, setShowClear] = React.useState(false);
  const [inputElement, setInputElement] = React.useState<HTMLInputElement>();
  const [text, setText] = React.useState("");
  const screenWidthMinSize = 1200;
  const inputRef = React.useRef<HTMLInputElement>(null);

  useDebounce(
    () => {
      onSearch(text);
    },
    1000,
    [text]
  );

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
    setShowClear(!!text.length);
    setInputElement(e.target);
    if (!e.target.value) {
      setShowClear(false);
    }
  };

  const handleClick = () => {
    const input = inputRef.current?.children?.item(0)?.children?.item(0);
    if (input) (input as HTMLElement)?.focus();
    if (showClear) {
      if (inputElement) {
        inputElement.value = "";
        setShowClear(false);
        onSearch(inputElement.value);
      }
    } else {
      setShowSearchField(true);
      if (window.innerWidth <= screenWidthMinSize) setFlexRightArea?.(3);
    }
  };

  React.useEffect(() => {
    if (!searchText && text) handleClick();
  }, [searchText]);

  const onBlurAction = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setShowClear(false);
      setShowSearchField(false);
      if (window.innerWidth <= screenWidthMinSize) setFlexRightArea?.(1);
    }
  };

  useUnmount(() => {
    onSearch("");
  });

  const renderTextField = () => (
    <TextField
      id="search-field"
      size="small"
      placeholder={t(commons.SEARCH)}
      ref={inputRef}
      sx={{ direction: "ltr" }}
      InputProps={{
        onChange: onValueChange,
        onBlur: onBlurAction,
        onClick: handleClick,
        endAdornment: (
          <InputAdornment position="end">
            <Icon sx={{ fontSize: "18px" }}>
              {showClear ? "clear" : "search"}
            </Icon>
          </InputAdornment>
        ),
        sx: {
          backgroundColor: "white",
          paddingRight: "11px",
          cursor: "pointer",
        },
      }}
    />
  );

  const renderCollapse = () => (
    <Collapse
      orientation="horizontal"
      timeout={500}
      in={showSearchField}
      collapsedSize="40px"
      sx={{
        direction: "rtl",
        borderRadius: "4px",
      }}
    >
      {renderTextField()}
    </Collapse>
  );
  return collapsible ? renderCollapse() : renderTextField();
};

export default SearchField;
