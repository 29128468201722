import { createSlice } from "@reduxjs/toolkit";

import { initialState } from "./state";
import {
  AddManualAdjustment,
  getSummaryAdjustment,
  sendAdjustments,
} from "./thunks";

export const adjustmentsSlice = createSlice({
  name: "adjustments",
  initialState,
  reducers: {
    toggleGroupFilter: (state, action) => {
      let groupFilter = [...state.filters.group];

      if (state.filters.group.includes(action.payload)) {
        groupFilter = groupFilter.filter(
          (groupName) => groupName !== action.payload
        );
      } else {
        groupFilter.push(action.payload);
      }

      state.filters.group = groupFilter;
    },
    setSearch: (state, action) => {
      state.filters.search = action.payload;
    },
    clearState: (state) => {
      state = initialState;
    },
    clearStatusLoad: (state) => {
      state.statusLoad = "idle";
    },
    clearAddManualAdjustment: (state) => {
      state.addManualAdjustment = {
        status: "idle",
        error: null,
      };
    },
  },
  extraReducers(builder) {
    builder
      // sendAdjustments
      .addCase(sendAdjustments.pending, (state, action) => {
        state.statusLoad = "loading";
      })
      .addCase(sendAdjustments.fulfilled, (state, action) => {
        state.statusLoad = "succeeded";
        const { adjustmentId, success, msg } = action.payload;
        state.adjustmentId = adjustmentId;
        state.success = success;
        state.msg = msg;
        state.summary = [];
      })
      .addCase(sendAdjustments.rejected, (state, action) => {
        state.statusLoad = "failed";
        state.error = action.error.message ?? null;
      })

      // getSummaryAdjustments
      .addCase(getSummaryAdjustment.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getSummaryAdjustment.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.summary = action.payload;
      })
      .addCase(getSummaryAdjustment.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ?? null;
      })

      // AddManualAdjustment
      .addCase(AddManualAdjustment.pending, (state, action) => {
        state.addManualAdjustment = {
          status: "loading",
          error: null,
        };
      })
      .addCase(AddManualAdjustment.fulfilled, (state, action) => {
        state.addManualAdjustment = {
          status: "succeeded",
          error: null,
        };
      })
      .addCase(AddManualAdjustment.rejected, (state, action) => {
        state.addManualAdjustment = {
          status: "failed",
          error: action.error.message ?? null,
        };
      });
  },
});

export const {
  toggleGroupFilter,
  setSearch,
  clearState,
  clearStatusLoad,
  clearAddManualAdjustment,
} = adjustmentsSlice.actions;

export const { reducer } = adjustmentsSlice;

export default adjustmentsSlice;
