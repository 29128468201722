import AddBoxIcon from "@mui/icons-material/AddBox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigator } from "app/hooks/useNavigator";
import { commons, physicalCountModule } from "app/i18n/types";
import { Product } from "core/physicalCount/entities/Product";
import {
  FocusEvent,
  FunctionComponent,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { getValidateQuantity } from "../../../store/slices/counting/thunks";
import FinishItemCountingModal from "./FinishItemCountingModal";
import SetInactiveDialog from "./SetInactiveDialog";
import SumModal from "./SumModal";

interface GroupTableRowProps {
  item: Product;
  openSumModalExternal: string | null;
  onChange: (event: FocusEvent<HTMLInputElement>, product: Product) => void;
  onCountIncrement: (item: Product, increment: number) => void;
  onFinishItemCounting: (sku: string) => void;
  onCloseSumModal: () => void;
}

const GroupTableRow: FunctionComponent<GroupTableRowProps> = (props) => {
  const navigator = useNavigator();
  const { kitchenId } = navigator.params();
  const {
    item,
    onChange,
    onCountIncrement,
    onFinishItemCounting,
    openSumModalExternal,
    onCloseSumModal,
  } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [value, setValue] = useState<string | null>(null);
  const [openSumModal, setOpenSumModal] = useState<boolean>(false);
  const [inactiveCheckbox, setInactiveCheckbox] = useState<boolean>(
    !item.available
  );

  const [finishModalTitle, setFinishModalTitle] = useState<string>("");
  const [finishModalDescription, setFinishModalDescription] =
    useState<string>("");
  const [cancelModalText, setCancelModalText] = useState<string>("");
  const [continueModalText, setContinueModalText] = useState<string>("");

  const [openSetInactiveModal, setOpenSetInactiveModal] =
    useState<boolean>(false);

  const [openFinisItemCountingModal, setOpenFinisItemCountingModal] =
    useState<boolean>(false);

  const handleOpenSumModal = useCallback(
    (open: boolean) => {
      if (openSumModalExternal === null) {
        setOpenSumModal(open);
        return;
      }

      if (openSumModalExternal !== null) {
        if (openSumModalExternal === item.SKU) {
          setOpenSumModal(true);
          return;
        }

        if (openSumModalExternal !== item.SKU) {
          setOpenSumModal(false);
          return;
        }
      }
    },
    [item.SKU, openSumModalExternal]
  );

  useEffect(() => {
    if (item.quantity !== null) {
      setValue(item.quantity.toString());
    }
  }, [item.quantity]);

  const statusColor = useMemo(():
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning" => {
    if (item.state.error !== null) {
      return "error";
    }

    if (item.status === "IDLE") {
      return "warning";
    }

    if (item.status === "FINISHED") {
      return "success";
    }

    return "primary";
  }, [item.state.error, item.status]);

  const getInputAddornement = useCallback((): ReactNode => {
    if (statusColor === "error") {
      return <ErrorOutlineIcon color="error" />;
    }

    if (item.state.status === "loading") {
      return <CircularProgress size={32} />;
    }

    if (item.state.status === "succeeded") {
      return <CheckCircleOutlineIcon color="primary" />;
    }

    if (item.state.status === "warning") {
      return <ErrorOutlineIcon color="error" />;
    }

    if (item.state.status === "failed") {
      return <ErrorOutlineIcon color="error" />;
    }

    return undefined;
  }, [statusColor, item.state.status]);

  const finishItem = async () => {
    if (kitchenId) {
      const deviation = await validateQuantity(item.SKU, kitchenId);
      if (deviation > 100 && item.quantity !== null) {
        setFinishModalTitle(t(physicalCountModule.CONFIRMATION_FINISHED_TITLE));
        setFinishModalDescription(
          t(physicalCountModule.CONFIRMATION_MODAL_DESCRIPTION)
        );
        setCancelModalText(t(commons.VALIDATE));
        setContinueModalText(t(commons.CONFIRMATION));
      } else {
        setFinishModalTitle(t(physicalCountModule.FINISH_ITEM_MODAL_TITLE));
        setFinishModalDescription(
          t(physicalCountModule.FINISH_ITEM_MODAL_DESCRIPTION)
        );
        setCancelModalText(t(commons.CANCEL));
        setContinueModalText(t(commons.CONTINUE));
      }
      setOpenFinisItemCountingModal(true);
    }
  };

  async function validateQuantity(sky: string, kitchenId: string) {
    let promise = await dispatch(
      getValidateQuantity({ sku: item.SKU, kitchenId: kitchenId })
    );
    const originalPromiseResult = unwrapResult(promise);
    let thericalQuantity = originalPromiseResult.quantity;
    return Math.abs(
      ((item.quantity - thericalQuantity) / thericalQuantity) * 100
    );
  }

  useEffect(() => {
    handleOpenSumModal(false);
  }, [handleOpenSumModal, openSumModalExternal]);

  return (
    <>
      <TableRow>
        <TableCell className="th-name">
          <Typography
            color="text.secondary"
            fontWeight={700}
            textTransform="uppercase"
          >
            {t(commons.NAME)}
          </Typography>
        </TableCell>

        <TableCell className="td-name">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography color={`${statusColor}.main`} fontWeight={700}>
              {item.name}
            </Typography>

            <Tooltip title="Marcar como completado" placement="left" arrow>
              <Box component="span">
                <IconButton
                  color={statusColor}
                  disabled={
                    item.quantity === null || item.status === "FINISHED"
                  }
                  onClick={() => finishItem()}
                  sx={{
                    p: 0,
                    "&:disabled > svg": {
                      opacity: 0.26,
                    },
                  }}
                >
                  <CheckBoxIcon color={statusColor} />
                </IconButton>
              </Box>
            </Tooltip>
          </Box>
        </TableCell>

        <TableCell className="th-unit">
          <Typography
            color="text.secondary"
            fontWeight={700}
            textTransform="uppercase"
          >
            {t(commons.UNITS)}
          </Typography>
        </TableCell>

        <TableCell className="td-unit">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="start"
            gap={1}
          >
            <TextField
              name="value"
              value={value ?? ""}
              label={t(commons.ENTER_QUANTITY)}
              size="small"
              type="tel"
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                min: 0,
              }}
              InputProps={{
                endAdornment: getInputAddornement(),
              }}
              color={statusColor}
              error={item.state.error !== null}
              helperText={item.state.error}
              onChange={(e) => setValue(e.target.value)}
              onBlur={(event: FocusEvent<HTMLInputElement>) =>
                onChange(event, item)
              }
              sx={{
                "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
                  borderColor: (theme) =>
                    `${theme.palette[statusColor].light}!important`,
                },
              }}
              fullWidth
              focused
              autoComplete="off"
            />

            <Box display="flex" justifyContent="center" width={40}>
              <Typography color={`${statusColor}.main`}>
                {item.unitShortName}
              </Typography>
            </Box>
            {item.status !== "FINISHED" && (
              <Box display="flex" justifyContent="center" width={40}>
                {item.quantity !== null && (
                  <IconButton onClick={() => handleOpenSumModal(true)}>
                    <AddBoxIcon />
                  </IconButton>
                )}
              </Box>
            )}
          </Box>
        </TableCell>

        <TableCell className="th-sku">
          <Typography
            color="text.secondary"
            fontWeight={700}
            textTransform="uppercase"
          >
            {t(commons.SKU)}
          </Typography>
        </TableCell>

        <TableCell className="td-sku">
          <Typography>{item.SKU}</Typography>
        </TableCell>
      </TableRow>

      {openSumModal && (
        <SumModal
          item={item}
          onClose={() => {
            setOpenSumModal(false);
            onCloseSumModal();
          }}
          onContinue={onCountIncrement}
        />
      )}

      <SetInactiveDialog
        item={item}
        open={openSetInactiveModal}
        onClose={() => setOpenSetInactiveModal(false)}
        onContinue={(event, item) => {
          setInactiveCheckbox(!inactiveCheckbox);
        }}
      />

      <FinishItemCountingModal
        item={item}
        open={openFinisItemCountingModal}
        onClose={() => setOpenFinisItemCountingModal(false)}
        message={finishModalDescription}
        title={finishModalTitle}
        cancelButtonText={cancelModalText}
        continueButtonText={continueModalText}
        onContinue={(event, item) => {
          onFinishItemCounting(item.SKU);
        }}
      />
    </>
  );
};

export default memo(GroupTableRow);
