import { Archive } from "@mui/icons-material";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useNavigator } from "app/hooks/useNavigator";
import { commons, supplyReception } from "app/i18n/types";
import { convertLocalDate } from "app/utils/dateUtils";
import { ProductionSupplyResponse } from "core/productions/entities/Supply";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface ProductionSupplyReceptionTableProps {
  productionSupplies: ProductionSupplyResponse[];
  kitchenId?: string;
  search?: number;
}

const ProductionSupplyReceptionTable: FunctionComponent<
  ProductionSupplyReceptionTableProps
> = ({ productionSupplies, kitchenId, search }) => {
  const { t } = useTranslation();
  const navigator = useNavigator();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width="12%" align="center">
              <Typography
                sx={{ textTransform: "uppercase" }}
                color="text.secondary"
                fontWeight={700}
              >
                {t(supplyReception.REQUISITION_NUMBER_LABEL)}
              </Typography>
            </TableCell>
            <TableCell width="52%" align="center">
              <Typography
                sx={{ textTransform: "uppercase" }}
                color="text.secondary"
                fontWeight={700}
              >
                {t(supplyReception.LOCATION_NAME_LABEL)}
              </Typography>
            </TableCell>
            <TableCell width="12%" align="center">
              <Typography
                sx={{ textTransform: "uppercase" }}
                color="text.secondary"
                fontWeight={700}
              >
                {t(supplyReception.DATE_LABEL)}
              </Typography>
            </TableCell>
            <TableCell width="12%" align="center">
              <Typography
                sx={{ textTransform: "uppercase" }}
                color="text.secondary"
                fontWeight={700}
              >
                {t(commons.STATUS)}
              </Typography>
            </TableCell>
            <TableCell width="12%" align="center">
              <Typography
                sx={{ textTransform: "uppercase" }}
                color="text.secondary"
                fontWeight={700}
              >
                {t(commons.ACTIONS)}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productionSupplies
            .filter((supply) => (search ? supply.id === search : true))
            .map((supply) => (
              <TableRow key={supply.id}>
                <TableCell align="center">{supply.id}</TableCell>
                <TableCell>{supply.locationName}</TableCell>
                <TableCell align="center">
                  <Typography fontWeight={700}>
                    {convertLocalDate(supply.productionDate.toString(), "date")}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Chip
                    label={t(`supplyReception.${supply.status}`)}
                    icon={<ErrorOutline />}
                    color="warning"
                  />
                </TableCell>
                <TableCell align="center">
                  <Button
                    variant="contained"
                    color="info"
                    startIcon={<Archive />}
                    onClick={() =>
                      navigator.toByLocationType(
                        `/productions/supply-reception/${kitchenId}/details/${supply.id}`
                      )
                    }
                  >
                    <Typography>{t(commons.RECEIVE)}</Typography>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default ProductionSupplyReceptionTable;
