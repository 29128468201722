import { ManualAdjustment } from "core/adjustments/entities/ManualAdjustment";
import { CanValidateAdjustments } from "core/adjustments/interfaces/adjustment/canValidateAdjustments";
import { UseCase } from "core/common/interfaces/useCase";

export class ValidateAdjustmentsUseCase implements UseCase {
  constructor(private readonly repository: CanValidateAdjustments) {}
  public execute(adjustments: Array<ManualAdjustment>) {
    return this.repository.validateAdjustments(adjustments);
  }
}
