export const slugify = (text: string) => {
  return text
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^a-z0-9-]/g, "") // Remove all non-word chars
    .replace(/^-+/g, "") // Remove leading -
    .replace(/-+$/g, ""); // Remove trailing -
};

export const refinedKitchenName = (name: string): string => {
  return name.toUpperCase().replace("COCINA", "").trim();
};
