import { KOSBaseTableHeader } from "@foodology-co/alejandria";
import { bcIntegrations, commons } from "app/i18n/types";
import { PurchaseOrderReceiptError } from "core/BusinessCentralIntegration/entities/BusinessCentralIntegration";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import BcIntegrationTable from "./BcIntegrationTable";

interface Props {
  errors: PurchaseOrderReceiptError[];
  loading: boolean;
}

const PurchaseOrderReceiptErrors: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const { errors, loading } = props;

  const getHeader = (): KOSBaseTableHeader[] => {
    return [
      {
        label: t(commons.COUNTRY),
        field: "country",
      },
      {
        label: t(commons.LOCATION),
        field: "kitchenId",
      },
      {
        label: t(commons.CODE),
        field: "purchaseCode",
      },
      {
        label: t(commons.DATE),
        field: "purchaseDate",
      },
      {
        label: t(commons.STATUS),
        field: "status",
      },
      {
        label: t(commons.ERROR),
        field: "error",
      },
    ];
  };

  return (
    <BcIntegrationTable
      data={errors}
      headers={getHeader()}
      loading={loading}
      title={t(bcIntegrations.PURCHASE_ORDERS_RECEIPT)}
    />
  );
};
export default PurchaseOrderReceiptErrors;
