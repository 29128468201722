/* eslint-disable react/require-default-props */
import React from "react";

interface IconProp {
  fontSize?: string;
}

const KitchenAuthorizeTransfersIcon = (props: IconProp): React.ReactElement => {
  const { fontSize } = props;
  return (
    <svg
      width={fontSize ?? 40}
      height={fontSize ?? 40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width={fontSize ?? 40}
        height={fontSize ?? 40}
        rx="20"
        fill="#F1EFEF"
      />
      <mask
        id="mask0_5928_575"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="24"
        height="24"
      >
        <rect x="8" y="8" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5928_575)">
        <path
          d="M19 29.9508C17.7333 29.8174 16.5542 29.4716 15.4625 28.9133C14.3708 28.3549 13.4208 27.6299 12.6125 26.7383C11.8042 25.8466 11.1667 24.8258 10.7 23.6758C10.2333 22.5258 10 21.3008 10 20.0008C10 18.4841 10.3042 17.0841 10.9125 15.8008C11.5208 14.5174 12.35 13.4174 13.4 12.5008H11V10.5008H17V16.5008H15V13.7758C14.0833 14.5091 13.3542 15.4133 12.8125 16.4883C12.2708 17.5633 12 18.7341 12 20.0008C12 22.0508 12.6708 23.8216 14.0125 25.3133C15.3542 26.8049 17.0167 27.6758 19 27.9258V29.9508ZM18.575 24.6008L14.35 20.3508L15.75 18.9508L18.575 21.7758L24.25 16.1008L25.65 17.5258L18.575 24.6008ZM23 29.5008V23.5008H25V26.2258C25.9167 25.4758 26.6458 24.5674 27.1875 23.5008C27.7292 22.4341 28 21.2674 28 20.0008C28 17.9508 27.3292 16.1799 25.9875 14.6883C24.6458 13.1966 22.9833 12.3258 21 12.0758V10.0508C23.5333 10.3008 25.6667 11.3674 27.4 13.2508C29.1333 15.1341 30 17.3841 30 20.0008C30 21.5174 29.6958 22.9174 29.0875 24.2008C28.4792 25.4841 27.65 26.5841 26.6 27.5008H29V29.5008H23Z"
          fill="#232323"
        />
      </g>
    </svg>
  );
};

export default KitchenAuthorizeTransfersIcon;
