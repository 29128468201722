import CloseOutlined from "@mui/icons-material/CloseOutlined";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import { FunctionComponent } from "react";

interface ChipsProps {
  chips: string[];
  setChips: (chips: string[]) => void;
}

const Chips: FunctionComponent<ChipsProps> = ({
  chips,
  setChips,
}: ChipsProps) => {
  const removeChip = (index: number) => {
    const chipsTmp = [...chips];
    chipsTmp.splice(index, 1);
    setChips(chipsTmp);
  };
  return (
    <>
      {!!chips.length && (
        <Grid item xs={12} sx={{ display: "flex", overflowX: "auto" }}>
          {chips.map((label, index) => (
            <Chip
              key={`ChipPackingFilter${label}`}
              icon={
                <CloseOutlined
                  onClick={() => removeChip(index)}
                  sx={{ cursor: "pointer" }}
                />
              }
              label={label}
              color="primary"
              sx={{ mr: 1 }}
            />
          ))}
        </Grid>
      )}
    </>
  );
};

export default Chips;
