import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Chip } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {
  AuthorizationOption,
  AuthorizationType,
  authorizationOptions,
} from "config/dailyAdjustment";
import { AdjustmentRecord } from "core/dailyAdjustments/entities/Adjustment";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  adjustmentsForReview: AdjustmentRecord[];
  adjustmentsForValidation: AdjustmentRecord[];
  selectedOption: AuthorizationOption;
  setSelectedOption: (option: AuthorizationOption) => void;
  onClickDeleteButton: () => void;
  disabledDeleteButton: boolean;
}

const AuthorizationTabs: FunctionComponent<Props> = (props) => {
  const {
    adjustmentsForReview,
    adjustmentsForValidation,
    selectedOption,
    setSelectedOption,
    onClickDeleteButton,
    disabledDeleteButton,
  } = props;
  const { t } = useTranslation();

  const getAdjustmentsCount = (type: AuthorizationType) => {
    if (type === "ALL") return adjustmentsForReview.length;
    if (type === "FOR_VALIDATE") return adjustmentsForValidation.length;
    return adjustmentsForReview.filter((el) => el.typeLoss === type).length;
  };

  return (
    <Box display="flex" gap={1}>
      <Button
        variant="text"
        color="error"
        sx={{
          bgcolor: "white",
          px: 1,
          display: "flex",
          alignItems: "center",
          minHeight: 48,
        }}
        onClick={onClickDeleteButton}
        disabled={disabledDeleteButton}
      >
        <DeleteIcon />
      </Button>

      <Box bgcolor="white" flexGrow={1}>
        <Tabs
          value={selectedOption}
          onChange={(_, value) => {
            setSelectedOption(value);
          }}
          variant="fullWidth"
        >
          {authorizationOptions.map((option) => {
            const total = getAdjustmentsCount(option.type);
            return (
              <Tab
                key={`AuthorizationTab-${option.type}`}
                label={
                  <>
                    {t(
                      option.label
                        ? option.label
                        : `adjustmentType.${option.type}`
                    )}
                    {!!total && <Chip label={total} color={"primary"} />}
                  </>
                }
                value={option}
                iconPosition="start"
                sx={{ minHeight: 48, gap: 1 }}
              />
            );
          })}
        </Tabs>
      </Box>
    </Box>
  );
};

export default AuthorizationTabs;
