import { KOSEmptyState } from "@foodology-co/alejandria";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useAppSelector } from "app/hooks/useAppSelector";
import { commons, productionSchedule } from "app/i18n/types";
import { Today } from "app/utils/dateUtils";
import { ProductionPlan, SlotType } from "core/productions/entities/Plan";
import React, { useEffect, useState } from "react";
import { DroppableProvided, DroppableStateSnapshot } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { compareSearchText } from "utils/general";
import { centerBoxElements } from "utils/generalUI";
import { Actions } from "utils/modal";
import {
  getSlotColor,
  slotBox,
  slotBoxWithCard,
  slotTitle,
} from "utils/productionSchedule";
import { StrictModeDroppable } from "../../DragAndDrop/StrictModeDroppable";
import { DragAndDropAction, DragAndDropData, SEPARATOR } from "./Calendar";
import ProductionScheduleCard from "./Card";
import { ProductionDialogProps } from "./Manager/useHandleProductions";

interface Props {
  date: Date;
  dateStr: string;
  openAddProductionDialog: (props: ProductionDialogProps) => void;
  plans: ProductionPlan[];
  slot: SlotType;
  dragAndDropData: DragAndDropData;
  loading: boolean;
  today: Today;
  openInfoDialog: (planId: number) => void;
  reloadDay: (plan: ProductionPlan) => void;
  onlyView?: boolean;
}

const ProductionScheduleSlotContent = (props: Props): React.ReactElement => {
  const {
    date,
    dateStr,
    openAddProductionDialog,
    slot,
    plans: plansOfDay,
    dragAndDropData,
    loading,
    today,
    openInfoDialog,
    reloadDay,
    onlyView,
  } = props;

  const { t } = useTranslation();

  const searchTerm = useAppSelector((state) => state.global.textSearchOnTopBar);

  const droppableId = `${slot}${SEPARATOR}${dateStr}`;
  const disabledAction = date.getTime() <= today.start.getTime();
  const isToday = today.str === dateStr;
  const [plans, setPlans] = useState<ProductionPlan[]>([]);
  const [filteredPlans, setFilteredPlans] = useState<ProductionPlan[]>([]);

  const filterTerm = (el: ProductionPlan) => {
    if (!searchTerm) return true;
    return (
      compareSearchText(el.name, searchTerm) ||
      compareSearchText(el.sku, searchTerm) ||
      compareSearchText(el.id, searchTerm)
    );
  };

  useEffect(() => {
    setFilteredPlans(plans.filter(filterTerm));
  }, [plans, searchTerm]);

  const existsPlans = !!plans.length;

  useEffect(() => {
    setPlans(plansOfDay.filter((el) => el.timeSlot === slot));
  }, [plansOfDay]);

  const removePlan = (dragAndDropAction: DragAndDropAction) => {
    if (
      dragAndDropAction.source === droppableId &&
      !dragAndDropAction.element
    ) {
      dragAndDropData.setElement(
        plans.find((el) => el.id === dragAndDropAction.elementId)
      );
      setPlans(plans.filter((el) => el.id !== dragAndDropAction.elementId));
    }
  };

  const addPlan = (dragAndDropAction: DragAndDropAction) => {
    if (
      dragAndDropAction.destination === droppableId &&
      dragAndDropAction.element
    ) {
      setPlans([...plans, dragAndDropAction.element]);
      dragAndDropData.clearData();
    }
  };

  useEffect(() => {
    if (dragAndDropData.data) {
      removePlan(dragAndDropData.data);
      addPlan(dragAndDropData.data);
    }
  }, [dragAndDropData.data]);

  const addProductionBySlot = () => {
    openAddProductionDialog({
      action: Actions.add,
      selectedSlot: {
        timeSlot: slot,
        scheduledDate: dateStr,
      },
    });
  };

  const cardBox = plans.length ? { ...slotBoxWithCard, pt: 0 } : slotBox;

  const removeCard = (id: number) => {
    setPlans(plans.filter((el) => el.id !== id));
  };

  const showAddButton = (!disabledAction || isToday) && !onlyView;

  return (
    <>
      <Box
        sx={{
          ...slotTitle,
          backgroundColor: getSlotColor(slot),
        }}
      >
        <Chip
          label={plans.length}
          sx={{
            backgroundColor: "#f9fafc",
            fontWeight: "bold",
          }}
        />
        <Typography variant="body2">{t(commons.PRODUCTIONS)}</Typography>
        {showAddButton && (
          <Button
            variant="text"
            startIcon={<AddOutlinedIcon />}
            sx={{ color: "#3C81DB" }}
            onClick={addProductionBySlot}
          >
            {t(commons.ADD)}
          </Button>
        )}
      </Box>
      <Box sx={{ ...cardBox }}>
        <StrictModeDroppable
          key={droppableId}
          droppableId={droppableId}
          isDropDisabled={disabledAction || !!onlyView}
        >
          {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
            <Box
              {...provided.droppableProps}
              ref={provided.innerRef}
              sx={{
                width: "100%",
                height: "100%",
                background: snapshot.isDraggingOver ? "#EDEDED" : "#f9fafc",
                overflowY: "auto",
                ...(loading ? centerBoxElements : {}),
              }}
            >
              {loading && <CircularProgress />}
              {!loading && (
                <>
                  {!!filteredPlans.length && (
                    <Grid container>
                      {filteredPlans.map((plan, index) => (
                        <Grid
                          item
                          xs={6}
                          key={`ProductionScheduleCardItem${plan.id}`}
                        >
                          <ProductionScheduleCard
                            id={plan.id}
                            index={index}
                            productionPlan={plan}
                            openAddProductionDialog={openAddProductionDialog}
                            disabledAction={disabledAction}
                            removeCard={() => removeCard(plan.id)}
                            openInfoDialog={() => openInfoDialog(plan.id)}
                            isToday={isToday}
                            reloadDay={() => reloadDay(plan)}
                            onlyView={onlyView}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  )}
                  {!filteredPlans.length && (
                    <KOSEmptyState
                      icon={
                        existsPlans
                          ? InfoOutlinedIcon
                          : CalendarMonthOutlinedIcon
                      }
                      message={
                        existsPlans
                          ? t(commons.NO_SEARCH_RESULTS, { search: searchTerm })
                          : t(productionSchedule.EMPTY_TITLE)
                      }
                      instruction={
                        existsPlans
                          ? undefined
                          : t(productionSchedule.EMPTY_DESCRIPTION)
                      }
                      withoutBackground
                      fullHeight
                    />
                  )}
                </>
              )}
            </Box>
          )}
        </StrictModeDroppable>
      </Box>
    </>
  );
};

export default React.memo(ProductionScheduleSlotContent);
