/* eslint-disable react/require-default-props */
import React from "react";

interface IconProp {
  fontSize?: string;
}

const DataAlertIcon = (props: IconProp): React.ReactElement => {
  const { fontSize } = props;
  return (
    <svg
      width={fontSize ?? 19}
      height={fontSize ?? 17}
      viewBox="0 0 19 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.75 17V15H12.75V17H0.75ZM13.75 10C12.3667 10 11.1875 9.5125 10.2125 8.5375C9.2375 7.5625 8.75 6.38333 8.75 5C8.75 3.61667 9.2375 2.4375 10.2125 1.4625C11.1875 0.4875 12.3667 0 13.75 0C15.1333 0 16.3125 0.4875 17.2875 1.4625C18.2625 2.4375 18.75 3.61667 18.75 5C18.75 6.38333 18.2625 7.5625 17.2875 8.5375C16.3125 9.5125 15.1333 10 13.75 10ZM0.75 9V7H7.05C7.16667 7.36667 7.3 7.71667 7.45 8.05C7.6 8.38333 7.78333 8.7 8 9H0.75ZM0.75 13V11H10.15C10.5333 11.2333 10.9417 11.4292 11.375 11.5875C11.8083 11.7458 12.2667 11.8583 12.75 11.925V13H0.75ZM13.25 6H14.25V2H13.25V6ZM13.75 8C13.8833 8 14 7.95 14.1 7.85C14.2 7.75 14.25 7.63333 14.25 7.5C14.25 7.36667 14.2 7.25 14.1 7.15C14 7.05 13.8833 7 13.75 7C13.6167 7 13.5 7.05 13.4 7.15C13.3 7.25 13.25 7.36667 13.25 7.5C13.25 7.63333 13.3 7.75 13.4 7.85C13.5 7.95 13.6167 8 13.75 8Z"
        fill="#011A5B"
      />
    </svg>
  );
};

export default DataAlertIcon;
