import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { commons } from "app/i18n/types";
import { Purchase } from "core/purchaseOrders/entities/PurchaseOrder";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  orders: Purchase[];
  selected: string | undefined;
  setSelected: (selected?: string) => void;
  currentUserId?: string;
  disabled?: boolean;
}

interface Option {
  id: string;
  name: string;
}

const PurchaseOrderUserSelector: FunctionComponent<Props> = (props) => {
  const { orders, selected, setSelected, currentUserId, disabled } = props;

  const { t } = useTranslation();
  const [reset, setReset] = useState<number>(new Date().getTime());
  const [options, setOptions] = useState<Option[]>([]);
  const [selectedOption, setSelectedOption] = useState<Option>();

  useEffect(() => {
    const dataSet = new Set(orders.map((order) => order.userId));
    const userIds = [...dataSet];
    const tmp = userIds
      .map((id) => {
        const order = orders.find((el) => el.userId === id);
        if (order) {
          return { id, name: order.userName };
        } else {
          return { id: "", name: "" };
        }
      })
      .filter((el) => !!el.id);
    setOptions(tmp);
  }, [orders]);

  useEffect(() => {
    if (!selected) {
      setReset(new Date().getTime());
    }
  }, [selected]);

  useEffect(() => {
    const optionFound = options.find((el) => el.id === currentUserId);
    setSelectedOption(optionFound);
  }, [options, currentUserId, reset]);

  return (
    <Autocomplete
      key={reset}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t(commons.BUYER)}
          sx={{ bgcolor: "white" }}
        />
      )}
      value={selectedOption}
      options={options}
      getOptionLabel={(option) => option.name}
      onChange={(_, value) => {
        setSelectedOption(value ?? undefined);
        setSelected(value?.id);
      }}
      disabled={disabled}
    />
  );
};

export default PurchaseOrderUserSelector;
