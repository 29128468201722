import { UseCase } from "core/common/interfaces/useCase";
import { CanArchiveTransferOrder } from "core/transfer/interfaces/transfer/canArchiveTransferOrder";

export class ArchiveTransferOrderUseCase implements UseCase {
  constructor(private readonly repository: CanArchiveTransferOrder) {}

  public execute(transferOrderId: number) {
    return this.repository.archiveTransferOrder(transferOrderId);
  }
}
