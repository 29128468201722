import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FinishProductionDIalog from "app/components/Productions/FinishDialog";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { productionManagement } from "app/i18n/types";
import { getListProducts } from "app/store/slices/dailyAdjustments/thunks";
import { Product } from "core/dailyAdjustments/entities/Catalog";
import { ProductionRecord } from "core/productions/entities/Productions";
import React from "react";
import { useTranslation } from "react-i18next";
import { getProductImageDefault, getProductImageUrl } from "utils/general";
import {
  SelectedProductionActionType,
  SelectedProductionAndAction,
} from "utils/ratatouille";
import ProductionCardFooterActions from "../Card/FooterActions";
import ProductionCardIngredients from "../Card/Ingredients";
import ProductionCardIngredientsToPrint from "../Card/IngredientsToPrint";
import ProductionPreparation from "./Preparation";
import ProductionPreparationToPrint from "./PreparationToPrint";

interface Props {
  production: ProductionRecord;
  kitchenId?: string;
  onClose: () => void;
  onRefresh: () => void;
  setSelectedProductionAndAction: (
    selected: SelectedProductionAndAction
  ) => void;
}

const ProductionInfo = (props: Props): React.ReactElement => {
  const {
    production,
    kitchenId,
    onClose,
    onRefresh,
    setSelectedProductionAndAction,
  } = props;
  const [product, setProduct] = React.useState<Product | null>();
  const [showFinishProduction, setShowFinishProduction] =
    React.useState<boolean>(false);

  const [imageUrl, setImageUrl] = React.useState<string>(
    getProductImageDefault()
  );

  const { t } = useTranslation();

  const products = useAppSelector((state) => state.global.products.data);

  const dispatch = useAppDispatch();

  const setSelectedProduction = (action: SelectedProductionActionType) => {
    setSelectedProductionAndAction({
      production,
      action: action,
    });
  };

  React.useEffect(() => {
    if (products) {
      const product = products.find(
        (product) => product.sku === production.sku
      );
      setProduct(product);
    }
  }, [products, production]);

  React.useEffect(() => {
    if (kitchenId !== null && kitchenId !== undefined) {
      dispatch(getListProducts({ kitchenId: kitchenId }));
    }
  }, [dispatch, kitchenId]);

  React.useEffect(() => {
    if (product) {
      const url = getProductImageUrl(product);
      fetch(url).then((response) => {
        if (response.status === 200) {
          setImageUrl(url);
        }
      });
    }
  }, [product]);

  const units = t(`measureUnits.${production.unit.toLowerCase()}`);
  const usedQuantity = `${production.producedQuantity} ${units}`;

  return (
    <Drawer
      anchor="right"
      open
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          width: 480,
        },
      }}
    >
      <Box>
        <IconButton
          onClick={onClose}
          color="default"
          sx={{
            position: "absolute",
            top: 8,
            left: 8,
            backgroundColor: "white",
            "&:hover": {
              backgroundColor: "white",
            },
          }}
        >
          <Icon>close</Icon>
        </IconButton>
        <img
          src={imageUrl}
          alt={product?.name}
          style={{ width: "100%", height: 200 }}
        />
        <Box display="flex" flexDirection="column" gap={2} m={1}>
          <Typography variant="h6" component="div">
            {production.name}
          </Typography>

          {["FINISHED"].includes(production.status) && (
            <Box
              display="flex"
              justifyContent="space-between"
              py={0.5}
              gap={2}
              alignItems="center"
              width={"100%"}
            >
              <Typography
                fontWeight={700}
                component="div"
                bgcolor="#eeeeee"
                color="primary.main"
                width="100%"
              >
                {t(productionManagement.GENERATED_QUANTITY)}: {usedQuantity}
              </Typography>
            </Box>
          )}

          {!["SCHEDULED", "FINISHED"].includes(production.status) && (
            <ProductionCardIngredientsToPrint production={production} />
          )}

          <ProductionCardIngredients production={production} />

          {!["FINISHED"].includes(production.status) && (
            <>
              <ProductionPreparationToPrint production={production} />
              <ProductionPreparation production={production} />
              <ProductionCardFooterActions
                key={`ProductionCardFooterActions${production.id}`}
                production={production}
                setSelectedProduction={setSelectedProduction}
                onRefresh={onRefresh}
                contract
              />
            </>
          )}
        </Box>
      </Box>
      {showFinishProduction && !["FINISHED"].includes(production.status) && (
        <FinishProductionDIalog
          production={production}
          setShowModal={setShowFinishProduction}
          onSuccess={onRefresh}
        />
      )}
    </Drawer>
  );
};

export default ProductionInfo;
