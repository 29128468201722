/* eslint-disable react/require-default-props */
import React from "react";

interface IconProp {
  fontSize?: string;
}

const MicrosoftIcon = (props: IconProp): React.ReactElement => {
  const { fontSize } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={fontSize ?? 40}
      height={fontSize ?? 40}
    >
      <path
        fill="currentColor"
        d="M2 3h9v9H2V3m9 19H2v-9h9v9M21 3v9h-9V3h9m0 19h-9v-9h9v9Z"
      />
    </svg>
  );
};

export default MicrosoftIcon;
