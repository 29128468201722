import { createSlice, current } from "@reduxjs/toolkit";
import { Status } from "core/dailyAdjustments/entities/Adjustment";
import { initialState } from "./state";
import {
  archiveAdjustments,
  authorizeAdjustments,
  createDailyAdjustmentReport,
  getAdjustmentsByKitchenForReview,
  setItemDailyAdjustments,
} from "./thunks";

export const dailyAdjustmentsSlice = createSlice({
  name: "dailyAdjustments",
  initialState,
  reducers: {
    toggleGroupFilter: (state, action) => {
      let groupFilter = [...state.filters.group];

      if (state.filters.group.includes(action.payload)) {
        groupFilter = groupFilter.filter(
          (groupName) => groupName !== action.payload
        );
      } else {
        groupFilter.push(action.payload);
      }

      state.filters.group = groupFilter;
    },
    setSearch: (state, action) => {
      state.filters.search = action.payload;
    },
    setDailyAdjustments: (state, action) => {
      if (action.payload !== null) state.dailyAdjustments = action.payload;
    },
    removeDailyAdjustment: (state, action) => {
      const newItems = [...current(state).dailyAdjustments];
      const itemToDeleteIndex = state.dailyAdjustments.findIndex(
        (item) => item.sku === action.payload.sku
      );

      if (itemToDeleteIndex < 0) {
        return;
      }

      newItems.splice(itemToDeleteIndex, 1);

      state.dailyAdjustments = newItems;
    },
    removeDailyAdjustments: (state, action) => {
      const newItems = [...current(state).dailyAdjustments];
      const itemToDeleteIndex = state.dailyAdjustments.findIndex(
        (item) => item.id === action.payload.id
      );

      if (itemToDeleteIndex < 0) {
        return;
      }

      newItems.splice(itemToDeleteIndex, 1);

      state.dailyAdjustments = newItems;
    },
    clearDailyAdjustemnt: (state) => {
      state.dailyAdjustments = [];
    },
    clearDailyAdjustmensForReview: (state) => {
      state.forReview.data = [];
    },
    clearState: (state) => {
      state = initialState;
    },
  },
  extraReducers(builder) {
    builder
      // setItemDailyAdjustments
      .addCase(setItemDailyAdjustments.pending, (state) => {
        state.status = "loading";
      })
      .addCase(setItemDailyAdjustments.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (action.payload && state.dailyAdjustments) {
          if (action.payload.success) {
            let index = state.dailyAdjustments?.findIndex(
              (p) => p.sku === action.payload.sku
            );
            if (index >= 0) {
              state.dailyAdjustments.splice(index, 1);
            } else {
              state.status = "failed";
            }
          } else {
            state.status = "failed";
          }
        }
      })
      .addCase(setItemDailyAdjustments.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ?? null;
      })

      // getAdjustmentsByKitchenForReview
      .addCase(getAdjustmentsByKitchenForReview.pending, (state) => {
        state.forReview = {
          status: "loading",
          error: null,
          data: [],
        };
        state.forValidation = {
          status: "loading",
          error: null,
          data: [],
        };
      })
      .addCase(getAdjustmentsByKitchenForReview.fulfilled, (state, action) => {
        const adjustments = action.payload.map((el) => {
          return { ...el, createdAt: el.createdAt.toString() };
        });
        state.forReview = {
          status: "succeeded",
          error: null,
          data: adjustments.filter((el) =>
            [Status.IN_REVIEW, Status.PRE_APPROVED].includes(
              el.status as Status
            )
          ),
        };
        state.forValidation = {
          status: "succeeded",
          error: null,
          data: adjustments.filter((el) =>
            [Status.IN_VALIDATION].includes(el.status as Status)
          ),
        };
      })
      .addCase(getAdjustmentsByKitchenForReview.rejected, (state, action) => {
        state.forReview = {
          status: "failed",
          error: action.error.message ?? null,
          data: [],
        };
        state.forValidation = {
          status: "failed",
          error: action.error.message ?? null,
          data: [],
        };
      })

      // authorizeAdjustments
      .addCase(authorizeAdjustments.pending, (state) => {
        state.authorization = {
          status: "loading",
          error: null,
        };
      })
      .addCase(authorizeAdjustments.fulfilled, (state, action) => {
        const adjustmentsIds = action.meta.arg.adjustments.map((adj) => adj.id);

        state.forReview.data = current(state).forReview.data.filter(
          (adjustment) => !adjustmentsIds.includes(adjustment.id)
        );

        state.authorization = {
          status: "succeeded",
          error: null,
        };
      })
      .addCase(authorizeAdjustments.rejected, (state, action) => {
        state.authorization = {
          status: "failed",
          error: action.error.message ?? null,
        };
      })

      // archiveAdjustments
      .addCase(archiveAdjustments.pending, (state) => {
        state.archiving = {
          status: "loading",
          error: null,
        };
      })
      .addCase(archiveAdjustments.fulfilled, (state, action) => {
        const adjustmentsIds = action.meta.arg.adjustments;

        state.forReview.data = current(state).forReview.data.filter(
          (adjustment) => !adjustmentsIds.includes(adjustment.id)
        );

        state.archiving = {
          status: "succeeded",
          error: null,
        };
      })
      .addCase(archiveAdjustments.rejected, (state, action) => {
        state.archiving = {
          status: "failed",
          error: action.error.message ?? null,
        };
      })
      // DailyAdjustmentReport Transfer Order
      .addCase(createDailyAdjustmentReport.pending, (state, action) => {
        state.createDailyReport = {
          status: "loading",
          error: null,
          data: null,
        };
      })
      .addCase(createDailyAdjustmentReport.fulfilled, (state, action) => {
        state.createDailyReport = {
          status: "succeeded",
          error: null,
          data: action.payload,
        };
      })
      .addCase(createDailyAdjustmentReport.rejected, (state, action) => {
        state.createDailyReport = {
          status: "failed",
          error: action.error.message ?? null,
          data: null,
        };
      });
  },
});

export const {
  toggleGroupFilter,
  setSearch,
  setDailyAdjustments,
  removeDailyAdjustment,
  clearState,
  clearDailyAdjustemnt,
  removeDailyAdjustments,
  clearDailyAdjustmensForReview,
} = dailyAdjustmentsSlice.actions;

export const { reducer } = dailyAdjustmentsSlice;

export default dailyAdjustmentsSlice;
