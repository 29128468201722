import { UseCase } from "core/common/interfaces/useCase";
import { ProductionPayload } from "../entities/Productions";
import { canCreateProduction } from "../interfaces/canCreateProduction";

export class CreateProduction implements UseCase {
  constructor(private readonly repository: canCreateProduction) {}

  public execute(production: ProductionPayload) {
    return this.repository.createProduction(production);
  }
}
