import { UseCase } from "core/common/interfaces/useCase";
import {
  CanExistsLabelLog,
  CanRemoveLabelLog,
  CanScannedItemReceipt,
} from "core/linesPurchaseOrders/traits/linesPurchaseOrder/canScannedItemReceipt";

export class ScannedItemReceiptUseCase implements UseCase {
  constructor(private readonly repository: CanScannedItemReceipt) {}

  public execute(orderId: string, productionLabelId: string) {
    return this.repository.scannedItemReceipt(orderId, productionLabelId);
  }
}

export class ExistsLabelLogUseCase implements UseCase {
  constructor(private readonly repository: CanExistsLabelLog) {}

  public execute(orderId: string, originId: string, productionLabelId: string) {
    return this.repository.existsLabelLog(orderId, originId, productionLabelId);
  }
}

export class RemoveLabelLogUseCase implements UseCase {
  constructor(private readonly repository: CanRemoveLabelLog) {}

  public execute(deleteLabelsIds: number[], originId: string) {
    return this.repository.removeLabelLog(deleteLabelsIds, originId);
  }
}
