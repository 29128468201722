import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Grid } from "@mui/material";
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { commons, purchaseOrderCreation } from "app/i18n/types";
import {
  NewIndividualPurchaseOrderLine,
  PurchaseOrder,
} from "core/purchaseOrders/entities/PurchaseOrder";
import { Supply } from "core/supplies/entities/Supply";
import { VendorSupply } from "core/vendors/entities/VendorSupply";
import { FunctionComponent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import VendorSupplyPriceRangeAlert from "../Vendor/Supply/PriceRange/Alert";
import VendorSupplyPriceRangeInPOValidator from "../Vendor/Supply/PriceRange/PurchaseOrder/Validator";
import {
  ValidationItemResponse,
  newPurchaseOrderLineToSupplyToValidate,
} from "../Vendor/Supply/PriceRange/utils";
import NumberField from "../common/Field/Number";
import { ManagerDialog } from "../common/Manager/Dialog";
import { ManagerDialogFooter } from "../common/Manager/Dialog/Footer";
import PurchaseOrderInfoHeader from "./PurchaseOrderInfoHeader";

interface AddLineModalProps {
  purchaseOrder: PurchaseOrder;
  supplies: Supply[];
  vendorSupplies: VendorSupply[];
  onClose: () => void;
  handleAddLine: (newPurchaseOrderLine: NewIndividualPurchaseOrderLine) => void;
}

const AddLineModal: FunctionComponent<AddLineModalProps> = (props) => {
  const { purchaseOrder, supplies, vendorSupplies, onClose, handleAddLine } =
    props;

  const { t } = useTranslation();
  const [selectedSupply, setSelectedSupply] = useState<Supply>();
  const [selectedVendorSupply, setSelectedVendorSupply] =
    useState<VendorSupply>();
  const [warns, setWarns] = useState<ValidationItemResponse[]>([]);

  const [newPurchaseOrderLine, setNewPurchaseOrderLine] =
    useState<NewIndividualPurchaseOrderLine>({
      purchaseOrderId: purchaseOrder.id,
      measureUnit: "",
      quantity: 0,
      sku: "",
      productName: "",
      unitPrice: 0,
    });

  const changeSelectedSupply = useCallback(
    (supply?: Supply) => {
      setSelectedSupply(supply);
      if (!supply) return;
      setNewPurchaseOrderLine((newPurchaseOrderLine) => {
        return {
          ...newPurchaseOrderLine,
          sku: supply.sku,
          productName: supply.name,
          measureUnit: supply.unit,
          unitPrice: 0,
          quantity: 0,
        };
      });
    },
    [setSelectedSupply, setNewPurchaseOrderLine]
  );

  const changeSelectedVendorSupply = useCallback(
    (supply?: VendorSupply) => {
      setSelectedVendorSupply(supply);
      console.log(supply);
      if (!supply) return;
      setNewPurchaseOrderLine((newPurchaseOrderLine) => {
        return {
          ...newPurchaseOrderLine,
          sku: supply.supplySku,
          productName: supply.supplyName,
          measureUnit: supply.supplyUnit,
          unitPrice: supply.supplyUnitPrice,
          quantity: 0,
          vendorSupplyId: supply.id,
        };
      });
    },
    [setSelectedVendorSupply, setNewPurchaseOrderLine]
  );

  const excludeSuppliesSKUs = purchaseOrder.lines.map((el) => el.sku);

  return (
    <ManagerDialog
      title={purchaseOrderCreation.ADD_LINE_MODAL_TITLE}
      onClose={onClose}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PurchaseOrderInfoHeader purchaseOrder={purchaseOrder} />
          </Grid>
          <Grid item xs={12}>
            <Alert severity="info">
              {t(purchaseOrderCreation.ADD_LINE_MODAL_INFO)}
            </Alert>
          </Grid>
          <Grid item xs={12}>
            {!!vendorSupplies.length && (
              <Autocomplete
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t(commons.SUPPLY)}
                    sx={{ bgcolor: "white" }}
                  />
                )}
                value={selectedVendorSupply}
                options={vendorSupplies}
                getOptionDisabled={(option) =>
                  !!excludeSuppliesSKUs.includes(option.supplySku)
                }
                getOptionLabel={(option) =>
                  `${option.supplySku} - ${option.supplyName} (${t(
                    commons.TYPE
                  )} ${option.vendorType})`
                }
                onChange={(_, value) =>
                  changeSelectedVendorSupply(value ?? undefined)
                }
              />
            )}
            {!vendorSupplies.length && (
              <Autocomplete
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t(commons.SUPPLY)}
                    sx={{ bgcolor: "white" }}
                  />
                )}
                value={selectedSupply}
                options={supplies}
                getOptionDisabled={(option) =>
                  !!excludeSuppliesSKUs.includes(option.sku)
                }
                getOptionLabel={(option) => `${option.sku} - ${option.name}`}
                onChange={(_, value) =>
                  changeSelectedSupply(value ?? undefined)
                }
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <NumberField
              value={newPurchaseOrderLine.quantity}
              label={t(commons.QUANTITY)}
              endAdornment={<Typography>{selectedSupply?.unit}</Typography>}
              onChangeValue={(newValue) =>
                setNewPurchaseOrderLine({
                  ...newPurchaseOrderLine,
                  quantity: newValue,
                })
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <NumberField
              value={newPurchaseOrderLine.unitPrice}
              label={t(commons.UNIT_PRICE)}
              onChangeValue={(newValue) =>
                setNewPurchaseOrderLine({
                  ...newPurchaseOrderLine,
                  unitPrice: newValue,
                })
              }
              fullWidth
              endAdornment={
                <>
                  {!!warns.length && (
                    <ErrorOutlineOutlinedIcon color="warning" />
                  )}
                </>
              }
            />
          </Grid>
          {!!vendorSupplies.length && (
            <Grid item xs={12}>
              <VendorSupplyPriceRangeInPOValidator
                kitchenId={purchaseOrder.locationCode}
                vendorId={Number(purchaseOrder.vendorCode)}
                vendorSupplies={vendorSupplies}
                supplyToValidate={newPurchaseOrderLineToSupplyToValidate(
                  newPurchaseOrderLine
                )}
                onValidation={setWarns}
              />
              <VendorSupplyPriceRangeAlert
                warnMessages={warns.map((el) => el.shortMessage)}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <ManagerDialogFooter
              mainButton={{
                children: t(commons.ADD),
                onClick: () => handleAddLine(newPurchaseOrderLine),
                disabled:
                  !newPurchaseOrderLine.sku ||
                  !newPurchaseOrderLine.quantity ||
                  !newPurchaseOrderLine.unitPrice,
              }}
              onCancel={onClose}
              loading={false}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

export default AddLineModal;
