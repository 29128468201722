/* eslint-disable react/require-default-props */
import React from "react";

interface IconProp {
  fontSize?: string;
}

const SchedulePhysicalCountingIcon = (props: IconProp): React.ReactElement => {
  const { fontSize } = props;
  return (
    <svg
      width={fontSize ?? 40}
      height={fontSize ?? 40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width={fontSize ?? 40}
        height={fontSize ?? 40}
        rx="20"
        fill="#F1EFEF"
      />
      <mask
        id="mask0_5932_371"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="24"
        height="24"
      >
        <rect x="8" y="8" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5932_371)">
        <path
          d="M27.675 28.375L28.375 27.675L26.5 25.8V23H25.5V26.2L27.675 28.375ZM13 29C12.45 29 11.9792 28.8042 11.5875 28.4125C11.1958 28.0208 11 27.55 11 27V13C11 12.45 11.1958 11.9792 11.5875 11.5875C11.9792 11.1958 12.45 11 13 11H27C27.55 11 28.0208 11.1958 28.4125 11.5875C28.8042 11.9792 29 12.45 29 13V19.7C28.6833 19.55 28.3583 19.4208 28.025 19.3125C27.6917 19.2042 27.35 19.125 27 19.075V13H13V27H19.05C19.1 27.3667 19.1792 27.7167 19.2875 28.05C19.3958 28.3833 19.525 28.7 19.675 29H13ZM13 27V13V19.075V19V27ZM15 25H19.075C19.125 24.65 19.2042 24.3083 19.3125 23.975C19.4208 23.6417 19.5417 23.3167 19.675 23H15V25ZM15 21H21.1C21.6333 20.5 22.2292 20.0833 22.8875 19.75C23.5458 19.4167 24.25 19.1917 25 19.075V19H15V21ZM15 17H25V15H15V17ZM26 31C24.6167 31 23.4375 30.5125 22.4625 29.5375C21.4875 28.5625 21 27.3833 21 26C21 24.6167 21.4875 23.4375 22.4625 22.4625C23.4375 21.4875 24.6167 21 26 21C27.3833 21 28.5625 21.4875 29.5375 22.4625C30.5125 23.4375 31 24.6167 31 26C31 27.3833 30.5125 28.5625 29.5375 29.5375C28.5625 30.5125 27.3833 31 26 31Z"
          fill="#232323"
        />
      </g>
    </svg>
  );
};

export default SchedulePhysicalCountingIcon;
