import { createAsyncThunk } from "@reduxjs/toolkit";
import appConfig from "config/app";
import { Country } from "core/common/entities/Country";
import { ProductHTTPRepository } from "core/physicalCount/repositories/http/product";
import { SetProductActivenUseCase } from "core/physicalCount/useCases/product/setProductActiveUseCase";
import {
  Supply,
  SupplyInactiveKitchen,
  UpdateSupplyStatusPayload,
} from "core/supplies/entities/Supply";
import { KitchenHTTPRepository } from "core/supplies/repositories/http/kitchen";
import { SupplyHTTPRepository } from "core/supplies/repositories/http/supply";
import {
  GetListActiveKitchensUseCase,
  GetListKitchensUseCase,
} from "core/supplies/useCases/kitchen/getListKitchensUseCase";
import { GetInactiveSuppliesByLocationUseCase } from "core/supplies/useCases/supply/getInactiveSuppliesByLocation";
import { GetSuppliesByCountryUseCase } from "core/supplies/useCases/supply/getSuppliesByCountry";
import { GetSupplyInactiveStatusForKitchensUseCase } from "core/supplies/useCases/supply/getSupplyInactiveStatusForKitchens";
import { SetSupliesStateByKitchensUseCase } from "core/supplies/useCases/supply/setSupliesStateByKitchens";

export const getKitchens = createAsyncThunk(
  "supplies/getKitchens",
  async () => {
    const httpRepository = new KitchenHTTPRepository(appConfig);
    const useCase = new GetListKitchensUseCase(httpRepository);
    const kitchens = await useCase.execute();
    return { kitchens };
  }
);

export const getActiveKitchens = createAsyncThunk(
  "supplies/getActiveKitchens",
  async () => {
    const httpRepository = new KitchenHTTPRepository(appConfig);
    const useCase = new GetListActiveKitchensUseCase(httpRepository);
    const kitchens = await useCase.execute();
    return { kitchens };
  }
);

export const setProductActive = createAsyncThunk(
  "supplies/setProductActive",
  async (payload: {
    sku: string;
    kitchenId: string;
    active: boolean;
  }): Promise<boolean> => {
    const httpRepository = new ProductHTTPRepository(appConfig);
    const setProductInactivenUseCase = new SetProductActivenUseCase(
      httpRepository
    );

    const setProductInactivenResult = await setProductInactivenUseCase.execute(
      payload.sku,
      payload.kitchenId,
      payload.active
    );

    return setProductInactivenResult;
  }
);

export const getSuppliesByCountry = createAsyncThunk(
  "supplies/getSuppliesByCountry",
  async (payload: Country): Promise<Array<Supply>> => {
    const httpRepository = new SupplyHTTPRepository(appConfig);
    const getSuppliesByKitchenUseCase = new GetSuppliesByCountryUseCase(
      httpRepository
    );

    const setProductInactivenResult = await getSuppliesByKitchenUseCase.execute(
      payload
    );

    return setProductInactivenResult;
  }
);

export const setSupliesStateByKitchens = createAsyncThunk(
  "supplies/setSupliesStateByKitchens",
  async (payload: Array<UpdateSupplyStatusPayload>): Promise<boolean> => {
    const httpRepository = new SupplyHTTPRepository(appConfig);
    const setSupliesStateByKitchensUseCase =
      new SetSupliesStateByKitchensUseCase(httpRepository);

    const setProductInactivenResult =
      await setSupliesStateByKitchensUseCase.execute(payload);

    return setProductInactivenResult;
  }
);

export const getSupplyInactiveKitchens = createAsyncThunk(
  "supplies/canGetSupplyInactiveStatusForKitchens",
  async (sku: string): Promise<Array<SupplyInactiveKitchen>> => {
    const httpRepository = new SupplyHTTPRepository(appConfig);
    const getSupplyInactiveStatusForKitchensUseCase =
      new GetSupplyInactiveStatusForKitchensUseCase(httpRepository);

    const setProductInactivenResult =
      await getSupplyInactiveStatusForKitchensUseCase.execute(sku);

    return setProductInactivenResult;
  }
);

export const getInactiveSuppliesByLocation = createAsyncThunk(
  "supplies/getInactiveSuppliesByLocation",
  async (locationId: string): Promise<Array<SupplyInactiveKitchen>> => {
    const httpRepository = new SupplyHTTPRepository(appConfig);
    const getInactiveSuppliesByLocationUseCase =
      new GetInactiveSuppliesByLocationUseCase(httpRepository);

    const inactiveProductsByLocation =
      await getInactiveSuppliesByLocationUseCase.execute(locationId);

    return inactiveProductsByLocation;
  }
);
