import { UseCase } from "core/common/interfaces/useCase";
import { CanSetProduct } from "core/physicalCount/interfaces/counting/canSetProductCount";

export class SetProductCountUseCase implements UseCase {
  constructor(private readonly repository: CanSetProduct) {}

  public execute(
    countingId: number,
    sku: string,
    unit: string,
    quantity: number,
    comment: string
  ) {
    return this.repository.setProductCount(
      countingId,
      sku,
      unit,
      quantity,
      comment
    );
  }
}
