export const groupByKey = <T>(arr: T[], fn: (item: T) => any) => {
  return arr.reduce<Record<string, T[]>>((prev, curr) => {
    const groupKey = fn(curr);
    const group = prev[groupKey] || [];
    group.push(curr);
    return { ...prev, [groupKey]: group };
  }, {});
};

export const arrayComparator = (array1: any[], array2: any[]): boolean => {
  return (
    JSON.stringify([...array1].sort()) !== JSON.stringify([...array2].sort())
  );
};
