import { createAsyncThunk } from "@reduxjs/toolkit";
import { PaginationParams } from "app/utils/pagination";
import appConfig from "config/app";
import { ProductionScannedtResponse } from "core/productions/entities/Productions";
import {
  ArchiveLinesPayload,
  ExtraItemRequest,
  ReportTransferOrderPayload,
  TransferOrder,
  UpdateLineQuantityAndReason,
} from "core/transfer/entities/TransferOrder";
import { TransferHTTPRepository } from "core/transfer/repositories/http/transfer";
import { GetPickingLinesByIdsUseCase } from "core/transfer/useCases/transfer/GetPickingLinesByIdsUseCase";
import { ArchiveLinesUseCase } from "core/transfer/useCases/transfer/canArchiveLinesUseCase";
import { ArchiveTransferOrderUseCase } from "core/transfer/useCases/transfer/canArchiveTransferOrderUseCase";
import { CanGetPackedTransferOrdersUseCase } from "core/transfer/useCases/transfer/canGetPackedTransferOrdersUseCase";
import { CanReceiveTransferOrderUseCase } from "core/transfer/useCases/transfer/canReceiveTransferOrderUseCase";
import { ReportTransferOrderUseCase } from "core/transfer/useCases/transfer/canReportTransferOrderUseCase";
import { CreateTransferOrderUseCase } from "core/transfer/useCases/transfer/createTransferOrderUseCase";
import { ExistsLabelLogUseCase } from "core/transfer/useCases/transfer/existsLabelLogUseCase";
import { GetMadeTransferOrdersByLocationIdUseCase } from "core/transfer/useCases/transfer/getMadeTransferOrdersByKitchenUseCase";
import { GetPendingTransferOrdersByLocationIdUseCase } from "core/transfer/useCases/transfer/getPendingTransferOrdersByKitchenUseCase";
import { GetTransferOrderPackingLogsUseCase } from "core/transfer/useCases/transfer/getTransferOrderPackingLogsUseCase";
import {
  GetTransferOrderPickingItemsUseCase,
  GetTransferOrderPickingOrdersUseCase,
} from "core/transfer/useCases/transfer/getTransferOrderPickingItemsUseCase";
import { GetTransferOrdersByKitchenIdUseCase } from "core/transfer/useCases/transfer/getTransferOrdersByKitchenIdUseCase ";
import { RemoveLabelLogUseCase } from "core/transfer/useCases/transfer/removeLabelLogUseCase";
import { SaveExtraItemsUseCase } from "core/transfer/useCases/transfer/saveExtraItemsUseCase";
import { SaveScannedTransferUseCase } from "core/transfer/useCases/transfer/saveScannedTransferUseCase";
import {
  CompleteTransfersByKitchenUseCase,
  SetTransferOrderAsPackedUseCase,
} from "core/transfer/useCases/transfer/setTransferOrderAsPackedUseCase";
import { SetTransferOrderAsSendUseCase } from "core/transfer/useCases/transfer/setTransferOrderAsSendUseCase";
import { UpdateTransferOrderLineQuantityUseCase } from "core/transfer/useCases/transfer/updateTransferOrderLineQuantityUseCase";
import { CanGetTransferOrderUseCase } from "../../../../core/transfer/useCases/transfer/canGetTransferOrderUseCase";

export const createTransferOrder = createAsyncThunk(
  "transfer/createTransferOrder",
  async (payload: {
    locationId: string;
    transferOrders: Array<TransferOrder>;
  }) => {
    const httpRepository = new TransferHTTPRepository(appConfig);

    const createTransferOrderUseCase = new CreateTransferOrderUseCase(
      httpRepository
    );

    const createTransferOrder = await createTransferOrderUseCase.execute(
      payload.locationId,
      payload.transferOrders
    );

    return createTransferOrder;
  }
);

export const createSingleTransferOrder = createAsyncThunk(
  "transfer/createSingleTransferOrder",
  async (payload: { locationId: string; transferOrder: TransferOrder }) => {
    const httpRepository = new TransferHTTPRepository(appConfig);

    const createTransferOrderUseCase = new CreateTransferOrderUseCase(
      httpRepository
    );

    const createSingleTransferOrder = await createTransferOrderUseCase.execute(
      payload.locationId,
      new Array(payload.transferOrder)
    );

    return createSingleTransferOrder;
  }
);

export const getPendingTransferOrdersByLocationId = createAsyncThunk(
  "transfer/getPendingTransferOrdersByLocationId",
  async (payload: string) => {
    const httpRepository = new TransferHTTPRepository(appConfig);

    const getPendingTransferOrderUseCase =
      new GetPendingTransferOrdersByLocationIdUseCase(httpRepository);

    const pendingTransferOrders = await getPendingTransferOrderUseCase.execute(
      payload
    );

    return pendingTransferOrders;
  }
);

export const getTransferOrdersByKitchenId = createAsyncThunk(
  "transfer/getTransferOrdersByKitchenId",
  async (payload: { kitchenId: string; params: PaginationParams }) => {
    const httpRepository = new TransferHTTPRepository(appConfig);

    const getTransferOrderUseCase = new GetTransferOrdersByKitchenIdUseCase(
      httpRepository
    );

    const transferOrders = await getTransferOrderUseCase.execute(
      payload.kitchenId,
      payload.params
    );

    return transferOrders;
  }
);

export const getMadeTransferOrdersByLocationId = createAsyncThunk(
  "transfer/getMadeTransferOrdersByLocationId",
  async (payload: { locationId: string; params: PaginationParams }) => {
    const httpRepository = new TransferHTTPRepository(appConfig);

    const getMadeTransferOrderUseCase =
      new GetMadeTransferOrdersByLocationIdUseCase(httpRepository);

    const pendingTransferOrders = await getMadeTransferOrderUseCase.execute(
      payload.locationId,
      payload.params
    );

    return pendingTransferOrders;
  }
);

export const reportTransferOrder = createAsyncThunk(
  "transfer/reportTransferOrder",
  async (payload: ReportTransferOrderPayload) => {
    const httpRepository = new TransferHTTPRepository(appConfig);

    const reportTransferOrderUseCase = new ReportTransferOrderUseCase(
      httpRepository
    );

    const reportTransferOrder = await reportTransferOrderUseCase.execute(
      payload
    );

    return reportTransferOrder;
  }
);

export const archiveLines = createAsyncThunk(
  "transfer/archiveLines",
  async (payload: ArchiveLinesPayload) => {
    const httpRepository = new TransferHTTPRepository(appConfig);

    const archiveLinesUseCase = new ArchiveLinesUseCase(httpRepository);

    const archiveLines = await archiveLinesUseCase.execute(payload);

    return archiveLines;
  }
);

export const updateTransferOrderLineQuantity = createAsyncThunk(
  "transfer/updateTranferOrderLineQuantity",
  async (payload: {
    transferLineId: number;
    updateQuantityAndReason: UpdateLineQuantityAndReason;
  }) => {
    return await updateLineQuantity(
      payload.transferLineId,
      payload.updateQuantityAndReason
    );
  }
);

export const updateTransferOrderPackedLineQuantity = createAsyncThunk(
  "transfer/updateTranferOrderPackedLineQuantity",
  async (payload: {
    transferLineId: number;
    updateQuantityAndReason: UpdateLineQuantityAndReason;
  }) => {
    return await updateLineQuantity(
      payload.transferLineId,
      payload.updateQuantityAndReason
    );
  }
);

export const getTransferOrderPickingItems = createAsyncThunk(
  "transfer/getTransferOrderPickingItems",
  async (payload: { locationId: string }) => {
    const httpRepository = new TransferHTTPRepository(appConfig);

    const getTransferOrderPickingItemsUseCase =
      new GetTransferOrderPickingItemsUseCase(httpRepository);

    const transferOrderPickingItems =
      await getTransferOrderPickingItemsUseCase.execute(payload.locationId);

    return transferOrderPickingItems;
  }
);

export const getTransferOrderPickingOrders = createAsyncThunk(
  "transfer-orders/picking-by-kitchen",
  async (payload: { locationId: string }) => {
    const httpRepository = new TransferHTTPRepository(appConfig);

    const useCase = new GetTransferOrderPickingOrdersUseCase(httpRepository);

    const response = await useCase.execute(payload.locationId);

    return response;
  }
);

export const pickingLinesByIds = createAsyncThunk(
  "transfer/pickingLinesByIds",
  async (payload: { lineIds: number[] }) => {
    const httpRepository = new TransferHTTPRepository(appConfig);
    const useCase = new GetPickingLinesByIdsUseCase(httpRepository);
    const pickingLinesResponse = await useCase.execute(payload.lineIds);
    return pickingLinesResponse;
  }
);

export const getTransferOrderPackingLogs = createAsyncThunk(
  "transfer/getTransferOrderPackingLogs",
  async (payload: { sku: string; transferId: number }) => {
    const httpRepository = new TransferHTTPRepository(appConfig);
    const useCase = new GetTransferOrderPackingLogsUseCase(httpRepository);
    const result = await useCase.execute(payload.sku, payload.transferId);
    return result;
  }
);

export const setTransferOrderAsPacked = createAsyncThunk(
  "transfer/setTransferOrderAsPacked",
  async (locationId: string) => {
    const httpRepository = new TransferHTTPRepository(appConfig);
    const setTransferOrderAsPackedUseCase = new SetTransferOrderAsPackedUseCase(
      httpRepository
    );

    const setTransferOrderAsPacked =
      await setTransferOrderAsPackedUseCase.execute(locationId);

    return setTransferOrderAsPacked;
  }
);

export const completeTransfersByKitchen = createAsyncThunk(
  "transfer/completeTransfersByKitchen",
  async (payload: { transferId: number }) => {
    const httpRepository = new TransferHTTPRepository(appConfig);
    const completeTransfers = new CompleteTransfersByKitchenUseCase(
      httpRepository
    );

    const completeTransfersByKitchen = await completeTransfers.execute(
      payload.transferId
    );

    return completeTransfersByKitchen;
  }
);

export const setTransferOrderAsSend = createAsyncThunk(
  "transfer/setTransferOrderAsSend",
  async (payload: { transferId: number; numberOfBaskets: number }) => {
    const httpRepository = new TransferHTTPRepository(appConfig);

    const setTransferOrderAsSendUseCase = new SetTransferOrderAsSendUseCase(
      httpRepository
    );

    const setTransferOrderAsSend = await setTransferOrderAsSendUseCase.execute(
      payload.transferId,
      payload.numberOfBaskets
    );

    return setTransferOrderAsSend;
  }
);

export const getPackedTransferOrders = createAsyncThunk(
  "trasnfer/getPackedTransferOrders",
  async (locationId: string) => {
    const httpRepository = new TransferHTTPRepository(appConfig);

    const canGetPackedTransferOrdersUseCase =
      new CanGetPackedTransferOrdersUseCase(httpRepository);

    const getPackedTransferOrders =
      await canGetPackedTransferOrdersUseCase.execute(locationId);

    return getPackedTransferOrders;
  }
);

export const getTransferOrderById = createAsyncThunk(
  "trasnfer/getTransferOrderById",
  async (payload: { transferId: string }) => {
    const httpRepository = new TransferHTTPRepository(appConfig);

    const canGetTransferOrdersUseCase = new CanGetTransferOrderUseCase(
      httpRepository
    );

    const transferOrder = await canGetTransferOrdersUseCase.execute(
      payload.transferId
    );

    return transferOrder;
  }
);

export const saveExtraItems = createAsyncThunk(
  "transfer/saveExtraItems",
  async (extraItemRequest: ExtraItemRequest) => {
    const httpRepository = new TransferHTTPRepository(appConfig);

    const saveExtraItemsUseCase = new SaveExtraItemsUseCase(httpRepository);

    const saveExtraItems = await saveExtraItemsUseCase.execute(
      extraItemRequest
    );

    return saveExtraItems;
  }
);

export const archiveTransferOrder = createAsyncThunk(
  "transfer/archiveTransferOrder",
  async (payload: { transferOrderId: number }) => {
    const httpRepository = new TransferHTTPRepository(appConfig);
    const archiveTransferOrderUseCase = new ArchiveTransferOrderUseCase(
      httpRepository
    );
    const updateTrasferOrder = await archiveTransferOrderUseCase.execute(
      payload.transferOrderId
    );
    return updateTrasferOrder;
  }
);

export const saveScannedTransfer = createAsyncThunk(
  "transfer/saveScannedTransfer",
  async (payload: {
    transferOrderId: number;
    productionLabelId: string;
  }): Promise<ProductionScannedtResponse> => {
    const httpRepository = new TransferHTTPRepository(appConfig);
    const useCase = new SaveScannedTransferUseCase(httpRepository);
    const useCaseResult = await useCase.execute(
      payload.transferOrderId,
      payload.productionLabelId
    );
    return useCaseResult;
  }
);

export const existsLabelLog = createAsyncThunk(
  "transfer/existsLabelLog",
  async (payload: {
    originId: string;
    productionLabelId: string;
  }): Promise<ProductionScannedtResponse> => {
    const httpRepository = new TransferHTTPRepository(appConfig);
    const useCase = new ExistsLabelLogUseCase(httpRepository);
    const useCaseResult = await useCase.execute(
      payload.originId,
      payload.productionLabelId
    );
    return useCaseResult;
  }
);

export const removeLabelLog = createAsyncThunk(
  "transfer/removeLabelLog",
  async (payload: {
    deleteLabelsIds: number[];
    originId: string;
  }): Promise<boolean> => {
    const httpRepository = new TransferHTTPRepository(appConfig);
    const useCase = new RemoveLabelLogUseCase(httpRepository);
    const useCaseResult = await useCase.execute(
      payload.deleteLabelsIds,
      payload.originId
    );
    return useCaseResult;
  }
);

export const receiveTransferOrder = createAsyncThunk(
  "transfer/receiveTransferOrder",
  async (payload: {
    transferOrderId: number;
    haveExtraOrLeftover: boolean;
    comment?: string;
  }) => {
    const httpRepository = new TransferHTTPRepository(appConfig);
    const canReceiveTransfer = new CanReceiveTransferOrderUseCase(
      httpRepository
    );
    const receiveResponse = await canReceiveTransfer.execute(
      payload.transferOrderId,
      payload.haveExtraOrLeftover,
      payload.comment
    );
    return receiveResponse;
  }
);

async function updateLineQuantity(
  transferLineId: number,
  payload: UpdateLineQuantityAndReason
) {
  const httpRepository = new TransferHTTPRepository(appConfig);

  const updateLineQuantityUseCase = new UpdateTransferOrderLineQuantityUseCase(
    httpRepository
  );

  const updateLineQuantity = await updateLineQuantityUseCase.execute(
    transferLineId,
    payload
  );

  return updateLineQuantity;
}
