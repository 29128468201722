import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { LinearProgress } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface CustomDialogTitleProps {
  title: string;
  status: "idle" | "loading" | "succeeded" | "failed";
  onClose: () => void;
}

const CustomDialogTitle: FunctionComponent<CustomDialogTitleProps> = (
  props
) => {
  const { title, status, onClose } = props;

  const { t } = useTranslation();

  return (
    <>
      <DialogTitle color="primary.main">
        {t(title)}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseOutlinedIcon />
        </IconButton>
      </DialogTitle>

      {status === "loading" && <LinearProgress />}
    </>
  );
};

export default CustomDialogTitle;
