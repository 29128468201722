import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { forwardRef } from "react";

import Divider from "@mui/material/Divider";
import { commons, measureUnits } from "app/i18n/types";
import { TransferResponse } from "core/transfer/entities/TransferOrder";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { DividerPrint, print80mm } from "utils/generalUI";

interface Props {
  transfer: TransferResponse;
}

const TransferSuppliesPrintTemplate = forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { transfer } = props;

    const { t } = useTranslation();

    return (
      <Box sx={print80mm} ref={ref}>
        <Box className="printable">
          <Box sx={{ textAlign: "center", mt: 2, mb: 1 }}>
            <img
              src="https://images-kos.s3.amazonaws.com/logo_Foodology/logo-foodology.png"
              alt="Foodology SAS1"
              width="50%"
            />
          </Box>
          <Box sx={{ textAlign: "center", textTransform: "uppercase" }}>
            <Typography textAlign="center" variant="caption" display="block">
              {transfer.originKitchenName}
            </Typography>

            <Typography
              textAlign="center"
              variant="caption"
              sx={{ mb: 1 }}
              display="block"
            >
              {transfer.externalId}
            </Typography>

            <Typography
              textAlign="center"
              textTransform="uppercase"
              fontWeight={700}
              variant="caption"
              display="block"
            >
              {t(commons.DATE).toUpperCase()}: {dayjs().format("DD/MM/YYYY")}
            </Typography>

            <DividerPrint />

            {transfer.lines.map((transferLine) => (
              <Box
                key={transferLine.id}
                display="flex"
                gap={1}
                alignItems="center"
                mb={1}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-around"
                >
                  <Typography
                    textTransform="uppercase"
                    textAlign="left"
                    variant="caption"
                  >
                    {t(commons.SKU).toUpperCase()}:{" "}
                    <strong>{transferLine.sku}</strong>
                  </Typography>
                  <Typography
                    textTransform="uppercase"
                    textAlign="left"
                    variant="caption"
                  >
                    {transferLine.name}
                  </Typography>
                </Box>
                <Divider sx={{ my: 1, flexGrow: 1, borderStyle: "dotted" }} />
                <Box
                  flexBasis={80}
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-around"
                  alignItems="center"
                  borderRadius={1}
                  p={0.25}
                >
                  <Typography
                    textTransform="uppercase"
                    fontWeight={700}
                    variant="caption"
                  >
                    {`${transferLine.sendQuantity}  ${t(
                      measureUnits[
                        transferLine.measureUnit as keyof typeof measureUnits
                      ]
                    )}`}
                  </Typography>

                  <Box
                    sx={{
                      borderBottom: "1px solid black",
                      height: 15,
                      width: 60,
                    }}
                  />
                </Box>
              </Box>
            ))}

            <Divider
              sx={{ mt: 1, mb: 2, borderStyle: "dashed", borderWidth: 2 }}
            />
          </Box>
        </Box>
      </Box>
    );
  }
);

export default TransferSuppliesPrintTemplate;
