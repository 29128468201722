import { KOSEmptyState } from "@foodology-co/alejandria";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AdjustmentRecord } from "core/dailyAdjustments/entities/Adjustment";
import { OriginType } from "core/purchaseOrders/entities/PurchaseOrder";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { commons } from "../../i18n/types";
import DocumentsViewer from "../DocumentViewer";
import DailyAdjustmentRow from "./DailyAdjustmentRow";

interface Props {
  items: AdjustmentRecord[];
}

const DailyAdjustmentTable: FunctionComponent<Props> = (props) => {
  const { items } = props;
  const { t } = useTranslation();

  const [adjustment, setAdjustment] = React.useState<AdjustmentRecord | null>(
    null
  );

  const [openDocumentList, setOpenDocumentList] = React.useState(false);

  const handleOpenDocumentList = (adjustment: AdjustmentRecord) => {
    setAdjustment(adjustment);
    setOpenDocumentList(true);
  };

  return (
    <Box width="100%" sx={{ textAlign: "-webkit-center" }}>
      <Box width="100%" sx={{ textAlign: "-webkit-center" }}>
        {items.length === 0 && (
          <Box
            sx={{
              width: "100%",
              mx: "auto",
              my: 2,
              textAlign: "-webkit-center",
            }}
          >
            <KOSEmptyState
              icon={InfoOutlinedIcon}
              instruction={t(commons.TABLE_EMPTY)}
            />
          </Box>
        )}
        {items.length > 0 && (
          <TableContainer
            style={{ height: "100%", width: "100%" }}
            component={Paper}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "10%" }} align="center">
                    {t(commons.SKU)}
                  </TableCell>
                  <TableCell sx={{ width: "20%" }} align="center">
                    {t(commons.NAME).toUpperCase()}
                  </TableCell>
                  <TableCell sx={{ width: "15%" }} align="center">
                    {t(commons.QUANTITY).toUpperCase()}
                  </TableCell>
                  <TableCell sx={{ width: "15%" }} align="center">
                    {t(commons.UNIT).toUpperCase()}
                  </TableCell>
                  <TableCell sx={{ width: "20%" }} align="center">
                    {t(commons.REASON).toUpperCase()}
                  </TableCell>
                  <TableCell align="center">{t(commons.DATETIME)}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item) => (
                  <DailyAdjustmentRow
                    key={item.id}
                    item={item}
                    handleOpenDocumentList={() => handleOpenDocumentList(item)}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {openDocumentList && (
          <DocumentsViewer
            sourceId={adjustment?.id.toString() ?? ""}
            origin={OriginType.KIS}
            type={adjustment?.typeLoss ?? ""}
            onClose={() => setOpenDocumentList(false)}
            kitchenId={adjustment?.kitchenId ?? ""}
          />
        )}
      </Box>
    </Box>
  );
};

export default DailyAdjustmentTable;
