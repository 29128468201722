import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import HistoryToggleOffOutlinedIcon from "@mui/icons-material/HistoryToggleOffOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import Grid from "@mui/material/Grid";
import SearchOnTopBar from "app/components/common/SearchOnTopBar";
import CustomTabs from "app/components/common/Tabs";
import PageHeader from "app/components/PageHeader/PageHeader";
import { AddExtraSupplyDialog } from "app/components/Productions/Supply/AddExtra/dialog";
import ProductionSupplyDetailTable from "app/components/Productions/Supply/Detail/Table";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { useNavigator } from "app/hooks/useNavigator";
import { commons, supplyingManagement } from "app/i18n/types";
import { getListProducts } from "app/store/slices/dailyAdjustments/thunks";
import {
  complete,
  getDetail,
} from "app/store/slices/productions/Supply/thunks";
import { Product } from "core/dailyAdjustments/entities/Catalog";
import { SlotEnum } from "core/productions/entities/Plan";
import { ProductionSupplyDetail } from "core/productions/entities/Supply";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { compareSearchText, OnChange, onChangeDefault } from "utils/general";
import { PageBox } from "utils/generalUI";

interface SupplyingDetailPageProps {}

const SupplyingDetailPage: FunctionComponent<SupplyingDetailPageProps> = () => {
  const navigator = useNavigator();
  const { supplyId, kitchenId } = navigator.params();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const products = useAppSelector((state) => state.global.products.data);
  const [suppliesToAdd, setSuppliesToAdd] = useState<Product[]>([]);

  const [tab, setTab] = useState<number>(-1);
  const [tabSlot, setTabSlot] = useState<number>(-1);
  const [supplies, setSupplies] = useState<ProductionSupplyDetail[]>([]);
  const [filteredSupplies, setFilteredSupplies] = useState<
    ProductionSupplyDetail[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [openAddExtraSupply, setOpenAddExtraSupply] = useState<boolean>(false);
  const [change, setChange] = useState<OnChange>(onChangeDefault);

  const getData = async () => {
    setLoading(true);
    if (supplyId) {
      const response = await dispatch(getDetail({ supplyId }));
      const data = response.payload as ProductionSupplyDetail[];
      setSupplies(data);
    }
    setLoading(false);
  };

  const updatePackedQuantity = (id: number, packedQuantity: number) => {
    setChange({ id, label: "packedQuantity", value: packedQuantity });
  };

  useEffect(() => {
    if (change.id) {
      const valueTochange = Number(change.value ?? 0);
      const suppliesTmp = supplies.map((el) => {
        if (el.id === change.id) {
          switch (change.label) {
            case "packedQuantity":
              return { ...el, packedQuantity: valueTochange };
          }
        }
        return el;
      });
      setSupplies(suppliesTmp);
    }
  }, [change]);

  const packagingComplete = async () => {
    const first = supplies.at(0);
    if (first) {
      await dispatch(complete({ productionSupplyId: first.supplyId }));
      navigator.toByLocationType(`/supplying/${first.locationId}`);
    }
  };

  const searchValidator = (el: ProductionSupplyDetail): boolean => {
    if (!search) return true;
    return (
      compareSearchText(el.sku, search) || compareSearchText(el.name, search)
    );
  };

  const slotValidator = (el: ProductionSupplyDetail): boolean => {
    switch (tabSlot) {
      case 1:
        return el.timeSlot === SlotEnum.AM;
      case 2:
        return el.timeSlot === SlotEnum.M;
      case 3:
        return el.timeSlot === SlotEnum.PM;
      default:
        return true;
    }
  };

  useEffect(() => {
    getData();
  }, [supplyId]);

  useEffect(() => {
    setFilteredSupplies(
      supplies.filter((el) => {
        const tabValidator = tab === 0 || (tab === 1 && slotValidator(el));
        return tabValidator && searchValidator(el);
      })
    );
  }, [supplies, tab, tabSlot, search]);

  useEffect(() => {
    if (kitchenId) {
      dispatch(getListProducts({ kitchenId }));
    }
  }, [kitchenId]);

  useEffect(() => {
    setSuppliesToAdd(products.filter((el) => el.group !== "PRODUCCION"));
  }, [products]);

  return (
    <PageBox>
      <SearchOnTopBar onSearch={setSearch} clean={`${tab}-${tabSlot}`} />
      <PageHeader
        title={t(supplyingManagement.MODULE_TITLE)}
        subtitle={t(supplyingManagement.MODULE_DESCRIPTION)}
        rightArea={[
          {
            children: t(commons.UPDATE),
            variant: "outlined",
            startIcon: <RestartAltOutlinedIcon />,
            onClick: () => getData(),
            disabled: loading,
          },
          {
            children: t(supplyingManagement.ADD_EXTRA_SUPPLY),
            startIcon: <AddBoxOutlinedIcon />,
            variant: "outlined",
            color: "info",
            show: supplies.at(0)?.status === "PENDING",
            onClick: () => setOpenAddExtraSupply(true),
          },
          {
            children: t(commons.CONFIRM),
            startIcon: <EventAvailableOutlinedIcon />,
            variant: "contained",
            onClick: () => packagingComplete(),
            disabled: !supplies.length,
            show: supplies.at(0)?.status === "PENDING",
          },
        ]}
      />

      {openAddExtraSupply && (
        <AddExtraSupplyDialog
          suppliesToAdd={suppliesToAdd}
          skusToDisabled={supplies.map((el) => el.sku)}
          onClose={() => setOpenAddExtraSupply(false)}
          onRefresh={getData}
        />
      )}

      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <CustomTabs
            key={`CustomTabs-primary`}
            tabs={[
              {
                children: t(supplyingManagement.SUPPLY_VIEW),
                startIcon: <CalendarMonthOutlinedIcon />,
              },
              {
                children: t(supplyingManagement.SLOT_VIEW),
                startIcon: <HistoryToggleOffOutlinedIcon />,
              },
            ]}
            onChange={setTab}
          />
        </Grid>
        {tab === 1 && (
          <Grid item sm={12} xs={12}>
            <CustomTabs
              key={`CustomTabs-slot`}
              tabs={[
                {
                  children: t(supplyingManagement.ALL_SLOTS),
                  startIcon: <CalendarMonthOutlinedIcon />,
                },
                ...Object.keys(SlotEnum).map((slot) => ({
                  children: slot,
                  startIcon: <HistoryToggleOffOutlinedIcon />,
                })),
              ]}
              onChange={setTabSlot}
            />
          </Grid>
        )}
        <Grid item sm={12} xs={12}>
          <ProductionSupplyDetailTable
            key={`ProductionSupplyDetailTable`}
            supplies={supplies}
            filteredSupplies={filteredSupplies}
            search={search}
            loading={loading}
            updatePackedQuantity={updatePackedQuantity}
          />
        </Grid>
      </Grid>
    </PageBox>
  );
};

export default SupplyingDetailPage;
