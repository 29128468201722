import { UseCase } from "core/common/interfaces/useCase";
import { canGetProductionLabel } from "../interfaces/canGetproductionLabel";

export class GetProductionLabelUseCase implements UseCase {
  constructor(private readonly repository: canGetProductionLabel) {}

  public execute(productionId: string) {
    return this.repository.getProductionLabels(productionId);
  }
}
