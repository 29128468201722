import { TableCell } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { commons } from "app/i18n/types";
import { onInputKeyDown } from "app/utils/input";
import { LinesPurchaseOrder } from "core/linesPurchaseOrders/entities/LinesPurchaseOrder";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import BoxTitleTable from "../PurchaseRecord/BoxTitleTable";
import PurchaseLinesTableRow from "./PurchaseLinesTableRow";
import PurchaseOrdersLinesSkeleton from "./PurchaseOrdersLinesSkeleton";

interface LinesPurchaseOrdersProps {
  items: Array<LinesPurchaseOrder> | null;
  loading: boolean;
  isPartial: boolean;
  origin: string;
}

const LinesPurchaseOrders: FunctionComponent<LinesPurchaseOrdersProps> = (
  props
) => {
  const { items, loading, isPartial, origin } = props;

  const { t } = useTranslation();

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="inventory" stickyHeader>
        <TableHead>
          <TableRow>
            <BoxTitleTable data={t(commons.SKU)} />
            <BoxTitleTable data={t(commons.PRODUCT_NAME)} />
            <BoxTitleTable data={t(commons.REQUESTED)} />
            <BoxTitleTable data={t(commons.RECEIVED)} />
            {isPartial && origin === "KIS" && (
              <BoxTitleTable data={t(commons.RECEIVE)} />
            )}
            <BoxTitleTable data={t(commons.UNIT)} />
          </TableRow>
        </TableHead>

        {loading && <PurchaseOrdersLinesSkeleton />}

        {loading === false && (
          <Box component={TableBody} onKeyDown={onInputKeyDown}>
            {items?.map((row) => (
              <PurchaseLinesTableRow
                item={row}
                key={row.lineId}
                isPartial={isPartial}
              />
            ))}
            {!items?.length && (
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  {t(commons.TABLE_EMPTY)}
                </TableCell>
              </TableRow>
            )}
          </Box>
        )}
      </Table>
    </TableContainer>
  );
};

export default LinesPurchaseOrders;
