import {
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSEmptyState,
  KOSRowData,
} from "@foodology-co/alejandria";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import { Button } from "@mui/material";
import { useNavigator } from "app/hooks/useNavigator";
import { commons, vendor } from "app/i18n/types";
import { VendorSupplyDistinct } from "core/vendors/entities/VendorSupply";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  supplies: VendorSupplyDistinct[];
  loading?: boolean;
}

const VendorSupplyDistinctTable: FunctionComponent<Props> = (props) => {
  const { supplies, loading } = props;

  const navigator = useNavigator();
  const { t } = useTranslation();

  const getHeader = (): KOSBaseTableHeader[] => [
    {
      label: t(commons.SKU),
      field: "supplySku",
      align: "center",
    },
    {
      label: t(vendor.SUPPLY_NAME),
      field: "supplyName",
    },
    {
      label: t(vendor.SUPPLY_UNIT),
      field: "supplyUnit",
      align: "center",
    },
    {
      label: t(commons.CATEGORY),
      text: (item: KOSRowData) => t(item.supplyCategory),
    },
    {
      label: t(vendor.VENDORS_QUANTITY),
      field: "countVendors",
      align: "center",
    },
    {
      label: t(commons.ACTIONS),
      align: "center",
      component: (item: KOSRowData) => (
        <Button
          onClick={() =>
            navigator.toByLocationType(
              `/vendor/sku/${item.supplySku}/city/${item.city}`
            )
          }
          variant="outlined"
          startIcon={<Inventory2OutlinedIcon />}
        >
          {t(vendor.SHOW_VENDORS)}
        </Button>
      ),
    },
  ];

  return (
    <KOSBaseTable
      columns={getHeader()}
      rows={{
        data: supplies,
        total: supplies.length,
        showNumber: true,
        loading: !!loading,
      }}
      pagination={{ enable: true }}
      emptyState={
        <KOSEmptyState
          icon={InfoOutlinedIcon}
          message={t(commons.TABLE_EMPTY)}
        />
      }
    />
  );
};

export default VendorSupplyDistinctTable;
