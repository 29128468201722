import { UseCase } from "core/common/interfaces/useCase";
import { CanGetCountingDetailsByIds } from "core/physicalCount/interfaces/counting/canGetCountingDetailsByIds";

export class GetCountingDetailsByIdsUseCase implements UseCase {
  constructor(private readonly repository: CanGetCountingDetailsByIds) {}

  public execute(ids: Array<number>) {
    return this.repository.getdetailsByIds(ids);
  }
}
