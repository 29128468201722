import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { Alert, Button, Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import DailyAdjustmenDialog from "app/components/DailyAdjustments/DailyAdjustmenDialog";
import ReasonSelect from "app/components/DailyAdjustments/ReasonSelect";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { commons, dailyAdjustmentsModule, measureUnits } from "app/i18n/types";
import {
  getAdjustmentsByKitchenForReview,
  setItemDailyAdjustments,
} from "app/store/slices/dailyAdjustments/thunks";
import { RegisterOption } from "config/dailyAdjustment";
import { ProductRequest } from "core/dailyAdjustments/entities/Catalog";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AddImageDialog from "../AddImageDialog";

interface Props {
  kitchenId: string;
  selectedOption: RegisterOption;
  productToSave: ProductRequest | undefined;
  setProductToSave: (productToSave: ProductRequest | undefined) => void;
}

const DailyAdjustmentsAddProduct: FunctionComponent<Props> = (props) => {
  const { kitchenId, selectedOption, productToSave, setProductToSave } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [openAddPhoto, setOpenAddPhoto] = useState<boolean>(false);
  const [disabledReportButton, setDisabledReportButton] =
    useState<boolean>(true);
  const requireEvidence = selectedOption.updaloadEvicence ?? false;

  const setConfirmLosses = async () => {
    const quantity = productToSave?.quantity ?? 0;
    let validReason = true;
    if (requireEvidence) {
      validReason = !!productToSave?.motiveId;
    }
    if (productToSave && quantity > 0 && validReason) {
      await dispatch(setItemDailyAdjustments({ product: productToSave }));
      setProductToSave(undefined);
      dispatch(
        getAdjustmentsByKitchenForReview({
          kitchenId,
        })
      );
      setConfirmDialogOpen(false);
    }
  };

  useEffect(() => {
    let disabledReportButtonTmp = !productToSave?.quantity;
    if (requireEvidence) {
      disabledReportButtonTmp =
        disabledReportButtonTmp ||
        !productToSave?.motiveId ||
        !productToSave?.folder?.attachments.length;
    }
    setDisabledReportButton(disabledReportButtonTmp);
  }, [productToSave]);

  return (
    <>
      {productToSave ? (
        <>
          <Grid item xs={requireEvidence ? 1 : 2}>
            <TextField
              name="value"
              label={t(commons.ENTER_QUANTITY)}
              type="tel"
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                min: 0,
              }}
              value={productToSave.quantity}
              onChange={(e) => {
                const value = e.target.value;
                setProductToSave({
                  ...productToSave,
                  quantity: value.length === 0 ? 0 : Number.parseFloat(value),
                });
              }}
              fullWidth
              focused
              autoComplete="off"
            />
          </Grid>

          <Grid
            item
            xs={requireEvidence ? 1 : 2}
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {t(
              measureUnits[productToSave.unit as keyof typeof measureUnits]
            ).toUpperCase()}
          </Grid>

          <Grid
            item
            xs={3}
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {!!selectedOption.reasons && (
              <ReasonSelect
                value={productToSave.motiveId}
                onChange={(motiveId) =>
                  setProductToSave({
                    ...productToSave,
                    motiveId,
                  })
                }
                reasons={selectedOption.reasons}
              />
            )}

            {!selectedOption.reasons &&
              t(`adjustmentType.${productToSave.typeLoss}`)}
          </Grid>

          {requireEvidence && (
            <>
              <Grid item xs={2}>
                <Button
                  variant="outlined"
                  color={
                    productToSave?.folder?.attachments.length
                      ? "success"
                      : "primary"
                  }
                  startIcon={<ArticleOutlinedIcon />}
                  disabled={!productToSave}
                  onClick={() => setOpenAddPhoto(true)}
                  sx={{ height: "100%" }}
                  fullWidth
                >
                  {t(commons.UPLOAD_EVIDENCE)}
                </Button>
              </Grid>

              {productToSave && openAddPhoto && (
                <AddImageDialog
                  productToSave={productToSave}
                  setProductToSave={setProductToSave}
                  onCloseDialog={() => setOpenAddPhoto(false)}
                />
              )}
            </>
          )}

          <Grid item xs={2}>
            <Button
              variant="contained"
              disabled={disabledReportButton}
              onClick={() => setConfirmDialogOpen(true)}
              startIcon={<CheckCircleOutlineOutlinedIcon />}
              fullWidth
              sx={{ height: "100%" }}
            >
              {t(commons.REPORT).toUpperCase()}
            </Button>
          </Grid>

          <DailyAdjustmenDialog
            open={confirmDialogOpen}
            handledConfirm={setConfirmLosses}
            handledClose={() => setConfirmDialogOpen(false)}
            title={productToSave.name}
            showCancelButton={true}
            message={t(dailyAdjustmentsModule.CONFIRM_MESSAGE, {
              quantity: productToSave.quantity,
              unit: productToSave.unit,
              type: t(`adjustmentType.${selectedOption.type}`),
            })}
          />
        </>
      ) : (
        <Grid item xs={9}>
          <Alert
            severity="info"
            sx={{ height: "100%", display: "flex", alignItems: "center" }}
          >
            {t(dailyAdjustmentsModule.REGISTER_SUPPLY_OR_PRODUCT_INSTRUCTION)}
          </Alert>
        </Grid>
      )}
    </>
  );
};

export default DailyAdjustmentsAddProduct;
