import { UseCase } from "core/common/interfaces/useCase";
import { CanGetPackedTransferOrders } from "core/transfer/interfaces/transfer/canGetPackedTransferOrders";

export class CanGetPackedTransferOrdersUseCase implements UseCase {
  constructor(private readonly repository: CanGetPackedTransferOrders) {}

  public execute(locationId: string) {
    return this.repository.getPackedTransferOrders(locationId);
  }
}
