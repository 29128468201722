import { createSlice } from "@reduxjs/toolkit";
import { RawCounting } from "core/physicalCount/entities/Counting";

import { initialState } from "./state";
import { getFinishedCountingsBetweenDates } from "./thunks";

export const consolidatedExportSlice = createSlice({
  name: "consolidatedExport",
  initialState,
  reducers: {
    selectCounting: (state, action) => {
      if (state.countings != null) {
        const counting: RawCounting = action.payload;
        const actualSelectedConuntings = state.selectedCountings;
        const index = actualSelectedConuntings.findIndex(
          (actual) => counting.id === actual.id
        );
        if (index < 0) {
          actualSelectedConuntings.push(counting);
        } else {
          actualSelectedConuntings.splice(index, 1);
        }
        state.selectedCountings = actualSelectedConuntings;
      }
    },
    selectAll: (state, action) => {
      const filteredCountings: RawCounting[] = action.payload;
      const selectableCountings = filteredCountings?.filter(
        (counting) => counting.status === "FINISHED"
      );
      if (selectableCountings?.length === state.selectedCountings.length) {
        state.selectedCountings = [];
      } else {
        state.selectedCountings = [];
        selectableCountings?.forEach((counting) =>
          state.selectedCountings.push(counting)
        );
      }
    },
    cleanSelected: (state, action) => {
      state.selectedCountings = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getFinishedCountingsBetweenDates.pending, (state, action) => {
        state.searchCountingStatus = "loading";
      })
      .addCase(getFinishedCountingsBetweenDates.fulfilled, (state, action) => {
        state.searchCountingStatus = "succeeded";
        state.countings = action.payload.countings;
      })
      .addCase(getFinishedCountingsBetweenDates.rejected, (state, action) => {
        state.searchCountingStatus = "failed";
        state.error = action.error.message ?? null;
      });
  },
});

export const { selectCounting, selectAll, cleanSelected } =
  consolidatedExportSlice.actions;

export const { reducer } = consolidatedExportSlice;

export default consolidatedExportSlice;
