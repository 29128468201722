import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SlotEnum, SlotType } from "core/productions/entities/Plan";
import React from "react";
import { column, corner, getSlotColor, slots } from "utils/productionSchedule";

const ProductionScheduleSlotLabel = (): React.ReactElement => {
  return (
    <Box sx={column}>
      <Box sx={corner} />
      {Object.keys(SlotEnum).map((slot) => (
        <Box
          key={slot}
          sx={{
            ...slots,
            height: 440,
            backgroundColor: getSlotColor(slot as SlotType),
          }}
        >
          <Typography variant="h6">{slot}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default ProductionScheduleSlotLabel;
