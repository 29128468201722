import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import { commons, productionSchedule } from "app/i18n/types";
import { ProductionPlanWithDetail } from "core/productions/entities/Plan";
import { findDetail } from "core/productions/repositories/http/Plan";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ProductionTime from "../ProductionTime";
import ProductionInfoDialogContent from "./Content";
import ProductionInProcessAlert from "./InProcessAlert";
import ProductionRealPerformance from "./RealPerformance";

interface Props {
  planId: number;
  open: boolean;
  onClose: () => void;
}

const ProductionInfoDialog: FunctionComponent<Props> = (props) => {
  const { open, onClose, planId } = props;
  const { t } = useTranslation();

  const [data, setData] = useState<ProductionPlanWithDetail>();
  const [loading, setLoading] = useState<boolean>(false);
  const [preparationTime, setPreparationTime] = useState("");

  useEffect(() => {
    if (planId) {
      setLoading(true);
      findDetail(planId)
        .then((response) => {
          setData(response.data);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setData(undefined);
    }
  }, [planId]);

  const getTitle = (): string => {
    switch (data?.productionStatus) {
      case "IN_PROCESS":
        return t(productionSchedule.TITLE_IN_PROCESS);
      case "FINISHED":
        return t(productionSchedule.TITLE_FINISHED);
      default:
        return t(productionSchedule.TITLE_SCHEDULED);
    }
  };

  const title = getTitle();
  const titlePlanId = data?.id ? `(${data.id})` : "";

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle color="primary.main">
        {`${title} ${titlePlanId}`}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseOutlinedIcon />
        </IconButton>
      </DialogTitle>

      {loading && <LinearProgress />}

      <DialogContent>
        {data && (
          <>
            <ProductionTime
              data={{
                startDate: data.startDate,
                finishedAt: data.finishedAt,
              }}
              preparationTime={preparationTime}
              setPreparationTime={setPreparationTime}
            />
            {data.productionStatus === "IN_PROCESS" && (
              <ProductionInProcessAlert preparationTime={preparationTime} />
            )}
            {data.productionStatus === "FINISHED" && (
              <ProductionRealPerformance
                scheduledQuantity={data.quantity}
                producedQuantity={data.producedQuantity}
              />
            )}
            <ProductionInfoDialogContent
              data={{
                name: data.name,
                quantity: data.quantity,
                measureUnit: data.measureUnit,
                producedQuantity: data.producedQuantity,
                startDate: data.startDate?.toString(),
                finishedAt: data.finishedAt?.toString(),
                assignedUserName: data.assignedUserName,
                productionStatus: data.productionStatus,
                timeSlot: data.timeSlot,
              }}
              preparationTime={preparationTime}
            />
          </>
        )}
      </DialogContent>

      <DialogActions sx={{ px: 3, pb: 2 }}>
        <Button
          variant="outlined"
          onClick={onClose}
          color="error"
          startIcon={<CloseOutlinedIcon />}
          fullWidth
          sx={{}}
        >
          {t(commons.CLOSE)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductionInfoDialog;
