import {
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSEmptyState,
  KOSRowData,
  KOSSelectedResult,
} from "@foodology-co/alejandria";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Typography } from "@mui/material";
import { commons } from "app/i18n/types";
import {
  PurchaseOrder,
  PurchaseOrderLine,
} from "core/purchaseOrders/entities/PurchaseOrder";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatPrice, localeData } from "utils/currency";
import { OnChange } from "utils/general";
import UpdateQuantityTextField from "../PurchaseLinesTable/UpdateQuantityTextField";
import UpdateUnitPriceTextField from "../PurchaseLinesTable/UpdateUnitPriceTextField";
import { ValidationItemResponse } from "../Vendor/Supply/PriceRange/utils";

interface Props {
  purchase: PurchaseOrder;
  setCheckedLines: (checkedLines: number[]) => void;
  onPurchaseOrderChange: (change: OnChange) => void;
  warns: ValidationItemResponse[];
}

const PurchaseOrderLinesTable: FunctionComponent<Props> = (props) => {
  const { purchase, setCheckedLines, onPurchaseOrderChange, warns } = props;

  const { t } = useTranslation();
  const [selectedItems, setSelectedItems] = useState<KOSSelectedResult[]>([]);

  useEffect(() => {
    setCheckedLines(selectedItems.map((el) => Number(el.id)));
  }, [selectedItems]);

  const setUnitPrice = useCallback(
    (detailId: number, unitPrice: number) => {
      onPurchaseOrderChange({
        id: purchase.id,
        detailId,
        label: "detailUnitPrice",
        value: unitPrice,
      });
    },
    [purchase, onPurchaseOrderChange]
  );

  const setQuantity = useCallback(
    (detailId: number, quantity: number) => {
      onPurchaseOrderChange({
        id: purchase.id,
        detailId,
        label: "detailQuantity",
        value: quantity,
      });
    },
    [purchase, onPurchaseOrderChange]
  );

  const getHeader = useCallback((): KOSBaseTableHeader[] => {
    return [
      {
        label: t(commons.SKU),
        field: "sku",
      },
      {
        label: t(commons.NAME),
        field: "productName",
      },
      {
        label: t(commons.UNIT),
        field: "measureUnit",
      },
      {
        label: t(commons.QUANTITY),
        align: "right",
        component: (item: KOSRowData) => {
          const line = item as PurchaseOrderLine;
          return (
            <UpdateQuantityTextField
              item={line}
              setQuantity={setQuantity}
              isCreate
              autoSave
            />
          );
        },
      },
      {
        label: t(commons.UNIT_PRICE),
        align: "right",
        component: (item: KOSRowData) => {
          const line = item as PurchaseOrderLine;
          const warn = warns.find((el) => el.id === line.id);
          const isWarn = !!warn;
          return (
            <>
              <UpdateUnitPriceTextField
                line={line}
                setUnitPrice={setUnitPrice}
                autoSave
                isSmall
                isWarn={isWarn}
              />
              {isWarn && (
                <Typography variant="body2" color="#ED6C02">
                  {warn.shortMessage2}
                </Typography>
              )}
            </>
          );
        },
      },
      {
        label: t(commons.TOTAL_PRICE),
        align: "right",
        text: (item: KOSRowData) => {
          const line = item as PurchaseOrderLine;
          return formatPrice(
            line.quantity * line.unitPrice,
            localeData[purchase.country]
          );
        },
      },
    ];
  }, [JSON.stringify(warns)]);

  return (
    <KOSBaseTable
      columns={getHeader()}
      rows={{ data: purchase.lines, total: purchase.lines.length }}
      selectable={{
        resultHeader: ["id"],
        items: selectedItems,
        onChange: setSelectedItems,
      }}
      emptyState={
        <KOSEmptyState
          icon={InfoOutlinedIcon}
          message={t(commons.TABLE_EMPTY)}
        />
      }
    />
  );
};

export default PurchaseOrderLinesTable;
