import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { productionManagement } from "app/i18n/types";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  preparationTime: string;
}

const ProductionInProcessAlert: FunctionComponent<Props> = (props) => {
  const { preparationTime } = props;
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        backgroundColor: "#FCE7D7",
        width: "100%",
        borderRadius: 2,
        p: 1,
        mb: 2,
      }}
    >
      <TimerOutlinedIcon sx={{ color: "#E65100" }} />
      <Box sx={{ display: "flex", gap: 0.5 }}>
        <Typography variant="body1" sx={{ color: "#E65100" }}>
          {t(productionManagement.PRODUCING_FOR)}:
        </Typography>
        <Typography variant="body1" fontWeight={600} sx={{ color: "#E65100" }}>
          {preparationTime}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProductionInProcessAlert;
