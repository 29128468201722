import { UseCase } from "core/common/interfaces/useCase";
import { CanGetTransferOrderPackingLogs } from "core/transfer/interfaces/transfer/canGetTransferOrderPackingLogs";

export class GetTransferOrderPackingLogsUseCase implements UseCase {
  constructor(private readonly repository: CanGetTransferOrderPackingLogs) {}

  public execute(sku: string, transferId: number) {
    return this.repository.getTransferOrderPackingLogs(sku, transferId);
  }
}
