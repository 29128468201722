import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import ViewWeekOutlinedIcon from "@mui/icons-material/ViewWeekOutlined";
import { Box, Grid } from "@mui/material";
import StatisticCard from "app/components/StatisticCard/StatisticCard";
import { commons } from "app/i18n/types";
import { PurchaseLine } from "core/purchaseOrders/entities/PurchaseOrder";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { DataFilterPurchaseOrder, getPurchaseLineStatus } from "utils/general";

interface Props {
  lines: PurchaseLine[];
  dataFilter: DataFilterPurchaseOrder;
  setDataFilter: (dataFilter: DataFilterPurchaseOrder) => void;
}

const PurchaseOrderLineSummary: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const { lines, dataFilter, setDataFilter } = props;
  const total = lines.length;
  const completeCount = lines.filter((line) => {
    const status = getPurchaseLineStatus(line);
    return status === commons.COMPLETE;
  }).length;
  const pendingCount = lines.filter((line) => {
    const status = getPurchaseLineStatus(line);
    return status === commons.PENDING;
  }).length;
  const partialCount = lines.filter((line) => {
    const status = getPurchaseLineStatus(line);
    return status === commons.PARTIAL;
  }).length;
  const completePorcentage = (completeCount * 100) / total;
  const pendingPorcentage = (pendingCount * 100) / total;
  const partialPorcentage = (partialCount * 100) / total;

  return (
    <>
      {!!lines.length && (
        <Grid item xs={12}>
          <Box display={"flex"} gap={2} flexWrap={"wrap"}>
            <StatisticCard
              icon={ViewWeekOutlinedIcon}
              label={"Todos"}
              value={total.toString()}
              count={``}
              color="primary"
              selected={dataFilter === "all"}
              onClick={() => setDataFilter("all")}
            />
            <StatisticCard
              icon={TrendingUpOutlinedIcon}
              label={t(commons.COMPLETE_PLURAL)}
              value={completeCount.toString()}
              count={`${completePorcentage.toFixed(0)}%`}
              color="success"
              selected={dataFilter === "complete"}
              onClick={() => setDataFilter("complete")}
            />
            <StatisticCard
              icon={TrendingUpOutlinedIcon}
              label={t(commons.PENDING_PLURAL)}
              value={pendingCount.toString()}
              count={`${pendingPorcentage.toFixed(0)}%`}
              color="info"
              selected={dataFilter === "pending"}
              onClick={() => setDataFilter("pending")}
            />
            <StatisticCard
              icon={TrendingUpOutlinedIcon}
              label={t(commons.PARTIAL_PLURAL)}
              value={partialCount.toString()}
              count={`${partialPorcentage.toFixed(0)}%`}
              color="warning"
              selected={dataFilter === "partial"}
              onClick={() => setDataFilter("partial")}
            />
          </Box>
        </Grid>
      )}
    </>
  );
};

export default PurchaseOrderLineSummary;
