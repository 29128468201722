import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { Alert, Grid, LinearProgress } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import NumberField from "app/components/common/Field/Number";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { commons, productionManagement } from "app/i18n/types";
import { clearLabels } from "app/store/slices/productions";
import { createProductionLabels } from "app/store/slices/productions/thunks";
import { ProductionRecord } from "core/productions/entities/Productions";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMount } from "react-use";
import ProductionLabelsDialog from "../Dialog";

interface Props {
  production: ProductionRecord;
  onClose: () => void;
}

const ProductionLabelCreate: FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();
  const { onClose, production } = props;
  const dispatch = useAppDispatch();

  const labels = useAppSelector((state) => state.productions.labels);
  const createStatus = useAppSelector(
    (state) => state.productions.createLabels.status
  );
  const [quantity, setQuantity] = useState<number>(0);
  const [quantityOfPacking, setQuantityOfPacking] = useState<number>(0);
  const [showLabels, setShowLabels] = useState<boolean>(false);

  const quantityPacked = production.labels.reduce(
    (sum, label) => sum + label.quantity,
    0
  );

  const onGenerateLabels = async () => {
    await dispatch(
      createProductionLabels({
        productionId: production.id,
        quantity,
        quantityOfPacking,
      })
    );
  };

  useEffect(() => {
    setShowLabels(createStatus === "succeeded" && !!labels?.length);
  }, [createStatus, labels]);

  const onCloseLabelsDialog = () => {
    setShowLabels(false);
    dispatch(clearLabels());
  };

  useMount(() => {
    onCloseLabelsDialog();
  });

  return (
    <>
      {production.producedQuantity !== quantityPacked && (
        <Dialog open onClose={onClose} maxWidth="xs" fullWidth>
          {createStatus === "loading" && <LinearProgress />}
          <DialogTitle color="primary.main">
            {t(productionManagement.PACK_PRODUCTION)}
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Alert severity="info">
                  {t(productionManagement.LABELS_GENERATED_INFO)}
                </Alert>
              </Grid>
              <Grid item xs={6}>
                <NumberField
                  variant="outlined"
                  value={quantity}
                  label={t(productionManagement.PRODUCTION_PACKED_QUANTITY)}
                  onChangeValue={(newValue) => setQuantity(newValue)}
                  fullWidth
                  disabled={createStatus === "loading"}
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={6}>
                <NumberField
                  variant="outlined"
                  value={quantityOfPacking}
                  label={t(productionManagement.QUANTITY_OF_PACKAGING)}
                  onChangeValue={(newValue) => setQuantityOfPacking(newValue)}
                  fullWidth
                  disabled={createStatus === "loading"}
                />
              </Grid>
              <Grid item xs={12}>
                <DialogActions sx={{ px: 0 }}>
                  <Button
                    variant="contained"
                    onClick={onGenerateLabels}
                    startIcon={<LocalPrintshopOutlinedIcon />}
                    fullWidth
                    disabled={createStatus === "loading" || !quantity}
                  >
                    {t(productionManagement.TRIGGER)}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={onClose}
                    color="error"
                    startIcon={<CloseOutlinedIcon />}
                    fullWidth
                  >
                    {t(commons.CANCEL)}
                  </Button>
                </DialogActions>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
      <ProductionLabelsDialog
        open={showLabels}
        onClose={onCloseLabelsDialog}
        labels={labels ?? []}
        total={production.labels.length}
      />
    </>
  );
};

export default ProductionLabelCreate;
