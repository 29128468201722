import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { productionSchedule } from "app/i18n/types";
import { archiveProductionPlan } from "app/store/slices/productions/plan/thunks";
import { ProductionPlan } from "core/productions/entities/Plan";
import React, { useState } from "react";
import { Draggable, DraggableProvided } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { Actions } from "utils/modal";
import ProductionDialog from "../ProductionDialog";
import { ProductionDialogProps } from "./Manager/useHandleProductions";

interface Props {
  id: number;
  index: number;
  productionPlan: ProductionPlan;
  openAddProductionDialog: (props: ProductionDialogProps) => void;
  disabledAction: boolean;
  removeCard: () => void;
  openInfoDialog: () => void;
  isToday: boolean;
  reloadDay: () => void;
  onlyView?: boolean;
}

const ProductionScheduleCard = (props: Props): React.ReactElement => {
  const {
    productionPlan,
    openAddProductionDialog,
    id,
    index,
    disabledAction,
    removeCard,
    openInfoDialog,
    isToday,
    reloadDay,
    onlyView,
  } = props;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const edit = () => {
    openAddProductionDialog({
      action: Actions.edit,
      currentPlan: {
        id: productionPlan.id,
        sku: productionPlan.sku,
        name: productionPlan.name,
        quantity: productionPlan.quantity,
        unit: productionPlan.measureUnit,
        scheduledDate: productionPlan.scheduledDate,
        timeSlot: productionPlan.timeSlot,
        userAssigned: productionPlan.assignedUserId,
        userAssignedName: productionPlan.assignedUserName,
      },
    });
  };

  const remove = async () => {
    const response = await dispatch(
      archiveProductionPlan({ productionPlanId: productionPlan.id })
    );
    const archived = response.payload as boolean;
    if (archived) {
      removeCard();
    } else {
      reloadDay();
    }
  };

  const getBorderBottom = (status: string) => {
    const result: { borderBottom?: string } = { borderBottom: undefined };
    if (status === "IN_PROCESS") {
      result.borderBottom = "#3C81DB solid 4px";
    }
    if (status === "FINISHED") {
      result.borderBottom = "#4CAF50 solid 4px";
    }
    if (status === "SCHEDULED") {
      result.borderBottom = "#FF9800 solid 4px";
    }
    if (status === "ARCHIVED") {
      result.borderBottom = "gray solid 4px";
    }
    return result;
  };

  const activesStatus = ["IN_PROCESS", "FINISHED"];
  const borderBottom = getBorderBottom(productionPlan.productionStatus ?? "");
  const showRightActions =
    (!disabledAction || isToday) &&
    !activesStatus.includes(productionPlan.productionStatus ?? "");

  return (
    <Draggable
      key={`ProductionScheduleCard${id}`}
      draggableId={id.toString()}
      index={index}
      isDragDisabled={
        disabledAction || !!productionPlan.productionId || !!onlyView
      }
    >
      {(provided: DraggableProvided) => (
        <Paper
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          elevation={1}
          sx={{ width: 224, p: 1, height: "100%", ...borderBottom }}
          onDoubleClick={openInfoDialog}
        >
          <Box
            sx={{
              display: "flex",
              gap: 0.5,
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Box sx={{ display: "flex", gap: 0.5 }}>
              {!disabledAction && !onlyView && (
                <DragIndicatorOutlinedIcon
                  sx={{
                    cursor: !disabledAction ? "pointer" : undefined,
                    color: disabledAction ? "#00000042" : undefined,
                    mt: 0.5,
                  }}
                />
              )}
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {productionPlan.name}
                </Typography>
                <Typography variant="body2">
                  {productionPlan.quantity} {productionPlan.measureUnit}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    display: "-webkit-box",
                    overflow: "hidden",
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {productionPlan.assignedUserName}
                </Typography>
              </Box>
            </Box>
            {showRightActions && !onlyView && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                {!productionPlan.productionId && (
                  <IconButton size="small" onClick={edit}>
                    <OpenInNewOutlinedIcon fontSize="small" />
                  </IconButton>
                )}
                <IconButton
                  size="small"
                  onClick={() => setOpenDeleteDialog(true)}
                >
                  <DeleteOutlineIcon fontSize="small" />
                </IconButton>
                <ProductionDialog
                  handleClose={() => setOpenDeleteDialog(false)}
                  handleConfirm={remove}
                  message={t(productionSchedule.DELETE_MESSAGE, {
                    productionName: productionPlan.name,
                  })}
                  title={t(productionSchedule.DELETE_TITLE)}
                  open={openDeleteDialog}
                  icon={
                    <DeleteOutlineIcon color="error" sx={{ fontSize: 96 }} />
                  }
                  showCancelButton={true}
                />
              </Box>
            )}
          </Box>
        </Paper>
      )}
    </Draggable>
  );
};

export default React.memo(ProductionScheduleCard);
