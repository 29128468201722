import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import React, { forwardRef } from "react";
import { useMobileOrientation } from "react-device-detect";
import { useAudio } from "react-use";
import Webcam from "react-webcam";
import KeyFrames from "../common/KeyFrame";
import Spinner from "../common/Spinner";

interface CameraProps {
  loading?: boolean;
  image?: string;
  width?: number;
  height?: number;
  screenshotFormat?: "image/webp" | "image/png" | "image/jpeg";
  facingMode?: "user" | "environment";
  mirrored?: boolean;
  secondsDelay?: number | null;
  onTakePhoto?: (image: string) => void;
}
/**
 * use ref to use this properties in parent components
 */
export interface CameraUtils {
  takePhoto: () => void;
}

const Camera = forwardRef(
  (props: CameraProps, ref: React.ForwardedRef<CameraUtils>) => {
    const {
      loading,
      image,
      height,
      screenshotFormat,
      width,
      facingMode,
      mirrored,
      secondsDelay,
      onTakePhoto,
    } = props;
    const [audio, , controls] = useAudio({
      src: "https://kitchen-os-assets.s3.amazonaws.com/audios/take-a-photo.mp3",
    });
    const webcamRef = React.useRef<Webcam>(null);
    const [countdown, setCountdown] = React.useState(secondsDelay);
    const { isPortrait } = useMobileOrientation();
    const webCamResolution = {
      width: isPortrait ? 720 : 1280,
      height: isPortrait ? 1280 : 720,
    };

    const handleTakePhoto = React.useCallback(() => {
      if (onTakePhoto) {
        const img = webcamRef.current?.getScreenshot(webCamResolution);
        onTakePhoto(img ?? "");
        controls.seek(0);
        controls.play();
      }
    }, [controls, isPortrait, onTakePhoto]);

    React.useImperativeHandle(
      ref,
      (): CameraUtils => ({
        takePhoto: () => handleTakePhoto(),
      })
    );

    React.useEffect(() => {
      if (!!countdown && countdown > 0) {
        const timerId = setTimeout(() => setCountdown(countdown - 1), 1000);
        return () => {
          clearTimeout(timerId);
        };
      }
      if (secondsDelay) {
        handleTakePhoto();
      }
      return () => {};
    }, [countdown, handleTakePhoto, secondsDelay]);

    const [validateWebCam, setValidateWebCam] = React.useState(false);

    const [validateTime, setValidateTime] = React.useState(
      new Date().getTime()
    );

    React.useEffect(() => {
      if (!validateWebCam) {
        setTimeout(() => {
          setValidateTime(new Date().getTime());
          setValidateWebCam(
            !!webcamRef.current?.getScreenshot(webCamResolution)
          );
        }, 500);
      }
    }, [validateTime]);

    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {audio}
        <KeyFrames
          name="take-photo"
          _0={{ transform: "scale(1, 1)" }}
          _50={{ transform: "scale(1.5, 1.5)", filter: "blur(5px)" }}
          _100={{ transform: "scale(1, 1)" }}
        />
        {!image && (
          <Webcam
            style={{ width, height }}
            audio={false}
            ref={webcamRef}
            mirrored={mirrored}
            videoConstraints={{
              facingMode,
              noiseSuppression: true,
              width,
              height,
            }}
            screenshotFormat={screenshotFormat}
            screenshotQuality={1}
          />
        )}
        {image && (
          <Box sx={{ overflow: "hidden", width: "100%" }}>
            <img
              alt="Foto"
              src={image}
              style={{
                width: "100%",
                animation: "take-photo 1s ease-out",
              }}
            />
          </Box>
        )}
        {(loading || !validateWebCam) && (
          <Spinner
            sx={{
              position: "absolute",
              color: !validateWebCam ? "black" : "#FFFFFF",
            }}
          />
        )}
        {!!countdown && (
          <Typography
            sx={{
              fontWeight: 900,
              color: "#FFFFFF",
              fontSize: "100px",
              position: "absolute",
            }}
          >
            {countdown}
          </Typography>
        )}
      </Box>
    );
  }
);

Camera.defaultProps = {
  loading: false,
  width: 438,
  height: 293,
  screenshotFormat: "image/jpeg",
  facingMode: "environment",
  mirrored: false,
  onTakePhoto() {},
  image: "",
  secondsDelay: null,
};

export default Camera;
