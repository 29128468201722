import { UseCase } from "core/common/interfaces/useCase";
import { UpdateSupplyStatusPayload } from "core/supplies/entities/Supply";
import { CanSetSupliesStateByKitchens } from "core/supplies/interfaces/supplies/canSetSupliesStateByKitchens";

export class SetSupliesStateByKitchensUseCase implements UseCase {
  constructor(private readonly repository: CanSetSupliesStateByKitchens) {}

  public execute(payload: Array<UpdateSupplyStatusPayload>) {
    return this.repository.setSupliesStateByKitchens(payload);
  }
}
