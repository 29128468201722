import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FunctionComponent } from "react";

interface ArchiveLinesCheckDialogProps {
  open: boolean;
  onClose: () => void;
  onContinue: () => void;
  selectedLines: number;
}

const ArchiveLinesCheckDialog: FunctionComponent<
  ArchiveLinesCheckDialogProps
> = (props) => {
  const { open, onClose, onContinue, selectedLines } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title" color="primary.main">
        {"Eliminar insumos"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" color="text.primary">
          Se eliminarán <strong>{selectedLines} elementos</strong> y no se hará
          el traslado. Por favor confirma si deseas continuar.
        </DialogContentText>

        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          my={2}
        >
          <DeleteOutlinedIcon
            sx={{ color: "error.main", width: 96, height: 96 }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" gap={2} width="100%">
          <Button
            onClick={onClose}
            size="large"
            variant="outlined"
            startIcon={<CloseOutlinedIcon />}
            color="error"
            fullWidth
          >
            Cancelar
          </Button>
          <Button
            onClick={onContinue}
            size="large"
            variant="contained"
            startIcon={<ChevronRightOutlinedIcon />}
            fullWidth
          >
            Continuar
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ArchiveLinesCheckDialog;
