import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { ElementType, FunctionComponent } from "react";

interface StatisticCardProps {
  icon: ElementType<any>;
  label: string;
  value: string;
  count: string;
  loading?: boolean;
  color?: "success" | "warning" | "error" | "primary" | "info";
  selected?: boolean;
  disabled?: boolean;
  onClick?: (label: string) => void;
  tooltip?: string;
}

const StatisticCard: FunctionComponent<StatisticCardProps> = (props) => {
  const {
    label,
    icon,
    value,
    count,
    loading,
    color,
    onClick,
    selected,
    disabled,
    tooltip,
  } = props;

  return (
    <Tooltip title={tooltip}>
      <Box
        bgcolor="white"
        borderRadius={1}
        py={2}
        px={3}
        display="flex"
        flexDirection={{
          xs: "column",
          lg: "row",
        }}
        gap={2}
        flex={1}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          cursor: onClick ? "pointer" : "default",
          borderBottomWidth: selected ? 2 : undefined,
          borderBottomColor: selected ? `${color}.main` : undefined,
          borderBottomStyle: selected ? "solid" : undefined,
          border: selected ? undefined : "1px solid #D7D3D3",
          opacity: disabled ? 0.75 : 1,
          minWidth: 200,
        }}
        onClick={disabled ? undefined : () => onClick && onClick(label)}
      >
        <Typography variant="h3" color={`${color}.main`} fontWeight={700}>
          {loading ? <Skeleton width={60} /> : value}
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <Typography
            variant="h6"
            textAlign={{
              xs: "center",
              lg: "right",
            }}
            fontWeight={400}
            color="text.secondary"
          >
            {label}
          </Typography>

          <Box display={"flex"} gap={1}>
            <Icon component={icon} color={color} mb={1} sx={{ fontSize: 24 }} />

            <Typography
              color={`${color}.main`}
              textAlign={{
                xs: "center",
                lg: "right",
              }}
            >
              {loading ? <Skeleton width={120} /> : count}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Tooltip>
  );
};

export default StatisticCard;
