export interface ProductionPlanSave {
  sku: string;
  quantity: number;
  scheduledDate: string;
  userAssigned: string;
  timeSlot: string;
}

export interface ProductionPlanSelectedSlot {
  scheduledDate?: string;
  timeSlot?: string;
}

export enum SlotEnum {
  AM = "AM",
  M = "M",
  PM = "PM",
}

export type SlotType = `${SlotEnum}`;

export interface ProductionPlan {
  id: number;
  sku: string;
  name: string;
  quantity: number;
  measureUnit: string;
  scheduledDate: string;
  timeSlot: SlotType;
  status: string;
  source: string;
  productionId: null;
  locationId: string;
  country: string;
  assignedUserId: string;
  assignedUserName: string;
  productionStatus?: string;
}

export interface UpdateDateAndSlotPayload {
  id: string;
  slot: string;
  scheduledDate: string;
}

export interface ProductionPlanWithDetail extends ProductionPlan {
  producedQuantity?: number;
  startDate?: Date;
  finishedAt?: Date;
}

export interface ProductionPlanWithDetailResponse {
  data?: ProductionPlanWithDetail;
}
