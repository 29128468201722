import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FunctionComponent, useMemo } from "react";

import { StoreRequestError, StoreRequestStatus } from "app/store/types";
import { onInputKeyDown } from "app/utils/input";
import { TransferOrderLine } from "core/transfer/entities/TransferOrder";
import TransferOrdersTableRow from "./TransferOrdersTableRow";

interface TransferOrdersTableProps {
  lines: Array<TransferOrderLine & { id: number; name: string }>;
  checkedLines: Array<number>;
  disabled: boolean;
  toggleSelectAll: (lineIds: Array<number>) => void;
  toggleChecked: (lineId: number) => void;
  onUpdateLineQuantity: (lineId: number, quantity: number) => void;
  linesStatus: {
    [sku: string]: {
      status: StoreRequestStatus;
      error: StoreRequestError;
    };
  } | null;
  searchTerm: string;
  showSendQuantity?: boolean;
}

const TransferOrdersTable: FunctionComponent<TransferOrdersTableProps> = (
  props
) => {
  const {
    lines,
    checkedLines,
    toggleSelectAll,
    toggleChecked,
    disabled,
    onUpdateLineQuantity,
    linesStatus,
    searchTerm,
    showSendQuantity,
  } = props;

  const filteredLines = useMemo(() => {
    if (searchTerm === "") {
      return lines;
    }

    return lines.filter(
      (line) =>
        line.name.toLowerCase().includes(searchTerm) ||
        line.sku.toLowerCase().includes(searchTerm)
    );
  }, [lines, searchTerm]);

  return (
    <TableContainer component={Paper} sx={{ mt: 1 }}>
      <Table>
        <TableHead>
          <TableRow
            sx={{
              "& > th": {
                bgcolor: "#F9FAFC",
                borderBottom: "2px solid #E3E5E8",
                color: "text.secondary",
                fontWeight: 700,
                textTransform: "uppercase",
              },
            }}
          >
            <TableCell align="center" width={80}>
              <Checkbox
                onChange={() => toggleSelectAll(lines.map((line) => line.id))}
                checked={lines.every((line) => checkedLines.includes(line.id))}
                disabled={disabled}
              />
            </TableCell>
            <TableCell>No. Linea</TableCell>
            <TableCell>Nombre</TableCell>
            <TableCell>Unidad</TableCell>
            {showSendQuantity && (
              <>
                <TableCell>Cantidad solicitada</TableCell>
                <TableCell>Cantidad empacada</TableCell>
              </>
            )}

            {!showSendQuantity && <TableCell>Cantidad</TableCell>}
          </TableRow>
        </TableHead>

        <TableBody onKeyDown={(e) => onInputKeyDown(e, 2)}>
          {filteredLines.map((line) => (
            <TransferOrdersTableRow
              key={line.id}
              line={line}
              checkedLines={checkedLines}
              toggleChecked={toggleChecked}
              onUpdateLineQuantity={onUpdateLineQuantity}
              lineStatus={
                linesStatus !== null && linesStatus[line.id] !== undefined
                  ? linesStatus[line.id]
                  : null
              }
              disabled={disabled}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TransferOrdersTable;
