import { clearSpecialCharacters } from "utils/general";

export const cities: { [codename: string]: string } = {
  BAQ: "Barranquilla",
  CLO: "Cali",
  BOG: "Bogotá",
  GDL: "Guadalajara",
  SMR: "Santa Marta",
  LIM: "Lima",
  ZCMX: "Zona Centro",
  PBC: "Puebla",
  PEI: "Pereira",
  MID: "Merida",
  MTY: "Monterrey",
  CDMX: "Ciudad de México",
  CTG: "Cartagena",
  MDE: "Medellín",
  BGA: "Bucaramanga",
  SAO: "São Paulo",
  RIO: "Río de Janeiro",
  CWB: "Curitiba",
  SD: "Santo Domingo",
  QTO: "Quito",
};

export const getCityName = (codename: string): string => {
  if (!Object.keys(cities).includes(codename)) {
    console.warn(`City codename ${codename} not found`);
    return codename;
  }

  return cities[codename];
};

export const getCityCodenameByName = (cityName: string) => {
  return (
    Object.keys(cities).filter((key) => {
      return (
        clearSpecialCharacters(cities[key]) === clearSpecialCharacters(cityName)
      );
    }) ?? null
  );
};

export const getCitiesCodenamesByNames = (citiesNames: Array<string>) => {
  return citiesNames.map((city) => getCityCodenameByName(city)).flat();
};
