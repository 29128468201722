import {
  PriceRangeConfig,
  SupplyToValidate,
  ValidationItemResponse,
} from "app/components/Vendor/Supply/PriceRange/utils";
import { useAppSelector } from "app/hooks/useAppSelector";
import { Country, getCountryKey } from "core/common/entities/Country";
import {
  RemoteConfigKey,
  getRemoteValueJSON,
} from "core/common/utils/remoteConfig";
import { VendorSupply } from "core/vendors/entities/VendorSupply";
import VendorSupplyPriceRangeValidator from "../Validator";

interface Props {
  vendorId: number;
  kitchenId: string;
  supplyToValidate: SupplyToValidate[];
  onValidation?: (values: ValidationItemResponse[]) => void;
  vendorSupplies?: VendorSupply[];
  onVendorSupplies?: (values: VendorSupply[]) => void;
}

const VendorSupplyPriceRangeInPOValidator = (props: Props) => {
  const {
    vendorId,
    kitchenId,
    supplyToValidate,
    onValidation,
    vendorSupplies,
    onVendorSupplies,
  } = props;

  const kitchens = useAppSelector((state) => state.global.kitchens.data);
  const kitchen = kitchens.find((el) => el.kitchenId === kitchenId);
  const country = kitchen?.country ?? getCountryKey(Country.COL);
  const city = kitchen?.city ?? "";

  const config = getRemoteValueJSON(
    RemoteConfigKey.PRICE_RANGE_IN_PO
  ) as PriceRangeConfig;

  return (
    <VendorSupplyPriceRangeValidator
      config={config[country]}
      country={country}
      vendorId={vendorId}
      city={city}
      supplyToValidate={supplyToValidate}
      onValidation={onValidation}
      vendorSupplies={vendorSupplies}
      onVendorSupplies={onVendorSupplies}
    />
  );
};

export default VendorSupplyPriceRangeInPOValidator;
