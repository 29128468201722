import FullDialog from "app/components/common/FullDialog";
import { purchaseOrderLine } from "app/i18n/types";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import PurchaseOrderConciliationDetail from ".";

interface Props {
  kitchenId: string;
  orderId: string;
  open: boolean;
  onClose: () => void;
  onUpdate: () => void;
}

const PurchaseOrderConciliationDetailModal: FunctionComponent<Props> = (
  props: Props
) => {
  const { kitchenId, orderId, open, onClose, onUpdate } = props;
  const { t } = useTranslation();

  const updateAndClose = () => {
    onUpdate();
    onClose();
  };

  return (
    <FullDialog
      open={open}
      onClose={onClose}
      title={t(purchaseOrderLine.TITLE)}
      content={
        <PurchaseOrderConciliationDetail
          kitchenId={kitchenId ?? ""}
          orderId={orderId ?? ""}
          isFullDialog
          onUpdate={updateAndClose}
        />
      }
    />
  );
};

export default PurchaseOrderConciliationDetailModal;
