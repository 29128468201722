import { Add } from "@mui/icons-material";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import {
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import PageHeader from "app/components/PageHeader";
import { VendorSupplyManagerDialog } from "app/components/Vendor/Supply/Manager";
import VendorSupplyTable from "app/components/Vendor/Supply/Table";
import CommonDialog from "app/components/common/CommonDialog";
import SearchOnTopBar from "app/components/common/SearchOnTopBar";
import { useAlert } from "app/hooks/useAlert";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { useNavigator } from "app/hooks/useNavigator";
import { commons, supplyingManagement, vendor } from "app/i18n/types";
import { getSuppliesByCountry } from "app/store/slices/supplies/thunks";
import { cities } from "app/utils/city.utils";
import { Country } from "core/common/entities/Country";
import { VendorWithSupplies } from "core/vendors/entities/Vendor";
import {
  CreateOrModifyDataResponse,
  VendorSupply,
} from "core/vendors/entities/VendorSupply";
import { getVendorWithSuppliesById } from "core/vendors/repositories/http/vendor";
import { archiveById } from "core/vendors/repositories/http/vendorSupply";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { compareSearchText } from "utils/general";
import { PageBox } from "utils/generalUI";
import { Actions, VendorSupplyModal } from "utils/modal";
import VendorSupplyByVendorResume from "./Resume";

interface Props {}

const VendorSupplyByVendorPage = (props: Props) => {
  const { t } = useTranslation();
  const navigator = useNavigator();
  const alert = useAlert();
  const { vendorId } = navigator.params();

  const dispatch = useAppDispatch();
  const suppliesRequest = useAppSelector(
    (state) => state.supplies.suppliesByCountry
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<VendorWithSupplies>();
  const [dataCities, setDataCities] = useState<string[]>([]);
  const all = "ALL";
  const [selectedCity, setSelectedCity] = useState<string>(all);
  const [supplies, setSupplies] = useState<VendorSupply[]>([]);
  const [search, setSearch] = useState<string>("");

  const [modal, setModal] = useState<VendorSupplyModal>({ open: false });
  const [archiveId, setArchiveId] = useState<number>();
  const [archiveLoading, setArchiveLoading] = useState<boolean>(false);
  const [country, setCountry] = useState<Country>();

  const getData = () => {
    if (vendorId) {
      setLoading(true);
      getVendorWithSuppliesById(Number(vendorId))
        .then((data) => {
          const tmpCities = data?.supplies.map((el) => el.city) ?? [];
          setDataCities(Array.from(new Set([all, ...tmpCities])));
          setData(data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (data) {
      const country = Country[data.country as keyof typeof Country];
      setCountry(country);
    }
  }, [data]);

  useEffect(() => {
    if (country) {
      dispatch(getSuppliesByCountry(country));
    }
  }, [country]);

  useEffect(() => {
    getData();
  }, [vendorId]);

  const searchValidator = (search: string, item: VendorSupply) => {
    if (!search) return true;
    return (
      compareSearchText(item.supplyName, search) ||
      compareSearchText(item.supplySku, search)
    );
  };

  const cityValidator = (city: string, item: VendorSupply) => {
    if (city === all) return true;
    return item.city === city;
  };

  useEffect(() => {
    setSupplies(
      data?.supplies.filter((item) => {
        return (
          cityValidator(selectedCity, item) && searchValidator(search, item)
        );
      }) ?? []
    );
  }, [data, search, selectedCity]);

  const getSupplies = (
    supplies: VendorSupply[],
    result: CreateOrModifyDataResponse
  ): VendorSupply[] => {
    const { current, refinedSupplies: refined } = result;

    if (modal.action === Actions.edit) {
      return supplies.map((el) => {
        if (el.id === current.id) {
          return current;
        }
        const supply = refined.find((ref) => ref.id === el.id);
        if (supply) {
          return { ...el, vendorType: supply.vendorType };
        }
        return el;
      });
    }

    return [
      current,
      ...supplies.map((supply) => {
        const current = refined.find((el) => el.id === supply.id);
        if (current) {
          return { ...supply, vendorType: current.vendorType };
        }
        return supply;
      }),
    ];
  };

  const onSuccess = useCallback(
    (result: CreateOrModifyDataResponse) => {
      if (!data) return;

      setData({
        ...data,
        supplies: getSupplies(data.supplies, result),
      });
    },
    [modal]
  );

  const handleArchive = useCallback(() => {
    if (!archiveId) return;
    if (!data) return;
    setArchiveLoading(true);
    archiveById(archiveId)
      .then((response) => {
        const title = t(`common.${response.message}`);
        if (!response.ok) {
          alert.errorWithMsg({ title });
          return;
        }
        alert.successWithMsg({ title });
        if (archiveId) {
          setData({
            ...data,
            supplies: data.supplies.filter((el) => el.id !== archiveId),
          });
        }
      })
      .finally(() => {
        setArchiveLoading(false);
        setArchiveId(undefined);
      });
  }, [archiveId]);

  const getArchiveMsg = useCallback((): string => {
    if (!archiveId) return "";
    const record = supplies.find((el) => el.id === archiveId);
    return `${record?.supplySku} | ${record?.supplyName} | ${record?.vendorSupplyName}`;
  }, [archiveId, supplies]);

  const handleAddSupply = useCallback(() => {
    setModal({
      open: true,
      action: Actions.add,
      supplies: suppliesRequest.supplies ?? [],
      country: data?.country,
      item: {
        vendorId: Number(vendorId ?? 0),
      },
    });
  }, [suppliesRequest.supplies]);

  return (
    <PageBox>
      <PageHeader
        title={vendor.VENDOR_SUPPLIES_TITLE}
        rightArea={[
          {
            children: `${t(commons.ADD)} ${t(commons.SUPPLY)}`,
            variant: "contained",
            color: "primary",
            startIcon: <Add />,
            onClick: handleAddSupply,
          },
          {
            children: t(commons.UPDATE),
            variant: "outlined",
            startIcon: <RestartAltOutlinedIcon />,
            onClick: getData,
          },
        ]}
      />

      {!!data && (
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <SearchOnTopBar onSearch={setSearch} />
          <VendorSupplyByVendorResume data={data} />
          <Grid item sm={3} xs={12}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                {t(commons.FILTER_BY_CITY)}
              </Typography>
              <Select
                fullWidth
                value={selectedCity}
                onChange={(event: SelectChangeEvent<string>) => {
                  setSelectedCity(event.target.value);
                }}
              >
                {dataCities.map((key) => (
                  <MenuItem key={`filter-city-${key}`} value={key}>
                    {key === all
                      ? t(supplyingManagement.ALL_SLOTS)
                      : cities[key]}
                  </MenuItem>
                ))}
              </Select>
            </Paper>
          </Grid>
        </Grid>
      )}
      <VendorSupplyTable
        supplies={supplies}
        country={data?.country ?? ""}
        loading={loading}
        setModal={setModal}
        setArchiveId={setArchiveId}
      />
      {modal.open && (
        <VendorSupplyManagerDialog
          {...modal}
          onClose={() => setModal({ open: false })}
          onSuccess={onSuccess}
        />
      )}
      <CommonDialog
        open={!!archiveId}
        handleClose={() => setArchiveId(undefined)}
        title={t(commons.DELETE)}
        message={getArchiveMsg()}
        handleConfirm={handleArchive}
        confirmButtonLabel={t(commons.CONFIRM)}
        isLoading={archiveLoading}
        showCancelButton
      />
    </PageBox>
  );
};

export default VendorSupplyByVendorPage;
