import Box from "@mui/material/Box";
import Tab, { TabProps } from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { FunctionComponent } from "react";

interface PageTabsProps {
  value: number;
  onChange: (value: number) => void;
  tabs: Array<TabProps>;
}

function a11yProps(index: number) {
  return {
    id: `page-tab-${index}`,
    "aria-controls": `page-tabpanel-${index}`,
  };
}

const PageTabs: FunctionComponent<PageTabsProps> = (props) => {
  const { value, onChange, tabs } = props;
  return (
    <Box>
      <Tabs
        value={value}
        onChange={(_, value) => onChange(value)}
        aria-label="Page tabs"
        sx={{
          justifyContent: "space-between",

          "& .MuiTab-root": {
            minHeight: 48,
          },
        }}
        variant="fullWidth"
      >
        {tabs.map((tab, index) => (
          <Tab key={tab.label?.toString()} {...tab} {...a11yProps(index)} />
        ))}
      </Tabs>
    </Box>
  );
};

export default PageTabs;
