import { UseCase } from "core/common/interfaces/useCase";
import { CanSendProductionsPrintEvent } from "../interfaces/canSendProductionsPrintEvent";

export class SendProductionsPrintEventUseCase implements UseCase {
  constructor(private readonly repository: CanSendProductionsPrintEvent) {}

  public execute(productionId: string) {
    return this.repository.sendProductionsPrintEvent(productionId);
  }
}
