import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { commons } from "app/i18n/types";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface PurchaseOrdersTableSkeletonProps {
  columns: number;
  hideActionColumn?: boolean;
}

const PurchaseOrdersTableSkeleton: FunctionComponent<
  PurchaseOrdersTableSkeletonProps
> = (props) => {
  const { columns, hideActionColumn } = props;
  const { t } = useTranslation();

  return (
    <TableBody>
      {Array.from(Array(5)).map((_, i) => (
        <TableRow key={i}>
          {Array.from(Array(!hideActionColumn ? columns - 1 : columns)).map(
            (_, i) => (
              <TableCell align="left" key={i}>
                <Typography>
                  <Skeleton />
                </Typography>
              </TableCell>
            )
          )}
          {!hideActionColumn && (
            <TableCell align="center">
              <Button variant="contained" disabled>
                {t(commons.ACTIONS)}
              </Button>
            </TableCell>
          )}
        </TableRow>
      ))}
    </TableBody>
  );
};

export default PurchaseOrdersTableSkeleton;
