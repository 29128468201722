import { UseCase } from "core/common/interfaces/useCase";
import { CanGetErrorSummaryByKitchen } from "../interfaces/canGetErrorSummaryByKitchen";

export class CanGetErrorSummaryByKitchenUseCase implements UseCase {
  constructor(private readonly repository: CanGetErrorSummaryByKitchen) {}

  public execute(kitchenId: string) {
    return this.repository.byKitchen(kitchenId);
  }
}
