import { StoreRequestError, StoreRequestStatus } from "app/store/types";
import { SummaryAdjustmentResponse } from "core/adjustments/entities/Adjusment";

export interface AdjustmentsState {
  kitchenId: string | null;
  filters: { group: Array<string>; search: string | null };
  status: StoreRequestStatus;
  statusLoad: StoreRequestStatus;
  error: StoreRequestError;
  adjustmentId: number | null;
  success: boolean | true;
  msg: string | null;
  summary: Array<SummaryAdjustmentResponse>;
  addManualAdjustment: {
    status: StoreRequestStatus;
    error: StoreRequestError;
  };
}

export const initialState: AdjustmentsState = {
  kitchenId: null,
  filters: {
    group: [],
    search: null,
  },
  status: "idle",
  statusLoad: "idle",
  error: null,
  adjustmentId: null,
  success: false,
  msg: "",
  summary: [],
  addManualAdjustment: {
    status: "idle",
    error: null,
  },
};
