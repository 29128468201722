import { StoreRequestError, StoreRequestStatus } from "app/store/types";
import { Group } from "core/physicalCount/entities/Group";
import { Supply, SupplyInactiveKitchen } from "core/supplies/entities/Supply";

export interface SuppliesState {
  kitchenId: string | null;
  groups: Array<Group> | null;
  filters: { group: Array<string>; search: string | null };
  status: StoreRequestStatus;
  error: StoreRequestError;
  suppliesByCountry: {
    supplies: Array<Supply> | null;
    status: StoreRequestStatus;
    error: StoreRequestError;
  };
  setSupliesStateByKitchens: {
    status: StoreRequestStatus;
    error: StoreRequestError;
  };
  supplyInactiveStatusForKitchens: {
    inactiveKitchens: Array<SupplyInactiveKitchen> | null;
    status: StoreRequestStatus;
    error: StoreRequestError;
  };
  inactiveSuppliesByLocation: {
    inactiveSupplies: Array<SupplyInactiveKitchen> | null;
    status: StoreRequestStatus;
    error: StoreRequestError;
  };
}

export const initialState: SuppliesState = {
  kitchenId: null,
  groups: null,
  filters: {
    group: [],
    search: null,
  },
  status: "idle",
  error: null,
  suppliesByCountry: {
    supplies: null,
    status: "idle",
    error: null,
  },
  setSupliesStateByKitchens: {
    status: "idle",
    error: null,
  },
  supplyInactiveStatusForKitchens: {
    inactiveKitchens: null,
    status: "idle",
    error: null,
  },
  inactiveSuppliesByLocation: {
    inactiveSupplies: null,
    status: "idle",
    error: null,
  },
};
