import { Box, Chip, Typography } from "@mui/material";
import { commentArea } from "app/i18n/types";
import { CommentDTO, CommentType } from "core/comment/entity";
import {
  createComment as createCommentRepository,
  getComments,
} from "core/comment/repository/http";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CommentCard from "./Card";
import CommentForm from "./form/Comment";

interface Props {
  sourceId: string;
  type: CommentType;
  kitchenId: string;
  onChangeMessagesCount?: (comments: number) => void;
  noRender?: boolean;
  inDrawer?: boolean;
}

const CommentArea = (props: Props) => {
  const {
    sourceId,
    type,
    kitchenId,
    inDrawer,
    onChangeMessagesCount,
    noRender,
  } = props;
  const canRender = !noRender;
  const { t } = useTranslation();
  const [comments, setComments] = useState<CommentDTO[]>([]);
  const [key, setKey] = useState<string>("");

  const createComment = (content: string) => {
    if (sourceId && type && kitchenId) {
      createCommentRepository({ sourceId, type, kitchenId, content }).then(
        (response) => {
          if (response.data) {
            setComments([response.data, ...comments]);
          }
        }
      );
    }
  };

  const createAnswer = (parentId: number, content: string) => {
    if (sourceId && type && kitchenId) {
      createCommentRepository({
        sourceId,
        type,
        kitchenId,
        content,
        parentId,
      }).then((response) => {
        if (response.data) {
          setComments(
            comments.map((comment) => {
              if (comment.id === parentId) {
                return {
                  ...comment,
                  children: [...comment.children, response.data],
                } as CommentDTO;
              }
              return comment;
            })
          );
        }
      });
    }
  };

  useEffect(() => {
    if (sourceId && type && kitchenId) {
      setKey(`${sourceId} ${type} ${kitchenId}`);
    }
  }, [sourceId, type, kitchenId]);

  useEffect(() => {
    if (key) {
      getComments(sourceId, type, kitchenId).then((response) => {
        if (response.ok) {
          const data = response.data;
          setComments(data.reverse());
        } else {
          setComments([]);
        }
      });
    }
  }, [key]);

  useEffect(() => {
    onChangeMessagesCount?.(comments.length);
  }, [comments]);

  return (
    <>
      {canRender && (
        <Box>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              {t(commentArea.TITLE)}
            </Typography>
            {!!comments.length && (
              <Chip label={comments.length} color="primary" size="small" />
            )}
          </Box>
          <CommentForm onSuccess={createComment} inDrawer={inDrawer} />
          {comments.map((comment) => (
            <CommentCard
              key={`CommentCard${comment.id}`}
              comment={comment}
              createAnswer={createAnswer}
              inDrawer={inDrawer}
            />
          ))}
        </Box>
      )}
    </>
  );
};

export default CommentArea;
