import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import CommonDialog from "app/components/common/CommonDialog";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import {
  commons,
  productionManagement,
  purchaseOrderLine,
} from "app/i18n/types";
import { clearFinishProduction } from "app/store/slices/productions";
import { finishProduction } from "app/store/slices/productions/thunks";
import { unitShortNameToUnitName } from "core/common/utils/unitShortNameToUnitName";
import { ProductionRecord } from "core/productions/entities/Productions";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface FinishProductionDIalogProps {
  production: ProductionRecord;
  setShowModal: (showModal: boolean) => void;
  onSuccess?: () => void;
}

const FinishProductionDIalog = (props: FinishProductionDIalogProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { production, setShowModal, onSuccess } = props;

  const [producedQuantity, setProducedQuantity] = useState<number>(0);
  const [realPerformance, setRealPerformance] = useState<string>("0");
  const [comment, setComment] = useState<string>("");
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const finishProductionStatus = useAppSelector(
    (state) => state.productions.finishProduction.status
  );

  const getRealPerformance = (): string => {
    const performance = (producedQuantity * 100) / production.quantityToProduce;
    return performance.toFixed(0);
  };

  const finishProductionHandler = useCallback(() => {
    if (
      producedQuantity >= production.quantityToProduce * 1.5 ||
      producedQuantity <= production.quantityToProduce * 0.5
    ) {
      setErrorMessage(
        t(
          producedQuantity >= production.quantityToProduce
            ? productionManagement.REPORT_PRODUCTION_ERROR_MESSAGE_HIGH
            : productionManagement.REPORT_PRODUCTION_ERROR_MESSAGE_LOW,
          {
            producedQuantity,
            unit: production.unit,
            productionName: production.name,
            quantityToProduce: production.quantityToProduce,
          }
        )
      );
      setShowErrorModal(true);
      return;
    }
    dispatch(
      finishProduction({
        productionId: production.id,
        producedQuantity,
        comment,
      })
    );
  }, [producedQuantity]);

  useEffect(() => {
    if (finishProductionStatus === "succeeded") {
      if (onSuccess) onSuccess();
      dispatch(clearFinishProduction(null));
      setShowModal(false);
    }
  }, [finishProductionStatus]);

  const unit = unitShortNameToUnitName(production.unit).toUpperCase();

  useEffect(() => {
    setRealPerformance(getRealPerformance());
  }, [producedQuantity]);

  return (
    <>
      <Dialog open onClose={() => setShowModal(false)} maxWidth="xs">
        {finishProductionStatus === "loading" && <LinearProgress />}

        <DialogTitle color="primary.main">
          {t(productionManagement.FINISH_PRODUCTION)}
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <DialogContentText fontWeight={600} color="text.primary">
                {production.name}
              </DialogContentText>
            </Grid>
            <Grid item xs={12}>
              <DialogContentText>
                {t(commons.PRODUCTION_QUANTITY)}: {production.quantityToProduce}{" "}
                {unit}
              </DialogContentText>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t(productionManagement.FINISH_REAL_PERFORMANCE)}
                InputProps={{
                  type: "number",
                }}
                variant="outlined"
                value={producedQuantity === 0 ? undefined : producedQuantity}
                onChange={(e) => setProducedQuantity(Number(e.target.value))}
                disabled={finishProductionStatus === "loading"}
                fullWidth
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12}>
              <DialogContentText>
                {t(productionManagement.FINISH_REAL_PERFORMANCE)} (%):{" "}
                {realPerformance} %
              </DialogContentText>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t(commons.COMMENT)}
                multiline
                maxRows={6}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                fullWidth
                autoComplete="off"
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Stack gap={1} width="100%">
            <Button
              onClick={() => finishProductionHandler()}
              variant="contained"
              size="large"
              disabled={
                producedQuantity === 0 || finishProductionStatus === "loading"
              }
              fullWidth
            >
              {t(commons.CONFIRMATION)}
              <ChevronRightOutlinedIcon sx={{ color: "primary" }} />
            </Button>

            <Button
              onClick={() => setShowModal(false)}
              color="error"
              variant="outlined"
              size="large"
              fullWidth
            >
              {t(commons.CANCEL)} <CloseOutlinedIcon sx={{ color: "error" }} />
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <CommonDialog
        open={showErrorModal}
        handleClose={() => {
          setShowErrorModal(false);
        }}
        handleConfirm={() => {
          setShowErrorModal(false);
        }}
        title={t(purchaseOrderLine.DIALOG_ERROR_MESSAGE)}
        message={errorMessage}
        icon={
          <HighlightOffIcon
            sx={{
              height: "80px",
              width: "80px",
              paddingRight: "5px",
              color: "error.main",
            }}
          />
        }
        showCancelButton={false}
      />
    </>
  );
};

export default FinishProductionDIalog;
