import { StoreRequestError, StoreRequestStatus } from "app/store/types";
import { Group } from "core/physicalCount/entities/Group";

export interface CountingState {
  countingId: number | null;
  countingStatus: string | null;
  groups: Array<Group> | null;
  filters: { group: Array<string>; search: string | null };
  status: StoreRequestStatus;
  error: StoreRequestError;
  validationStatus: {
    status: StoreRequestStatus;
    error: StoreRequestError;
    data: 0 | null;
  };
}

export const initialState: CountingState = {
  countingId: null,
  countingStatus: null,
  groups: null,
  filters: {
    group: [],
    search: null,
  },
  status: "idle",
  error: null,

  validationStatus: {
    status: "idle",
    error: null,
    data: null,
  },
};
