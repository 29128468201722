import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import { useMediaQuery } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import InputAdornment from "@mui/material/InputAdornment";
import Popover from "@mui/material/Popover";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { commons, physicalCountModule } from "app/i18n/types";
import { setSearch, toggleGroupFilter } from "app/store/slices/counting";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

interface PhysicalCountFilterProps {}

const PhysicalCountFilter: FunctionComponent<PhysicalCountFilterProps> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [searchDialogOpen, setSearchDialogOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);

  const groups = useAppSelector((state) => state.counting.groups);
  const groupsFilter = useAppSelector((state) => state.counting.filters.group);
  const searchFilter = useAppSelector((state) => state.counting.filters.search);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleSearch = () => {
    if (searchTerm === "") {
      dispatch(setSearch(null));
    } else {
      dispatch(setSearch(searchTerm));
    }
    setSearchTerm(null);
    setSearchDialogOpen(false);
  };

  return (
    <Box>
      <Box display="flex" gap={2}>
        <Button
          size="large"
          fullWidth={isMobile}
          variant="outlined"
          sx={{ bgcolor: "white", px: 8 }}
          onClick={handleClick}
          disabled={searchFilter !== null}
        >
          <Box component={FilterListIcon} mr={2} /> {t(commons.FILTERS)}
          <Avatar
            sx={{
              width: 24,
              height: 24,
              fontSize: 12,
              bgcolor: "primary.main",
              ml: 1,
            }}
          >
            {groupsFilter.length}
          </Avatar>
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={() => setSearchDialogOpen(true)}
        >
          <SearchIcon />
        </Button>
      </Box>

      <Dialog
        open={searchDialogOpen}
        onClose={() => setSearchDialogOpen(false)}
      >
        <DialogTitle>Realizar Búsqueda</DialogTitle>

        <DialogContent>
          <Typography mb={2}>
            Ingresa el nombre del artículo que deseas buscar.
          </Typography>

          <TextField
            id="search-input"
            label={t(physicalCountModule.FILTER_SEARCH_PLACEHOLDER)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={searchTerm ?? ""}
            onChange={(e) => setSearchTerm(e.target.value)}
            variant="outlined"
            size="small"
            sx={{ mb: 2 }}
            fullWidth
            autoComplete="off"
          />

          <Button
            onClick={handleSearch}
            variant="contained"
            size="large"
            fullWidth
          >
            Buscar <ChevronRightIcon />
          </Button>
        </DialogContent>
      </Dialog>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box p={3} width={{ xs: "calc(100vw - 48px)", sm: 330 }}>
          <Typography variant="h5" gutterBottom>
            {t(commons.FILTERS)}
          </Typography>

          <Typography variant="h6" gutterBottom>
            {t(commons.CATEGORIES)}
          </Typography>

          <FormGroup>
            {groups !== null &&
              groups.map((group) => (
                <FormControlLabel
                  key={group.groupName}
                  control={
                    <Checkbox
                      checked={groupsFilter.includes(group.groupName)}
                      onChange={() =>
                        dispatch(toggleGroupFilter(group.groupName))
                      }
                    />
                  }
                  label={t(group.groupName)}
                />
              ))}
          </FormGroup>
        </Box>
      </Popover>
    </Box>
  );
};

export default PhysicalCountFilter;
