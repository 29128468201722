import { UseCase } from "core/common/interfaces/useCase";
import { CanGetLastFinishedCountings } from "core/physicalCount/interfaces/counting/canGetLastFinishedCountings";

export class GetLastFinishedCountingsUseCase implements UseCase {
  constructor(private readonly repository: CanGetLastFinishedCountings) {}

  public execute(initDate: Date, endDate: Date) {
    return this.repository.getLastFinishedCountings(initDate, endDate);
  }
}
