import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Chip, LinearProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { commons } from "app/i18n/types";
import { clearSendProductionPrintEvent } from "app/store/slices/productions";
import { ProductionLabelResponse } from "core/productions/entities/Productions";
import { FunctionComponent, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import ProductionLabelPrintTemplate from "../PrintTemplate";
import ProductionLabelCard from "./Card";

interface Props {
  open: boolean;
  onClose: () => void;
  labels: ProductionLabelResponse[];
  total: number;
  onlyView?: boolean;
}

const ProductionLabelsDialog: FunctionComponent<Props> = (props) => {
  const { open, onClose, labels, total, onlyView } = props;
  const componentRef = useRef(null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const sendProductionPrintEventRequest = useAppSelector(
    (state) => state.productions.sendProductionPrintEvent
  );

  useEffect(() => {
    if (sendProductionPrintEventRequest.status === "succeeded") {
      const timer = setTimeout(() => {
        dispatch(clearSendProductionPrintEvent(null));
      }, 5000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [dispatch, sendProductionPrintEventRequest.status]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={labels?.length ? "xl" : "xs"}
      fullWidth
    >
      {sendProductionPrintEventRequest.status === "loading" && (
        <LinearProgress />
      )}

      <DialogTitle color="primary.main">
        {t(onlyView ? commons.VIEW_LABELS : commons.PRINT_LABELS)}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseOutlinedIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: 2,
            overflowX: "scroll",
            height: "100%",
            pb: 1,
          }}
        >
          {labels.map((label, index) => (
            <Box textAlign={"center"} key={`ProductionLabelCard${index}`}>
              <Box mb={1}>
                <Chip
                  label={`${label.batch.split("|")[2]} de ${total}`}
                  variant="outlined"
                />
              </Box>
              <ProductionLabelCard {...label} />
            </Box>
          ))}
        </Box>
      </DialogContent>

      <ProductionLabelPrintTemplate labels={labels} ref={componentRef} />

      <DialogActions>
        {!onlyView && (
          <Button
            variant="contained"
            onClick={handlePrint}
            startIcon={<ChevronRightOutlinedIcon />}
            disabled={
              sendProductionPrintEventRequest.status !== "idle" ||
              !labels.length
            }
            fullWidth
          >
            {t(commons.PRINT)}
          </Button>
        )}

        <Button
          variant="outlined"
          onClick={onClose}
          color="error"
          startIcon={<CloseOutlinedIcon />}
          fullWidth
        >
          {t(commons.CANCEL)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductionLabelsDialog;
