import { createSlice } from "@reduxjs/toolkit";
import { getGroup, getItem } from "../counting/utils";
import { initialState } from "./state";
import {
  getInactiveSuppliesByLocation,
  getSuppliesByCountry,
  getSupplyInactiveKitchens,
  setProductActive,
  setSupliesStateByKitchens,
} from "./thunks";

export const suppliesSlice = createSlice({
  name: "supplies",
  initialState,
  reducers: {
    toggleGroupFilter: (state, action) => {
      let groupFilter = [...state.filters.group];

      if (state.filters.group.includes(action.payload)) {
        groupFilter = groupFilter.filter(
          (groupName) => groupName !== action.payload
        );
      } else {
        groupFilter.push(action.payload);
      }

      state.filters.group = groupFilter;
    },
    setSearch: (state, action) => {
      state.filters.search = action.payload;
    },
    clearState: (state) => {
      state = initialState;
    },
    clearInactiveKitchensState: (state) => {
      state.supplyInactiveStatusForKitchens =
        initialState.supplyInactiveStatusForKitchens;
    },
    clearSuppliesByCountryState: (state) => {
      state.suppliesByCountry = initialState.suppliesByCountry;
    },
    clearSuppliesByLocationState: (state) => {
      state.inactiveSuppliesByLocation =
        initialState.inactiveSuppliesByLocation;
    },
  },
  extraReducers(builder) {
    builder
      // setProductActive
      .addCase(setProductActive.pending, (state, action) => {
        const { sku } = action.meta.arg;
        if (!state.groups) {
          throw new Error("Groups state is empty");
        }

        try {
          const { group, groupIndex } = getGroup(state.groups, sku);

          const { item, itemIndex } = getItem(group.groupItems, sku);
          item.state.status = "loading";
          item.state.error = null;

          state.groups[groupIndex].groupItems[itemIndex] = item;
        } catch (err) {
          console.error(err);
        }
      })
      .addCase(setProductActive.fulfilled, (state, action) => {
        const { sku, active } = action.meta.arg;

        if (!state.groups) {
          throw new Error("Groups state is empty");
        }

        const { group, groupIndex } = getGroup(state.groups, sku);

        const { itemIndex } = getItem(group.groupItems, sku);
        state.groups[groupIndex].groupItems[itemIndex].active = active;
      })
      .addCase(setProductActive.rejected, (state, action) => {
        const { sku } = action.meta.arg;

        if (!state.groups) {
          throw new Error("Groups state is empty");
        }

        const { group, groupIndex } = getGroup(state.groups, sku);

        const { item, itemIndex } = getItem(group.groupItems, sku);

        item.state.status = "failed";
        item.state.error = action.error.message ?? null;

        state.groups[groupIndex].groupItems[itemIndex] = item;
      })

      // getSuppliesByCountry
      .addCase(getSuppliesByCountry.pending, (state, action) => {
        state.suppliesByCountry = {
          status: "loading",
          supplies: null,
          error: null,
        };
      })
      .addCase(getSuppliesByCountry.fulfilled, (state, action) => {
        state.suppliesByCountry = {
          status: "succeeded",
          supplies: action.payload,
          error: null,
        };
      })
      .addCase(getSuppliesByCountry.rejected, (state, action) => {
        state.suppliesByCountry = {
          supplies: null,
          status: "failed",
          error: action.error.message ?? null,
        };
      })

      // setSupliesStateByKitchens
      .addCase(setSupliesStateByKitchens.pending, (state) => {
        state.setSupliesStateByKitchens = {
          status: "loading",
          error: null,
        };
      })
      .addCase(setSupliesStateByKitchens.fulfilled, (state, action) => {
        const supplies = action.meta.arg;

        supplies.forEach((supply) => {
          if (!supply.state) {
            state.inactiveSuppliesByLocation.inactiveSupplies?.push({
              ...supply,
              createdAt: "",
              state: "",
            });
          } else {
            const index =
              state.inactiveSuppliesByLocation.inactiveSupplies?.findIndex(
                (product) => product.sku === supply.sku
              );
            if (index && index > -1) {
              state.inactiveSuppliesByLocation.inactiveSupplies?.splice(
                index,
                1
              );
            }
          }
        });

        state.setSupliesStateByKitchens = {
          status: "succeeded",
          error: null,
        };
      })
      .addCase(setSupliesStateByKitchens.rejected, (state, action) => {
        state.setSupliesStateByKitchens = {
          status: "failed",
          error: action.error.message ?? null,
        };
      })

      // getSupplyInactiveKitchens
      .addCase(getSupplyInactiveKitchens.pending, (state) => {
        state.supplyInactiveStatusForKitchens = {
          inactiveKitchens: null,
          status: "loading",
          error: null,
        };
      })
      .addCase(getSupplyInactiveKitchens.fulfilled, (state, action) => {
        state.supplyInactiveStatusForKitchens = {
          inactiveKitchens: action.payload,
          status: "succeeded",
          error: null,
        };
      })
      .addCase(getSupplyInactiveKitchens.rejected, (state, action) => {
        state.supplyInactiveStatusForKitchens = {
          inactiveKitchens: null,
          status: "failed",
          error: action.error.message ?? null,
        };
      })

      // getInactiveSuppliesByLocation
      .addCase(getInactiveSuppliesByLocation.pending, (state) => {
        state.inactiveSuppliesByLocation = {
          inactiveSupplies: null,
          status: "loading",
          error: null,
        };
      })
      .addCase(getInactiveSuppliesByLocation.fulfilled, (state, action) => {
        state.inactiveSuppliesByLocation = {
          inactiveSupplies: action.payload,
          status: "succeeded",
          error: null,
        };
      })
      .addCase(getInactiveSuppliesByLocation.rejected, (state, action) => {
        state.inactiveSuppliesByLocation = {
          inactiveSupplies: null,
          status: "failed",
          error: action.error.message ?? null,
        };
      });
  },
});

export const {
  toggleGroupFilter,
  setSearch,
  clearState,
  clearInactiveKitchensState,
  clearSuppliesByCountryState,
  clearSuppliesByLocationState,
} = suppliesSlice.actions;

export const { reducer } = suppliesSlice;

export default suppliesSlice;
