import {
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSEmptyState,
  KOSRowData,
} from "@foodology-co/alejandria";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Chip, IconButton } from "@mui/material";
import { commons, vendor } from "app/i18n/types";
import { getCityName } from "app/utils/city.utils";
import { VendorSupply } from "core/vendors/entities/VendorSupply";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { formatPrice, localeData } from "utils/currency";
import { getVendorCategoryColor } from "utils/general";
import { Actions, VendorSupplyModal } from "utils/modal";
import { numberFormat } from "utils/number";

interface Props {
  supplies: VendorSupply[];
  country: string;
  loading?: boolean;
  setModal: (modal: VendorSupplyModal) => void;
  setArchiveId: (archiveId: number) => void;
}

const VendorSupplyTable: FunctionComponent<Props> = (props) => {
  const { supplies, country, loading, setModal, setArchiveId } = props;

  const { t } = useTranslation();

  const getHeader = (): KOSBaseTableHeader[] => [
    {
      label: t(commons.SKU),
      field: "supplySku",
      align: "center",
      sort: true,
    },
    {
      label: t(commons.CATEGORY),
      text: (item: KOSRowData) => t(item.supplyCategory),
    },
    {
      label: t(vendor.SUPPLY_NAME),
      field: "supplyName",
      sort: true,
    },
    {
      label: t(vendor.VENDOR_SUPPLY_NAME),
      field: "vendorSupplyName",
      align: "center",
    },
    {
      label: t(vendor.VENDOR_UNIT),
      align: "center",
      text: (item: KOSRowData) => {
        const vendorUnit = item.vendorUnit as string;
        if (!vendorUnit.trim()) return undefined;
        return t(`vendorMeasureUnit.${vendorUnit.trim()}`);
      },
    },
    {
      label: t(vendor.SUPPLY_QUANTITY),
      align: "center",
      text: (item: KOSRowData) => {
        const supplyQuantity = numberFormat(
          item.supplyQuantity,
          localeData[country]
        );
        return `${supplyQuantity} ${item.supplyUnit}`;
      },
    },
    {
      label: t(vendor.VENDOR_UNIT_PRICE),
      align: "right",
      text: (item: KOSRowData) =>
        formatPrice(item.vendorUnitPrice, localeData[country]),
    },
    {
      label: t(vendor.SUPPLY_UNIT),
      field: "supplyUnit",
      align: "center",
    },
    {
      label: t(vendor.SUPPLY_UNIT_PRICE),
      align: "right",
      text: (item: KOSRowData) =>
        formatPrice(item.supplyUnitPrice, localeData[country], 5),
    },
    {
      label: t(vendor.CATEGORY),
      align: "center",
      component: (item: KOSRowData) => {
        const type = item.vendorType.toUpperCase();
        return (
          <Chip
            label={`${t(commons.TYPE)} ${type}`}
            color={getVendorCategoryColor(type)}
          />
        );
      },
    },
    {
      label: t(commons.CITY),
      text: (item: KOSRowData) => getCityName(item.city as string),
    },
    {
      label: t(commons.ACTIONS),
      align: "center",
      component: (item: KOSRowData) => {
        return (
          <>
            <IconButton
              onClick={() =>
                setModal({
                  open: true,
                  action: Actions.edit,
                  item: item as VendorSupply,
                })
              }
              color="warning"
            >
              <EditOutlinedIcon />
            </IconButton>
            <IconButton
              onClick={() => setArchiveId(item.id)}
              color="error"
              size="small"
              sx={{ height: "100%" }}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <KOSBaseTable
      columns={getHeader()}
      rows={{
        data: supplies,
        total: supplies.length ?? 0,
        loading: !!loading,
      }}
      pagination={{ enable: true }}
      emptyState={
        <KOSEmptyState
          icon={InfoOutlinedIcon}
          message={t(commons.TABLE_EMPTY)}
        />
      }
    />
  );
};

export default VendorSupplyTable;
