import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { FunctionComponent } from "react";
import CommonDialog from "../common/CommonDialog";

interface DailyAdjustmenDialogProps {
  message: string;
  open: boolean;
  handledClose: () => void;
  handledConfirm: () => void;
  title: string;
  showCancelButton: boolean;
}

const DailyAdjustmenDialog: FunctionComponent<DailyAdjustmenDialogProps> = (
  props
) => {
  const {
    message,
    open,
    title,
    showCancelButton,
    handledClose,
    handledConfirm,
  } = props;

  return (
    <CommonDialog
      open={open}
      handleClose={handledClose} // Actualizar esta línea para usar la función de cierre del diálogo
      handleConfirm={handledConfirm}
      title={title}
      message={message}
      icon={
        <InfoOutlined
          sx={{
            height: "80px",
            width: "80px",
            paddingRight: "5px",
            color: "#ED6C02",
          }}
        />
      }
      showCancelButton={showCancelButton}
    />
  );
};

export default DailyAdjustmenDialog;
