/* eslint-disable react/require-default-props */
import React from "react";

interface IconProp {
  fontSize?: string;
  color?: string;
}

const DataAlertIcon = (props: IconProp): React.ReactElement => {
  const { fontSize, color } = props;
  return (
    <svg
      width={fontSize ?? 23}
      height={fontSize ?? 22}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.25 18V16L2.25 14V18H0.25ZM4.25 18V12L6.25 10V18H4.25ZM8.25 18V10L10.25 12.025V18H8.25ZM12.25 18V12.025L14.25 10.025V18H12.25ZM16.25 18V8L18.25 6V18H16.25ZM0.25 12.825V10L7.25 3L11.25 7L18.25 0V2.825L11.25 9.825L7.25 5.825L0.25 12.825Z"
        fill={color ?? "#FFF"}
      />
    </svg>
  );
};

export default DataAlertIcon;
