import { commons } from "app/i18n/types";
import { number, object, string } from "yup";

export const handleValidations = object({
  sku: string().required(commons.REQUIRED_FIELD),
  quantity: number()
    .min(1)
    .typeError(commons.QUANTITY_ERROR)
    .required(commons.REQUIRED_FIELD),
  scheduledDate: string().required(commons.REQUIRED_FIELD),
  timeSlot: string().required(commons.REQUIRED_FIELD),
});
