import { UseCase } from "core/common/interfaces/useCase";
import { CanSetProductActive } from "core/physicalCount/interfaces/product/canSetProductActive";

export class SetProductActivenUseCase implements UseCase {
  constructor(private readonly repository: CanSetProductActive) {}

  public execute(sku: string, kitchenId: string, active: boolean) {
    this.repository.setProductActive(sku, kitchenId, active);
    return active;
  }
}
