import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { FunctionComponent } from "react";

interface InvetoryRecordSkeletonProps {}

const ItemCountHistorySkeleton: FunctionComponent<
  InvetoryRecordSkeletonProps
> = () => {
  return (
    <Box display="flex" flexDirection="column" gap={2} mb={2}>
      <Skeleton variant="rounded" width="100%" height={56} />

      <Skeleton variant="rounded" width="100%" height={56} />
    </Box>
  );
};

export default ItemCountHistorySkeleton;
