import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { transferOrdersPackedReviewTable } from "app/i18n/types";
import { updateTransferOrderLineQuantity } from "app/store/slices/transfer/thunks";
import { StoreRequestError, StoreRequestStatus } from "app/store/types";
import { TransferOrderLineRefined } from "core/transfer/entities/TransferOrder";
import { FunctionComponent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getTransferItemServiceLevel } from "utils/general";
import NumberField from "../common/Field/Number";

interface TransferOrdersReviewPackedTableRowProps {
  line: TransferOrderLineRefined;
  lineStatus: {
    status: StoreRequestStatus;
    error: StoreRequestError;
  } | null;
  disabled: boolean;
  updateLine: (id: number, sendQuantity: number) => void;
}

const TransferOrdersReviewPackedTableRow: FunctionComponent<
  TransferOrdersReviewPackedTableRowProps
> = (props) => {
  const { line, lineStatus, disabled, updateLine } = props;

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [sendQuantity, setSendQuantity] = useState<number>(line.sendQuantity);

  const statusColor = useMemo(() => {
    if (sendQuantity >= line.quantity) {
      return "success";
    }

    if (sendQuantity < line.quantity && sendQuantity > 0) {
      return "warning";
    }

    return "error";
  }, [line.quantity, sendQuantity]);

  const statusLabel = useMemo(() => {
    if (sendQuantity >= line.quantity) {
      return t(transferOrdersPackedReviewTable.COMPLETE);
    }

    if (sendQuantity < line.quantity && sendQuantity > 0) {
      return t(transferOrdersPackedReviewTable.PARTIAL);
    }

    return t(transferOrdersPackedReviewTable.NO_SENT);
  }, [line.quantity, sendQuantity]);

  const statusIcon = useMemo(() => {
    if (sendQuantity >= line.quantity) {
      return <CheckCircleOutlineOutlinedIcon />;
    }

    if (sendQuantity < line.quantity) {
      return <CircleOutlinedIcon />;
    }

    return <CancelOutlinedIcon />;
  }, [line.quantity, sendQuantity]);

  const onUpdateSendQuantity = useCallback(() => {
    if (
      sendQuantity !== null &&
      sendQuantity !== undefined &&
      !isNaN(sendQuantity)
    ) {
      dispatch(
        updateTransferOrderLineQuantity({
          transferLineId: line.id,
          updateQuantityAndReason: {
            quantity: sendQuantity ?? 0,
            transferOrderStep: "AUDIT",
            sku: line.sku,
          },
        })
      );
      updateLine(line.id, sendQuantity);
    }
  }, [sendQuantity, dispatch, line.id, line.sku]);

  const serviceLevelResult = getTransferItemServiceLevel(
    sendQuantity,
    line.quantity
  );

  return (
    <TableRow
      sx={{
        bgcolor: "white",
        "&.selected > td": {
          borderBottomColor: "#ccdef1",
        },
        "&:hover": {
          bgcolor: "#fafafa",
          "& .show-on-hover": {
            display: "flex",
          },
        },
      }}
    >
      <TableCell>{line.sku}</TableCell>

      <TableCell>
        <Typography>{line.name}</Typography>
      </TableCell>

      <TableCell>
        <Typography
          textTransform="uppercase"
          fontWeight={700}
          color="text.secondary"
        >
          {t(`measureUnits.${line.measureUnit.toLowerCase()}`)}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography>{line.quantity}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{line.packedQuantity}</Typography>
      </TableCell>

      <TableCell align="center">
        <Box display="flex" alignItems="center" justifyContent="start" gap={1}>
          <NumberField
            name="value"
            value={sendQuantity}
            onChangeValue={(newValue) => setSendQuantity(newValue)}
            label={t(transferOrdersPackedReviewTable.QUANTITY_TO_SEND)}
            size="small"
            color={statusColor}
            error={
              lineStatus !== null &&
              lineStatus !== undefined &&
              lineStatus.error !== null
            }
            onBlur={onUpdateSendQuantity}
            sx={{ width: 180 }}
            disabled={disabled}
            focused
          />
        </Box>
      </TableCell>

      <TableCell>
        <Typography color={serviceLevelResult.color}>
          {serviceLevelResult.serviceLevelPercentage}
        </Typography>
      </TableCell>

      <TableCell align="center">
        <Chip icon={statusIcon} label={statusLabel} color={statusColor} />
      </TableCell>
    </TableRow>
  );
};

export default TransferOrdersReviewPackedTableRow;
