import { KOSEmptyState } from "@foodology-co/alejandria";
import { InventoryOutlined } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import PurchaseOrderItem from "app/components/PurchaseOrder/Create/Pending/PurchaseOrderItem";
import { purchaseOrderCreation } from "app/i18n/types";
import {
  PurchaseOrder,
  PurchaseOrderLine,
} from "core/purchaseOrders/entities/PurchaseOrder";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { OnChange } from "utils/general";

interface Props {
  fileErrors: string[];
  purchaseOrders: PurchaseOrder[];
  purchaseOrdersFiltered: PurchaseOrder[];
  setPurchaseOrders: (orders: PurchaseOrder[]) => void;
  getPurchaseOrders: () => void;
  change: OnChange;
  setChange: (change: OnChange) => void;
  checkedPurchaseOrders: number[];
  toggleChecked: (purchaseOrderId?: number) => void;
  emptySearchMessage: string;
  getPurchaseOrderLinesRefined: (
    purchaseOrder: PurchaseOrder,
    change: Partial<PurchaseOrderLine>,
    lineId?: number
  ) => PurchaseOrderLine[];
}

const CreatePurchaseOrderPending = (props: Props) => {
  const {
    fileErrors,
    purchaseOrders,
    purchaseOrdersFiltered,
    setPurchaseOrders,
    getPurchaseOrders,
    change,
    setChange,
    checkedPurchaseOrders,
    toggleChecked,
    emptySearchMessage,
    getPurchaseOrderLinesRefined,
  } = props;

  const { t } = useTranslation();

  const getPurchaseLinesRefined = (
    change: OnChange,
    purchaseOrder: PurchaseOrder,
    valueTochange: number
  ) => {
    switch (change.label) {
      case "detailNew":
        return getPurchaseOrderLinesRefined(
          purchaseOrder,
          change.value as PurchaseOrderLine,
          change.detailId
        );
      case "detailQuantity":
        return getPurchaseOrderLinesRefined(
          purchaseOrder,
          { quantity: valueTochange },
          change.detailId
        );
      case "detailUnitPrice":
        return getPurchaseOrderLinesRefined(
          purchaseOrder,
          { unitPrice: valueTochange },
          change.detailId
        );
      default:
        return purchaseOrder.lines;
    }
  };

  useEffect(() => {
    if (change.label === "removeMany") {
      const ids = change.value as number[];
      setPurchaseOrders(purchaseOrders.filter((el) => !ids.includes(el.id)));
    }
    if (change.id) {
      const ordersTmp = purchaseOrders.map((purchaseOrder) => {
        if (purchaseOrder.id === change.id) {
          if (change.label === "detailRemoveMany") {
            const ids = change.value as number[];
            return {
              ...purchaseOrder,
              lines: purchaseOrder.lines.filter((el) => !ids.includes(el.id)),
            };
          }
          const lines = getPurchaseLinesRefined(
            change,
            purchaseOrder,
            Number(change.value ?? 0)
          );
          switch (change.label) {
            case "expectedReceiptAt":
              return {
                ...purchaseOrder,
                expectedReceiptAt: String(change.value),
                lines,
              };
          }
          return {
            ...purchaseOrder,
            lines,
          };
        }
        return purchaseOrder;
      });
      setPurchaseOrders(ordersTmp);
    }
  }, [change]);

  const isCheckedAll =
    !!checkedPurchaseOrders.length &&
    checkedPurchaseOrders.length === purchaseOrders.length;

  return (
    <>
      <Alert
        icon={<InventoryOutlined sx={{ color: "white" }} />}
        sx={{
          bgcolor: "success.light",
          "& .MuiAlert-message": {
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            padding: 0,
            alignItems: "center",
          },
        }}
      >
        <Typography color="white" fontWeight={600}>
          {t(purchaseOrderCreation.DETECTED_ORDERS_TITLE)}
        </Typography>
        <Box>
          <Chip
            label={<Typography>{purchaseOrders.length}</Typography>}
            sx={{ bgcolor: "white" }}
          />
          <Checkbox
            sx={{ paddingY: 0 }}
            onChange={() => toggleChecked()}
            value={isCheckedAll}
            checked={isCheckedAll}
          />
        </Box>
      </Alert>
      <Box>
        {fileErrors.length > 0 && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {fileErrors.map((error) => (
              <Typography key={error} fontWeight={700} color="text.secondary">
                {error}
              </Typography>
            ))}
          </Alert>
        )}
        {!purchaseOrders.length && (
          <KOSEmptyState
            icon={InfoOutlinedIcon}
            message={t(purchaseOrderCreation.NO_ORDENES_DETECTED)}
          />
        )}
        {!!purchaseOrders.length && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            mt={2}
          >
            {!purchaseOrdersFiltered.length && (
              <KOSEmptyState
                icon={InfoOutlinedIcon}
                message={emptySearchMessage}
              />
            )}
            {purchaseOrdersFiltered.map((purchase, i) => (
              <PurchaseOrderItem
                key={`PurchaseOrderItem${purchase.id}`}
                purchase={purchase}
                checkedPurchaseOrders={checkedPurchaseOrders}
                toggleChecked={toggleChecked}
                onPurchaseOrderChange={setChange}
                openAddLineModal={!purchase.lines.length && i === 0}
                updateOrders={getPurchaseOrders}
              />
            ))}
          </Box>
        )}
      </Box>
    </>
  );
};

export default CreatePurchaseOrderPending;
