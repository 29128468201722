import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { FunctionComponent } from "react";

interface PurchaseOrdersLinesSkeletonProps {}

const PurchaseOrdersLinesSkeleton: FunctionComponent<
  PurchaseOrdersLinesSkeletonProps
> = () => {
  return (
    <TableBody>
      {Array.from(Array(5)).map((elOne, indexOne) => (
        <TableRow key={`PurchaseOrdersLinesSkeleton${indexOne}`}>
          {Array.from(Array(5)).map((elTwo, indexTwo) => (
            <TableCell align="left" key={indexTwo}>
              <Typography>
                <Skeleton />
              </Typography>
            </TableCell>
          ))}
          <TableCell align="left">
            <Button variant="contained" color="error" disabled>
              <CloseIcon />
            </Button>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default PurchaseOrdersLinesSkeleton;
