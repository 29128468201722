/* eslint-disable react/require-default-props */
import React from "react";

interface IconProp {
  fontSize?: string;
}

const CreatePurchaseOrderIcon = (props: IconProp): React.ReactElement => {
  const { fontSize } = props;
  return (
    <svg
      width={fontSize ?? 40}
      height={fontSize ?? 40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width={fontSize ?? 40}
        height={fontSize ?? 40}
        rx="20"
        fill="#F1EFEF"
      />
      <mask
        id="mask0_5943_253"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="24"
        height="24"
      >
        <rect x="8" y="8" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5943_253)">
        <path
          d="M15 30C14.45 30 13.9792 29.8042 13.5875 29.4125C13.1958 29.0208 13 28.55 13 28C13 27.45 13.1958 26.9792 13.5875 26.5875C13.9792 26.1958 14.45 26 15 26C15.55 26 16.0208 26.1958 16.4125 26.5875C16.8042 26.9792 17 27.45 17 28C17 28.55 16.8042 29.0208 16.4125 29.4125C16.0208 29.8042 15.55 30 15 30ZM25 30C24.45 30 23.9792 29.8042 23.5875 29.4125C23.1958 29.0208 23 28.55 23 28C23 27.45 23.1958 26.9792 23.5875 26.5875C23.9792 26.1958 24.45 26 25 26C25.55 26 26.0208 26.1958 26.4125 26.5875C26.8042 26.9792 27 27.45 27 28C27 28.55 26.8042 29.0208 26.4125 29.4125C26.0208 29.8042 25.55 30 25 30ZM14.15 14L16.55 19H23.55L26.3 14H14.15ZM13.2 12H27.95C28.3333 12 28.625 12.1708 28.825 12.5125C29.025 12.8542 29.0333 13.2 28.85 13.55L25.3 19.95C25.1167 20.2833 24.8708 20.5417 24.5625 20.725C24.2542 20.9083 23.9167 21 23.55 21H16.1L15 23H27V25H15C14.25 25 13.6833 24.6708 13.3 24.0125C12.9167 23.3542 12.9 22.7 13.25 22.05L14.6 19.6L11 12H9V10H12.25L13.2 12Z"
          fill="#232323"
        />
      </g>
    </svg>
  );
};

export default CreatePurchaseOrderIcon;
