import { CalendarMonth, Grading } from "@mui/icons-material";
import TagOutlinedIcon from "@mui/icons-material/TagOutlined";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { commons, transferOrderModule } from "app/i18n/types";
import { convertLocalDate } from "app/utils/dateUtils";
import { TransferResponse } from "core/transfer/entities/TransferOrder";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface TransferOrderDetailsPrincipalProps {
  transferOrder: TransferResponse;
}

const TransferOrderDetailsPrincipal: FunctionComponent<
  TransferOrderDetailsPrincipalProps
> = ({ transferOrder }) => {
  const { t } = useTranslation();

  return (
    <Grid item sm={9} xs={12}>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {transferOrder.destinationKitchenName ?? ""}
        </Typography>
        <Box
          sx={{
            display: "flex",
            mb: 1,
            gap: 1,
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <TagOutlinedIcon />
            <Box>
              <Typography>{t(transferOrderModule.TRANSFER_ID)}</Typography>
              <Typography sx={{ fontWeight: "bold" }}>
                {transferOrder.id}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Box display="flex" gap={1} alignItems="center">
              <CalendarMonth />
              <Box>
                <Typography>{t(commons.SEND_DATE)}</Typography>
                <Typography fontWeight="bold">
                  {transferOrder.sendAt
                    ? convertLocalDate(transferOrder.sendAt, "date")
                    : "--/--/----"}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Box display="flex" gap={1} alignItems="center">
              <Grading />
              <Box>
                <Typography>{t(commons.STATUS)}</Typography>
                <Typography fontWeight="bold">
                  {t(`status.${transferOrder.status}`)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};

export default TransferOrderDetailsPrincipal;
