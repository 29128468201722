import { CheckCircleOutline } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Icon,
  IconButton,
  Typography,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { commons, dailyAdjustmentsModule } from "app/i18n/types";
import { getListProducts } from "app/store/slices/dailyAdjustments/thunks";
import { AdjustmentRecord } from "core/dailyAdjustments/entities/Adjustment";
import { Product } from "core/dailyAdjustments/entities/Catalog";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getProductImageDefault } from "utils/general";
import { preApprroveDailyAdjustment } from "utils/ratatouille";

interface Props {
  adjustment: AdjustmentRecord;
  product: Product;
  kitchenId: string;
  open: boolean;
  onClose: () => void;
  getAdjusments: () => void;
}

const FinishedProductDrawer: FunctionComponent<Props> = (props) => {
  const { adjustment, product, kitchenId, open, onClose, getAdjusments } =
    props;
  const [ingredients, setIngredients] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedIngredients, setSelectedIngredients] = useState<string[]>([]);

  const products = useAppSelector((state) => state.global.products.data);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const getIngredientsInfo = (product: Product) => {
    const ingredientsTemp: Product[] = [];
    product.ingredients.forEach((ingredient) => {
      const ingredientInfo = products.find((p) => p.sku === ingredient.sku);
      if (ingredientInfo !== undefined) {
        ingredientsTemp.push(ingredientInfo);
      }
    });
    setIngredients(ingredientsTemp);
  };

  const getIngredientQuantity = (sku: string) => {
    const ingredient = product.ingredients.find(
      (ingredient) => ingredient.sku === sku
    );
    return ingredient ? ingredient.quantity * adjustment.quantity : 0;
  };

  const handlePreApproveAdjustment = () => {
    setLoading(true);
    preApprroveDailyAdjustment({
      id: adjustment.id,
      quantity: adjustment.quantity,
      sku: adjustment.sku,
      kitchenId,
      type: adjustment.typeLoss,
      ingredients: selectedIngredients,
    }).then(() => {
      setLoading(false);
      getAdjusments();
      onClose();
    });
  };

  useEffect(() => {
    if (kitchenId) {
      dispatch(getListProducts({ kitchenId }));
    }
  }, [dispatch, kitchenId]);

  useEffect(() => {
    getIngredientsInfo(product);
  }, [products, product]);

  useEffect(() => {
    setSelectedIngredients(
      ingredients.map((ingredient) => {
        return ingredient.sku;
      })
    );
  }, [ingredients]);

  const onChangeCheckBox = useCallback(
    (ingredient: Product) => {
      setSelectedIngredients((prev) => {
        if (prev.includes(ingredient.sku)) {
          return prev.filter((sku) => sku !== ingredient.sku);
        }
        return [...prev, ingredient.sku];
      });
    },
    [setSelectedIngredients]
  );

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          width: 480,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          alignItems: "center",
        }}
      >
        <IconButton
          onClick={onClose}
          color="default"
          sx={{
            position: "absolute",
            top: 8,
            left: 8,
            backgroundColor: "white",
            "&:hover": {
              backgroundColor: "white",
            },
          }}
        >
          <Icon>close</Icon>
        </IconButton>
        <img
          src={product.image || getProductImageDefault()}
          alt={product.name}
          width={"100%"}
          height={300}
        />
        <Typography variant="h5" color="primary" sx={{ textAlign: "center" }}>
          {product.name}
        </Typography>
        <Button
          variant="outlined"
          sx={{ width: "max-content", my: 2 }}
          size="large"
          onClick={handlePreApproveAdjustment}
          disabled={loading}
        >
          <Box display="flex" gap={1}>
            {loading ? <CircularProgress size={20} /> : <CheckCircleOutline />}
            <Typography>
              {t(dailyAdjustmentsModule.PRE_AUTHORIZE_ADJUSMENT_BUTTON)}
            </Typography>
          </Box>
        </Button>
        <Box sx={{ px: 2, width: "100%" }}>
          <Alert severity="info" sx={{ width: "100%" }}>
            {t(dailyAdjustmentsModule.PRODUCT_DRAWER_INFO)}
          </Alert>
          <Box bgcolor="background.default" width="100%">
            <Typography variant="h6" color="primary" m={2}>
              {t(commons.SUPPLIES)}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" width="100%" gap={1}>
            {ingredients.map((ingredient, i) => (
              <>
                <Box
                  key={ingredient.sku}
                  display="flex"
                  width="100%"
                  alignItems="center"
                  justifyContent="space-between"
                  m={1}
                  gap={2}
                >
                  <Box display="flex">
                    <Checkbox
                      checked={selectedIngredients.includes(ingredient.sku)}
                      onChange={() => {
                        onChangeCheckBox(ingredient);
                      }}
                    />
                    <Typography>{ingredient.name}</Typography>
                  </Box>
                  <Typography fontWeight={600}>
                    {getIngredientQuantity(ingredient.sku)} {ingredient.unit}
                  </Typography>
                </Box>
                {i !== ingredients.length - 1 && <Divider />}
              </>
            ))}
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default FinishedProductDrawer;
