import StoreMallDirectoryOutlinedIcon from "@mui/icons-material/StoreMallDirectoryOutlined";
import { Button, Grid } from "@mui/material";
import KitchenSelectorAutocompletes from "app/components/Kitchen/Autocomplete";
import { useAppSelector } from "app/hooks/useAppSelector";
import { physicalCountSchedule } from "app/i18n/types";
import {
  citiesOptions,
  countriesOptions,
  locationsOptionsByCity,
} from "app/utils/kitchenSelector.utils";
import { Kitchen } from "core/supplies/entities/Kitchen";
import React, { FunctionComponent, memo } from "react";
import { useTranslation } from "react-i18next";
import {
  PhysicalCountScheduleLocation,
  allCitiesLabel,
  allKitchens,
} from "utils/general";
import LocationStepTable from "./Table";

interface LocationStepProps {
  locations: PhysicalCountScheduleLocation[];
  setLocations: (locations: PhysicalCountScheduleLocation[]) => void;
  size?: "small" | "medium";
}

const LocationStep: FunctionComponent<LocationStepProps> = (props) => {
  const { locations, setLocations, size } = props;

  const { t } = useTranslation();

  const kitchens = useAppSelector((state) => state.global.kitchens.data);
  const [selectedCountry, setSelectedCountry] = React.useState<string | null>(
    null
  );
  const [selectedCity, setSelectedCity] = React.useState<string | null>(null);
  const [selectedLocation, setSelectedLocation] =
    React.useState<Kitchen | null>(null);
  const countriesOptionsTmp = countriesOptions(kitchens);
  const citiesOptionsTmp = citiesOptions(selectedCountry, kitchens);
  const locationsOptionsTmp = locationsOptionsByCity(
    selectedCity,
    kitchens,
    null,
    {}
  );

  React.useEffect(() => {
    setSelectedCity(null);
    setSelectedLocation(null);
  }, [selectedCountry]);

  React.useEffect(() => {
    setSelectedLocation(null);
  }, [selectedCity]);

  const getNewElement = (): PhysicalCountScheduleLocation => {
    return {
      country: selectedCountry ?? "",
      city: selectedCity ?? "",
      kitchenName: selectedLocation?.name ?? allKitchens.name,
      kitchenId: selectedLocation?.kitchenId ?? "",
      kitchens: [],
    };
  };

  const doNotAdd = (
    locationsToAdd: PhysicalCountScheduleLocation[],
    newEl: PhysicalCountScheduleLocation
  ): boolean => {
    const countriesInTable = locationsToAdd.map((el) => el.country);
    return !!locationsToAdd.length && !countriesInTable.includes(newEl.country);
  };

  const foundElement = (
    locationsToAdd: PhysicalCountScheduleLocation[],
    newEl: PhysicalCountScheduleLocation
  ): PhysicalCountScheduleLocation | undefined => {
    const found = locationsToAdd.find(
      (el) =>
        el.country === newEl.country &&
        el.city === newEl.city &&
        el.kitchenId === newEl.kitchenId
    );
    return found;
  };

  const selectedCityValidate = (newElement: PhysicalCountScheduleLocation) => {
    if (selectedCity === allCitiesLabel) {
      citiesOptionsTmp.forEach((city) => {
        const kitchensTmp = locationsOptionsByCity(city, kitchens, null, {});
        newElement.kitchens = [...newElement.kitchens, ...kitchensTmp];
      });
    }
  };

  const selectedLocationValidate = (
    newElement: PhysicalCountScheduleLocation
  ) => {
    if (selectedLocation) {
      if (selectedLocation.kitchenId === allKitchens.kitchenId) {
        newElement.kitchens = [...locationsOptionsTmp];
      } else {
        newElement.kitchens = [selectedLocation];
      }
    }
  };

  const handleAddLocation = () => {
    const locationsToAdd = [...locations];
    const newElement = getNewElement();
    if (doNotAdd(locationsToAdd, newElement)) {
      return;
    }
    const found = foundElement(locationsToAdd, newElement);
    if (found) {
      return;
    }
    selectedCityValidate(newElement);
    selectedLocationValidate(newElement);
    locationsToAdd.push(newElement);
    setLocations(locationsToAdd);
  };

  const removeDate = (index: number) => {
    const locationsToRm = [...locations];
    locationsToRm.splice(index, 1);
    setLocations(locationsToRm);
  };

  let disableButtonAdd = true;
  if (selectedCity === allCitiesLabel) {
    disableButtonAdd = false;
  } else {
    disableButtonAdd = !selectedLocation;
  }

  return (
    <Grid container spacing={2}>
      <Grid item sm={10} xs={12}>
        <KitchenSelectorAutocompletes
          countriesOptions={countriesOptionsTmp}
          citiesOptions={citiesOptionsTmp}
          locationsOptions={locationsOptionsTmp}
          selectedCountry={selectedCountry}
          selectedCity={selectedCity}
          selectedLocation={selectedLocation}
          setSelectedCountry={setSelectedCountry}
          setSelectedCity={setSelectedCity}
          setSelectedLocation={setSelectedLocation}
          allCityOrKitchens
        />
      </Grid>
      <Grid item sm={2} xs={12}>
        <Button
          onClick={handleAddLocation}
          variant="outlined"
          size={size}
          fullWidth
          sx={{ height: "100%" }}
          disabled={disableButtonAdd}
          startIcon={<StoreMallDirectoryOutlinedIcon />}
        >
          {t(physicalCountSchedule.ADD_LOCATION)}
        </Button>
      </Grid>
      <Grid item sm={12} xs={12}>
        <LocationStepTable
          locations={locations}
          removeDate={removeDate}
          size={size}
        />
      </Grid>
    </Grid>
  );
};

export default memo(LocationStep);
