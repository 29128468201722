import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import PageHeader from "app/components/PageHeader";
import ProductionLiberationTable from "app/components/Productions/Liberation/Table";
import SearchOnTopBar from "app/components/common/SearchOnTopBar";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { useNavigator } from "app/hooks/useNavigator";
import { commons, productionLiberation } from "app/i18n/types";
import { getProductionsByKitchen } from "app/store/slices/productions/thunks";
import { ProductionRecord } from "core/productions/entities/Productions";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMount } from "react-use";
import { compareSearchText } from "utils/general";
import { PageBox } from "utils/generalUI";

interface Props {}

const ProductionLiberationPage: FunctionComponent<Props> = () => {
  const navigator = useNavigator();
  const { kitchenId } = navigator.params();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const production = useAppSelector(
    (state) => state.productions.productionList
  );

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredProductions, setFilteredProductions] = useState<
    ProductionRecord[]
  >([]);

  const getProductionsData = useCallback(() => {
    if (kitchenId) {
      dispatch(getProductionsByKitchen({ kitchenId, status: "TO_APPROVE" }));
    }
  }, [kitchenId, dispatch]);

  const searchFilter = (order: ProductionRecord) => {
    if (!searchTerm) return true;
    return (
      compareSearchText(order.id, searchTerm) ||
      compareSearchText(order.sku, searchTerm) ||
      compareSearchText(order.name, searchTerm)
    );
  };

  useEffect(() => {
    const list = production.list?.filter((el) => searchFilter(el));
    setFilteredProductions(list ?? []);
  }, [production.list, searchTerm]);

  useMount(() => {
    getProductionsData();
  });

  return (
    <PageBox>
      <PageHeader
        title={productionLiberation.TITLE}
        rightArea={[
          {
            children: t(commons.UPDATE),
            variant: "outlined",
            startIcon: <RestartAltOutlinedIcon />,
            onClick: getProductionsData,
          },
        ]}
      />
      <SearchOnTopBar onSearch={setSearchTerm} />
      <ProductionLiberationTable
        productions={filteredProductions}
        loading={production.status === "loading"}
      />
    </PageBox>
  );
};

export default ProductionLiberationPage;
