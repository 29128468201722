import { ModifyPayload } from "core/purchaseOrders/entities/PurchaseOrder";
import { CanUpdateLinesQuantityAndUnitPrice } from "core/purchaseOrders/traits/receiptOrders/canUpdateLinesQuantityAndUnitPrice";

export class UpdateLinesQuantityAndUnitPriceUseCase {
  constructor(
    private readonly repository: CanUpdateLinesQuantityAndUnitPrice
  ) {}

  public execute(payload: ModifyPayload) {
    return this.repository.updateLinesQuantityAndUnitPrice(payload);
  }
}
