import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import { Checkbox, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import KitchenSelector from "app/components/Kitchen/Selector";
import PageHeader from "app/components/PageHeader/PageHeader";
import CommonDialog, {
  CommonDialogContent,
} from "app/components/common/CommonDialog";
import NumberField from "app/components/common/Field/Number";
import InputSearchCustom from "app/components/common/InputSearchCustom";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import {
  adjustmentType,
  commons,
  dailyAdjustmentsModule,
  kitchenInventoryManagement,
  theoricalInventoryModule,
} from "app/i18n/types";
import {
  getLastCountingByKitchen,
  validateCounting,
} from "app/store/slices/counting/thunks";
import { localizeDayjs } from "app/utils/dayjs";
import {
  EntryType,
  ManualAdjustment,
} from "core/adjustments/entities/ManualAdjustment";
import {
  CountingDetail,
  CountingResponse,
  LastRecountResponse,
} from "core/physicalCount/entities/Counting";
import { Kitchen } from "core/supplies/entities/Kitchen";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { PageBox } from "utils/generalUI";
import EmptyAuthorizationTable from "../../components/DailyAdjustments/Authorization/Table/Empty";

type AdjustmentQuantities = {
  [sku: string]: number;
};

interface AuthorizationReCountProps {}

const AuthorizationReCount: FunctionComponent<
  AuthorizationReCountProps
> = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const [selectedKitchen, setSelectedKitchen] = useState<Kitchen | null>(null);
  const [counting, setCounting] = useState<CountingResponse>();
  const [countingDetailFiltered, setCountingDetailFiltered] = useState<
    CountingDetail[]
  >([]);
  const [search, setSearch] = React.useState<string>("");
  const [selected, setSelected] = useState<string[]>([]);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [quantities, setQuantities] = useState<AdjustmentQuantities>({});
  const [modal, setModal] = React.useState<CommonDialogContent>();

  const getLastReCount = async () => {
    if (!selectedKitchen) {
      return;
    }
    const response = await dispatch(
      getLastCountingByKitchen({
        kitchenId: selectedKitchen.kitchenId,
      })
    );
    const lastRecountResponse = response?.payload as LastRecountResponse;

    const { data: lastRecount } = lastRecountResponse;

    const adjustmentQuantities: AdjustmentQuantities = {};
    lastRecount?.countingDetails?.forEach((detail) => {
      adjustmentQuantities[detail.sku] = detail.quantity;
    });
    setQuantities(adjustmentQuantities);

    setCounting(lastRecount);
  };

  const setQuantity = useCallback((sku: string, quantity: number) => {
    setQuantities((prevState) => ({ ...prevState, [sku]: quantity }));
  }, []);

  const getManualAdjustment = (
    counting: CountingResponse,
    sku: string
  ): ManualAdjustment => {
    const countingId = Number(counting.id);
    const detail = counting.countingDetails?.find(
      (datail) => datail.sku === sku
    );
    const quantityTmp = quantities[sku];
    const lastQuantity = detail?.lastQuantity ?? 0;
    const quantityDiff = quantityTmp - lastQuantity;
    const now = new Date();
    return {
      id: countingId,
      quantity: Math.abs(quantityDiff),
      quantityAfterValidation: quantityTmp,
      sku: detail?.sku ?? "",
      kitchenId: selectedKitchen?.kitchenId ?? "",
      entryType: quantityDiff < 0 ? EntryType.NEGATIVE : EntryType.POSITIVE,
      name: detail?.productName,
      unit: detail?.unit,
      documentCode: `VAL-${countingId}`,
      key: now.toTimeString(),
    } as ManualAdjustment;
  };

  const saveValidation = async () => {
    if (!quantities || !counting) {
      return;
    }
    const manualAdjustments: ManualAdjustment[] = Object.keys(quantities)
      .filter((sku) => selected.includes(sku))
      .map((sku) => {
        return getManualAdjustment(counting, sku);
      });

    await dispatch(
      validateCounting({
        adjustments: manualAdjustments,
      })
    );
  };

  const authorizeMovements = useCallback(async () => {
    if (quantities === null) {
      return;
    }

    setOpenDialog(false);

    await saveValidation();

    getLastReCount();
  }, [quantities, selected]);

  useEffect(() => {
    localizeDayjs(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    getLastReCount();
  }, [selectedKitchen]);

  useEffect(() => {
    const searchTmp = search.toLowerCase();
    setCountingDetailFiltered(
      counting?.countingDetails.filter((detail) => {
        let match = true;
        const forValidate = !detail.validationBy;
        if (searchTmp) {
          const skuMatch = detail.sku.toLowerCase().includes(searchTmp);
          const nameMatch = detail.productName
            .toLowerCase()
            .includes(searchTmp);
          match = skuMatch || nameMatch;
        }
        return match && forValidate;
      }) ?? []
    );
  }, [counting?.countingDetails, search]);

  const toggleSelectAll = useCallback(() => {
    const countingDetail = counting?.countingDetails ?? [];
    if (selected.length === countingDetail.length) {
      setSelected([]);
      return;
    }

    if (!countingDetail.length) {
      return;
    }

    setSelected(countingDetail.map((detail) => detail.sku));
  }, [counting?.countingDetails, selected]);

  const toggleSelected = useCallback(
    (sku: string) => {
      if (selected.includes(sku)) {
        setSelected((prevState) =>
          prevState.filter((prevSelected) => prevSelected !== sku)
        );

        return;
      }

      setSelected((prevState) => [...prevState, sku]);
    },
    [selected]
  );

  return (
    <PageBox>
      <PageHeader
        title={t(kitchenInventoryManagement.AUTHORIZATION_RECOUNTING_TITLE)}
        subtitle={t(
          kitchenInventoryManagement.AUTHORIZATION_RECOUNTING_DESCRIPTION
        )}
        rightArea={[
          {
            children: "Actualizar",
            variant: "contained",
            size: "large",
            startIcon: <FactCheckOutlinedIcon />,
            color: "inherit",
            sx: { bgcolor: "white", mr: 2 },
            onClick: getLastReCount,
          },
          {
            children: t(commons.AUTHORIZE),
            variant: "contained",
            size: "large",
            startIcon: <FactCheckOutlinedIcon />,
            onClick: () => {
              const title = t(dailyAdjustmentsModule.MOVEMENTS_AUTHORIZATION);
              const message = t(
                selected.length === 1
                  ? dailyAdjustmentsModule.AUTHORIZE_CONFIRM_MESSAGE
                  : dailyAdjustmentsModule.AUTHORIZE_CONFIRM_MESSAGE_PLURAL,
                {
                  supplyQuantity: selected.length,
                }
              );
              setModal({
                title,
                message,
                icon: (
                  <FactCheckOutlinedIcon
                    color="success"
                    sx={{ fontSize: 96 }}
                  />
                ),
                handleConfirm: authorizeMovements,
              });
              setOpenDialog(true);
            },
            disabled: selected.length === 0,
          },
        ]}
      />

      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item sm={8} xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {t(theoricalInventoryModule.REPORT_FILTER)}
            </Typography>
            <KitchenSelector onKitchenChange={setSelectedKitchen} />
          </Paper>
        </Grid>
        <Grid item sm={4} xs={12}>
          <InputSearchCustom
            title={commons.FIND_INPUT}
            label={commons.FIND_SUPPLY_LABEL}
            setSearch={setSearch}
          />
        </Grid>
      </Grid>

      <Box sx={{ mt: 1 }}>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="counting-history"
            size="small"
            stickyHeader
          >
            <TableHead>
              <TableRow
                sx={{
                  "& > th": {
                    bgcolor: "#F9FAFC",
                    borderBottom: "2px solid #E3E5E8",
                  },
                }}
              >
                <TableCell align="center">
                  <Checkbox
                    onChange={toggleSelectAll}
                    checked={
                      selected.length > 0 &&
                      selected.length === counting?.countingDetails?.length
                    }
                  />
                </TableCell>
                <TableCell align="left">{t(commons.SKU)}</TableCell>
                <TableCell align="left">{t(commons.NAME)}</TableCell>
                <TableCell align="center">
                  {t(theoricalInventoryModule.THEORETICAL_AMOUNT)}
                </TableCell>
                <TableCell align="center">
                  {t(theoricalInventoryModule.ACTUAL_AMOUNT)}
                </TableCell>
                <TableCell align="center">{t(commons.QUANTITY)}</TableCell>
                <TableCell align="center">
                  {t(adjustmentType.MANUAL_ADJUSTMENT)}
                </TableCell>
                <TableCell align="center">{t(commons.TYPE)}</TableCell>
                <TableCell align="center">{t(commons.UNIT)}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {counting &&
                countingDetailFiltered.map((detail) => {
                  const adjustment = getManualAdjustment(counting, detail.sku);
                  const entryColor =
                    adjustment.entryType === EntryType.POSITIVE
                      ? "success.main"
                      : "error.main";
                  return (
                    <TableRow key={detail.sku}>
                      <TableCell align="center">
                        <Checkbox
                          onChange={() => toggleSelected(detail.sku)}
                          checked={selected.includes(detail.sku)}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Typography fontWeight={600} color="text.secondary">
                          {detail.sku}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography>{detail.productName}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography>{detail.lastTheoretical ?? 0}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography>{detail.lastQuantity ?? 0}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <NumberField
                          name="value"
                          value={
                            Object.keys(quantities).length
                              ? quantities[detail.sku]
                              : detail.quantity
                          }
                          label={t(commons.REPORTED_AMOUNT)}
                          size="small"
                          onChangeValue={(newValue) =>
                            setQuantity(detail.sku, newValue)
                          }
                          sx={{ maxWidth: 160 }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Typography color={entryColor}>
                          {adjustment.quantity}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography color={entryColor}>
                          {t(`adjustmentType.${adjustment.entryType}`)}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography fontWeight={600} color="text.secondary">
                          {detail.unit}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}

              <EmptyAuthorizationTable
                isTableEmpty={countingDetailFiltered.length === 0}
                tableCellNumber={9}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <CommonDialog
        handleClose={() => setOpenDialog(false)}
        handleConfirm={() => {
          modal?.handleConfirm ? modal?.handleConfirm() : setOpenDialog(false);
        }}
        open={openDialog}
        title={modal?.title ?? ""}
        message={modal?.message ?? ""}
        icon={modal?.icon ?? <></>}
        showCancelButton={true}
      />
    </PageBox>
  );
};

export default AuthorizationReCount;
