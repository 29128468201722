import { UseCase } from "core/common/interfaces/useCase";
import { GetCountingType } from "core/physicalCount/interfaces/counting/getCountingType";

export class GetCountingTypeUseCase implements UseCase {
  constructor(private readonly repository: GetCountingType) {}

  public execute() {
    return this.repository.getCountingType();
  }
}
