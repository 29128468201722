import { UseCase } from "core/common/interfaces/useCase";
import { CanGetExportCountingDetail } from "core/physicalCount/interfaces/product/canGetExportCountingDetail";

export class GetCountingDetailExportUseCase implements UseCase {
  constructor(private readonly repository: CanGetExportCountingDetail) {}

  public execute(countingId: string) {
    return this.repository.getCountingDetailExport(countingId);
  }
}
