import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import StoreMallDirectoryOutlinedIcon from "@mui/icons-material/StoreMallDirectoryOutlined";
import { Button, Step, StepLabel, Stepper } from "@mui/material";
import Box from "@mui/material/Box";
import { physicalCountSchedule } from "app/i18n/types";
import {
  CountingType,
  MassiveData,
} from "core/physicalCount/entities/Counting";
import { Supply } from "core/supplies/entities/Supply";
import React, { FunctionComponent, memo } from "react";
import { useTranslation } from "react-i18next";
import {
  CountingTypeCode,
  PhysicalCountScheduleLocation,
  getIsRecountOrMassive,
} from "utils/general";
import { AlertDefault, CustomStepIcon, QontoConnector } from "utils/generalUI";
import StepBody from "./Body";
import StepBodyInfo from "./BodyInfo";
import StepFinal from "./Step/FinalStep";

interface PhysicalCountScheduleStepperProps {
  selectedCountingType: CountingType | null;
  setSelectedCountingType: (selectedCountingType: CountingType | null) => void;
  dates: string[];
  setDates: (dates: string[]) => void;
  locations: PhysicalCountScheduleLocation[];
  setLocations: (locations: PhysicalCountScheduleLocation[]) => void;
  selectedSupplies: Supply[];
  setSelectedSupplies: (selectedSupplies: Supply[]) => void;
  massiveData: MassiveData;
  setMassiveData: (massiveData: MassiveData) => void;
  handleComplete: () => void;
  handleReset: () => void;
  errorOnFinish: boolean;
  name: string;
  setName: (name: string) => void;
}

const PhysicalCountScheduleStepper: FunctionComponent<
  PhysicalCountScheduleStepperProps
> = (props) => {
  const {
    selectedCountingType,
    setSelectedCountingType,
    dates,
    setDates,
    locations,
    setLocations,
    selectedSupplies,
    setSelectedSupplies,
    massiveData,
    setMassiveData,
    handleComplete: handleCompleteTmp,
    handleReset: handleResetTmp,
    errorOnFinish,
    name,
    setName,
  } = props;

  const { t } = useTranslation();

  const isRecountOrDaily = getIsRecountOrMassive(selectedCountingType);
  const countAll = selectedCountingType?.countAll ?? true;
  const canSelectSupplies = !countAll && !isRecountOrDaily;

  const steps = [
    t(physicalCountSchedule.STEP_LABEL_1),
    t(
      isRecountOrDaily
        ? physicalCountSchedule.IMPORT
        : physicalCountSchedule.STEP_LABEL_2
    ),
    t(physicalCountSchedule.STEP_LABEL_3),
    ...(canSelectSupplies ? [t(physicalCountSchedule.STEP_LABEL_4)] : []),
    t(physicalCountSchedule.STEP_LABEL_FINAL),
  ];

  const icons: { [index: string]: React.ReactElement } = {
    1: <CalculateOutlinedIcon />,
    2: isRecountOrDaily ? (
      <FileDownloadOutlinedIcon />
    ) : (
      <StoreMallDirectoryOutlinedIcon />
    ),
    3: <CalendarMonthOutlinedIcon />,
    ...(canSelectSupplies
      ? { 4: <FactCheckOutlinedIcon />, 5: <CheckCircleOutlinedIcon /> }
      : {
          4: <CheckCircleOutlinedIcon />,
        }),
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<boolean>(false);
  const [canContinue, setCanContinue] = React.useState<boolean>(false);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const getActiveStep = (): number => {
    const step = steps.length - 1 === activeStep ? -1 : activeStep;
    return step;
  };

  const handleComplete = () => {
    setCompleted(true);
    handleCompleteTmp();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted(false);
    handleResetTmp();
  };

  React.useEffect(() => {
    let canContinueTmp = false;
    const step = getActiveStep();
    switch (step) {
      case 0:
        canContinueTmp =
          !!selectedCountingType &&
          (selectedCountingType.code === CountingTypeCode.other_individual ||
            selectedCountingType.code === CountingTypeCode.other_massive)
            ? name !== ""
            : true;
        break;
      case 1:
        canContinueTmp =
          !!locations.length || !!Object.keys(massiveData).length;
        break;
      case 2:
        canContinueTmp = !!dates.length;
        break;
      case 3:
        canContinueTmp = !!selectedSupplies.length;
        break;
      default:
        canContinueTmp = true;
        break;
    }
    setCanContinue(canContinueTmp);
  }, [
    activeStep,
    selectedCountingType,
    dates,
    locations,
    selectedSupplies,
    massiveData,
    name,
  ]);

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<QontoConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              StepIconComponent={(props) => (
                <CustomStepIcon {...props} icons={icons} />
              )}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {completed && (
          <React.Fragment>
            <Box sx={{ my: 2 }}>
              <AlertDefault
                message={t(physicalCountSchedule.STEPS_COMPLETED)}
              />
            </Box>
            <Box>
              {selectedCountingType && (
                <StepFinal
                  selectedCountingType={selectedCountingType}
                  locations={locations}
                  massiveData={massiveData}
                  dates={dates}
                  selectedSupplies={selectedSupplies}
                />
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                pt: 4,
              }}
            >
              <Button variant="outlined" onClick={handleReset}>
                {t(physicalCountSchedule.STEPS_RESET)}
              </Button>
              {errorOnFinish && (
                <Button
                  onClick={handleComplete}
                  variant="contained"
                  endIcon={<CheckOutlinedIcon />}
                >
                  {t(physicalCountSchedule.FINISH)}
                </Button>
              )}
            </Box>
          </React.Fragment>
        )}
        {!completed && (
          <React.Fragment>
            <StepBodyInfo
              index={getActiveStep()}
              isRecountOrDaily={isRecountOrDaily}
            />
            <Box>
              <StepBody
                selectedCountingType={selectedCountingType}
                setSelectedCountingType={setSelectedCountingType}
                locations={locations}
                setLocations={setLocations}
                dates={dates}
                setDates={setDates}
                selectedSupplies={selectedSupplies}
                setSelectedSupplies={setSelectedSupplies}
                massiveData={massiveData}
                setMassiveData={setMassiveData}
                name={name}
                setName={setName}
                index={getActiveStep()}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                pt: 4,
              }}
            >
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
                variant="contained"
                startIcon={<ArrowBackIosOutlinedIcon />}
              >
                {t(physicalCountSchedule.PREVIOUS)}
              </Button>
              {steps.length - 1 !== activeStep && (
                <Button
                  onClick={handleNext}
                  sx={{ mr: 1 }}
                  variant="contained"
                  endIcon={<ArrowForwardIosOutlinedIcon />}
                  disabled={!canContinue}
                >
                  {t(physicalCountSchedule.CONTINUE)}
                </Button>
              )}
              {steps.length - 1 === activeStep && (
                <Button
                  onClick={handleComplete}
                  variant="contained"
                  endIcon={<CheckOutlinedIcon />}
                >
                  {t(physicalCountSchedule.FINISH)}
                </Button>
              )}
            </Box>
          </React.Fragment>
        )}
      </div>
    </Box>
  );
};

export default memo(PhysicalCountScheduleStepper);
