import { createAsyncThunk } from "@reduxjs/toolkit";
import appConfig from "config/app";
import { AuthorizeAdjustmentsPayload } from "core/dailyAdjustments/entities/Adjustment";
import {
  ItemDailyAdjustmentsResponse,
  ProductRequest,
} from "core/dailyAdjustments/entities/Catalog";
import { ReportRequest } from "core/dailyAdjustments/entities/Report";
import { AdjustmentHTTPRepository } from "core/dailyAdjustments/repositories/http/adjustment";
import { ProductHTTPRepository } from "core/dailyAdjustments/repositories/http/product";
import { ArchiveAdjustmentsUseCase } from "core/dailyAdjustments/useCases/adjustment/archiveAdjustmentsUseCase";
import { AuthorizeAdjustments } from "core/dailyAdjustments/useCases/adjustment/authorizeAdjustmentsUseCase";
import { CreateDailyAdjustmentReportUseCase } from "core/dailyAdjustments/useCases/adjustment/createDailyAdjustmentReportUseCase";
import { GetAdjustmentsByKitchenForReviewUseCase } from "core/dailyAdjustments/useCases/adjustment/getAdjustmentsByKitchenForReviewUseCase";
import { SendToValidationAdjustmentsUseCase } from "core/dailyAdjustments/useCases/adjustment/sendToValidationAdjustmentsUseCase";
import { GetListProductsUseCase } from "core/dailyAdjustments/useCases/product/getListProductsUseCases";
import { SetItemLossesUseCase } from "core/dailyAdjustments/useCases/product/setItemLossesUseCase";

export const getListProducts = createAsyncThunk(
  "losses/getListProducts",
  async (payload: { kitchenId: string }) => {
    const productHttpRepository = new ProductHTTPRepository(appConfig);
    const getListProductsUseCase = new GetListProductsUseCase(
      productHttpRepository
    );
    const productsForKitchen = await getListProductsUseCase.execute(
      payload.kitchenId
    );
    return { productsForKitchen };
  }
);

export const setItemDailyAdjustments = createAsyncThunk(
  "supplies/setItemDailyAdjustments",

  async (payload: {
    product: ProductRequest;
  }): Promise<ItemDailyAdjustmentsResponse> => {
    const httpRepository = new ProductHTTPRepository(appConfig);
    const setProductInactivenUseCase = new SetItemLossesUseCase(httpRepository);

    const setProductInactivenResult = await setProductInactivenUseCase.execute(
      payload.product
    );

    return setProductInactivenResult;
  }
);

export const getAdjustmentsByKitchenForReview = createAsyncThunk(
  "losses/getAdjustmentsByKitchenForReview",
  async (payload: { kitchenId: string }) => {
    const adjustmentHTTPRepository = new AdjustmentHTTPRepository(appConfig);

    const getAdjustmentsByKitchenForReviewUseCase =
      new GetAdjustmentsByKitchenForReviewUseCase(adjustmentHTTPRepository);

    const adjustments = await getAdjustmentsByKitchenForReviewUseCase.execute(
      payload.kitchenId
    );

    return adjustments;
  }
);

export const authorizeAdjustments = createAsyncThunk(
  "losses/authorizeAdjustments",
  async (payload: { adjustments: Array<AuthorizeAdjustmentsPayload> }) => {
    const adjustmentHTTPRepository = new AdjustmentHTTPRepository(appConfig);

    const authorizeAdjustmentsUseCase = new AuthorizeAdjustments(
      adjustmentHTTPRepository
    );

    const adjustments = await authorizeAdjustmentsUseCase.execute(
      payload.adjustments
    );

    return adjustments;
  }
);

export const archiveAdjustments = createAsyncThunk(
  "losses/archiveAdjustments",
  async (payload: { adjustments: Array<number> }) => {
    const adjustmentHTTPRepository = new AdjustmentHTTPRepository(appConfig);

    const archiveAdjustmentsUseCase = new ArchiveAdjustmentsUseCase(
      adjustmentHTTPRepository
    );

    const adjustments = await archiveAdjustmentsUseCase.execute(
      payload.adjustments
    );

    return adjustments;
  }
);

export const sendToValidationAdjustments = createAsyncThunk(
  "losses/sendToValidationAdjustments",
  async (payload: { adjustments: Array<number> }) => {
    const httpRepository = new AdjustmentHTTPRepository(appConfig);

    const useCase = new SendToValidationAdjustmentsUseCase(httpRepository);

    const adjustments = await useCase.execute(payload.adjustments);

    return adjustments;
  }
);

export const createDailyAdjustmentReport = createAsyncThunk(
  "losses/createDailyAdjustmentReport",
  async (payload: { report: ReportRequest }) => {
    const adjustmentHTTPRepository = new AdjustmentHTTPRepository(appConfig);

    const CsreateDailyAdjustmentReportUseCase =
      new CreateDailyAdjustmentReportUseCase(adjustmentHTTPRepository);

    const adjustments = await CsreateDailyAdjustmentReportUseCase.execute(
      payload.report
    );

    return adjustments;
  }
);
