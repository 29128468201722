import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { commons } from "app/i18n/types";

interface Props {
  open: boolean;
  title: string;
  message: string;
  icon: JSX.Element;
  showCancelButton: boolean;
  handleConfirm: () => void;
  handleClose: () => void;
}

const ProductionDialog: FunctionComponent<Props> = (props) => {
  const {
    open,
    message,
    handleConfirm,
    handleClose,
    title,
    icon,
    showCancelButton,
  } = props;

  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={{ pr: "40px", pl: "40px", pt: "30px" }}
        id="authorization-dialog-title"
        color="primary.dark"
        variant="h5"
        fontWeight={600}
      >
        {title}
      </DialogTitle>

      <DialogContent sx={{ maxWidth: "450px", pr: "40px", pl: "40px" }}>
        <DialogContentText id="alert-dialog-description" color="text.primary">
          {message}
        </DialogContentText>

        <Box display="flex" justifyContent="center" pt={2}>
          {icon}
        </Box>
      </DialogContent>

      <DialogActions sx={{ pl: "40px", pr: "40px", pb: "30px" }}>
        <Box width="100%" display="flex" gap={1}>
          {showCancelButton && (
            <Button
              onClick={handleClose}
              variant="outlined"
              color="error"
              startIcon={<CloseOutlinedIcon />}
              fullWidth
              sx={{ p: "12px", fontWeight: "600" }}
            >
              {t(commons.CANCEL)}
            </Button>
          )}

          <Button
            onClick={handleConfirm}
            variant="contained"
            color="primary"
            startIcon={<ArrowForwardIosIcon />}
            fullWidth
            autoFocus
            sx={{ p: "12px", fontWeight: "600" }}
          >
            {t(commons.CONTINUE)}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ProductionDialog;
