import CountertopsOutlinedIcon from "@mui/icons-material/CountertopsOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import Box from "@mui/material/Box";
import CustomTabs from "app/components/common/Tabs";
import PageHeader from "app/components/PageHeader";
import MassiveTransfer from "app/components/Transfers/MassiveTransfer";
import SingleTransfer from "app/components/Transfers/SingleTransfer";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { useNavigator } from "app/hooks/useNavigator";
import { kitchenInventoryManagement, transferModule } from "app/i18n/types";
import { createSingleTransferOrder } from "app/store/slices/transfer/thunks";
import { LocationType } from "config/module";
import { TransferOrder } from "core/transfer/entities/TransferOrder";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageBox } from "utils/generalUI";

interface TransfersPageProps {}

const TransfersPage: FunctionComponent<TransfersPageProps> = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigator = useNavigator();
  const { kitchenId, locationType } = navigator.params();

  const createSingleTransferOrderRequest = useAppSelector(
    (state) => state.transfer.createSingleTransferOrder
  );

  const [tab, setTab] = useState<number>(-1);

  const [singleTransferOrder, setSingleTransferOrder] =
    useState<TransferOrder | null>(null);

  const handleChangeSingleTransferOrder = (
    transferOrder: TransferOrder | null
  ) => {
    setSingleTransferOrder(transferOrder);
  };

  const onClickPrimaryAction = () => {
    if (
      kitchenId !== undefined &&
      singleTransferOrder !== null &&
      singleTransferOrder.lines.length > 0
    ) {
      dispatch(
        createSingleTransferOrder({
          locationId: kitchenId,
          transferOrder: singleTransferOrder,
        })
      );
    }
  };

  return (
    <PageBox>
      <PageHeader
        title={t(kitchenInventoryManagement.TRANSFER_LOAD_FILE_TITLE)}
        subtitle={t(kitchenInventoryManagement.TRANSFER_LOAD_FILE_DESCRIPTION)}
        rightArea={[
          {
            children: t(transferModule.SCHEDULE),
            variant: "contained",
            disabled:
              singleTransferOrder === null ||
              singleTransferOrder?.lines.length === 0 ||
              singleTransferOrder.lines.some(
                (orderLine) => orderLine.quantity === 0
              ) ||
              createSingleTransferOrderRequest.status === "loading",
            onClick: onClickPrimaryAction,
            show: tab === 1 || locationType === LocationType.KITCHEN,
          },
        ]}
      />
      {locationType === LocationType.CP ? (
        <>
          <Box sx={{ my: 2 }}>
            <CustomTabs
              tabs={[
                {
                  children: t(transferModule.MASSIVE),
                  startIcon: <ListAltOutlinedIcon />,
                },
                {
                  children: t(transferModule.INDIVIDUAL),
                  startIcon: <CountertopsOutlinedIcon />,
                },
              ]}
              onChange={setTab}
            />
          </Box>
          <Box>
            {tab === 0 && <MassiveTransfer />}
            {tab === 1 && (
              <SingleTransfer
                onChangeSingleTransferOrder={handleChangeSingleTransferOrder}
              />
            )}
          </Box>
        </>
      ) : (
        <Box mt={2}>
          <SingleTransfer
            onChangeSingleTransferOrder={handleChangeSingleTransferOrder}
          />
        </Box>
      )}
    </PageBox>
  );
};

export default TransfersPage;
