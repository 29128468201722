import { EditOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { commons, supplyReception } from "app/i18n/types";
import { ProductionSupplyDetail } from "core/productions/entities/Supply";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import NoveltySupplyReception from "./NoveltySupplyReceptionModal";

interface SupplyReceptionTableProps {
  details: ProductionSupplyDetail[];
  search?: string;
  onRefresh: () => void;
}

const SupplyReceptionTable: FunctionComponent<SupplyReceptionTableProps> = ({
  details,
  search,
  onRefresh,
}) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [selectedSupplyDetail, setSelectedSupplyDetail] =
    useState<ProductionSupplyDetail | null>(null);

  const handleClose = () => {
    setOpenModal(false);
    setSelectedSupplyDetail(null);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" width="12%">
                <Typography
                  sx={{ textTransform: "uppercase" }}
                  color="text.secondary"
                  fontWeight={700}
                >
                  {t(commons.SKU)}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  sx={{ textTransform: "uppercase" }}
                  color="text.secondary"
                  fontWeight={700}
                >
                  {t(commons.SUPPLY)}
                </Typography>
              </TableCell>
              <TableCell align="center" width="12%">
                <Typography
                  sx={{ textTransform: "uppercase" }}
                  color="text.secondary"
                  fontWeight={700}
                >
                  {t(supplyReception.SENT_QUANTITY)}
                </Typography>
              </TableCell>
              <TableCell align="center" width="12%">
                <Typography
                  sx={{ textTransform: "uppercase" }}
                  color="text.secondary"
                  fontWeight={700}
                >
                  {t(supplyReception.RECEIVED_QUANTITY)}
                </Typography>
              </TableCell>
              <TableCell align="center" width="12%">
                <Typography
                  sx={{ textTransform: "uppercase" }}
                  color="text.secondary"
                  fontWeight={700}
                >
                  {t(commons.UNIT)}
                </Typography>
              </TableCell>
              <TableCell align="center" width="12%">
                <Typography
                  sx={{ textTransform: "uppercase" }}
                  color="text.secondary"
                  fontWeight={700}
                >
                  {t(commons.ACTIONS)}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {details
              .filter((detail) =>
                search
                  ? detail.name.toLowerCase().includes(search.toLowerCase()) ||
                    detail.sku === search
                  : true
              )
              .map((detail) => (
                <TableRow key={detail.id}>
                  <TableCell align="center">{detail.sku}</TableCell>
                  <TableCell>{detail.name}</TableCell>
                  <TableCell align="center">
                    <Typography fontWeight={700}>
                      {detail.packedQuantity}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography fontWeight={700}>
                      {detail.receivedQuantity}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography fontWeight={700} textTransform="uppercase">
                      {t(`measureUnits.${detail.measureUnit.toLowerCase()}`)}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<EditOutlined />}
                      onClick={() => {
                        setOpenModal(true);
                        setSelectedSupplyDetail(detail);
                      }}
                    >
                      <Typography textTransform="uppercase">
                        {t(supplyReception.NOVELTY)}
                      </Typography>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <NoveltySupplyReception
        open={openModal}
        onClose={handleClose}
        supplyDetail={selectedSupplyDetail}
        onRefresh={onRefresh}
      />
    </>
  );
};

export default SupplyReceptionTable;
