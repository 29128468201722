import {
  Close,
  CloudDownloadOutlined,
  DocumentScannerOutlined,
  Domain,
} from "@mui/icons-material";
import FileUpload from "@mui/icons-material/FileUpload";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import {
  commons,
  purchaseOrderModule,
  theoricalInventoryModule,
} from "app/i18n/types";
import { convertLocalFullDate } from "app/utils/dateUtils";
import { AttachmentFile } from "core/attachment/entity";
import {
  getReceiptAttachments,
  viewFile,
} from "core/attachment/repository/http";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { openUrl } from "utils/general";
import AddDocumentsDialog from "../AddDocumentsDialog";
import { ManagerDialogFooter } from "../common/Manager/Dialog/Footer";

interface Props {
  sourceId: string;
  origin: string;
  type: string;
  title?: string;
  kitchenId: string;
  onClose: () => void;
  modeToAdd?: boolean;
}

const DocumentsViewer: FunctionComponent<Props> = (props) => {
  const { sourceId, origin, type, title, onClose, kitchenId, modeToAdd } =
    props;

  const [key, setKey] = useState<string>("");
  const [documents, setDocuments] = useState<AttachmentFile[]>([]);
  const [openAddDocuments, setOpenAddDocuments] = useState<boolean>(false);

  const { t } = useTranslation();

  const getDocuments = async () => {
    const response = await getReceiptAttachments(
      sourceId,
      origin,
      type,
      kitchenId
    );
    setDocuments(response);
  };

  const openDocument = (name: string) => {
    viewFile(sourceId, origin, type, name);
  };

  useEffect(() => {
    if (!key) return;
    if (openAddDocuments) return;
    getDocuments();
  }, [key]);

  useEffect(() => {
    setKey(`${sourceId}|${origin}|${type}|${openAddDocuments}`);
  }, [sourceId, origin, type, openAddDocuments]);

  return (
    <Dialog maxWidth="md" open onClose={onClose} disableEscapeKeyDown>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">
              {t(purchaseOrderModule.DOCUMENTS_LIST_TITLE)}
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
          <Divider sx={{ mb: 1 }} />
          {title && (
            <Box display="flex" mb={2}>
              <Domain />
              <Typography sx={{ ml: 1 }} fontWeight={500}>
                {title}
              </Typography>
            </Box>
          )}
          {!documents.length && (
            <Alert severity="info">
              {t(theoricalInventoryModule.NO_ELEMENTS)}
            </Alert>
          )}
          {documents.map((doc) => (
            <Paper
              key={doc.name}
              sx={{
                p: 2,
                display: "flex",
                justifyContent: "space-between",
                gap: 2,
                borderBottom: doc.verified ? "4px solid green" : "none",
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                gap={1}
              >
                <Box display="flex" gap={1}>
                  <DocumentScannerOutlined />
                  <Typography fontWeight="bold">{doc.name}</Typography>
                </Box>
                {doc.createdAt && (
                  <Typography>
                    {convertLocalFullDate(doc.createdAt.toString())}
                  </Typography>
                )}
              </Box>
              <Box sx={{ display: "flex", gap: 0.5 }}>
                <Button
                  variant="outlined"
                  color="info"
                  size="small"
                  onClick={() => openDocument(doc.name)}
                >
                  <VisibilityOutlinedIcon />
                </Button>
                <Button
                  variant="outlined"
                  color="info"
                  size="small"
                  onClick={() => openUrl(doc.path ?? "")}
                >
                  <CloudDownloadOutlined />
                </Button>
              </Box>
            </Paper>
          ))}
        </Box>
        {modeToAdd && (
          <>
            <Divider sx={{ mb: 1, mt: 2 }} />
            <ManagerDialogFooter
              mainButton={{
                children: t(commons.ADD),
                onClick: () => {
                  setOpenAddDocuments(true);
                },
                endIcon: <FileUpload />,
              }}
              onCancel={onClose}
              loading={false}
            />
            {openAddDocuments && (
              <AddDocumentsDialog
                code={sourceId ?? ""}
                origin={origin}
                type={type}
                kitchenId={kitchenId ?? ""}
                onCloseDialog={() => setOpenAddDocuments(false)}
                noClose
              />
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DocumentsViewer;
