import { UseCase } from "core/common/interfaces/useCase";
import {
  CanGetActiveKitchens,
  CanGetKitchens,
} from "core/supplies/interfaces/kitchen/canGetListKitchens";

export class GetListKitchensUseCase implements UseCase {
  constructor(private readonly repository: CanGetKitchens) {}

  public execute() {
    return this.repository.getAllKitchens();
  }
}

export class GetListActiveKitchensUseCase implements UseCase {
  constructor(private readonly repository: CanGetActiveKitchens) {}

  public execute() {
    return this.repository.getKitchensByStatus("ACTIVE");
  }
}
