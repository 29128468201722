import { UseCase } from "core/common/interfaces/useCase";
import { CanFinishItemCounting } from "core/physicalCount/interfaces/counting/canFinishItemCounting";

export class FinishItemCountingUseCase implements UseCase {
  constructor(private readonly repository: CanFinishItemCounting) {}

  public execute(countingId: number, sku: string) {
    return this.repository.finishItemCounting(countingId, sku);
  }
}
