import { KOSSelectedResult } from "@foodology-co/alejandria";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import {
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import KitchenSelector from "app/components/Kitchen/Selector";
import PageHeader from "app/components/PageHeader/PageHeader";
import PurchaseOrderStatusSelector from "app/components/PurchaseOrder/Status/Selector";
import PurchaseOrderUserSelector from "app/components/PurchaseOrder/UserSelector";
import PurchaseTable from "app/components/PurchaseRecord/PurchaseTable";
import SearchOnTopBar from "app/components/common/SearchOnTopBar";
import { useAppSelector } from "app/hooks/useAppSelector";
import { commons, purchaseOrder } from "app/i18n/types";
import { DownloadZipPayload } from "core/attachment/entity";
import {
  downloadPurchaseOrdersZip,
  getPurchaseOrderPdfName,
} from "core/attachment/repository/http";
import { downloadTextAsCsv, json2Csv } from "core/common/utils/fileUtils";
import { Purchase } from "core/purchaseOrders/entities/PurchaseOrder";
import { getPurchaseOrders } from "core/purchaseOrders/repositories/http/purchase";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { compareSearchText, getLocationAndType } from "utils/general";
import { PageBox } from "utils/generalUI";
import { TypeOfRole as Role } from "utils/role";

interface Props {}

const PurchaseOrder: FunctionComponent<Props> = () => {
  const { t } = useTranslation();

  const user = useAppSelector((state) => state.session.user.data);

  const [orders, setOrders] = useState<Purchase[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<Purchase[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [selectedKitchen, setSelectedKitchen] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [dateFilter, setDateFilter] = useState<string>("");
  const [userIdFilter, setUserIdFilter] = useState<string>();
  const [cleanSearch, setCleanSearch] = useState<number>(0);
  const [statuses, setStatuses] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string>();
  const [selectedItems, setSelectedItems] = useState<KOSSelectedResult[]>([]);
  const [loadingZip, setLoadingZip] = useState<boolean>(false);

  const getData = () => {
    const params = getLocationAndType({
      selectedCountry,
      selectedKitchen,
    });
    if (!params) return;
    setLoading(true);
    setSelectedStatus(undefined);
    setSearch("");
    setUserIdFilter("");
    setDateFilter("");
    setCleanSearch(new Date().getTime());
    setOrders([]);
    getPurchaseOrders(params.type, params.location)
      .then((response) => {
        setOrders(response);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, [selectedCountry, selectedKitchen]);

  useEffect(() => {
    const statusesDataSet = new Set(orders.map((order) => order.status));
    const statusesArray = [...statusesDataSet];
    setStatuses(statusesArray);
  }, [orders]);

  const searchValidator = (order: Purchase, toSearch: string): boolean => {
    if (!toSearch) return true;
    return (
      compareSearchText(order.orderId, toSearch) ||
      compareSearchText(order.code, toSearch) ||
      compareSearchText(order.vendorName, toSearch)
    );
  };

  const statusValidator = (order: Purchase, status: string): boolean => {
    if (!status) return true;
    return order.status === status;
  };

  const dateValidator = (order: Purchase, date: string): boolean => {
    if (!date) return true;
    return order.dateDocument === date;
  };

  const userIdValidator = (order: Purchase, userId: string): boolean => {
    if (!userId) return true;
    return order.userId === userId;
  };

  useEffect(() => {
    setFilteredOrders(
      orders.filter((order) => {
        return (
          searchValidator(order, search) &&
          statusValidator(order, selectedStatus ?? "") &&
          dateValidator(order, dateFilter) &&
          userIdValidator(order, userIdFilter ?? "")
        );
      })
    );
  }, [orders, search, selectedStatus, dateFilter, userIdFilter]);

  const getCSV = () => {
    if (orders.length) {
      const data = orders.map((purchase) => {
        return {
          code: purchase.code,
          kitchen: purchase.kitchenId,
          proveedor: purchase.vendorName,
          date: purchase.dateDocument,
          estimated: purchase.expectedReceiptAt,
          status: purchase.status,
          user: purchase.userName,
        };
      });
      downloadTextAsCsv(
        json2Csv(data),
        `${t(purchaseOrder.TITLE)} ${new Date().getTime()}.csv`
      );
    }
  };

  const onUpdateItem = useCallback(
    (id: string, purchase: Purchase) => {
      setOrders((prev) =>
        prev.map((el) => (el.orderId === id ? purchase : el))
      );
    },
    [orders]
  );

  const downloadPdfs = useCallback(() => {
    setLoadingZip(true);
    const data = selectedItems.map((item) => {
      const { country, orderId, code, kitchenId, vendorName } = item;
      return {
        id: orderId,
        country,
        name: getPurchaseOrderPdfName(code, kitchenId, vendorName),
      } as DownloadZipPayload;
    });
    downloadPurchaseOrdersZip(data).finally(() => {
      setLoadingZip(false);
    });
  }, [selectedItems]);

  return (
    <PageBox>
      <PageHeader
        title={t(purchaseOrder.TITLE)}
        subtitle={t(purchaseOrder.DESCRIPTION)}
        rightArea={[
          {
            children: t(commons.UPDATE),
            variant: "outlined",
            startIcon: <RestartAltOutlinedIcon />,
            onClick: getData,
          },
          {
            children: t(commons.EXPORT),
            variant: "outlined",
            color: "info",
            onClick: getCSV,
            disabled: !orders.length,
            startIcon: <FileDownloadOutlinedIcon />,
          },
          {
            children: t(commons.DOWNLOAD_PDFS),
            variant: "outlined",
            color: "error",
            startIcon: (
              <>
                {loadingZip ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <SimCardDownloadOutlinedIcon />
                )}
              </>
            ),
            onClick: downloadPdfs,
            disabled: !selectedItems.length || loadingZip,
          },
        ]}
      />

      <SearchOnTopBar onSearch={setSearch} clean={cleanSearch} />
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item sm={12} xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {t(purchaseOrder.FILTER_ORDERS)}
            </Typography>
            <Grid container spacing={2}>
              <Grid item sm={5}>
                <KitchenSelector
                  onCountryChange={(country) =>
                    setSelectedCountry(country ?? "")
                  }
                  onKitchenChange={(kitchen) =>
                    setSelectedKitchen(kitchen?.kitchenId ?? "")
                  }
                  hideCitySelector
                />
              </Grid>
              <Grid item sm={2}>
                <TextField
                  type="date"
                  value={dateFilter}
                  onChange={(e) => {
                    setDateFilter(e.target.value);
                  }}
                  fullWidth
                  autoComplete="off"
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={() => setDateFilter("")}>
                        <CancelOutlinedIcon />
                      </IconButton>
                    ),
                  }}
                  disabled={!selectedCountry}
                />
              </Grid>
              <Grid item sm={2}>
                <PurchaseOrderStatusSelector
                  options={statuses}
                  selected={selectedStatus}
                  setSelected={setSelectedStatus}
                  disabled={!selectedCountry}
                />
              </Grid>
              <Grid item sm={3}>
                <PurchaseOrderUserSelector
                  orders={orders}
                  selected={userIdFilter}
                  setSelected={setUserIdFilter}
                  currentUserId={
                    user?.role === Role.COMPRAS ? user?._id : undefined
                  }
                  disabled={!selectedCountry}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <PurchaseTable
        items={filteredOrders}
        loading={loading}
        onUpdateItem={onUpdateItem}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        canPagination={dateFilter === ""}
      />
    </PageBox>
  );
};

export default PurchaseOrder;
