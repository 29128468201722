import { UseCase } from "core/common/interfaces/useCase";
import { CanAddScannedItemCount } from "core/physicalCount/interfaces/product/CanAddScannedItemCount";

export class AddScannedItemCount implements UseCase {
  constructor(private readonly repository: CanAddScannedItemCount) {}

  public execute(countingId: string, productionLabelId: string) {
    return this.repository.addScannedItemCount(countingId, productionLabelId);
  }
}
