import {
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSEmptyState,
  KOSRowData,
} from "@foodology-co/alejandria";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PinOutlinedIcon from "@mui/icons-material/PinOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PageHeader from "app/components/PageHeader";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { useNavigator } from "app/hooks/useNavigator";
import { commons } from "app/i18n/types";
import { getTheoreticalInventoryMovementsResume } from "app/store/slices/theoricalInventory/thunks";
import {
  downloadTextAsCsv,
  json2CSVContent,
} from "core/common/utils/fileUtils";
import {
  InventoryMovement,
  InventoryMovementExport,
} from "core/theoricalInventory/entities/ThereticalInventory";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageBox } from "utils/generalUI";
import { TypeOfRole, isKitchenRol } from "utils/role";

interface TheoreticalInventoryMovementsProps {}

type Filter = { name: string; group: Array<string> };

const filters: Array<Filter> = [
  {
    name: "Inventario Teórico",
    group: [
      "ADJUSTMENT",
      "COUNTING",
      "DISCARDED",
      "EVENT",
      "INITIAL",
      "INTERNAL_CONSUMPTION",
      "LOSS",
      "PRODUCTION",
      "PURCHASE_ORDER",
      "SALE",
      "TRANSFER",
      "MANUAL_ADJUSTMENT",
      "VALIDATION_ADJUSTMENT",
      "COUNTING_ADJUSTMENT",
      "DAILY_COUNTING_ADJUSTMENT",
    ],
  },
  {
    name: "Traslados / Compras",
    group: ["PURCHASE_ORDER", "TRANSFER", "UPDATE_PURCHASE_ORDER_LINE"],
  },
  {
    name: "Producto Vendido",
    group: ["SALE", "SALE_CANCELLATION"],
  },
  {
    name: "Movimientos",
    group: [
      "DISCARDED",
      "EVENT",
      "INTERNAL_CONSUMPTION",
      "LOSS",
      "MANUAL_ADJUSTMENT",
      "VALIDATION_ADJUSTMENT",
      "COUNTING_ADJUSTMENT",
    ],
  },
  {
    name: "Producciones",
    group: ["PRODUCTION"],
  },
];

const TheoreticalInventoryMovements: FunctionComponent<
  TheoreticalInventoryMovementsProps
> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigator = useNavigator();
  const { kitchenId, sku } = navigator.params();

  const inventoryMovementsResume = useAppSelector(
    (state) => state.theoricalInventory.inventoryMovementsResume.data
  );
  const maxCounting = useAppSelector(
    (state) => state.theoricalInventory.maxCounting
  );
  const inventoryMovementsResumeStatus = useAppSelector(
    (state) => state.theoricalInventory.inventoryMovementsResume.status
  );
  const user = useAppSelector((state) => state.session.user);

  const [selectedFilter, setSelectedFilter] = useState<Filter>(filters[0]);

  const getInventoryMovements = useCallback(() => {
    if (kitchenId !== undefined && sku !== undefined) {
      dispatch(getTheoreticalInventoryMovementsResume({ kitchenId, sku }));
    }
  }, [dispatch, kitchenId, sku]);

  const getQuantity = useCallback(
    (movements: Array<InventoryMovement>, group: Array<string>): number => {
      let total = 0;
      if (movements.length > 0 && group.length > 0) {
        const entry = movements
          .filter(
            (movement) =>
              group.includes(movement.transactionType) &&
              movement.movementType === "ENTRY"
          )
          .reduce((accum, movement) => accum + movement.quantity, 0);

        const egress = movements
          .filter(
            (movement) =>
              group.includes(movement.transactionType) &&
              movement.movementType === "EGRESS"
          )
          .reduce((accum, movement) => accum + movement.quantity, 0);

        total = entry - egress;
      }

      if (total < 0) {
        total = total - total * 2;
      }

      return total;
    },
    []
  );

  useEffect(() => {
    getInventoryMovements();
  }, [getInventoryMovements]);

  const exportDetail = () => {
    if (inventoryMovementsResume?.movementsDetail) {
      downloadTextAsCsv(
        json2CSVContent<InventoryMovementExport>(
          inventoryMovementsResume.movementsDetail.map((movement) => {
            return {
              externalId: movement.externalId,
              sku: movement.sku,
              quantity: movement.quantity,
              transactionType: movement.transactionType,
              movementType: movement.movementType,
              createdAt: movement.createdAt,
            };
          })
        ),
        `inventory_detail${inventoryMovementsResume.sku}.csv`
      );
    }
  };

  const itemsFiltered =
    inventoryMovementsResume?.movementsDetail.filter((movement) =>
      selectedFilter.group.includes(movement.transactionType)
    ) ?? [];

  const getHeader = (): KOSBaseTableHeader[] => [
    {
      field: "externalId",
      label: t(commons.CODE),
    },
    {
      label: t(commons.QUANTITY),
      align: "center",
      color: (item: KOSRowData) => {
        return item.movementType === "EGRESS" ? "error.main" : "success.main";
      },
      weight: "bold",
      text: (item: KOSRowData) => {
        const sign = item.movementType === "EGRESS" ? "-" : "";
        return `${sign}${item.quantity}`;
      },
    },
    {
      label: t(commons.UNIT),
      align: "center",
      text: t(`measureUnits.${inventoryMovementsResume?.unit.toLowerCase()}`),
    },
    {
      label: t(commons.REASON),
      align: "center",
      component: (item: KOSRowData) => (
        <>
          <Typography variant="overline" fontSize={10} color="text.secondary">
            {t(`inventoryMovementType.${item.movementType.toLowerCase()}`)}
          </Typography>
          <Typography>{t(`adjustmentType.${item.transactionType}`)}</Typography>
        </>
      ),
    },
    {
      label: t(commons.DATETIME),
      field: "createdAt",
      type: "datetime_utc",
      align: "center",
    },
  ];

  return (
    <PageBox>
      <PageHeader
        title={
          inventoryMovementsResume?.productName ?? `${t(commons.LOADING)}...`
        }
        rightArea={[
          {
            children: t(commons.UPDATE),
            variant: "outlined",
            startIcon: <RefreshOutlinedIcon />,
            onClick: getInventoryMovements,
            sx: {
              bgcolor: "white",
            },
          },
          {
            children: t(commons.EXPORT),
            variant: "outlined",
            startIcon: <FactCheckOutlinedIcon />,
            onClick: exportDetail,
            disabled: isKitchenRol(user.data?.role as TypeOfRole),
            sx: {
              bgcolor: "white",
            },
          },
        ]}
      />

      {!isKitchenRol(user.data?.role as TypeOfRole) && (
        <Box
          display="flex"
          gap={2}
          width="100%"
          flexDirection={{ xs: "column", md: "row" }}
        >
          {filters.map((filter) => (
            <Box
              key={filter.name}
              display="flex"
              gap={2}
              onClick={() => setSelectedFilter(filter)}
              sx={{
                bgcolor:
                  selectedFilter.name === filter.name ? "#E2F0FF" : "white",
                borderRadius: 2,
                py: 2,
                px: 4,
                border: "1px solid #D7D3D3",
                cursor: "pointer",
                flex: 1,

                "&:hover": {
                  border: "1px solid #aaa",
                },
              }}
            >
              <PinOutlinedIcon sx={{ color: "#1C1B1F" }} />
              <Box>
                <Typography color="text.secondary" fontWeight={300}>
                  {filter.name}
                </Typography>
                {inventoryMovementsResumeStatus !== "loading" && (
                  <Typography fontWeight={600}>
                    {`${getQuantity(
                      inventoryMovementsResume?.movementsDetail.filter(
                        (mov) => mov.countingId === maxCounting
                      ) ?? [],
                      filter.group
                    )} `}
                    {t(
                      `measureUnits.${
                        inventoryMovementsResume?.unit.toLowerCase() ?? "un"
                      }`
                    )}
                  </Typography>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      )}

      <Box height="100%" width="100%" mt={2}>
        <KOSBaseTable
          columns={getHeader()}
          rows={{
            data: itemsFiltered,
            total: itemsFiltered.length ?? 0,
            loading: inventoryMovementsResumeStatus === "loading",
            sx: (item: KOSRowData) => ({
              backgroundColor:
                item.countingId === maxCounting ? "white" : "#F6F6F6",
              borderBottom:
                item.transactionType === "COUNTING"
                  ? "3px solid #D8D8D8"
                  : "1px solid #D8D8D8",
            }),
          }}
          emptyState={
            <KOSEmptyState
              icon={InfoOutlinedIcon}
              message="No se encontraron movimientos"
            />
          }
        />
      </Box>
    </PageBox>
  );
};

export default TheoreticalInventoryMovements;
