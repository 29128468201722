import { FunctionComponent } from "react";

import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";

interface BoxTitleTableProps {
  data: string;
  align?: "left" | "center" | "right" | "justify" | "inherit";
}

const BoxTitleTable: FunctionComponent<BoxTitleTableProps> = (props) => {
  const { data, align } = props;
  return (
    <TableCell align={align ?? "left"} sx={{ backgroundColor: "#F9FAFC" }}>
      <Typography
        textTransform={"uppercase"}
        variant="h6"
        sx={{
          fontSize: "14px",
          fontWeight: "700",
          letterSpacing: "0.15px",
          color: "#7B7B7B",
          lineHeight: "16px",
        }}
      >
        {data}
      </Typography>
    </TableCell>
  );
};

export default BoxTitleTable;
