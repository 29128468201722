import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import { Chip, Grid, Paper, Tab, Tabs } from "@mui/material";
import Typography from "@mui/material/Typography";
import { commons } from "app/i18n/types";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface PackingViewModesProps {
  tab: number;
  setTab: (tab: number) => void;
  totalSupplies: number;
  totalKitchens: number;
}

const PackingViewModes: FunctionComponent<PackingViewModesProps> = (props) => {
  const { tab, setTab, totalSupplies, totalKitchens } = props;

  const { t } = useTranslation();

  return (
    <Paper sx={{ p: 2, height: "100%" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h6">{t(commons.VIEW_MODES)}</Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Tabs
            value={tab}
            onChange={(_, value) => {
              setTab(value);
            }}
            variant="fullWidth"
          >
            <Tab
              label={
                <>
                  {t(commons.FOR_SUPPLY)}
                  {!!totalSupplies && (
                    <Chip label={totalSupplies} color={"primary"} />
                  )}
                </>
              }
              value={0}
              icon={<FormatListBulletedOutlinedIcon />}
              iconPosition="start"
              sx={{ minHeight: 48, gap: 1 }}
            />
            <Tab
              label={
                <>
                  {t(commons.FOR_KITCHEN)}
                  {!!totalKitchens && (
                    <Chip label={totalKitchens} color={"primary"} />
                  )}
                </>
              }
              value={1}
              icon={<StorefrontOutlinedIcon />}
              iconPosition="start"
              sx={{ minHeight: 48, gap: 1 }}
            />
          </Tabs>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PackingViewModes;
