/* eslint-disable react/require-default-props */
import React from "react";

interface IconProp {
  fontSize?: string;
}

const CountDownloadIcon = (props: IconProp): React.ReactElement => {
  const { fontSize } = props;
  return (
    <svg
      width={fontSize ?? 40}
      height={fontSize ?? 40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width={fontSize ?? 40}
        height={fontSize ?? 40}
        rx="20"
        fill="#F1EFEF"
      />
      <mask
        id="mask0_5932_511"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="24"
        height="24"
      >
        <rect x="8" y="8" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5932_511)">
        <path
          d="M14.5 27.9996C12.9833 27.9996 11.6875 27.4746 10.6125 26.4246C9.5375 25.3746 9 24.0913 9 22.5746C9 21.2746 9.39167 20.1163 10.175 19.0996C10.9583 18.0829 11.9833 17.4329 13.25 17.1496C13.5333 15.9496 14.2417 14.8079 15.375 13.7246C16.5083 12.6413 17.7167 12.0996 19 12.0996C19.55 12.0996 20.0208 12.2954 20.4125 12.6871C20.8042 13.0788 21 13.5496 21 14.0996V20.1496L22.6 18.5996L24 19.9996L20 23.9996L16 19.9996L17.4 18.5996L19 20.1496V14.0996C17.7333 14.3329 16.75 14.9454 16.05 15.9371C15.35 16.9288 15 17.9496 15 18.9996H14.5C13.5333 18.9996 12.7083 19.3413 12.025 20.0246C11.3417 20.7079 11 21.5329 11 22.4996C11 23.4663 11.3417 24.2913 12.025 24.9746C12.7083 25.6579 13.5333 25.9996 14.5 25.9996H26.5C27.2 25.9996 27.7917 25.7579 28.275 25.2746C28.7583 24.7913 29 24.1996 29 23.4996C29 22.7996 28.7583 22.2079 28.275 21.7246C27.7917 21.2413 27.2 20.9996 26.5 20.9996H25V18.9996C25 18.1996 24.8167 17.4538 24.45 16.7621C24.0833 16.0704 23.6 15.4829 23 14.9996V12.6746C24.2333 13.2579 25.2083 14.1204 25.925 15.2621C26.6417 16.4038 27 17.6496 27 18.9996C28.15 19.1329 29.1042 19.6288 29.8625 20.4871C30.6208 21.3454 31 22.3496 31 23.4996C31 24.7496 30.5625 25.8121 29.6875 26.6871C28.8125 27.5621 27.75 27.9996 26.5 27.9996H14.5Z"
          fill="#232323"
        />
      </g>
    </svg>
  );
};

export default CountDownloadIcon;
