import appConfig from "config/app";
import { getMessaging } from "firebase/messaging";
import { envHermes, firebaseApp } from "./firebase-config";

const initMessaging = async () => {
  const { legologyHermesInstance: instance, app } = firebaseApp();

  const messaging = getMessaging(app);
  await instance.configMessaging(
    messaging,
    `/firebase-messaging-sw${envHermes[appConfig.env]}.js`
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { initMessaging };
