import { UseCase } from "core/common/interfaces/useCase";
import { RemoveLabelLog } from "core/transfer/interfaces/transfer/removeLabelLog";

export class RemoveLabelLogUseCase implements UseCase {
  constructor(private readonly repository: RemoveLabelLog) {}

  public execute(deleteLabelsIds: number[], originId: string) {
    return this.repository.removeLabelLog(deleteLabelsIds, originId);
  }
}
