import { Session } from "core/account/entities/Session";
import { UseCase } from "core/common/interfaces/useCase";
import { MassiveDataToSent } from "core/physicalCount/entities/Counting";
import {
  CanCreateCounting,
  CanCreateSchedulePhysicalCounting,
} from "core/physicalCount/interfaces/counting/canCreateCounting";

export class CreateCountingUseCase implements UseCase {
  constructor(private readonly repository: CanCreateCounting) {}

  public execute(
    kitchenId: string,
    countingTypeCode: string,
    session: Session
  ) {
    return this.repository.createCounting(kitchenId, countingTypeCode, session);
  }
}

export class CreateSchedulePhysicalCountingUseCase implements UseCase {
  constructor(private readonly repository: CanCreateSchedulePhysicalCounting) {}

  public execute(
    countingTypeCode: string,
    name: string,
    kitchenIds: string[],
    dates: string[],
    supplies: string[],
    massiveDataToSent: MassiveDataToSent[]
  ) {
    return this.repository.createSchedulePhysicalCounting(
      countingTypeCode,
      name,
      kitchenIds,
      dates,
      supplies,
      massiveDataToSent
    );
  }
}
