import { Grid, Paper, Skeleton, Typography } from "@mui/material";
import PurchaseOrderLineResumeItem from "app/components/PurchaseOrder/Line/Resume/Item";
import POLProgressBar from "app/pages/ReceiptOrder/PurchaseOrderLines/POLProgressBar";
import { convertLocalDate } from "app/utils/dateUtils";
import {
  TransferLine,
  TransferResponse,
} from "core/transfer/entities/TransferOrder";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { purchaseOrderModule, transferModule } from "../../../../i18n/types";

interface Props {
  loading: boolean;
  transferOrder?: TransferResponse;
}

const TransferOrderLineResume: FunctionComponent<Props> = (props) => {
  const { loading, transferOrder } = props;
  const { t } = useTranslation();

  const vendorName = transferOrder?.originKitchenName ?? "";
  const createdAt = transferOrder?.sendAt
    ? convertLocalDate(transferOrder.sendAt, "date")
    : "--/--/----";
  const sourceId = transferOrder?.externalId ?? "";
  const numberOfBaskets = transferOrder?.numberOfBaskets ?? "--";

  const [percentage, setPercentage] = useState<number>(0);
  const [pendingLines, setPendingLines] = useState<number>(0);

  const getPercentage = (): number => {
    const completeLines =
      transferOrder?.lines?.filter(
        (line: TransferLine) => line.status === "IN_PROCESS"
      ).length ?? 0;
    const totalLines = transferOrder?.lines?.length ?? 0;
    setPendingLines(totalLines - completeLines);
    if (totalLines === 0) {
      return 100;
    }
    const percent = (completeLines / totalLines) * 100;
    return parseFloat(percent.toFixed(2));
  };

  useEffect(() => {
    setPercentage(getPercentage());
  }, [transferOrder]);

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h6" sx={{ mb: 2.5 }}>
        {loading ? <Skeleton sx={{ width: 360 }} /> : <>{vendorName}</>}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <PurchaseOrderLineResumeItem
            label={t(purchaseOrderModule.ORDER_ID)}
            value={sourceId}
            icon="pin"
            isLoading={loading}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <PurchaseOrderLineResumeItem
            label={t(purchaseOrderModule.REQUEST_DATE)}
            value={createdAt}
            icon="event"
            isLoading={loading}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <PurchaseOrderLineResumeItem
            label={t(transferModule.NUMBER_OF_BASKETS)}
            value={numberOfBaskets.toString()}
            icon="pin"
            isLoading={loading}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <POLProgressBar percentage={percentage} pendingLines={pendingLines} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TransferOrderLineResume;
