import { Box, Grid, Typography } from "@mui/material";
import Autocomplete, {
  AutocompleteChangeReason,
} from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import GreenSwitch from "app/components/GreenSwitch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { commons, dailyAdjustmentsModule } from "app/i18n/types";
import { Product, RegisterOption } from "config/dailyAdjustment";
import { AdjustmentRecord } from "core/dailyAdjustments/entities/Adjustment";
import {
  Product as CatalogProduct,
  ProductRequest,
} from "core/dailyAdjustments/entities/Catalog";
import { FunctionComponent, SyntheticEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getFinishedProductsByCountry } from "utils/ratatouille";
import DailyAdjustmentsAddProduct from "../AddProduct";

interface Props {
  selectedOption: RegisterOption;
  kitchenId: string;
}

const DailyAdjustmentsRegister: FunctionComponent<Props> = (prop) => {
  const { selectedOption, kitchenId } = prop;

  const { t } = useTranslation();
  const products = useAppSelector((state) => state.global.products.data);
  const kitchens = useAppSelector((state) => state.global.kitchens.data);
  const dailyAdjustments = useAppSelector((state) => state.dailyAdjustments);
  const [adjustments, setAdjustments] = useState<AdjustmentRecord[]>([]);
  const [productToSave, setProductToSave] = useState<ProductRequest>();
  const [autocompleteKey, setAutocompleteKey] = useState<number>(0);
  const [switchKey, setSwitchKey] = useState<number>(0);
  const [showFinishedProducts, setShowFinishedProducts] =
    useState<boolean>(false);
  const [finishedProducts, setFinishedProducts] = useState<CatalogProduct[]>(
    []
  );

  useEffect(() => {
    if (!productToSave) {
      setShowFinishedProducts(false);
      setAutocompleteKey(new Date().getTime());
    }
  }, [productToSave]);

  useEffect(() => {
    if (!productToSave) {
      setSwitchKey(new Date().getTime());
    }
  }, [autocompleteKey]);

  useEffect(() => {
    setAdjustments(
      dailyAdjustments.forReview.data.map((el) => {
        return { ...el, createdAt: new Date(el.createdAt) };
      }) ?? []
    );
  }, [dailyAdjustments.forReview.data]);

  useEffect(() => {
    setProductToSave(undefined);
    setShowFinishedProducts(selectedOption.product === Product.FINISHED);
    setAutocompleteKey(new Date().getTime());
  }, [selectedOption, adjustments]);

  useEffect(() => {
    const currentKitchen = kitchens.find(
      (kitchen) => kitchen.kitchenId === kitchenId
    );

    if (currentKitchen) {
      getFinishedProductsByCountry(currentKitchen?.country).then((res) => {
        setFinishedProducts(res);
      });
    }
  }, [kitchens]);

  const productSelect = (
    e: SyntheticEvent<Element, Event>,
    value: {
      label: string;
      value: CatalogProduct;
    } | null,
    reason: AutocompleteChangeReason
  ) => {
    if (!value) {
      setProductToSave(undefined);
      return;
    }
    if (kitchenId) {
      const product: CatalogProduct = value.value;

      setProductToSave({
        id: `${Date.now()}-${product.sku}`,
        typeLoss: selectedOption.type,
        kitchenId,
        sku: product.sku,
        quantity: 0,
        image: null,
        motiveId: null,
        name: product.name,
        unit: product.unit,
        isFinishedProduct: showFinishedProducts ?? false,
      });
    }
  };

  const refinedCatalogProducts = (list?: CatalogProduct[]) =>
    list
      ?.reduce<CatalogProduct[]>((acc, product) => acc.concat(product), [])
      .map((product: CatalogProduct) => ({
        label: product.sku + "-" + product.name.toLocaleUpperCase(),
        value: product,
      })) ?? [];

  const searchLabel = t(
    showFinishedProducts ? commons.FINISHED_PRODUCTS : commons.SUPPLIES
  ).toLowerCase();

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography fontWeight={600}>
          {t(commons.REPORT)}{" "}
          {t(`adjustmentType.${selectedOption.type}`).toLowerCase()}
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ display: "flex", flexDirection: "row-reverse" }}>
        {selectedOption.product === Product.BOTH && (
          <Box display="flex" alignItems="center" gap={2}>
            <Typography>
              {t(dailyAdjustmentsModule.SHOW_FINISHED_PRODUCTS)}
            </Typography>
            <GreenSwitch
              key={switchKey}
              value={showFinishedProducts}
              onChange={() => {
                setShowFinishedProducts(!showFinishedProducts);
                setProductToSave(undefined);
              }}
            />
          </Box>
        )}
      </Grid>
      <Grid item xs={3}>
        <Autocomplete
          key={autocompleteKey}
          disablePortal
          options={refinedCatalogProducts(
            showFinishedProducts ? finishedProducts : products
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={
                dailyAdjustments.status === "loading"
                  ? `${t(commons.LOADING)}...`
                  : `${t(commons.SEEKER)} ${t(commons.OF)} ${searchLabel}`
              }
            />
          )}
          onChange={productSelect}
          disabled={dailyAdjustments.status === "loading"}
        />
      </Grid>
      {kitchenId && (
        <DailyAdjustmentsAddProduct
          kitchenId={kitchenId}
          selectedOption={selectedOption}
          productToSave={productToSave}
          setProductToSave={setProductToSave}
        />
      )}
    </Grid>
  );
};

export default DailyAdjustmentsRegister;
