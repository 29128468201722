import { createSlice } from "@reduxjs/toolkit";
import { ProductionRecord } from "core/productions/entities/Productions";
import { initialState } from "./state";
import {
  archiveProduction,
  createProduction,
  createProductionLabels,
  finishProduction,
  getProductionById,
  getProductionInventory,
  getProductionLabels,
  getProductionRecipe,
  getProductionsByKitchen,
  getProductionsCatalogByKitchen,
  liberateProduction,
  sendProductionPrintEvent,
  setProductionDetailUsedQuantity,
  startProduction,
  updateProduction,
  updateProductionManager,
} from "./thunks";

export const getProductionIndex = (
  productionList: ProductionRecord[] | null,
  productionId: string
): { productions: ProductionRecord[]; index: number } => {
  const productions = productionList ? [...productionList] : [];
  const prodIndex = productions.findIndex((prod) => prod.id === productionId);
  return { productions, index: prodIndex };
};

export const adjustmentsSlice = createSlice({
  name: "productions",
  initialState,
  reducers: {
    setSelectedProduction: (state, action) => {
      state.selectedProduction = action.payload;
    },
    clearLabels: (state) => {
      state.labels = [];
    },
    setSupplyDisplayMeasureUnit: (state, action) => {
      const { productionSku, supplySku, measureUnit } = action.payload;

      if (state.productionCatalog === null) {
        return;
      }

      const productionIndex = state.productionCatalog?.findIndex(
        (productionCatalogItem) => productionCatalogItem.sku === productionSku
      );

      if (productionIndex < 0) {
        return;
      }

      const production = state.productionCatalog[productionIndex];

      const supplyIndex = production.ingredients.findIndex(
        (productionCatalogSupply) => productionCatalogSupply.sku === supplySku
      );

      if (supplyIndex < 0) {
        return;
      }

      production.ingredients[supplyIndex].displayUnit = measureUnit;

      state.productionCatalog[productionIndex] = production;
      state.selectedProduction = production;
    },
    clearCreateProduction: (state, action) => {
      state.createProduction = {
        status: "idle",
        error: null,
      };
    },
    clearUpdateProduction: (state, action) => {
      state.updateProduction = {
        status: "idle",
        error: null,
      };
    },
    clearFinishProduction: (state, action) => {
      state.finishProduction = {
        status: "idle",
        error: null,
      };
    },
    clearDeleteProduction: (state, action) => {
      const index = state.productionList.list?.findIndex(
        (production) => production.id === action.payload.id
      );
      if (index !== undefined && index >= 0) {
        state.productionList.list?.splice(index, 1);
      }
      //action.payload.status = "ARCHIVED";
      state.deleteProduction = {
        status: "idle",
        error: null,
      };
    },
    clearSendProductionPrintEvent: (state, action) => {
      state.sendProductionPrintEvent = {
        status: "idle",
        error: null,
      };
    },
  },
  extraReducers(builder) {
    builder
      // getProductionsByKitchen
      .addCase(getProductionsCatalogByKitchen.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProductionsCatalogByKitchen.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.productionCatalog = action.payload.products;
      })
      .addCase(getProductionsCatalogByKitchen.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ?? null;
      })
      //getProductionRecipe
      .addCase(getProductionRecipe.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProductionRecipe.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.productionCatalog = action.payload.products;
      })
      .addCase(getProductionRecipe.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ?? null;
      })
      // getProductionInventory
      .addCase(getProductionInventory.pending, (state) => {
        state.status = "loading";
        state.selectedProduction = null;
      })
      .addCase(getProductionInventory.fulfilled, (state, action) => {
        state.status = "succeeded";

        const inventory = action.payload;

        const productionCatalogIndex = state.productionCatalog?.findIndex(
          (production) => production.sku === inventory.sku
        );

        if (
          productionCatalogIndex === undefined ||
          productionCatalogIndex < 0 ||
          state.productionCatalog === null ||
          state.productionCatalog.length === 0
        ) {
          return;
        }

        const productionCatalogItem =
          state.productionCatalog[productionCatalogIndex];

        productionCatalogItem.inventory = inventory.quantity;

        const productionSuppliesInventory =
          productionCatalogItem.ingredients.map((productionDetail) => ({
            ...productionDetail,
            name:
              inventory.suppliesInventory?.find(
                (inventory) => inventory.sku === productionDetail.sku
              )?.name ?? "",
            inventory:
              inventory.suppliesInventory?.find(
                (inventory) => inventory.sku === productionDetail.sku
              )?.quantity ?? null,
          }));

        productionCatalogItem.ingredients = productionSuppliesInventory;
        state.productionCatalog[productionCatalogIndex] = productionCatalogItem;
        state.selectedProduction = productionCatalogItem;
      })
      .addCase(getProductionInventory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ?? null;
      })

      // getProductionsByKitchen
      .addCase(getProductionsByKitchen.pending, (state) => {
        state.productionList.status = "loading";
        state.productionList.list = null;
      })
      .addCase(getProductionsByKitchen.fulfilled, (state, action) => {
        state.productionList = {
          status: "succeeded",
          list: action.payload,
          error: null,
        };
      })
      .addCase(getProductionsByKitchen.rejected, (state, action) => {
        state.productionList.status = "failed";
        state.productionList.error = action.error.message ?? null;
        state.productionList.list = null;
      })

      // getProductionsById
      .addCase(getProductionById.pending, (state) => {
        state.production.status = "loading";
        state.production.data = null;
      })
      .addCase(getProductionById.fulfilled, (state, action) => {
        state.production = {
          ...state.production,
          status: "succeeded",
          data: action.payload,
          error: null,
        };
      })
      .addCase(getProductionById.rejected, (state, action) => {
        state.production.status = "failed";
        state.production.data = null;
        state.production.error = action.error.message ?? null;
      })

      // setProductionDetailUsedQuantity
      .addCase(setProductionDetailUsedQuantity.pending, (state, action) => {
        state.production.detailsStatus[action.meta.arg.productionDetailId] =
          "loading";
      })
      .addCase(setProductionDetailUsedQuantity.fulfilled, (state, action) => {
        const productionDetailId = action.meta.arg.productionDetailId;
        const usedQuantity = action.meta.arg.usedQuantity;

        state.production.detailsStatus[productionDetailId] = "succeeded";

        if (state.production.data === null) {
          return;
        }

        const productionDetailIndex =
          state.production.data?.productionDetails.findIndex(
            (productionDetail) => productionDetail.id === productionDetailId
          );

        if (productionDetailIndex < 0) {
          return;
        }

        state.production.data.productionDetails[
          productionDetailIndex
        ].usedQuantity = usedQuantity;
      })
      .addCase(setProductionDetailUsedQuantity.rejected, (state, action) => {
        const productionDetailId = action.meta.arg.productionDetailId;

        state.production.detailsStatus[productionDetailId] = "failed";
      })

      //createProduction
      .addCase(createProduction.pending, (state, action) => {
        state.createProduction = {
          status: "loading",
          error: null,
        };
      })
      .addCase(createProduction.fulfilled, (state, action) => {
        state.createProduction = {
          status: "succeeded",
          error: null,
        };
      })
      .addCase(createProduction.rejected, (state, action) => {
        state.createProduction = {
          status: "failed",
          error: action.error.message ?? null,
        };
      })

      //updateProduction
      .addCase(updateProduction.pending, (state, action) => {
        state.updateProduction = {
          status: "loading",
          error: null,
        };
      })
      .addCase(updateProduction.fulfilled, (state, action) => {
        state.updateProduction = {
          status: "succeeded",
          error: null,
        };
      })
      .addCase(updateProduction.rejected, (state, action) => {
        state.updateProduction = {
          status: "failed",
          error: action.error.message ?? null,
        };
      })

      //deleteProduction
      .addCase(archiveProduction.pending, (state, action) => {
        state.deleteProduction = {
          status: "loading",
          error: null,
        };
      })
      .addCase(archiveProduction.fulfilled, (state, action) => {
        state.deleteProduction = {
          status: "succeeded",
          error: null,
        };
      })
      .addCase(archiveProduction.rejected, (state, action) => {
        state.deleteProduction = {
          status: "failed",
          error: action.error.message ?? null,
        };
      })

      //finishProduction
      .addCase(finishProduction.pending, (state, action) => {
        state.finishProduction = {
          status: "loading",
          error: null,
        };
      })
      .addCase(finishProduction.fulfilled, (state, action) => {
        state.finishProduction = {
          status: "succeeded",
          error: null,
        };
      })
      .addCase(finishProduction.rejected, (state, action) => {
        state.finishProduction = {
          status: "failed",
          error: action.error.message ?? null,
        };
      })
      // Get Production labels
      .addCase(getProductionLabels.pending, (state, action) => {
        state.getLabels = {
          status: "loading",
          error: null,
        };
        state.labels = [];
      })
      .addCase(getProductionLabels.fulfilled, (state, action) => {
        state.getLabels = {
          status: "succeeded",
          error: null,
        };
        state.labels = action.payload;
      })
      .addCase(getProductionLabels.rejected, (state, action) => {
        state.getLabels = {
          status: "failed",
          error: action.error.message ?? null,
        };
      })
      // Create Production labels
      .addCase(createProductionLabels.pending, (state, action) => {
        state.createLabels = {
          status: "loading",
          error: null,
        };
      })
      .addCase(createProductionLabels.fulfilled, (state, action) => {
        state.createLabels = {
          status: "succeeded",
          error: null,
        };
        state.labels = action.payload;

        const { productionId } = action.meta.arg;
        const { productions, index } = getProductionIndex(
          state.productionList.list,
          productionId
        );
        if (index !== -1) {
          productions[index].labels = [
            ...productions[index].labels,
            ...action.payload.map((line) => {
              return {
                id: line.id,
                quantity: line.quantity,
                unit: line.measureUnit,
                name: line.productName,
                batch: line.batch,
                sku: line.sku,
                productionDate: line.productionDate,
                expirationDate: line.expirationDate ?? "",
                createdAt: line.createdAt,
              };
            }),
          ];
        }
        state.productionList.list = productions;
      })
      .addCase(createProductionLabels.rejected, (state, action) => {
        state.createLabels = {
          status: "failed",
          error: action.error.message ?? null,
        };
      })

      // sendProductionPrintEvent
      .addCase(sendProductionPrintEvent.pending, (state, action) => {
        state.sendProductionPrintEvent = {
          status: "loading",
          error: null,
        };
      })
      .addCase(sendProductionPrintEvent.fulfilled, (state, action) => {
        state.sendProductionPrintEvent = {
          status: "succeeded",
          error: null,
        };
      })
      .addCase(sendProductionPrintEvent.rejected, (state, action) => {
        state.sendProductionPrintEvent = {
          status: "failed",
          error: action.error.message ?? null,
        };
      })

      .addCase(updateProductionManager.pending, (state, action) => {
        state.updateProductionManager = "loading";
      })
      .addCase(updateProductionManager.fulfilled, (state, action) => {
        state.updateProductionManager = "succeeded";
        const { productionId, userId, userName } = action.meta.arg;
        const { productions, index } = getProductionIndex(
          state.productionList.list,
          productionId
        );
        if (index !== -1) {
          productions[index].productionManager = userId;
          productions[index].productionManagerName = userName;
        }
        state.productionList.list = productions;
      })
      .addCase(updateProductionManager.rejected, (state, action) => {
        state.updateProductionManager = "failed";
      })
      .addCase(startProduction.fulfilled, (state, action) => {
        const { productionId } = action.meta.arg;
        const { productions, index } = getProductionIndex(
          state.productionList.list,
          productionId
        );
        if (index !== -1) {
          productions[index].startDate = new Date();
          productions[index].status = "IN_PROCESS";
        }
        state.productionList.list = productions;
      })
      .addCase(liberateProduction.fulfilled, (state, action) => {
        const { productionId } = action.meta.arg;
        const filteredProductions = state.productionList.list?.filter(
          (production) => production.id !== productionId
        );
        state.productionList.list = filteredProductions ?? [];
      });
  },
});

export const {
  setSelectedProduction,
  setSupplyDisplayMeasureUnit,
  clearCreateProduction,
  clearFinishProduction,
  clearUpdateProduction,
  clearDeleteProduction,
  clearSendProductionPrintEvent,
  clearLabels,
} = adjustmentsSlice.actions;

export const { reducer } = adjustmentsSlice;

export default adjustmentsSlice;
