import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./state";
import {
  exportInventoryByCountry,
  getTheoreticalInventoryByKitchen,
  getTheoreticalInventoryComaprisonByCity,
  getTheoreticalInventoryComaprisonByCountry,
  getTheoreticalInventoryComaprisonByKitchen,
  getTheoreticalInventoryMovementsResume,
} from "./thunks";

export const theoricalInventorySlice = createSlice({
  name: "theoricalInventory",
  initialState,
  reducers: {
    setCountry: (state, action) => {
      state.country = action.payload;
    },
    setKitchen: (state, action) => {
      state.kitchen = action.payload;
    },
    cleanState: (state) => {
      state.status = "idle";
    },
  },
  extraReducers(builder) {
    builder
      //Get inventory by City
      .addCase(
        getTheoreticalInventoryComaprisonByCity.pending,
        (state, action) => {
          state.comaprisonByCity = {
            data: [],
            status: "loading",
            error: null,
          };
        }
      )
      .addCase(
        getTheoreticalInventoryComaprisonByCity.fulfilled,
        (state, action) => {
          state.comaprisonByCity = {
            data: action.payload.kitchens,
            status: "succeeded",
            error: null,
          };
        }
      )
      .addCase(
        getTheoreticalInventoryComaprisonByCity.rejected,
        (state, action) => {
          state.comaprisonByCity = {
            data: [],
            status: "failed",
            error: null,
          };
        }
      )

      //Get inventory by kitchen
      .addCase(getTheoreticalInventoryByKitchen.pending, (state, action) => {
        state.statusInventory = "loading";
      })
      .addCase(getTheoreticalInventoryByKitchen.fulfilled, (state, action) => {
        state.statusInventory = "succeeded";
        state.inventory = action.payload;
      })
      .addCase(getTheoreticalInventoryByKitchen.rejected, (state, action) => {
        state.statusInventory = "failed";
        state.error = action.error.message ?? null;
      })

      //Get inventory comparison by kitchen
      .addCase(
        getTheoreticalInventoryComaprisonByKitchen.pending,
        (state, action) => {
          state.comaprisonByKitchen = {
            data: {
              lastCounting: null,
              data: null,
            },
            status: "loading",
            error: null,
          };
        }
      )
      .addCase(
        getTheoreticalInventoryComaprisonByKitchen.fulfilled,
        (state, action) => {
          state.comaprisonByKitchen = {
            data: action.payload,
            status: "succeeded",
            error: null,
          };
        }
      )
      .addCase(
        getTheoreticalInventoryComaprisonByKitchen.rejected,
        (state, action) => {
          state.comaprisonByKitchen = {
            data: {
              lastCounting: null,
              data: null,
            },
            status: "failed",
            error: action.error.message ?? null,
          };
        }
      )

      //Get inventory comparison by country
      .addCase(
        getTheoreticalInventoryComaprisonByCountry.pending,
        (state, action) => {
          state.comaprisonByCountry = {
            data: null,
            status: "loading",
            error: null,
          };
        }
      )
      .addCase(
        getTheoreticalInventoryComaprisonByCountry.fulfilled,
        (state, action) => {
          state.comaprisonByCountry = {
            data: action.payload,
            status: "succeeded",
            error: null,
          };
        }
      )
      .addCase(
        getTheoreticalInventoryComaprisonByCountry.rejected,
        (state, action) => {
          state.comaprisonByCountry = {
            data: null,
            status: "failed",
            error: action.error.message ?? null,
          };
        }
      )

      // getTheoreticalInventoryMovementsResume
      .addCase(
        getTheoreticalInventoryMovementsResume.pending,
        (state, action) => {
          state.inventoryMovementsResume = {
            status: "loading",
            data: null,
            error: null,
          };
        }
      )
      .addCase(
        getTheoreticalInventoryMovementsResume.fulfilled,
        (state, action) => {
          state.inventoryMovementsResume = {
            status: "succeeded",
            data: action.payload,
            error: null,
          };
          state.maxCounting = Math.max.apply(
            0,
            action.payload?.movementsDetail.map((mov) => mov.countingId) ?? []
          );
        }
      )
      .addCase(
        getTheoreticalInventoryMovementsResume.rejected,
        (state, action) => {
          state.inventoryMovementsResume = {
            status: "failed",
            data: null,
            error: action.error.message ?? null,
          };
        }
      )

      //export inventiry
      .addCase(exportInventoryByCountry.pending, (state, action) => {
        state.statusExport = "loading";
      })
      .addCase(exportInventoryByCountry.fulfilled, (state, action) => {
        state.statusExport = "succeeded";
        state.exportInventory = action.payload;
      })
      .addCase(exportInventoryByCountry.rejected, (state, action) => {
        state.statusExport = "failed";
        state.error = action.error.message ?? null;
      });
  },
});
export const { setCountry, setKitchen, cleanState } =
  theoricalInventorySlice.actions;

export const { reducer } = theoricalInventorySlice;

export default theoricalInventorySlice;
