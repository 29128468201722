import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Alert, LinearProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { commons, productionManagement } from "app/i18n/types";
import { clearSendProductionPrintEvent } from "app/store/slices/productions";
import { getProductionLabels } from "app/store/slices/productions/thunks";
import {
  ProductionLabelgroup,
  ProductionRecord,
} from "core/productions/entities/Productions";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import ProductionLabelPrintTemplate from "../PrintTemplate";
import ProductionLabelHistoryItem from "./Item";

interface Props {
  production: ProductionRecord;
  onClose: () => void;
  onlyView?: boolean;
}

const ProductionLabelHistoryDialog: FunctionComponent<Props> = (props) => {
  const { onClose, production, onlyView } = props;

  const componentRef = useRef(null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const labels = useAppSelector((state) => state.productions.labels);
  const sendProductionPrintEventRequest = useAppSelector(
    (state) => state.productions.sendProductionPrintEvent
  );
  const [labelGroup, setLabelGroup] = useState<ProductionLabelgroup>({});
  const [openGroup, setOpenGroup] = useState<string>("");

  useEffect(() => {
    if (sendProductionPrintEventRequest.status === "succeeded") {
      const timer = setTimeout(() => {
        dispatch(clearSendProductionPrintEvent(null));
      }, 5000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [dispatch, sendProductionPrintEventRequest.status]);

  useEffect(() => {
    if (production.id) {
      dispatch(getProductionLabels({ productionId: production.id }));
    }
  }, [dispatch, production.id]);

  useEffect(() => {
    const labelGroupTmp: ProductionLabelgroup = {};
    labels?.forEach((label) => {
      const key = label.createdAt.split(".")[0];
      if (!labelGroupTmp[key]) {
        labelGroupTmp[key] = [];
      }
      labelGroupTmp[key].push(label);
    });
    setLabelGroup(labelGroupTmp);
  }, [labels]);

  const toggleOpenGroup = (key: string) => {
    setOpenGroup(openGroup === key ? "" : key);
  };

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      {sendProductionPrintEventRequest.status === "loading" && (
        <LinearProgress />
      )}

      <DialogTitle color="primary.main">
        {t(productionManagement.LABELS_HISTORY)}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseOutlinedIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {!onlyView && (
          <Alert severity="info" sx={{ mb: 1 }}>
            {t(productionManagement.SELECT_LABELS_TO_PRINT)}
          </Alert>
        )}
        {!!labels?.length && (
          <>
            {Object.keys(labelGroup).map((labelGroupKey) => (
              <ProductionLabelHistoryItem
                key={labelGroupKey}
                labelGroup={labelGroup}
                labelGroupKey={labelGroupKey}
                total={labels.length}
                openGroup={openGroup}
                toggleOpenGroup={toggleOpenGroup}
                onlyView={onlyView}
              />
            ))}
          </>
        )}
        <DialogActions sx={{ px: 0 }}>
          <Box display="flex" gap={2} flexDirection="column" width="100%">
            {!labels?.length && (
              <>
                <Button
                  variant="contained"
                  onClick={handlePrint}
                  startIcon={<ChevronRightOutlinedIcon />}
                  disabled={sendProductionPrintEventRequest.status !== "idle"}
                  fullWidth
                >
                  {t(productionManagement.REPRINT_LABELS)}
                </Button>
                {labels && (
                  <ProductionLabelPrintTemplate
                    labels={labels}
                    ref={componentRef}
                  />
                )}
              </>
            )}

            <Button
              variant="outlined"
              onClick={onClose}
              color="error"
              startIcon={<CloseOutlinedIcon />}
              fullWidth
              sx={{}}
            >
              {t(commons.CANCEL)}
            </Button>
          </Box>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ProductionLabelHistoryDialog;
