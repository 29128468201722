import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloudSyncOutlinedIcon from "@mui/icons-material/CloudSyncOutlined";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import LinearProgress from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import PageHeader from "app/components/PageHeader/PageHeader";
import ProductionCatalogTable from "app/components/Productions/ProductionCatalogTable";
import ProductionDialog from "app/components/Productions/ProductionDialog";
import { useAppDispatch } from "app/hooks/useAppDispatch";
import { useAppSelector } from "app/hooks/useAppSelector";
import { useNavigator } from "app/hooks/useNavigator";
import {
  clearUpdateProduction,
  setSelectedProduction,
} from "app/store/slices/productions";
import {
  getProductionById,
  getProductionInventory,
  getProductionRecipe,
  updateProduction,
} from "app/store/slices/productions/thunks";
import { AmplitudeEvent, logEvent } from "core/common/utils/analytics";
import {
  RemoteConfigKey,
  getRemoteValueBoolean,
} from "core/common/utils/remoteConfig";
import { ProductionPayload } from "core/productions/entities/Productions";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMount } from "react-use";
import { PageBox } from "utils/generalUI";
import {
  adjustmentType,
  commons,
  productionManagement,
} from "../../i18n/types";

interface ProductionEditProps {}

const ProductionEdit: FunctionComponent<ProductionEditProps> = () => {
  const navigator = useNavigator();
  let { kitchenId, productionId } = navigator.params();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const showInventoryOnNewProductions = getRemoteValueBoolean(
    RemoteConfigKey.SHOW_INVENTORY_ON_NEW_PRODUCTIONS
  );

  const production = useAppSelector(
    (state) => state.productions.production.data
  );

  const productionsState = useAppSelector((state) => state.productions.status);
  const productions = useAppSelector(
    (state) => state.productions.productionCatalog
  );

  const selectedProduction = useAppSelector(
    (state) => state.productions.selectedProduction
  );
  const updateProductionStatus = useAppSelector(
    (state) => state.productions.updateProduction.status
  );

  const [quantityToProduce, setQuantityToProduce] = useState<number | null>(
    null
  );

  const [openConfirmationDialog, setOpenConfirmationDialog] =
    useState<boolean>(false);

  useMount(() => {
    if (productionId !== undefined && kitchenId !== undefined) {
      dispatch(getProductionById({ productionId, kitchenId }));
      dispatch(getProductionRecipe({ productionId, kitchenId }));
    }
  });

  useEffect(() => {
    if (production && kitchenId) {
      setQuantityToProduce(production?.quantityToProduce || 0);
      dispatch(
        getProductionInventory({ kitchenId, sku: production?.sku || "" })
      );
    }
  }, [production, kitchenId]);

  useEffect(() => {
    if (updateProductionStatus === "succeeded") {
      setOpenConfirmationDialog(true);
    }
  }, [updateProductionStatus]);

  const onProductionUpdated = () => {
    dispatch(setSelectedProduction(null));
    dispatch(clearUpdateProduction(null));
    navigator.toByLocationType(`/productions/${kitchenId}`);
  };

  const onSetQuantityToProduce = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const quantity =
        event.target.value === "" ? null : Number(event.target.value);
      setQuantityToProduce(quantity);
    },
    []
  );

  const saveProduction = useCallback(() => {
    try {
      if (kitchenId) {
        if (
          production !== null &&
          kitchenId !== undefined &&
          selectedProduction
        ) {
          const newProduction: ProductionPayload = {
            sku: production.sku,
            name: production.name,
            quantityToProduce: quantityToProduce ?? 0,
            locationId: kitchenId,
            unit: production.unit,
            userId: "",
            productionDetails: selectedProduction.ingredients.map((supply) => ({
              sku: supply.sku,
              name: supply.name,
              quantity: supply.quantity,
              requiredQuantity: (quantityToProduce ?? 1) * supply.quantity,
              unit: supply.unit,
              displayUnit: supply.displayUnit ?? supply.unit,
              userId: "",
            })),
          };

          dispatch(
            updateProduction({
              production: newProduction,
              productionId: production.id,
            })
          );
        }
      }
    } catch (e) {
      throw e;
    }
  }, [kitchenId, quantityToProduce, selectedProduction, production]);

  return (
    <PageBox>
      <PageHeader
        title={t(productionManagement.EDIT_PRODUCTION)}
        rightArea={[
          {
            children: t(commons.SAVE_CHANGES),
            variant: "contained",
            startIcon: <CloudSyncOutlinedIcon />,
            onClick: () => {
              saveProduction();
              logEvent(AmplitudeEvent.SaveEditProduction, {
                kitchenId: kitchenId,
                productionId: selectedProduction?.id,
                sku: selectedProduction?.sku,
                productName: selectedProduction?.name,
                newQuantity: quantityToProduce,
                previousQuantity: selectedProduction?.quantityToProduce,
              });
            },
            disabled:
              quantityToProduce === null ||
              quantityToProduce === 0 ||
              updateProductionStatus === "loading",
          },
        ]}
      />

      <Grid container spacing={2} mb={2}>
        <Grid item xs={9}>
          <Box
            p={2}
            borderRadius={2}
            bgcolor="white"
            display="flex"
            gap={2}
            justifyContent="space-between"
            alignItems="center"
            border="1px solid #D7D3D3"
            height="100%"
            mb={2}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography
                  color="text.secondary"
                  fontWeight={300}
                  gutterBottom
                >
                  {t(adjustmentType.PRODUCTION)}
                </Typography>
                <Autocomplete
                  size="small"
                  loading={productionsState === "loading"}
                  options={productions ?? []}
                  getOptionLabel={(product) => product.name}
                  value={selectedProduction}
                  popupIcon={
                    productionsState === "loading" ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <ArrowDropDownOutlinedIcon />
                    )
                  }
                  isOptionEqualToValue={(option, selected) =>
                    option.sku === selected.sku
                  }
                  renderInput={(params) => <TextField {...params} />}
                  disabled={true}
                  disablePortal
                />
              </Grid>
              <Grid item xs={3}>
                <Typography
                  color="text.secondary"
                  fontWeight={300}
                  textAlign="center"
                  gutterBottom
                >
                  {t(commons.EXISTENCE)}
                </Typography>
                <Typography
                  color="text.secondary"
                  textAlign="center"
                  fontWeight={500}
                  gutterBottom
                >
                  {selectedProduction?.inventory ?? "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  color="text.secondary"
                  fontWeight={300}
                  textAlign="center"
                  gutterBottom
                >
                  {t(commons.UNITS)}
                </Typography>
                <Typography
                  color="text.secondary"
                  textAlign="center"
                  fontWeight={500}
                  gutterBottom
                >
                  {selectedProduction?.unit ?? "UNI"}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={3}>
          <Box
            px={2}
            py={2}
            borderRadius={2}
            bgcolor="white"
            display="flex"
            gap={2}
            justifyContent="space-between"
            alignItems="center"
            border="1px solid #D7D3D3"
            height="100%"
          >
            <TextField
              label={t(commons.PRODUCTION_QUANTITY)}
              value={quantityToProduce ?? ""}
              onChange={onSetQuantityToProduce}
              InputProps={{
                type: "number",
                endAdornment: (
                  <InputAdornment position="start">
                    {selectedProduction?.unit ?? "UNI"}
                  </InputAdornment>
                ),
              }}
              color="warning"
              focused
              autoComplete="off"
            />
          </Box>
        </Grid>
      </Grid>

      {(productionsState === "loading" ||
        updateProductionStatus === "loading") && <LinearProgress />}

      <Box display="flex" width="100%">
        {selectedProduction !== null &&
          selectedProduction.ingredients !== null && (
            <ProductionCatalogTable
              production={selectedProduction}
              quantityToProduce={quantityToProduce}
              showInventoryOnNewProductions={showInventoryOnNewProductions}
            />
          )}
      </Box>
      <ProductionDialog
        handleClose={onProductionUpdated}
        handleConfirm={onProductionUpdated}
        message={t(productionManagement.EDIT_PRODUCTION_MODAL_SUCCESS_MESSAGE, {
          productionName: production?.name,
        })}
        title={t(productionManagement.EDIT_PRODUCTION_MODAL_SUCCESS_TITLE)}
        open={openConfirmationDialog}
        icon={<CheckCircleOutlineIcon color="success" sx={{ fontSize: 96 }} />}
        showCancelButton={false}
      />
    </PageBox>
  );
};

export default ProductionEdit;
