import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { FunctionComponent } from "react";

interface InfoCardProps {
  icon?: string;
  label: string;
  value: string;
  loading?: boolean;
}

const InfoCard: FunctionComponent<InfoCardProps> = (props) => {
  const { label, icon, value, loading } = props;

  return (
    <Box
      bgcolor="white"
      borderRadius={2}
      border="1px solid #D7D3D3"
      p={2}
      display="flex"
      gap={2}
      flex={1}
    >
      {icon && (
        <Box>
          <Icon className="material-icons-outlined">{icon}</Icon>
        </Box>
      )}

      <Box>
        <Typography variant="overline" color="text.secondary">
          {label}
        </Typography>

        {loading ? (
          <Skeleton />
        ) : (
          <Typography fontWeight={700} textTransform="uppercase">
            {value}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default InfoCard;
