import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useAppSelector } from "app/hooks/useAppSelector";
import { getTransferOrderById } from "app/store/slices/transfer/thunks";
import { getCityName } from "app/utils/city.utils";
import dayjs from "dayjs";
import React, { forwardRef, useEffect, useState } from "react";
import Barcode from "react-barcode";
import { useTranslation } from "react-i18next";
import { getCountryName } from "../../../core/common/entities/Country";
import { Kitchen } from "../../../core/supplies/entities/Kitchen";
import { TransferOrderResponse } from "../../../core/transfer/entities/TransferOrder";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { measureUnits } from "../../i18n/types";

interface PrintReportTemplateProps {
  originKitchenId: string;
  transferId: number;
  handlePrint: () => void;
}

const PrintReportTemplate = forwardRef(
  (
    props: PrintReportTemplateProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const [transferOrderData, setTransferOrderDataData] =
      useState<TransferOrderResponse>();
    const [destiny, setDestiny] = useState<Kitchen>();
    const [origin, setOrigin] = useState<Kitchen>();
    const { originKitchenId, transferId: id, handlePrint } = props;
    const [transferId, setTransferId] = useState<string>("");
    const kitchens = useAppSelector((state) => state.global.kitchens.data);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    useEffect(() => {
      id && setTransferId(id.toString());
    }, [id]);

    const getPageMargins = () => {
      return `@page { margin-top: 4vh !important;  margin-bottom: 4vh !important; }`;
    };

    const getdataToPrint = async () => {
      const transferOrder = await dispatch(
        getTransferOrderById({ transferId })
      );

      const payload = transferOrder.payload as TransferOrderResponse;
      setTransferOrderDataData(payload);

      if (kitchens) {
        const originData = kitchens.find(
          (it) => it.kitchenId === originKitchenId
        );
        setOrigin(originData);
        const destinyData = kitchens.find(
          (it) => it.kitchenId === payload.destinationKitchenId
        );
        setDestiny(destinyData);
      }
    };

    useEffect(() => {
      transferId && getdataToPrint();
    }, [transferId]);

    useEffect(() => {
      if (transferOrderData) {
        setTimeout(() => {
          handlePrint();
        }, 1000);
      }
    }, [transferOrderData]);

    if (!transferOrderData) {
      return (
        <Box
          ref={ref}
          sx={{ display: "none", displayPrint: "block", overflow: "auto" }}
        >
          <style>{getPageMargins()}</style>
          <h1>Sin data</h1>
        </Box>
      );
    }

    const noMarginAndPadding = { margin: "0", padding: "0" };

    return (
      <Box
        ref={ref}
        sx={{ display: "none", displayPrint: "block", overflow: "auto" }}
      >
        <style>{getPageMargins()}</style>
        <Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "2vh",
            }}
          >
            <img
              src="https://images-kos.s3.amazonaws.com/logo_Foodology/logo-foodology.png"
              alt="Foodology SAS"
              style={{ width: "40%" }}
            />
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              paddingLeft: "5vw",
              paddingRight: "5vw",
              marginTop: transferOrderData.fiscalDocumentKey ? "" : "2vh",
            }}
          >
            {destiny && origin && (
              <Box width={"100%"}>
                <Box sx={{ display: "flex" }}>
                  <Grid container spacing={1} sx={{ mx: "5%" }}>
                    {transferOrderData.fiscalDocumentKey && (
                      <Grid item xs={12} sx={{ textAlign: "center" }}>
                        <Barcode
                          value={`${transferOrderData.fiscalDocumentKey}`}
                          height={50}
                          width={1.2}
                          displayValue={true}
                          fontSize={12}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <h2
                        style={{
                          ...noMarginAndPadding,
                          textAlign: "right",
                        }}
                      >{`Ped. transfer: ${transferOrderData.externalId}`}</h2>
                      <p
                        style={{
                          ...noMarginAndPadding,
                          textAlign: "right",
                        }}
                      >{`Fecha Envio: ${dayjs(transferOrderData.sendAt).format(
                        "DD/MM/YYYY"
                      )}`}</p>
                    </Grid>

                    <Grid item xs={6}>
                      <p
                        style={{
                          ...noMarginAndPadding,
                          fontWeight: "bold",
                        }}
                      >
                        Origen
                      </p>
                      <p style={noMarginAndPadding}>{origin.name}</p>
                      <p style={noMarginAndPadding}>{origin.address}</p>
                      <p style={noMarginAndPadding}>
                        {getCityName(origin.city)}
                      </p>
                      <p style={noMarginAndPadding}>
                        {getCountryName(origin.country)}
                      </p>
                    </Grid>
                    <Grid item xs={6}>
                      <p
                        style={{
                          ...noMarginAndPadding,
                          fontWeight: "bold",
                        }}
                      >
                        Destino
                      </p>
                      <p style={noMarginAndPadding}>{`${destiny.name}`}</p>
                      <p style={noMarginAndPadding}>{destiny.address}</p>
                      <p style={noMarginAndPadding}>
                        {getCityName(destiny.city)}
                      </p>
                      <p style={noMarginAndPadding}>
                        {getCountryName(destiny.country)}
                      </p>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box sx={{ width: "90%", paddingLeft: "10vw", marginTop: "2vh" }}>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead style={{ borderBottom: "2px solid black" }}>
              <tr>
                <th
                  style={{
                    width: "12%",
                    textAlign: "center",
                    border: "1px solid black",
                  }}
                >
                  SKU
                </th>
                <th
                  style={{
                    width: "50%",
                    textAlign: "left",
                    border: "1px solid black",
                    paddingLeft: "5vw",
                  }}
                >
                  Descripción
                </th>
                <th
                  style={{
                    width: "20%",
                    textAlign: "center",
                    border: "1px solid black",
                  }}
                >
                  Cantidad
                </th>
                <th
                  style={{
                    width: "20%",
                    textAlign: "center",
                    border: "1px solid black",
                  }}
                >
                  Unidad
                </th>
              </tr>
            </thead>
            <tbody>
              {transferOrderData.lines.length > 0 &&
                transferOrderData.lines
                  .filter((line) => line.sendQuantity > 0)
                  .map((line) => (
                    <tr key={`PrintReportRow${transferId}-${line.id}`}>
                      <td
                        style={{
                          textAlign: "center",
                          border: "1px solid black",
                        }}
                      >
                        {line.sku}
                      </td>
                      <td
                        style={{
                          textAlign: "left",
                          border: "1px solid black",
                          paddingLeft: "5vw",
                        }}
                      >
                        {line.name}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "1px solid black",
                        }}
                      >
                        {line.sendQuantity}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          border: "1px solid black",
                        }}
                      >
                        {t(
                          measureUnits[
                            line.measureUnit as keyof typeof measureUnits
                          ]
                        )}
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </Box>
      </Box>
    );
  }
);

export default PrintReportTemplate;
